import React, { useEffect, useState } from 'react'
import FindToFollowTeachersNFriendsCard from './FindToFollowTeachersNFriendsCard';
import '../FIndToFollow/FindToFollowTeachersNFriends.css'
import '../FIndToFollow/FindToFollowSchools.css'
import { collection, doc, getDocs, limit, onSnapshot, query, startAfter } from 'firebase/firestore';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { Autocomplete, Button, TextField } from '@mui/material';
import dB from '../../firebase';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function FindToFollowTeachersNFriends(props) {

    const { userId, userPathCollection, userCurrentStatus } = props

    const [open, setOpen] = useState(false);
    const handleClickOpen = (e) => { e.preventDefault(); setOpen(true); };
    const handleClose = (e) => { e.preventDefault(); setOpen(false); };

    const [findToFollowFriends, setfindToFollowFriends] = useState([]);
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            onSnapshot(query(collection(dB, "USERS"), limit(7)), (snap) => {
                setfindToFollowFriends(snap.docs.map((userDoc) => ({
                    friendId: userDoc.id,
                    friendPathCollection: userDoc.data()?.USER_NAME,
                    friendImage: userDoc.data()?.USER_DISPLAY_PIC,
                    friendESchoolStatus: userDoc.data()?.USER_CURRENT_STATUS
                })))
            })
        }
        return () => { aaa = false }
    }, [])

    const [btndisabled, setbtndisabled] = useState(false)
    const [findToFollowFriendsSet, setfindToFollowFriendsSet] = useState([]);
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            onSnapshot(query(collection(dB, "USERS"), limit(9)), (snap) => {
                fetchMore(snap)
            })
        }
        return () => { aaa = false }
    }, [])
    const [lastVisible, setlastVisible] = useState()
    const fetchMore = (snap) => {
        let friendsList = snap.docs.map((frndDoc) => ({
            friendId: frndDoc.id,
            friendPathCollection: frndDoc.data()?.USER_NAME,
            friendImage: frndDoc.data()?.USER_DISPLAY_PIC,
            friendESchoolStatus: frndDoc.data()?.USER_CURRENT_STATUS
        }))
        setfindToFollowFriendsSet(prev => { return [...prev, ...friendsList] })
        var lastVisibleComment = snap.docs[snap.docs.length - 1];
        setlastVisible(lastVisibleComment)
    }
    const [totalFriends, settotalFriends] = useState()
    const loadMoreFriends = async (FindToFollowSchoolsCardSetLength) => {
        settotalFriends(FindToFollowSchoolsCardSetLength)
        setbtndisabled(true)

        const q = query(collection(dB, "USERS"), startAfter(lastVisible), limit(9))
        const snap = await getDocs(q).catch(err => alert(err.message))
        await fetchMore(snap)
        setbtndisabled(false)
    }

    const [userToSearch, setuserToSearch] = useState(null)
    const [userToSearchInputValue, setuserToSearchInputValue] = useState(null)

    const [searchOptions, setsearchOptions] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            onSnapshot(collection(dB, "USERS"), (snap) => {
                setsearchOptions(snap.docs.map((searchDoc) => ({
                    userId: searchDoc.id,
                    userName: searchDoc.data()?.USER_NAME,
                    userDP: searchDoc.data()?.USER_DISPLAY_PIC
                })))
            })
        }
        return () => { aaa = false }
    }, [])

    const [searchOptionId, setsearchOptionId] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userToSearch) {
                searchOptions.map(option => {
                    if (option.userName === userToSearch) {
                        setsearchOptionId(option.userId)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userToSearch, searchOptions])

    const [selectedUserData, setselectedUserData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (searchOptionId) {
                onSnapshot(doc(dB, "USERS", searchOptionId), (snap) => {
                    if (snap.exists()) {
                        setselectedUserData(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [searchOptionId])

    return (
        <div className='FindToFollowTeachersNFriends'>
            {
                findToFollowFriends.map((cardData, i) => (
                    <FindToFollowTeachersNFriendsCard
                        key={i}
                        userId={userId}
                        userPathCollection={userPathCollection}
                        userCurrentStatus={userCurrentStatus}
                        friendPathCollection={cardData.friendPathCollection}
                        friendImage={cardData.friendImage}
                        friendId={cardData.friendId}
                        friendESchoolStatus={cardData.friendESchoolStatus}
                    />
                ))
            }
            {
                findToFollowFriends.length > 6 && (
                    <h2 onClick={handleClickOpen}>See<br />More</h2>
                )
            }


            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <div className='seeAllSchools'>
                    <div className='seeAllSchoolsTitleSearch'>
                        <h3>Friends to Follow</h3>
                        <Autocomplete
                            value={userToSearch}
                            onChange={(event, newValue) => {
                                event.preventDefault()
                                setuserToSearch(newValue);
                            }}
                            inputValue={userToSearchInputValue}
                            onInputChange={(event, newInputValue) => {
                                setuserToSearchInputValue(newInputValue);
                            }}
                            id="controllable-states-demo"
                            options={searchOptions.map((option) => option.userName)}
                            renderInput={(params) =>
                                <TextField
                                    id="searchUser"
                                    name='searchUser'
                                    {...params}
                                    margin="normal"
                                    label="Search friends here..."
                                    variant="outlined"
                                />
                            }
                        />
                    </div>
                    <hr />
                    <div className='friendsAllFollow'>
                        {
                            selectedUserData !== null ? (
                                <>
                                    <FindToFollowTeachersNFriendsCard
                                        userId={userId}
                                        userPathCollection={userPathCollection}
                                        userCurrentStatus={userCurrentStatus}
                                        friendPathCollection={selectedUserData?.USER_NAME}
                                        friendImage={selectedUserData?.USER_DISPLAY_PIC}
                                        friendId={searchOptionId}
                                        friendESchoolStatus={selectedUserData?.USER_CURRENT_STATUS}
                                    />
                                </>
                            ) : (
                                <div className='showMoreSchools'>
                                    <div className='friendsAllFollow'>
                                        {
                                            findToFollowFriendsSet.map((listData, i) => (
                                                <FindToFollowTeachersNFriendsCard
                                                    key={i}
                                                    userId={userId}
                                                    userPathCollection={userPathCollection}
                                                    userCurrentStatus={userCurrentStatus}
                                                    friendPathCollection={listData?.friendPathCollection}
                                                    friendImage={listData?.friendImage}
                                                    friendId={listData?.friendId}
                                                    friendESchoolStatus={listData?.friendESchoolStatus}
                                                />
                                            ))
                                        }
                                    </div>
                                    {
                                        totalFriends !== findToFollowFriendsSet.length ? (
                                            <div className='ButtonSeeMore'>
                                                <Button onClick={(e) => { e.preventDefault(); loadMoreFriends(findToFollowFriendsSet.length) }} disabled={btndisabled}>show more..</Button>
                                            </div>
                                        ) : (
                                            <div className='noMoreSchools'>
                                                <h4>No more friends to show...</h4>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }

                    </div>
                </div>
            </BootstrapDialog>
        </div>
    )
}

export default FindToFollowTeachersNFriends