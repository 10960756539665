import React, { useEffect, useState } from 'react'
import '../Pages/SchoolAccountProfile.css'
import Login from './Login'
import Header from '../Components/Header/Header'
import SchoolAccountTop from '../Components/Social/SchoolAccountTop'
import SchoolProfileSidebar from '../Components/Social/SchoolProfileSidebar'
import HomePageFeed from '../Components/Social/HomePageFeed'
import SchoolAbout from '../Components/Social/SchoolAbout'
import UserPhotos from '../Components/Social/UserPhotos'
import SchoolAccountFollowing from '../Components/Following/SchoolAccountFollowing'

import dB, { auth } from '../firebase'
import { doc, onSnapshot } from 'firebase/firestore'
import { onAuthStateChanged } from 'firebase/auth'

import { AppBar, Box, Tab, Tabs, Typography } from '@mui/material'
import PropTypes from 'prop-types';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function SchoolAccountProfile() {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        event.preventDefault()
        setValue(newValue);
    };
    const [schoolId, setschoolId] = useState('')
    const [schoolDetails, setschoolDetails] = useState(null)
    const [schoolRegInfo, setschoolRegInfo] = useState(null)
    const [schoolInfoData, setschoolInfoData] = useState(null)
    useEffect(() => {
        const unSubscribe = onAuthStateChanged(auth, (userCredentials) => {
            if (userCredentials) {
                // var uid = userCredentials.uid;
                setschoolId(userCredentials.uid)
                onSnapshot(doc(dB, "SCHOOLS", userCredentials.uid), async (schlDoc) => {
                    if (schlDoc.exists()) {
                        await setschoolInfoData(schlDoc.data())
                    }
                })
            }
        });
        return () => { unSubscribe(); }
    }, [])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolInfoData) {
                onSnapshot(doc(dB, "SCHOOLS", schoolId, schoolInfoData?.SCHOOL_NAME, "REGISTRATION_INFO"), async (regDoc) => {
                    if (regDoc.exists()) {
                        await setschoolRegInfo(regDoc.data())
                    }
                })
                onSnapshot(doc(dB, "SCHOOLS", schoolId, schoolInfoData?.SCHOOL_NAME, "SCHOOL_DETAILS"), async (schlDoc) => {
                    if (schlDoc.exists()) {
                        await setschoolDetails(schlDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [schoolId, schoolInfoData])

    return (
        <>
            {
                schoolId === '' ? (
                    <Login />
                ) : (
                    <div className='schoolaccount'>
                        <Header />
                        <div className='schoolaccount__body'>
                            <div className='schoolaccount__bodyTop'>

                                <SchoolAccountTop
                                    schoolId={schoolId}
                                    schoolPathCollection={schoolInfoData?.SCHOOL_NAME}
                                    userDP={schoolInfoData?.USER_DISPLAY_PIC}
                                    schoolFullName={schoolInfoData?.SCHOOL_FULL_NAME}
                                    schoolAffiliation={schoolRegInfo?.SCHOOL_AFFILIATION}
                                    schoolPhone={schoolRegInfo?.SCHOOL_PHONE_NO}
                                    schoolAbout={schoolDetails?.SCHOOL_ABOUT}
                                    schoolAchievements={schoolDetails?.SCHOOL_ACHIEVEMENTS}
                                    schoolAddres={schoolDetails?.SCHOOL_ADDRESS}
                                    schoolCity={schoolDetails?.SCHOOL_CITY}
                                    schoolDistrict={schoolDetails?.SCHOOL_DISTRICT}
                                    schoolEstablished={schoolDetails?.SCHOOL_ESTABLISHMENT}
                                    schoolfacilities={schoolDetails?.SCHOOL_FACILITIES}
                                    schoolMission={schoolDetails?.SCHOOL_MISSION}
                                    schoolPinCode={schoolDetails?.SCHOOL_PINCODE}
                                    schoolRestAddres={schoolDetails?.SCHOOL_REST_ADDRESS}
                                    schoolState={schoolDetails?.SCHOOL_STATE}
                                    schooltehsil={schoolDetails?.SCHOOL_TEHSIL}
                                    schoolVillage={schoolDetails?.SCHOOL_VILLAGE}
                                    schoolVision={schoolDetails?.SCHOOL_VISION}
                                    schoolAddressLine1={schoolDetails?.SCHOOL_ADDRESS_LINE1}
                                    schoolAddressLine2={schoolDetails?.SCHOOL_ADDRESS_LINE2}

                                    schoolInitName={schoolRegInfo?.SCHOOL_NAME}
                                />
                            </div>
                            <div className='schoolaccount__bodyBottom'>
                                <Box id='schoolprofile__bodyTopNavbarAppBar' sx={{ flexGrow: 1, backgroundColor: 'var(--connected-background)' }}>
                                    <AppBar color='inherit' position="static">
                                        <Tabs indicatorColor='primary' textColor='primary' className='tab' value={value} onChange={handleChange} centered>
                                            <Tab sx={{ fontSize: '16px', fontWeight: '600', width: "150px", color: 'black' }} label="Timeline" {...a11yProps(0)} />
                                            <Tab sx={{ fontSize: '16px', fontWeight: '600', width: "150px", color: 'black' }} label="About" {...a11yProps(1)} />
                                            <Tab sx={{ fontSize: '16px', fontWeight: '600', width: "150px", color: 'black' }} label="Photos" {...a11yProps(2)} />
                                            <Tab sx={{ fontSize: '16px', fontWeight: '600', width: "150px", color: 'black' }} label="Following" {...a11yProps(3)} />
                                        </Tabs>
                                    </AppBar>
                                    <TabPanel value={value} index={0}>
                                        <div id="feed" className='schoolprofile__bodyBottom'>
                                            <div className='schoolprofile__bodyBottomLeft'>
                                                <SchoolProfileSidebar
                                                    schoolPhone={schoolRegInfo?.SCHOOL_PHONE_NO}
                                                    schoolAbout={schoolDetails?.SCHOOL_ABOUT}
                                                    schoolEstablished={schoolDetails?.SCHOOL_ESTABLISHMENT}
                                                    schoolRestAddres={schoolDetails?.SCHOOL_REST_ADDRESS}
                                                    schoolAffiliation={schoolRegInfo?.SCHOOL_AFFILIATION}
                                                    schoolMission={schoolDetails?.SCHOOL_MISSION}
                                                    schoolVision={schoolDetails?.SCHOOL_VISION}
                                                />
                                            </div>
                                            <div className='schoolprofile__bodyBottomRight'>

                                                <HomePageFeed
                                                    userId={schoolId}
                                                    userFullName={schoolInfoData?.SCHOOL_FULL_NAME}
                                                    userPathCollection={schoolInfoData?.SCHOOL_NAME}
                                                    userCurrentStatus={'SCHOOL'}
                                                    userDP={schoolInfoData?.USER_DISPLAY_PIC}

                                                    friendId={null}
                                                    friendFullName={null}
                                                    friendPathCollection={null}
                                                    friendCurrentStatus={null}
                                                    friendDP={null}

                                                    MainHomePage={false}
                                                />
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <div id="about" className='schoolprofile__bodyBottom'>
                                            <SchoolAbout
                                                schoolVision={schoolDetails?.SCHOOL_VISION}
                                                schoolMission={schoolDetails?.SCHOOL_MISSION}
                                                schoolRestAddres={schoolDetails?.SCHOOL_REST_ADDRESS}
                                                schoolPhone={schoolRegInfo?.SCHOOL_PHONE_NO}
                                                schoolEmail={schoolInfoData?.SCHOOL_EMAIL}
                                                schoolEstablished={schoolDetails?.SCHOOL_ESTABLISHMENT}
                                                schoolfacilities={schoolDetails?.SCHOOL_FACILITIES}
                                                schoolAbout={schoolDetails?.SCHOOL_ABOUT}
                                                schoolAchievements={schoolDetails?.SCHOOL_ACHIEVEMENTS}
                                                schoolId={schoolId}
                                                schoolPathCollection={schoolInfoData?.SCHOOL_NAME}
                                            />
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <div id="friends" className='schoolprofile__bodyBottom'>
                                            <UserPhotos
                                                userId={schoolId}
                                                userPathCollection={schoolInfoData?.SCHOOL_NAME}
                                                userCurrentStatus='SCHOOL'
                                                friendId={null}
                                                friendCurrentStatus={null}
                                                friendPathCollection={null}
                                            />
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={3}>
                                        <div id="photos" className='friendsprofile__bodyBottom'>
                                            <SchoolAccountFollowing
                                                schoolId={schoolId}
                                                schoolPathCollection={schoolInfoData?.SCHOOL_NAME}
                                            />
                                        </div>
                                    </TabPanel>
                                </Box>
                            </div>
                        </div>
                    </div>
                )
            }
        </>

    )
}

export default SchoolAccountProfile
