import React, { useEffect, useState } from 'react'
import '../Social/HomePagePosts.css'
import { deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import { Link } from 'react-router-dom'

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Avatar, Box, Button, Modal, Popover } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function FriendsComment(props) {

    const { commentText, commentId, commentorId, commentorStatus, commentTimestamp,
        friendId, friendPathCollection, friendCurrentStatus, friendFullName,
        userId, userPathCollection, userCurrentStatus, postId } = props

    // open yes/no modal for deleting post
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = (e) => { setOpenModal(true); };
    const handleCloseModal = (e) => { setOpenModal(false); };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClose = () => { setAnchorEl(null); };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [btnLoading, setbtnLoading] = useState(false)
    const [commentData, setcommentData] = useState({ commentorPic: '', commentorRegName: '', commentorPathCollection: '' })
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (commentorId && commentorStatus) {
                if (commentorStatus === 'SCHOOL') {
                    onSnapshot(doc(dB, "SCHOOLS", commentorId), (snap) => {
                        if (snap.exists()) {
                            setcommentData(prev => { return { ...prev, commentorPathCollection: snap.data()?.SCHOOL_NAME, commentorPic: snap.data()?.USER_DISPLAY_PIC } })
                        }
                    })
                } else {
                    onSnapshot(doc(dB, "USERS", commentorId), (snap) => {
                        if (snap.exists()) {
                            setcommentData(prev => { return { ...prev, commentorPathCollection: snap.data()?.USER_NAME, commentorPic: snap.data()?.USER_DISPLAY_PIC } })
                        }
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [commentorId, commentorStatus])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (commentorId && commentorStatus && commentData.commentorPathCollection) {
                if (commentorStatus === 'SCHOOL') {
                    onSnapshot(doc(dB, "SCHOOLS", commentorId, commentData.commentorPathCollection, "REGISTRATION_INFO"), (snap) => {
                        if (snap.exists()) {
                            setcommentData(prev => { return { ...prev, commentorRegName: snap.data()?.SCHOOL_FULL_NAME } })
                        }
                    })
                } else {
                    onSnapshot(doc(dB, "USERS", commentorId, commentData.commentorPathCollection, "REGISTRATION_INFO"), (snap) => {
                        if (snap.exists()) {
                            setcommentData(prev => { return { ...prev, commentorRegName: snap.data()?.USER_NAME } })
                        }
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [commentData.commentorPathCollection, commentorId, commentorStatus])

    const handleClickDeleteComment = (e) => {
        e.preventDefault();
        setbtnLoading(true)
        if (friendId) {
            if (friendCurrentStatus === 'SCHOOL') {
                deleteDoc(doc(dB, "SCHOOLS", friendId, friendPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS", commentId))
                    .then(() => {
                        setbtnLoading(false)
                        setOpenModal(false)
                        setAnchorEl(null)
                    }).catch(err => alert(err.message))
            } else {
                deleteDoc(doc(dB, "USERS", friendId, friendPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS", commentId))
                    .then(() => {
                        setbtnLoading(false)
                        setOpenModal(false)
                        setAnchorEl(null)
                    }).catch(err => alert(err.message))
            }
        } else {
            if (userCurrentStatus === 'SCHOOL') {
                deleteDoc(doc(dB, "SCHOOLS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS", commentId))
                    .then(() => {
                        setbtnLoading(false)
                        setOpenModal(false)
                        setAnchorEl(null)
                    }).catch(err => alert(err.message))
            } else {
                deleteDoc(doc(dB, "USERS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS", commentId))
                    .then(() => {
                        setbtnLoading(false)
                        setOpenModal(false)
                        setAnchorEl(null)
                    }).catch(err => alert(err.message))
            }
        }

    }

    return (
        <div className='friendsComment'>
            <div className='friendsCommentNameAvatar'>
                <Link to={commentorStatus === 'SCHOOL' ? `/schoolfriendprofile/${commentorId}` : `/friendsprofile/${commentorId}`} >
                    <Avatar
                        src={commentData.commentorPic}
                        alt={commentData.commentorRegName}
                        // className={classes.Avatar}
                        sx={{ width: 30, height: 30 }}
                    />
                </Link>
                <div className='friendsCommentText'>
                    <p>
                        <Link to={commentorStatus === 'SCHOOL' ? `/schoolfriendprofile/${commentorId}` : `/friendsprofile/${commentorId}`} style={{ textDecoration: 'none', color: 'black' }}>
                            <strong className='commentoRName'>{commentData.commentorRegName}</strong>
                        </Link>
                        <span></span>
                        <span style={{ fontSize: '11px', fontWeight: '400', color: 'gray' }}> ({commentTimestamp?.toDate().toDateString()})</span>
                        <br />{commentText}
                    </p>
                </div>
            </div>
            {
                (commentorId === userId || !friendId) && (
                    <div className='commentDelete' onClick={handleClick}>
                        <MoreVertIcon />
                    </div>
                )
            }

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className='btnDeleteComment'>
                    <Button onClick={handleOpenModal}>Delete Comment</Button>
                </div>
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className='deletePostModal'>
                            <div className='deletePostModalText'>
                                <h4>Do you really want to delete this post?</h4>
                            </div>
                            <div className='deletePostModalButtons'>
                                <LoadingButton
                                    variant='outlined'
                                    onClick={handleClickDeleteComment}
                                    loading={btnLoading}
                                >
                                    Yes
                                </LoadingButton>
                                <Button onClick={handleCloseModal}>No</Button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </Popover>

        </div>
    )
}

export default FriendsComment
