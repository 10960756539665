import React, { useEffect, useState } from 'react'
import '../Pages/CreateNewAccount.css'
import CreateNewAccountInfo from '../Components/CreateNewAccount/CreateNewAccountInfo';
import { Link } from 'react-router-dom'
import {
    Box, Button, FormControl, FormControlLabel, FormHelperText, FormLabel,
    IconButton, InputAdornment, InputLabel, OutlinedInput, Radio, RadioGroup, TextField
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function CreateNewAccount() {

    const [textInputsUser, setTextInputsUser] = useState({
        inputFName: '', inputLName: '', inputEmailId: '', inputMobileNo: '', birthDate: ''
    })
    const handleChangeInputsUser = (event) => {
        event.preventDefault();
        setTextInputsUser(prev => { return { ...prev, [event.target.name]: event.target.value } });
        setshowEntered(prev => { return { ...prev, [event.target.name]: { error: false, helperText: '' } } })
    }


    const [textInputsPwd, setTextInputsPwd] = useState({ newUserPassword: '', confirmUserPassword: '', })
    const handleChangeInputsPwd = (event) => {
        event.preventDefault();
        setTextInputsPwd(prev => { return { ...prev, [event.target.name]: event.target.value } });
        setshowEntered(prev => { return { ...prev, [event.target.name]: { error: false, helperText: '' } } })
    }

    const handleChangeDOB = (event) => {
        event.preventDefault();
        setshowEntered(prev => { return { ...prev, studentSelectDOB: '' } })
        setTextInputsUser(prev => { return { ...prev, birthDate: event.target.value } })
    }
    const [showNewUserPassword, setShowNewUserPassword] = useState(false)
    const handleClickShowNewUserPassword = (event) => { event.preventDefault(); setShowNewUserPassword(!showNewUserPassword); };
    const handleMouseDownNewUserPassword = (event) => { event.preventDefault(); };

    const [showConfirmUserPassword, setshowConfirmUserPassword] = useState(false)
    const handleClickShowConfirmUserPassword = (event) => { event.preventDefault(); setshowConfirmUserPassword(!showConfirmUserPassword); };
    const handleMouseDownConfirmUserPassword = (event) => { event.preventDefault(); };

    const [userGender, setuserGender] = useState('')
    const handleGenderValueChange = (event) => {
        event.preventDefault();
        setuserGender(event.target.value);
        setshowEntered(prev => { return { ...prev, studentSelectGender: '' } })
    };

    const [showEntered, setshowEntered] = useState({
        inputFName: { error: false, helperText: '' },
        inputLName: { error: false, helperText: '' },
        inputEmailId: { error: false, helperText: '' },
        inputMobileNo: { error: false, helperText: '' },
        newUserPassword: { error: false, helperText: '' },
        confirmUserPassword: { error: false, helperText: '' },
        studentSelectDOB: '',
        studentSelectGender: '',
    })

    const [btnDisabled, setbtnDisabled] = useState(false)

    const [showBlock, setshowBlock] = useState({ showCreateNewAccBlock: true, showSpecifyYouBlock: false })

    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            let date = document.getElementById('birthdate');
            const birthDate = date.value
            setTextInputsUser(prev => { return { ...prev, birthDate: birthDate } })
        }
        return () => { aaa = false }
    }, [])

    const handleCreateAccount = (event) => {
        setbtnDisabled(true)
        event.preventDefault();

        if (textInputsUser.inputFName && textInputsUser.inputLName && textInputsUser.inputEmailId && textInputsPwd.newUserPassword && textInputsPwd.confirmUserPassword && textInputsUser.birthDate && userGender) {
            if (textInputsPwd.newUserPassword.length > 7 && textInputsPwd.newUserPassword === textInputsPwd.confirmUserPassword && /\S+@\S+\.\S+/.test(textInputsUser.inputEmailId)) {
                if (textInputsUser.inputMobileNo.length && textInputsUser.inputMobileNo.length === 10) {
                    setshowBlock(prev => { return { ...prev, showCreateNewAccBlock: false, showSpecifyYouBlock: true } })
                }
                else if (textInputsUser.inputMobileNo.length && textInputsUser.inputMobileNo.length !== 10) {
                    setshowEntered(prev => { return { ...prev, inputMobileNo: { error: true, helperText: 'Mobile Number must be of 10 digit.' } } })
                } else {
                    setshowBlock(prev => { return { ...prev, showCreateNewAccBlock: false, showSpecifyYouBlock: true } })
                }
            } else {
                if (!(textInputsPwd.newUserPassword.length > 7)) {
                    setshowEntered(prev => { return { ...prev, newUserPassword: { error: true, helperText: 'Password must contain at least 8 characters' } } })
                }
                if (textInputsPwd.newUserPassword !== textInputsPwd.confirmUserPassword) {
                    setshowEntered(prev => { return { ...prev, confirmUserPassword: { error: true, helperText: 'password and confirm password must match' } } })
                }

                if (/\S+@\S+\.\S+/.test(textInputsUser.inputEmailId)) { }
                else { setshowEntered(prev => { return { ...prev, inputEmailId: { error: true, helperText: 'Please enter a valid email-id in the format of xyz@email.com' } } }) }

                setbtnDisabled(false)
            }
        } else {
            if (!textInputsUser.inputFName) { setshowEntered(prev => { return { ...prev, inputFName: { error: true, helperText: 'Please enter your first name' } } }) }
            if (!textInputsUser.inputLName) { setshowEntered(prev => { return { ...prev, inputLName: { error: true, helperText: 'Please enter your last name' } } }) }
            if (!textInputsUser.inputEmailId) { setshowEntered(prev => { return { ...prev, inputEmailId: { error: true, helperText: 'Please enter your Email-Id.' } } }) }
            if (!textInputsUser.birthDate) { setshowEntered(prev => { return { ...prev, studentSelectDOB: 'show' } }) }
            if (!textInputsPwd.newUserPassword) { setshowEntered(prev => { return { ...prev, newUserPassword: { error: true, helperText: 'Please enter a password' } } }) }
            if (!textInputsPwd.confirmUserPassword) { setshowEntered(prev => { return { ...prev, confirmUserPassword: { error: true, helperText: 'Please confirm your password' } } }) }
            if (!userGender) { setshowEntered(prev => { return { ...prev, studentSelectGender: 'show' } }) }

            setbtnDisabled(false)
        }
    }


    return (
        <>
            <div style={{ display: showBlock.showCreateNewAccBlock ? 'grid' : 'none' }} className='createnewaccount'>
                <form className='createnewaccount__container' onSubmit={handleCreateAccount}>
                    <h1>ConnectEdApp</h1>
                    <p>Create a New User Account</p>
                    <hr />
                    <div className='createnewaccount__name'>
                        <div className='h5__FName'>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '36ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div>
                                    <TextField
                                        error={showEntered.inputFName.error}
                                        name='inputFName'
                                        id="inputFName"
                                        type='text'
                                        label="First Name"
                                        // placeholder='E-mail Id'
                                        value={textInputsUser.inputFName}
                                        onChange={handleChangeInputsUser}
                                        helperText={showEntered.inputFName.helperText}
                                    />
                                </div>
                            </Box>

                        </div>
                        <div className='h5__LName'>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '36ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div>
                                    <TextField
                                        error={showEntered.inputLName.error}
                                        name='inputLName'
                                        id="inputLName"
                                        type='text'
                                        label="Last Name"
                                        // placeholder='E-mail Id'
                                        value={textInputsUser.inputLName}
                                        onChange={handleChangeInputsUser}
                                        helperText={showEntered.inputLName.helperText}
                                    />
                                </div>
                            </Box>


                        </div>
                    </div>
                    <div className='createnewaccount__emailmob'>
                        <div className='h5__Email'>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '74ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div>
                                    <TextField
                                        error={showEntered.inputEmailId.error}
                                        name='inputEmailId'
                                        id="inputEmailId"
                                        type='text'
                                        label="Email-Id"
                                        // placeholder='E-mail Id'
                                        value={textInputsUser.inputEmailId}
                                        onChange={handleChangeInputsUser}
                                        helperText={showEntered.inputEmailId.helperText}
                                    />
                                </div>
                            </Box>


                        </div>
                        <div className='h5__mobile'>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '74ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div>
                                    <TextField
                                        error={showEntered.inputMobileNo.error}
                                        name='inputMobileNo'
                                        id="inputMobileNo"
                                        type='number'
                                        label="Mobile No. (Optional)"
                                        // placeholder='E-mail Id'
                                        value={textInputsUser.inputMobileNo}
                                        onChange={handleChangeInputsUser}
                                        helperText={showEntered.inputMobileNo.helperText}
                                    />
                                </div>
                            </Box>

                        </div>
                        <div className='createnewaccount__passwords'>
                            <div className='h5__password'>
                                <FormControl error={showEntered.newUserPassword.error} sx={{ m: 1, width: '36ch' }} variant="outlined">
                                    <InputLabel htmlFor="newUserPassword">Enter New Password</InputLabel>
                                    <OutlinedInput
                                        name='newUserPassword'
                                        id="newUserPassword"
                                        type={showNewUserPassword ? 'text' : 'password'}
                                        value={textInputsPwd.newUserPassword}
                                        onChange={handleChangeInputsPwd}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowNewUserPassword}
                                                    onMouseDown={handleMouseDownNewUserPassword}
                                                    edge="end"
                                                >
                                                    {showNewUserPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Enter New Password"
                                    />
                                    <FormHelperText id="NewUserPassword"><p>{showEntered.newUserPassword.helperText}</p></FormHelperText>
                                </FormControl>

                            </div>
                            <div className='h5__passwordConfrim'>
                                <FormControl error={showEntered.confirmUserPassword.error} sx={{ m: 1, width: '36ch' }} variant="outlined">
                                    <InputLabel htmlFor="confirmUserPassword">Confirm Password</InputLabel>
                                    <OutlinedInput
                                        name='confirmUserPassword'
                                        id="confirmUserPassword"
                                        type={showConfirmUserPassword ? 'text' : 'password'}
                                        value={textInputsPwd.confirmUserPassword}
                                        onChange={handleChangeInputsPwd}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmUserPassword}
                                                    onMouseDown={handleMouseDownConfirmUserPassword}
                                                    edge="end"
                                                >
                                                    {showConfirmUserPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Confirm Password"
                                    />
                                    <FormHelperText id="confirmUserPassword"><p>{showEntered.confirmUserPassword.helperText}</p></FormHelperText>
                                </FormControl>

                            </div>
                        </div>
                    </div>
                    <div className='h5__DOB'>
                        <h5 style={{ display: showEntered.studentSelectDOB === 'show' ? 'block' : 'none' }} id='student__SelectDOB'>Please select your Date of Birth</h5>
                        <div className='createnewaccount__birthdate' onChange={handleChangeDOB}>
                            <h5>Date of Birth : </h5>
                            <input type='date' id="birthdate" name="birthdate" />
                        </div>
                    </div>
                    <div className='h5__Gender'>
                        <h5 style={{ display: showEntered.studentSelectGender === 'show' ? 'block' : 'none' }} id='student__SelectGender'>Please select your appropriate Gender</h5>
                        <FormControl>
                            <Box sx={{ width: '300px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontSize: '18px', fontWeight: '600', color: 'var(--connected-color)' }}>Gender</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={userGender} onChange={handleGenderValueChange}
                                >
                                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                                </RadioGroup>
                            </Box>
                        </FormControl>
                    </div>
                    <div className='createnewaccount__containerButton'>
                        <Button onClick={handleCreateAccount} disabled={btnDisabled}>Continue</Button>
                    </div>
                    <Link to='/loginpage' style={{ textDecoration: 'none' }}>
                        <div className='createnewaccount__containerh4'>
                            <h4>Login to ConnectEdApp</h4>
                        </div>
                    </Link>
                </form>
            </div>

            <div style={{ display: showBlock.showSpecifyYouBlock ? 'grid' : 'none' }}>
                <CreateNewAccountInfo
                    fName={textInputsUser.inputFName}
                    lName={textInputsUser.inputLName}
                    userFullName={textInputsUser.inputFName + ' ' + textInputsUser.inputLName}
                    userMobNum={textInputsUser.inputMobileNo}
                    userPwd={textInputsPwd.confirmUserPassword}
                    userGender={userGender}
                    userDOB={textInputsUser.birthDate}
                    userEmailId={textInputsUser.inputEmailId}
                />
            </div>
        </>
    )
}

export default CreateNewAccount