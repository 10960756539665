import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, } from "react-router-dom";
import Login from './Pages/Login';
import AllMessages from './Pages/AllMessages';
import SchoolUpcomingEvents from './Pages/SchoolUpcomingEvents';
import HowToUse from './Pages/HowToUse';
import SidebarNoticeBoard from './Pages/SidebarNoticeBoard';
import FindToFollow from './Pages/FindToFollow';
import SchoolAccountProfile from './Pages/SchoolAccountProfile';
import SchoolAddOrRemove from './Pages/SchoolAddOrRemove';
import SchoolFriendProfile from './Pages/SchoolFriendProfile';
import UserProfile from './Pages/UserProfile';
import FriendsProfile from './Pages/FriendsProfile';
import UserNoticeBoard from './Pages/UserNoticeBoard';
import SidebarHomeWorks from './Pages/SidebarHomeWorks';
import MainHomePage from './Pages/MainHomePage';
import SchoolVerificationRequests from './Pages/SchoolVerificationRequests';
import SchoolVerifiedMembers from './Pages/SchoolVerifiedMembers';
import SidebarESchoolingStatus from './Pages/SidebarESchoolingStatus';
import CreateNewAccount from './Pages/CreateNewAccount';
import CreateSchoolAccountSchoolDetails from './Pages/CreateSchoolAccountSchoolDetails';
import CreateSchoolAccount from './Pages/CreateSchoolAccount';
import Subscription from './Pages/Subscription';
// import SchoolLeaveApplications from './Pages/SchoolLeaveApplications';
import SchoolTimeTable from './Pages/SchoolTimeTable';
import SchoolSyllabusCovered from './Pages/SchoolSyllabusCovered';
// import SchoolResults from './Pages/SchoolResults';
import SchoolComplaints from './Pages/SchoolComplaints';
import SchoolLeaveApplications from './Pages/SchoolLeaveApplications';
import ClassTeacherAllocate from './Pages/ClassTeacherAllocate';

function App() {

  return (
    <Router>
      <div className="app">
        <Switch>
          <Route exact path='/loginpage' component={Login} />
          <Route exact path='/messages' component={AllMessages} />
          <Route exact path='/upcomingevents' component={SchoolUpcomingEvents} />
          <Route exact path='/howtouse' component={HowToUse} />
          <Route exact path='/noticeboard' component={SidebarNoticeBoard} />
          <Route exact path='/findtofollow' component={FindToFollow} />
          <Route exact path='/schoolsselfprofile' component={SchoolAccountProfile} />
          <Route exact path='/addorremove' component={SchoolAddOrRemove} />
          <Route exact path='/schoolfriendprofile/:schoolFriendHandleId' component={SchoolFriendProfile} />
          <Route exact path='/friendsprofile/:friendsHandleId' component={FriendsProfile} />
          <Route exact path='/userprofile' component={UserProfile} />
          <Route exact path='/usernoticeboard' component={UserNoticeBoard} />
          <Route exact path='/projects&homeworks' component={SidebarHomeWorks} />
          <Route exact path='/' component={MainHomePage} />
          <Route exact path='/user' component={MainHomePage} />
          <Route exact path='/schoolaccountprofile' component={MainHomePage} />
          <Route exact path='/verificationrequests' component={SchoolVerificationRequests} />
          <Route exact path='/verifiedmembers' component={SchoolVerifiedMembers} />
          <Route exact path='/eschoolingstatus' component={SidebarESchoolingStatus} />
          <Route exact path='/createuseraccount' component={CreateNewAccount} />
          <Route exact path='/createschoolaccount' component={CreateSchoolAccountSchoolDetails} />
          <Route exact path='/createschoolaccount/selectclasses' component={CreateSchoolAccount} />

          <Route exact path='/schooltimetable' component={SchoolTimeTable} />
          <Route exact path='/schoolcomplaints' component={SchoolComplaints} />
          <Route exact path='/syllabuscovered' component={SchoolSyllabusCovered} />
          <Route exact path='/leaveapplications' component={SchoolLeaveApplications} />
          <Route exact path='/classteacher' component={ClassTeacherAllocate} />


          <Route exact path='/subscription' component={Subscription} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
