import React, { useEffect, useState } from 'react';
import '../Header/Header.css';
import HeaderSentRequestCard from './HeaderSentRequestCard';
import RecievedRequest from './RecievedRequest';
import HeaderMessages from './HeaderMessages';

import { Link, useHistory } from 'react-router-dom'
import dB, { auth } from '../../firebase';
import { collection, doc, getDocs, limit, onSnapshot, orderBy, query, startAfter, updateDoc } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';

import HomeIcon from '@mui/icons-material/Home';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MessageIcon from '@mui/icons-material/Message';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { Avatar, Badge, Button, ClickAwayListener, Dialog, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { FormControl, FormHelperText } from '@mui/material'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

const dropdown = {
    width: '270px',
    justifyContent: 'center',
    position: 'absolute',
    top: 50,
    right: 0,
    left: 5,
    zIndex: 1,
    border: '1px solid gray',
    borderRadius: '15px',
    padding: 1,
    backgroundColor: 'rgb(241, 241, 241)',
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
};
const dropdownReq = {
    position: 'absolute',
    top: 62,
    left: -130,
    zIndex: 1,
    border: '1px solid lightgray',
    borderRadius: '15px',
    padding: 1,
    width: '400px',
    minHeight: '100px',
    maxHeight: '500px',
    backgroundColor: 'white',
    boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)',
    overflowY: 'auto',
}
const dropdownMsg = {
    position: 'absolute',
    top: 62,
    left: -230,
    zIndex: 1,
    border: '1px solid lightgray',
    borderRadius: '15px',
    padding: 1,
    width: '400px',
    minHeight: '100px',
    maxHeight: '500px',
    backgroundColor: 'white',
    boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)',
    overflowY: 'auto',
    overflowX: 'none',
    margin: '0 1px 0 0',
}
const dropdownAvatar = {
    position: 'absolute',
    top: 62,
    left: -330,
    zIndex: 1,
    border: '1px solid lightgray',
    borderRadius: '15px',
    padding: 1,
    width: '400px',
    minHeight: '100px',
    maxHeight: '500px',
    backgroundColor: 'white',
    boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)',
    overflowY: 'auto',
    overflowX: 'none',
    margin: '0 1px 0 0',
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const root = { position: 'relative', }

function Header() {

    const [userData, setuserData] = useState({
        userId: '', userCurrentStatus: '', userPathCollection: '', userFullName: '', userDP: null,
    })
    useEffect(() => {
        const unSubscribe = onAuthStateChanged(auth, userCredentials => {
            if (userCredentials) {
                onSnapshot(doc(dB, "USERS", userCredentials.uid), (userDoc) => {
                    if (userDoc.exists()) {
                        setuserData(prev => {
                            return {
                                ...prev,
                                userId: userCredentials.uid,
                                userCurrentStatus: userDoc.data()?.USER_CURRENT_STATUS,
                                userPathCollection: userDoc.data()?.USER_NAME,
                                userDP: userDoc.data()?.USER_DISPLAY_PIC
                            }
                        })
                    } else {
                        onSnapshot(doc(dB, "SCHOOLS", userCredentials.uid), (schoolDoc) => {
                            if (schoolDoc.exists()) {
                                setuserData(prev => {
                                    return {
                                        ...prev,
                                        userId: userCredentials.uid,
                                        userCurrentStatus: schoolDoc.data()?.USER_STATUS,
                                        userPathCollection: schoolDoc.data()?.SCHOOL_NAME,
                                        userFullName: schoolDoc.data()?.SCHOOL_FULL_NAME,
                                        userDP: schoolDoc.data()?.USER_DISPLAY_PIC
                                    }
                                })
                            }
                        })
                    }
                })
            }
        });
        return () => { unSubscribe(); }
    }, [])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userCurrentStatus !== 'SCHOOL') {
                if (userData.userId && userData.userPathCollection) {
                    onSnapshot(doc(dB, "USERS", userData.userId, userData.userPathCollection, "REGISTRATION_INFO"), (regDoc) => {
                        if (regDoc.exists()) {
                            setuserData(prev => { return { ...prev, userFullName: regDoc.data()?.USER_NAME, } })
                        }
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [userData.userCurrentStatus, userData.userId, userData.userPathCollection])

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const [opensent, setOpensent] = useState(false);
    const handleClickOpenSent = (e) => { setOpensent(true); };
    const handleCloseSent = (e) => { setOpensent(false); };

    // const classes = useStyles();
    const history = useHistory();

    const [openTab, setopenTab] = useState({ reqOpen: false, msgOpen: false, notiOpen: false, avatarOpen: false, })
    const handleClickReq = (e) => { setopenTab(prevState => { return { ...prevState, reqOpen: !openTab.reqOpen } }) };
    const handleClickAwayReq = (e) => { setopenTab(prevState => { return { ...prevState, reqOpen: false } }) };
    const handleClickMsg = (e) => { setopenTab(prevState => { return { ...prevState, msgOpen: !openTab.msgOpen } }) };
    const handleClickAwayMsg = (e) => { setopenTab(prevState => { return { ...prevState, msgOpen: false } }) };
    const handleClickAvatar = (e) => { setopenTab(prevState => { return { ...prevState, avatarOpen: !openTab.avatarOpen } }) };
    const handleClickAwayAvatar = (e) => { setopenTab(prevState => { return { ...prevState, avatarOpen: false } }) };

    // recieved requests
    const [requestsRecieved, setrequestsRecieved] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userId && userData.userPathCollection) {
                onSnapshot(collection(dB, "USERS", userData.userId, userData.userPathCollection, "REQUESTS_RECIEVED", "REQUESTS_RECIEVED_IDs"), (snap) => {
                    setrequestsRecieved(snap.docs.map((userDoc) => userDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [userData.userCurrentStatus, userData.userId, userData.userPathCollection])
    const [requestsRecievedData, setrequestsRecievedData] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            requestsRecieved.forEach(reqId => {
                onSnapshot(doc(dB, "USERS", reqId), (snap) => {
                    if (snap.exists()) {
                        setrequestsRecievedData(prev => {
                            return [...prev, {
                                reqAvatar: snap.data()?.USER_DISPLAY_PIC,
                                reqId: reqId,
                                reqUserName: snap.data()?.USER_NAME
                            }]
                        })
                    }
                })
            })
        }
        return () => { aaa = false }
    }, [requestsRecieved])

    // sent requests
    const [sentRequests, setsentRequests] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userId && userData.userPathCollection) {
                onSnapshot(collection(dB, "USERS", userData.userId, userData.userPathCollection, "REQUESTS_SENT", "REQUESTS_SENT_IDs"), (snap) => {
                    setsentRequests(snap.docs.map((userDoc) => userDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [userData.userId, userData.userPathCollection])
    const [sentRecievedData, setsentRecievedData] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            sentRequests.forEach(reqId => {
                onSnapshot(doc(dB, "USERS", reqId), (snap) => {
                    if (snap.exists()) {
                        setsentRecievedData(prev => {
                            return [...prev, {
                                reqAvatar: snap.data()?.USER_DISPLAY_PIC,
                                reqId: reqId,
                                reqUserName: snap.data()?.USER_NAME,
                                reqCurrrentStatus: snap.data()?.USER_CURRENT_STATUS
                            }]
                        })
                    }
                })
            })

        }
        return () => { aaa = false }
    }, [sentRequests])


    // recieved messages
    const [allRecievedMsgs, setallRecievedMsgs] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userId && userData.userPathCollection) {
                if (userData.userCurrentStatus === 'SCHOOL') {
                    onSnapshot(collection(dB, "SCHOOLS", userData.userId, userData.userPathCollection, "MESSAGES_RECIEVED", "MESSAGES"), (snap) => {
                        setallRecievedMsgs(snap.docs.map((schlDOc) => schlDOc.id))
                    })
                } else {
                    onSnapshot(collection(dB, "USERS", userData.userId, userData.userPathCollection, "MESSAGES_RECIEVED", "MESSAGES"), (snap) => {
                        setallRecievedMsgs(snap.docs.map((userDOc) => userDOc.id))
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [userData.userCurrentStatus, userData.userId, userData.userPathCollection])

    const [messagesNotRead, setmessagesNotRead] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userId && userData.userPathCollection) {
                if (userData.userCurrentStatus === 'SCHOOL') {
                    onSnapshot(collection(dB, "SCHOOLS", userData.userId, userData.userPathCollection, "MESSAGES_RECIEVED", "MESSAGES"), (snap) => {
                        snap.docs.forEach(async (doc) => {
                            if (!doc.data()?.IS_VIEWED) { setmessagesNotRead(prev => { return [...prev, doc.id] }) }
                        })
                    })
                } else {
                    onSnapshot(collection(dB, "USERS", userData.userId, userData.userPathCollection, "MESSAGES_RECIEVED", "MESSAGES"), (snap) => {
                        snap.docs.forEach(async (doc) => {
                            if (!doc.data()?.IS_VIEWED) { setmessagesNotRead(prev => { return [...prev, doc.id] }) }
                        })
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [userData.userCurrentStatus, userData.userId, userData.userPathCollection])

    const [btndisabledRecieved, setbtndisabledRecieved] = useState(false)
    const [recivedMessagesAll, setrecivedMessagesAll] = useState([]);
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userId && userData.userPathCollection && userData.userCurrentStatus) {
                if (userData.userCurrentStatus === 'SCHOOL') {
                    onSnapshot(query(collection(dB, "SCHOOLS", userData.userId, userData.userPathCollection, "MESSAGES_RECIEVED", "MESSAGES"), orderBy('MSG_TIMESTAMP', 'desc'), limit(5)), (snap) => {
                        fetchMoreRecieved(snap)
                    })
                } else {
                    onSnapshot(query(collection(dB, "USERS", userData.userId, userData.userPathCollection, "MESSAGES_RECIEVED", "MESSAGES"), orderBy('MSG_TIMESTAMP', 'desc'), limit(5)), (snap) => {
                        fetchMoreRecieved(snap)
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [userData.userCurrentStatus, userData.userId, userData.userPathCollection])
    const [lastVisibleRecieved, setlastVisibleRecieved] = useState()
    const fetchMoreRecieved = (snap) => {
        let recievedMsgAll = snap.docs.map((msgDoc) => ({
            recievedMsgId: msgDoc.id,
            recievedMsgData: msgDoc.data(),
        }))
        setrecivedMessagesAll(prev => { return [...prev, ...recievedMsgAll] })
        var lastVisible = snap.docs[snap.docs.length - 1];
        setlastVisibleRecieved(lastVisible)
    }
    const [totalRecievedMsgs, settotalRecievedMsgs] = useState()
    const loadMoreRecieved = async (recivedMessagesAllLength) => {
        settotalRecievedMsgs(recivedMessagesAllLength)
        setbtndisabledRecieved(true)
        if (userData.userCurrentStatus === 'SCHOOL') {
            const q = query(collection(dB, "SCHOOLS", userData.userId, userData.userPathCollection, "MESSAGES_RECIEVED", "MESSAGES"), orderBy('MSG_TIMESTAMP', 'desc'), startAfter(lastVisibleRecieved), limit(5))
            const snap = await getDocs(q).catch(err => alert(err.message))
            await fetchMoreRecieved(snap)
            setbtndisabledRecieved(false)
        } else {
            const q = query(collection(dB, "USERS", userData.userId, userData.userPathCollection, "MESSAGES_RECIEVED", "MESSAGES"), orderBy('MSG_TIMESTAMP', 'desc'), startAfter(lastVisibleRecieved), limit(5))
            const snap = await getDocs(q).catch(err => alert(err.message))
            await fetchMoreRecieved(snap)
            setbtndisabledRecieved(false)
        }
    }

    // const [btnDisabled, setbtnDisabled] = useState(false)
    const [btnLoading, setbtnLoading] = useState(false)
    const handleSignOut = () => {
        setbtnLoading(true)

        signOut(auth).then(() => {
            setbtnLoading(false)
        }).catch((error) => {
            alert(error.message)
        });
        onAuthStateChanged(auth, (userCredentials) => {
            if (!userCredentials) {
                history.push('/loginpage')
            }
        });
    }




    // /////////////////////////////////////////////////////////////////////////////////

    const [userStatuses, setuserStatuses] = useState([])
    useEffect(() => {
        if (userStatuses.length > 0) {
            userStatuses.splice(0, userStatuses.length)
        }
        var aaa = true
        if (aaa === true) {
            if (userData.userId && userData.userPathCollection) {
                onSnapshot(doc(dB, "USERS", userData.userId, userData.userPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "VERIFICATION_DETAILS"), (teachDoc) => {
                    if (teachDoc.exists()) { setuserStatuses(prev => { return [...prev, teachDoc.data()?.USER_STATUS] }) }
                })
                onSnapshot(doc(dB, "USERS", userData.userId, userData.userPathCollection, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), (prnciDoc) => {
                    if (prnciDoc.exists()) { setuserStatuses(prev => { return [...prev, prnciDoc.data()?.USER_STATUS] }) }
                })
                onSnapshot(doc(dB, "USERS", userData.userId, userData.userPathCollection, "E-SCHOOLING_INFO", "IS_STUDENT", "VERIFICATION_DETAILS"), (studDoc) => {
                    if (studDoc.exists()) { setuserStatuses(prev => { return [...prev, studDoc.data()?.USER_STATUS] }) }
                })
                onSnapshot(doc(dB, "USERS", userData.userId, userData.userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "VERIFICATION_DETAILS"), (parDoc) => {
                    if (parDoc.exists()) { setuserStatuses(prev => { return [...prev, parDoc.data()?.USER_STATUS] }) }
                })
            }
        }
        return () => { aaa = false }
    }, [userData.userId, userData.userPathCollection])

    const [inputError, setinputError] = useState({ error: false, helperText: '' })
    const [selectedStatus, setselectedStatus] = useState('');
    const [showBlock, setshowBlock] = useState('')
    const handleChangeStatus = (e) => {
        e.preventDefault()
        setshowBlock('show')
    }
    const handleChangeSelectStatus = (event) => {
        event.preventDefault()
        setselectedStatus(event.target.value);
        setinputError(prev => { return { ...prev, error: false, helperText: '' } })
    };
    const handleChangeStatusUpdate = async (e) => {
        e.preventDefault()
        setbtnLoading(true)
        if (selectedStatus !== '') {
            if (selectedStatus === 'TEACHER') {
                updateDoc(doc(dB, "USERS", userData.userId), {
                    USER_CURRENT_STATUS: 'TEACHER'
                }).then(() => {
                    setshowBlock('')
                    setselectedStatus('');
                    setbtnLoading(false)
                }).catch(err => alert(err.message))
            } else
                if (selectedStatus === 'PRINCIPAL') {
                    updateDoc(doc(dB, "USERS", userData.userId), {
                        USER_CURRENT_STATUS: 'PRINCIPAL'
                    }).then(() => {
                        setshowBlock('')
                        setselectedStatus('');
                        setbtnLoading(false)
                    }).catch(err => alert(err.message))
                } else
                    if (selectedStatus === 'PARENT') {
                        updateDoc(doc(dB, "USERS", userData.userId), {
                            USER_CURRENT_STATUS: 'PARENT'
                        }).then(() => {
                            setshowBlock('')
                            setselectedStatus('');
                            setbtnLoading(false)
                        }).catch(err => alert(err.message))
                    } else if (selectedStatus === 'STUDENT') {
                        updateDoc(doc(dB, "USERS", userData.userId), {
                            USER_CURRENT_STATUS: 'STUDENT'
                        }).then(() => {
                            setshowBlock('')
                            setselectedStatus('');
                            setbtnLoading(false)
                        }).catch(err => alert(err.message))
                    }
        }
        else {
            setinputError(prev => { return { ...prev, error: true, helperText: 'Please select status' } })
        }
    }
    const handleChangeStatusUpdateCancel = (e) => {
        e.preventDefault()
        setshowBlock('')
        setinputError(prev => { return { ...prev, error: false, helperText: '' } })
        setselectedStatus('');
    }

    const [teacherSchoolData, setteacherSchoolData] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userId && userData.userPathCollection && userData.userCurrentStatus === 'TEACHER') {
                onSnapshot(doc(dB, "USERS", userData.userId, userData.userPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "VERIFICATION_DETAILS"), (teachDoc) => {
                    if (teachDoc.exists()) {
                        setteacherSchoolData(teachDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userData.userCurrentStatus, userData.userId, userData.userPathCollection])
    const [princiSchoolData, setprinciSchoolData] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userId && userData.userPathCollection && userData.userCurrentStatus === 'PRINCIPAL') {
                onSnapshot(doc(dB, "USERS", userData.userId, userData.userPathCollection, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), (princiDoc) => {
                    if (princiDoc.exists()) {
                        setprinciSchoolData(princiDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userData.userCurrentStatus, userData.userId, userData.userPathCollection])
    const [studSchoolData, setstudSchoolData] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userId && userData.userPathCollection && userData.userCurrentStatus === 'STUDENT') {
                onSnapshot(doc(dB, "USERS", userData.userId, userData.userPathCollection, "E-SCHOOLING_INFO", "IS_STUDENT", "VERIFICATION_DETAILS"), (studDoc) => {
                    if (studDoc.exists()) {
                        setstudSchoolData(studDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userData.userCurrentStatus, userData.userId, userData.userPathCollection])

    const [schoolData, setschoolData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (princiSchoolData.USER_SCHOOL_ID) {
                onSnapshot(doc(dB, "SCHOOLS", princiSchoolData?.USER_SCHOOL_ID), (princiDoc) => {
                    if (princiDoc.exists()) {
                        setschoolData(princiDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [princiSchoolData.USER_SCHOOL_ID])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (teacherSchoolData.USER_SCHOOL_ID) {
                onSnapshot(doc(dB, "SCHOOLS", teacherSchoolData?.USER_SCHOOL_ID), (princiDoc) => {
                    if (princiDoc.exists()) {
                        setschoolData(princiDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [teacherSchoolData.USER_SCHOOL_ID])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (studSchoolData.USER_SCHOOL_ID) {
                onSnapshot(doc(dB, "SCHOOLS", studSchoolData?.USER_SCHOOL_ID), (princiDoc) => {
                    if (princiDoc.exists()) {
                        setschoolData(princiDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [studSchoolData.USER_SCHOOL_ID])




    const [openChild, setOpenChild] = React.useState(false);
    const handleClickDropDown = (e) => {
        e.preventDefault()
        setOpenChild((prev) => !prev);
    };
    const handleClickDropDownAway = (e) => {
        setOpenChild(false);
    };

    const [parentChilds, setparentChilds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userId && userData.userPathCollection) {
                onSnapshot(collection(dB, "USERS", userData.userId, userData.userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO"), (snap) => {
                    setparentChilds(snap.docs.map((childDoc) => ({
                        childId: childDoc.id,
                        childData: childDoc.data(),
                    })))
                })
            }
        }
        return () => { aaa = false }
    }, [userData.userId, userData.userPathCollection])

    const [currentSelectedChildId, setcurrentSelectedChildId] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userId && userData.userPathCollection) {
                onSnapshot(doc(dB, "USERS", userData.userId, userData.userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA"), async (parDoc) => {
                    if (parDoc.exists()) { await setcurrentSelectedChildId(parDoc.data()?.CURRENT_SELECTED_CHILD) }
                })
            }
        }
        return () => { aaa = false }
    }, [userData.userId, userData.userPathCollection])

    const [selectedChildData, setselectedChildData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userId && userData.userPathCollection && currentSelectedChildId) {
                onSnapshot(doc(dB, "USERS", userData.userId, userData.userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", currentSelectedChildId), async (childDoc) => {
                    if (childDoc.exists()) {
                        await setselectedChildData(childDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [currentSelectedChildId, userData.userId, userData.userPathCollection])

    const [childsSchoolData, setchildsSchoolData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (selectedChildData?.CHILDS_SCHOOL_ID) {
                onSnapshot(doc(dB, "SCHOOLS", selectedChildData?.CHILDS_SCHOOL_ID), (schlDoc) => {
                    if (schlDoc.exists()) { setchildsSchoolData(schlDoc.data()) }
                })
            }
        }
        return () => { aaa = false }
    }, [selectedChildData?.CHILDS_SCHOOL_ID])

    // select child
    const handleClickSelectChild = async (childId) => {
        updateDoc(doc(dB, "USERS", userData.userId, userData.userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA"), {
            CURRENT_SELECTED_CHILD: childId
        }).then(() => {
            setOpenChild((prev) => !prev);
        }).catch(err => alert(err.message))
    }

    const statusOptions = (
        <div className='StatusUpdate'>
            <FormControl error={inputError.error} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <FormLabel id="selectStatus" style={{ color: 'var(--connected-orange)' }}>Select Status</FormLabel>
                <RadioGroup
                    id="selectStatus"
                    aria-labelledby="selectStatus"
                    name="selectStatus"
                    value={selectedStatus}
                    onChange={handleChangeSelectStatus}
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                >
                    {userStatuses.includes('TEACHER') && <FormControlLabel value="TEACHER" control={<Radio />} disabled={userData.userCurrentStatus === 'TEACHER' ? true : false} label="TEACHER" />}
                    {userStatuses.includes('PRINCIPAL') && <FormControlLabel value="PRINCIPAL" control={<Radio />} disabled={userData.userCurrentStatus === 'PRINCIPAL' ? true : false} label="PRINCIPAL" />}
                    {userStatuses.includes('PARENT') && <FormControlLabel value="PARENT" control={<Radio />} disabled={userData.userCurrentStatus === 'PARENT' ? true : false} label="PARENT" />}
                </RadioGroup>
                <FormHelperText id="inputFName"><p>{inputError.helperText}</p></FormHelperText>
            </FormControl>
            <div className='StatusUpdateButton'>
                <LoadingButton
                    onClick={handleChangeStatusUpdate}
                    loading={btnLoading}
                    variant="outlined"
                >
                    Update
                </LoadingButton>
                <Button onClick={handleChangeStatusUpdateCancel}>Cancel</Button>
            </div>
        </div>
    )
    const schlVerified = (
        <div className='verifiedBySchool'>
            <VerifiedUserIcon />
            <p>verified by school</p>
        </div>
    )
    const notVerified = (
        <div className='notYetVerified'>
            <p>not yet verified</p>
        </div>
    )



    return (
        <nav className='header'>
            <Link to={userData.userCurrentStatus === 'SCHOOL' ? '/schoolaccountprofile' : '/user'} style={{ textDecoration: 'none', color: 'var(--connected-color)' }}>
                <div className='imgLogoName'>
                    <div className='logoImg'>
                        <img
                            alt=''
                            src='../Logo.png'
                            style={{ maxWidth: 40, maxHeight: 40 }}
                        />
                    </div>
                    <div className='header__left'>
                        <h1>
                            ConnectEdApp
                        </h1>
                    </div>
                </div>
            </Link>
            <div className='header__right'>
                <div className='header__options'>
                    {/* home */}
                    <div className=' header__active'>
                        <Link to={userData.userCurrentStatus === 'SCHOOL' ? '/schoolaccountprofile' : '/user'} style={{ textDecoration: 'none', color: 'black' }}>
                            <div className='header__optionHome'>
                                <HomeIcon />
                                <h3>Home</h3>
                            </div>
                        </Link>
                    </div>

                    {/* requests */}
                    <div style={{ display: userData.userCurrentStatus === 'SCHOOL' ? 'none' : 'flex' }} className='header__optionDropdown'>
                        <ClickAwayListener onClickAway={handleClickAwayReq}>
                            <Box sx={{ position: 'relative' }}>
                                <div className='header__option' onClick={handleClickReq} >
                                    <Badge color="info" badgeContent={requestsRecieved.length}>
                                        <PeopleAltIcon />
                                    </Badge>
                                    <h3 >Requests</h3>
                                </div>

                                {openTab.reqOpen ? (
                                    <Box id="requests" sx={dropdownReq}>
                                        <div id="requestsTitle">
                                            <p>Connection Requests</p>
                                            <h5 onClick={handleClickOpenSent}>See All Sent Requests</h5>
                                            <BootstrapDialog
                                                onClose={handleCloseSent}
                                                aria-labelledby="customized-dialog-title"
                                                open={opensent}
                                            >
                                                <div className='seeAllSent'>
                                                    <h3>All sent requests ({sentRequests?.length})</h3>
                                                    <hr />
                                                    <div >
                                                        {
                                                            sentRequests?.length > 0 ? (
                                                                <div className='seeAllSentCardsBlock'>
                                                                    <div className='seeAllSentCards'>
                                                                        {
                                                                            sentRecievedData.map((info, i) => (
                                                                                <HeaderSentRequestCard
                                                                                    key={i}
                                                                                    reqAvatar={info?.reqAvatar}
                                                                                    reqId={info?.reqId}
                                                                                    reqUserName={info?.reqUserName}
                                                                                    reqCurrrentStatus={info?.reqCurrrentStatus}
                                                                                    userId={userData.userId}
                                                                                    userCurrentStatus={userData.userCurrentStatus}
                                                                                    userPathCollection={userData.userPathCollection}
                                                                                    userFullName={userData.userFullName}
                                                                                />
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className='noSentRequest'>
                                                                    <h4>You have not sent any connection request yet</h4>
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </BootstrapDialog>
                                        </div>
                                        <hr />
                                        {
                                            requestsRecievedData.length > 0 ? (
                                                <div className='requestsRecievedHeader'>
                                                    {
                                                        requestsRecievedData.map((reqData, i) => (
                                                            <RecievedRequest
                                                                key={i}
                                                                reqAvatar={reqData?.reqAvatar}
                                                                reqId={reqData?.reqId}
                                                                reqUserName={reqData?.reqUserName}
                                                                userId={userData.userId}
                                                                userCurrentStatus={userData.userCurrentStatus}
                                                                userPathCollection={userData.userPathCollection}
                                                                userFullName={userData.userFullName}
                                                            />
                                                        ))
                                                    }
                                                </div>
                                            ) : (
                                                <div className='headerNoData'>
                                                    <h4>No requests to show at the moment.</h4>
                                                </div>
                                            )

                                        }
                                    </Box>
                                ) : null}
                            </Box>
                        </ClickAwayListener>
                    </div>

                    {/* messages */}
                    <div className='header__optionDropdown'>
                        <ClickAwayListener onClickAway={handleClickAwayMsg}>
                            <Box sx={root} >
                                <div className='header__option' onClick={handleClickMsg} >
                                    <Badge color="info" badgeContent={messagesNotRead?.length}>
                                        <MessageIcon />
                                    </Badge>
                                    <h3 >Inbox</h3>
                                </div>
                                {openTab.msgOpen ? (
                                    < Box id="messages" sx={dropdownMsg}>
                                        <div id="messagesTitle">
                                            <p>Inbox</p>
                                            <h5>
                                                <Link to='/messages' style={{ textDecoration: 'none', color: 'black' }}>
                                                    See All Messages
                                                </Link>
                                            </h5>
                                        </div>
                                        <hr />
                                        {
                                            allRecievedMsgs.length > 0 ? (
                                                <div>
                                                    <div>
                                                        {
                                                            recivedMessagesAll.map((data, i) => (
                                                                <HeaderMessages
                                                                    key={i}
                                                                    msgToFromId={data.recievedMsgData?.MSG_FROM}
                                                                    msgRecieverSenderStatus={data.recievedMsgData?.MSG_SENDER_STATUS}
                                                                    msgId={data.recievedMsgId}
                                                                    msgText={data.recievedMsgData?.MSG_TEXT}
                                                                    msgTimestamp={data.recievedMsgData?.MSG_TIMESTAMP}
                                                                    isViewed={data.recievedMsgData?.IS_VIEWED}
                                                                    userId={userData.userId}
                                                                    userPathCollection={userData.userPathCollection}
                                                                    userCurrentStatus={userData.userCurrentStatus}
                                                                    userFullName={userData.userFullName}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                    {
                                                        allRecievedMsgs.length > 5 && (
                                                            <>
                                                                {
                                                                    totalRecievedMsgs !== recivedMessagesAll.length ? (
                                                                        <div className='ButtonSeeMore'>
                                                                            <Button onClick={(e) => { e.preventDefault(); loadMoreRecieved(recivedMessagesAll.length) }} disabled={btndisabledRecieved}>show more..</Button>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='noMoreSchools'>
                                                                            <h4>No more recieved messages to show...</h4>
                                                                        </div>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }

                                                </div>
                                            ) : (
                                                <div className='headerNoData'>
                                                    <h4>No messages to show at the moment.</h4>
                                                </div>
                                            )
                                        }

                                    </Box>
                                ) : null}
                            </Box>
                        </ClickAwayListener>
                    </div>

                    {/* logout/user profile */}
                    <div className='header__optionDropdown'>
                        <ClickAwayListener onClickAway={handleClickAwayAvatar}>
                            <Box sx={root}>
                                <div className='header__option header__optionProfile' onClick={handleClickAvatar} >
                                    <Avatar
                                        src={userData.userDP}
                                        alt={userData.userFullName}
                                        sx={{ width: 40, height: 40 }}
                                    />
                                    <ArrowDropDownIcon />
                                </div>
                                {openTab.avatarOpen ? (
                                    <Box id="avatar" sx={dropdownAvatar} >
                                        <Link to={userData.userCurrentStatus === 'SCHOOL' ? '/schoolsselfprofile' : '/userprofile'} style={{ textDecoration: 'none', color: 'black' }}>
                                            <div className='avatarDropdown__profile'>
                                                <Avatar
                                                    src={userData.userDP}
                                                    alt={userData.userFullName}
                                                    sx={{ width: 60, height: 60 }}
                                                />
                                                <h5>{userData.userFullName}</h5>
                                            </div>
                                        </Link>

                                        <hr />
                                        <div>
                                            {
                                                userData.userCurrentStatus === "STUDENT" && (
                                                    <>
                                                        <div className='sidebar__info'>
                                                            <div className='sidebar__infoStatus'>
                                                                <p >Current E-Schooling Status</p>
                                                                <div className='userCurrentStatus'>
                                                                    <div className='userCurrentStatusChange'>
                                                                        <h3 >"{userData.userCurrentStatus}"</h3>
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            studSchoolData?.VERIFIED_STUDENT ? schlVerified : notVerified
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p className='sidebar__at'>at</p>
                                                        </div>
                                                        <div className='sidebar__school'>
                                                            <Link to={`/schoolfriendprofile/${studSchoolData?.USER_SCHOOL_ID}`} style={{ textDecoration: 'none', color: 'black' }}>
                                                                <Avatar
                                                                    alt={schoolData?.SCHOOL_FULL_NAME}
                                                                    src={schoolData?.USER_DISPLAY_PIC}
                                                                    sx={{ width: 60, height: 60 }}
                                                                />
                                                            </Link>
                                                            <h5>
                                                                <Link to={`/schoolfriendprofile/${studSchoolData?.USER_SCHOOL_ID}`} style={{ textDecoration: 'none', color: 'black' }}>
                                                                    {schoolData?.SCHOOL_FULL_NAME}
                                                                </Link>
                                                            </h5>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            {
                                                userData.userCurrentStatus === "PRINCIPAL" && (
                                                    <>
                                                        <div className='sidebar__info'>
                                                            <div className='sidebar__infoStatus'>
                                                                <p style={{ display: showBlock === 'show' ? 'none' : 'flex' }}>Current E-Schooling Status</p>
                                                                <div className='userCurrentStatus'>
                                                                    <div style={{ display: showBlock === 'show' ? 'none' : 'flex' }} className='userCurrentStatusChange'>
                                                                        <h3 >"{userData.userCurrentStatus}"</h3>
                                                                        <p onClick={handleChangeStatus}>Change</p>
                                                                    </div>
                                                                    <div style={{ display: showBlock === 'show' ? 'none' : 'flex' }}>
                                                                        {
                                                                            princiSchoolData.VERIFIED_PRINCIPAL ? schlVerified : notVerified
                                                                        }
                                                                    </div>
                                                                    {
                                                                        showBlock === 'show' && (statusOptions)
                                                                    }

                                                                </div>
                                                            </div>
                                                            <p style={{ display: showBlock === 'show' ? 'none' : 'flex' }} className='sidebar__at'>at</p>
                                                        </div>

                                                        <div style={{ display: showBlock === 'show' ? 'none' : 'flex' }} className='sidebar__school'>
                                                            <Link to={`/schoolfriendprofile/${princiSchoolData.USER_SCHOOL_ID}`} style={{ textDecoration: 'none', color: 'black' }}>
                                                                <Avatar
                                                                    alt={schoolData?.SCHOOL_FULL_NAME}
                                                                    src={schoolData?.USER_DISPLAY_PIC}
                                                                    sx={{ width: 60, height: 60 }}
                                                                />
                                                            </Link>
                                                            <h5>
                                                                <Link to={`/schoolfriendprofile/${princiSchoolData.USER_SCHOOL_ID}`} style={{ textDecoration: 'none', color: 'black' }}>
                                                                    {schoolData?.SCHOOL_FULL_NAME}
                                                                </Link>
                                                            </h5>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            {
                                                userData.userCurrentStatus === "TEACHER" && (
                                                    <>
                                                        <div className='sidebar__info'>
                                                            <div className='sidebar__infoStatus'>
                                                                <p style={{ display: showBlock === 'show' ? 'none' : 'flex' }}>Current E-Schooling Status</p>
                                                                <div className='userCurrentStatus'>
                                                                    <div style={{ display: showBlock === 'show' ? 'none' : 'flex' }} className='userCurrentStatusChange'>
                                                                        <h3 >"{userData.userCurrentStatus}"</h3>
                                                                        <p onClick={handleChangeStatus}>Change</p>
                                                                    </div>
                                                                    <div style={{ display: showBlock === 'show' ? 'none' : 'flex' }}>
                                                                        {
                                                                            teacherSchoolData?.VERIFIED_TEACHER ? schlVerified : notVerified
                                                                        }
                                                                    </div>
                                                                    {
                                                                        showBlock === 'show' && (statusOptions)
                                                                    }

                                                                </div>
                                                            </div>
                                                            <p style={{ display: showBlock === 'show' ? 'none' : 'flex' }} className='sidebar__at'>at</p>
                                                        </div>

                                                        <div style={{ display: showBlock === 'show' ? 'none' : 'flex' }} className='sidebar__school'>
                                                            <Link to={`/schoolfriendprofile/${teacherSchoolData?.USER_SCHOOL_ID}`} style={{ textDecoration: 'none', color: 'black' }}>
                                                                <Avatar
                                                                    alt={schoolData?.SCHOOL_FULL_NAME}
                                                                    src={schoolData?.USER_DISPLAY_PIC}
                                                                    // className={classes.large}
                                                                    sx={{ width: 60, height: 60 }}
                                                                />
                                                            </Link>
                                                            <h5>
                                                                <Link to={`/schoolfriendprofile/${teacherSchoolData?.USER_SCHOOL_ID}`} style={{ textDecoration: 'none', color: 'black' }}>
                                                                    {schoolData?.SCHOOL_FULL_NAME}
                                                                </Link>
                                                            </h5>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            {
                                                userData.userCurrentStatus === "PARENT" && (
                                                    <>
                                                        <div className='HomePageSidebarParentssidebar__infoStatus'>
                                                            <p style={{ display: showBlock === 'show' ? 'none' : 'flex' }}>Current E-Schooling Status</p>
                                                            <div className='userCurrentStatus'>
                                                                <div style={{ display: showBlock === 'show' ? 'none' : 'flex' }} className='userCurrentStatusChange'>
                                                                    <h3 >"{userData.userCurrentStatus}"</h3>
                                                                    <p onClick={handleChangeStatus}>Change</p>
                                                                </div>
                                                                <div style={{ display: showBlock === 'show' ? 'none' : 'flex' }} className='Of'>
                                                                    <h4>Of</h4>
                                                                </div>
                                                                <div style={{ display: showBlock === 'show' ? 'none' : 'flex' }} className='childsData'>
                                                                    <div className='childNameArrow' >
                                                                        <div className='parentsChildsName'>
                                                                            <Avatar
                                                                                src={selectedChildData?.CHILD_DISPLAY_PIC}

                                                                                alt={selectedChildData?.CHILDS_FULL_NAME}
                                                                                sx={{ width: 35, height: 35 }}
                                                                            />
                                                                            <h2>{selectedChildData?.CHILDS_FULL_NAME}</h2>
                                                                        </div>
                                                                        <div className='arrowDropdown'>
                                                                            <ClickAwayListener onClickAway={handleClickDropDownAway}>
                                                                                <div className={`arrowDropdown`} >
                                                                                    <ArrowDropDownIcon onClick={handleClickDropDown} />
                                                                                    {
                                                                                        openChild ? (
                                                                                            <>
                                                                                                <Box sx={dropdown} >
                                                                                                    {
                                                                                                        parentChilds.map(data => (
                                                                                                            <div key={data?.childId} id='childsListDropDown' onClick={(e) => { e.preventDefault(); handleClickSelectChild(data?.childId) }}>
                                                                                                                <Avatar
                                                                                                                    src={data?.childData?.CHILD_DISPLAY_PIC}
                                                                                                                    // className={classes.small}
                                                                                                                    alt={data?.childData?.CHILDS_FULL_NAME}
                                                                                                                    sx={{ width: 35, height: 35 }}
                                                                                                                />
                                                                                                                <h2>{data?.childData?.CHILDS_FULL_NAME}</h2>
                                                                                                            </div>
                                                                                                        ))
                                                                                                    }
                                                                                                </Box>
                                                                                            </>
                                                                                        ) : null
                                                                                    }
                                                                                </div>
                                                                            </ClickAwayListener>

                                                                        </div>
                                                                    </div>
                                                                    <div style={{ display: showBlock === 'show' ? 'none' : 'flex' }} className='tableOfClass'>
                                                                        {
                                                                            selectedChildData?.VERIFIED_CHILD ? schlVerified : notVerified
                                                                        }
                                                                        <h4>{selectedChildData?.CHILDS_MEDIUM}</h4>
                                                                        <h3>{selectedChildData?.CHILDS_CLASS} | {selectedChildData?.CHILDS_SECTION}</h3>
                                                                    </div>
                                                                    <div style={{ display: showBlock === 'show' ? 'none' : 'flex' }} className='at'>
                                                                        <h4>Studying at</h4>
                                                                    </div>
                                                                    <div style={{ display: showBlock === 'show' ? 'none' : 'flex' }} className='childSchoolName'>
                                                                        <h5>
                                                                            <Link to={`/schoolfriendprofile/${selectedChildData?.CHILDS_SCHOOL_ID}`} style={{ textDecoration: 'none', color: 'black' }}>
                                                                                {childsSchoolData?.SCHOOL_FULL_NAME}
                                                                            </Link>
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    showBlock === 'show' && (statusOptions)
                                                                }

                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                        <hr />
                                        <div onClick={handleOpen} className='avatarDropdown__logout'>
                                            <ExitToAppIcon />
                                            <h5 className='avatarDropdown__profilelogout'>Log Out</h5>
                                        </div>
                                        <Modal
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box sx={style}>
                                                <div className='deletePostModal'>
                                                    <div className='deletePostModalText'>
                                                        <h4>Do you really want to sign out?</h4>
                                                    </div>
                                                    <div className='deletePostModalButtons'>
                                                        <LoadingButton
                                                            onClick={handleSignOut}
                                                            loading={btnLoading}
                                                            variant="outlined"
                                                        >
                                                            Yes
                                                        </LoadingButton>
                                                        {/* <Button disabled={btnDisabled} ></Button> */}
                                                        <Button onClick={handleClose}>No</Button>
                                                    </div>
                                                </div>
                                            </Box>
                                        </Modal>
                                        <hr />
                                        <div className='HeaderBottomRow'>
                                            <div className='complaintMobEmail'>
                                                <h4>For Suggestions & Complaints about ConnectedApp.in</h4>
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <div className='complaintMobEmail' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <AlternateEmailIcon style={{ fontSize: 14, marginTop: 2 }} />
                                                        <p style={{ marginLeft: 3 }}>info@connectedapp.in</p>
                                                    </div>
                                                    <div className='complaintMobEmail' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
                                                        <WhatsAppIcon style={{ fontSize: 14 }} />
                                                        <p style={{ marginLeft: 3 }}>+91-9975530191</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Box>
                                ) : null}
                            </Box>
                        </ClickAwayListener>
                    </div>
                </div>
            </div>
        </nav >
    )
}

export default Header