import React, { useEffect, useState } from 'react'
import '../ProjectsHomeworks/HomeWorksPrincipal.css'
import HomeWorksPrincipalClassNSubject from './HomeWorksPrincipalClassNSubject'
import { doc, onSnapshot } from 'firebase/firestore'
import dB from '../../firebase'

function HomeWorksPrincipal(props) {
    const { userId, userPathCollection, userCurrentStatus, userSchoolId } = props

    const [princiSchoolData, setprinciSchoolData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userSchoolId) {
                onSnapshot(doc(dB, "SCHOOLS", userSchoolId), (schlDoc) => {
                    if (schlDoc.exists()) {
                        setprinciSchoolData(schlDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userSchoolId])

    const [princiVerified, setprinciVerified] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), (princiDoc) => {
                    if (princiDoc.exists()) {
                        setprinciVerified(princiDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    return (
        <div className='HomeWorksPrincipal'>
            <div className='HomeWorksPrincipal__top'>
                <h3>{princiSchoolData?.SCHOOL_FULL_NAME}</h3>
                <h4>Projects and Homeworks</h4>
                <hr />
            </div>
            {
                princiVerified?.VERIFIED_PRINCIPAL ? (
                    <HomeWorksPrincipalClassNSubject
                        userId={userId}
                        userPathCollection={userPathCollection}
                        userCurrentStatus={userCurrentStatus}
                        userSchoolId={userSchoolId}
                        userSchoolPathCollection={princiSchoolData?.SCHOOL_NAME}
                        isPrinciVerified={princiVerified?.VERIFIED_PRINCIPAL}
                    />
                ) : (
                    <div className='homeworkPrinciNotVerified'>
                        <h3>You are not verified by your school at the moment. Please wait till your school verifies you.</h3>
                    </div>
                )
            }
        </div>
    )
}

export default HomeWorksPrincipal
