import React, { useEffect, useState } from 'react'
import '../VerificationRequest/SchoolVerificationRequestsTeachingStaff.css'
import { doc, onSnapshot, serverTimestamp, writeBatch } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';

function SchoolVerificationRequestsTeachingStaffSubject(props) {

    const { subTeacherId, subTeacherSubjectId, schoolId, schoolShortName, staffTeacherMedClsSec, subjectName, verfiedTeacherId,
        verfiedTeacherPathCollection, verfiedTeachersSubjectId } = props

    const [userData, setuserData] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (subTeacherId) {
                onSnapshot(doc(dB, "USERS", subTeacherId), (snap) => {
                    if (snap.exists()) {
                        setuserData(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [subTeacherId])
    const [teacherRegName, setteacherRegName] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (subTeacherId && userData) {
                onSnapshot(doc(dB, "USERS", subTeacherId, userData?.USER_NAME, "REGISTRATION_INFO"), (snap) => {
                    if (snap.exists()) {
                        setteacherRegName(snap.data()?.USER_NAME)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [subTeacherId, userData])
    const [btnLoading, setbtnLoading] = useState(false)
    var batch = writeBatch(dB)
    const handleConfirm = () => {
        setbtnLoading(true)

        batch.delete(doc(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", staffTeacherMedClsSec, "TEACHERS", "VERIFICATION_REQUESTS", subjectName, subTeacherId));

        batch.set(doc(dB, "SCHOOLS", schoolId, schoolShortName, "ALL_TEACHERS", "ALL_TEACHERS_LIST", subTeacherId), {
            TIMESTAMP: serverTimestamp()
        })

        if (verfiedTeacherId) {

            batch.update(doc(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", staffTeacherMedClsSec, "PROJECTS_&_HOMEWORKS", subjectName), {
                SUBJECT_TEACHER_ID: subTeacherId
            })

            batch.update(doc(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", staffTeacherMedClsSec, "TEACHERS", "VERIFIED_MEMBERS", subjectName, "VERIFIED_MEMBERS_DETAILS"), {
                TEACHERS_ID: subTeacherId,
                TEACHERS_SUBJECT_ID: subTeacherSubjectId,
                TIMESTAMP: serverTimestamp()
            });

            batch.update(doc(dB, "USERS", subTeacherId, userData?.USER_NAME, "E-SCHOOLING_INFO", "IS_TEACHER", "IS_TEACHER_DATA", "SUBJECTS_TEACH", verfiedTeachersSubjectId), {
                VERIFIED_TEACHER: false
            });
        } else {

            batch.set(doc(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", staffTeacherMedClsSec, "PROJECTS_&_HOMEWORKS", subjectName), {
                SUBJECT_TEACHER_ID: subTeacherId
            })

            batch.set(doc(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", staffTeacherMedClsSec, "TEACHERS", "VERIFIED_MEMBERS", subjectName, "VERIFIED_MEMBERS_DETAILS"), {
                TEACHERS_ID: subTeacherId,
                TEACHERS_SUBJECT_ID: subTeacherSubjectId,
                TIMESTAMP: serverTimestamp()
            });
        }



        batch.update(doc(dB, "USERS", subTeacherId, userData?.USER_NAME, "E-SCHOOLING_INFO", "IS_TEACHER", "IS_TEACHER_DATA", "SUBJECTS_TEACH", subTeacherSubjectId), {
            VERIFIED_SCHOOL_ID: schoolId,
            VERIFIED_TEACHER: true
        });

        batch.update(doc(dB, "USERS", subTeacherId, userData?.USER_NAME, "E-SCHOOLING_INFO", "IS_TEACHER", "VERIFICATION_DETAILS"), {
            VERIFIED_SCHOOL_ID: schoolId,
            VERIFIED_TEACHER: true
        })

        // Commit the batch
        batch.commit().then(() => {
            setbtnLoading(false)
        }).catch(err => alert(err.message));

    }

    const handleCancel = async () => {
        setbtnLoading(true)

        batch.delete(doc(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", staffTeacherMedClsSec, "TEACHERS", "VERIFICATION_REQUESTS", subjectName, subTeacherId))
            .then(() => {
                setbtnLoading(false)
            }).catch(err => alert(err.message));
    }

    return (
        <div className='subjectDataRow' >
            <div className='schoolverificationrequests__containerRightTeachingStaffDataName dataNameParent'>
                <Link to={`/friendsprofile/${subTeacherId}`} style={{ textDecoration: 'none', color: 'gray' }}>
                    <Avatar
                        alt={teacherRegName}
                        src={userData?.USER_DISPLAY_PIC}
                        sx={{ width: 40, height: 40, }}
                    />
                </Link>
                <h3>
                    <Link to={`/friendsprofile/${subTeacherId}`} style={{ textDecoration: 'none', color: 'black' }}>
                        {teacherRegName}
                    </Link>
                </h3>
            </div>
            <div className='schoolverificationrequests__containerRightTeachingStaffDataButtons'>
                <LoadingButton
                    onClick={handleConfirm}
                    variant='outlined'
                    loading={btnLoading}
                >
                    Confirm
                </LoadingButton>
                <LoadingButton
                    onClick={handleCancel}
                    variant='outlined'
                    loading={btnLoading}
                >
                    Cancel
                </LoadingButton>
            </div>
        </div>
    )
}

export default SchoolVerificationRequestsTeachingStaffSubject
