import React, { useEffect, useState } from 'react'
import '../Social/SchoolAccountTop.css'
import { doc, updateDoc, writeBatch } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import {
    Avatar, Box, Button, Checkbox, Dialog, FormControl, FormControlLabel, FormGroup,
    FormHelperText, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Select, TextField
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import dB, { storage } from '../../firebase';


const imageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
};

function SchoolAccountTop(props) {

    const { schoolId, schoolPathCollection, schoolFullName, schoolAffiliation, schoolPhone,
        schoolAbout, schoolAchievements, schoolAddres, schoolCity, schoolDistrict, schoolEstablished, schoolfacilities,
        schoolMission, schoolPinCode, schoolRestAddres, schoolState, schooltehsil, schoolVillage, schoolVision,
        schoolAddressLine1, schoolAddressLine2, schoolInitName, userDP
    } = props

    // const classes = useStyles();

    const [showErrors, setshowErrors] = useState({
        schoolName: { error: false, helperText: '' },
        schoolCity: { error: false, helperText: '' },
        schoolDistrict: { error: false, helperText: '' },
        schoolPinCode: { error: false, helperText: '' },
        schoolState: { error: false, helperText: '' }
    })

    const [schoolDetails, setschoolDetails] = useState({
        schoolName: '',
        schoolAddress: {
            schoolAddressLine1: '', schoolAddressLine2: '', schoolVillage: '', schooltehsil: '', schoolCity: '', schoolDistrict: '', schoolPinCode: '', schoolState: ''
        },
        schoolVision: '', schoolMission: '', schoolAbout: '', schoolPhoneNo: '', schoolEstablishmentYear: '',
        affiliation: '',
        achievements: {
            achieve1: '', achieve2: '', achieve3: '', achieve4: '', achieve5: '', achieve6: '', achieve7: '', achieve8: '', achieve9: '', achieve10: '',
        }
    })


    const handleChangeAddresInputs = (event) => {
        event.preventDefault();
        setschoolDetails(prev => {
            return {
                ...prev, schoolAddress: {
                    ...prev.schoolAddress,
                    [event.target.name]: event.target.value
                }
            }
        });
        setshowErrors(prev => { return { ...prev, [event.target.name]: { error: false, helperText: '' }, } })
    }
    const handleChangeSchoolDetails = (event) => {
        event.preventDefault();
        setschoolDetails(prev => {
            return {
                ...prev, [event.target.name]: event.target.value
            }
        });
        setshowErrors(prev => { return { ...prev, [event.target.name]: { error: false, helperText: '' }, } })
    }
    const handleChangeAwardNAchieve = (e) => {
        e.preventDefault()
        setschoolDetails(prev => {
            return {
                ...prev,
                achievements: {
                    ...prev.achievements,
                    [e.target.name]: e.target.value
                }
            }
        })
    }

    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolCity) {
                setschoolDetails(prev => { return { ...prev, schoolAddress: { ...prev.schoolAddress, schoolCity: schoolCity } } })
            }
        }
        return () => { aaa = false }
    }, [schoolCity])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolDistrict) {
                setschoolDetails(prev => { return { ...prev, schoolAddress: { ...prev.schoolAddress, schoolDistrict: schoolDistrict } } })
            }
        }
        return () => { aaa = false }
    }, [schoolDistrict])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolPinCode) {
                setschoolDetails(prev => { return { ...prev, schoolAddress: { ...prev.schoolAddress, schoolPinCode: schoolPinCode } } })
            }
        }
        return () => { aaa = false }
    }, [schoolPinCode])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolState) {
                setschoolDetails(prev => { return { ...prev, schoolAddress: { ...prev.schoolAddress, schoolState: schoolState } } })
            }
        }
        return () => { aaa = false }
    }, [schoolState])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolVillage) {
                setschoolDetails(prev => { return { ...prev, schoolAddress: { ...prev.schoolAddress, schoolVillage: schoolVillage } } })
            }
        }
        return () => { aaa = false }
    }, [schoolVillage])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schooltehsil) {
                setschoolDetails(prev => { return { ...prev, schoolAddress: { ...prev.schoolAddress, schooltehsil: schooltehsil } } })
            }
        }
        return () => { aaa = false }
    }, [schooltehsil])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolAddressLine1) {
                setschoolDetails(prev => { return { ...prev, schoolAddress: { ...prev.schoolAddress, schoolAddressLine1: schoolAddressLine1 } } })
            }
        }
        return () => { aaa = false }
    }, [schoolAddressLine1])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolAddressLine2) {
                setschoolDetails(prev => { return { ...prev, schoolAddress: { ...prev.schoolAddress, schoolAddressLine2: schoolAddressLine2 } } })
            }
        }
        return () => { aaa = false }
    }, [schoolAddressLine2])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolVision) {
                setschoolDetails(prev => { return { ...prev, schoolVision: schoolVision } })
            }
        }
        return () => { aaa = false }
    }, [schoolVision])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolMission) {
                setschoolDetails(prev => { return { ...prev, schoolMission: schoolMission } })
            }
        }
        return () => { aaa = false }
    }, [schoolMission])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolAbout) {
                setschoolDetails(prev => { return { ...prev, schoolAbout: schoolAbout } })
            }
        }
        return () => { aaa = false }
    }, [schoolAbout])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolPhone) {
                setschoolDetails(prev => { return { ...prev, schoolPhoneNo: schoolPhone } })
            }
        }
        return () => { aaa = false }
    }, [schoolPhone])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolEstablished) {
                setschoolDetails(prev => { return { ...prev, schoolEstablishmentYear: schoolEstablished } })
            }
        }
        return () => { aaa = false }
    }, [schoolEstablished])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolAffiliation) {
                setschoolDetails(prev => { return { ...prev, affiliation: schoolAffiliation } })
            }
        }
        return () => { aaa = false }
    }, [schoolAffiliation])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolInitName) {
                setschoolDetails(prev => { return { ...prev, schoolName: schoolInitName } })
            }
        }
        return () => { aaa = false }
    }, [schoolInitName])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolAchievements?.length) {
                setschoolDetails(prev => {
                    return {
                        ...prev,
                        achievements: {
                            ...prev.achievements,
                            achieve1: schoolAchievements[0] !== undefined ? schoolAchievements[0] : '',
                            achieve2: schoolAchievements[1] !== undefined ? schoolAchievements[1] : '',
                            achieve3: schoolAchievements[2] !== undefined ? schoolAchievements[2] : '',
                            achieve4: schoolAchievements[3] !== undefined ? schoolAchievements[3] : '',
                            achieve5: schoolAchievements[4] !== undefined ? schoolAchievements[4] : '',
                            achieve6: schoolAchievements[5] !== undefined ? schoolAchievements[5] : '',
                            achieve7: schoolAchievements[6] !== undefined ? schoolAchievements[6] : '',
                            achieve8: schoolAchievements[7] !== undefined ? schoolAchievements[7] : '',
                            achieve9: schoolAchievements[8] !== undefined ? schoolAchievements[8] : '',
                            achieve10: schoolAchievements[9] !== undefined ? schoolAchievements[9] : '',
                        }
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [schoolAchievements])

    const [inputData, setinputData] = useState({
        schoolName: '', schoolCity: '', schoolDistrict: '', schoolPinCode: '', schoolState: '', schoolVillage: '', schooltehsil: '',
    })
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolDetails.schoolName) {
                setinputData(prev => { return { ...prev, schoolName: schoolDetails.schoolName } })
            }
        }
        return () => { aaa = false }

    }, [schoolDetails.schoolName])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolDetails.schoolAddress.schoolCity) {
                setinputData(prev => { return { ...prev, schoolCity: schoolDetails.schoolAddress.schoolCity } })
            }
        }
        return () => { aaa = false }

    }, [schoolDetails.schoolAddress.schoolCity])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolDetails.schoolAddress.schoolDistrict) {
                setinputData(prev => { return { ...prev, schoolDistrict: schoolDetails.schoolAddress.schoolDistrict } })
            }
        }
        return () => { aaa = false }

    }, [schoolDetails.schoolAddress.schoolDistrict])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolDetails.schoolAddress.schoolPinCode) {
                setinputData(prev => { return { ...prev, schoolPinCode: schoolDetails.schoolAddress.schoolPinCode } })
            }
        }
        return () => { aaa = false }

    }, [schoolDetails.schoolAddress.schoolPinCode])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolDetails.schoolAddress.schoolState) {
                setinputData(prev => { return { ...prev, schoolState: schoolDetails.schoolAddress.schoolState } })
            }
        }
        return () => { aaa = false }

    }, [schoolDetails.schoolAddress.schoolState])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolDetails.schoolAddress.schoolVillage) {
                setinputData(prev => { return { ...prev, schoolVillage: schoolDetails.schoolAddress.schoolVillage } })
            }
        }
        return () => { aaa = false }

    }, [schoolDetails.schoolAddress.schoolVillage])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolDetails.schoolAddress.schooltehsil) {
                setinputData(prev => { return { ...prev, schooltehsil: schoolDetails.schoolAddress.schooltehsil } })
            }
        }
        return () => { aaa = false }

    }, [schoolDetails.schoolAddress.schooltehsil])

    const states = ['Andhra Pradesh', 'Andaman & Nicobar Islands', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chandigarh', 'Chhattisgarh', 'Dadra & Nagar Haveli', ' Daman & Diu', 'Delhi', 'Goa', 'Gujrat', 'Haryana', 'Himachal Pradesh', 'Jammu & Kashmir', 'Jharkhand', 'Karnataka', 'Kerala', 'Ladakh', 'Lakshadweep', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Puducherry', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal']
    const estYears = ['2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010', '2009', '2008', '2007', '2006', '2005', '2004', '2003', '2002', '2001', '2000', '1999', '1998', '1997', '1996', '1995', '1994', '1993', '1992', '1991', '1990', '1989', '1988', '1987', '1986', '1985', '1984', '1983', '1982', '1981', '1980', '1979', '1978', '1977', '1976', '1975', '1974', '1973', '1972', '1971', '1970', '1969', '1968', '1967', '1966', '1965', '1964', '1963', '1962', '1961', '1960', '1959', '1958', '1957', '1956', '1955', '1954', '1953', '1952', '1951', '1950', '1949', '1948', '1947', '1946', '1945', '1944', '1943', '1942', '1941', '1940', '1939', '1938', '1937', '1936', '1935', '1934', '1933', '1932', '1931', '1930', '1929', '1928', '1927', '1926', '1925', '1924', '1923', '1922', '1921', '1920', '1919', '1918', '1917', '1916', '1915', '1914', '1913', '1912', '1911', '1910', '1909', '1908', '1907', '1906', '1905', '1904', '1903', '1902', '1901']
    const affiliations = ['CBSE', 'ICSE', 'Kendriya Vidyalaya', ' Jawahar Navoday Vidyalaya', ' Kasturbha Gandhi Balika Vidyalaya', ' Indian Army Public School', ' State Board School']

    const [open, setOpen] = useState(false);
    const handleClickOpen = (e) => { e.preventDefault(); setOpen(true); };
    const handleClose = (e) => { e.preventDefault(); setOpen(false); };

    const [disableButtons, setdisableButtons] = useState({
        schoolAddress: false, schoolImg: false,
        schoolVision: false, schoolMission: false, schoolAbout: false, schoolPhoneNo: false,
        schoolEstablishmentYear: false, facilities: false, achievements: false, affiliation: false
    })
    const [updatedText, setupdatedText] = useState({
        schoolAddress: false, schoolImg: false,
        schoolVision: false, schoolMission: false, schoolAbout: false, schoolPhoneNo: false,
        schoolEstablishmentYear: false, facilities: false, achievements: false, affiliation: false
    })

    var batch = writeBatch(dB)

    const handleUpdateVision = async (e) => {
        e.preventDefault();
        setdisableButtons(prev => { return { ...prev, schoolVision: true } })
        updateDoc(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "SCHOOL_DETAILS"), {
            SCHOOL_VISION: schoolDetails.schoolVision,
        }).then(() => {
            setschoolDetails(prev => { return { ...prev, schoolVision: '' } })
            setdisableButtons(prev => { return { ...prev, schoolVision: true } })
            setupdatedText(prev => { return { ...prev, schoolVision: true } })
        }).catch(err => alert(err.message))
    }
    const handleUpdateMission = async (e) => {
        e.preventDefault();
        setdisableButtons(prev => { return { ...prev, schoolMission: true } })
        updateDoc(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "SCHOOL_DETAILS"), {
            SCHOOL_MISSION: schoolDetails.schoolMission,
        }).then(() => {
            setschoolDetails(prev => { return { ...prev, schoolMission: '' } })
            setdisableButtons(prev => { return { ...prev, schoolMission: true } })
            setupdatedText(prev => { return { ...prev, schoolMission: true } })
        }).catch(err => alert(err.message))
    }
    const handleUpdatePhoneNo = async (e) => {
        e.preventDefault();
        setdisableButtons(prev => { return { ...prev, schoolPhoneNo: true } })
        updateDoc(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "REGISTRATION_INFO"), {
            SCHOOL_PHONE_NO: schoolDetails.schoolPhoneNo,
        }).then(() => {
            setschoolDetails(prev => { return { ...prev, schoolPhoneNo: '' } })
            setdisableButtons(prev => { return { ...prev, schoolPhoneNo: true } })
            setupdatedText(prev => { return { ...prev, schoolPhoneNo: true } })
        }).catch(err => alert(err.message))
    }
    const handleUpdateAboutSchool = async (e) => {
        e.preventDefault();
        setdisableButtons(prev => { return { ...prev, schoolAbout: true } })
        updateDoc(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "SCHOOL_DETAILS"), {
            SCHOOL_ABOUT: schoolDetails.schoolAbout,
        }).then(() => {
            setschoolDetails(prev => { return { ...prev, schoolAbout: '' } })
            setdisableButtons(prev => { return { ...prev, schoolAbout: true } })
            setupdatedText(prev => { return { ...prev, schoolAbout: true } })
        }).catch(err => alert(err.message))
    }
    const handleUpdateEstabYear = async (e) => {
        e.preventDefault();
        setdisableButtons(prev => { return { ...prev, schoolEstablishmentYear: true } })
        updateDoc(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "SCHOOL_DETAILS"), {
            SCHOOL_ESTABLISHMENT: schoolDetails.schoolEstablishmentYear,
        }).then(() => {
            setschoolDetails(prev => { return { ...prev, schoolEstablishmentYear: '' } })
            setdisableButtons(prev => { return { ...prev, schoolEstablishmentYear: true } })
            setupdatedText(prev => { return { ...prev, schoolEstablishmentYear: true } })
        }).catch(err => alert(err.message))
    }
    const handleUpdateAchievements = async (e) => {
        e.preventDefault();
        setdisableButtons(prev => { return { ...prev, achievements: true } })
        const abc = []
        if (schoolDetails.achievements.achieve1 !== '') { abc.push(schoolDetails.achievements.achieve1) }
        if (schoolDetails.achievements.achieve2 !== '') { abc.push(schoolDetails.achievements.achieve2) }
        if (schoolDetails.achievements.achieve3 !== '') { abc.push(schoolDetails.achievements.achieve3) }
        if (schoolDetails.achievements.achieve4 !== '') { abc.push(schoolDetails.achievements.achieve4) }
        if (schoolDetails.achievements.achieve5 !== '') { abc.push(schoolDetails.achievements.achieve5) }
        if (schoolDetails.achievements.achieve6 !== '') { abc.push(schoolDetails.achievements.achieve6) }
        if (schoolDetails.achievements.achieve7 !== '') { abc.push(schoolDetails.achievements.achieve7) }
        if (schoolDetails.achievements.achieve8 !== '') { abc.push(schoolDetails.achievements.achieve8) }
        if (schoolDetails.achievements.achieve9 !== '') { abc.push(schoolDetails.achievements.achieve9) }
        if (schoolDetails.achievements.achieve10 !== '') { abc.push(schoolDetails.achievements.achieve10) }

        updateDoc(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "SCHOOL_DETAILS"), {
            SCHOOL_ACHIEVEMENTS: abc,
        }).then(() => {
            setschoolDetails(prev => { return { ...prev, achievements: { ...prev.achievements, achieve1: '', achieve2: '', achieve3: '', achieve4: '', achieve5: '', achieve6: '', achieve7: '', achieve8: '', achieve9: '', achieve10: '', } } })
            setdisableButtons(prev => { return { ...prev, achievements: true } })
            setupdatedText(prev => { return { ...prev, achievements: true } })
        }).catch(err => alert(err.message))
    }
    const handleUpdateSchoolNameNAddress = async (e) => {
        e.preventDefault()
        setdisableButtons(prev => { return { ...prev, schoolAddress: true } })
        if (schoolDetails.schoolName && schoolDetails.schoolAddress.schoolCity && schoolDetails.schoolAddress.schoolDistrict && schoolDetails.schoolAddress.schoolPinCode && schoolDetails.schoolAddress.schoolState) {

            var villageName = schoolDetails.schoolAddress.schoolVillage ? `${schoolDetails.schoolAddress.schoolVillage}, ` : ''
            var tehsilName = schoolDetails.schoolAddress.schooltehsil ? `${schoolDetails.schoolAddress.schooltehsil}, ` : ''
            var newSchoolFullName = schoolDetails.schoolName + ', ' + villageName + tehsilName + schoolDetails.schoolAddress.schoolCity + ', ' + schoolDetails.schoolAddress.schoolDistrict + ', ' + schoolDetails.schoolAddress.schoolState + ', ' + schoolDetails.schoolAddress.schoolPinCode + '.'

            await batch.update(doc(dB, "SCHOOLS", schoolId), {
                SCHOOL_FULL_NAME: newSchoolFullName
            })

            var schoolrestAddress = villageName + tehsilName + schoolDetails.schoolAddress.schoolCity + ', ' + schoolDetails.schoolAddress.schoolDistrict + ', ' + schoolDetails.schoolAddress.schoolState + ', ' + schoolDetails.schoolAddress.schoolPinCode + '.'

            await batch.update(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "SCHOOL_DETAILS"), {
                SCHOOL_ADDRESS_LINE1: schoolDetails.schoolAddress.schoolAddressLine1,
                SCHOOL_ADDRESS_LINE2: schoolDetails.schoolAddress.schoolAddressLine2,
                SCHOOL_CITY: schoolDetails.schoolAddress.schoolCity,
                SCHOOL_DISTRICT: schoolDetails.schoolAddress.schoolDistrict,
                SCHOOL_PINCODE: schoolDetails.schoolAddress.schoolPinCode,
                SCHOOL_STATE: schoolDetails.schoolAddress.schoolState,
                SCHOOL_TEHSIL: schoolDetails.schoolAddress.schooltehsil,
                SCHOOL_VILLAGE: schoolDetails.schoolAddress.schoolVillage,
                SCHOOL_REST_ADDRESS: schoolrestAddress,

            })

            await batch.update(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "REGISTRATION_INFO"), {
                SCHOOL_NAME: schoolDetails.schoolName,
                SCHOOL_FULL_NAME: newSchoolFullName
            })

            await batch.commit().then(() => {
                setschoolDetails(prev => { return { ...prev, schoolName: '', schoolAddress: { ...prev.schoolAddress, schoolAddressLine1: '', schoolAddressLine2: '', schoolVillage: '', schooltehsil: '', schoolCity: '', schoolDistrict: '', schoolPinCode: '', schoolState: '' } } })
                setdisableButtons(prev => { return { ...prev, schoolAddress: true } })
                setupdatedText(prev => { return { ...prev, schoolAddress: true } })
                setinputData(prev => { return { ...prev, schoolName: '', schoolCity: '', schoolDistrict: '', schoolPinCode: '', schoolState: '', schoolVillage: '', schooltehsil: '', } })
            }).catch(err => alert(err.message))
        }
        else {
            if (!schoolDetails.schoolAddress.schoolName) { setshowErrors(prev => { return { ...prev, schoolName: { ...prev.schoolName, error: true, helperText: '* Required' }, } }) }
            if (!schoolDetails.schoolAddress.schoolCity) { setshowErrors(prev => { return { ...prev, schoolCity: { ...prev.schoolCity, error: true, helperText: '* Required' }, } }) }
            if (!schoolDetails.schoolAddress.schoolDistrict) { setshowErrors(prev => { return { ...prev, schoolDistrict: { ...prev.schoolDistrict, error: true, helperText: '* Required' }, } }) }
            if (!schoolDetails.schoolAddress.schoolPinCode) { setshowErrors(prev => { return { ...prev, schoolPinCode: { ...prev.schoolPinCode, error: true, helperText: '* Required' }, } }) }
            if (!schoolDetails.schoolAddress.schoolState) { setshowErrors(prev => { return { ...prev, schoolState: { ...prev.schoolState, error: true, helperText: '* Required' }, } }) }
        }

    }
    const handleUpdateAffiliation = async (e) => {
        e.preventDefault();
        setdisableButtons(prev => { return { ...prev, affiliation: true } })
        updateDoc(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "REGISTRATION_INFO"), {
            SCHOOL_AFFILIATION: schoolDetails.affiliation,
        }).then(() => {
            setschoolDetails(prev => { return { ...prev, affiliation: '' } })
            setdisableButtons(prev => { return { ...prev, affiliation: true } })
            setupdatedText(prev => { return { ...prev, affiliation: true } })
        }).catch(err => alert(err.message))
    }



    const [facilitiesState, setfacilitiesState] = useState({
        ComputerLab: false, PhysicsLab: false, ElectronicsLab: false, BiologyLab: false, ChemistryLab: false,
        LibraryNReadingRoom: false, Playground: false, BoysHostel: false, GirlsHostel: false, GirlsCommonRoom: false,
        DedicatedClassrooms: false, DigitalClassrooms: false, IndoorMultipurposeHall: false, ArtsandMusicRoom: false,
        SwimmingPool: false, SchoolBus: false, CCTVsurveillancesystem: false, FirePreventionSystem: false,
        CafeteriaCanteen: false, ScienceLab: false, Auditorium: false
    });
    const [checkedFacilities, setcheckedFacilities] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolfacilities?.length) {
                setcheckedFacilities(schoolfacilities)
            }
        }
        return () => { aaa = false }
    }, [schoolfacilities])

    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (checkedFacilities.includes('Computer Lab')) { setfacilitiesState(prev => { return { ...prev, ComputerLab: true } }) }
            if (checkedFacilities.includes('Physics Lab')) { setfacilitiesState(prev => { return { ...prev, PhysicsLab: true } }) }
            if (checkedFacilities.includes('Electronics Lab')) { setfacilitiesState(prev => { return { ...prev, ElectronicsLab: true } }) }
            if (checkedFacilities.includes('Biology Lab')) { setfacilitiesState(prev => { return { ...prev, BiologyLab: true } }) }
            if (checkedFacilities.includes('Chemistry Lab')) { setfacilitiesState(prev => { return { ...prev, ChemistryLab: true } }) }
            if (checkedFacilities.includes('Library & Reading Room')) { setfacilitiesState(prev => { return { ...prev, LibraryNReadingRoom: true } }) }
            if (checkedFacilities.includes('Playground')) { setfacilitiesState(prev => { return { ...prev, Playground: true } }) }
            if (checkedFacilities.includes('Hostel for Boys')) { setfacilitiesState(prev => { return { ...prev, BoysHostel: true } }) }
            if (checkedFacilities.includes('Hostel for Girls')) { setfacilitiesState(prev => { return { ...prev, GirlsHostel: true } }) }
            if (checkedFacilities.includes('Girls Common Room')) { setfacilitiesState(prev => { return { ...prev, GirlsCommonRoom: true } }) }
            if (checkedFacilities.includes('Dedicated Classrooms')) { setfacilitiesState(prev => { return { ...prev, DedicatedClassrooms: true } }) }
            if (checkedFacilities.includes('Digital Classrooms')) { setfacilitiesState(prev => { return { ...prev, DigitalClassrooms: true } }) }
            if (checkedFacilities.includes('Indoor Multipurpose Hall')) { setfacilitiesState(prev => { return { ...prev, IndoorMultipurposeHall: true } }) }
            if (checkedFacilities.includes('Arts & Music Room')) { setfacilitiesState(prev => { return { ...prev, ArtsandMusicRoom: true } }) }
            if (checkedFacilities.includes('Swimming Pool')) { setfacilitiesState(prev => { return { ...prev, SwimmingPool: true } }) }
            if (checkedFacilities.includes('School Bus')) { setfacilitiesState(prev => { return { ...prev, SchoolBus: true } }) }
            if (checkedFacilities.includes('CCTV Surveillance System')) { setfacilitiesState(prev => { return { ...prev, CCTVsurveillancesystem: true } }) }
            if (checkedFacilities.includes('Fire Prevention System')) { setfacilitiesState(prev => { return { ...prev, FirePreventionSystem: true } }) }
            if (checkedFacilities.includes('Cafeteria/Canteen')) { setfacilitiesState(prev => { return { ...prev, CafeteriaCanteen: true } }) }
            if (checkedFacilities.includes('Science Lab')) { setfacilitiesState(prev => { return { ...prev, ScienceLab: true } }) }
            if (checkedFacilities.includes('Auditorium')) { setfacilitiesState(prev => { return { ...prev, Auditorium: true } }) }
        }
        return () => { aaa = false }
    }, [checkedFacilities])
    const handleChange = (event) => {
        event.preventDefault()
        setfacilitiesState({ ...facilitiesState, [event.target.name]: event.target.checked, });
        if (event.target.checked) {
            setcheckedFacilities(prev => { return [...prev, event.target.value] })
        } else {
            const a = checkedFacilities.indexOf(event.target.value);
            if (a >= 0) { checkedFacilities.splice(a, 1); }
        }
    };
    const [disableAllCheck, setdisableAllCheck] = useState(false)
    const handleUpdateSchoolFacilities = (e) => {
        e.preventDefault()
        setdisableButtons(prev => { return { ...prev, facilities: true } })
        updateDoc(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "SCHOOL_DETAILS"), {
            SCHOOL_FACILITIES: checkedFacilities,
        }).then(() => {
            setdisableButtons(prev => { return { ...prev, facilities: true } })
            setupdatedText(prev => { return { ...prev, facilities: true } })
            setdisableAllCheck(true)
        }).catch(err => alert(err.message))
    }



    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userDP) {
                setImgData(userDP)
            }
        }
        return () => { aaa = false }
    }, [userDP])
    const [randomKey, setrandomKey] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var a = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
            setrandomKey(a)
        }
        return () => { aaa = false }
    }, [])
    var resizeImage = function (settings) {
        var file = settings.file;
        var maxSize = settings.maxSize;
        var reader = new FileReader();
        var image = new Image();
        var canvas = document.createElement('canvas');
        var dataURItoBlob = function (dataURI) {
            var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
                atob(dataURI.split(',')[1]) :
                unescape(dataURI.split(',')[1]);
            var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var max = bytes.length;
            var ia = new Uint8Array(max);
            for (var i = 0; i < max; i++)
                ia[i] = bytes.charCodeAt(i);
            return new Blob([ia], { type: mime });
        };
        var resize = function () {
            var width = image.width;
            var height = image.height;
            if (width > height) {
                if (width > maxSize) {
                    height *= maxSize / width;
                    width = maxSize;
                }
            } else {
                if (height > maxSize) {
                    width *= maxSize / height;
                    height = maxSize;
                }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            var dataUrl = canvas.toDataURL('image/jpeg');
            return dataURItoBlob(dataUrl);
        };
        return new Promise(function (ok, no) {
            if (!file.type.match(/image.*/)) {
                no(new Error("Not an image"));
                return;
            }
            reader.onload = function (readerEvent) {
                image.onload = function () { return ok(resize()); };
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        });
    };
    // const [progress, setprogress] = useState(0);
    const [imgData, setImgData] = useState(null);
    const [picture, setPicture] = useState(null);
    const onChangePicture = async (e) => {
        if (e.target.files[0]) {
            setPicture(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);

            const config = {
                file: e.target.files[0],
                maxSize: 500
            };
            const resizedImage = await resizeImage(config)
            setPicture(resizedImage);
        }
    };
    const handleUpdateDisplayPic = async (e) => {
        e.preventDefault()
        setdisableButtons(prev => { return { ...prev, schoolImg: true } })

        // const uploadTask = storage.ref(`SCHOOLS/${schoolId}/Profile/${randomKey}`).put(picture);
        // uploadTask.on("state_changed",
        //     (snapshot) => {
        //         const progress = Math.round(
        //             (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        //         );
        //         setprogress(progress);
        //     },
        //     (error) => { },
        //     () => {
        //         // complete function
        //         storage
        //             .ref(`SCHOOLS/${schoolId}/Profile`)
        //             .child(randomKey)
        //             .getDownloadURL()
        //             .then(async url => {
        //                 updateDoc(doc(dB, "SCHOOLS", schoolId), {
        //                     USER_DISPLAY_PIC: url,
        //                 }).then(() => {
        //                     
        //                 })
        //             })
        //     })

        const imageRef = ref(storage, `SCHOOLS/${schoolId}/Profile/${randomKey}`);
        const metadata = { contentType: "image/jpeg", };
        await uploadBytes(imageRef, picture, metadata)
            .then(async (snapshot) => {
                const downloadURL = await getDownloadURL(imageRef);
                await updateDoc(doc(dB, "SCHOOLS", schoolId), {
                    USER_DISPLAY_PIC: downloadURL,
                }).then(() => {
                    setdisableButtons(prev => { return { ...prev, schoolImg: true } })
                    setupdatedText(prev => { return { ...prev, schoolImg: true } })
                    // setImgData(null)
                    setPicture(null)
                    // setprogress(0);
                    var b = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
                    setrandomKey(b)
                }).catch(err => alert(err.message))
            })
    }

    const [openImage, setOpenImage] = React.useState(false);
    const handleOpenImage = () => setOpenImage(true);
    const handleCloseImage = () => setOpenImage(false);

    return (
        <div className='schoolaccounttop'>
            <div className='schoolaccounttop__image'>
                <Box sx={{ display: 'flex', '& > *': { margin: 1 } }} id='schoolaccounttop__image'>
                    <Avatar
                        alt={schoolFullName}
                        src={userDP}
                        // className={classes.Avatar}
                        onClick={handleOpenImage}
                        sx={{ width: 150, height: 150 }}
                    />
                    <Modal
                        open={openImage}
                        onClose={handleCloseImage}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={imageStyle}>
                            <div className='profileImageBig'>
                                <img src={userDP} alt={schoolFullName} />
                            </div>
                        </Box>
                    </Modal>
                </Box>
            </div>
            <div className='schoolaccounttop__info'>
                <h5 >{schoolFullName}</h5>
                {
                    schoolAffiliation && (
                        <p ><strong>(K to 12th)</strong></p>
                    )
                }
                <p >{schoolAffiliation}</p>
                <div className='verifyButton'>
                    <Button onClick={handleClickOpen}>Edit Profile</Button>
                </div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <div className='editSchoolProfile'>

                        <h2 style={{ color: 'orange' }}>Update School Information</h2>

                        {/* display pic */}
                        <div className='editSchoolProfilePic'>
                            <Avatar
                                alt={schoolFullName}
                                src={imgData}
                                // className={classes.Avatar}
                                sx={{ width: 120, height: 120, }}
                            />
                            {/* <div className='editSchoolProfileButtons'> */}
                            {/* <input
                                type="file"
                                onChange={onChangePicture}
                                accept='.jpg, .jpeg, .png'
                            /> */}
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                            >
                                <input
                                    hidden
                                    type="file"
                                    id="postImage"
                                    name="postImage"
                                    accept=".jpg,.jpeg,.png,"
                                    // multiple={userCurrentStatus === 'SCHOOL' ? true : false}
                                    onChange={onChangePicture}
                                />
                                <PhotoCamera sx={{ fontSize: "30px", color: 'lightblue' }} />
                                <h3 style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "grey"
                                }}>Photo</h3>
                            </IconButton>
                            <Button disabled={disableButtons.schoolImg} onClick={handleUpdateDisplayPic}>Update</Button>
                            {/* </div> */}
                            {/* <progress
                                className='imageUpload__progress'
                                value={progress}
                                max='100'
                            /> */}
                            {updatedText.schoolImg && (<h2>Profile Pic updated..</h2>)}
                        </div>


                        <h2>School Name & Address</h2>
                        <div className='schoolNameAppearance'>
                            <h3>Your school name will appear like : <b style={{ color: '#2e81f4' }}>{inputData.schoolName ? `${inputData.schoolName}, ` : ''}{inputData.schoolVillage ? `${inputData.schoolVillage}, ` : ''}{inputData.schooltehsil ? `${inputData.schooltehsil}, ` : ''}{inputData.schoolCity ? `${inputData.schoolCity}, ` : ''}{inputData.schoolDistrict ? `${inputData.schoolDistrict}, ` : ''}{inputData.schoolState ? `${inputData.schoolState} - ` : ''}{inputData.schoolPinCode ? `${inputData.schoolPinCode}.` : ''}</b></h3>
                        </div>
                        {/* school name */}
                        <div className='editSchoolProfileSchoolName'>
                            <FormControl error={showErrors.schoolName.error} name='schoolName' sx={{ width: '530px' }} variant="outlined">
                                <InputLabel htmlFor="schoolAddressLine2">Name of the School</InputLabel>
                                <OutlinedInput
                                    id="schoolName"
                                    type='text'
                                    name='schoolName'
                                    value={schoolDetails.schoolName}
                                    onChange={handleChangeSchoolDetails}
                                    // labelWidth={150}
                                    label='Name of the School'
                                />
                                <FormHelperText id="inputCreatorFName"><p>{showErrors.schoolName.helperText}</p></FormHelperText>
                            </FormControl>
                            <h5><b>Note :</b> Dont mention village, tehsil, city or district in the school name. Enter the school name only. <b>For Example :</b> <em>"Little Angels High School".</em></h5>
                        </div>
                        {/* address */}
                        <div className='editSchoolProfileSchoolAddress'>
                            <FormControl name='schoolAddressLine1' sx={{ width: '530px' }} variant="outlined">
                                <InputLabel htmlFor="schoolAddressLine1">Address line 1(Optional)</InputLabel>
                                <OutlinedInput
                                    id="schoolAddressLine1"
                                    type='text'
                                    name='schoolAddressLine1'
                                    value={schoolDetails.schoolAddress.schoolAddressLine1}
                                    onChange={handleChangeAddresInputs}
                                    label='Address line 1(Optional)'
                                />
                            </FormControl>
                            <FormControl name='schoolAddressLine2' sx={{ width: '530px' }} variant="outlined">
                                <InputLabel htmlFor="schoolAddressLine2">Address line 2(Optional)</InputLabel>
                                <OutlinedInput
                                    id="schoolAddressLine2"
                                    type='text'
                                    name='schoolAddressLine2'
                                    value={schoolDetails.schoolAddress.schoolAddressLine2}
                                    onChange={handleChangeAddresInputs}
                                    label='Address line 2(Optional)'
                                />
                            </FormControl>
                            <div className='editSchoolProfileSchoolAddressData'>
                                <FormControl name='schoolVillage' sx={{ width: '260px' }} variant="outlined">
                                    <InputLabel htmlFor="schoolVillage">Village Name (Optional)</InputLabel>
                                    <OutlinedInput
                                        id="schoolVillage"
                                        type='text'
                                        name='schoolVillage'
                                        value={schoolDetails.schoolAddress.schoolVillage}
                                        onChange={handleChangeAddresInputs}
                                        label='Village Name (Optional)'
                                    />
                                </FormControl>
                                <FormControl name='schooltehsil' sx={{ width: '260px' }} variant="outlined">
                                    <InputLabel htmlFor="schooltehsil">Tehsil/Taluka Name (Optional)</InputLabel>
                                    <OutlinedInput
                                        id="schooltehsil"
                                        type='text'
                                        name='schooltehsil'
                                        value={schoolDetails.schoolAddress.schooltehsil}
                                        onChange={handleChangeAddresInputs}
                                        label='Tehsil/Taluka Name (Optional)'
                                    />
                                </FormControl>
                            </div>

                            <div className='editSchoolProfileSchoolAddressData'>
                                <FormControl error={showErrors.schoolCity.error} name='schoolCity' sx={{ width: '260px' }} variant="outlined">
                                    <InputLabel htmlFor="schoolCity">City</InputLabel>
                                    <OutlinedInput
                                        id="schoolCity"
                                        type='text'
                                        name='schoolCity'
                                        value={schoolDetails.schoolAddress.schoolCity}
                                        onChange={handleChangeAddresInputs}
                                        label='City'
                                    />
                                    <FormHelperText id="inputCreatorFName"><p>{showErrors.schoolCity.helperText}</p></FormHelperText>
                                </FormControl>
                                <FormControl error={showErrors.schoolDistrict.error} name='schoolDistrict' sx={{ width: '260px' }} variant="outlined">
                                    <InputLabel htmlFor="schoolDistrict">District</InputLabel>
                                    <OutlinedInput
                                        id="schoolDistrict"
                                        type='text'
                                        name='schoolDistrict'
                                        value={schoolDetails.schoolAddress.schoolDistrict}
                                        onChange={handleChangeAddresInputs}
                                        label='District'
                                    />
                                    <FormHelperText id="inputCreatorFName"><p>{showErrors.schoolDistrict.helperText}</p></FormHelperText>
                                </FormControl>
                            </div>

                            <div className='editSchoolProfileSchoolAddressData'>
                                <FormControl error={showErrors.schoolPinCode.error} name='schoolPinCode' sx={{ width: '260px' }} variant="outlined">
                                    <InputLabel htmlFor="schoolPinCode">PIN CODE</InputLabel>
                                    <OutlinedInput
                                        id="schoolPinCode"
                                        type='number'
                                        name='schoolPinCode'
                                        value={schoolDetails.schoolAddress.schoolPinCode}
                                        onChange={handleChangeAddresInputs}
                                        label='PIN CODE'
                                    />
                                    <FormHelperText id="inputCreatorFName"><p>{showErrors.schoolPinCode.helperText}</p></FormHelperText>
                                </FormControl>

                                <FormControl error={showErrors.schoolState.error} name='schoolState' variant="outlined" sx={{ width: '260px' }}>
                                    <InputLabel htmlFor="schoolState">State</InputLabel>
                                    <Select
                                        id="schoolState"
                                        name='schoolState'
                                        // native
                                        value={schoolDetails.schoolAddress.schoolState}
                                        onChange={handleChangeAddresInputs}
                                        label="State"
                                        inputProps={{
                                            name: 'schoolState',
                                            id: 'schoolState',
                                        }}
                                    >
                                        <MenuItem aria-label="None" value="" />
                                        {states.map((state) => (<MenuItem key={state} value={state}>{state}</MenuItem>))}
                                    </Select>
                                    <FormHelperText id="inputCreatorFName"><p>{showErrors.schoolState.helperText}</p></FormHelperText>
                                </FormControl>
                            </div>
                            <div className='editSchoolProfileButtons'>
                                <Button disabled={disableButtons.schoolAddress} onClick={handleUpdateSchoolNameNAddress}>Update School Name & Address</Button>
                            </div>
                            {updatedText.schoolAddress && (<h2>School Name & Address updated..</h2>)}
                        </div>


                        <h2>School Vision, Mission</h2>
                        {/* vision */}
                        <div className='editSchoolProfileVision'>
                            <TextField
                                name='schoolVision'
                                sx={{ width: '530px' }}
                                value={schoolDetails.schoolVision}
                                onChange={handleChangeSchoolDetails}
                                id="schoolVision"
                                label="Vision of the School. (Maximum 500 Characters)"
                                multiline
                                minRows={3}
                                maxRows={5}
                                variant={'outlined'}
                                inputProps={{ maxLength: 500 }}
                            />
                            <div className='editSchoolProfileButtons'>
                                <Button disabled={disableButtons.schoolVision} onClick={handleUpdateVision}>Update Vision</Button>
                            </div>
                            {updatedText.schoolVision && (<h2>Vision updated..</h2>)}

                        </div>
                        {/* mission */}
                        <div className='editSchoolProfileMission'>
                            <TextField
                                name='schoolMission'
                                value={schoolDetails.schoolMission}
                                onChange={handleChangeSchoolDetails}
                                id="schoolMission"
                                label="Mission of the School. (Maximum 500 Characters)"
                                multiline
                                minRows={3}
                                maxRows={5}
                                variant={'outlined'}
                                sx={{ width: '530px' }}
                                inputProps={{ maxLength: 500 }}
                            />
                            <div className='editSchoolProfileButtons'>
                                <Button disabled={disableButtons.schoolMission} onClick={handleUpdateMission}>Update Mission</Button>
                            </div>
                            {updatedText.schoolMission && (<h2>Mission updated..</h2>)}

                        </div>


                        <h2>School Basic Information</h2>
                        {/* about school */}
                        <div className='editSchoolProfileAboutShcool'>
                            <TextField
                                name='schoolAbout'
                                value={schoolDetails.schoolAbout}
                                onChange={handleChangeSchoolDetails}
                                id="schoolAbout"
                                label="About School. (Maximum 750 Characters)"
                                multiline
                                minRows={5}
                                maxRows={10}
                                variant={'outlined'}
                                sx={{ width: '530px' }}
                                inputProps={{ maxLength: 750 }}
                            />
                            <div className='editSchoolProfileButtons'>
                                <Button disabled={disableButtons.schoolAbout} onClick={handleUpdateAboutSchool}>Update About School</Button>
                            </div>
                            {updatedText.schoolAbout && (<h2>About School updated..</h2>)}

                        </div>
                        {/* phone number */}
                        <div className='editSchoolProfilePhoneNo'>
                            <TextField
                                id="schoolPhoneNo"
                                label="Phone Number of School"
                                type="number"
                                variant={'outlined'}
                                name='schoolPhoneNo'
                                sx={{ width: '530px' }}
                                value={schoolDetails.schoolPhoneNo}
                                onChange={handleChangeSchoolDetails}
                            />
                            <div className='editSchoolProfileButtons'>
                                <Button disabled={disableButtons.schoolPhoneNo} onClick={handleUpdatePhoneNo}>Update Phone No</Button>
                            </div>
                            {updatedText.schoolPhoneNo && (<h2>Phone No. updated..</h2>)}

                        </div>
                        {/* establishment */}
                        <div className='editSchoolProfileEstaYear'>
                            <FormControl variant={'outlined'} sx={{ width: '260px' }}>
                                <InputLabel id="schoolEstablishmentYear">Establishment Year</InputLabel>
                                <Select
                                    labelId="schoolEstablishmentYear"
                                    id="schoolEstablishmentYear"
                                    value={schoolDetails.schoolEstablishmentYear}
                                    label="Establishment Year"
                                    onChange={handleChangeSchoolDetails}
                                    name='schoolEstablishmentYear'
                                >
                                    <MenuItem value=''>{''}</MenuItem>
                                    {estYears.map(year => (<MenuItem key={year} value={year}>{year}</MenuItem>))}
                                </Select>
                            </FormControl>
                            <div className='editSchoolProfileButtons'>
                                <Button disabled={disableButtons.schoolEstablishmentYear} onClick={handleUpdateEstabYear}>Update Establishment Year</Button>
                            </div>
                            {updatedText.schoolEstablishmentYear && (<h2>Establishment Year updated..</h2>)}

                        </div>
                        {/* affiliation */}
                        <div className='editSchoolProfileAffiliation'>
                            <FormControl variant={'outlined'} sx={{ width: '260px' }}>
                                <InputLabel id="affiliation">Affiliation</InputLabel>
                                <Select
                                    labelId="affiliation"
                                    id="affiliation"
                                    value={schoolDetails.affiliation}
                                    label="Affiliation"
                                    onChange={handleChangeSchoolDetails}
                                    name='affiliation'
                                >
                                    <MenuItem value=''>{''}</MenuItem>
                                    {affiliations.map(afflns => (<MenuItem key={afflns} value={afflns}>{afflns}</MenuItem>))}
                                </Select>
                            </FormControl>
                            <div className='editSchoolProfileButtons'>
                                <Button disabled={disableButtons.affiliation} onClick={handleUpdateAffiliation}>Update Affiliation</Button>
                            </div>
                            {updatedText.affiliation && (<h2>School Affiliation updated..</h2>)}

                        </div>


                        <h2>School Facilities</h2>
                        {/* facilities */}
                        <div className='editSchoolProfileFacilities'>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormGroup>
                                    <div className='editSchoolProfileFacilitiesRow'>
                                        <div className='editSchoolProfileFacilitiesRowLeft'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'Computer Lab'} checked={facilitiesState.ComputerLab} onChange={handleChange} name="ComputerLab" />}
                                                label="Computer Lab"
                                            />
                                        </div>
                                        <div className='editSchoolProfileFacilitiesRowRight'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'Physics Lab'} checked={facilitiesState.PhysicsLab} onChange={handleChange} name="PhysicsLab" />}
                                                label="Physics Lab"
                                            />
                                        </div>
                                    </div>
                                    <div className='editSchoolProfileFacilitiesRow'>
                                        <div className='editSchoolProfileFacilitiesRowLeft'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'Electronics Lab'} checked={facilitiesState.ElectronicsLab} onChange={handleChange} name="ElectronicsLab" />}
                                                label="Electronics Lab"
                                            />
                                        </div>
                                        <div className='editSchoolProfileFacilitiesRowRight'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'Biology Lab'} checked={facilitiesState.BiologyLab} onChange={handleChange} name="BiologyLab" />}
                                                label="Biology Lab"
                                            />
                                        </div>
                                    </div>
                                    <div className='editSchoolProfileFacilitiesRow'>
                                        <div className='editSchoolProfileFacilitiesRowLeft'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'Chemistry Lab'} checked={facilitiesState.ChemistryLab} onChange={handleChange} name="ChemistryLab" />}
                                                label="Chemistry Lab"
                                            />
                                        </div>

                                        <div className='editSchoolProfileFacilitiesRowRight'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'Library & Reading Room'} checked={facilitiesState.LibraryNReadingRoom} onChange={handleChange} name="LibraryNReadingRoom" />}
                                                label="Library & Reading Room"
                                            />
                                        </div>

                                    </div>
                                    <div className='editSchoolProfileFacilitiesRow'>
                                        <div className='editSchoolProfileFacilitiesRowLeft'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'Playground'} checked={facilitiesState.Playground} onChange={handleChange} name="Playground" />}
                                                label="Playground"
                                            />
                                        </div>

                                        <div className='editSchoolProfileFacilitiesRowRight'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'Hostel for Boys'} checked={facilitiesState.BoysHostel} onChange={handleChange} name="BoysHostel" />}
                                                label="Hostel for Boys"
                                            />
                                        </div>

                                    </div>
                                    <div className='editSchoolProfileFacilitiesRow'>
                                        <div className='editSchoolProfileFacilitiesRowLeft'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'Hostel for Girls'} checked={facilitiesState.GirlsHostel} onChange={handleChange} name="GirlsHostel" />}
                                                label="Hostel for Girls"
                                            />
                                        </div>

                                        <div className='editSchoolProfileFacilitiesRowRight'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'Girls Common Room'} checked={facilitiesState.GirlsCommonRoom} onChange={handleChange} name="GirlsCommonRoom" />}
                                                label="Girls Common Room"
                                            />
                                        </div>

                                    </div>
                                    <div className='editSchoolProfileFacilitiesRow'>
                                        <div className='editSchoolProfileFacilitiesRowLeft'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'Dedicated Classrooms'} checked={facilitiesState.DedicatedClassrooms} onChange={handleChange} name="DedicatedClassrooms" />}
                                                label="Dedicated Classrooms"
                                            />
                                        </div>
                                        <div className='editSchoolProfileFacilitiesRowRight'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'Digital Classrooms'} checked={facilitiesState.DigitalClassrooms} onChange={handleChange} name="DigitalClassrooms" />}
                                                label="Digital Classrooms"
                                            />
                                        </div>

                                    </div>
                                    <div className='editSchoolProfileFacilitiesRow'>
                                        <div className='editSchoolProfileFacilitiesRowLeft'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'Indoor Multipurpose Hall'} checked={facilitiesState.IndoorMultipurposeHall} onChange={handleChange} name="IndoorMultipurposeHall" />}
                                                label="Indoor Multipurpose Hall"
                                            />
                                        </div>
                                        <div className='editSchoolProfileFacilitiesRowRight'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'Arts & Music Room'} checked={facilitiesState.ArtsandMusicRoom} onChange={handleChange} name="ArtsandMusicRoom" />}
                                                label="Arts & Music Room"
                                            />
                                        </div>

                                    </div>
                                    <div className='editSchoolProfileFacilitiesRow'>
                                        <div className='editSchoolProfileFacilitiesRowLeft'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'Swimming Pool'} checked={facilitiesState.SwimmingPool} onChange={handleChange} name="SwimmingPool" />}
                                                label="Swimming Pool"
                                            />
                                        </div>
                                        <div className='editSchoolProfileFacilitiesRowRight'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'School Bus'} checked={facilitiesState.SchoolBus} onChange={handleChange} name="SchoolBus" />}
                                                label="School Bus"
                                            />
                                        </div>

                                    </div>
                                    <div className='editSchoolProfileFacilitiesRow'>
                                        <div className='editSchoolProfileFacilitiesRowLeft'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'CCTV Surveillance System'} checked={facilitiesState.CCTVsurveillancesystem} onChange={handleChange} name="CCTVsurveillancesystem" />}
                                                label="CCTV Surveillance System"
                                            />
                                        </div>
                                        <div className='editSchoolProfileFacilitiesRowRight'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'Fire Prevention System'} checked={facilitiesState.FirePreventionSystem} onChange={handleChange} name="FirePreventionSystem" />}
                                                label="Fire Prevention System"
                                            />
                                        </div>

                                    </div>
                                    <div className='editSchoolProfileFacilitiesRow'>
                                        <div className='editSchoolProfileFacilitiesRowLeft'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'Cafeteria/Canteen'} checked={facilitiesState.CafeteriaCanteen} onChange={handleChange} name="CafeteriaCanteen" />}
                                                label="Cafeteria/Canteen"
                                            />
                                        </div>
                                        <div className='editSchoolProfileFacilitiesRowRight'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'Science Lab'} checked={facilitiesState.ScienceLab} onChange={handleChange} name="ScienceLab" />}
                                                label="Science Lab"
                                            />
                                        </div>
                                    </div>
                                    <div className='editSchoolProfileFacilitiesRow'>
                                        <div className='editSchoolProfileFacilitiesRowLeft'>
                                            <FormControlLabel
                                                control={<Checkbox disabled={disableAllCheck} size="small" color='primary' value={'Auditorium'} checked={facilitiesState.Auditorium} onChange={handleChange} name="Auditorium" />}
                                                label="Auditorium"
                                            />
                                        </div>

                                    </div>

                                </FormGroup>
                            </FormControl>
                            <div className='editSchoolFacilities'>
                                <Button disabled={disableButtons.facilities} onClick={handleUpdateSchoolFacilities}>Update School Facilities</Button>
                                {updatedText.facilities && (<h2>School Facilities updated..</h2>)}

                            </div>
                        </div>

                        {/* awards and achievements */}
                        <h2>Awards & other Achievements of School</h2>
                        <div className='editSchoolProfileAchievements'>
                            <TextField
                                sx={{ width: '530px' }}
                                id="achieve1"
                                label="Award / Achievement 1 (Max. 200 Characters)"
                                variant="outlined"
                                name='achieve1'
                                value={schoolDetails.achievements.achieve1}
                                onChange={handleChangeAwardNAchieve}
                                inputProps={{ maxLength: 200 }}
                            />
                            <TextField
                                inputProps={{ maxLength: 200 }}
                                sx={{ width: '530px' }}
                                id="achieve2"
                                label="Award / Achievement 2 (Max. 200 Characters)"
                                variant="outlined"
                                name='achieve2'
                                value={schoolDetails.achievements.achieve2}
                                onChange={handleChangeAwardNAchieve}
                            />
                            <TextField
                                inputProps={{ maxLength: 200 }}
                                sx={{ width: '530px' }}
                                id="achieve3"
                                label="Award / Achievement 3 (Max. 200 Characters)"
                                variant="outlined"
                                name='achieve3'
                                value={schoolDetails.achievements.achieve3}
                                onChange={handleChangeAwardNAchieve}
                            />
                            <TextField
                                inputProps={{ maxLength: 200 }}
                                sx={{ width: '530px' }}
                                id="achieve4"
                                label="Award / Achievement 4 (Max. 200 Characters)"
                                variant="outlined"
                                name='achieve4'
                                value={schoolDetails.achievements.achieve4}
                                onChange={handleChangeAwardNAchieve}
                            />
                            <TextField
                                inputProps={{ maxLength: 200 }}
                                sx={{ width: '530px' }}
                                id="achieve5"
                                label="Award / Achievement 5 (Max. 200 Characters)"
                                variant="outlined"
                                name='achieve5'
                                value={schoolDetails.achievements.achieve5}
                                onChange={handleChangeAwardNAchieve}
                            />
                            <TextField
                                inputProps={{ maxLength: 200 }}
                                sx={{ width: '530px' }}
                                id="achieve6"
                                label="Award / Achievement 6 (Max. 200 Characters)"
                                variant="outlined"
                                name='achieve6'
                                value={schoolDetails.achievements.achieve6}
                                onChange={handleChangeAwardNAchieve}
                            />
                            <TextField
                                inputProps={{ maxLength: 200 }}
                                sx={{ width: '530px' }}
                                id="achieve7"
                                label="Award / Achievement 7 (Max. 200 Characters)"
                                variant="outlined"
                                name='achieve7'
                                value={schoolDetails.achievements.achieve7}
                                onChange={handleChangeAwardNAchieve}
                            />
                            <TextField
                                inputProps={{ maxLength: 200 }}
                                sx={{ width: '530px' }}
                                id="achieve8"
                                label="Award / Achievement 8 (Max. 200 Characters)"
                                variant="outlined"
                                name='achieve8'
                                value={schoolDetails.achievements.achieve8}
                                onChange={handleChangeAwardNAchieve}
                            />
                            <TextField
                                inputProps={{ maxLength: 200 }}
                                sx={{ width: '530px' }}
                                id="achieve9"
                                label="Award / Achievement 9 (Max. 200 Characters)"
                                variant="outlined"
                                name='achieve9'
                                value={schoolDetails.achievements.achieve9}
                                onChange={handleChangeAwardNAchieve}
                            />
                            <TextField
                                inputProps={{ maxLength: 200 }}
                                sx={{ width: '530px' }}
                                id="achieve10"
                                label="Award / Achievement 10 (Max. 200 Characters)"
                                variant="outlined"
                                name='achieve10'
                                value={schoolDetails.achievements.achieve10}
                                onChange={handleChangeAwardNAchieve}
                            />
                            <div className='editSchoolProfileButtons'>
                                <Button disabled={disableButtons.achievements} onClick={handleUpdateAchievements}>Update Achievements</Button>
                            </div>
                            {updatedText.achievements && (<h2>Awards & Achievements updated..</h2>)}

                        </div>
                    </div>
                </Dialog>
            </div>
        </div>

    )
}

export default SchoolAccountTop