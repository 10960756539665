import React, { useState, useEffect } from 'react'
import '../Sidebars/SidebarESchoolingStatusAddParent.css'
import '../CreateNewAccount/CreateNewAccountInfoParents.css'
// import dB from '../firebase';
import { useHistory } from 'react-router-dom';
import { collection, doc, onSnapshot, serverTimestamp, writeBatch } from 'firebase/firestore';
import {
    Autocomplete, FormControl, FormControlLabel, FormHelperText, FormLabel,
    InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec';
import { returnSection } from '../../Constants/FuncMedium';

function SidebarESchoolingStatusAddParent(props) {
    const { userId, userPathCollection, userStatuses } = props


    const history = useHistory()

    const [showEnteredParents, setshowEnteredParents] = useState({
        inputChildSchoolName: { error: false },
        childFName: { error: false, helperText: '' },
        childLName: { error: false, helperText: '' },
        childClass: { error: false, helperText: '' },
        childSection: { error: false, helperText: '' },
        childsMedium: { error: false, helperText: '' },
    })

    const [childData, setchildData] = useState({
        childFName: '', childLName: '', inputChildSchoolName: null, parentsInputValue: '',
        childsMedium: '', childClass: '', childSection: '',
    })

    const handleChangeParentsInputChildFName = (e) => {
        e.preventDefault()
        setchildData(prev => { return { ...prev, childFName: e.target.value } })
        setshowEnteredParents(prevState => { return { ...prevState, childFName: { error: false, helperText: '' } } })
        setactionsPerformed(prev => { return { ...prev, msgSent: false } })
    }
    const handleChangeParentsInputChildLName = (e) => {
        e.preventDefault()
        setchildData(prev => { return { ...prev, childLName: e.target.value } })
        setshowEnteredParents(prevState => { return { ...prevState, childLName: { error: false, helperText: '' } } })
        setactionsPerformed(prev => { return { ...prev, msgSent: false } })
    }

    const handleChangeChildClass = (e) => {
        e.preventDefault();
        setchildData(prev => { return { ...prev, childClass: e.target.value, childSection: '' } })
        setshowEnteredParents(prevState => { return { ...prevState, childClass: { error: false, helperText: '' } } })
    }
    const handleChangeChildSection = (e) => {
        e.preventDefault();
        setchildData(prev => { return { ...prev, childSection: e.target.value } })
        setshowEnteredParents(prevState => { return { ...prevState, childSection: { error: false, helperText: '' } } })
    }

    const [parentsOptions, setparentsOptions] = useState([])
    useEffect(() => {
        if (parentsOptions.length > 0) {
            parentsOptions.splice(0, parentsOptions.length)
        }
        var aaa = true
        if (aaa === true) {
            onSnapshot(collection(dB, "SCHOOLS"), (snap) => {
                snap.forEach((schlDOc) => {
                    const newdata = schlDOc.data()?.SCHOOL_FULL_NAME;
                    setparentsOptions(prev => { return [...prev, { id: schlDOc.id, schoolName: newdata }] })
                });
            })
        }
        return () => { aaa = false }
    }, [])

    // fetch school id's for parents to select
    const [parentOptionSchoolId, setparentOptionSchoolId] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (childData.inputChildSchoolName) {
                parentsOptions.map(option => {
                    if (option.schoolName === childData.inputChildSchoolName) {
                        setparentOptionSchoolId(option.id)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [childData.inputChildSchoolName, parentsOptions])

    const handleMediumValueChange = (event) => {
        setchildData(prev => { return { ...prev, childsMedium: event.target.value } })
    };
    const handleMediumChange = (e) => {
        e.preventDefault();
        setchildData(prevState => { return { ...prevState, childClass: '', childSection: '' } })
        setshowEnteredParents(prevState => { return { ...prevState, childsMedium: { error: false, helperText: '' } } })
    }

    const [returnedChildsClassList, setreturnedChildsClassList] = useState([])
    const [parentsSchoolPath, setparentsSchoolPath] = useState({ schoolSmallName: '', schoolId: '', })
    useEffect(() => {
        if (returnedChildsClassList.length > 0) {
            returnedChildsClassList.splice(0, returnedChildsClassList.length)
        }
        var aaa = true
        if (aaa === true) {
            onSnapshot(collection(dB, "SCHOOLS"), (snap) => {
                snap.forEach((schlDOc) => {
                    const newdata = schlDOc.data()?.SCHOOL_FULL_NAME;
                    if (newdata === childData.inputChildSchoolName) {
                        const schoolCollection = schlDOc.data()?.SCHOOL_NAME;
                        setparentsSchoolPath(prevState => { return { ...prevState, schoolSmallName: schoolCollection, schoolId: schlDOc.id } })
                        onSnapshot(doc(dB, "SCHOOLS", schlDOc.id, schoolCollection, "CLASSES_DETAILS"), (classes) => {
                            if (classes.exists()) {
                                setreturnedChildsClassList(classes.data()?.CLASS_LIST)
                            }
                        })
                    }
                });
            })
        }
        return () => { aaa = false }
    }, [childData.inputChildSchoolName])

    const [returnedChildsSectionList, setreturnedChildsSectionList] = useState(null);
    useEffect(() => {
        setreturnedChildsSectionList(null)
        var aaa = true
        if (aaa === true) {
            var sectionObject = returnSection(childData.childsMedium, childData.childClass, returnedChildsClassList)
            setreturnedChildsSectionList(sectionObject)
        }
        return () => { aaa = false }
    }, [childData.childsMedium, childData.childClass, returnedChildsClassList])

    const [paarentChildClassSection, setpaarentChildClassSection] = useState('')
    useEffect(() => {
        setpaarentChildClassSection('')
        var aaa = true
        if (aaa === true) {
            var abc = returnMedClsSec(childData.childsMedium, childData.childClass, childData.childSection)
            setpaarentChildClassSection(abc)
        }
        return () => { aaa = false }
    }, [childData.childClass, childData.childSection, childData.childsMedium])

    // generate 20 digit random number
    const [randomKey, setrandomKey] = useState({ randomId1: '', })
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var a = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
            setrandomKey(prevState => { return { ...prevState, randomId1: a, } })
        }
        return () => { aaa = false }
    }, [])

    const [parentDetails, setparentDetails] = useState({ userFullName: '', parentGender: '' })
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "REGISTRATION_INFO"), (snap) => {
                    if (snap.exists()) {
                        setparentDetails(prev => {
                            return {
                                ...prev,
                                userFullName: snap.data()?.USER_NAME,
                                parentGender: snap.data()?.USER_GENDER
                            }
                        })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])
    useEffect(() => {
        if (parentDetails.parentGender) {
            if (parentDetails.parentGender === 'Male') { setparentDetails(prev => { return { ...prev, parentGender: 'Father' } }) }
            if (parentDetails.parentGender === 'Female') { setparentDetails(prev => { return { ...prev, parentGender: 'Mother' } }) }
            if (parentDetails.parentGender === 'Others') { setparentDetails(prev => { return { ...prev, parentGender: 'Guardian' } }) }
        }
    }, [parentDetails.parentGender])

    const [actionsPerformed, setactionsPerformed] = useState({
        msgSent: false, buttonDisabled: false
    })

    var batch = writeBatch(dB)
    const [btnLoading, setbtnLoading] = useState(false)
    const sendRequestChild1 = async (e) => {
        e.preventDefault()
        setbtnLoading(true)
        setactionsPerformed(prev => { return { ...prev, buttonDisabled: true } })
        if (childData.childFName && childData.childLName && childData.inputChildSchoolName && childData.childsMedium && childData.childClass && childData.childSection) {
            const childFullName = childData.childFName + ' ' + childData.childLName

            await batch.set(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "VERIFICATION_DETAILS"), {
                USER_STATUS: 'PARENT',
                VERIFIED_PARENT: false,
            });

            if (userStatuses.length === 0) {
                await batch.update(doc(dB, "USERS", userId), {
                    USER_CURRENT_STATUS: 'PARENT',
                });
            }

            await batch.set(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", randomKey.randomId1), {
                CHILDS_FULL_NAME: childFullName,
                CHILDS_CLASS: childData.childClass,
                CHILDS_SECTION: childData.childSection,
                CHILDS_MEDIUM: childData.childsMedium,
                VERIFIED_CHILD: false,
                CHILDS_SCHOOL_ID: parentOptionSchoolId
            });

            await batch.set(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA"), {
                CURRENT_SELECTED_CHILD: randomKey.randomId1
            })

            await batch.set(doc(dB, "SCHOOLS", parentsSchoolPath.schoolId, parentsSchoolPath.schoolSmallName, "CLASSES_DETAILS", "CLASSES", paarentChildClassSection, "PARENTS", "VERIFICATION_REQUESTS", "VERIFICATION_REQUESTS_DATA", userId, "CHILDS_LIST", randomKey.randomId1), {
                TIMESTAMP: serverTimestamp()
            });

            await batch.commit().then(() => {
                setbtnLoading(false)
                setactionsPerformed(prev => { return { ...prev, msgSent: true, buttonDisabled: false } })
                setchildData(prev => {
                    return {
                        ...prev,
                        childFName: '', childLName: '', inputChildSchoolName: null, parentsInputValue: '',
                        childsMedium: '', childClass: '', childSection: '',
                    }
                })
                history.push('/user')
            }).catch(err => alert(err.message))
        } else {
            if (!childData.childFName) { setshowEnteredParents(prev => { return { ...prev, childFName: { error: true, helperText: 'Please enter childs first name' }, } }) }
            if (!childData.childLName) { setshowEnteredParents(prev => { return { ...prev, childLName: { error: true, helperText: 'Please enter childs last name' }, } }) }
            if (!childData.inputChildSchoolName) { setshowEnteredParents(prev => { return { ...prev, inputChildSchoolName: { error: true }, } }) }
            if (!childData.childsMedium) { setshowEnteredParents(prev => { return { ...prev, childsMedium: { error: true, helperText: 'Please select childs medium' }, } }) }
            if (!childData.childClass) { setshowEnteredParents(prev => { return { ...prev, childClass: { error: true, helperText: 'Please select childs class' }, } }) }
            if (!childData.childSection) { setshowEnteredParents(prev => { return { ...prev, childSection: { error: true, helperText: 'Please select childs section' }, } }) }
        }
    }

    const [addStatusValue, setaddStatusValue] = React.useState('No');
    const handleChange = (event) => {
        setaddStatusValue(event.target.value);
        setchildData(prev => {
            return {
                ...prev, childFName: '', childLName: '', inputChildSchoolName: null,
                parentsInputValue: '', childsMedium: '', childClass: '', childSection: '',
            }
        })
    };

    return (
        <div className='SidebarESchoolingStatusAddStatusYesNo'>
            <div className='yesNoStatus'>
                <h3>Are you a parent of a child who is stydying in K-12 :</h3>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="gender"
                        name="controlled-radio-buttons-group"
                        value={addStatusValue}
                        onChange={handleChange}
                    >
                        <div>
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                        </div>
                    </RadioGroup>
                </FormControl>
            </div>
            {
                addStatusValue === 'Yes' && (
                    <div className='SidebarESchoolingStatusAddParent'>
                        <div className='SidebarESchoolingStatusAddParent__Name'>
                            <FormControl error={showEnteredParents.childFName.error} name='childFName1' sx={{ width: '300px', marginRight: '20px' }} variant="outlined">
                                <InputLabel htmlFor="childFName1">Childs First Name</InputLabel>
                                <OutlinedInput
                                    id="childFName1"
                                    name='childFName1'
                                    type='text'
                                    value={childData.childFName}
                                    onChange={handleChangeParentsInputChildFName}
                                    // labelWidth={130}
                                    label="Childs First Name"

                                />
                                <FormHelperText id="childFName1"><p>{showEnteredParents.childFName.helperText}</p></FormHelperText>
                            </FormControl>
                            <FormControl error={showEnteredParents.childLName.error} name='childLName1' sx={{ width: '300px' }} variant="outlined">
                                <InputLabel htmlFor="childLName1">Childs Last Name</InputLabel>
                                <OutlinedInput
                                    id="childLName1"
                                    name='childLName1'
                                    type='text'
                                    value={childData.childLName}
                                    onChange={handleChangeParentsInputChildLName}
                                    // labelWidth={130}
                                    label="Childs Last Name"
                                />
                                <FormHelperText id="childLName1"><p>{showEnteredParents.childLName.helperText}</p></FormHelperText>
                            </FormControl>
                        </div>

                        <Autocomplete
                            value={childData.inputChildSchoolName}
                            onChange={(e, newValue) => {
                                e.preventDefault()
                                setchildData(prev => { return { ...prev, inputChildSchoolName: newValue } })
                            }}
                            inputValue={childData.parentsInputValue}
                            onInputChange={(event, newInputValue) => {
                                event.preventDefault()
                                setchildData(prev => { return { ...prev, parentsInputValue: newInputValue } })
                                if (returnedChildsClassList.length > 0) {
                                    returnedChildsClassList.splice(0, returnedChildsClassList.length)
                                }
                                setshowEnteredParents(prevState => { return { ...prevState, inputChildSchoolName: { error: false, helperText: '' } } })
                                setchildData(prevState => { return { ...prevState, childClass: '', childSection: '' } })
                                setchildData(prev => { return { ...prev, childsMedium: '' } })
                                setactionsPerformed(prev => { return { ...prev, msgSent: false } })
                            }}
                            id="inputChildSchoolName1"
                            name='inputChildSchoolName1'
                            options={parentsOptions.map((option) => option.schoolName)}
                            // className={clsx(Muiclasses.longWidth)}
                            sx={{ width: '620px', }}
                            renderInput={(params) =>
                                <TextField
                                    id="inputChildSchoolName1"
                                    name='inputChildSchoolName1'
                                    {...params}
                                    margin="normal"
                                    label="Search your child's school here by school name, village ,city or district"
                                    variant="outlined"
                                    error={showEnteredParents.inputChildSchoolName.error}
                                />
                            }
                        />
                        <div className='noAvailable'>
                            <h5><b>Note : </b>If School you are looking for does not appear in the list, then your school is yet to create an account. Ask your school to create account first. Till then you can use this platform to follow other schools and make new friends.</h5>
                        </div>

                        <FormControl error={showEnteredParents.childsMedium.error} sx={{ marginTop: '10px', marginBottom: '10px' }} component="fieldset" onChange={handleMediumChange}>
                            <div className='parents__radioMediumMuiDiv'>
                                <FormLabel component="legend">Medium</FormLabel>
                                <RadioGroup aria-label="studentsMedium" name="studentsMedium" value={childData.childsMedium} onChange={handleMediumValueChange}>
                                    <div className='parents__radioMediumMui'>
                                        <FormControlLabel value="English Medium" control={<Radio />} label="English Medium" />
                                        <FormControlLabel value="Marathi Medium" control={<Radio />} label="Marathi Medium" />
                                    </div>
                                </RadioGroup>
                            </div>
                        </FormControl>
                        <FormHelperText id="childsMedium"><p>{showEnteredParents.childsMedium.helperText}</p></FormHelperText>

                        <div className='SidebarESchoolingStatusAddParent__ClsSection'>
                            <FormControl error={showEnteredParents.childClass.error} name='childClass' variant="outlined" >
                                <InputLabel htmlFor="childClass">Class :</InputLabel>
                                <Select
                                    sx={{ width: '330px', marginRight: '20px' }}
                                    id="childClass"
                                    name='childClass'
                                    // native 
                                    label="Class : "
                                    inputProps={{
                                        name: 'childClass',
                                        id: 'childClass',
                                    }}
                                    value={childData.childClass}
                                    onChange={handleChangeChildClass}
                                >
                                    <MenuItem style={{ display: childData.childsMedium != null ? 'none' : 'block' }} aria-label="None" value="" />

                                    <MenuItem style={{ display: childData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngPreNurserySecA') ? 'block' : 'none' }} id='Eng Pre Nursery' key='Eng Pre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngNurserySecA') ? 'block' : 'none' }} id='Eng Nursery' key='Eng Nursery' value='Nursery'>Nursery</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngKG1SecA') ? 'block' : 'none' }} id='Eng Kindergarten 1(KG-1)' key='Eng Kindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngKG2SecA') ? 'block' : 'none' }} id='Eng Kindergarten 2(KG-2)' key='Eng Kindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass1SecA') ? 'block' : 'none' }} id='Eng Class 1' key='Eng Class 1' value='Class 1'>Class 1</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass2SecA') ? 'block' : 'none' }} id='Eng Class 2' key='Eng Class 2' value='Class 2'>Class 2</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass3SecA') ? 'block' : 'none' }} id='Eng Class 3' key='Eng Class 3' value='Class 3'>Class 3</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass4SecA') ? 'block' : 'none' }} id='Eng Class 4' key='Eng Class 4' value='Class 4'>Class 4</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass5SecA') ? 'block' : 'none' }} id='Eng Class 5' key='Eng Class 5' value='Class 5'>Class 5</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass6SecA') ? 'block' : 'none' }} id='Eng Class 6' key='Eng Class 6' value='Class 6'>Class 6</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass7SecA') ? 'block' : 'none' }} id='Eng Class 7' key='Eng Class 7' value='Class 7'>Class 7</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass8SecA') ? 'block' : 'none' }} id='Eng Class 8' key='Eng Class 8' value='Class 8'>Class 8</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass9SecA') ? 'block' : 'none' }} id='Eng Class 9' key='Eng Class 9' value='Class 9'>Class 9</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass10SecA') ? 'block' : 'none' }} id='Eng Class 10' key='Eng Class 10' value='Class 10'>Class 10</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass11ScienceSecA') ? 'block' : 'none' }} id='Eng Class 11(Science)' key='Eng Class 11(Science)' value='Class 11(Science)'>Class 11(Science)</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass11CommerceSecA') ? 'block' : 'none' }} id='Eng Class 11(Commerce)' key='Eng Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass12ScienceSecA') ? 'block' : 'none' }} id='Eng Class 12(Science)' key='Eng Class 12(Science)' value='Class 12(Science)'>Class 12(Science)</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass12CommerceSecA') ? 'block' : 'none' }} id='Eng Class 12(Commerce)' key='Eng Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>

                                    <MenuItem style={{ display: childData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarPreNurserySecA') ? 'block' : 'none' }} id='Mar Pre Nursery' key='Mar Pre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarNurserySecA') ? 'block' : 'none' }} id='Mar Nursery' key='Mar Nursery' value='Nursery'>Nursery</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarKG1SecA') ? 'block' : 'none' }} id='Mar Kindergarten 1(KG-1)' key='Mar Kindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarKG2SecA') ? 'block' : 'none' }} id='Mar Kindergarten 2(KG-2)' key='Mar Kindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>

                                    <MenuItem style={{ display: childData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass1SecA') ? 'block' : 'none' }} id='Mar Class 1' key='Mar Class 1' value='Class 1'>Class 1</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass2SecA') ? 'block' : 'none' }} id='Mar Class 2' key='Mar Class 2' value='Class 2'>Class 2</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass3SecA') ? 'block' : 'none' }} id='Mar Class 3' key='Mar Class 3' value='Class 3'>Class 3</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass4SecA') ? 'block' : 'none' }} id='Mar Class 4' key='Mar Class 4' value='Class 4'>Class 4</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass5SecA') ? 'block' : 'none' }} id='Mar Class 5' key='Mar Class 5' value='Class 5'>Class 5</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass6SecA') ? 'block' : 'none' }} id='Mar Class 6' key='Mar Class 6' value='Class 6'>Class 6</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass7SecA') ? 'block' : 'none' }} id='Mar Class 7' key='Mar Class 7' value='Class 7'>Class 7</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass8SecA') ? 'block' : 'none' }} id='Mar Class 8' key='Mar Class 8' value='Class 8'>Class 8</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass9SecA') ? 'block' : 'none' }} id='Mar Class 9' key='Mar Class 9' value='Class 9'>Class 9</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass10SecA') ? 'block' : 'none' }} id='Mar Class 10' key='Mar Class 10' value='Class 10'>Class 10</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass11ArtsSecA') ? 'block' : 'none' }} id='Mar Class 11(Arts)' key='Mar Class 11(Arts)' value='Class 11(Arts)'>Class 11(Arts)</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass11CommerceSecA') ? 'block' : 'none' }} id='Mar Class 11(Commerce)' key='Mar Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass12ArtsSecA') ? 'block' : 'none' }} id='Mar Class 12(Arts)' key='Mar Class 12(Arts)' value='Class 12(Arts)'>Class 12(Arts)</MenuItem>
                                    <MenuItem style={{ display: childData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass12CommerceSecA') ? 'block' : 'none' }} id='Mar Class 12(Commerce)' key='Mar Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>
                                </Select>
                                <FormHelperText id="childClass"><p>{showEnteredParents.childClass.helperText}</p></FormHelperText>
                            </FormControl>

                            <FormControl error={showEnteredParents.childSection.error} name='childSection' variant="outlined">
                                <InputLabel htmlFor="childSection">Section :</InputLabel>
                                <Select
                                    sx={{ width: '30ch' }}
                                    id="childSection"
                                    name='childSection'
                                    // native 
                                    label="Section"
                                    inputProps={{
                                        name: 'childSection',
                                        id: 'childSection',
                                    }}
                                    value={childData.childSection}
                                    onChange={handleChangeChildSection}
                                >
                                    <MenuItem style={{ display: childData.childClass === '' ? 'block' : 'none' }} aria-label="None" value="" />

                                    <MenuItem style={{ display: returnedChildsSectionList?.SectionA === true ? 'block' : 'none' }} key='Section A' value='Section A'>Section A</MenuItem>
                                    <MenuItem style={{ display: returnedChildsSectionList?.SectionB === true ? 'block' : 'none' }} key='Section B' value='Section B'>Section B</MenuItem>
                                    <MenuItem style={{ display: returnedChildsSectionList?.SectionC === true ? 'block' : 'none' }} key='Section C' value='Section C'>Section C</MenuItem>
                                    <MenuItem style={{ display: returnedChildsSectionList?.SectionD === true ? 'block' : 'none' }} key='Section D' value='Section D'>Section D</MenuItem>
                                    <MenuItem style={{ display: returnedChildsSectionList?.SectionE === true ? 'block' : 'none' }} key='Section E' value='Section E'>Section E</MenuItem>
                                </Select>
                                <FormHelperText id="childSection"><p>{showEnteredParents.childSection.helperText}</p></FormHelperText>
                            </FormControl>
                        </div>

                        <LoadingButton
                            onClick={sendRequestChild1}
                            variant='outlined'
                            loading={btnLoading}
                        >
                            Send Verification Request to School
                        </LoadingButton>
                        {/* <Button disabled={actionsPerformed.buttonDisabled} >Send Verification Request to School</Button> */}
                        {actionsPerformed.msgSent && (<h5 id='requestSent5' >Child's Request Sent to School..</h5>)}
                    </div>
                )
            }
        </div>
    )
}

export default SidebarESchoolingStatusAddParent
