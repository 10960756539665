import React, { useEffect, useState } from 'react'

import '../VerifiedMembers/SchoolVerifiedMembersParents.css'
import '../../Pages/SchoolVerifiedMembers.css'
import { Link } from 'react-router-dom';
import { doc, onSnapshot, writeBatch } from 'firebase/firestore';
import { Avatar, Box, Button, Modal } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function SchoolVerifiedMembersParentsChild(props) {
    const { childsClass, childsSection, parentsId, parentsPathCollection, parentsMedClsSec,
        schoolId, schoolShortName, schoolFullName, schoolAffiliation, childsId, parentsDP } = props

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const batch1 = writeBatch(dB)

    const [parentRegName, setparentRegName] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (parentsId && parentsPathCollection) {
                onSnapshot(doc(dB, "USERS", parentsId, parentsPathCollection, "REGISTRATION_INFO"), (snap) => {
                    if (snap.exists()) {
                        setparentRegName(snap.data()?.USER_NAME)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [parentsId, parentsPathCollection])

    const [childsData, setchildsData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (parentsId && parentsPathCollection) {
                onSnapshot(doc(dB, "USERS", parentsId, parentsPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", childsId), (snap) => {
                    if (snap.exists()) {
                        setchildsData(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [childsId, parentsId, parentsPathCollection])

    const [userGender, setuserGender] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (parentsId && parentsPathCollection) {
                onSnapshot(doc(dB, "USERS", parentsId, parentsPathCollection, "REGISTRATION_INFO"), (snap) => {
                    if (snap.exists()) { setuserGender(snap.data()) }
                })
            }
        }
        return () => { aaa = false }
    }, [childsId, parentsId, parentsPathCollection])


    const [btnLoading, setbtnLoading] = useState(false)

    const handleParentRemoveChild1 = async (e) => {
        e.preventDefault();
        setbtnLoading(true)

        await batch1.delete(doc(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", parentsMedClsSec, "PARENTS", "VERIFICATION_REQUESTS", "VERIFICATION_REQUESTS_DATA", parentsId, "CHILDS_LIST", childsId))

        await batch1.update(doc(dB, "USERS", parentsId, parentsPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", childsId), {
            VERIFIED_CHILD: false,
        })

        await batch1.commit().then(() => {
            setOpen(false)
            setbtnLoading(false)
        }).catch(err => alert(err.message));


    }

    return (
        <div className='SchoolVerifiedMembersParents' >
            <div className='schoolverifiedMembers__containerRightParentsGuardiansData' >
                <div className='schoolverifiedMembers__containerRightParentsGuardiansDataName dataNameParent'>
                    <Link to={`/friendsprofile/${parentsId}`} style={{ textDecoration: 'none', color: 'gray' }}>
                        <Avatar
                            alt={parentRegName}
                            src={parentsDP}
                            sx={{ width: 40, height: 40, }}
                        />
                    </Link>
                    <h3>
                        <Link to={`/friendsprofile/${parentsId}`} style={{ textDecoration: 'none', color: 'black' }}>
                            {parentRegName}
                        </Link>
                    </h3>
                </div>
                <h5>{userGender?.USER_GENDER === 'Female' ? 'Mother ' : 'Father '}of</h5>
                <div className='schoolverifiedMembers__containerRightParentsGuardiansDataName'>
                    <Avatar
                        alt={childsData?.CHILDS_FULL_NAME}
                        src={childsData?.CHILD_DISPLAY_PIC}
                        sx={{ width: 40, height: 40, }}
                    />
                    <h3>{childsData?.CHILDS_FULL_NAME}</h3>
                </div>
                <div className='schoolverifiedMembers__containerRightParentsGuardiansDataButtons'>
                    <Button onClick={handleOpen}>Remove</Button>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div className='removeVerifiedUser'>
                                <div className='removeVerifiedUserText'>
                                    <h4>Do you really want to remove <br />
                                        <strong>{parentRegName}</strong> {userGender?.USER_GENDER === 'Female' ? 'Mother' : 'Father'} of <strong>{childsData?.CHILDS_FULL_NAME}</strong> from your school's list?</h4>
                                </div>
                                <div className='removeVerifiedUserButtons'>
                                    <LoadingButton
                                        variant='outlined'
                                        loading={btnLoading}
                                        onClick={handleParentRemoveChild1}
                                    >
                                        Yes
                                    </LoadingButton>
                                    <Button onClick={handleClose}>No</Button>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default SchoolVerifiedMembersParentsChild
