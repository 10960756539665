import React, { useState } from 'react'
import '../ProjectsHomeworks/ProjectNHomework.css'
// import dB from '../firebase';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Modal } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dB from '../../firebase';

const imageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
};

function ProjectNHomework(props) {
    const {
        proNHomeId, uploadedOn, submitOn, questionStatement, questionImage, questionNote,
        userId, schoolId, schoolPathCollection, medClsSec, subjectName, userCurrentStatus,
        assignment, project, homework, homeworkId, assignmentId, projectId, viewedBy
    } = props

    const [selectedPic, setselectedPic] = useState('')

    const [openImage, setOpenImage] = React.useState(false);
    const handleOpenImage = (pic) => {
        setOpenImage(true);
        setselectedPic(pic)
    }
    const handleCloseImage = () => setOpenImage(false);

    const handleViewHW = () => {
        if (userCurrentStatus === 'PARENT' || userCurrentStatus === 'STUDENT') {
            if (!viewedBy.includes(userId)) {
                if (homework === true) {
                    updateDoc(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "CLASSES_DETAILS", "CLASSES", medClsSec, "PROJECTS_&_HOMEWORKS", subjectName, "HOMEWORK", homeworkId), {
                        VIEWED_BY: arrayUnion(userId)
                    }).catch(err => alert(err.message))
                } else if (assignment === true) {
                    updateDoc(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "CLASSES_DETAILS", "CLASSES", medClsSec, "PROJECTS_&_HOMEWORKS", subjectName, "ASSIGNMENT", assignmentId), {
                        VIEWED_BY: arrayUnion(userId)
                    }).catch(err => alert(err.message))
                } else {
                    updateDoc(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "CLASSES_DETAILS", "CLASSES", medClsSec, "PROJECTS_&_HOMEWORKS", subjectName, "PROJECT", projectId), {
                        VIEWED_BY: arrayUnion(userId)
                    }).catch(err => alert(err.message))
                }
            }

        }
    }

    return (
        <div className='ProjectNHomework' >
            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={handleViewHW}
                >
                    {
                        !viewedBy.includes(userId) && (userCurrentStatus === 'PARENT' || userCurrentStatus === 'STUDENT') ? (
                            <Badge color="secondary" badgeContent=" 10" variant="dot" anchorOrigin={{ vertical: 'top', horizontal: 'left', }}>
                                <div key='' className='PrincipalClassNSubject princiAccordianSummary'>
                                    <div className='PrincipalClassNSubjectHead subRowPanelDataUploadedOn'>
                                        <h4>Uploaded On :</h4>
                                        <h3>{uploadedOn?.toDate().toDateString()}</h3>
                                    </div>
                                    <div className='PrincipalClassNSubjectHead subRowPanelDataSubmitOn'>
                                        <h4>Sumbit On/Before :</h4>
                                        <h3>{submitOn}</h3>
                                    </div>
                                </div>
                            </Badge>
                        ) : (
                            <div key='' className='PrincipalClassNSubject princiAccordianSummary'>
                                <div className='PrincipalClassNSubjectHead subRowPanelDataUploadedOn'>
                                    <h4>Uploaded On :</h4>
                                    <h3>{uploadedOn?.toDate().toDateString()}</h3>
                                </div>
                                <div className='PrincipalClassNSubjectHead subRowPanelDataSubmitOn'>
                                    <h4>Sumbit On/Before :</h4>
                                    <h3>{submitOn}</h3>
                                </div>
                            </div>
                        )
                    }

                </AccordionSummary>
                <AccordionDetails>
                    <div key='' className='subRowPanelDataAccordianData'>
                        {/* question */}
                        {
                            questionStatement && (
                                <div className='PrincipalClassNSubjectQuestion'>
                                    <h4><span style={{ color: 'var(--connected-color)' }}>Question : </span>{questionStatement}</h4>
                                </div>
                            )
                        }
                        {
                            questionImage && (
                                <div className='PrincipalClassNSubjectImage'>
                                    {/* image if any */}
                                    <img src={questionImage} alt={questionImage} onClick={() => handleOpenImage(questionImage)} />
                                    <Modal
                                        open={openImage}
                                        onClose={handleCloseImage}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={imageStyle}>
                                            <div className='profileImageBig'>
                                                <img src={selectedPic} alt={selectedPic} />
                                            </div>
                                        </Box>
                                    </Modal>
                                </div>
                            )
                        }

                        {/* submit on/before */}
                        <div className='subRowPanelDataAccordianDates'>
                            <h5>Uploaded On</h5>
                            <p>:</p>
                            <h4>{uploadedOn?.toDate().toDateString()}</h4>
                        </div>
                        <div className='subRowPanelDataAccordianDates'>
                            <h5>Submit On/Before</h5>
                            <p>:</p>
                            <h4>{submitOn}</h4>
                        </div>
                        {
                            questionNote && (
                                <div className='subRowPanelDataAccordianDates'>
                                    <h5>Note/Remark</h5>
                                    <p>:</p>
                                    <h4>{questionNote}</h4>
                                </div>
                            )
                        }

                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default ProjectNHomework
