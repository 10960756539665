import React, { useState } from 'react'
import '../CreateNewAccount/CreateNewAccountInfo.css';
import CreateNewAccountInfoStudents from './CreateNewAccountInfoStudents';
import CreateNewAccountInfoParents from './CreateNewAccountInfoParents';
import CreateNewAccountInfoTeachers from './CreateNewAccountInfoTeachers';
import CreateNewAccountInfoUser from './CreateNewAccountInfoUser';

import { Button, FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material';

function CreateNewAccountInfo(props) {
    const { fName, lName, userFullName, userMobNum, userPwd, userGender, userDOB, userEmailId } = props
    const [value, setValue] = useState('');
    const [showBlock, setShowBlock] = useState('')
    const [hideBlock, setHideBlock] = useState('');
    const [showEnteredRadio, setshowEnteredRadio] = useState({
        UserType: { error: false, helperText: '' },
    })
    const handleChange = (event) => {
        event.preventDefault();
        setValue(event.target.value);
        setshowEnteredRadio({ UserType: { error: false, helperText: '' } })
    };

    const showSelected = (event) => {
        event.preventDefault();
        if (!value) {
            setshowEnteredRadio({ UserType: { error: true, helperText: 'Please specify yourself by selecting an appropriate option' } })
        } else {
            setHideBlock('hide');
            setShowBlock(value)
        }
    }

    return (
        <div className='createnewaccountinfo'>
            <div className='createnewaccountinfo__container'>
                <h1>ConnectEdApp</h1>
                <p>Add your Educational Information and other Details</p>
                <hr />
                <div style={{ display: hideBlock === 'hide' ? 'none' : 'flex' }} id='createnewaccountinfo__containerSpecify' className='createnewaccountinfo__containerSpecify'>
                    <div id='createnewaccountinfo__containerSpecifyTop' className='createnewaccountinfo__containerSpecifyTop'>
                        <h2>Who are You? </h2>
                        <FormControl >
                            <RadioGroup aria-label="UserType" name="UserType" value={value} error={showEnteredRadio.UserType.error} onChange={handleChange}>
                                <FormControlLabel sx={{ marginBottom: '20px' }} value="Student" control={<Radio />} label={<div className='option'><h4>Student</h4><p>(If you are studying in 8th - 12th)</p></div>} />
                                <FormControlLabel sx={{ marginBottom: '20px' }} value="Parents" control={<Radio />} label={<div className='option'><h4>Parent/Local-Guardian</h4><p>(If your child is studying in KG1 to 12th Class)</p></div>} />
                                <FormControlLabel sx={{ marginBottom: '20px' }} value="Teacher" control={<Radio />} label={<div className='option'><h4>Teacher/Principal</h4><p>(If you are teaching KG-1 to 12th Class Students)</p></div>} />
                                <FormControlLabel sx={{ marginBottom: '20px' }} value="JustaUser" control={<Radio />} label={<div className='option'><h4>Just a User</h4><p>(If you don't want to use educational features)</p></div>} />
                            </RadioGroup>
                            <FormHelperText id="NewUserPassword"><p>{showEnteredRadio.UserType.helperText}</p></FormHelperText>
                        </FormControl>
                    </div>
                    <Button onClick={showSelected}>Next</Button>
                </div>
                {
                    showBlock === 'Student' ? (
                        <CreateNewAccountInfoStudents
                            fName={fName}
                            lName={lName}
                            userFullName={userFullName}
                            userMobNum={userMobNum}
                            userPwd={userPwd}
                            userGender={userGender}
                            userDOB={userDOB}
                            userEmailId={userEmailId}
                        />
                    ) : showBlock === 'Parents' ? (
                        <CreateNewAccountInfoParents
                            fName={fName}
                            lName={lName}
                            userFullName={userFullName}
                            userMobNum={userMobNum}
                            userPwd={userPwd}
                            userGender={userGender}
                            userDOB={userDOB}
                            userEmailId={userEmailId}
                        />
                    ) : showBlock === 'Teacher' ? (
                        <CreateNewAccountInfoTeachers
                            fName={fName}
                            lName={lName}
                            userFullName={userFullName}
                            userMobNum={userMobNum}
                            userPwd={userPwd}
                            userGender={userGender}
                            userDOB={userDOB}
                            userEmailId={userEmailId}
                        />
                    ) : showBlock === 'JustaUser' ? (
                        <CreateNewAccountInfoUser
                            fName={fName}
                            lName={lName}
                            userFullName={userFullName}
                            userMobNum={userMobNum}
                            userPwd={userPwd}
                            userGender={userGender}
                            userDOB={userDOB}
                            userEmailId={userEmailId}
                        />
                    ) : (<></>)
                }
            </div>
        </div>
    )
}

export default CreateNewAccountInfo
