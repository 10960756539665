import React, { useEffect, useState } from 'react'
import { functionClsStr } from '../../Constants/FunctionClsStr'
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec'
import { doc, onSnapshot } from 'firebase/firestore'
import dB from '../../firebase'

import { Box, Modal } from '@mui/material';

const imageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
};

function SchoolTimeTableUsersTeachersClassData(props) {

    const { teacherMedium, teacherClass, teacherSection, teacherSubject, userSchoolId, userSchoolPathCollection } = props

    const [open, setOpen] = React.useState(false);
    // const [openedImg, setopenedImg] = useState('')
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [classPath, setclassPath] = useState('')
    useEffect(() => {
        if (teacherMedium && teacherClass && teacherSection) {
            const abc = returnMedClsSec(teacherMedium, teacherClass, teacherSection)
            setclassPath(abc)
        }
    }, [teacherClass, teacherMedium, teacherSection])


    const [timeTableData, settimeTableData] = useState(null)
    useEffect(() => {
        // var aaa = true
        // if (aaa === true) {
        if (userSchoolId && userSchoolPathCollection && classPath) {
            onSnapshot(doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", classPath, "TIMETABLE", "TIMETABLE_IMAGE"), (snap) => {
                if (snap.exists()) {
                    settimeTableData(snap.data())
                }
            })
        }
        // }
        // return () => { aaa = false }
    }, [classPath, userSchoolId, userSchoolPathCollection])


    return (
        <div>
            <div className='headingHeading'>
                <h4>{teacherMedium} | {teacherClass} | {teacherSection} </h4>
                <div className='headingHeadingSubject'>
                    <h2>Subject</h2>
                    <h3>:</h3>
                    <h4>{teacherSubject}</h4>
                </div>
                <hr />
            </div>

            <div className='recentlyAdddedHW recentlyAdddedHead'>
                <h2>Recently Published Timetable</h2>
                {
                    timeTableData ? (
                        <>
                            <img
                                style={{ width: '90%', borderRadius: '10px', marginTop: '10px' }}
                                src={timeTableData?.TIMETABLE_IMAGE}
                                alt={timeTableData?.TIMETABLE_IMAGE}
                                onClick={handleOpen}
                            />
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={imageStyle}>
                                    <div className='imageBig'>
                                        <img src={timeTableData?.TIMETABLE_IMAGE} alt={timeTableData?.TIMETABLE_IMAGE} />
                                    </div>
                                </Box>
                            </Modal>
                        </>
                    ) : (
                        <>
                            <h3 style={{ fontSize: '18px', fontWeight: '700', color: 'var(--connected-orange)', textAlign: 'center' }}>No timetable has been updated by school yet.</h3>
                        </>
                    )
                }


            </div>
        </div>
    )
}

export default SchoolTimeTableUsersTeachersClassData