import React, { useState } from 'react'
import '../UpcomingEvents/SchoolUpcomingEventData.css'

import { deleteDoc, doc } from 'firebase/firestore';

import { LoadingButton } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Modal } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dB from '../../firebase';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function SchoolUpcomingEventData(props) {

    const { eventName, eventDate, eventDescription, eventCrowd,
        chiefGuests, eventId, SchoolFriendProfile, userSchoolId,
        userSchoolPathCollection, eventType, uploadedOn
    } = props

    const [btnLoading, setbtnLoading] = useState(false)

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = (e) => { setOpenModal(true); };
    const handleCloseModal = (e) => { setOpenModal(false); };

    const handleClickDeleteEvent = (e) => {
        e.preventDefault();
        setbtnLoading(true)
        if (userSchoolId !== null) {
            deleteDoc(doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "SCHOOL_EVENTS", "ALL_EVENTS", eventId))
                .then(() => {
                    setbtnLoading(false)
                    setOpenModal(false);
                }).catch(err => alert(err.message))
        }
    }

    return (
        <div className='SchoolUpcomingEventData' >
            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className='SchoolUpcomingEventData_AccordionSummary'>
                        <div className='summary_Data nameEvent'>
                            <h3>Event Name</h3>
                            <h5>:</h5>
                            <h4>{eventName}</h4>
                        </div>
                        <div className='summary_Data dateEvent'>
                            <h3>Event Type</h3>
                            <h5>:</h5>
                            <h4>{eventType}</h4>
                        </div>
                        {/* <Button disabled={btnLoading} style={{ display: SchoolFriendProfile ? 'none' : 'flex' }} onClick={handleClickDeleteEvent}>Delete</Button> */}
                        <Button style={{ display: SchoolFriendProfile ? 'none' : 'flex' }} onClick={handleOpenModal}>Delete</Button>
                    </div>
                    <Modal
                        open={openModal}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div className='deletePostModal'>
                                <div className='deletePostModalText'>
                                    <h4 style={{ textAlign: 'center' }}>Do you really want to delete this EVENT of "{eventName}"?</h4>
                                </div>
                                <div className='deletePostModalButtons'>
                                    <LoadingButton
                                        onClick={handleClickDeleteEvent}
                                        loading={btnLoading}
                                        variant="outlined"
                                    >
                                        Yes
                                    </LoadingButton>
                                    {/* <Button disabled={} >Yes</Button> */}
                                    <Button onClick={handleCloseModal}>No</Button>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='SchoolUpcomingEventData_AccordionDetails'>
                        <div className='eventRow alignCenter'>
                            <h3>Event Name</h3>
                            <h5>:</h5>
                            <h4><b>{eventName}</b></h4>
                        </div>

                        <div className='eventRow alignCenter'>
                            <h3>Event Type</h3>
                            <h5>:</h5>
                            <h4>{eventType} Event</h4>
                        </div>

                        <div className='eventRow alignCenter'>
                            <h3>Date</h3>
                            <h5>:</h5>
                            <h4>{eventDate} (YYYY-MM-DD)</h4>
                        </div>
                        {
                            eventDescription && (
                                <div className='eventRow'>
                                    <h3>Description</h3>
                                    <h5>:</h5>
                                    <h4>{eventDescription}</h4>
                                </div>
                            )
                        }

                        {/* event Chief Guests */}
                        {
                            chiefGuests?.length > 0 && (
                                <div className='eventRow'>
                                    <h3>Chief Guests</h3>
                                    <h5>:</h5>
                                    <div className='eventRowChiefGuests'>
                                        <h4 style={{ display: chiefGuests[0] ? 'flex' : 'none' }}><b>1. {chiefGuests[0]}</b></h4>
                                        <h4 style={{ display: chiefGuests[1] ? 'flex' : 'none' }}><b>2. {chiefGuests[1]}</b></h4>
                                        <h4 style={{ display: chiefGuests[2] ? 'flex' : 'none' }}><b>3. {chiefGuests[2]}</b></h4>
                                        <h4 style={{ display: chiefGuests[3] ? 'flex' : 'none' }}><b>4. {chiefGuests[3]}</b></h4>
                                    </div>
                                </div>
                            )
                        }

                        {/* event Crowd */}
                        <div className='eventRow alignCenter'>
                            <h3>Event Status</h3>
                            <h5>:</h5>
                            <h4><b>{eventCrowd}</b></h4>
                        </div>

                        <div className='eventRow alignCenter'>
                            <h3>Uploaded on</h3>
                            <h5>:</h5>
                            <h4>{uploadedOn?.toDate().toDateString()}</h4>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default SchoolUpcomingEventData