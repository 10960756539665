import React, { useState, useEffect } from 'react'
import '../ProjectsHomeworks/HomeWorksParents.css'
import '../ComplainsAndFeedback/Complains.css'
import '../LeaveApplication/LeaveApp.css'
import '../../Pages/SidebarNoticeBoard.css'
import '../ProjectsHomeworks/ProjectNHomework.css'
import { addDoc, collection, doc, onSnapshot, serverTimestamp, setDoc } from 'firebase/firestore'
import dB from '../../firebase'
import { Accordion, AccordionDetails, AccordionSummary, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import subDays from "date-fns/subDays";
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SentApplication from './SentApplication'

function LeaveApplicationParents(props) {

    const { userId, userPathCollection, userCurrentStatus, childSchoolId, childId, ischildVerified } = props

    const [childsSchoolName, setchildsSchoolName] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (childSchoolId) {
                onSnapshot(doc(dB, "SCHOOLS", childSchoolId), (childDOc) => {
                    if (childDOc.exists()) {
                        setchildsSchoolName(childDOc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [childSchoolId])

    const [childsData, setchildsData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection && childId) {
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", childId), (childDOc) => {
                    if (childDOc.exists()) {
                        setchildsData(childDOc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [childId, userId, userPathCollection])

    const [childMedClsSec, setchildMedClsSec] = useState('')
    useEffect(() => {
        var aaa = true
        setchildMedClsSec('')
        if (aaa === true) {
            if (childsData?.CHILDS_MEDIUM && childsData?.CHILDS_CLASS && childsData?.CHILDS_SECTION) {
                var abc = returnMedClsSec(childsData?.CHILDS_MEDIUM, childsData?.CHILDS_CLASS, childsData?.CHILDS_SECTION)
                setchildMedClsSec(abc)
            }
        }
        return () => { aaa = false }
    }, [childsData])

    const [btnLoading, setbtnLoading] = useState(false)

    const [oneDay, setoneDay] = useState("One Day")
    const handleChangeRadio = (e) => {
        e.preventDefault();
        setoneDay(e.target.value)
    }

    const [sentText, setsentText] = useState(false)
    const [leaveAppSubject, setleaveAppSubject] = useState("")
    const [leaveAppBody, setleaveAppBody] = useState("")
    const [leaveAppDate, setleaveAppDate] = useState('')
    const handleGetSubject = (e) => {
        e.preventDefault()
        setleaveAppSubject(e.target.value)
        setshowError(prev => { return { ...prev, leaveAppSubject: { error: false, helperText: '' } } })
        setsentText(false)
    }
    const handleGetBody = (e) => {
        e.preventDefault()
        setleaveAppBody(e.target.value)
        setshowError(prev => { return { ...prev, leaveAppBody: { error: false, helperText: '' } } })
        setsentText(false)
    }
    const handleChangeSubmitDate = (event) => {
        event.preventDefault();
        setleaveAppDate(event.target.value)
        setshowError(prev => { return { ...prev, leaveAppDate: 'hide' } })
        setsentText(false)
    }
    const [showError, setshowError] = useState({
        leaveAppSubject: { error: false, helperText: '' },
        leaveAppBody: { error: false, helperText: '' },
        leaveAppDate: 'hide'
    })

    const dateToday = new Date()
    var nowDate = dateToday.getDate();
    var nowMonth = dateToday.getMonth() + 1
    var nowYear = dateToday.getFullYear()

    if (nowMonth < 10) { nowMonth = '0' + nowMonth.toString(); }
    if (nowDate < 10) { nowDate = '0' + nowDate.toString(); }

    var minDate = nowYear + '-' + nowMonth + '-' + nowDate;


    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    // const batch=writeBatch(dB)
    const handleSendLeaveApplication = (e) => {
        e.preventDefault()
        setbtnLoading(true)
        if (oneDay === 'One Day') {
            if (leaveAppSubject && leaveAppBody && leaveAppDate) {



                addDoc(collection(dB, "SCHOOLS", childSchoolId, childsSchoolName?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", childMedClsSec, "LEAVE_APPLICATION", "RECIEVED", "RECIEVED_APPLICATIONS"), {
                    APPLICATION_STATUS: 'SENT',
                    CHILD_ID: childId,
                    APPLICANT_ID: userId,
                    APPLICANT_PATH_COLLECTION: userPathCollection,
                    APPLICANT_STATUS: userCurrentStatus,
                    TIMESTAMP: serverTimestamp(),
                    ONE_DAY: true,
                    ON_DATE: leaveAppDate,
                    APPLICATION_SUBJECT: leaveAppSubject,
                    APPLICATION_BODY: leaveAppBody,
                    FROM_TO_DATE: {
                        START_DATE: "",
                        END_DATE: ""
                    },
                    VIEWED_BY: false
                }).then((docRef) => {

                    setDoc(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", childId, "LEAVE_APPLICATIONS", docRef.id), {
                        APPLICATION_STATUS: 'SENT',
                        TIMESTAMP: serverTimestamp(),
                        ONE_DAY: true,
                        ON_DATE: leaveAppDate,
                        APPLICATION_SUBJECT: leaveAppSubject,
                        APPLICATION_BODY: leaveAppBody,
                        FROM_TO_DATE: {
                            START_DATE: "",
                            END_DATE: ""
                        }
                    }).then(() => {
                        setsentText(true)
                        setbtnLoading(false)
                        setleaveAppSubject('')
                        setleaveAppBody('')
                        setleaveAppDate('')
                    }).catch((err) => {
                        alert(err);
                    })
                }).catch((err) => {
                    alert(err);
                })
            } else {
                setbtnLoading(false)
                if (!leaveAppSubject) { setshowError(prev => { return { ...prev, leaveAppSubject: { error: true, helperText: 'Please enter the subject of your leave application' } } }) }
                if (!leaveAppBody) { setshowError(prev => { return { ...prev, leaveAppBody: { error: true, helperText: 'Please enter the leave application information' } } }) }
                if (!leaveAppDate) { setshowError(prev => { return { ...prev, leaveAppDate: 'show' } }) }
            }
        } else {
            if (leaveAppSubject && leaveAppBody && startDate && endDate) {

                setbtnLoading(false)
                setsentText(true)
                addDoc(collection(dB, "SCHOOLS", childSchoolId, childsSchoolName?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", childMedClsSec, "LEAVE_APPLICATION", "RECIEVED", "RECIEVED_APPLICATIONS"), {
                    APPLICATION_STATUS: 'SENT',
                    CHILD_ID: childId,
                    APPLICANT_ID: userId,
                    APPLICANT_PATH_COLLECTION: userPathCollection,
                    APPLICANT_STATUS: userCurrentStatus,
                    TIMESTAMP: serverTimestamp(),
                    ONE_DAY: false,
                    ON_DATE: "",
                    APPLICATION_SUBJECT: leaveAppSubject,
                    APPLICATION_BODY: leaveAppBody,
                    FROM_TO_DATE: {
                        START_DATE: startDate,
                        END_DATE: endDate
                    },
                    VIEWED_BY: false
                }).then((docRef) => {
                    setDoc(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", childId, "LEAVE_APPLICATIONS", docRef.id), {
                        APPLICATION_STATUS: 'SENT',
                        TIMESTAMP: serverTimestamp(),
                        ONE_DAY: false,
                        ON_DATE: "",
                        APPLICATION_SUBJECT: leaveAppSubject,
                        APPLICATION_BODY: leaveAppBody,
                        FROM_TO_DATE: {
                            START_DATE: startDate,
                            END_DATE: endDate
                        }
                    })
                    setsentText(true)
                    setbtnLoading(false)
                    setleaveAppSubject('')
                    setleaveAppBody('')
                    setleaveAppDate('')
                }).catch((err) => {
                    alert(err);
                })


            } else {
                setbtnLoading(false)
                if (!leaveAppSubject) { setshowError(prev => { return { ...prev, leaveAppSubject: { error: true, helperText: 'Please enter the subject of your leave application' } } }) }
                if (!leaveAppBody) { setshowError(prev => { return { ...prev, leaveAppBody: { error: true, helperText: 'Please enter the leave application information' } } }) }
                if (!startDate || !endDate) { setshowError(prev => { return { ...prev, leaveAppDate: 'show' } }) }
            }
        }


    }

    const [allApplications, setallApplications] = useState([])
    useEffect(() => {
        if (userId && userPathCollection && childId) {
            onSnapshot(collection(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", childId, "LEAVE_APPLICATIONS"), (snap) => {
                setallApplications(snap.docs.map((appDoc) => ({
                    applicationId: appDoc.id,
                    applicationData: appDoc.data()
                })))
            })
        }
    }, [childId, userId, userPathCollection])

    return (
        <div className='homeworkteachers'>
            <div className='homeworkteachers__top'>
                <h3>{childsSchoolName?.SCHOOL_FULL_NAME}</h3>
                <h4>Leave Aplication</h4>
                <hr />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className='childName'>
                    <h3>Students Name</h3>
                    <h4>:</h4>
                    <div className='childNameName'>
                        <h5>{childsData?.CHILDS_FULL_NAME}</h5>
                        <p>({childsData?.CHILDS_MEDIUM} · {childsData?.CHILDS_CLASS} · {childsData?.CHILDS_SECTION})</p>
                    </div>
                </div>

                <div className='sendApplication'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        // onClick={handleViewHW}
                        >
                            <div className='headingLeave'>
                                <h3>Send Leave Application Here</h3>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='leaveOption'>
                                <h3>Leave Application for</h3>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={handleChangeRadio}
                                        value={oneDay}
                                    >
                                        <FormControlLabel value="One Day" control={<Radio />} label="One Day" />
                                        <FormControlLabel value="More than One Day" control={<Radio />} label="More than One Day" />
                                    </RadioGroup>
                                </FormControl>
                            </div>


                            <div className='oneDay'>
                                <h3>Leave Application</h3>
                                {
                                    oneDay === 'One Day' ? (
                                        <div className='leavedatedata'>
                                            <div className='leaveDate' value={leaveAppDate} onChange={handleChangeSubmitDate}>
                                                <p>Date</p>
                                                <input type="date" min={minDate} />
                                            </div>
                                            {
                                                showError.leaveAppDate === 'show' && <p>please select date</p>
                                            }
                                        </div>
                                    ) : (
                                        <div className='leavedatedata'>
                                            <div className='leaveDate' value={leaveAppDate} onChange={handleChangeSubmitDate}>
                                                <p>Date</p>
                                                <DatePicker
                                                    placeholderText='Select date in range here.'
                                                    selectsRange={true}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    onChange={(update) => {
                                                        setDateRange(update);
                                                        setshowError(prev => { return { ...prev, leaveAppDate: 'hide' } })
                                                    }}
                                                    isClearable={true}
                                                    minDate={subDays(new Date(), 5)}
                                                />
                                            </div>
                                            {
                                                showError.leaveAppDate === 'show' && <p>please select start and end date</p>
                                            }
                                        </div>
                                    )
                                }


                                <FormControl error={showError.leaveAppSubject.error} name='leaveAppSubject' sx={{ width: '75ch' }} variant="outlined">
                                    <InputLabel htmlFor="leaveAppSubject"> Subject </InputLabel>
                                    <OutlinedInput
                                        name='leaveAppSubject'
                                        id="leaveAppSubject"
                                        type='text'
                                        value={leaveAppSubject}
                                        onChange={handleGetSubject}
                                        label='Subject '
                                    />
                                    <FormHelperText id="leaveAppSubject"><p>{showError.leaveAppSubject.helperText}</p></FormHelperText>
                                </FormControl>

                                <div className='sendNotice__noticeBody'>
                                    <TextField
                                        error={showError.leaveAppBody.error}
                                        sx={{ width: '75ch' }}
                                        name='leaveAppBody'
                                        value={leaveAppBody}
                                        onChange={handleGetBody}
                                        id="leaveAppBody"
                                        type='text'
                                        label='Type your Leave Application here..'
                                        multiline
                                        rows={4}
                                        rowsMax={6}
                                        variant="outlined"
                                    />
                                    <FormHelperText id="noticefrom"><p>{showError.leaveAppBody.helperText}</p></FormHelperText>
                                </div>

                                <LoadingButton
                                    loading={btnLoading}
                                    onClick={handleSendLeaveApplication}
                                >Send Application
                                </LoadingButton>
                                {
                                    sentText && (<h5>Leave Application Sent.</h5>)
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className='sentApplications'>
                    <h3>Sent Leave Applications</h3>

                    {
                        allApplications.map((app, i) => (
                            <SentApplication
                                key={i}
                                applicationId={app?.applicationId}
                                applicationData={app?.applicationData}
                            />
                        ))
                    }
                    <div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default LeaveApplicationParents