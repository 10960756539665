import React, { useEffect, useState } from 'react'
import '../CreateNewAccount/CreateNewAccountInfoTeachers.css';

// import dB, { auth } from '../firebase';
import { collection, doc, onSnapshot, serverTimestamp, writeBatch } from 'firebase/firestore';
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { useHistory } from 'react-router';

import {
    Autocomplete, FormControl, FormControlLabel, FormHelperText, FormLabel,
    InputLabel, MenuItem, Radio, RadioGroup, Select, TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB, { auth } from '../../firebase';
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec';
import { returnSection } from '../../Constants/FuncMedium';

function CreateNewAccountInfoTeachers(props) {
    const { fName, lName, userFullName, userMobNum, userPwd, userGender, userDOB, userEmailId } = props

    const history = useHistory();

    // Get a new write batch
    var batch = writeBatch(dB)

    const [requestSentData, setrequestSentData] = useState({
        requestSentMsg: false, goToProfileButton: false, hideDive: false, btnDisabled: false, btnLoginDisabled: false
    })


    const [showEnteredTeachers, setshowEnteredTeachers] = useState({
        schoolName: { error: false },
        teacherClass1Medium: { error: false, helperText: '' },
        teacherClass1: { error: false, helperText: '' },
        teacherClass1Sec: { error: false, helperText: '' },
        teacherClass1SecSub: { error: false, helperText: '' },
    })

    const [inputTeachersSchoolName, setinputTeachersSchoolName] = useState(null);
    const [parentsInputValue, setparentsInputValue] = useState('');

    // fetch school names for teachers to select
    const [teachersOptions, setteachersOptions] = useState([])
    useEffect(() => {
        if (teachersOptions.length > 0) {
            teachersOptions.splice(0, teachersOptions.length)
        }
        var aaa = true
        if (aaa === true) {
            onSnapshot(collection(dB, "SCHOOLS"), (querySnapshot) => {
                querySnapshot.forEach((schoolDoc) => {
                    const newdata = schoolDoc.data()?.SCHOOL_FULL_NAME;
                    setteachersOptions(prev => { return [...prev, { id: schoolDoc.id, schoolName: newdata }] })
                });
            })
        }
        return () => { aaa = false }
    }, [])

    // fetch school id's for teachers to select
    const [teacherOptionSchoolId, setteacherOptionSchoolId] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (inputTeachersSchoolName) {
                teachersOptions.map(option => {
                    if (option.schoolName === inputTeachersSchoolName) {
                        setteacherOptionSchoolId(option.id)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [inputTeachersSchoolName, teachersOptions])

    const [teacherData, setTeacherData] = useState({
        teacherClass1Medium: '', teacherClass1: '', teacherClass1Sec: '', teacherClass1SecSub: '',
    })

    const [teacherSchoolPath, setteacherSchoolPath] = useState({ schoolSmallName: '', schoolId: '', })
    const [returnedTeachersClassList, setreturnedTeachersClassList] = useState([])
    // Fetching Classes
    useEffect(() => {
        if (returnedTeachersClassList.length > 0) {
            returnedTeachersClassList.splice(0, returnedTeachersClassList.length)
        }
        var aaa = true
        if (aaa === true) {
            if (inputTeachersSchoolName) {
                onSnapshot(collection(dB, "SCHOOLS"), (querySnapshot) => {
                    querySnapshot.forEach((queryDoc) => {
                        const newdata = queryDoc.data()?.SCHOOL_FULL_NAME;
                        if (newdata === inputTeachersSchoolName) {
                            const schoolCollection = queryDoc.data()?.SCHOOL_NAME;
                            setteacherSchoolPath(prev => { return { ...prev, schoolSmallName: schoolCollection, schoolId: queryDoc.id } })
                            onSnapshot(doc(dB, "SCHOOLS", queryDoc.id, schoolCollection, "CLASSES_DETAILS"), (clsDoc) => {
                                if (clsDoc.exists()) {
                                    setreturnedTeachersClassList(clsDoc.data()?.CLASS_LIST)
                                }
                            })
                        }
                    });
                })
            }
        }
        return () => { aaa = false }
    }, [inputTeachersSchoolName])

    // Setting the Teachers Medium Class n Section
    const [teacherMedClassSection, setteacherMedClassSection] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var abc = returnMedClsSec(teacherData.teacherClass1Medium, teacherData.teacherClass1, teacherData.teacherClass1Sec)
            setteacherMedClassSection(abc)
        }
        return () => { aaa = false }
    }, [teacherData.teacherClass1Medium, teacherData.teacherClass1, teacherData.teacherClass1Sec])


    const [returnedSubjectsList, setreturnedSubjectsList] = useState([])
    useEffect(() => {
        if (returnedSubjectsList.length > 0) {
            returnedSubjectsList.splice(0, returnedSubjectsList.length)
        }
        var aaa = true
        if (aaa === true) {
            if (teacherMedClassSection) {
                onSnapshot(doc(dB, "SCHOOLS", teacherSchoolPath.schoolId, teacherSchoolPath.schoolSmallName, "CLASSES_DETAILS", "CLASSES", teacherMedClassSection), (newClassDoc) => {
                    if (newClassDoc.exists()) {
                        setreturnedSubjectsList(newClassDoc.data().SUBJECTS_LIST)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [teacherSchoolPath, teacherData, teacherMedClassSection])



    // Fetching Sections
    const [returnedTeachersSectionList, setreturnedTeachersSectionList] = useState(null);
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            setreturnedTeachersSectionList(null)
            var sectionObject = returnSection(teacherData.teacherClass1Medium, teacherData.teacherClass1, returnedTeachersClassList)
            setreturnedTeachersSectionList(sectionObject)
        }
        return () => { aaa = false }
    }, [teacherData.teacherClass1Medium, teacherData.teacherClass1, returnedTeachersClassList])


    const [principalValue, setprincipalValue] = useState('No');
    const handleChangePrincipalValue = (event) => {
        event.preventDefault()
        setprincipalValue(event.target.value);
        seteitherOr('No')
    };

    const [teacherValue, setteacherValue] = useState('Yes');
    const handleChangeTeacherValue = (event) => {
        event.preventDefault()
        setteacherValue(event.target.value);
        seteitherOr('No')
        if (!teacherData.teacherClass1Medium) { setshowEnteredTeachers(prevState => { return { ...prevState, teacherClass1Medium: { error: false, helperText: '' } } }) }
        if (!teacherData.teacherClass1) { setshowEnteredTeachers(prevState => { return { ...prevState, teacherClass1: { error: false, helperText: '' } } }) }
        if (!teacherData.teacherClass1Sec) { setshowEnteredTeachers(prevState => { return { ...prevState, teacherClass1Sec: { error: false, helperText: '' } } }) }
        if (!teacherData.teacherClass1SecSub) { setshowEnteredTeachers(prevState => { return { ...prevState, teacherClass1SecSub: { error: false, helperText: '' } } }) }
    };

    const [firebaseErrorMessage, setfirebaseErrorMessage] = useState('')

    const [eitherOr, seteitherOr] = useState('No')

    // generate 14 digit random number
    const [randomKey, setrandomKey] = useState({ randomId1: '', })
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var a = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
            setrandomKey(prevState => {
                return {
                    ...prevState,
                    randomId1: a,
                }
            })
        }
        return () => { aaa = false }
    }, [])

    const [btnLoading, setbtnLoading] = useState(false)
    const handleSendVerificationRequest = async (e) => {
        e.preventDefault()
        setbtnLoading(true)
        setrequestSentData(prev => { return { ...prev, btnDisabled: true } })
        if (inputTeachersSchoolName) {
            if (teacherValue === 'Yes' || principalValue === 'Yes') {
                if (teacherValue === 'Yes') {
                    if (teacherData.teacherClass1Medium && teacherData.teacherClass1 && teacherData.teacherClass1Sec && teacherData.teacherClass1SecSub) {
                        await createUserWithEmailAndPassword(auth, userEmailId, userPwd)
                            .then(async (authUser) => {
                                await batch.set(doc(dB, "USERS", authUser.user.uid), {
                                    USER_CREATED_ON: serverTimestamp(),
                                    USER_EMAIL_ID: userEmailId,
                                    USER_NAME: userFullName,
                                    USER_FULL_NAME: userFullName
                                });

                                await batch.set(doc(dB, "USERS", authUser.user.uid, userFullName, "REGISTRATION_INFO"), {
                                    USER_NAME: userFullName,
                                    USER_MOB_NO: userMobNum,
                                    USER_PWD: userPwd,
                                    USER_GENDER: userGender,
                                    USER_DOB: userDOB,
                                    F_NAME: fName,
                                    L_NAME: lName,
                                });

                                if (principalValue === 'Yes' && teacherValue === 'Yes') {
                                    await batch.update(doc(dB, "USERS", authUser.user.uid), { USER_CURRENT_STATUS: 'PRINCIPAL' });
                                    await batch.set(doc(dB, "USERS", authUser.user.uid, userFullName, "E-SCHOOLING_INFO"), { USER_SCHOOL_ID: teacherOptionSchoolId });
                                } else {
                                    await batch.update(doc(dB, "USERS", authUser.user.uid), { USER_CURRENT_STATUS: 'TEACHER' });
                                    await batch.set(doc(dB, "USERS", authUser.user.uid, userFullName, "E-SCHOOLING_INFO"), { USER_SCHOOL_ID: teacherOptionSchoolId });
                                }


                                if (principalValue === 'Yes') {
                                    await batch.set(doc(dB, "SCHOOLS", teacherSchoolPath.schoolId, teacherSchoolPath.schoolSmallName, "PRINCIPAL_DETAILS", "VERIFICATION_REQUESTS", authUser.user.uid), {
                                        TIMESTAMP: serverTimestamp()
                                    });

                                    await batch.set(doc(dB, "USERS", authUser.user.uid, userFullName, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), {
                                        VERIFIED_PRINCIPAL: false,
                                        USER_STATUS: 'PRINCIPAL',
                                        USER_SCHOOL_ID: teacherOptionSchoolId
                                    });
                                }

                                await batch.set(doc(dB, "USERS", authUser.user.uid, userFullName, "E-SCHOOLING_INFO", "IS_TEACHER", "IS_TEACHER_DATA", "SUBJECTS_TEACH", randomKey.randomId1), {
                                    TEACH_MEDIUM: teacherData.teacherClass1Medium,
                                    TEACH_CLASS: teacherData.teacherClass1,
                                    TEACH_CLASS_SEC: teacherData.teacherClass1Sec,
                                    TEACH_CLASS_SEC_SUB: teacherData.teacherClass1SecSub,
                                    VERIFIED_TEACHER: false,
                                });
                                await batch.set(doc(dB, "USERS", authUser.user.uid, userFullName, "E-SCHOOLING_INFO", "IS_TEACHER", "VERIFICATION_DETAILS"), {
                                    USER_STATUS: 'TEACHER',
                                    VERIFIED_TEACHER: false,
                                    USER_SCHOOL_ID: teacherOptionSchoolId
                                });

                                await batch.set(doc(dB, "SCHOOLS", teacherSchoolPath.schoolId, teacherSchoolPath.schoolSmallName, "CLASSES_DETAILS", "CLASSES", teacherMedClassSection, "TEACHERS", "VERIFICATION_REQUESTS", teacherData.teacherClass1SecSub, authUser.user.uid), {
                                    TEACHERS_SUBJECT_ID: randomKey.randomId1,
                                    TIMESTAMP: serverTimestamp()
                                });

                                await batch.commit().then(async () => {
                                    setbtnLoading(false)
                                    setrequestSentData(prev => { return { ...prev, requestSentMsg: true, goToProfileButton: true, hideDive: true } })
                                }).catch((error) => { setfirebaseErrorMessage(error.message) });
                            }).catch((error) => { setfirebaseErrorMessage(error.message) });
                    }
                    else {
                        if (!teacherData.teacherClass1Medium) { setshowEnteredTeachers(prevState => { return { ...prevState, teacherClass1Medium: { error: true, helperText: 'Selection Required *' } } }) }
                        if (!teacherData.teacherClass1) { setshowEnteredTeachers(prevState => { return { ...prevState, teacherClass1: { error: true, helperText: 'Selection Required *' } } }) }
                        if (!teacherData.teacherClass1Sec) { setshowEnteredTeachers(prevState => { return { ...prevState, teacherClass1Sec: { error: true, helperText: 'Selection Required *' } } }) }
                        if (!teacherData.teacherClass1SecSub) { setshowEnteredTeachers(prevState => { return { ...prevState, teacherClass1SecSub: { error: true, helperText: 'Selection Required *' } } }) }
                    }
                } else {
                    await createUserWithEmailAndPassword(auth, userEmailId, userPwd)
                        .then(async (authUser) => {

                            await batch.set(doc(dB, "USERS", authUser.user.uid), {
                                USER_CREATED_ON: serverTimestamp(),
                                USER_EMAIL_ID: userEmailId,
                                USER_NAME: userFullName,
                                USER_CURRENT_STATUS: 'PRINCIPAL',
                                USER_FULL_NAME: userFullName
                            });

                            await batch.set(doc(dB, "USERS", authUser.user.uid, userFullName, "REGISTRATION_INFO"), {
                                USER_NAME: userFullName,
                                USER_MOB_NO: userMobNum,
                                USER_PWD: userPwd,
                                USER_GENDER: userGender,
                                USER_DOB: userDOB,
                            });

                            await batch.update(doc(dB, "USERS", authUser.user.uid), {
                                USER_CURRENT_STATUS: 'PRINCIPAL'
                            });
                            await batch.set(doc(dB, "USERS", authUser.user.uid, userFullName, "E-SCHOOLING_INFO"), {
                                USER_SCHOOL_ID: teacherOptionSchoolId
                            });

                            if (principalValue === 'Yes') {
                                await batch.set(doc(dB, "SCHOOLS", teacherSchoolPath.schoolId, teacherSchoolPath.schoolSmallName, "PRINCIPAL_DETAILS", "VERIFICATION_REQUESTS", authUser.user.uid), {
                                    TIMESTAMP: serverTimestamp()
                                });

                                await batch.set(doc(dB, "USERS", authUser.user.uid, userFullName, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), {
                                    VERIFIED_PRINCIPAL: false,
                                    USER_STATUS: 'PRINCIPAL',
                                    USER_SCHOOL_ID: teacherOptionSchoolId
                                });
                            }

                            await batch.commit().then(async () => {
                                setbtnLoading(false)
                                setrequestSentData(prev => { return { ...prev, requestSentMsg: true, goToProfileButton: true, hideDive: true } })
                            }).catch((error) => { setfirebaseErrorMessage(error.message) });
                        }).catch((error) => { setfirebaseErrorMessage(error.message) });
                }
            } else {
                setbtnLoading(false)
                seteitherOr('Yes')
            }
        } else {
            setbtnLoading(false)
            setshowEnteredTeachers(prevState => { return { ...prevState, schoolName: { error: true, helperText: 'Please enter your school name.' } } })
        }
    }

    const teacherGoToProfile = () => {
        setbtnLoading(true)
        setrequestSentData(prev => { return { ...prev, btnLoginDisabled: true } })
        signInWithEmailAndPassword(auth, userEmailId, userPwd)
            .catch((error) => {
                setfirebaseErrorMessage(error.message)
                setbtnLoading(false)
            });

        onAuthStateChanged(auth, (user) => {
            if (user) {
                history.push('/user');
            } else {
            }
        })
        // auth.onAuthStateChanged();

    }

    return (
        <div id='mainTeachers' className='mainTeachers'>
            <div style={{ display: requestSentData.hideDive ? 'none' : 'flex' }} id='createnewaccountinfo__containerTeachers' className='createnewaccountinfo__containerTeachers'>
                <h3>Teacher/Principal/Headmaster</h3>
                <div className='h5__TeacherSchool'>
                    <Autocomplete
                        value={inputTeachersSchoolName}
                        onChange={(e, newValue) => {
                            e.preventDefault();
                            setinputTeachersSchoolName(newValue)
                        }}
                        inputValue={parentsInputValue}
                        onInputChange={(event, newInputValue) => {
                            event.preventDefault();
                            setparentsInputValue(newInputValue);
                            if (returnedTeachersClassList.length > 0) {
                                returnedTeachersClassList.splice(0, returnedTeachersClassList.length)
                            }
                            setshowEnteredTeachers(prevState => { return { ...prevState, schoolName: { error: false } } })
                            setTeacherData(prevState => {
                                return {
                                    ...prevState,
                                    teacherClass1Medium: '', teacherClass1: '', teacherClass1Sec: '', teacherClass1SecSub: '',
                                    teacherClass2Medium: '', teacherClass2: '', teacherClass2Sec: '', teacherClass2SecSub: '',
                                    teacherClass3Medium: '', teacherClass3: '', teacherClass3Sec: '', teacherClass3SecSub: '',
                                    teacherClass4Medium: '', teacherClass4: '', teacherClass4Sec: '', teacherClass4SecSub: '',
                                    teacherClass5Medium: '', teacherClass5: '', teacherClass5Sec: '', teacherClass5SecSub: '',
                                    teacherClass6Medium: '', teacherClass6: '', teacherClass6Sec: '', teacherClass6SecSub: '',
                                }
                            })
                        }}

                        disablePortal
                        id="teacherSchoolName"
                        name='teacherSchoolName'
                        options={teachersOptions.map((option) => option.schoolName)}
                        sx={{ width: '85ch' }}
                        renderInput={(params) =>
                            <TextField
                                id="teacherSchoolName"
                                name='teacherSchoolName'
                                {...params}
                                margin="normal"
                                label="Search your school here by school name, village ,city or district"
                                variant="outlined"
                                error={showEnteredTeachers.schoolName.error}
                            />
                        }
                    />
                    <div className='noAvailable_teacher'>
                        <h5><b>Note : </b>If School you are looking for does not appear in the list, then your school is yet to create an account. Ask your school to create account first. Till then you can use this platform to follow other schools and make new friends.</h5>
                    </div>
                    <div className='pricipal'>
                        <FormControl component="fieldset">
                            <div className='pricipalYesNo'>
                                <FormLabel component="legend">Are you Principal/HM at your school?</FormLabel>
                                <RadioGroup aria-label="principal" name="principal1" value={principalValue} onChange={handleChangePrincipalValue}>
                                    <div className='pricipalYesNoOptions'>
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </div>
                                </RadioGroup>
                            </div>
                        </FormControl>
                    </div>
                </div>
                <div className='teachersData'>
                    <div className='teacher'>
                        <FormControl component="fieldset">
                            <div className='teacherYesNo'>
                                <FormLabel component="legend">Are you a teacher at your school?</FormLabel>
                                <RadioGroup aria-label="teacher" name="teacher1" value={teacherValue} onChange={handleChangeTeacherValue}>
                                    <div className='teacherYesNoOptions'>
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </div>
                                </RadioGroup>
                            </div>
                        </FormControl>
                    </div>
                    {
                        teacherValue === 'Yes' && (
                            <div className='teacherDataSection'>
                                <div className='teacherClassnSubjects'>
                                    <h3>Select the Classes and the Subjects you teach at your school</h3>
                                    <h5 style={{ color: 'var(--connected-orange)' }}><b>Note :</b> You can add more subjects once you sign in to your profile.</h5>
                                </div>

                                <div className='ClassnSubjectRow'>
                                    <div className='ClassnSubject'>
                                        <p>1. </p>
                                        <div className='TeacherClassNum'>
                                            <div className='h5__1TeacherClassMedium'>
                                                {/* <h5>Selection Required *</h5> */}
                                                <FormControl name='teacherClass1Medium' error={showEnteredTeachers.teacherClass1Medium.error} sx={{ m: 1, minWidth: "18ch" }}>
                                                    <InputLabel name='teacherClass1Medium' id="teacherClass1Medium">Medium</InputLabel>
                                                    <Select
                                                        labelId="teacherClass1Medium"
                                                        id="teacherClass1Medium"
                                                        name='teacherClass1Medium'
                                                        value={teacherData.teacherClass1Medium}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            setTeacherData(prevState => { return { ...prevState, teacherClass1Medium: e.target.value } })
                                                            setshowEnteredTeachers(prevState => { return { ...prevState, teacherClass1Medium: { error: false, helperText: '' } } })
                                                            setTeacherData(prevState => { return { ...prevState, teacherClass1: '', teacherClass1Sec: '', teacherClass1SecSub: '' } })
                                                        }}
                                                        label="Medium"
                                                    >
                                                        <MenuItem style={{ display: inputTeachersSchoolName === '' ? 'block' : 'none' }} key='' value=''></MenuItem>

                                                        <MenuItem style={{ display: inputTeachersSchoolName === '' ? 'none' : 'block' }} key='EnglishMedium' value='English Medium'>English Medium</MenuItem>
                                                        <MenuItem style={{ display: inputTeachersSchoolName === '' ? 'none' : 'block' }} key='MarathiMedium' value='Marathi Medium'>Marathi Medium</MenuItem>
                                                    </Select>
                                                    <FormHelperText id="teacherClass1Medium"><p>{showEnteredTeachers.teacherClass1Medium.helperText}</p></FormHelperText>
                                                </FormControl>
                                            </div>
                                            <div className='h5__1TeacherClass'>
                                                {/* <h5>Selection Required *</h5> */}
                                                <FormControl name='teacherClass1' error={showEnteredTeachers.teacherClass1.error} sx={{ m: 1, minWidth: "23ch" }}>
                                                    <InputLabel id="teacherClass1" name='teacherClass1'>Class</InputLabel>
                                                    <Select
                                                        labelId="teacherClass1"
                                                        id="teacherClass1"
                                                        name='teacherClass1'
                                                        value={teacherData.teacherClass1}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            setTeacherData(prevState => { return { ...prevState, teacherClass1: e.target.value } })
                                                            setshowEnteredTeachers(prevState => { return { ...prevState, teacherClass1: { error: false, helperText: '' } } })
                                                            setTeacherData(prevState => { return { ...prevState, teacherClass1Sec: '', teacherClass1SecSub: '' } })
                                                        }}
                                                        label="Class"
                                                    // onChange={handleChange}
                                                    >
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === '' ? 'block' : 'none' }} aria-label="None" value="" />

                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'English Medium' && returnedTeachersClassList.includes('EngPreNurserySecA') ? 'block' : 'none' }} id='Eng Pre Nursery' key='Eng Pre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'English Medium' && returnedTeachersClassList.includes('EngNurserySecA') ? 'block' : 'none' }} id='Eng Nursery' key='Eng Nursery' value='Nursery'>Nursery</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'English Medium' && returnedTeachersClassList.includes('EngKG1SecA') ? 'block' : 'none' }} id='Eng Kindergarten 1(KG-1)' key='Eng Kindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'English Medium' && returnedTeachersClassList.includes('EngKG2SecA') ? 'block' : 'none' }} id='Eng Kindergarten 2(KG-2)' key='Eng Kindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'English Medium' && returnedTeachersClassList.includes('EngClass1SecA') ? 'block' : 'none' }} id='Eng Class 1' key='Eng Class 1' value='Class 1'>Class 1</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'English Medium' && returnedTeachersClassList.includes('EngClass2SecA') ? 'block' : 'none' }} id='Eng Class 2' key='Eng Class 2' value='Class 2'>Class 2</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'English Medium' && returnedTeachersClassList.includes('EngClass3SecA') ? 'block' : 'none' }} id='Eng Class 3' key='Eng Class 3' value='Class 3'>Class 3</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'English Medium' && returnedTeachersClassList.includes('EngClass4SecA') ? 'block' : 'none' }} id='Eng Class 4' key='Eng Class 4' value='Class 4'>Class 4</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'English Medium' && returnedTeachersClassList.includes('EngClass5SecA') ? 'block' : 'none' }} id='Eng Class 5' key='Eng Class 5' value='Class 5'>Class 5</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'English Medium' && returnedTeachersClassList.includes('EngClass6SecA') ? 'block' : 'none' }} id='Eng Class 6' key='Eng Class 6' value='Class 6'>Class 6</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'English Medium' && returnedTeachersClassList.includes('EngClass7SecA') ? 'block' : 'none' }} id='Eng Class 7' key='Eng Class 7' value='Class 7'>Class 7</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'English Medium' && returnedTeachersClassList.includes('EngClass8SecA') ? 'block' : 'none' }} id='Eng Class 8' key='Eng Class 8' value='Class 8'>Class 8</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'English Medium' && returnedTeachersClassList.includes('EngClass9SecA') ? 'block' : 'none' }} id='Eng Class 9' key='Eng Class 9' value='Class 9'>Class 9</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'English Medium' && returnedTeachersClassList.includes('EngClass10SecA') ? 'block' : 'none' }} id='Eng Class 10' key='Eng Class 10' value='Class 10'>Class 10</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'English Medium' && returnedTeachersClassList.includes('EngClass11ScienceSecA') ? 'block' : 'none' }} id='Eng Class 11(Science)' key='Eng Class 11(Science)' value='Class 11(Science)'>Class 11(Science)</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'English Medium' && returnedTeachersClassList.includes('EngClass11CommerceSecA') ? 'block' : 'none' }} id='Eng Class 11(Commerce)' key='Eng Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'English Medium' && returnedTeachersClassList.includes('EngClass12ScienceSecA') ? 'block' : 'none' }} id='Eng Class 12(Science)' key='Eng Class 12(Science)' value='Class 12(Science)'>Class 12(Science)</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'English Medium' && returnedTeachersClassList.includes('EngClass12CommerceSecA') ? 'block' : 'none' }} id='Eng Class 12(Commerce)' key='Eng Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>

                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'Marathi Medium' && returnedTeachersClassList.includes('MarPreNurserySecA') ? 'block' : 'none' }} id='Mar Pre Nursery' key='Mar Pre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'Marathi Medium' && returnedTeachersClassList.includes('MarNurserySecA') ? 'block' : 'none' }} id='Mar Nursery' key='Mar Nursery' value='Nursery'>Nursery</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'Marathi Medium' && returnedTeachersClassList.includes('MarKG1SecA') ? 'block' : 'none' }} id='Mar Kindergarten 1(KG-1)' key='Mar Kindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'Marathi Medium' && returnedTeachersClassList.includes('MarKG2SecA') ? 'block' : 'none' }} id='Mar Kindergarten 2(KG-2)' key='Mar Kindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>

                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass1SecA') ? 'block' : 'none' }} id='Mar Class 1' key='Mar Class 1' value='Class 1'>Class 1</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass2SecA') ? 'block' : 'none' }} id='Mar Class 2' key='Mar Class 2' value='Class 2'>Class 2</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass3SecA') ? 'block' : 'none' }} id='Mar Class 3' key='Mar Class 3' value='Class 3'>Class 3</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass4SecA') ? 'block' : 'none' }} id='Mar Class 4' key='Mar Class 4' value='Class 4'>Class 4</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass5SecA') ? 'block' : 'none' }} id='Mar Class 5' key='Mar Class 5' value='Class 5'>Class 5</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass6SecA') ? 'block' : 'none' }} id='Mar Class 6' key='Mar Class 6' value='Class 6'>Class 6</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass7SecA') ? 'block' : 'none' }} id='Mar Class 7' key='Mar Class 7' value='Class 7'>Class 7</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass8SecA') ? 'block' : 'none' }} id='Mar Class 8' key='Mar Class 8' value='Class 8'>Class 8</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass9SecA') ? 'block' : 'none' }} id='Mar Class 9' key='Mar Class 9' value='Class 9'>Class 9</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass10SecA') ? 'block' : 'none' }} id='Mar Class 10' key='Mar Class 10' value='Class 10'>Class 10</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass11ArtsSecA') ? 'block' : 'none' }} id='Mar Class 11(Arts)' key='Mar Class 11(Arts)' value='Class 11(Arts)'>Class 11(Arts)</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass11CommerceSecA') ? 'block' : 'none' }} id='Mar Class 11(Commerce)' key='Mar Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass12ArtsSecA') ? 'block' : 'none' }} id='Mar Class 12(Arts)' key='Mar Class 12(Arts)' value='Class 12(Arts)'>Class 12(Arts)</MenuItem>
                                                        <MenuItem style={{ display: teacherData.teacherClass1Medium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass12CommerceSecA') ? 'block' : 'none' }} id='Mar Class 12(Commerce)' key='Mar Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>
                                                    </Select>
                                                    <FormHelperText id="teacherClass1"><p>{showEnteredTeachers.teacherClass1.helperText}</p></FormHelperText>
                                                </FormControl>

                                            </div>
                                        </div>
                                        <div className='TeacherClassSubject'>
                                            <div className='h5__1TeacherSection'>
                                                {/* <h5>Selection Required *</h5> */}
                                                <FormControl name='teacherClass1Sec' error={showEnteredTeachers.teacherClass1Sec.error} sx={{ m: 1, minWidth: "20ch" }}>
                                                    <InputLabel name='teacherClass1Sec' id="teacherClass1Sec">Section</InputLabel>
                                                    <Select
                                                        labelId="teacherClass1Sec"
                                                        id="teacherClass1Sec"
                                                        name='teacherClass1Sec'
                                                        value={teacherData.teacherClass1Sec}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            setTeacherData(prevState => { return { ...prevState, teacherClass1Sec: e.target.value } })
                                                            setshowEnteredTeachers(prevState => { return { ...prevState, teacherClass1Sec: { error: false, helperText: '' } } })
                                                            setTeacherData(prevState => { return { ...prevState, teacherClass1SecSub: '' } })
                                                        }}
                                                        label="Section"
                                                    >
                                                        <MenuItem style={{ display: teacherData.teacherClass1 === '' ? 'block' : 'none' }} aria-label="None" value="" />

                                                        <MenuItem style={{ display: returnedTeachersSectionList?.class1Section1.SectionA === true ? 'block' : 'none' }} key='Section A' value='Section A'>Section A</MenuItem>
                                                        <MenuItem style={{ display: returnedTeachersSectionList?.class1Section1.SectionB === true ? 'block' : 'none' }} key='Section B' value='Section B'>Section B</MenuItem>
                                                        <MenuItem style={{ display: returnedTeachersSectionList?.class1Section1.SectionC === true ? 'block' : 'none' }} key='Section C' value='Section C'>Section C</MenuItem>
                                                        <MenuItem style={{ display: returnedTeachersSectionList?.class1Section1.SectionD === true ? 'block' : 'none' }} key='Section D' value='Section D'>Section D</MenuItem>
                                                        <MenuItem style={{ display: returnedTeachersSectionList?.class1Section1.SectionE === true ? 'block' : 'none' }} key='Section E' value='Section E'>Section E</MenuItem>
                                                    </Select>
                                                    <FormHelperText id="teacherClass1Sec"><p>{showEnteredTeachers.teacherClass1Sec.helperText}</p></FormHelperText>
                                                </FormControl>
                                            </div>
                                            <div className='h5__1TeacherSubject'>
                                                <FormControl name='teacherClass1SecSub' error={showEnteredTeachers.teacherClass1SecSub.error} sx={{ m: 1, minWidth: "16ch" }}>
                                                    <InputLabel name='teacherClass1SecSub' id="teacherClass1SecSub">Subject</InputLabel>
                                                    <Select
                                                        labelId="teacherClass1SecSub"
                                                        id="teacherClass1SecSub"
                                                        name='teacherClass1SecSub'
                                                        value={teacherData.teacherClass1SecSub}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            setTeacherData(prevState => { return { ...prevState, teacherClass1SecSub: e.target.value } })
                                                            setshowEnteredTeachers(prevState => { return { ...prevState, teacherClass1SecSub: { error: false, helperText: '' } } })
                                                        }}
                                                        label="Subject"
                                                    >
                                                        <MenuItem style={{ display: returnedSubjectsList.length > 0 ? 'none' : 'block' }} aria-label="None" value="" />
                                                        {
                                                            returnedSubjectsList.map((sub) => {
                                                                return (
                                                                    <MenuItem key={sub} value={sub}>{sub}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                    <FormHelperText id="teacherClass1SecSub"><p>{showEnteredTeachers.teacherClass1SecSub.helperText}</p></FormHelperText>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    }

                </div>
                {
                    eitherOr === 'Yes' && (
                        <div className='eitherOr'>
                            <h3>You should either be Principal/HM or a Teacher or Both</h3>
                        </div>
                    )
                }

                <div className='eitherOr'>
                    <h3>{firebaseErrorMessage}</h3>
                </div>
                <LoadingButton
                    onClick={handleSendVerificationRequest}
                    loading={btnLoading}
                    variant="outlined"
                >
                    Send Verification Request to School
                </LoadingButton>
            </div>
            {
                requestSentData.requestSentMsg && (
                    <h5>Request Sent to School..</h5>
                )
            }
            {
                requestSentData.goToProfileButton && (
                    <LoadingButton
                        onClick={teacherGoToProfile}
                        loading={btnLoading}
                        variant="outlined"
                    >
                        Go to profile
                    </LoadingButton>
                )
            }

        </div>
    )
}

export default CreateNewAccountInfoTeachers
