import React, { useEffect, useState } from 'react'
import '../Pages/UserNoticeBoard.css'
import '../Components/NoticeBoards/SidebarNoticeBoardAccordian.css'

import dB, { auth } from '../firebase';
import { collection, doc, onSnapshot, orderBy, query } from 'firebase/firestore'
import { onAuthStateChanged } from 'firebase/auth'
import Login from './Login';
import Header from '../Components/Header/Header';
import SidebarNoticeBoardAccordian from '../Components/NoticeBoards/SidebarNoticeBoardAccordian';

function UserNoticeBoard() {

    const [noticesData, setnoticesData] = useState([])
    const [userData, setuserData] = useState({
        userId: '', schoolId: '', schoolFullName: '', userCurrentStatus: '',
        userPathCollection: '', isUserVerified: false, userSchoolPath: '',
        parentData: {
            currentChildId: '', childFullName: '', isChildVerified: false, childSchoolId: '', childSchoolPathName: '',
            childSchoolFullName: '', childMedium: '', childClass: '', childSection: ''
        }
    })
    useEffect(() => {
        const unSubscribe = onAuthStateChanged(auth, async (userCredentials) => {
            if (userCredentials) {
                onSnapshot(doc(dB, "USERS", userCredentials.uid), (userDoc) => {
                    if (userDoc.exists()) {
                        setuserData(prevState => {
                            return {
                                ...prevState,
                                userId: userCredentials.uid,
                                userCurrentStatus: userDoc.data()?.USER_CURRENT_STATUS,
                                userPathCollection: userDoc.data()?.USER_NAME,
                            }
                        })
                    }
                })
            }
        });
        return () => { unSubscribe(); }
    }, [userData.schoolId, userData.userCurrentStatus, userData.userId, userData.userPathCollection])

    // done teacher
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userCurrentStatus === 'TEACHER') {
                onSnapshot(doc(dB, "USERS", userData.userId, userData.userPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "VERIFICATION_DETAILS"), (snap) => {
                    if (snap.exists()) {
                        setuserData(prevState => {
                            return {
                                ...prevState,
                                schoolId: snap.data()?.USER_SCHOOL_ID,
                                isUserVerified: snap.data()?.VERIFIED_TEACHER
                            }
                        })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userData.userCurrentStatus, userData.userId, userData.userPathCollection])
    // done principal
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userCurrentStatus === 'PRINCIPAL') {
                onSnapshot(doc(dB, "USERS", userData.userId, userData.userPathCollection, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), (snap) => {
                    if (snap.exists()) {
                        setuserData(prevState => {
                            return {
                                ...prevState,
                                schoolId: snap.data()?.USER_SCHOOL_ID,
                                isUserVerified: snap.data()?.VERIFIED_PRINCIPAL
                            }
                        })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userData.userCurrentStatus, userData.userId, userData.userPathCollection])
    // done student
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userCurrentStatus === 'STUDENT') {
                onSnapshot(doc(dB, "USERS", userData.userId, userData.userPathCollection, "E-SCHOOLING_INFO", "IS_STUDENT", "VERIFICATION_DETAILS"), (snap) => {
                    if (snap.exists()) {
                        setuserData(prevState => {
                            return {
                                ...prevState,
                                schoolId: snap.data()?.USER_SCHOOL_ID,
                                isUserVerified: snap.data()?.VERIFIED_STUDENT
                            }
                        })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userData.userCurrentStatus, userData.userId, userData.userPathCollection])


    // done parent
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userCurrentStatus === 'PARENT') {
                onSnapshot(doc(dB, "USERS", userData.userId, userData.userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA"), (snap) => {
                    if (snap.exists()) {
                        setuserData(prev => {
                            return {
                                ...prev,
                                parentData: { ...prev.parentData, currentChildId: snap.data()?.CURRENT_SELECTED_CHILD }
                            }
                        })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userData.userCurrentStatus, userData.userId, userData.userPathCollection])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.parentData.currentChildId !== '') {
                onSnapshot(doc(dB, "USERS", userData.userId, userData.userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", userData.parentData.currentChildId), (snap) => {
                    if (snap.exists()) {
                        setuserData(prev => {
                            return {
                                ...prev,
                                parentData: {
                                    ...prev.parentData,
                                    isChildVerified: snap.data()?.VERIFIED_CHILD,
                                    childSchoolId: snap.data()?.CHILDS_SCHOOL_ID,
                                    childFullName: snap.data()?.CHILDS_FULL_NAME,
                                    childMedium: snap.data()?.CHILDS_MEDIUM,
                                    childClass: snap.data()?.CHILDS_CLASS,
                                    childSection: snap.data()?.CHILDS_SECTION
                                }
                            }
                        })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userData.parentData.currentChildId, userData.userId, userData.userPathCollection])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.parentData.childSchoolId !== '') {
                onSnapshot(doc(dB, "SCHOOLS", userData.parentData.childSchoolId), (snap) => {
                    if (snap.exists()) {
                        setuserData(prev => {
                            return {
                                ...prev,
                                parentData: {
                                    ...prev.parentData,
                                    childSchoolPathName: snap.data()?.SCHOOL_NAME,
                                    childSchoolFullName: snap.data()?.SCHOOL_FULL_NAME
                                }
                            }
                        })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userData.parentData.childSchoolId])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.parentData.childSchoolId && userData.parentData.childSchoolPathName) {
                onSnapshot(query(collection(dB, "SCHOOLS", userData.parentData.childSchoolId, userData.parentData.childSchoolPathName, "NOTICE_BOARD", "NOTICES_SENT"), orderBy('NOTICE_TIMESTAMP', 'desc')), (snap) => {
                    setnoticesData(snap.docs.map((notDoc) => ({
                        noticeId: notDoc.id,
                        noticeData: notDoc.data(),
                    })))
                })
            }
        }
        return () => { aaa = false }
    }, [userData.parentData.childSchoolId, userData.parentData.childSchoolPathName])



    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.schoolId) {
                onSnapshot(doc(dB, "SCHOOLS", userData.schoolId), (snap) => {
                    if (snap.exists()) {
                        setuserData(prevState => {
                            return {
                                ...prevState,
                                schoolFullName: snap.data()?.SCHOOL_FULL_NAME,
                                userSchoolPath: snap.data()?.SCHOOL_NAME
                            }
                        })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userData.schoolId])



    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userCurrentStatus === 'TEACHER' && userData.isUserVerified && userData.schoolId) {
                onSnapshot(doc(dB, "SCHOOLS", userData.schoolId), (snap) => {
                    if (snap.exists()) {
                        setuserData(prevState => {
                            return {
                                ...prevState,
                                userSchoolPath: snap.data()?.SCHOOL_NAME
                            }
                        })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userData.isUserVerified, userData.schoolId, userData.userCurrentStatus])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userCurrentStatus === 'TEACHER' && userData.isUserVerified && userData.schoolId && userData.userSchoolPath) {
                onSnapshot(query(collection(dB, "SCHOOLS", userData.schoolId, userData.userSchoolPath, "NOTICE_BOARD", "NOTICES_SENT"), orderBy('NOTICE_TIMESTAMP', 'desc')), (snap) => {
                    setnoticesData(snap.docs.map((notDOc) => ({
                        noticeId: notDOc.id,
                        noticeData: notDOc.data(),
                    })))
                })
            }
        }
        return () => { aaa = false }
    }, [userData.isUserVerified, userData.schoolId, userData.userCurrentStatus, userData.userSchoolPath])

    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userCurrentStatus === 'PRINCIPAL' && userData.isUserVerified && userData.schoolId) {
                onSnapshot(doc(dB, "SCHOOLS", userData.schoolId), (snap) => {
                    if (snap.exists()) {
                        setuserData(prevState => {
                            return {
                                ...prevState,
                                userSchoolPath: snap.data()?.SCHOOL_NAME
                            }
                        })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userData.isUserVerified, userData.schoolId, userData.userCurrentStatus])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userCurrentStatus === 'PRINCIPAL' && userData.isUserVerified && userData.schoolId && userData.userSchoolPath) {
                onSnapshot(query(collection(dB, "SCHOOLS", userData.schoolId, userData.userSchoolPath, "NOTICE_BOARD", "NOTICES_SENT"), orderBy('NOTICE_TIMESTAMP', 'desc')), (snap) => {
                    setnoticesData(snap.docs.map((notDOc) => ({
                        noticeId: notDOc.id,
                        noticeData: notDOc.data(),
                    })))
                })
            }
        }
        return () => { aaa = false }
    }, [userData.isUserVerified, userData.schoolId, userData.userCurrentStatus, userData.userSchoolPath])

    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userCurrentStatus === 'STUDENT' && userData.isUserVerified && userData.schoolId) {
                onSnapshot(doc(dB, "SCHOOLS", userData.schoolId), (snap) => {
                    if (snap.exists()) {
                        setuserData(prevState => {
                            return {
                                ...prevState,
                                userSchoolPath: snap.data()?.SCHOOL_NAME
                            }
                        })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userData.isUserVerified, userData.schoolId, userData.userCurrentStatus])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userCurrentStatus === 'STUDENT' && userData.isUserVerified && userData.schoolId && userData.userSchoolPath) {
                onSnapshot(query(collection(dB, "SCHOOLS", userData.schoolId, userData.userSchoolPath, "NOTICE_BOARD", "NOTICES_SENT"), orderBy('NOTICE_TIMESTAMP', 'desc')), (snap) => {
                    setnoticesData(snap.docs.map((notDoc) => ({
                        noticeId: notDoc.id,
                        noticeData: notDoc.data(),
                    })))
                })
            }
        }
        return () => { aaa = false }
    }, [userData.isUserVerified, userData.schoolId, userData.userCurrentStatus, userData.userSchoolPath])


    return (
        <>
            {
                userData.userId === '' ? (
                    <Login />
                ) : (
                    <div className='UserNoticeBoard'>
                        <Header />
                        <div className='UserNoticeBoard__Container'>
                            <h3>{userData.userCurrentStatus === 'PARENT' ? userData.parentData.childSchoolFullName : userData.schoolFullName}</h3>
                            <h4>Notice Board</h4>
                            <hr />
                            {
                                userData.userCurrentStatus === 'PARENT' && (
                                    <div className='childName'>
                                        <h3>Students Name</h3>
                                        <h4>:</h4>
                                        <div className='childNameName'>
                                            <h5>{userData.parentData.childFullName}</h5>
                                            <p>({userData.parentData.childMedium} · {userData.parentData.childClass} · {userData.parentData.childSection})</p>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                userData.userCurrentStatus === 'PARENT' ? (
                                    <>
                                        {
                                            userData.parentData.isChildVerified ? (
                                                <>
                                                    {
                                                        noticesData.length > 0 ? (
                                                            <>
                                                                {
                                                                    noticesData.map((data, i) => {
                                                                        return (
                                                                            <SidebarNoticeBoardAccordian
                                                                                key={i}
                                                                                userCurrentStatus={userData.userCurrentStatus}
                                                                                userId={userData.userId}
                                                                                schoolId={userData.parentData.childSchoolId}
                                                                                schoolFullName={userData.parentData.childSchoolFullName}
                                                                                userPathCollection={userData.userPathCollection}
                                                                                userSchoolPath={userData.parentData.childSchoolPathName}
                                                                                noticeSubject={data?.noticeData?.NOTICE_SUBJECT}
                                                                                noticeBody={data?.noticeData?.NOTICE_BODY}
                                                                                noticefrom={data?.noticeData?.NOTICE_FROM}
                                                                                noticeDate={data?.noticeData?.NOTICE_TIMESTAMP}
                                                                                noticeTimeStamp={data?.noticeData?.NOTICE_TIMESTAMP}
                                                                                noticeViewedBy={data?.noticeData?.VIEWED_BY}
                                                                                noticeId={data?.noticeId}
                                                                            />
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        ) : (
                                                            <div className='noNoticesYet'>
                                                                <h3>No notices from your child's school yet.</h3>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            ) : (
                                                <div className='notVerifiedYet'>
                                                    <h3>Your child is not a verified by school yet. First get your child verified from your school</h3>
                                                </div>
                                            )
                                        }
                                    </>
                                ) : (
                                    <>
                                        {
                                            userData.isUserVerified ? (
                                                <>
                                                    {
                                                        noticesData.length > 0 ? (
                                                            <>
                                                                {
                                                                    noticesData.map((data, i) => {
                                                                        return (
                                                                            <SidebarNoticeBoardAccordian
                                                                                key={i}
                                                                                userCurrentStatus={userData.userCurrentStatus}
                                                                                userId={userData.userId}
                                                                                schoolId={userData.schoolId}
                                                                                schoolFullName={userData.schoolFullName}
                                                                                userPathCollection={userData.userPathCollection}
                                                                                userSchoolPath={userData.userSchoolPath}
                                                                                noticeSubject={data?.noticeData?.NOTICE_SUBJECT}
                                                                                noticeBody={data?.noticeData?.NOTICE_BODY}
                                                                                noticefrom={data?.noticeData?.NOTICE_FROM}
                                                                                noticeDate={data?.noticeData?.NOTICE_TIMESTAMP}
                                                                                noticeTimeStamp={data?.noticeData?.NOTICE_TIMESTAMP}
                                                                                noticeViewedBy={data?.noticeData?.VIEWED_BY}
                                                                                noticeId={data?.noticeId}
                                                                            />
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        ) : (
                                                            <div className='noNoticesYet'>
                                                                <h3>No notices from your school yet.</h3>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            ) : (
                                                <div className='notVerifiedYet'>
                                                    <h3>You are not a verified user. First get yourself verified from your school</h3>
                                                </div>
                                            )
                                        }
                                    </>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default UserNoticeBoard