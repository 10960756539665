import React, { useState, useEffect } from 'react'
import '../Pages/ClassTeacherAllocate.css'
import dB, { auth } from '../firebase'
import { onAuthStateChanged } from 'firebase/auth'
import { collection, doc, onSnapshot } from 'firebase/firestore'
import Login from './Login'
import Header from '../Components/Header/Header'
import AllocateOneTeacher from '../Components/AllocateTeacher/AllocateOneTeacher'
import { functionClsStr } from '../Constants/FunctionClsStr'


function ClassTeacherAllocate() {

    const [schoolData, setschoolData] = useState({ schoolId: '', schoolShortName: '', schoolFullName: '', schoolAffiliation: '', })
    useEffect(() => {
        const unSubscribe = onAuthStateChanged(auth, (userCredentials) => {
            if (userCredentials) {
                onSnapshot(doc(dB, "SCHOOLS", userCredentials.uid), (snap) => {
                    if (snap.exists()) { setschoolData(prevState => { return { ...prevState, schoolId: userCredentials.uid, schoolShortName: snap.data()?.SCHOOL_NAME, schoolFullName: snap.data()?.SCHOOL_FULL_NAME } }) }
                })
            }
        });
        return () => { unSubscribe(); }
    }, [])

    const [classList, setclassList] = useState([])
    useEffect(() => {
        setclassList([])
        var aaa = true
        if (aaa === true) {
            if (schoolData.schoolId && schoolData.schoolShortName) {
                onSnapshot(doc(dB, "SCHOOLS", schoolData.schoolId, schoolData.schoolShortName, "CLASSES_DETAILS"), (classes) => {
                    if (classes.exists()) {
                        setclassList(classes.data()?.CLASS_LIST)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [schoolData])

    const [allClassMedSec, setallClassMedSec] = useState([])
    useEffect(() => {
        setallClassMedSec([])
        var aaa = true
        if (aaa === true) {
            if (classList.length > 0) {
                classList.forEach(clsStr => {
                    var xyz = functionClsStr(clsStr)
                    setallClassMedSec(prev => { return [...prev, xyz] })
                })
            }
        }
        return () => { aaa = false }
    }, [classList])



    return (
        <>
            {
                schoolData.schoolId === '' ? (
                    <Login />
                ) : (
                    <div className='classTeacherAllocate'>
                        <Header />
                        <div className='classTeacherAllocateBody'>

                            <div className='SidebarNoticeBoard__ContainerTop'>
                                <h3>{schoolData.schoolFullName}</h3>
                                <h2>Class Teachers</h2>
                                <hr />
                            </div>

                            <div className='allTeachersSelections'>
                                <h3>Allocate Class Teacher to each class individually.</h3>

                                <div className='classTeacherAllocationOptions'>
                                    {
                                        allClassMedSec.map((medClsSec, i) => (
                                            <AllocateOneTeacher
                                                key={i}
                                                medium={medClsSec.medium}
                                                classNum={medClsSec.classNum}
                                                section={medClsSec.section}
                                                classPath={medClsSec.classPath}
                                                schoolId={schoolData.schoolId}
                                                schoolPathCollection={schoolData.schoolShortName}
                                                schoolFullName={schoolData.schoolFullName}
                                            />
                                        ))
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default ClassTeacherAllocate