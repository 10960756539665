import React, { useEffect, useState } from 'react'
import '../Following/SchoolAccountFollowing.css';

import '../Social/UserFriends.css';
import '../FIndToFollow/FindToFollowTeachersNFriends.css'
import FindToFollowTeachersNFriendsCard from '../FIndToFollow/FindToFollowTeachersNFriendsCard';
import FindToFollowSchoolsCard from '../FIndToFollow/FindToFollowSchoolsCard';
import { collection, doc, getDocs, limit, onSnapshot, query, startAfter } from 'firebase/firestore';

import PropTypes from 'prop-types';
import { Box, Button, Dialog, Tab, Tabs, Typography } from '@mui/material';
import dB from '../../firebase';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function SchoolAccountFollowing({ schoolId, schoolPathCollection }) {

    const [openFollowers, setOpenFollowers] = useState(false);
    const handleClickOpenFollowers = (e) => { e.preventDefault(); setOpenFollowers(true); };
    const handleCloseFollowers = (e) => { e.preventDefault(); setOpenFollowers(false); };

    const [openFollowing, setOpenFollowing] = useState(false);
    const handleClickOpenFollowing = (e) => { e.preventDefault(); setOpenFollowing(true); };
    const handleCloseFollowing = (e) => { e.preventDefault(); setOpenFollowing(false); };

    const [valueFollowers, setValueFollowers] = React.useState(0);
    const handleChangeFollowers = (event, newValueFollowers) => { event.preventDefault(); setValueFollowers(newValueFollowers); };

    const [totalFollowingSchlsIds, settotalFollowingSchlsIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolPathCollection) {
                onSnapshot(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWING", "FOLLOWING_SCHOOLS"), (snap) => {
                    settotalFollowingSchlsIds(snap.docs.map((follDOc) => follDOc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [schoolId, schoolPathCollection])


    const [followingSchlsIds, setfollowingSchlsIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolPathCollection) {
                onSnapshot(query(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWING", "FOLLOWING_SCHOOLS"), limit(8)), (snap) => {
                    fetchFwngSchlIds(snap)
                })
            }
        }
        return () => { aaa = false }
    }, [schoolId, schoolPathCollection])

    const fetchFwngSchlIds = (snap) => {
        let fwngSchlId = snap.docs.map((idsDoc) => idsDoc.id)
        setfollowingSchlsIds(prev => { return [...prev, ...fwngSchlId] })

        var lastVisibleFwngSchlIdId = snap.docs[snap.docs.length - 1];
        setlastVisibleFwngSchlId(lastVisibleFwngSchlIdId)
    }
    const [lastVisibleFwngSchlId, setlastVisibleFwngSchlId] = useState(null)
    const [totalFwngSchlIds, settotalFwngSchlIds] = useState(null)
    const loadMoreFwnSchlIds = async (followingSchlsIdsLength) => {
        settotalFwngSchlIds(followingSchlsIdsLength)
        if (schoolId && schoolPathCollection) {
            const q = query(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWING", "FOLLOWING_SCHOOLS"), startAfter(lastVisibleFwngSchlId), limit(8))
            const snap = await getDocs(q).catch(err => alert(err.message))
            fetchFwngSchlIds(snap)
        }
    }
    const [followingSchlsData, setfollowingSchlsData] = useState([]);
    useEffect(() => {
        if (followingSchlsData?.length > 0) {
            followingSchlsData?.splice(0, followingSchlsData?.length)
        }
        var aaa = true
        if (aaa === true) {
            followingSchlsIds.forEach(id => {
                onSnapshot(doc(dB, "SCHOOLS", id), (snap) => {
                    if (snap.exists()) {
                        setfollowingSchlsData(prev => {
                            return [...prev, {
                                schoolName: snap.data()?.SCHOOL_FULL_NAME,
                                schoolImage: snap.data()?.USER_DISPLAY_PIC,
                                schoolId: id
                            }]
                        })
                    }
                })
            })
        }
        return () => { aaa = false }
    }, [followingSchlsIds])




    const [totalFollowingUsersIds, settotalFollowingUsersIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolPathCollection) {
                onSnapshot(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWING", "FOLLOWING_USERS"), (snap) => {
                    settotalFollowingUsersIds(snap.docs.map((userDOc) => userDOc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [schoolId, schoolPathCollection])

    const [followingUsersIds, setfollowingUsersIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolPathCollection) {
                onSnapshot(query(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWING", "FOLLOWING_USERS"), limit(9)), (snap) => {
                    fetchFwngUserIds(snap)
                })
            }
        }
        return () => { aaa = false }
    }, [schoolId, schoolPathCollection])


    const fetchFwngUserIds = (snap) => {
        let fwngUserId = snap.docs.map((userIdDoc) => userIdDoc.id)
        setfollowingUsersIds(prev => { return [...prev, ...fwngUserId] })

        var lastVisible = snap.docs[snap.docs.length - 1];
        setlastVisibleFwngUserId(lastVisible)
    }
    const [lastVisibleFwngUserId, setlastVisibleFwngUserId] = useState(null)
    const [totalFwngUserIds, settotalFwngUserIds] = useState(null)
    const loadMoreFwnUserIds = async (followingUsersIdsLength) => {
        settotalFwngUserIds(followingUsersIdsLength)
        if (schoolId && schoolPathCollection) {
            const q = query(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWING", "FOLLOWING_USERS"), startAfter(lastVisibleFwngUserId), limit(9))
            const snap = await getDocs(q).catch(err => alert(err.message))
            fetchFwngUserIds(snap)
        }
    }
    const [followingUsersData, setfollowingUsersData] = useState([]);
    useEffect(() => {
        if (followingUsersData?.length > 0) {
            followingUsersData?.splice(0, followingUsersData?.length)
        }
        var aaa = true
        if (aaa === true) {
            followingUsersIds.forEach(id => {
                onSnapshot(doc(dB, "USERS", id), (snap) => {
                    if (snap.exists()) {
                        setfollowingUsersData(prev => {
                            return [...prev, {
                                friendUserName: snap.data()?.USER_NAME,
                                friendUserImage: snap.data()?.USER_DISPLAY_PIC,
                                friendUserId: id,
                                friendUserStatus: snap.data()?.USER_CURRENT_STATUS
                            }]
                        })
                    }
                })
            })
        }
        return () => { aaa = false }
    }, [followingUsersIds])

    const [totalFollowerSchlsIds, settotalFollowerSchlsIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolPathCollection) {
                onSnapshot(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWERS", "FOLLOWER_SCHOOLS"), (snap) => {
                    settotalFollowerSchlsIds(snap.docs.map((sclDOc) => sclDOc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [schoolId, schoolPathCollection])
    const [followerSchlsIds, setfollowerSchlsIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolPathCollection) {
                onSnapshot(query(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWERS", "FOLLOWER_SCHOOLS"), limit(9)), (snap) => {
                    fetchFlwrSchlIds(snap)
                })
            }
        }
        return () => { aaa = false }
    }, [schoolId, schoolPathCollection])
    const fetchFlwrSchlIds = (snap) => {
        let fwngUserId = snap.docs.map((schlIdDOc) => schlIdDOc.id)
        setfollowerSchlsIds(prev => { return [...prev, ...fwngUserId] })

        var lastVisibleFlwrSchlIdId = snap.docs[snap.docs.length - 1];
        setlastVisibleFlwrSchlId(lastVisibleFlwrSchlIdId)
    }
    const [lastVisibleFlwrSchlId, setlastVisibleFlwrSchlId] = useState(null)
    const [totalFlwrSchlIds, settotalFlwrSchlIds] = useState(null)
    const loadMoreFlwrSchlIds = async (followerSchlsIdsLength) => {
        settotalFlwrSchlIds(followerSchlsIdsLength)
        if (schoolId && schoolPathCollection) {
            const q = query(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWERS", "FOLLOWER_SCHOOLS"), startAfter(lastVisibleFlwrSchlId), limit(9))
            const snap = await getDocs(q).catch(err => alert(err.message))
            fetchFlwrSchlIds(snap)
        }
    }
    const [followerSchlsData, setfollowerSchlsData] = useState([]);
    useEffect(() => {
        if (followerSchlsData?.length > 0) {
            followerSchlsData?.splice(0, followerSchlsData?.length)
        }
        var aaa = true
        if (aaa === true) {
            followerSchlsIds.forEach(id => {
                onSnapshot(doc(dB, "SCHOOLS", id), (snap) => {
                    if (snap.exists()) {
                        setfollowerSchlsData(prev => {
                            return [...prev, {
                                schoolName: snap.data()?.SCHOOL_FULL_NAME,
                                schoolImage: snap.data()?.USER_DISPLAY_PIC,
                                schoolId: id
                            }]
                        })
                    }
                })
            })
        }
        return () => { aaa = false }
    }, [followerSchlsIds])



    const [totalFollowerUsersIds, settotalFollowerUsersIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolPathCollection) {
                onSnapshot(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWERS", "FOLLOWER_USERS"), (snap) => {
                    settotalFollowerUsersIds(snap.docs.map((idDoc) => idDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [schoolId, schoolPathCollection])
    const [followerUsersIds, setfollowerUsersIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolPathCollection) {
                onSnapshot(query(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWERS", "FOLLOWER_USERS"), limit(9)), (snap) => {
                    fetchFlwrUserIds(snap)
                })
            }
        }
        return () => { aaa = false }
    }, [schoolId, schoolPathCollection])
    const fetchFlwrUserIds = (snap) => {
        let flwrUserId = snap.docs.map((idsDOc) => idsDOc.id)
        setfollowerUsersIds(prev => { return [...prev, ...flwrUserId] })

        var lastVisibleFlwrUserIdId = snap.docs[snap.docs.length - 1];
        setlastVisibleFlwrUserId(lastVisibleFlwrUserIdId)
    }
    const [lastVisibleFlwrUserId, setlastVisibleFlwrUserId] = useState(null)
    const [totalFlwrUserIds, settotalFlwrUserIds] = useState(null)
    const loadMoreFlwrUserIds = async (followerUsersIdsLength) => {
        settotalFlwrUserIds(followerUsersIdsLength)
        if (schoolId && schoolPathCollection) {
            const q = query(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWERS", "FOLLOWER_USERS"), startAfter(lastVisibleFlwrUserId), limit(9))
            const snap = await getDocs(q).catch(err => alert(err.message))
            fetchFlwrUserIds(snap)
        }
    }
    const [followerUsersData, setfollowerUsersData] = useState([]);
    useEffect(() => {
        if (followerUsersData?.length > 0) {
            followerUsersData?.splice(0, followerUsersData?.length)
        }
        var aaa = true
        if (aaa === true) {
            followerUsersIds.forEach(id => {
                onSnapshot(doc(dB, "USERS", id), (snap) => {
                    if (snap.exists()) {
                        setfollowerUsersData(prev => {
                            return [...prev, {
                                friendUserName: snap.data()?.USER_NAME,
                                friendUserImage: snap.data()?.USER_DISPLAY_PIC,
                                friendUserId: id,
                                friendUserStatus: snap.data()?.USER_CURRENT_STATUS
                            }]
                        })
                    }
                })
            })
        }
        return () => { aaa = false }
    }, [followerUsersIds])



    const [totalFollower, settotalFollower] = useState(0);
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (totalFollowerUsersIds?.length > 0 || totalFollowerSchlsIds?.length > 0) {
                settotalFollower(totalFollowerUsersIds?.length + totalFollowerSchlsIds?.length)
            }
        }
        return () => { aaa = false }
    }, [totalFollowerSchlsIds?.length, totalFollowerUsersIds?.length])


    return (
        <div className='userfriends'>
            <div className='fridnsHeadr'>
                <div className='userfriends__top'>
                    <h2>Following Schools ({totalFollowingSchlsIds?.length})</h2>
                </div>
                <div className='followErsIng'>

                    <h3 onClick={handleClickOpenFollowing}>Following Users ({totalFollowingUsersIds?.length})</h3>
                    <h3 onClick={handleClickOpenFollowers}>Followers ({totalFollower})</h3>

                    <Dialog open={openFollowing} onClose={handleCloseFollowing} aria-labelledby="form-dialog-title">
                        <div className='dialogFollowErsIng'>
                            <div className='userfriends__topNew'>
                                <h2>Following Users ({totalFollowingUsersIds?.length})</h2>
                                <hr style={{ marginTop: 10, marginBottom: 10 }} />
                            </div>
                            <div>
                                {
                                    followingUsersData.length > 0 ? (
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <div className='followingFriends'>
                                                {
                                                    followingUsersData.map((data, i) => (
                                                        <FindToFollowTeachersNFriendsCard
                                                            key={i}
                                                            userId={schoolId}
                                                            userPathCollection={schoolPathCollection}
                                                            userCurrentStatus='SCHOOL'
                                                            friendPathCollection={data?.friendUserName}
                                                            friendImage={data?.friendUserImage}
                                                            friendId={data?.friendUserId}
                                                            friendESchoolStatus={data?.friendUserStatus}
                                                        />
                                                    ))
                                                }
                                            </div>

                                            {
                                                followingUsersIds?.length > 8 ? (
                                                    <div style={{ marginTop: 30 }}>
                                                        {
                                                            totalFwngUserIds !== followingUsersIds?.length ? (
                                                                <div className='showMoreBtnFrndsNew' >
                                                                    <Button color="primary" onClick={() => loadMoreFwnUserIds(followingUsersIds?.length)} >Show More Users..</Button>
                                                                </div>
                                                            ) : (
                                                                <div className='noMorePostsNew'>
                                                                    <h4 >No more users to show</h4>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    // 
                                                    <></>
                                                )
                                            }
                                        </div>
                                    ) : (
                                        <div className='notFollowingAny'>
                                            <h3>User is not following any Teacher, Principal or Friends at the moment.</h3>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </Dialog>

                    <Dialog open={openFollowers} onClose={handleCloseFollowers} aria-labelledby="form-dialog-title">
                        <div className='dialogFollowErsIng'>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ width: '100%', bgcolor: 'background.paper', borderBottom: 1, color: 'divider' }}>
                                    <Tabs value={valueFollowers} onChange={handleChangeFollowers}>
                                        <Tab sx={{ width: '200px', color: 'rgb(153, 152, 152)' }} label="Follower Users" />
                                        <Tab sx={{ width: '200px', color: 'rgb(153, 152, 152)' }} label="Follower Schools" />
                                    </Tabs>
                                </Box>
                                <TabPanel value={valueFollowers} index={0}>
                                    <div >
                                        {
                                            followerUsersData.length > 0 ? (
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                                                    <div className='followingFriends'>
                                                        {
                                                            followerUsersData.map((data, i) => (
                                                                <FindToFollowTeachersNFriendsCard
                                                                    key={i}
                                                                    userId={schoolId}
                                                                    userPathCollection={schoolPathCollection}
                                                                    userCurrentStatus='SCHOOL'
                                                                    // friendName={data?.friendUserName}
                                                                    friendImage={data?.friendUserImage}
                                                                    friendId={data?.friendUserId}
                                                                    friendESchoolStatus={data?.friendUserStatus}

                                                                    friendPathCollection={data?.friendUserName}
                                                                />
                                                            ))
                                                        }
                                                    </div>

                                                    {
                                                        followerUsersIds?.length > 8 ? (
                                                            <div style={{ marginTop: 30 }}>
                                                                {
                                                                    totalFlwrUserIds !== followerUsersIds?.length ? (
                                                                        <div className='showMoreBtnFrndsNew' >
                                                                            <Button color="primary" onClick={() => loadMoreFlwrUserIds(followerUsersIds?.length)} >Show More Users..</Button>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='noMorePostsNew'>
                                                                            <h4 >No more users to show</h4>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        ) : (
                                                            // 
                                                            <></>
                                                        )
                                                    }
                                                </div>
                                            ) : (
                                                <div className='notFollowingAny'>
                                                    <h3>No Teacher, Principal or Friends are following User at the moment.</h3>
                                                </div>
                                            )
                                        }
                                    </div>
                                </TabPanel>
                                <TabPanel value={valueFollowers} index={1}>
                                    <div >
                                        {
                                            followerSchlsData.length > 0 ? (
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <div className='followerSchools'>
                                                        {
                                                            followerSchlsData.map((data, i) => (
                                                                <FindToFollowSchoolsCard
                                                                    key={i}
                                                                    userId={schoolId}
                                                                    userPathCollection={schoolPathCollection}
                                                                    userCurrentStatus='SCHOOL'
                                                                    schoolName={data?.schoolName}
                                                                    schoolImage={data?.schoolImage}
                                                                    schoolId={data?.schoolId}
                                                                />
                                                            ))
                                                        }
                                                    </div>

                                                    {
                                                        followerSchlsIds?.length > 8 ? (
                                                            <div style={{ marginTop: 30 }}>
                                                                {
                                                                    totalFlwrSchlIds !== followerSchlsIds?.length ? (
                                                                        <div className='showMoreBtnFrndsNew' >
                                                                            <Button color="primary" onClick={() => loadMoreFlwrSchlIds(followerSchlsIds?.length)} >Show More Users..</Button>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='noMorePostsNew'>
                                                                            <h4 >No more users to show</h4>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        ) : (
                                                            // 
                                                            <></>
                                                        )
                                                    }
                                                </div>
                                            ) : (
                                                <div className='notFollowingAny'>
                                                    <h3>No school is following User at the moment.</h3>
                                                </div>
                                            )
                                        }
                                    </div>
                                </TabPanel>
                            </Box>
                        </div>
                    </Dialog>


                </div>
            </div>

            <hr />

            <div >
                {
                    followingSchlsData.length > 0 ? (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div className='schoolfollowingSchools'>
                                {
                                    followingSchlsData.map((data, i) => (
                                        <FindToFollowSchoolsCard
                                            key={i}
                                            userId={schoolId}
                                            userPathCollection={schoolPathCollection}
                                            userCurrentStatus={'SCHOOL'}
                                            schoolName={data?.schoolName}
                                            schoolImage={data?.schoolImage}
                                            schoolId={data?.schoolId}
                                        />
                                    ))
                                }
                            </div>
                            {
                                followingSchlsIds?.length > 7 ? (
                                    <div style={{ marginTop: 30 }}>
                                        {
                                            totalFwngSchlIds !== followingSchlsIds?.length ? (
                                                <div className='showMoreBtnFrndsNew' >
                                                    <Button color="primary" onClick={() => loadMoreFwnSchlIds(followingSchlsIds?.length)} >Show More Schools..</Button>
                                                </div>
                                            ) : (
                                                <div className='noMorePostsNew'>
                                                    <h4 >No more schools to show</h4>
                                                </div>
                                            )
                                        }
                                    </div>
                                ) : (
                                    // 
                                    <></>
                                )
                            }
                        </div>
                    ) : (
                        <div className='notFollowingAny'>
                            <h3>You are not following any school at the moment.</h3>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default SchoolAccountFollowing