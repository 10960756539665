import React, { useEffect, useState } from 'react'
import '../CreateNewAccount/CreateSchoolAccountInfo.css'
import { useHistory } from 'react-router-dom';

// import dB, { auth } from '../firebase';
import { doc, getDoc, serverTimestamp, writeBatch } from 'firebase/firestore';
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';

import {
    Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel,
    MenuItem, Select, TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB, { auth } from '../../firebase';

function CreateSchoolAccountInfo(props) {

    const { adminFName, adminLName, inputSchoolName, inputSchoolEmail, inputSchoolPhoneNo,
        affiliation, confirmPassword, subjectsOfClass, schoolClassData, inputAddress, inputVillage, inputTehsil,
        inputCity, inputDistrict, inputPinCode, indianState } = props

    const history = useHistory();

    const [showEnteredSchoolInfo, setshowEnteredSchoolInfo] = useState({
        inputAddress: { error: false, helperText: '' },
        inputCity: { error: false, helperText: '' },
        inputDistrict: { error: false, helperText: '' },
        inputPinCode: { error: false, helperText: '' },
        indianState: { error: false, helperText: '' },
        inputEstYear: { error: false, helperText: '' },
        availableFacilities: ''
    })

    const estYears = ['2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010',
        '2009', '2008', '2007', '2006', '2005', '2004', '2003', '2002', '2001', '2000', '1999', '1998', '1997', '1996',
        '1995', '1994', '1993', '1992', '1991', '1990', '1989', '1988', '1987', '1986', '1985', '1984', '1983', '1982',
        '1981', '1980', '1979', '1978', '1977', '1976', '1975', '1974', '1973', '1972', '1971', '1970', '1969', '1968',
        '1967', '1966', '1965', '1964', '1963', '1962', '1961', '1960', '1959', '1958', '1957', '1956', '1955', '1954',
        '1953', '1952', '1951', '1950', '1949', '1948', '1947', '1946', '1945', '1944', '1943', '1942', '1941', '1940',
        '1939', '1938', '1937', '1936', '1935', '1934', '1933', '1932', '1931', '1930', '1929', '1928', '1927', '1926',
        '1925', '1924', '1923', '1922', '1921', '1920', '1919', '1918', '1917', '1916', '1915', '1914', '1913', '1912',
        '1911', '1910', '1909', '1908', '1907', '1906', '1905', '1904', '1903', '1902', '1901']

    const [textInputs, setTextInputs] = useState({ inputVision: '', inputMission: '', inputAboutSchool: '', })
    const handleChangeInputs = (event) => {
        event.preventDefault();
        setTextInputs(prev => { return { ...prev, [event.target.name]: event.target.value } });
        setshowEnteredSchoolInfo(prev => { return { ...prev, [event.target.name]: { error: false, helperText: '' } } })
    }

    const [dropDown, setDropDown] = useState({ indianState: '', inputEstYear: '', })
    const handleChnangeDropDown = (event) => {
        event.preventDefault();
        setDropDown(prev => { return { ...prev, [event.target.name]: event.target.value } });
        setshowEnteredSchoolInfo(prev => { return { ...prev, [event.target.name]: { error: false, helperText: '' } } })
    }


    const [facilitiesState, setfacilitiesState] = useState({
        ComputerLab: false, PhysicsLab: false, ElectronicsLab: false, BiologyLab: false, ChemistryLab: false,
        LibraryNReadingRoom: false, Playground: false, BoysHostel: false, GirlsHostel: false, GirlsCommonRoom: false,
        DedicatedClassrooms: false, DigitalClassrooms: false, IndoorMultipurposeHall: false, ArtsandMusicRoom: false,
        SwimmingPool: false, SchoolBus: false, Auditorium: false
    });
    const handleChange = (event) => {
        event.preventDefault()
        setfacilitiesState({ ...facilitiesState, [event.target.name]: event.target.checked, });
        if (event.target.checked) {
            setcheckedFacilities(prev => { return [...prev, event.target.value] })
        } else {
            const a = checkedFacilities.indexOf(event.target.value);
            if (a >= 0) { checkedFacilities.splice(a, 1); }
        }
        setshowEnteredSchoolInfo(prev => { return { ...prev, availableFacilities: '' } })
    };

    const [checkedFacilities, setcheckedFacilities] = useState([])

    const [newSchoolAddress, setnewSchoolAddress] = useState('');
    const [schoolFullName, setschoolFullName] = useState('');

    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (inputVillage && inputTehsil) {
                const schoolNameNAddress = inputVillage + ', ' + inputTehsil + ', ' + inputCity + ', ' + inputDistrict + ', ' + dropDown.indianState + ', ' + inputPinCode;
                setnewSchoolAddress(schoolNameNAddress)
            }
            if (inputVillage && !inputTehsil) {
                const schoolNameNAddress = inputVillage + ', ' + inputCity + ', ' + inputDistrict + ', ' + dropDown.indianState + ', ' + inputPinCode;
                setnewSchoolAddress(schoolNameNAddress)
            }
            if (!inputVillage && inputTehsil) {
                const schoolNameNAddress = inputTehsil + ', ' + inputCity + ', ' + inputDistrict + ', ' + dropDown.indianState + ', ' + inputPinCode;
                setnewSchoolAddress(schoolNameNAddress)
            }
            if (!inputVillage && !inputTehsil) {
                const schoolNameNAddress = inputCity + ', ' + inputDistrict + ', ' + dropDown.indianState + ', ' + inputPinCode;
                setnewSchoolAddress(schoolNameNAddress)
            }
        }
        return () => { aaa = false }
    }, [inputVillage, inputTehsil, inputCity, inputDistrict, dropDown.indianState, inputPinCode])

    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (inputSchoolName && newSchoolAddress) {
                setschoolFullName(inputSchoolName + ', ' + newSchoolAddress);
            }
        }
        return () => { aaa = false }

    }, [inputSchoolName, newSchoolAddress])

    const [firebaseError, setfirebaseError] = useState('')

    const [showBlock, setshowBlock] = useState({ showVisMisBlock: true, showLoginBlock: false })

    var batch = writeBatch(dB)
    const [btnLoading, setbtnLoading] = useState(false)
    const handleFinish = async (e) => {
        e.preventDefault()
        setbtnLoading(true)
        if (dropDown.inputEstYear && checkedFacilities.length > 0) {

            await createUserWithEmailAndPassword(auth, inputSchoolEmail, confirmPassword).then(async cred => {

                const inputCreatorName = adminFName + ' ' + adminLName

                await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "REGISTRATION_INFO"), {
                    SCHOOL_ADMIN_NAME: inputCreatorName,
                    SCHOOL_NAME: inputSchoolName,
                    SCHOOL_PHONE_NO: inputSchoolPhoneNo,
                    SCHOOL_AFFILIATION: affiliation,
                    SCHOOL_PASSWORD: confirmPassword,
                    SCHOOL_FULL_NAME: schoolFullName,
                })

                await batch.set(doc(dB, "SCHOOLS", cred.user.uid), {
                    SCHOOL_EMAIL: inputSchoolEmail,
                    USER_STATUS: 'SCHOOL',
                    SCHOOL_CREATED_ON: serverTimestamp(),
                    SCHOOL_FULL_NAME: schoolFullName,
                    SCHOOL_NAME: inputSchoolName,
                    USER_DISPLAY_PIC: ''
                })

                await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "SCHOOL_DETAILS"), {
                    SCHOOL_VISION: textInputs.inputVision,
                    SCHOOL_MISSION: textInputs.inputMission,
                    SCHOOL_ABOUT: textInputs.inputAboutSchool,
                    SCHOOL_ESTABLISHMENT: dropDown.inputEstYear,
                    SCHOOL_ADDRESS: inputAddress,
                    SCHOOL_CITY: inputCity,
                    SCHOOL_DISTRICT: inputDistrict,
                    SCHOOL_TEHSIL: inputTehsil,
                    SCHOOL_VILLAGE: inputVillage,
                    SCHOOL_PINCODE: inputPinCode,
                    SCHOOL_STATE: indianState,
                    SCHOOL_FACILITIES: checkedFacilities,
                    SCHOOL_REST_ADDRESS: newSchoolAddress,
                })

                if (subjectsOfClass.subNamesEngPreNurserySecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngPreNurserySecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngPreNurserySecA }) }
                if (subjectsOfClass.subNamesEngPreNurserySecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngPreNurserySecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngPreNurserySecB }) }
                if (subjectsOfClass.subNamesEngPreNurserySecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngPreNurserySecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngPreNurserySecC }) }
                if (subjectsOfClass.subNamesEngPreNurserySecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngPreNurserySecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngPreNurserySecD }) }
                if (subjectsOfClass.subNamesEngPreNurserySecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngPreNurserySecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngPreNurserySecE }) }
                if (subjectsOfClass.subNamesMarPreNurserySecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarPreNurserySecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarPreNurserySecA }) }
                if (subjectsOfClass.subNamesMarPreNurserySecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarPreNurserySecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarPreNurserySecB }) }
                if (subjectsOfClass.subNamesMarPreNurserySecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarPreNurserySecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarPreNurserySecC }) }
                if (subjectsOfClass.subNamesMarPreNurserySecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarPreNurserySecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarPreNurserySecD }) }
                if (subjectsOfClass.subNamesMarPreNurserySecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarPreNurserySecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarPreNurserySecE }) }

                // Nursery
                if (subjectsOfClass.subNamesEngNurserySecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngNurserySecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngNurserySecA }) }
                if (subjectsOfClass.subNamesEngNurserySecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngNurserySecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngNurserySecB }) }
                if (subjectsOfClass.subNamesEngNurserySecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngNurserySecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngNurserySecC }) }
                if (subjectsOfClass.subNamesEngNurserySecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngNurserySecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngNurserySecD }) }
                if (subjectsOfClass.subNamesEngNurserySecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngNurserySecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngNurserySecE }) }
                if (subjectsOfClass.subNamesMarNurserySecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarNurserySecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarNurserySecA }) }
                if (subjectsOfClass.subNamesMarNurserySecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarNurserySecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarNurserySecB }) }
                if (subjectsOfClass.subNamesMarNurserySecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarNurserySecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarNurserySecC }) }
                if (subjectsOfClass.subNamesMarNurserySecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarNurserySecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarNurserySecD }) }
                if (subjectsOfClass.subNamesMarNurserySecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarNurserySecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarNurserySecE }) }

                // Kindergarten-1
                if (subjectsOfClass.subNamesEngKG1SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngKG1SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngKG1SecA }) }
                if (subjectsOfClass.subNamesEngKG1SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngKG1SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngKG1SecB }) }
                if (subjectsOfClass.subNamesEngKG1SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngKG1SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngKG1SecC }) }
                if (subjectsOfClass.subNamesEngKG1SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngKG1SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngKG1SecD }) }
                if (subjectsOfClass.subNamesEngKG1SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngKG1SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngKG1SecE }) }
                if (subjectsOfClass.subNamesMarKG1SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarKG1SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarKG1SecA }) }
                if (subjectsOfClass.subNamesMarKG1SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarKG1SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarKG1SecB }) }
                if (subjectsOfClass.subNamesMarKG1SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarKG1SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarKG1SecC }) }
                if (subjectsOfClass.subNamesMarKG1SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarKG1SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarKG1SecD }) }
                if (subjectsOfClass.subNamesMarKG1SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarKG1SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarKG1SecE }) }

                // Kindergarten-2
                if (subjectsOfClass.subNamesEngKG2SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngKG2SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngKG2SecA }) }
                if (subjectsOfClass.subNamesEngKG2SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngKG2SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngKG2SecB }) }
                if (subjectsOfClass.subNamesEngKG2SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngKG2SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngKG2SecC }) }
                if (subjectsOfClass.subNamesEngKG2SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngKG2SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngKG2SecD }) }
                if (subjectsOfClass.subNamesEngKG2SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngKG2SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngKG2SecE }) }
                if (subjectsOfClass.subNamesMarKG2SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarKG2SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarKG2SecA }) }
                if (subjectsOfClass.subNamesMarKG2SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarKG2SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarKG2SecB }) }
                if (subjectsOfClass.subNamesMarKG2SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarKG2SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarKG2SecC }) }
                if (subjectsOfClass.subNamesMarKG2SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarKG2SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarKG2SecD }) }
                if (subjectsOfClass.subNamesMarKG2SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarKG2SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarKG2SecE }) }

                // Class-1
                if (subjectsOfClass.subNamesEngClass1SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass1SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass1SecA }) }
                if (subjectsOfClass.subNamesEngClass1SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass1SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass1SecB }) }
                if (subjectsOfClass.subNamesEngClass1SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass1SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass1SecC }) }
                if (subjectsOfClass.subNamesEngClass1SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass1SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass1SecD }) }
                if (subjectsOfClass.subNamesEngClass1SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass1SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass1SecE }) }
                if (subjectsOfClass.subNamesMarClass1SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass1SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass1SecA }) }
                if (subjectsOfClass.subNamesMarClass1SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass1SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass1SecB }) }
                if (subjectsOfClass.subNamesMarClass1SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass1SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass1SecC }) }
                if (subjectsOfClass.subNamesMarClass1SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass1SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass1SecD }) }
                if (subjectsOfClass.subNamesMarClass1SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass1SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass1SecE }) }

                // Class-2
                if (subjectsOfClass.subNamesEngClass2SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass2SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass2SecA }) }
                if (subjectsOfClass.subNamesEngClass2SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass2SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass2SecB }) }
                if (subjectsOfClass.subNamesEngClass2SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass2SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass2SecC }) }
                if (subjectsOfClass.subNamesEngClass2SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass2SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass2SecD }) }
                if (subjectsOfClass.subNamesEngClass2SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass2SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass2SecE }) }
                if (subjectsOfClass.subNamesMarClass2SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass2SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass2SecA }) }
                if (subjectsOfClass.subNamesMarClass2SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass2SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass2SecB }) }
                if (subjectsOfClass.subNamesMarClass2SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass2SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass2SecC }) }
                if (subjectsOfClass.subNamesMarClass2SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass2SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass2SecD }) }
                if (subjectsOfClass.subNamesMarClass2SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass2SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass2SecE }) }

                // Class-3
                if (subjectsOfClass.subNamesEngClass3SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass3SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass3SecA }) }
                if (subjectsOfClass.subNamesEngClass3SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass3SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass3SecB }) }
                if (subjectsOfClass.subNamesEngClass3SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass3SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass3SecC }) }
                if (subjectsOfClass.subNamesEngClass3SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass3SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass3SecD }) }
                if (subjectsOfClass.subNamesEngClass3SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass3SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass3SecE }) }
                if (subjectsOfClass.subNamesMarClass3SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass3SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass3SecA }) }
                if (subjectsOfClass.subNamesMarClass3SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass3SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass3SecB }) }
                if (subjectsOfClass.subNamesMarClass3SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass3SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass3SecC }) }
                if (subjectsOfClass.subNamesMarClass3SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass3SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass3SecD }) }
                if (subjectsOfClass.subNamesMarClass3SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass3SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass3SecE }) }

                // Class-4
                if (subjectsOfClass.subNamesEngClass4SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass4SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass4SecA }) }
                if (subjectsOfClass.subNamesEngClass4SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass4SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass4SecB }) }
                if (subjectsOfClass.subNamesEngClass4SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass4SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass4SecC }) }
                if (subjectsOfClass.subNamesEngClass4SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass4SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass4SecD }) }
                if (subjectsOfClass.subNamesEngClass4SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass4SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass4SecE }) }
                if (subjectsOfClass.subNamesMarClass4SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass4SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass4SecA }) }
                if (subjectsOfClass.subNamesMarClass4SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass4SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass4SecB }) }
                if (subjectsOfClass.subNamesMarClass4SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass4SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass4SecC }) }
                if (subjectsOfClass.subNamesMarClass4SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass4SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass4SecD }) }
                if (subjectsOfClass.subNamesMarClass4SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass4SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass4SecE }) }

                // Class-5
                if (subjectsOfClass.subNamesEngClass5SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass5SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass5SecA }) }
                if (subjectsOfClass.subNamesEngClass5SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass5SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass5SecB }) }
                if (subjectsOfClass.subNamesEngClass5SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass5SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass5SecC }) }
                if (subjectsOfClass.subNamesEngClass5SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass5SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass5SecD }) }
                if (subjectsOfClass.subNamesEngClass5SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass5SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass5SecE }) }
                if (subjectsOfClass.subNamesMarClass5SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass5SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass5SecA }) }
                if (subjectsOfClass.subNamesMarClass5SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass5SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass5SecB }) }
                if (subjectsOfClass.subNamesMarClass5SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass5SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass5SecC }) }
                if (subjectsOfClass.subNamesMarClass5SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass5SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass5SecD }) }
                if (subjectsOfClass.subNamesMarClass5SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass5SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass5SecE }) }

                // Class-6
                if (subjectsOfClass.subNamesEngClass6SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass6SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass6SecA }) }
                if (subjectsOfClass.subNamesEngClass6SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass6SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass6SecB }) }
                if (subjectsOfClass.subNamesEngClass6SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass6SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass6SecC }) }
                if (subjectsOfClass.subNamesEngClass6SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass6SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass6SecD }) }
                if (subjectsOfClass.subNamesEngClass6SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass6SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass6SecE }) }
                if (subjectsOfClass.subNamesMarClass6SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass6SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass6SecA }) }
                if (subjectsOfClass.subNamesMarClass6SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass6SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass6SecB }) }
                if (subjectsOfClass.subNamesMarClass6SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass6SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass6SecC }) }
                if (subjectsOfClass.subNamesMarClass6SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass6SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass6SecD }) }
                if (subjectsOfClass.subNamesMarClass6SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass6SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass6SecE }) }

                // Class-7
                if (subjectsOfClass.subNamesEngClass7SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass7SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass7SecA }) }
                if (subjectsOfClass.subNamesEngClass7SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass7SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass7SecB }) }
                if (subjectsOfClass.subNamesEngClass7SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass7SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass7SecC }) }
                if (subjectsOfClass.subNamesEngClass7SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass7SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass7SecD }) }
                if (subjectsOfClass.subNamesEngClass7SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass7SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass7SecE }) }
                if (subjectsOfClass.subNamesMarClass7SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass7SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass7SecA }) }
                if (subjectsOfClass.subNamesMarClass7SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass7SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass7SecB }) }
                if (subjectsOfClass.subNamesMarClass7SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass7SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass7SecC }) }
                if (subjectsOfClass.subNamesMarClass7SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass7SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass7SecD }) }
                if (subjectsOfClass.subNamesMarClass7SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass7SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass7SecE }) }

                // Class-8
                if (subjectsOfClass.subNamesEngClass8SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass8SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass8SecA }) }
                if (subjectsOfClass.subNamesEngClass8SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass8SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass8SecB }) }
                if (subjectsOfClass.subNamesEngClass8SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass8SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass8SecC }) }
                if (subjectsOfClass.subNamesEngClass8SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass8SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass8SecD }) }
                if (subjectsOfClass.subNamesEngClass8SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass8SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass8SecE }) }
                if (subjectsOfClass.subNamesMarClass8SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass8SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass8SecA }) }
                if (subjectsOfClass.subNamesMarClass8SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass8SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass8SecB }) }
                if (subjectsOfClass.subNamesMarClass8SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass8SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass8SecC }) }
                if (subjectsOfClass.subNamesMarClass8SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass8SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass8SecD }) }
                if (subjectsOfClass.subNamesMarClass8SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass8SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass8SecE }) }

                // Class-9
                if (subjectsOfClass.subNamesEngClass9SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass9SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass9SecA }) }
                if (subjectsOfClass.subNamesEngClass9SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass9SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass9SecB }) }
                if (subjectsOfClass.subNamesEngClass9SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass9SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass9SecC }) }
                if (subjectsOfClass.subNamesEngClass9SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass9SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass9SecD }) }
                if (subjectsOfClass.subNamesEngClass9SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass9SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass9SecE }) }
                if (subjectsOfClass.subNamesMarClass9SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass9SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass9SecA }) }
                if (subjectsOfClass.subNamesMarClass9SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass9SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass9SecB }) }
                if (subjectsOfClass.subNamesMarClass9SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass9SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass9SecC }) }
                if (subjectsOfClass.subNamesMarClass9SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass9SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass9SecD }) }
                if (subjectsOfClass.subNamesMarClass9SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass9SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass9SecE }) }

                // Class-10
                if (subjectsOfClass.subNamesEngClass10SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass10SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass10SecA }) }
                if (subjectsOfClass.subNamesEngClass10SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass10SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass10SecB }) }
                if (subjectsOfClass.subNamesEngClass10SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass10SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass10SecC }) }
                if (subjectsOfClass.subNamesEngClass10SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass10SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass10SecD }) }
                if (subjectsOfClass.subNamesEngClass10SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass10SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass10SecE }) }
                if (subjectsOfClass.subNamesMarClass10SecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass10SecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass10SecA }) }
                if (subjectsOfClass.subNamesMarClass10SecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass10SecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass10SecB }) }
                if (subjectsOfClass.subNamesMarClass10SecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass10SecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass10SecC }) }
                if (subjectsOfClass.subNamesMarClass10SecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass10SecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass10SecD }) }
                if (subjectsOfClass.subNamesMarClass10SecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass10SecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass10SecE }) }

                // Class-11 Arts
                if (subjectsOfClass.subNamesMarClass11ArtsSecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass11ArtsSecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass11ArtsSecA }) }
                if (subjectsOfClass.subNamesMarClass11ArtsSecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass11ArtsSecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass11ArtsSecB }) }
                if (subjectsOfClass.subNamesMarClass11ArtsSecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass11ArtsSecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass11ArtsSecC }) }
                if (subjectsOfClass.subNamesMarClass11ArtsSecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass11ArtsSecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass11ArtsSecD }) }
                if (subjectsOfClass.subNamesMarClass11ArtsSecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass11ArtsSecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass11ArtsSecE }) }

                // Class-11 Commerce
                if (subjectsOfClass.subNamesEngClass11CommerceSecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass11CommerceSecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass11CommerceSecA }) }
                if (subjectsOfClass.subNamesEngClass11CommerceSecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass11CommerceSecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass11CommerceSecB }) }
                if (subjectsOfClass.subNamesEngClass11CommerceSecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass11CommerceSecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass11CommerceSecC }) }
                if (subjectsOfClass.subNamesEngClass11CommerceSecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass11CommerceSecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass11CommerceSecD }) }
                if (subjectsOfClass.subNamesEngClass11CommerceSecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass11CommerceSecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass11CommerceSecE }) }
                if (subjectsOfClass.subNamesMarClass11CommerceSecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass11CommerceSecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass11CommerceSecA }) }
                if (subjectsOfClass.subNamesMarClass11CommerceSecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass11CommerceSecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass11CommerceSecB }) }
                if (subjectsOfClass.subNamesMarClass11CommerceSecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass11CommerceSecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass11CommerceSecC }) }
                if (subjectsOfClass.subNamesMarClass11CommerceSecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass11CommerceSecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass11CommerceSecD }) }
                if (subjectsOfClass.subNamesMarClass11CommerceSecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass11CommerceSecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass11CommerceSecE }) }

                // Class-11 Science
                if (subjectsOfClass.subNamesEngClass11ScienceSecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass11ScienceSecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass11ScienceSecA }) }
                if (subjectsOfClass.subNamesEngClass11ScienceSecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass11ScienceSecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass11ScienceSecB }) }
                if (subjectsOfClass.subNamesEngClass11ScienceSecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass11ScienceSecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass11ScienceSecC }) }
                if (subjectsOfClass.subNamesEngClass11ScienceSecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass11ScienceSecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass11ScienceSecD }) }
                if (subjectsOfClass.subNamesEngClass11ScienceSecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass11ScienceSecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass11ScienceSecE }) }

                // Class-12 Arts
                if (subjectsOfClass.subNamesMarClass12ArtsSecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass12ArtsSecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass12ArtsSecA }) }
                if (subjectsOfClass.subNamesMarClass12ArtsSecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass12ArtsSecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass12ArtsSecB }) }
                if (subjectsOfClass.subNamesMarClass12ArtsSecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass12ArtsSecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass12ArtsSecC }) }
                if (subjectsOfClass.subNamesMarClass12ArtsSecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass12ArtsSecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass12ArtsSecD }) }
                if (subjectsOfClass.subNamesMarClass12ArtsSecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass12ArtsSecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass12ArtsSecE }) }

                // Class-12 Commerce
                if (subjectsOfClass.subNamesEngClass12CommerceSecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass12CommerceSecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass12CommerceSecA }) }
                if (subjectsOfClass.subNamesEngClass12CommerceSecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass12CommerceSecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass12CommerceSecB }) }
                if (subjectsOfClass.subNamesEngClass12CommerceSecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass12CommerceSecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass12CommerceSecC }) }
                if (subjectsOfClass.subNamesEngClass12CommerceSecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass12CommerceSecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass12CommerceSecD }) }
                if (subjectsOfClass.subNamesEngClass12CommerceSecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass12CommerceSecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass12CommerceSecE }) }
                if (subjectsOfClass.subNamesMarClass12CommerceSecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass12CommerceSecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass12CommerceSecA }) }
                if (subjectsOfClass.subNamesMarClass12CommerceSecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass12CommerceSecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass12CommerceSecB }) }
                if (subjectsOfClass.subNamesMarClass12CommerceSecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass12CommerceSecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass12CommerceSecC }) }
                if (subjectsOfClass.subNamesMarClass12CommerceSecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass12CommerceSecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass12CommerceSecD }) }
                if (subjectsOfClass.subNamesMarClass12CommerceSecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'MarClass12CommerceSecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesMarClass12CommerceSecE }) }

                // Class-12 Science
                if (subjectsOfClass.subNamesEngClass12ScienceSecA.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass12ScienceSecA'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass12ScienceSecA }) }
                if (subjectsOfClass.subNamesEngClass12ScienceSecB.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass12ScienceSecB'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass12ScienceSecB }) }
                if (subjectsOfClass.subNamesEngClass12ScienceSecC.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass12ScienceSecC'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass12ScienceSecC }) }
                if (subjectsOfClass.subNamesEngClass12ScienceSecD.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass12ScienceSecD'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass12ScienceSecD }) }
                if (subjectsOfClass.subNamesEngClass12ScienceSecE.length > 0) { await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS", "CLASSES", 'EngClass12ScienceSecE'), { SUBJECTS_LIST: subjectsOfClass.subNamesEngClass12ScienceSecE }) }

                await batch.set(doc(dB, "SCHOOLS", cred.user.uid, inputSchoolName, "CLASSES_DETAILS"), {
                    CLASS_LIST: schoolClassData
                })

                await batch.commit().then(() => {
                    setbtnLoading(false)
                    setshowBlock(prev => { return { ...prev, showVisMisBlock: false, showLoginBlock: true } })
                }).catch(err => setfirebaseError(err.message))
            }).catch(err => setfirebaseError(err.message))
        } else {
            setbtnLoading(false)
            if (!dropDown.inputEstYear) { setshowEnteredSchoolInfo(prev => { return { ...prev, inputEstYear: { error: true, helperText: 'Please select establishment year' } } }) }
            if (checkedFacilities.length === 0) { setshowEnteredSchoolInfo(prev => { return { ...prev, availableFacilities: 'show' } }) }
        }
    }

    const handleSchoolLogin = (e) => {
        e.preventDefault();
        setbtnLoading(true)
        signInWithEmailAndPassword(auth, inputSchoolEmail, confirmPassword)
            .then(userCredentials => { setbtnLoading(false); history.push('/schoolaccountprofile'); })
            .catch(err => setfirebaseError(err.message))

    }

    // onAuthStateChanged(auth, async (userCredentials) => {
    //     if (userCredentials) {
    //         var uid = userCredentials.uid;
    //         const q = doc(dB, "USERS", uid)
    //         const schlDoc = await getDoc(q).catch(err => setfirebaseError(err.message))
    //         if (schlDoc.exists()) {
    //             history.push('/schoolaccountprofile');
    //         }
    //     }
    // })

    return (
        <div className='createschoolaccountinfo'>
            <div className='createschoolaccountinfo__container'>
                <h1>ConnectEdApp</h1>
                <p>Add your School Information and other Details</p>
                <hr />

                {
                    showBlock.showVisMisBlock && (
                        <div className='InsideHr'>
                            <div className='createschoolaccountinfo__containerOne'>
                                <div className='addinfo'>
                                    <h3>Add School Information</h3>
                                </div>
                                <div className='createschoolaccountinfo__containerVMData'>
                                    {/* add Vision and Mission */}
                                    <div className='createschoolaccountinfo__containerVMDataVision'>
                                        <h4>Vision</h4>
                                        <Box
                                            component="form"
                                            sx={{
                                                '& .MuiTextField-root': { m: 1, width: '65ch' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <div>
                                                <TextField
                                                    id="inputVision"
                                                    type='text'
                                                    name='inputVision'
                                                    label="Vision of the School. (Optional) Max. 500 Characters"
                                                    // placeholder='E-mail Id'
                                                    value={textInputs.inputVision}
                                                    onChange={handleChangeInputs}
                                                    multiline
                                                    rows={3}
                                                    rowsMax={8}
                                                    inputProps={{ maxLength: 500 }}
                                                />
                                            </div>
                                        </Box>
                                    </div>
                                    <div className='createschoolaccountinfo__containerVMDataMission'>
                                        <h4>Mission</h4>
                                        <Box
                                            component="form"
                                            sx={{
                                                '& .MuiTextField-root': { m: 1, width: '65ch' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <div>
                                                <TextField
                                                    id="inputMission"
                                                    type='text'
                                                    name='inputMission'
                                                    label="Mission of the School. (Optional) Max. 500 Characters"
                                                    // placeholder='E-mail Id'
                                                    value={textInputs.inputMission}
                                                    onChange={handleChangeInputs}
                                                    multiline
                                                    rows={3}
                                                    rowsMax={8}
                                                    inputProps={{ maxLength: 500 }}
                                                />
                                            </div>
                                        </Box>
                                    </div>
                                    <div className='createschoolaccountinfo__containerVMDataAbout'>
                                        <h4>About School</h4>
                                        <Box
                                            component="form"
                                            sx={{
                                                '& .MuiTextField-root': { m: 1, width: '65ch' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <div>
                                                <TextField
                                                    id="inputAboutSchool"
                                                    type='text'
                                                    name='inputAboutSchool'
                                                    label="Some Information About School. (Optional) Max. 750 Characters"
                                                    // placeholder='E-mail Id'
                                                    value={textInputs.inputAboutSchool}
                                                    onChange={handleChangeInputs}
                                                    multiline
                                                    rows={3}
                                                    rowsMax={8}
                                                    inputProps={{ maxLength: 750 }}
                                                />
                                            </div>
                                        </Box>
                                    </div>
                                    <div className='createschoolaccountinfo__containerVMDataEstablished'>
                                        <h4>Established In</h4>
                                        <FormControl name='studentsSchoolClassSection' error={showEnteredSchoolInfo.inputEstYear.error} sx={{ m: 1, minWidth: "30ch" }}>
                                            <InputLabel name='studentsSchoolClassSection' id="studentsSchoolClassSection">Establishment Year of School</InputLabel>
                                            <Select
                                                labelId="studentsSchoolClassSection"
                                                id="inputEstYear"
                                                name='inputEstYear'
                                                value={dropDown.inputEstYear}
                                                onChange={handleChnangeDropDown}
                                                label="Establishment Year of School"
                                            >
                                                <MenuItem aria-label="None" value="" />
                                                {estYears.map((yr) => (
                                                    <MenuItem key={yr} value={yr}>
                                                        {yr}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText id="inputCreatorFName"><p>{showEnteredSchoolInfo.inputEstYear.helperText}</p></FormHelperText>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <hr />

                            <div className='createschoolaccountinfo__containerOne'>
                                <div className='createschoolaccountinfo__containerFacilities'>
                                    {/* facilities like Computer Lab/Physics lab/Electronics Lab/Biology Lab/Chemistry Lab/Library/Playground/hostel/Dedicated Classrooms/Indoor Multipurpose Hall/Arts and Music Room/Swimming Pool etc. */}
                                    <p>Please tick all the facilities available at your school. It will help students, parents & others to get to know about your school's infrastructure.</p>
                                    {showEnteredSchoolInfo.availableFacilities === 'show' && (<h5>Please select the facilities available at your school. It will help others to know about failities available at your school</h5>)}

                                    <div className='createschoolaccountinfo__containerFacilitiesData'>
                                        <h4>Facilities available at school</h4>
                                        <div>
                                            <Box sx={{ display: 'flex' }}>
                                                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={<Checkbox size="small" color='primary' value={'Computer Lab'} checked={facilitiesState.ComputerLab} onChange={handleChange} name="ComputerLab" />}
                                                            label="Computer Lab"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox size="small" color='primary' value={'Physics Lab'} checked={facilitiesState.PhysicsLab} onChange={handleChange} name="PhysicsLab" />}
                                                            label="Physics Lab"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox size="small" color='primary' value={'Electronics Lab'} checked={facilitiesState.ElectronicsLab} onChange={handleChange} name="ElectronicsLab" />}
                                                            label="Electronics Lab"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox size="small" color='primary' value={'Biology Lab'} checked={facilitiesState.BiologyLab} onChange={handleChange} name="BiologyLab" />}
                                                            label="Biology Lab"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox size="small" color='primary' value={'Chemistry Lab'} checked={facilitiesState.ChemistryLab} onChange={handleChange} name="ChemistryLab" />}
                                                            label="Chemistry Lab"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox size="small" color='primary' value={'Library & Reading Room'} checked={facilitiesState.LibraryNReadingRoom} onChange={handleChange} name="LibraryNReadingRoom" />}
                                                            label="Library & Reading Room"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox size="small" color='primary' value={'Playground'} checked={facilitiesState.Playground} onChange={handleChange} name="Playground" />}
                                                            label="Playground"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox size="small" color='primary' value={'Auditorium'} checked={facilitiesState.Auditorium} onChange={handleChange} name="Auditorium" />}
                                                            label="Auditorium"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox size="small" color='primary' value={'Boys Hostel'} checked={facilitiesState.BoysHostel} onChange={handleChange} name="BoysHostel" />}
                                                            label="Boys Hostel"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox size="small" color='primary' value={'Girls Hostel'} checked={facilitiesState.GirlsHostel} onChange={handleChange} name="GirlsHostel" />}
                                                            label="Girls Hostel"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox size="small" color='primary' value={'Girls Common Room'} checked={facilitiesState.GirlsCommonRoom} onChange={handleChange} name="GirlsCommonRoom" />}
                                                            label="Girls Common Room"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox size="small" color='primary' value={'Dedicated Classrooms'} checked={facilitiesState.DedicatedClassrooms} onChange={handleChange} name="DedicatedClassrooms" />}
                                                            label="Dedicated Classrooms"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox size="small" color='primary' value={'Digital Classrooms'} checked={facilitiesState.DigitalClassrooms} onChange={handleChange} name="DigitalClassrooms" />}
                                                            label="Digital Classrooms"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox size="small" color='primary' value={'Indoor Multipurpose Hall'} checked={facilitiesState.IndoorMultipurposeHall} onChange={handleChange} name="IndoorMultipurposeHall" />}
                                                            label="Indoor Multipurpose Hall"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox size="small" color='primary' value={'Arts & Music Room'} checked={facilitiesState.ArtsandMusicRoom} onChange={handleChange} name="ArtsandMusicRoom" />}
                                                            label="Arts & Music Room"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox size="small" color='primary' value={'Swimming Pool'} checked={facilitiesState.SwimmingPool} onChange={handleChange} name="SwimmingPool" />}
                                                            label="Swimming Pool"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox size="small" color='primary' value={'School Bus'} checked={facilitiesState.SchoolBus} onChange={handleChange} name="SchoolBus" />}
                                                            label="School Bus"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                                <LoadingButton
                                    onClick={handleFinish}
                                    loading={btnLoading}
                                    variant="outlined"
                                >
                                    Finish
                                </LoadingButton>
                                <h2>{firebaseError}</h2>
                            </div>
                            <hr />
                        </div>
                    )
                }

                {
                    showBlock.showLoginBlock && (
                        <div className='buttonLoginSchool'>
                            <LoadingButton
                                onClick={handleSchoolLogin}
                                loading={btnLoading}
                                variant="outlined"
                            >
                                Sign in
                            </LoadingButton>
                            <h2>{firebaseError}</h2>
                        </div>
                    )
                }


            </div>

        </div>
    )
}

export default CreateSchoolAccountInfo;