import React, { useEffect, useState } from 'react'
import '../Social/UserAbout.css'
import { doc, onSnapshot } from 'firebase/firestore';
import { Link } from 'react-router-dom';

import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import { Avatar } from '@mui/material';
import dB from '../../firebase';

function UserAbout(props) {
    const { userId, userCurrentStatus, userPathCollection, friendId, friendFullName, friendPathCollection, friendCurrentStatus,
        userBio, userDOB, userGender, userMobNo, userEmail, userDegree1, userDegree2, userDegree3, userAchievements,
        userStatuses, friendStatuses, isStudentData, studentVerificationDeatils, princiVerificationDeatils, isTeacherData, teacherVerificationDeatils,
    } = props

    const [studentSchoolDetails, setstudentSchoolDetails] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (studentVerificationDeatils?.USER_SCHOOL_ID) {
                onSnapshot(doc(dB, "SCHOOLS", studentVerificationDeatils?.USER_SCHOOL_ID), (snap) => {
                    if (snap.exists()) {
                        setstudentSchoolDetails(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [studentVerificationDeatils?.USER_SCHOOL_ID])

    const [princiSchoolDetails, setprinciSchoolDetails] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (princiVerificationDeatils?.USER_SCHOOL_ID) {
                onSnapshot(doc(dB, "SCHOOLS", princiVerificationDeatils?.USER_SCHOOL_ID), (snap) => {
                    if (snap.exists()) { setprinciSchoolDetails(snap.data()) }
                })
            }
        }
        return () => { aaa = false }
    }, [princiVerificationDeatils?.USER_SCHOOL_ID])

    const [teacherSchoolDetails, setteacherSchoolDetails] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (teacherVerificationDeatils?.USER_SCHOOL_ID) {
                onSnapshot(doc(dB, "SCHOOLS", teacherVerificationDeatils?.USER_SCHOOL_ID), (snap) => {
                    if (snap.exists()) { setteacherSchoolDetails(snap.data()) }
                })
            }
        }
        return () => { aaa = false }
    }, [teacherVerificationDeatils?.USER_SCHOOL_ID])
    return (
        <div className='userabout'>

            {/* about */}
            {
                userBio && (
                    <div className='userabout__about'>
                        <div className='userabout__Title'>
                            <h4>About</h4>
                        </div>
                        <div className='userabout__Info'>
                            <div className='userabout__InfoBio'>
                                <p className='userabout__InfoBioData'>{userBio}</p>
                            </div>
                        </div>
                    </div>
                )
            }


            {/* gender, dob */}
            <div className='userabout__basic'>
                <div className='userabout__Title'>
                    <h4>Basic Info</h4>
                </div>
                <div className='userabout__Info'>
                    {
                        userGender && (
                            <div className='userabout__option'>
                                <PermIdentityOutlinedIcon />
                                <p className='userabout__data'>{userGender}</p>
                            </div>
                        )
                    }
                    {
                        userDOB && (
                            <div className='userabout__option'>
                                <CakeOutlinedIcon />
                                <p className='userabout__data'>{userDOB}</p>
                            </div>
                        )
                    }

                </div>
            </div>

            {/* email, mob */}
            {
                (userMobNo || userEmail) && (
                    <div className='userabout__contact'>
                        <div className='userabout__Title'>
                            <h4>Contact</h4>
                        </div>
                        <div className='userabout__Info'>
                            {
                                userMobNo && (
                                    <div className='userabout__option'>
                                        <PhoneAndroidOutlinedIcon />
                                        <p className='userabout__data'>{userMobNo}</p>
                                    </div>
                                )
                            }
                            {
                                userEmail && (
                                    <div className='userabout__option'>
                                        <AlternateEmailOutlinedIcon />
                                        <p className='userabout__data'>{userEmail}</p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }


            {/* e-schooling status */}
            {
                friendId === null ? (
                    <div style={{ display: userStatuses?.length === 0 || (userStatuses?.length === 1 && userStatuses?.includes('PARENT')) ? 'none' : 'flex' }} className='userabout__education'>
                        <div className='userabout__Title'>
                            <h4>E-Schooling<br />Status</h4>
                        </div>
                        <div className='userabout__Info'>
                            {
                                userStatuses?.includes('STUDENT') && (
                                    <div className='userabout__InfoStudent'>
                                        <div>
                                            <div className='studentVerif'>
                                                <h4>Student</h4>
                                                {
                                                    studentVerificationDeatils?.VERIFIED_STUDENT === true ? (
                                                        <h5 style={{ fontSize: '14px', fontWeight: '600', marginLeft: '10px', color: 'var(--connected-color)' }}>verified by school</h5>
                                                    ) : (
                                                        <h3 style={{ fontSize: '14px', fontWeight: '600', marginLeft: '10px', color: 'red' }}>not yet verified</h3>
                                                    )
                                                }
                                            </div>
                                            <p style={{ fontSize: '14px', fontWeight: '700', color: 'black', marginLeft: '20px' }}>({isStudentData?.MEDIUM} · {isStudentData?.STUDENTS_CLASS} · {isStudentData?.STUDENTS_SECTION})</p>

                                        </div>
                                        <div className='userabout__optionschool'>
                                            <Avatar
                                                src={studentSchoolDetails?.USER_DISPLAY_PIC}
                                                alt={studentSchoolDetails?.SCHOOL_FULL_NAME}
                                                sx={{ width: 40, height: 40, }}
                                            />
                                            <p className='userabout__data'>
                                                <Link to={`/schoolfriendprofile/${studentVerificationDeatils?.USER_SCHOOL_ID}`} style={{ textDecoration: 'none', color: 'black' }}>
                                                    {studentSchoolDetails?.SCHOOL_FULL_NAME}
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                userStatuses?.includes('TEACHER') && (
                                    <div className='userabout__InfoTeacher'>
                                        <h4>Teacher</h4>
                                        <div className='userabout__InfoTeacherData'>
                                            {
                                                isTeacherData?.map((data, i) => (
                                                    <div key={i} className='dataAndVerif'>
                                                        <h5>- {data?.subData?.TEACH_MEDIUM} | {data?.subData?.TEACH_CLASS} | {data?.subData?.TEACH_CLASS_SEC} | {data?.subData?.TEACH_CLASS_SEC_SUB} | {data?.subData?.VERIFIED_TEACHER === true ? <span style={{ color: 'var(--connected-color)' }}>verified by school</span> : <span style={{ color: 'red' }}>not yet verified</span>}</h5>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className='userabout__optionschool'>
                                            <Avatar
                                                src={teacherSchoolDetails?.USER_DISPLAY_PIC}
                                                alt={teacherSchoolDetails?.SCHOOL_FULL_NAME}
                                                sx={{ width: 40, height: 40, }}
                                            />
                                            <p className='userabout__data'>
                                                <Link to={`/schoolfriendprofile/${teacherVerificationDeatils?.USER_SCHOOL_ID}`} style={{ textDecoration: 'none', color: 'black' }}>
                                                    {teacherSchoolDetails?.SCHOOL_FULL_NAME}
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                userStatuses?.includes('PRINCIPAL') && (
                                    <div className='userabout__InfoPrincipal'>
                                        <div className='princiVerfi'>
                                            <h4>Principal</h4>
                                            {
                                                princiVerificationDeatils?.VERIFIED_PRINCIPAL === true ? (
                                                    <h5>verified by school</h5>
                                                ) : (
                                                    <h3>not yet verified</h3>
                                                )
                                            }
                                        </div>
                                        <div className='userabout__optionschool'>
                                            <Avatar
                                                src={princiSchoolDetails?.USER_DISPLAY_PIC}
                                                alt={princiSchoolDetails?.SCHOOL_FULL_NAME}
                                                sx={{ width: 40, height: 40, }}
                                            />
                                            <p className='userabout__data'>
                                                <Link to={`/schoolfriendprofile/${princiVerificationDeatils?.USER_SCHOOL_ID}`} style={{ textDecoration: 'none', color: 'black' }}>
                                                    {princiSchoolDetails?.SCHOOL_FULL_NAME}
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                ) : (
                    <div style={{ display: friendStatuses?.length === 0 || (friendStatuses?.length === 1 && friendStatuses?.includes('PARENT')) ? 'none' : 'flex' }} className='userabout__education'>
                        <div className='userabout__Title'>
                            <h4>E-Schooling<br />Status</h4>
                        </div>
                        <div className='userabout__Info'>
                            {
                                friendStatuses?.includes('STUDENT') && (
                                    <div className='userabout__InfoStudent'>
                                        <div>
                                            <div className='studentVerif'>
                                                <h4>Student</h4>
                                                {
                                                    studentVerificationDeatils?.VERIFIED_STUDENT === true ? (
                                                        <h5 style={{ fontSize: '14px', fontWeight: '600', marginLeft: '10px', color: 'var(--connected-color)' }}>verified by school</h5>
                                                    ) : (
                                                        <h3 style={{ fontSize: '14px', fontWeight: '600', marginLeft: '10px', color: 'red' }}>not yet verified</h3>
                                                    )
                                                }
                                            </div>
                                            <p style={{ fontSize: '14px', fontWeight: '700', color: 'black', marginLeft: '20px' }}>({isStudentData?.MEDIUM} · {isStudentData?.STUDENTS_CLASS} · {isStudentData?.STUDENTS_SECTION})</p>

                                        </div>
                                        <div className='userabout__optionschool'>
                                            <Avatar
                                                src={studentSchoolDetails?.USER_DISPLAY_PIC}
                                                alt={studentSchoolDetails?.SCHOOL_FULL_NAME}
                                                sx={{ width: 40, height: 40, }}
                                            />

                                            <p className='userabout__data'>
                                                <Link to={`/schoolfriendprofile/${studentVerificationDeatils?.USER_SCHOOL_ID}`} style={{ textDecoration: 'none', color: 'black' }}>
                                                    {studentSchoolDetails?.SCHOOL_FULL_NAME}
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                friendStatuses?.includes('TEACHER') && (
                                    <div className='userabout__InfoTeacher'>
                                        <h4>Teacher</h4>
                                        <div className='userabout__InfoTeacherData'>
                                            {
                                                isTeacherData?.map((data, i) => (
                                                    <div key={i} className='dataAndVerif'>
                                                        <h5>- {data?.subData?.TEACH_MEDIUM} | {data?.subData?.TEACH_CLASS} | {data?.subData?.TEACH_CLASS_SEC} | {data?.subData?.TEACH_CLASS_SEC_SUB} | {data?.subData?.VERIFIED_TEACHER === true ? <span style={{ color: 'var(--connected-color)' }}>verified by school</span> : <span style={{ color: 'red' }}>not yet verified</span>}</h5>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className='userabout__optionschool'>
                                            <Avatar
                                                src={teacherSchoolDetails?.USER_DISPLAY_PIC}
                                                alt={teacherSchoolDetails?.SCHOOL_FULL_NAME}
                                                sx={{ width: 40, height: 40, }}
                                            />
                                            <p className='userabout__data'>
                                                <Link to={`/schoolfriendprofile/${teacherVerificationDeatils?.USER_SCHOOL_ID}`} style={{ textDecoration: 'none', color: 'black' }}>
                                                    {teacherSchoolDetails?.SCHOOL_FULL_NAME}
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                friendStatuses?.includes('PRINCIPAL') && (
                                    <div className='userabout__InfoPrincipal'>
                                        <div className='princiVerfi'>
                                            <h4>Principal</h4>
                                            {
                                                princiVerificationDeatils?.VERIFIED_PRINCIPAL === true ? (
                                                    <h5>verified by school</h5>
                                                ) : (
                                                    <h3>not yet verified</h3>
                                                )
                                            }
                                        </div>
                                        <div className='userabout__optionschool'>
                                            <Avatar
                                                src={princiSchoolDetails?.USER_DISPLAY_PIC}
                                                alt={princiSchoolDetails?.SCHOOL_FULL_NAME}
                                                sx={{ width: 40, height: 40, }}
                                            />
                                            <p className='userabout__data'>
                                                <Link to={`/schoolfriendprofile/${princiVerificationDeatils?.USER_SCHOOL_ID}`} style={{ textDecoration: 'none', color: 'black' }}>
                                                    {princiSchoolDetails?.SCHOOL_FULL_NAME}
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                )
            }

            {/* educational qualification */}
            {
                (userDegree1?.USER_QUAL_DEGREE || userDegree2?.USER_QUAL_DEGREE || userDegree3?.USER_QUAL_DEGREE) && (
                    <div className='userabout__education'>
                        <div className='userabout__Title'>
                            <h4>Educational<br />Qualifications</h4>
                        </div>
                        <div className='userabout__Info'>
                            {
                                userDegree1?.USER_QUAL_DEGREE && (
                                    <div className='userabout__InfoDegree'>
                                        <h3>{userDegree1?.USER_QUAL_DEGREE} ({userDegree1?.USER_QUAL_SPECIALIZATION})</h3>
                                        <div className='userabout__InfoDegreeData'>
                                            {
                                                userDegree1?.USER_QUAL_PASSOUT_YEAR && (
                                                    <div className='userabout__InfoDegreeDataInfo'>
                                                        <h5>Passout Year</h5>
                                                        <p>:</p>
                                                        <h4>{userDegree1?.USER_QUAL_PASSOUT_YEAR}</h4>
                                                    </div>
                                                )
                                            }
                                            {
                                                userDegree1?.USER_QUAL_UNIVERSITY && (
                                                    <div className='userabout__InfoDegreeDataInfo'>
                                                        <h5>University</h5>
                                                        <p>:</p>
                                                        <h4>{userDegree1?.USER_QUAL_UNIVERSITY}</h4>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                            {
                                userDegree2?.USER_QUAL_DEGREE && (
                                    <div className='userabout__InfoDegree'>
                                        <h3>{userDegree2?.USER_QUAL_DEGREE} ({userDegree2?.USER_QUAL_SPECIALIZATION})</h3>
                                        <div className='userabout__InfoDegreeData'>
                                            {
                                                userDegree2?.USER_QUAL_PASSOUT_YEAR && (
                                                    <div className='userabout__InfoDegreeDataInfo'>
                                                        <h5>Passout Year</h5>
                                                        <p>:</p>
                                                        <h4>{userDegree2?.USER_QUAL_PASSOUT_YEAR}</h4>
                                                    </div>
                                                )
                                            }
                                            {
                                                userDegree2?.USER_QUAL_UNIVERSITY && (
                                                    <div className='userabout__InfoDegreeDataInfo'>
                                                        <h5>University</h5>
                                                        <p>:</p>
                                                        <h4>{userDegree2?.USER_QUAL_UNIVERSITY}</h4>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                            {
                                userDegree3?.USER_QUAL_DEGREE && (
                                    <div className='userabout__InfoDegree'>
                                        <h3>{userDegree3?.USER_QUAL_DEGREE} ({userDegree3?.USER_QUAL_SPECIALIZATION})</h3>
                                        <div className='userabout__InfoDegreeData'>
                                            {
                                                userDegree3?.USER_QUAL_PASSOUT_YEAR && (
                                                    <div className='userabout__InfoDegreeDataInfo'>
                                                        <h5>Passout Year</h5>
                                                        <p>:</p>
                                                        <h4>{userDegree3?.USER_QUAL_PASSOUT_YEAR}</h4>
                                                    </div>
                                                )
                                            }
                                            {
                                                userDegree3?.USER_QUAL_UNIVERSITY && (
                                                    <div className='userabout__InfoDegreeDataInfo'>
                                                        <h5>University</h5>
                                                        <p>:</p>
                                                        <h4>{userDegree3?.USER_QUAL_UNIVERSITY}</h4>
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                )
            }


            {/* achievements */}
            {
                userAchievements?.length > 0 && (
                    <div className='userabout__education'>
                        <div className='userabout__Title'>
                            <h4>Educational<br />Achievements</h4>
                        </div>
                        <div className='userabout__Info'>
                            {
                                userAchievements?.map((award, i) => (
                                    <div key={i} className='userabout__InfoAward'>
                                        <h5>-</h5>
                                        <p>{award}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default UserAbout