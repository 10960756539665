import React, { useEffect, useState } from 'react'
import '../VerifiedMembers/SchoolVerifiedMembersTeachers.css'
import '../../Pages/SchoolVerifiedMembers.css'
import { Link } from 'react-router-dom';
import { deleteField, doc, onSnapshot, writeBatch } from 'firebase/firestore';
import { Avatar, Box, Button, Modal } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function SchoolVerifiedMembersTeachersSubject(props) {
    const { subTeacherId, subTeacherSubjectId,
        schoolId, schoolShortName, staffTeacherMedClsSec, subjectName, subTeacherPathCollection, teachersDP,
        subTeacherName } = props

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // const classes = useStyles();

    const [teacherRegName, setteacherRegName] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (subTeacherId && subTeacherPathCollection) {
                onSnapshot(doc(dB, "USERS", subTeacherId, subTeacherPathCollection, "REGISTRATION_INFO"), (snap) => {
                    if (snap.exists()) { setteacherRegName(snap.data()?.USER_NAME) }
                })
            }
        }
        return () => { aaa = false }
    }, [subTeacherId, subTeacherPathCollection])

    const [btnLoading, setbtnLoading] = useState(false)
    var batch = writeBatch(dB)
    const handleRemove = async (e) => {
        e.preventDefault()
        setbtnLoading(true)

        await batch.delete(doc(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", staffTeacherMedClsSec, "TEACHERS", "VERIFIED_MEMBERS", subjectName, "VERIFIED_MEMBERS_DETAILS"));

        await batch.update(doc(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", staffTeacherMedClsSec, "PROJECTS_&_HOMEWORKS", subjectName), {
            SUBJECT_TEACHER_ID: deleteField()
        })

        await batch.update(doc(dB, "USERS", subTeacherId, subTeacherPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "IS_TEACHER_DATA", "SUBJECTS_TEACH", subTeacherSubjectId), {
            VERIFIED_TEACHER: false
        });

        // Commit the await batch
        await batch.commit().then(() => {
            setOpen(false)
            setbtnLoading(false)
        }).catch(err => alert(err.message));
    }

    return (
        <div className='verMemData' >
            <div className='SchoolVerifiedMembersTeachers__containerRightTeachingStaffDataName dataNameParent' >
                <Link to={`/friendsprofile/${subTeacherId}`} style={{ textDecoration: 'none', color: 'gray' }}>
                    <Avatar
                        alt={teacherRegName}
                        src={teachersDP}
                        sx={{ width: 40, height: 40, }}
                    />
                </Link>
                <h3>
                    <Link to={`/friendsprofile/${subTeacherId}`} style={{ textDecoration: 'none', color: 'black' }}>
                        {teacherRegName}
                    </Link>
                </h3>
            </div>
            <div className='SchoolVerifiedMembersTeachers__containerRightTeachingStaffDataButtons'>
                <Button id='buttonConfirm' onClick={handleOpen}>Remove</Button>
                {/* <p onClick={handleOpen} style={{ marginLeft: '10px' }}>Class Teacher</p> */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className='removeVerifiedUser'>
                            <div className='removeVerifiedUserText'>
                                <h4>Do you really want to remove <br /><strong>{teacherRegName}</strong> from <strong>{subjectName} Teacher's</strong> post?</h4>
                            </div>
                            <div className='removeVerifiedUserButtons'>
                                <LoadingButton
                                    variant='outlined'
                                    loading={btnLoading}
                                    onClick={handleRemove}
                                >
                                    Yes
                                </LoadingButton>
                                {/* <Button disabled={btnLoading} >Yes</Button> */}
                                <Button onClick={handleClose}>No</Button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}

export default SchoolVerifiedMembersTeachersSubject
