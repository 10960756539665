import React, { useState } from 'react'
import '../NoticeBoards/SidebarNoticeBoardAccordian.css'
// import dB from '../firebase';
import { arrayUnion, deleteDoc, doc, updateDoc } from 'firebase/firestore';

import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Button, Modal } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dB from '../../firebase';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function SidebarNoticeBoardAccordian(props) {

    const { userCurrentStatus, userId, schoolId, schoolFullName, userPathCollection, userSchoolPath,
        noticeSubject, noticeBody, noticefrom, noticeDate, noticeTimeStamp, noticeId, noticeViewedBy
    } = props
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = (e) => { setOpenModal(true); };
    const handleCloseModal = (e) => { setOpenModal(false); };

    const [btnLoading, setbtnLoading] = useState(false)

    const handleClickDeleteNotice = async (e) => {
        e.preventDefault();
        setbtnLoading(true)
        deleteDoc(doc(dB, "SCHOOLS", userId, userPathCollection, "NOTICE_BOARD", "NOTICES_SENT", noticeId))
            .then(() => {
                setbtnLoading(false)
                setOpenModal(false);
            })
            .catch(err => alert(err.message))
    }

    const handleViewNotice = () => {
        if (userCurrentStatus !== 'SCHOOL') {
            if (!noticeViewedBy.includes(userId)) {
                updateDoc(doc(dB, "SCHOOLS", schoolId, userSchoolPath, "NOTICE_BOARD", "NOTICES_SENT", noticeId), {
                    VIEWED_BY: arrayUnion(userId),
                }).catch(err => alert(err.message))
            }
        }
    }

    return (
        <div className='SidebarNoticeBoardAccordian' >
            <div sx={{ width: '100%' }} id='acoordianNoticeBoard'>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={handleViewNotice}
                        sx={{ backgroundColor: "rgb(245, 244, 244)" }}
                    >
                        {
                            userCurrentStatus === 'SCHOOL' ? (
                                <div className='AccordionSummary'>
                                    <div className='AccordionSummary__SentOn'>
                                        <h3>Date : <strong style={{ marginLeft: '10px' }}>{noticeTimeStamp?.toDate().toDateString()}</strong></h3>
                                    </div>
                                    <div className='AccordionSummary__Subject'>
                                        <h3>Subject : <strong>{noticeSubject}</strong></h3>
                                    </div>
                                    <div className='AccordionSummary__Button'>
                                        <Button d onClick={handleOpenModal}>Delete</Button>
                                    </div>
                                    <Modal
                                        open={openModal}
                                        onClose={handleCloseModal}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <div className='deletePostModal'>
                                                <div className='deletePostModalText'>
                                                    <h4 style={{ textAlign: 'center' }}>Do you really want to delete the notice with subject "{noticeSubject}"?</h4>
                                                </div>
                                                <div className='deletePostModalButtons'>
                                                    <LoadingButton
                                                        onClick={handleClickDeleteNotice}
                                                        loading={btnLoading}
                                                        variant='outlined'
                                                    >
                                                        Yes
                                                    </LoadingButton>
                                                    <Button onClick={handleCloseModal}>No</Button>
                                                </div>
                                            </div>
                                        </Box>
                                    </Modal>
                                </div>
                            ) : (
                                <>
                                    {
                                        noticeViewedBy?.includes(userId) ? (
                                            <div className='AccordionSummary'>
                                                <div className='AccordionSummary__SentOn'>
                                                    <h3>Date : <strong style={{ marginLeft: '10px' }}>{noticeTimeStamp?.toDate().toDateString()}</strong></h3>
                                                </div>
                                                <div className='AccordionSummary__Subject'>
                                                    <h3>Subject : <strong>{noticeSubject}</strong></h3>
                                                </div>
                                            </div>
                                        ) : (
                                            <Badge anchorOrigin={{ vertical: 'top', horizontal: 'left', }} color="secondary" badgeContent=" " variant="dot">
                                                <div className='AccordionSummary'>
                                                    <div className='AccordionSummary__SentOn'>
                                                        <h3>Date : <strong style={{ marginLeft: '10px' }}>{noticeTimeStamp?.toDate().toDateString()}</strong></h3>
                                                    </div>
                                                    <div className='AccordionSummary__Subject'>
                                                        <h3>Subject : <strong>{noticeSubject}</strong></h3>
                                                    </div>
                                                </div>
                                            </Badge>
                                        )
                                    }
                                </>
                            )
                        }


                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='AccordionDetails'>

                            {/* schoolName */}
                            <h3>{schoolFullName}</h3>

                            {/* NOTICE HEADING */}
                            <h1>Notice</h1>

                            {/* Date */}
                            <h2>Date : {noticeTimeStamp?.toDate().toDateString()}</h2>

                            {/* Subject */}
                            <h4>Subject : {noticeSubject}.</h4>

                            {/* Notice Body */}
                            <p>{noticeBody}</p>

                            {/* From */}
                            <h5>{noticefrom}</h5>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}

export default SidebarNoticeBoardAccordian