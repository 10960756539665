import React, { useEffect, useState } from 'react'
import HomeWorksPrincipalClassNSubject from './HomeWorksPrincipalClassNSubject'
import '../ProjectsHomeworks/HomeWorksSchool.css'
import { doc, onSnapshot } from 'firebase/firestore'
import dB from '../../firebase'

function HomeWorksSchool(props) {
    const { userId, userPathCollection, userCurrentStatus } = props

    const [userSchoolName, setuserSchoolName] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId) {
                onSnapshot(doc(dB, "SCHOOLS", userId), (schlDoc) => {
                    if (schlDoc.exists()) {
                        setuserSchoolName(schlDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userId])

    return (
        <div className='HomeWorksSchool'>
            <div className='HomeWorksSchool__top'>
                <h3 style={{ textAlign: 'center' }}>{userSchoolName?.SCHOOL_FULL_NAME}</h3>
                <h4>Projects and Homeworks</h4>
                <hr />
            </div>

            <HomeWorksPrincipalClassNSubject
                userId={userId}
                userPathCollection={userPathCollection}
                userCurrentStatus={userCurrentStatus}
                userSchoolId={userId}
                userSchoolPathCollection={userPathCollection}
                isPrinciVerified={null}
            />

        </div>
    )
}

export default HomeWorksSchool
