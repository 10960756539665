import React, { useEffect, useState } from 'react'
import '../Sidebars/SidebarESchoolingStatusAddPrincipal.css'
import '../Sidebars/SidebarESchoolingStatusAddStudent.css'
import '../CreateNewAccount/CreateNewAccountInfoTeachers.css'
import '../CreateNewAccount/CreateNewAccountInfoStudents.css'
import { useHistory } from 'react-router-dom';
import { collection, doc, onSnapshot, serverTimestamp, writeBatch } from 'firebase/firestore';
import {
    Autocomplete, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel,
    MenuItem,
    Radio, RadioGroup, Select, TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec'
import { returnSection } from '../../Constants/FuncMedium'


function SidebarESchoolingStatusAddStudent(props) {

    const { userId, userPathCollection } = props

    const history = useHistory()

    const [studentData, setstudentData] = useState({
        isStudent: 'No', schoolName: '', schoolId: '', schoolPathCollection: '',
        studMedium: '', studClass: '', studSec: '', showInputs: false
    })
    const handleChangeStudentValue = (event) => {
        event.preventDefault()
        setstudentData(prev => { return { ...prev, isstudent: event.target.value } })
        setstudentsInputValue('')
        setinputStudentSchoolName(null)
        setstudentMedium('')
        setStudentsData(prev => { return { ...prev, studentsSchoolClass: '', studentsSchoolClassSection: '' } })
        setbtnLoading(false)
        setshowSent(false)
    }
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (studentData.isstudent === 'Yes') {
                setstudentData(prev => { return { ...prev, showInputs: true } })
            } else {
                setstudentData(prev => { return { ...prev, showInputs: false } })
            }
        }
        return () => { aaa = false }
    }, [studentData.isstudent])

    const [studentsInputValue, setstudentsInputValue] = useState('');
    const [inputStudentSchoolName, setinputStudentSchoolName] = useState(null)

    const [showEnteredStudents, setshowEnteredStudents] = useState({
        studentsSchoolName: { error: false, helperText: '' },
        studentsSchoolClass: { error: false, helperText: '' },
        studentsSchoolClassSection: { error: false, helperText: '' },
        studentSelectMedium: '',
    })

    const [studentMedium, setstudentMedium] = useState('')
    const handleMediumValueChange = (event) => {
        event.preventDefault()
        setstudentMedium(event.target.value);
    };
    const handleMediumChange = (e) => {
        e.preventDefault();
        setStudentsData(prevState => { return { ...prevState, studentsSchoolClass: '', studentsSchoolClassSection: '' } })
    }

    const [studentsData, setStudentsData] = useState({ studentsSchoolClass: '', studentsSchoolClassSection: '', })
    const [returnedSectionList, setreturnedSectionList] = useState(null);

    const handleChangeStudentsDataClass = (e) => {
        e.preventDefault();
        setshowEnteredStudents(prevState => { return { ...prevState, [e.target.name]: { error: false, helperText: '' } } })
        setStudentsData(prevState => { return { ...prevState, studentsSchoolClass: e.target.value } })
        setStudentsData(prevState => { return { ...prevState, studentsSchoolClassSection: '' } })
    }
    const handleChangeStudentsDataSection = (e) => {
        e.preventDefault()
        setStudentsData(prevState => { return { ...prevState, studentsSchoolClassSection: e.target.value } })
        setshowEnteredStudents(prevState => { return { ...prevState, studentsSchoolClassSection: { error: false, helperText: '' } } })
    }

    const [studentsOptions, setstudentsOptions] = useState([])
    useEffect(() => {
        if (studentsOptions.length > 0) {
            studentsOptions.splice(0, studentsOptions.length)
        }
        var aaa = true
        if (aaa === true) {
            onSnapshot(collection(dB, "SCHOOLS"), (snap) => {
                snap.forEach((doc) => {
                    const newdata = doc.data()?.SCHOOL_FULL_NAME;
                    setstudentsOptions(prev => { return [...prev, { id: doc.id, schoolName: newdata }] })
                });
            })
        }
        return () => { aaa = false }
    }, [])

    // fetch school id's for students to select
    const [studentsOptionSchoolId, setstudentsOptionSchoolId] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (inputStudentSchoolName) {
                studentsOptions.map(option => {
                    if (option.schoolName === inputStudentSchoolName) {
                        setstudentsOptionSchoolId(option.id)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [inputStudentSchoolName, studentsOptions])

    const [returnedStudentsClassList, setreturnedStudentsClassList] = useState([])
    const [studentSchoolPath, setstudentSchoolPath] = useState({ schoolSmallName: '', schoolId: '', })
    useEffect(() => {
        if (returnedStudentsClassList.length > 0) {
            returnedStudentsClassList.splice(0, returnedStudentsClassList.length)
        }
        var aaa = true
        if (aaa === true) {
            onSnapshot(collection(dB, "SCHOOLS"), (snap) => {
                snap.forEach((schlDOc) => {
                    const newdata = schlDOc.data()?.SCHOOL_FULL_NAME;
                    if (newdata === inputStudentSchoolName) {
                        const schoolCollection = schlDOc.data()?.SCHOOL_NAME;
                        setstudentSchoolPath(prevState => { return { ...prevState, schoolSmallName: schoolCollection, schoolId: schlDOc.id } })
                        onSnapshot(doc(dB, "SCHOOLS", schlDOc.id, schoolCollection, "CLASSES_DETAILS"), (classes) => {
                            if (classes.exists()) {
                                setreturnedStudentsClassList(classes.data()?.CLASS_LIST)
                            }
                        })
                    }
                });
            })
        }
        return () => { aaa = false }
    }, [inputStudentSchoolName])

    // fetch sections
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            setreturnedSectionList(null)
            var sectionObject = returnSection(studentMedium, studentsData.studentsSchoolClass, returnedStudentsClassList)
            setreturnedSectionList(sectionObject)
        }
        return () => { aaa = false }
    }, [studentMedium, studentsData.studentsSchoolClass, returnedStudentsClassList])

    // medClassSection
    const [studentsClassSection, setstudentsClassSection] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var abc = returnMedClsSec(studentMedium, studentsData.studentsSchoolClass, studentsData.studentsSchoolClassSection)
            setstudentsClassSection(abc)
        }
        return () => { aaa = false }
    }, [studentMedium, studentsData])

    const [showSent, setshowSent] = useState(false)
    const [btnLoading, setbtnLoading] = useState(false)
    var batch = writeBatch(dB)
    const sendStudentRequest = async (e) => {
        e.preventDefault()
        setbtnLoading(true)
        if (inputStudentSchoolName && studentsData.studentsSchoolClass && studentsData.studentsSchoolClassSection && studentMedium) {

            await batch.update(doc(dB, "USERS", userId), {
                USER_CURRENT_STATUS: 'STUDENT',
            });

            await batch.set(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_STUDENT", "IS_STUDENT_DATA"), {
                MEDIUM: studentMedium,
                STUDENTS_SCHOOL_NAME: inputStudentSchoolName,
                STUDENTS_CLASS: studentsData.studentsSchoolClass,
                STUDENTS_SECTION: studentsData.studentsSchoolClassSection,
            });

            await batch.set(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_STUDENT", "VERIFICATION_DETAILS"), {
                USER_STATUS: 'STUDENT',
                VERIFIED_STUDENT: false,
                USER_SCHOOL_ID: studentsOptionSchoolId
            });

            await batch.set(doc(dB, "SCHOOLS", studentSchoolPath.schoolId, studentSchoolPath.schoolSmallName, "CLASSES_DETAILS", "CLASSES", studentsClassSection, "STUDENTS", "VERIFICATION_REQUESTS", "VERIFICATION_REQUESTS_DATA", userId), {
                TIMESTAMP: serverTimestamp()
            });

            await batch.commit().then(() => {
                setstudentsInputValue('')
                setinputStudentSchoolName(null)
                setstudentMedium('')
                setStudentsData(prev => { return { ...prev, studentsSchoolClass: '', studentsSchoolClassSection: '' } })
                setbtnLoading(false)
                setshowSent(true)
                history.push('/user')
            }).catch(err => alert(err.message));
        } else {
            if (!inputStudentSchoolName) { setshowEnteredStudents(prevState => { return { ...prevState, studentsSchoolName: { error: true, helperText: 'Please enter your school name.' } } }) }
            if (!studentsData.studentsSchoolClass) { setshowEnteredStudents(prevState => { return { ...prevState, studentsSchoolClass: { error: true, helperText: 'Please select the class you are studying in.' } } }) }
            if (!studentsData.studentsSchoolClassSection) { setshowEnteredStudents(prevState => { return { ...prevState, studentsSchoolClassSection: { error: true, helperText: 'Please select your section here.' } } }) }
            if (!studentMedium) { setshowEnteredStudents(prevState => { return { ...prevState, studentSelectMedium: 'show' } }) }
        }
    }

    return (
        <div className='SidebarESchoolingStatusAddStudent'>
            <div className='student'>
                <FormControl component="fieldset">
                    <div className='studentYesNo'>
                        <FormLabel component="legend">Are you student at your school?</FormLabel>
                        <RadioGroup aria-label="student" name="student1" value={studentData.isstudent} onChange={handleChangeStudentValue}>
                            <div className='studentYesNoOptions'>
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                            </div>
                        </RadioGroup>
                    </div>
                </FormControl>
            </div>
            {
                studentData.showInputs && (
                    <div className='studentInputs'>
                        <div className='h5__schooName'>
                            <Autocomplete
                                value={inputStudentSchoolName}
                                onChange={(event, newValue) => {
                                    event.preventDefault()
                                    setinputStudentSchoolName(newValue);
                                }}
                                inputValue={studentsInputValue}
                                onInputChange={(event, newInputValue) => {
                                    setstudentsInputValue(newInputValue);
                                    if (returnedStudentsClassList.length > 0) {
                                        returnedStudentsClassList.splice(0, returnedStudentsClassList.length)
                                    }
                                    setshowEnteredStudents(prevState => { return { ...prevState, studentsSchoolName: { error: false, helperText: '' } } })
                                    setStudentsData(prevState => { return { ...prevState, studentsSchoolClass: '', studentsSchoolClassSection: '' } })
                                    setstudentMedium('')
                                }}
                                id="controllable-states-demo"
                                options={studentsOptions.map((option) => option.schoolName)}
                                // className={clsx(Muiclasses.longWidth)}
                                sx={{ width: '75ch', }}
                                renderInput={(params) =>
                                    <TextField
                                        id="studentsSchoolName"
                                        name='studentsSchoolName'
                                        {...params}
                                        margin="normal"
                                        label="Search your school here by school name, village ,city or district"
                                        variant="outlined"
                                        error={showEnteredStudents.studentsSchoolName.error}
                                    />
                                }
                            />
                            <div className='noAvailable'>
                                <h5><b>Note : </b>If School you are looking for does not appear in the list, then your school is yet to create an account. Ask your school to create account first. Till then you can use this platform to follow other schools and make new friends.</h5>
                            </div>
                        </div>
                        <div className='h5__medium'>
                            <FormControl component="fieldset" onChange={handleMediumChange}>
                                <div className='students__radioMediumMuiDiv'>
                                    <FormLabel component="legend">Medium</FormLabel>
                                    <RadioGroup aria-label="studentsMedium" name="studentsMedium" value={studentMedium} onChange={handleMediumValueChange}>
                                        <div className='students__radioMediumMui'>
                                            <FormControlLabel value="English Medium" control={<Radio />} label="English Medium" />
                                            <FormControlLabel value="Marathi Medium" control={<Radio />} label="Marathi Medium" />
                                        </div>
                                    </RadioGroup>
                                </div>
                            </FormControl>
                        </div>
                        <div className='ClassnSection'>
                            <div className='ClassNum'>
                                <div className='h5__Class'>
                                    <FormControl error={showEnteredStudents.studentsSchoolClass.error} name='studentsSchoolClass' variant="outlined">
                                        <InputLabel htmlFor="classes">Class :</InputLabel>
                                        <Select sx={{ width: '40ch', }} id="studentsSchoolClass" name='studentsSchoolClass' label="Class : " inputProps={{ name: 'studentsSchoolClass', id: 'studentsSchoolClass', }}
                                            value={studentsData.studentsSchoolClass}
                                            onChange={handleChangeStudentsDataClass}
                                        >
                                            <MenuItem style={{ display: studentMedium != null ? 'none' : 'block' }} aria-label="None" value="" />

                                            <MenuItem style={{ display: studentMedium === 'English Medium' && returnedStudentsClassList.includes('EngClass8SecA') ? 'block' : 'none' }} id='Eng Class 8' key='Eng Class 8' value='Class 8'>Class 8</MenuItem>
                                            <MenuItem style={{ display: studentMedium === 'English Medium' && returnedStudentsClassList.includes('EngClass9SecA') ? 'block' : 'none' }} id='Eng Class 9' key='Eng Class 9' value='Class 9'>Class 9</MenuItem>
                                            <MenuItem style={{ display: studentMedium === 'English Medium' && returnedStudentsClassList.includes('EngClass10SecA') ? 'block' : 'none' }} id='Eng Class 10' key='Eng Class 10' value='Class 10'>Class 10</MenuItem>
                                            <MenuItem style={{ display: studentMedium === 'English Medium' && returnedStudentsClassList.includes('EngClass11ScienceSecA') ? 'block' : 'none' }} id='Eng Class 11(Science)' key='Eng Class 11(Science)' value='Class 11(Science)'>Class 11(Science)</MenuItem>
                                            <MenuItem style={{ display: studentMedium === 'English Medium' && returnedStudentsClassList.includes('EngClass11CommerceSecA') ? 'block' : 'none' }} id='Eng Class 11(Commerce)' key='Eng Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                            <MenuItem style={{ display: studentMedium === 'English Medium' && returnedStudentsClassList.includes('EngClass12ScienceSecA') ? 'block' : 'none' }} id='Eng Class 12(Science)' key='Eng Class 12(Science)' value='Class 12(Science)'>Class 12(Science)</MenuItem>
                                            <MenuItem style={{ display: studentMedium === 'English Medium' && returnedStudentsClassList.includes('EngClass12CommerceSecA') ? 'block' : 'none' }} id='Eng Class 12(Commerce)' key='Eng Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>

                                            <MenuItem style={{ display: studentMedium === 'Marathi Medium' && returnedStudentsClassList.includes('MarClass8SecA') ? 'block' : 'none' }} id='Mar Class 8' key='Mar Class 8' value='Class 8'>Class 8</MenuItem>
                                            <MenuItem style={{ display: studentMedium === 'Marathi Medium' && returnedStudentsClassList.includes('MarClass9SecA') ? 'block' : 'none' }} id='Mar Class 9' key='Mar Class 9' value='Class 9'>Class 9</MenuItem>
                                            <MenuItem style={{ display: studentMedium === 'Marathi Medium' && returnedStudentsClassList.includes('MarClass10SecA') ? 'block' : 'none' }} id='Mar Class 10' key='Mar Class 10' value='Class 10'>Class 10</MenuItem>
                                            <MenuItem style={{ display: studentMedium === 'Marathi Medium' && returnedStudentsClassList.includes('MarClass11ArtsSecA') ? 'block' : 'none' }} id='Mar Class 11(Arts)' key='Mar Class 11(Arts)' value='Class 11(Arts)'>Class 11(Arts)</MenuItem>
                                            <MenuItem style={{ display: studentMedium === 'Marathi Medium' && returnedStudentsClassList.includes('MarClass11CommerceSecA') ? 'block' : 'none' }} id='Mar Class 11(Commerce)' key='Mar Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                            <MenuItem style={{ display: studentMedium === 'Marathi Medium' && returnedStudentsClassList.includes('MarClass12ArtsSecA') ? 'block' : 'none' }} id='Mar Class 12(Arts)' key='Mar Class 12(Arts)' value='Class 12(Arts)'>Class 12(Arts)</MenuItem>
                                            <MenuItem style={{ display: studentMedium === 'Marathi Medium' && returnedStudentsClassList.includes('MarClass12CommerceSecA') ? 'block' : 'none' }} id='Mar Class 12(Commerce)' key='Mar Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>
                                        </Select>
                                        <FormHelperText id="studentsSchoolClass"><p>{showEnteredStudents.studentsSchoolClass.helperText}</p></FormHelperText>
                                    </FormControl>
                                </div>
                            </div>
                            <div className='ClassSection'>
                                <div className='h5__section'>
                                    <FormControl error={showEnteredStudents.studentsSchoolClassSection.error} name='studentsSchoolClassSection' variant="outlined">
                                        <InputLabel htmlFor="studentsSchoolClassSection">Section :</InputLabel>
                                        <Select sx={{ width: '30ch' }} id="studentsSchoolClassSection" name='studentsSchoolClassSection' label="Section :" inputProps={{ name: 'studentsSchoolClassSection', id: 'studentsSchoolClassSection', }}
                                            value={studentsData.studentsSchoolClassSection}
                                            onChange={handleChangeStudentsDataSection}
                                        >
                                            <MenuItem style={{ display: studentsData.studentsSchoolClass === '' ? 'block' : 'none' }} aria-label="None" value="" />

                                            <MenuItem style={{ display: returnedSectionList?.SectionA === true ? 'block' : 'none' }} key='Section A' value='Section A'>Section A</MenuItem>
                                            <MenuItem style={{ display: returnedSectionList?.SectionB === true ? 'block' : 'none' }} key='Section B' value='Section B'>Section B</MenuItem>
                                            <MenuItem style={{ display: returnedSectionList?.SectionC === true ? 'block' : 'none' }} key='Section C' value='Section C'>Section C</MenuItem>
                                            <MenuItem style={{ display: returnedSectionList?.SectionD === true ? 'block' : 'none' }} key='Section D' value='Section D'>Section D</MenuItem>
                                            <MenuItem style={{ display: returnedSectionList?.SectionE === true ? 'block' : 'none' }} key='Section E' value='Section E'>Section E</MenuItem>
                                        </Select>
                                        <FormHelperText id="studentsSchoolClassSection"><p>{showEnteredStudents.studentsSchoolClassSection.helperText}</p></FormHelperText>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <LoadingButton
                            onClick={sendStudentRequest}
                            variant='outlined'
                            loading={btnLoading}
                        >
                            Send Verification Request to School
                        </LoadingButton>
                        {/* <Button disabled={btnLoading} id='buttonRequestSentTeacher' ></Button> */}
                        {showSent && (<h5 id='requestSentTeacher' >Request Sent to School..</h5>)}

                    </div>
                )
            }

        </div>
    )
}

export default SidebarESchoolingStatusAddStudent
