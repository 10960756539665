import React, { useEffect, useState } from 'react'
import '../Social/SchoolAbout.css'
import SchoolAboutTeachersList from './SchoolAboutTeachersList'
import { collection, doc, getDocs, limit, onSnapshot, query, startAfter } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import { Avatar, Button } from '@mui/material';
import dB from '../../firebase';

function SchoolAbout(props) {

    const { schoolVision, schoolMission, schoolRestAddres, schoolPhone, schoolEmail, schoolEstablished,
        schoolfacilities, schoolAbout, schoolAchievements, schoolId, schoolPathCollection
    } = props

    const [teacherIds, setteacherIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolPathCollection) {
                onSnapshot(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "ALL_TEACHERS", "ALL_TEACHERS_LIST"), (snap) => {
                    setteacherIds(snap.docs.map((teachDoc) => teachDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [schoolId, schoolPathCollection])

    const [teacherIdList, setteacherIdList] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolPathCollection) {
                onSnapshot(query(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "ALL_TEACHERS", "ALL_TEACHERS_LIST"), limit(4)), (snap) => {
                    fetchFrndIds(snap)
                })
            }
        }
        return () => { aaa = false }
    }, [schoolId, schoolPathCollection])
    const fetchFrndIds = (snap) => {
        let frndId = snap.docs.map((frndsDoc) => frndsDoc.id)
        setteacherIdList(prev => { return [...prev, ...frndId] })

        var lastVisible = snap.docs[snap.docs.length - 1];
        setlastVisibleTeachId(lastVisible)
    }
    const [lastVisibleTeachId, setlastVisibleTeachId] = useState()
    const [totalTeachIds, settotalTeachIds] = useState()
    const loadMoreTeachIds = async (teacherIdListLength) => {
        settotalTeachIds(teacherIdListLength)
        if (schoolId && schoolPathCollection) {
            const q = query(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "ALL_TEACHERS", "ALL_TEACHERS_LIST"), startAfter(lastVisibleTeachId), limit(10))
            const snap = await getDocs(q).catch(err => alert(err.message))
            fetchFrndIds(snap)
        }
    }


    const [princiId, setprinciId] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolPathCollection) {
                onSnapshot(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "PRINCIPAL_DETAILS", "VERIFIED_MEMBERS", "PRINCIPAL_DATA"), (snap) => {
                    if (snap.exists()) { setprinciId(snap.data()) }
                })
            }
        }
        return () => { aaa = false }
    }, [schoolId, schoolPathCollection])
    const [princiData, setprinciData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (princiId?.PRINCIPAL_ID) {
                onSnapshot(doc(dB, "USERS", princiId?.PRINCIPAL_ID), (snap) => {
                    if (snap.exists()) { setprinciData(snap.data()) }
                })
            }
        }
        return () => { aaa = false }
    }, [princiId?.PRINCIPAL_ID])

    return (
        <div className='schoolabout'>
            {
                (schoolVision || schoolMission) && (
                    <div className='schoolabout__visionmission'>
                        <div className='schoolabout__Title'>
                            <h4>Vision/Mission</h4>
                        </div>
                        <div className='schoolabout__Info'>
                            {
                                schoolVision && (
                                    <div className='schoolabout__InfoVision'>
                                        <h4>School Vision</h4>
                                        <p>{schoolVision}</p>
                                    </div>
                                )
                            }
                            {
                                schoolMission && (
                                    <div className='schoolabout__InfoMission'>
                                        <h4>School Mission</h4>
                                        <p>{schoolMission}</p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }

            {
                (schoolRestAddres || schoolPhone || schoolEmail) && (
                    <div className='schoolabout__contact'>
                        <div className='schoolabout__Title'>
                            <h4>Contact</h4>
                        </div>
                        <div className='schoolabout__Info'>
                            {
                                schoolRestAddres && (
                                    <div className='schoolabout__InfoAddress'>
                                        <LocationOnOutlinedIcon />
                                        <p>{schoolRestAddres}</p>
                                    </div>
                                )
                            }
                            {
                                schoolPhone && (
                                    <div className='schoolabout__InfoPhone'>
                                        <CallOutlinedIcon />
                                        <p>{schoolPhone}</p>
                                    </div>
                                )
                            }
                            {
                                schoolEmail && (
                                    <div className='schoolabout__InfoEmail'>
                                        <AlternateEmailOutlinedIcon />
                                        <p>{schoolEmail}</p>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                )
            }

            {
                schoolfacilities?.length > 0 && (
                    <div className='schoolabout__facilities'>
                        <div className='schoolabout__Title'>
                            <h4>Facilities</h4>
                        </div>
                        <div className='schoolabout__Info schoolabout__facilitieslist'>
                            {
                                schoolfacilities?.map((facility, i) => (
                                    <p key={i}> -  {facility}</p>
                                ))
                            }
                        </div>
                    </div>
                )
            }

            {
                (schoolEstablished || schoolAbout || schoolAchievements?.length > 0) && (
                    <div className='schoolabout__otherinfo'>
                        <div className='schoolabout__Title'>
                            <h4>Other Info</h4>
                        </div>
                        <div className='schoolabout__Info'>
                            {
                                schoolEstablished && (
                                    <div className='schoolabout__InfoEstablished'>
                                        <CakeOutlinedIcon />
                                        <p>Established in <strong>{schoolEstablished}</strong></p>
                                    </div>
                                )
                            }
                            {
                                schoolAbout && (
                                    <div className='schoolabout__otherinfoBio'>
                                        <h4>About School</h4>
                                        <p>{schoolAbout}</p>
                                    </div>
                                )
                            }
                            {
                                schoolAchievements?.length > 0 && (
                                    <div className='schoolabout__otherinfoBio'>
                                        <h4>Awards & Achievements of school</h4>
                                        <div className='schoolabout__otherinfoBioAchievementsList'>
                                            {
                                                schoolAchievements?.map((achievement, i) => (
                                                    <div key={i} style={{ display: 'flex', flexDIrection: 'row', marginBottom: '5px' }}>
                                                        <FiberManualRecordIcon sx={{ fontSize: '7px', marginTop: '8px', marginRight: '10px', }} />
                                                        <p>{achievement}</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                )
            }



            <div className='schoolabout__staff'>
                <div className='schoolabout__Title'>
                    <h4>School Staff</h4>
                </div>
                <div className='schoolabout__Info'>
                    <div className='schoolabout__staffTeaching'>
                        <div className='schoolabout__staffTeachingPrincipal'>
                            <h5>Principal / HeadMaster / HeadMistress : </h5>
                            {
                                princiData && (
                                    <div className='staffNameLinkPrinci'>
                                        <Link to={`/friendsprofile/${princiId?.PRINCIPAL_ID}`} style={{ textDecoration: 'none', color: 'gray' }}>
                                            <Avatar
                                                alt={princiData?.USER_NAME}
                                                src={princiData?.USER_DISPLAY_PIC}
                                                // className={classes.small} 
                                                sx={{ width: 40, height: 40 }}
                                            />
                                        </Link>
                                        <h3>
                                            <Link to={`/friendsprofile/${princiId?.PRINCIPAL_ID}`} style={{ textDecoration: 'none', color: 'black' }}>
                                                {princiData?.USER_NAME}
                                            </Link>
                                        </h3>
                                    </div>
                                )
                            }

                        </div>
                        <div className='schoolabout__staffTeachingTeachers'>
                            <h5>Teachers({teacherIds?.length}) :</h5>
                            <div className='schoolabout__staffTeachingTeachersList'>
                                {
                                    teacherIdList?.map((teacherId, i) => (
                                        <SchoolAboutTeachersList
                                            key={i}
                                            teacherId={teacherId}
                                        />
                                    ))
                                }
                                {
                                    teacherIdList?.length > 3 && (
                                        <div style={{ marginTop: 30 }}>
                                            {
                                                totalTeachIds !== teacherIdList?.length ? (
                                                    <div className='showMoreBtnTeachNew' >
                                                        <Button color="primary" onClick={() => loadMoreTeachIds(teacherIdList?.length)} >Show More Teachers..</Button>
                                                    </div>
                                                ) : (
                                                    <div className='noMoreTeach'>
                                                        <h4 >No more teachers to show</h4>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SchoolAbout