import React, { useEffect, useState } from 'react'
import '../../Pages/SchoolUpcomingEvents.css'
import '../../Pages/SidebarNoticeBoard.css'
import { collection, doc, onSnapshot, } from 'firebase/firestore';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, } from '@mui/material';

import dB from '../../firebase';
import CallReceivedIcon from '@mui/icons-material/CallReceived';

import { functionClsStr } from '../../Constants/FunctionClsStr';
import PrinciLeave from './PrinciLeave';
import ParentLeave from './ParentLeave';


function LeaveApplicationPrincipal(props) {

    const { userId, userPathCollection, userCurrentStatus, userSchoolId } = props

    const [princiSchoolData, setprinciSchoolData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userSchoolId) {
                onSnapshot(doc(dB, "SCHOOLS", userSchoolId), (snap) => {
                    if (snap.exists()) {
                        setprinciSchoolData(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userSchoolId])

    const [princiVerified, setprinciVerified] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), (princiDoc) => {
                    if (princiDoc.exists()) {
                        setprinciVerified(princiDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    // fetch class list from firebase
    const [gotClsList, setgotClsList] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userSchoolId && princiSchoolData?.SCHOOL_NAME) {
                onSnapshot(collection(dB, "SCHOOLS", userSchoolId, princiSchoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES"), (snap) => {
                    setgotClsList(snap.docs.map((listDoc) => listDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [userSchoolId, princiSchoolData?.SCHOOL_NAME])

    // sort english, marathi in order
    const [totalClsList, settotalClsList] = useState([])
    useEffect(() => {
        if (totalClsList.length > 0) {
            totalClsList.splice(0, totalClsList.length)
        }
        var aaa = true
        if (aaa === true) {
            if (gotClsList.length > 0) {
                var bothCls = []
                var engCls = []
                var marCls = []
                gotClsList.forEach(cls => {
                    if (cls.includes('Eng')) {
                        engCls.push(cls)
                    } else {
                        marCls.push(cls)
                    }
                })
                bothCls = engCls.concat(marCls)
                settotalClsList(bothCls)
            }
        }
        return () => { aaa = false }

    }, [gotClsList])

    const [classInfoData, setclassInfoData] = useState([])
    useEffect(() => {
        if (classInfoData.length > 0) {
            classInfoData.splice(0, classInfoData.length)
        }
        var aaa = true
        if (aaa === true) {
            if (totalClsList.length > 0) {
                totalClsList.forEach(clsStr => {
                    var xyz = functionClsStr(clsStr)
                    setclassInfoData(prev => { return [...prev, xyz] })
                })
            }
        }
        return () => { aaa = false }
    }, [totalClsList])

    const [activeTab1, setactiveTab1] = useState({
        teachRecAppl: '', teachAccAppl: '', teachRejAppl: '',
        parRecAppl: '', parAccAppl: '', parRejAppl: '',
        stuRecAppl: '', stuAccAppl: '', stuRejAppl: ''
    })
    const handleClickTeachRecAppl = (e) => {
        e.preventDefault()
        setactiveTab1(prev => {
            return {
                ...prev,
                teachRecAppl: 'active', teachAccAppl: '', teachRejAppl: '',
                parRecAppl: '', parAccAppl: '', parRejAppl: '',
                stuRecAppl: '', stuAccAppl: '', stuRejAppl: ''
            }
        })
    }
    const handleClickTeachAccAppl = (e) => {
        e.preventDefault()
        setactiveTab1(prev => {
            return {
                ...prev,
                teachRecAppl: '', teachAccAppl: 'active', teachRejAppl: '',
                parRecAppl: '', parAccAppl: '', parRejAppl: '',
                stuRecAppl: '', stuAccAppl: '', stuRejAppl: ''
            }
        })
    }
    const handleClickTeachRejAppl = (e) => {
        e.preventDefault()
        setactiveTab1(prev => {
            return {
                ...prev,
                teachRecAppl: '', teachAccAppl: '', teachRejAppl: 'active',
                parRecAppl: '', parAccAppl: '', parRejAppl: '',
                stuRecAppl: '', stuAccAppl: '', stuRejAppl: ''
            }
        })
    }
    const handleClickParRecAppl = (e) => {
        e.preventDefault()
        setactiveTab1(prev => {
            return {
                ...prev,
                teachRecAppl: '', teachAccAppl: '', teachRejAppl: '',
                parRecAppl: 'active', parAccAppl: '', parRejAppl: '',
                stuRecAppl: '', stuAccAppl: '', stuRejAppl: ''
            }
        })
    }
    const handleClickParAccAppl = (e) => {
        e.preventDefault()
        setactiveTab1(prev => {
            return {
                ...prev,
                teachRecAppl: '', teachAccAppl: '', teachRejAppl: '',
                parRecAppl: '', parAccAppl: 'active', parRejAppl: '',
                stuRecAppl: '', stuAccAppl: '', stuRejAppl: ''
            }
        })
    }
    const handleClickParRejAppl = (e) => {
        e.preventDefault()
        setactiveTab1(prev => {
            return {
                ...prev,
                teachRecAppl: '', teachAccAppl: '', teachRejAppl: '',
                parRecAppl: '', parAccAppl: '', parRejAppl: 'active',
                stuRecAppl: '', stuAccAppl: '', stuRejAppl: ''
            }
        })
    }

    const [allTeachRec, setallTeachRec] = useState([])
    useEffect(() => {
        if (princiSchoolData && userSchoolId) {
            onSnapshot(collection(dB, "SCHOOLS", userSchoolId, princiSchoolData?.SCHOOL_NAME, "ALL_TEACHERS", "LEAVE_APPLICATIONS", "ALL_LEAVE_APPLICATIONS", "RECIEVED"), (snap) => {
                setallTeachRec(snap.docs.map((teachDoc) => ({
                    teachRecAppId: teachDoc.id,
                    teachRecAppData: teachDoc.data()
                })))
            })
        }
    }, [princiSchoolData, userSchoolId])

    const [allTeachAcc, setallTeachAcc] = useState([])
    useEffect(() => {
        if (princiSchoolData && userSchoolId) {
            onSnapshot(collection(dB, "SCHOOLS", userSchoolId, princiSchoolData?.SCHOOL_NAME, "ALL_TEACHERS", "LEAVE_APPLICATIONS", "ALL_LEAVE_APPLICATIONS", "ACCEPTED"), (snap) => {
                setallTeachAcc(snap.docs.map((teachDoc) => ({
                    teachAccAppId: teachDoc.id,
                    teachAccAppData: teachDoc.data()
                })))
            })
        }
    }, [princiSchoolData, userSchoolId])

    const [allTeachRej, setallTeachRej] = useState([])
    useEffect(() => {
        if (princiSchoolData && userSchoolId) {
            onSnapshot(collection(dB, "SCHOOLS", userSchoolId, princiSchoolData?.SCHOOL_NAME, "ALL_TEACHERS", "LEAVE_APPLICATIONS", "ALL_LEAVE_APPLICATIONS", "REJECTED"), (snap) => {
                setallTeachRej(snap.docs.map((teachDoc) => ({
                    teachRejAppId: teachDoc.id,
                    teachRejAppData: teachDoc.data()
                })))
            })
        }
    }, [princiSchoolData, userSchoolId])




    return (
        <div className='SchoolUpcomingEvents'>
            {/* <Header /> */}
            <div className='SidebarNoticeBoard__Container'>
                <div className='SidebarNoticeBoard__ContainerTop'>
                    <h3>{princiSchoolData?.SCHOOL_FULL_NAME}</h3>
                    <h2>Leave Applications</h2>
                    <hr />
                </div>
                <div className='SidebarNoticeBoard__ContainerBottom'>

                    <div className='SidebarNoticeBoard__ContainerBottomLeft'>
                        <nav className='SidebarNoticeBoard__containerLeft'>
                            <div key='CreateNewNotice' className={`SidebarNoticeBoard__containerLeft__title ${activeTab1.teachRecAppl}`} onClick={handleClickTeachRecAppl}>
                                <CallReceivedIcon />
                                <h2>Teachers Recieved</h2>
                            </div>
                            <div key='CreateNewNotice' className={`SidebarNoticeBoard__containerLeft__title ${activeTab1.teachAccAppl}`} onClick={handleClickTeachAccAppl}>
                                <ThumbUpOffAltIcon />
                                <h2>Teachers Accepted</h2>
                            </div>
                            <div key='CreateNewNotice' className={`SidebarNoticeBoard__containerLeft__title ${activeTab1.teachRejAppl}`} onClick={handleClickTeachRejAppl}>
                                <ThumbDownOffAltIcon />
                                <h2>Teachers Rejected</h2>
                            </div>
                            <div key='CreateNewNotice' className={`SidebarNoticeBoard__containerLeft__title ${activeTab1.parRecAppl}`} onClick={handleClickParRecAppl}>
                                <CallReceivedIcon />
                                <h2>Parents Recieved</h2>
                            </div>
                            <div key='CreateNewNotice' className={`SidebarNoticeBoard__containerLeft__title ${activeTab1.parAccAppl}`} onClick={handleClickParAccAppl}>
                                <ThumbUpOffAltIcon />
                                <h2>Parents Accepted</h2>
                            </div>
                            <div key='CreateNewNotice' className={`SidebarNoticeBoard__containerLeft__title ${activeTab1.parRejAppl}`} onClick={handleClickParRejAppl}>
                                <ThumbDownOffAltIcon />
                                <h2>Parents Rejected</h2>
                            </div>
                        </nav>
                    </div>

                    <div className='SidebarNoticeBoard__ContainerBottomRight'>
                        {
                            activeTab1.teachRecAppl === 'active' && (
                                <div className=' teacherLeave'>
                                    <h3>New Leave Applications from Teachers</h3>

                                    {
                                        allTeachRec.map((rec, i) => (
                                            <PrinciLeave
                                                key={i}
                                                leaveAppId={rec.teachRecAppId}
                                                leaveAppData={rec.teachRecAppData}

                                                userId={userId}
                                                userPathCollection={userPathCollection}
                                                userCurrentStatus={userCurrentStatus}
                                                userSchoolId={userSchoolId}
                                                princiSchoolData={princiSchoolData}

                                                appplicationStatus={"recieved"}
                                                showButtons={true}
                                            />
                                        ))
                                    }

                                </div>
                            )
                        }
                        {
                            activeTab1.teachAccAppl === 'active' && (
                                <div className=' teacherLeave'>
                                    <h3>Accepted Leave Applications from Teachers</h3>

                                    {
                                        allTeachAcc.map((rec, i) => (
                                            <PrinciLeave
                                                key={i}
                                                leaveAppId={rec.teachAccAppId}
                                                leaveAppData={rec.teachAccAppData}

                                                userId={userId}
                                                userPathCollection={userPathCollection}
                                                userCurrentStatus={userCurrentStatus}
                                                userSchoolId={userSchoolId}
                                                princiSchoolData={princiSchoolData}

                                                appplicationStatus={"accepted"}
                                                showButtons={false}
                                            />
                                        ))
                                    }
                                </div>
                            )
                        }
                        {
                            activeTab1.teachRejAppl === 'active' && (
                                <div className=' teacherLeave'>
                                    <h3>Rejected Leave Applications from Teachers</h3>

                                    {
                                        allTeachRej.map((rec, i) => (
                                            <PrinciLeave
                                                key={i}
                                                leaveAppId={rec.teachRejAppId}
                                                leaveAppData={rec.teachRejAppData}

                                                userId={userId}
                                                userPathCollection={userPathCollection}
                                                userCurrentStatus={userCurrentStatus}
                                                userSchoolId={userSchoolId}
                                                princiSchoolData={princiSchoolData}

                                                appplicationStatus={"rejected"}
                                                showButtons={false}
                                            />
                                        ))
                                    }
                                </div>
                            )
                        }

                        {
                            activeTab1.parRecAppl === 'active' && (
                                <div className='teacherLeave'>
                                    <h3>New Leave Applications from Parents</h3>
                                    <div className='HomeWorksPrincipalClassNSubject'>
                                        {
                                            classInfoData.map(oneClassData => (
                                                <div key={oneClassData.classPath} className='princiSubRowLeave'>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                        >
                                                            <div key={oneClassData.classPath} className='princiButtonText'>
                                                                <h4>{oneClassData.medium} Medium | {oneClassData.classNum} | {oneClassData.section}</h4>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div key={oneClassData.classPath} className='princiSubRowPanelData'>
                                                                <h3>New Leave Applications of <b style={{ color: 'var(--connected-color)' }}>({oneClassData.medium} Medium · {oneClassData.classNum} · {oneClassData.section})</b></h3>
                                                                <ParentLeave
                                                                    userId={userId}
                                                                    userPathCollection={userPathCollection}
                                                                    userCurrentStatus={userCurrentStatus}
                                                                    userSchoolId={userSchoolId}
                                                                    princiSchoolData={princiSchoolData}
                                                                    princiVerified={princiVerified}
                                                                    classPath={oneClassData.classPath}
                                                                    medium={oneClassData.medium}
                                                                    classNum={oneClassData.classNum}
                                                                    section={oneClassData.section}

                                                                    applStatus={'recieved'}
                                                                />

                                                            </div>
                                                        </AccordionDetails>

                                                    </Accordion>

                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {
                            activeTab1.parAccAppl === 'active' && (
                                <div className='teacherLeave'>
                                    <h3>Accepted Leave Applications of Parents</h3>
                                    <div className='HomeWorksPrincipalClassNSubject'>
                                        {
                                            classInfoData.map(oneClassData => (
                                                <div key={oneClassData.classPath} className='princiSubRowLeave'>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                        >
                                                            <div key={oneClassData.classPath} className='princiButtonText'>
                                                                <h4>{oneClassData.medium} Medium | {oneClassData.classNum} | {oneClassData.section}</h4>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div key={oneClassData.classPath} className='princiSubRowPanelData'>
                                                                <h3>Accepted Leave Applications of <b style={{ color: 'var(--connected-color)' }}>({oneClassData.medium} Medium · {oneClassData.classNum} · {oneClassData.section})</b></h3>
                                                                <ParentLeave
                                                                    userId={userId}
                                                                    userPathCollection={userPathCollection}
                                                                    userCurrentStatus={userCurrentStatus}
                                                                    userSchoolId={userSchoolId}
                                                                    princiSchoolData={princiSchoolData}
                                                                    princiVerified={princiVerified}
                                                                    classPath={oneClassData.classPath}
                                                                    medium={oneClassData.medium}
                                                                    classNum={oneClassData.classNum}
                                                                    section={oneClassData.section}

                                                                    applStatus={'accepted'}
                                                                />
                                                            </div>
                                                        </AccordionDetails>

                                                    </Accordion>

                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {
                            activeTab1.parRejAppl === 'active' && (
                                <div className='teacherLeave'>
                                    <h3>Rejected Leave Applications of Parents</h3>
                                    <div className='HomeWorksPrincipalClassNSubject'>
                                        {
                                            classInfoData.map(oneClassData => (
                                                <div key={oneClassData.classPath} className='princiSubRowLeave'>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                        >
                                                            <div key={oneClassData.classPath} className='princiButtonText'>
                                                                <h4>{oneClassData.medium} Medium | {oneClassData.classNum} | {oneClassData.section}</h4>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div key={oneClassData.classPath} className='princiSubRowPanelData'>
                                                                <h3>Rejected Leave Applications of <b style={{ color: 'var(--connected-color)' }}>({oneClassData.medium} Medium · {oneClassData.classNum} · {oneClassData.section})</b></h3>
                                                                <ParentLeave
                                                                    userId={userId}
                                                                    userPathCollection={userPathCollection}
                                                                    userCurrentStatus={userCurrentStatus}
                                                                    userSchoolId={userSchoolId}
                                                                    princiSchoolData={princiSchoolData}
                                                                    princiVerified={princiVerified}
                                                                    classPath={oneClassData.classPath}
                                                                    medium={oneClassData.medium}
                                                                    classNum={oneClassData.classNum}
                                                                    section={oneClassData.section}

                                                                    applStatus={'rejected'}
                                                                />
                                                            </div>
                                                        </AccordionDetails>

                                                    </Accordion>

                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        }

                    </div>
                </div>
                <hr />
            </div>
        </div>
    )
}

export default LeaveApplicationPrincipal