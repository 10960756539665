import React from 'react'
import '../ProjectsHomeworks/HomeWorksParents.css'
import '../ComplainsAndFeedback/Complains.css'
import { useState } from 'react'
import { useEffect } from 'react'
import { addDoc, collection, doc, onSnapshot, serverTimestamp, setDoc } from 'firebase/firestore'
import dB from '../../firebase'
import { Accordion, AccordionDetails, AccordionSummary, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ComplaintsParentsAll from './ComplaintsParentsAll'

function ComplaintsParents(props) {

    const { userId, userPathCollection, userCurrentStatus, childSchoolId, childId, ischildVerified } = props

    const [childsSchoolName, setchildsSchoolName] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (childSchoolId) {
                onSnapshot(doc(dB, "SCHOOLS", childSchoolId), (childDOc) => {
                    if (childDOc.exists()) {
                        setchildsSchoolName(childDOc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [childSchoolId])

    const [childsData, setchildsData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection && childId) {
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", childId), (childDOc) => {
                    if (childDOc.exists()) {
                        setchildsData(childDOc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [childId, userId, userPathCollection])

    const [btnLoading, setbtnLoading] = useState(false)

    const [suggestion, setsuggestion] = useState('')
    const [complaint, setcomplaint] = useState('')
    const handleSendSuggestion = (e) => {
        e.preventDefault();

        if (suggestion) {
            setbtnLoading(true)

            addDoc(collection(dB, "SCHOOLS", childSchoolId, childsSchoolName?.SCHOOL_NAME, "COMPLAINTS_AND_SUGGESTIONS", "SUGGESTIONS"), {
                TIMESTAMP: serverTimestamp(),
                SUGGESTION_TEXT: suggestion,
                SUGGESTION_FROM: userId,
                USER_PATH_COLLECTION: userPathCollection,
                USER_STATUS: userCurrentStatus,
                CHILD_ID: childId
            }).then((docRef) => {
                setDoc(doc(dB, "USERS", userId, userPathCollection, "COMPLAINTS_AND_SUGGESTIONS", "SUGGESTIONS", docRef.id), {
                    TIMESTAMP: serverTimestamp(),
                    SUGGESTION_TEXT: suggestion,
                    CHILD_ID: childId,
                    SCHOOL_ID: childSchoolId,
                    SCHOOL_PATH_COLLECTION: childsSchoolName?.SCHOOL_NAME
                }).then(() => {
                    setbtnLoading(false)
                    setsuggestion('')
                }).catch((err) => {
                    setbtnLoading(false)
                    setsuggestion('')
                    alert(err)
                })

            }).catch((err) => {
                setbtnLoading(false)
                setsuggestion('')
                alert(err)
            })
        }
    }
    const handleSendComplaint = (e) => {
        e.preventDefault();

        if (complaint) {
            setbtnLoading(true)

            addDoc(collection(dB, "SCHOOLS", childSchoolId, childsSchoolName?.SCHOOL_NAME, "COMPLAINTS_AND_SUGGESTIONS", "COMPLAINTS"), {
                TIMESTAMP: serverTimestamp(),
                COMPLAINT_TEXT: complaint,
                COMPLAINT_FROM: userId,
                USER_PATH_COLLECTION: userPathCollection,
                USER_STATUS: userCurrentStatus,
                CHILD_ID: childId
            }).then((docRef) => {
                setDoc(doc(dB, "USERS", userId, userPathCollection, "COMPLAINTS_AND_SUGGESTIONS", "COMPLAINTS", docRef.id), {
                    TIMESTAMP: serverTimestamp(),
                    COMPLAINT_TEXT: complaint,
                    CHILD_ID: childId,
                    SCHOOL_ID: childSchoolId,
                    SCHOOL_PATH_COLLECTION: childsSchoolName?.SCHOOL_NAME
                }).then(() => {
                    setbtnLoading(false)
                    setcomplaint('')
                }).catch((err) => {
                    setbtnLoading(false)
                    setcomplaint('')
                    alert(err)
                })
            }).catch((err) => {
                setbtnLoading(false)
                setcomplaint('')
                alert(err)
            })
        }
    }

    const [allSuggestions, setallSuggestions] = useState([])
    useEffect(() => {
        if (userId && userPathCollection) {
            onSnapshot(collection(dB, "USERS", userId, userPathCollection, "COMPLAINTS_AND_SUGGESTIONS", "SUGGESTIONS"), (snap) => {
                setallSuggestions(snap.docs.map((sugDoc) => ({
                    suggestionId: sugDoc.id,
                    suggestionData: sugDoc.data()
                })))
            })
        }
    }, [userId, userPathCollection])

    const [allComplaints, setallComplaints] = useState([])
    useEffect(() => {
        if (userId && userPathCollection) {
            onSnapshot(collection(dB, "USERS", userId, userPathCollection, "COMPLAINTS_AND_SUGGESTIONS", "COMPLAINTS"), (snap) => {
                setallComplaints(snap.docs.map((compDoc) => ({
                    complaintId: compDoc.id,
                    complaintData: compDoc.data()
                })))
            })
        }
    }, [userId, userPathCollection])

    return (
        <div className='homeworkteachers'>
            <div className='homeworkteachers__top'>
                <h3>{childsSchoolName?.SCHOOL_FULL_NAME}</h3>
                <h4>Complaints & Feedback</h4>
                <hr />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className='childName'>
                    <h3>Students Name</h3>
                    <h4>:</h4>
                    <div className='childNameName'>
                        <h5>{childsData?.CHILDS_FULL_NAME}</h5>
                        <p>({childsData?.CHILDS_MEDIUM} · {childsData?.CHILDS_CLASS} · {childsData?.CHILDS_SECTION})</p>
                    </div>
                </div>
                <div className='complaintAndFeedback'>
                    <Accordion >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p className='Complaintitle'>Complaint (if any)</p>

                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='complainBox'>
                                {/* complaint message */}
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Write your complaint here."
                                    multiline
                                    rows={4}
                                    value={complaint}
                                    onChange={(e) => { e.preventDefault(); setcomplaint(e.target.value) }}
                                // placeholder="Write your complaint here."
                                />
                                <LoadingButton
                                    loading={btnLoading}
                                    onClick={handleSendComplaint}
                                    disabled={btnLoading}
                                >
                                    Submit Complaint
                                </LoadingButton>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className='complaintAndFeedback'>
                    <Accordion >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p className='Complaintitle'>Suggestions (if any)</p>

                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='complainBox'>
                                {/* complaint message */}
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Write your suggestions here."
                                    multiline
                                    rows={4}
                                    value={suggestion}
                                    onChange={(e) => { e.preventDefault(); setsuggestion(e.target.value) }}
                                // placeholder="Write your suggestions here."
                                />
                                <LoadingButton
                                    loading={btnLoading}
                                    onClick={handleSendSuggestion}
                                    disabled={btnLoading}
                                >
                                    Submit Suggestion
                                </LoadingButton>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className='complaintAndFeedback'>
                    <ComplaintsParentsAll
                        allSuggestions={allSuggestions}
                        allComplaints={allComplaints}
                    />
                </div>
            </div>


        </div>
    )
}

export default ComplaintsParents