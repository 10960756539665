import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import React from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function SentApplication(props) {

    const { applicationId, applicationData } = props

    return (
        <div className='oneSentApplication'>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                // onClick={handleViewHW}
                >
                    <div className='oneAppData'>
                        <div className='leaveDataHead'>
                            <h3>Leave on :</h3>
                            <p>{applicationData?.ONE_DAY ? typeof applicationData?.ON_DATE === 'object' ? applicationData?.ON_DATE.toDate().toDateString() : applicationData?.ON_DATE : `${typeof applicationData?.FROM_TO_DATE?.START_DATE === 'object' ? applicationData?.FROM_TO_DATE?.START_DATE?.toDate().toDateString() : applicationData?.FROM_TO_DATE?.START_DATE} to ${typeof applicationData?.FROM_TO_DATE?.END_DATE === 'object' ? applicationData?.FROM_TO_DATE?.END_DATE?.toDate().toDateString() : applicationData?.FROM_TO_DATE?.END_DATE}`}</p>
                        </div>
                        <div className='leaveDataHead'>
                            <h3>Subject :</h3>
                            <p>{applicationData?.APPLICATION_SUBJECT}</p>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='leaveAppDataDiv'>
                        <div className='leaveAppData'>
                            <h3>Leave on :</h3>
                            <p>{applicationData?.ONE_DAY ? applicationData?.ON_DATE : `${applicationData?.FROM_TO_DATE?.START_DATE.toDate().toDateString()} to ${applicationData?.FROM_TO_DATE?.END_DATE.toDate().toDateString()}`}</p>
                        </div>
                        <div className='leaveAppData'>
                            <h3>Application Subject :</h3>
                            <p>{applicationData?.APPLICATION_SUBJECT}</p>
                        </div>
                        <div className='leaveAppData'>
                            <h3>Application Message :</h3>
                            <p>{applicationData?.APPLICATION_BODY}</p>
                        </div>
                        <div className='leaveAppData'>
                            <h3>Application Status :</h3>
                            <p style={{ color: applicationData?.APPLICATION_STATUS === 'ACCEPTED' ? 'green' : applicationData?.APPLICATION_STATUS === 'REJECTED' ? 'red' : 'var(--connected-orange)' }}>{applicationData?.APPLICATION_STATUS}</p>
                        </div>
                        <div className='leaveAppData'>
                            <h3>Application Sent Date :</h3>
                            <p>{applicationData?.TIMESTAMP?.toDate().toDateString()}</p>
                        </div>
                        <div className='leaveAppData'>
                            <h3>leave for :</h3>
                            <p> {applicationData?.ONE_DAY ? "One Day" : "More than One Day"}</p>
                        </div>

                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default SentApplication