import React, { useEffect, useState } from 'react'
import '../Sidebars/SidebarESchoolingStatusShowTeacher.css'
import { useHistory } from 'react-router-dom';
import { collection, doc, onSnapshot, serverTimestamp, writeBatch } from 'firebase/firestore';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion, AccordionDetails, AccordionSummary, Box, Button, FormControl,
    FormHelperText, InputLabel, MenuItem, Modal, Select
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec';
import { returnSection } from '../../Constants/FuncMedium';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


function SidebarESchoolingStatusShowTeacher(props) {
    const { userSchoolFullName, userSchoolId, userId, userPathCollection, userStatuses,
        userSchoolPathCollection, userCurrentStatus } = props

    const history = useHistory()

    const [showEnteredTeachers, setshowEnteredTeachers] = useState({
        teacherClassMedium: { error: false, helperText: '' },
        teacherClass: { error: false, helperText: '' },
        teacherClassSec: { error: false, helperText: '' },
        teacherClassSecSub: { error: false, helperText: '' },
    })
    const [toBeAddedTeacherData, settoBeAddedTeacherData] = useState({
        teacherClassMedium: '', teacherClass: '', teacherClassSec: '', teacherClassSecSub: '',
    })

    // fetching returnedTeachersClassList
    const [returnedTeachersClassList, setreturnedTeachersClassList] = useState([])
    useEffect(() => {
        if (returnedTeachersClassList.length > 0) {
            returnedTeachersClassList.splice(0, returnedTeachersClassList.length)
        }
        var aaa = true
        if (aaa === true) {
            if (userSchoolPathCollection) {
                onSnapshot(doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS"), (classes) => {
                    if (classes.exists()) {
                        setreturnedTeachersClassList(classes.data()?.CLASS_LIST)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userSchoolId, userSchoolPathCollection])

    // Fetching Sections
    const [returnedTeachersSectionList, setreturnedTeachersSectionList] = useState(null);
    useEffect(() => {
        setreturnedTeachersSectionList(null)
        var aaa = true
        if (aaa === true) {
            var sectionObject = returnSection(toBeAddedTeacherData.teacherClassMedium, toBeAddedTeacherData.teacherClass, returnedTeachersClassList)
            setreturnedTeachersSectionList(sectionObject)
        }
        return () => { aaa = false }
    }, [toBeAddedTeacherData.teacherClassMedium, toBeAddedTeacherData.teacherClass, returnedTeachersClassList])
    // set medium class section path EngKG1SecA
    const [teacherMedClsSect, setteacherMedClsSect] = useState('')
    useEffect(() => {
        setteacherMedClsSect('')
        var aaa = true
        if (aaa === true) {
            var abc = returnMedClsSec(toBeAddedTeacherData.teacherClassMedium, toBeAddedTeacherData.teacherClass, toBeAddedTeacherData.teacherClassSec)
            setteacherMedClsSect(abc)

        }
        return () => { aaa = false }
    }, [toBeAddedTeacherData.teacherClassMedium, toBeAddedTeacherData.teacherClass, toBeAddedTeacherData.teacherClassSec])

    const [subjectsList, setsubjectsList] = useState([])
    const [subjectsData, setsubjectsData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "IS_TEACHER_DATA", "SUBJECTS_TEACH"), (snap) => {
                    setsubjectsData(snap.docs.map((teachDOc) => ({
                        subjectId: teachDOc.id,
                        subjectData: teachDOc.data(),
                    })))
                    setsubjectsList(snap.docs.map((teachDOc) => teachDOc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const [returnedSubjects, setreturnedSubjects] = useState([])
    useEffect(() => {
        if (returnedSubjects.length > 0) {
            returnedSubjects.splice(0, returnedSubjects.length)
        }
        var aaa = true
        if (aaa === true) {
            if (teacherMedClsSect) {
                onSnapshot(doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", teacherMedClsSect), (newClassDoc) => {
                    if (newClassDoc.exists()) {
                        setreturnedSubjects(newClassDoc.data()?.SUBJECTS_LIST)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [teacherMedClsSect, userSchoolId, userSchoolPathCollection])

    const [isSent, setisSent] = useState(false)
    var batch = writeBatch(dB)

    // generate 14 digit random number
    const [randomKey, setrandomKey] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var a = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
            setrandomKey(a)
        }
        return () => { aaa = false }
    }, [userId])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (subjectsList?.length > 0 && randomKey) {
                if (subjectsList?.includes(randomKey)) {
                    setrandomKey(randomKey + 1111111111)
                }
            }
        }
        return () => { aaa = false }
    }, [randomKey, subjectsList])

    const [btnLoading, setbtnLoading] = useState(false)

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = (e) => { setOpenModal(true); };
    const handleCloseModal = (e) => { setOpenModal(false); };

    const [dataToRemove, setdataToRemove] = useState({
        subjectId: '', isVerified: '', teachMedium: '', teachClass: '', teachSection: '', teachSubject: ''
    })
    const handleCollectData = (subjectId, isVerified, teachMedium, teachClass, teachSection, teachSubject) => {
        setdataToRemove(prev => {
            return {
                ...prev,
                subjectId: subjectId,
                isVerified: isVerified,
                teachMedium: teachMedium,
                teachClass: teachClass,
                teachSection: teachSection,
                teachSubject: teachSubject
            }
        })
        handleOpenModal()
    }

    const handleRemoveSubject = async (subjectId, isVerified, teachMedium, teachClass, teachSection, teachSubject) => {

        setbtnLoading(true)

        var setMedClsSection = await returnMedClsSec(teachMedium, teachClass, teachSection)

        // setbtnLoading(true)

        // var userdB = dB.collection('USERS').doc(userId)

        await batch.delete(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "IS_TEACHER_DATA", "SUBJECTS_TEACH", subjectId))


        if (subjectsData.length === 1) {

            await batch.delete(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "VERIFICATION_DETAILS"))

            if (userStatuses.length === 1) {
                await batch.update(doc(dB, "USERS", userId), {
                    USER_CURRENT_STATUS: '',
                });
            } else {
                await batch.update(doc(dB, "USERS", userId), {
                    USER_CURRENT_STATUS: userStatuses[0] === 'TEACHER' ? userStatuses[1] : userStatuses[0],
                });
            }
        }

        // delete from schools class subjects collection
        if (isVerified) {
            await batch.delete(doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", setMedClsSection, "TEACHERS", "VERIFIED_MEMBERS", teachSubject, "VERIFIED_MEMBERS_DETAILS"));
        }
        else {
            await batch.delete(doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", setMedClsSection, "TEACHERS", "VERIFICATION_REQUESTS", teachSubject, userId));
        }

        await batch.commit().then(() => {
            setbtnLoading(false)
            setOpenModal(false)
            if (subjectsData.length === 1) {
                history.push('/user')
            }
        }).catch(err => alert(err.message))
    }

    const handleSendVerificationRequest = async (e) => {
        e.preventDefault()
        if (toBeAddedTeacherData.teacherClassMedium && toBeAddedTeacherData.teacherClass && toBeAddedTeacherData.teacherClassSec && toBeAddedTeacherData.teacherClassSecSub) {
            setbtnLoading(true)
            await batch.set(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "IS_TEACHER_DATA", "SUBJECTS_TEACH", randomKey), {
                TEACH_MEDIUM: toBeAddedTeacherData.teacherClassMedium,
                TEACH_CLASS: toBeAddedTeacherData.teacherClass,
                TEACH_CLASS_SEC: toBeAddedTeacherData.teacherClassSec,
                TEACH_CLASS_SEC_SUB: toBeAddedTeacherData.teacherClassSecSub,
                VERIFIED_TEACHER: false,
            });
            await batch.set(doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", teacherMedClsSect, "TEACHERS", "VERIFICATION_REQUESTS", toBeAddedTeacherData.teacherClassSecSub, userId), {
                TEACHERS_SUBJECT_ID: randomKey,
                TIMESTAMP: serverTimestamp()
            });

            // Commit the batch
            await batch.commit().then(() => {
                settoBeAddedTeacherData(prev => { return { ...prev, teacherClassMedium: '', teacherClass: '', teacherClassSec: '', teacherClassSecSub: '', } })
                setisSent(true)
                setbtnLoading(false)
            }).catch(err => alert(err.message))
        }
        else {
            if (!toBeAddedTeacherData.teacherClassMedium) { setshowEnteredTeachers(prev => { return { ...prev, teacherClassMedium: { error: true, helperText: 'Please Select your Medium' }, } }) }
            if (!toBeAddedTeacherData.teacherClass) { setshowEnteredTeachers(prev => { return { ...prev, teacherClass: { error: true, helperText: 'Please Select your Class' }, } }) }
            if (!toBeAddedTeacherData.teacherClassSec) { setshowEnteredTeachers(prev => { return { ...prev, teacherClassSec: { error: true, helperText: 'Please Select your Section' }, } }) }
            if (!toBeAddedTeacherData.teacherClassSecSub) { setshowEnteredTeachers(prev => { return { ...prev, teacherClassSecSub: { error: true, helperText: 'Please Select your Subject' }, } }) }
        }
    }

    return (
        <div className='SidebarESchoolingStatusShowTeacher' key='SidebarESchoolingStatusShowTeacherComp'>
            <div className='rowData teacherStatus' key='addNewSubjectStatus'>
                <h2 key='addNewSubjectStatush2'>User E-Schooling Status</h2>
                <h4 key='addNewSubjectStatush4'>:</h4>
                <h3 key='addNewSubjectStatush3'>"TEACHER"</h3>
            </div>
            <div className='rowData teacherSchool' key='addNewSubjectSchoolName'>
                <h2 key='addNewSubjectSchoolNameh2'>School</h2>
                <h4 key='addNewSubjectSchoolNameh4'>:</h4>
                <h3 key='addNewSubjectSchoolNameh3'>{userSchoolFullName}</h3>
            </div>
            <div className='tableSubjects' key='tableSubjects'>
                <h3 key='tableSubjectsTittle'>Existing Subjects</h3>

                <table >
                    <thead >
                        <tr key='tableheading'>
                            <th key='tableMedium'><h3 key='tableMedium'>Medium</h3></th>
                            <th key='tableClass'><h3 key='tableClass'>Class</h3></th>
                            <th key='tableSection'><h3 key='tableSection'>Section</h3></th>
                            <th key='tableSubject'><h3 key='tableSubject'>Subject</h3></th>
                            <th key='tableStatus'><h3 key='tableStatus'>Verification</h3></th>
                            <th key='tableButton'></th>
                        </tr>
                    </thead>
                    <tbody key='tableBody' >
                        {
                            subjectsData?.map(data => (
                                <tr key={data?.subjectId}>
                                    <td key={`${data?.subjectId}Medium`}><h1 key={`${data?.subjectId}Medium`}>{data?.subjectData?.TEACH_MEDIUM === 'English Medium' ? 'English' : 'Marathi'}</h1></td>
                                    <td key={`${data?.subjectId}Class`}><h2 key={`${data?.subjectId}Class`}>{data?.subjectData?.TEACH_CLASS}</h2></td>
                                    <td key={`${data?.subjectId}Section`}><h3 key={`${data?.subjectId}Section`}>{data?.subjectData?.TEACH_CLASS_SEC}</h3></td>
                                    <td key={`${data?.subjectId}Subject`}><h4 key={`${data?.subjectId}Subject`}>{data?.subjectData?.TEACH_CLASS_SEC_SUB}</h4></td>
                                    <td key={`${data?.subjectId}isVerified`}>{data?.subjectData?.VERIFIED_TEACHER ? (<h4 key={`${data?.subjectId}isVerified`} style={{ color: 'var(--connected-color)', fontSize: '15px' }}>Verified By School</h4>) : (<h5 key={`${data?.subjectId}isVerified`}>request sent to school</h5>)}</td>
                                    <td key={`${data?.subjectId}Button`}>{<div className='subjectButton' key={`${data?.subjectId}Button`}><Button onClick={(e) => { e.preventDefault(); handleCollectData(data?.subjectId, data?.subjectData?.VERIFIED_TEACHER, data?.subjectData?.TEACH_MEDIUM, data?.subjectData?.TEACH_CLASS, data?.subjectData?.TEACH_CLASS_SEC, data?.subjectData?.TEACH_CLASS_SEC_SUB) }}>Remove</Button></div>}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                    <Modal
                        open={openModal}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div className='deletePostModal'>
                                <div className='deletePostModalText'>
                                    <h4 style={{ textAlign: 'center' }}>Do you really want to remove the subject "{dataToRemove.teachSubject}" of {dataToRemove.teachMedium} {dataToRemove.teachClass} {dataToRemove.teachSection}, from your teaching subjects list?</h4>
                                </div>
                                <div className='deletePostModalButtons'>
                                    <LoadingButton
                                        onClick={(e) => { e.preventDefault(); handleRemoveSubject(dataToRemove.subjectId, dataToRemove.isVerified, dataToRemove.teachMedium, dataToRemove.teachClass, dataToRemove.teachSection, dataToRemove.teachSubject) }}
                                        variant='outlined'
                                        loading={btnLoading}
                                    >
                                        Yes
                                    </LoadingButton>
                                    {/* <Button disabled={btnLoading} >Yes</Button> */}
                                    <Button onClick={handleCloseModal}>No</Button>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                </table>

                {
                    subjectsData?.length === 8 ? (
                        <div className='limitReached'>
                            <h3>You have reached the limit. You can not add more subjects.</h3>
                        </div>
                    ) : (
                        <div className='addNewSubject' key='addNewSubject'>
                            <h3 key='addNewSubjecth3'>Add new Subject</h3>
                            <Box sx={{ width: '800px', marginTop: '20px' }} id='addNewSubjectAccordianTop' key='addNewSubjectAccordian'>
                                <Accordion key='addNewSubjectAccordianTop'>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        key='addNewSubjectAccordianSummary'
                                    >
                                        <div className='addNewChildDataHereTop' key='addNewSubjectName'>
                                            <h3 key='addNewSubjectAccordianAddNewSubject'>Add New Subject here</h3>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails key='addNewSubjectAccordianDetails'>
                                        <div className='addNewChildDataHereData' key='addNewSubjectData'>
                                            <FormControl key='addNewSubjectMedium' error={showEnteredTeachers.teacherClassMedium.error} name='teacherClassMedium' variant="outlined">
                                                <InputLabel htmlFor="teacherClassMedium" key='addNewSubjectAccordianMediumInputLabel'>Medium :</InputLabel>
                                                <Select key='addNewSubjectAccordianMediumSelect' sx={{ width: '300px', marginBottom: '10px' }} id="teacherClassMedium" name='teacherClassMedium' label="Medium : " inputProps={{ name: 'teacherClassMedium', id: 'teacherClassMedium', }}
                                                    value={toBeAddedTeacherData.teacherClassMedium}
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        settoBeAddedTeacherData(prevState => { return { ...prevState, teacherClassMedium: e.target.value, teacherClass: '', teacherClassSec: '', teacherClassSecSub: '' } })
                                                        setshowEnteredTeachers(prevState => { return { ...prevState, teacherClassMedium: { error: false, helperText: '' } } })
                                                        setisSent(false)
                                                    }}
                                                >
                                                    <MenuItem key='NoneMedium' value='' ></MenuItem>

                                                    <MenuItem key='EnglishMedium' value='English Medium'>English Medium</MenuItem>
                                                    <MenuItem key='MarathiMedium' value='Marathi Medium'>Marathi Medium</MenuItem>
                                                </Select>
                                                <FormHelperText key='addNewSubjectMediumFormHelperText' id="teacherClassMedium">{showEnteredTeachers.teacherClassMedium.helperText}</FormHelperText>
                                            </FormControl>

                                            <FormControl key='addNewSubjectClass' error={showEnteredTeachers.teacherClass.error} name='teacherClass' variant="outlined">
                                                <InputLabel key='addNewSubjectClassInputLabel' htmlFor="teacherClass">Class :</InputLabel>
                                                <Select key='addNewSubjectClassSelect' sx={{ width: '300px', marginBottom: '10px' }} id="teacherClass" name='teacherClass' label="Class : " inputProps={{ name: 'teacherClass', id: 'teacherClass', }}
                                                    value={toBeAddedTeacherData.teacherClass}
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        settoBeAddedTeacherData(prevState => { return { ...prevState, teacherClass: e.target.value, teacherClassSec: '', teacherClassSecSub: '' } })
                                                        setshowEnteredTeachers(prevState => { return { ...prevState, teacherClass: { error: false, helperText: '' } } })
                                                    }}
                                                >
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === '' ? 'block' : 'none' }} aria-label="None" value="" key='NoneClass' />

                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'English Medium' && returnedTeachersClassList.includes('EngPreNurserySecA') ? 'block' : 'none' }} id='Eng Pre Nursery' key='EngPre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'English Medium' && returnedTeachersClassList.includes('EngNurserySecA') ? 'block' : 'none' }} id='Eng Nursery' key='EngNursery' value='Nursery'>Nursery</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'English Medium' && returnedTeachersClassList.includes('EngKG1SecA') ? 'block' : 'none' }} id='Eng Kindergarten 1(KG-1)' key='EngKindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'English Medium' && returnedTeachersClassList.includes('EngKG2SecA') ? 'block' : 'none' }} id='Eng Kindergarten 2(KG-2)' key='EngKindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass1SecA') ? 'block' : 'none' }} id='Eng Class 1' key='EngClass1' value='Class 1'>Class 1</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass2SecA') ? 'block' : 'none' }} id='Eng Class 2' key='EngClass2' value='Class 2'>Class 2</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass3SecA') ? 'block' : 'none' }} id='Eng Class 3' key='EngClass3' value='Class 3'>Class 3</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass4SecA') ? 'block' : 'none' }} id='Eng Class 4' key='EngClass4' value='Class 4'>Class 4</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass5SecA') ? 'block' : 'none' }} id='Eng Class 5' key='EngClass5' value='Class 5'>Class 5</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass6SecA') ? 'block' : 'none' }} id='Eng Class 6' key='EngClass6' value='Class 6'>Class 6</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass7SecA') ? 'block' : 'none' }} id='Eng Class 7' key='EngClass7' value='Class 7'>Class 7</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass8SecA') ? 'block' : 'none' }} id='Eng Class 8' key='EngClass8' value='Class 8'>Class 8</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass9SecA') ? 'block' : 'none' }} id='Eng Class 9' key='EngClass9' value='Class 9'>Class 9</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass10SecA') ? 'block' : 'none' }} id='Eng Class 10' key='EngClass10' value='Class 10'>Class 10</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass11ScienceSecA') ? 'block' : 'none' }} id='Eng Class 11(Science)' key='EngClass11(Science)' value='Class 11(Science)'>Class 11(Science)</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass11CommerceSecA') ? 'block' : 'none' }} id='Eng Class 11(Commerce)' key='EngClass11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass12ScienceSecA') ? 'block' : 'none' }} id='Eng Class 12(Science)' key='EngClass12(Science)' value='Class 12(Science)'>Class 12(Science)</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass12CommerceSecA') ? 'block' : 'none' }} id='Eng Class 12(Commerce)' key='EngClass12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>

                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarPreNurserySecA') ? 'block' : 'none' }} id='Mar Pre Nursery' key='MarPre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarNurserySecA') ? 'block' : 'none' }} id='Mar Nursery' key='MarNursery' value='Nursery'>Nursery</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarKG1SecA') ? 'block' : 'none' }} id='Mar Kindergarten 1(KG-1)' key='MarKindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarKG2SecA') ? 'block' : 'none' }} id='Mar Kindergarten 2(KG-2)' key='MarKindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>

                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass1SecA') ? 'block' : 'none' }} id='Mar Class 1' key='MarClass1' value='Class 1'>Class 1</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass2SecA') ? 'block' : 'none' }} id='Mar Class 2' key='MarClass2' value='Class 2'>Class 2</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass3SecA') ? 'block' : 'none' }} id='Mar Class 3' key='MarClass3' value='Class 3'>Class 3</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass4SecA') ? 'block' : 'none' }} id='Mar Class 4' key='MarClass4' value='Class 4'>Class 4</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass5SecA') ? 'block' : 'none' }} id='Mar Class 5' key='MarClass5' value='Class 5'>Class 5</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass6SecA') ? 'block' : 'none' }} id='Mar Class 6' key='MarClass6' value='Class 6'>Class 6</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass7SecA') ? 'block' : 'none' }} id='Mar Class 7' key='MarClass7' value='Class 7'>Class 7</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass8SecA') ? 'block' : 'none' }} id='Mar Class 8' key='MarClass8' value='Class 8'>Class 8</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass9SecA') ? 'block' : 'none' }} id='Mar Class 9' key='MarClass9' value='Class 9'>Class 9</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass10SecA') ? 'block' : 'none' }} id='Mar Class 10' key='MarClass10' value='Class 10'>Class 10</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass11ArtsSecA') ? 'block' : 'none' }} id='Mar Class 11(Arts)' key='MarClass11(Arts)' value='Class 11(Arts)'>Class 11(Arts)</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass11CommerceSecA') ? 'block' : 'none' }} id='Mar Class 11(Commerce)' key='MarClass11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass12ArtsSecA') ? 'block' : 'none' }} id='Mar Class 12(Arts)' key='MarClass12(Arts)' value='Class 12(Arts)'>Class 12(Arts)</MenuItem>
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClassMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass12CommerceSecA') ? 'block' : 'none' }} id='Mar Class 12(Commerce)' key='MarClass12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>
                                                </Select>
                                                <FormHelperText key='addNewSubjectClassHelperText' id="teacherClass">{showEnteredTeachers.teacherClass.helperText}</FormHelperText>
                                            </FormControl>

                                            <FormControl key='addNewSubjectSection' error={showEnteredTeachers.teacherClassSec.error} name='teacherClassSec' variant="outlined" >
                                                <InputLabel key='addNewSubjectHelperText' htmlFor="teacherClassSec">Section :</InputLabel>
                                                <Select key='addNewSubjectSelect' sx={{ width: '300px', marginBottom: '10px' }} id="teacherClassSec" name='teacherClassSec' label="Section : " inputProps={{ name: 'teacherClassSec', id: 'teacherClassSec', }}
                                                    value={toBeAddedTeacherData.teacherClassSec}
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        settoBeAddedTeacherData(prevState => { return { ...prevState, teacherClassSec: e.target.value, teacherClassSecSub: '' } })
                                                        setshowEnteredTeachers(prevState => { return { ...prevState, teacherClassSec: { error: false, helperText: '' } } })
                                                    }}
                                                >
                                                    <MenuItem style={{ display: toBeAddedTeacherData.teacherClass === '' ? 'block' : 'none' }} key='NoneSection' aria-label="None" value="" />

                                                    <MenuItem style={{ display: returnedTeachersSectionList?.classSection.SectionA === true ? 'block' : 'none' }} key='SectionA' value='Section A'>Section A</MenuItem>
                                                    <MenuItem style={{ display: returnedTeachersSectionList?.classSection.SectionB === true ? 'block' : 'none' }} key='SectionB' value='Section B'>Section B</MenuItem>
                                                    <MenuItem style={{ display: returnedTeachersSectionList?.classSection.SectionC === true ? 'block' : 'none' }} key='SectionC' value='Section C'>Section C</MenuItem>
                                                    <MenuItem style={{ display: returnedTeachersSectionList?.classSection.SectionD === true ? 'block' : 'none' }} key='SectionD' value='Section D'>Section D</MenuItem>
                                                    <MenuItem style={{ display: returnedTeachersSectionList?.classSection.SectionE === true ? 'block' : 'none' }} key='SectionE' value='Section E'>Section E</MenuItem>
                                                </Select>
                                                <FormHelperText key='addNewSubjectSectionFormHelperText' id="teacherClassSec">{showEnteredTeachers.teacherClassSec.helperText}</FormHelperText>
                                            </FormControl>

                                            <FormControl key='addNewSubjectSubject' error={showEnteredTeachers.teacherClassSecSub.error} name='teacherClassSecSub' variant="outlined">
                                                <InputLabel key='addNewSubjectSubjectInputLabel' htmlFor="teacherClassSecSub">Subject :</InputLabel>
                                                <Select key='addNewSubjectSubjectSelect' sx={{ width: '300px', marginBottom: '10px' }} id="teacherClassSecSub" name='teacherClassSecSub' label="Subject : " inputProps={{ name: 'teacherClassSecSub', id: 'teacherClassSecSub', }}
                                                    value={toBeAddedTeacherData.teacherClassSecSub}
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        settoBeAddedTeacherData(prevState => { return { ...prevState, teacherClassSecSub: e.target.value } })
                                                        setshowEnteredTeachers(prevState => { return { ...prevState, teacherClassSecSub: { error: false, helperText: '' } } })
                                                    }}
                                                >
                                                    <MenuItem style={{ display: returnedSubjects.length > 0 ? 'none' : 'block' }} key='NoneSubject' aria-label="None" value="" />
                                                    {
                                                        returnedSubjects.map((sub) => {
                                                            return (
                                                                <MenuItem key={sub} value={sub}>{sub}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText key='addNewSubjectSubjectFormHelperText' id="teacherClassSecSub">{showEnteredTeachers.teacherClassSecSub.helperText}</FormHelperText>
                                            </FormControl>

                                            <LoadingButton
                                                onClick={handleSendVerificationRequest}
                                                variant='outlined'
                                                loading={btnLoading}
                                            >
                                                Send Verification Request to School
                                            </LoadingButton>
                                            {/* <Button disabled={btnLoading} id='buttonRequestSentTeacher' >Send Verification Request to School</Button> */}
                                            {
                                                isSent && (
                                                    <div className='addNewChildDataHereRequestSent' key='addNewSubjectButton'>
                                                        <h3 key='addNewSubjectButtonh3'>Request Sent to School...</h3>
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </div>

                    )
                }
            </div>

        </div>
    )
}

export default SidebarESchoolingStatusShowTeacher
