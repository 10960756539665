import React, { useEffect, useState } from 'react'
import '../ProjectsHomeworks/HomeWorksPrincipalClassNSubject.css'
import '../ProjectsHomeworks/HomeWorksStudentsSubject.css'
import dB from '../../firebase';
import { doc, onSnapshot } from 'firebase/firestore';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import SyllabusCoveredSubjectwise from './SyllabusCoveredSubjectwise';

function SyllabusCoveredPrincipalSubjects(props) {
    const { userId, userPathCollection, userCurrentStatus, userSchoolId,
        userSchoolPathCollection, isPrinciVerified, classPath, medium, classNum, section
    } = props

    const [subjectsList, setsubjectsList] = useState([])
    useEffect(() => {
        if (subjectsList.length > 0) {
            subjectsList.splice(0, subjectsList.length)
        }
        var aaa = true
        if (aaa === true) {
            if (userSchoolId && userSchoolPathCollection && classPath) {
                onSnapshot(doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", classPath), (snap) => {
                    if (snap.exists()) {
                        setsubjectsList(snap.data()?.SUBJECTS_LIST)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [classPath, userSchoolId, userSchoolPathCollection])
    return (
        <div className='subjectClass'>
            {
                subjectsList.map(subName => (
                    <Accordion key={`${classPath}${subName}`}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <div key={`${classPath}${subName}`} className='subAccordionSummary'>
                                <h3>{subName}</h3>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SyllabusCoveredSubjectwise
                                userId={userId}
                                userPathCollection={userPathCollection}
                                userCurrentStatus={userCurrentStatus}
                                userSchoolId={userSchoolId}
                                userSchoolPathCollection={userSchoolPathCollection}
                                isPrinciVerified={isPrinciVerified}
                                classPath={classPath}
                                medium={`${medium} Medium`}
                                classNum={classNum}
                                section={section}
                                subName={subName}
                            />
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </div>
    )
}

export default SyllabusCoveredPrincipalSubjects