import React, { useEffect, useState } from 'react'
import '../Sidebars/SidebarESchoolingStatusShowPrincipal.css'
import { doc, onSnapshot, writeBatch } from 'firebase/firestore';
import { useHistory } from 'react-router-dom';
import { Box, Button, Modal } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function SidebarESchoolingStatusShowPrincipal(props) {
    const { userSchoolFullName, userSchoolId, userId, userPathCollection, userStatuses,
        userSchoolPathCollection, userCurrentStatus } = props

    const history = useHistory()

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = (e) => { setOpenModal(true); };
    const handleCloseModal = (e) => { setOpenModal(false); };

    var batch = writeBatch(dB)
    const [btnLoading, setbtnLoading] = useState(false)

    const [princiVerifi, setprinciVerifi] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), (snap) => {
                    if (snap.exists()) {
                        setprinciVerifi(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const handleClickRemovePrincipal = async (e) => {
        e.preventDefault()
        await setbtnLoading(true)

        await batch.delete(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"))

        if (userStatuses.length === 1) {
            await batch.update(doc(dB, "USERS", userId), {
                USER_CURRENT_STATUS: '',
            });
        } else {
            await batch.update(doc(dB, "USERS", userId), {
                USER_CURRENT_STATUS: userStatuses[0] === 'PRINCIPAL' ? userStatuses[1] : userStatuses[0],
            });
        }

        // delete from schools class subjects collection
        if (princiVerifi?.VERIFIED_PRINCIPAL) {
            await batch.delete(doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "PRINCIPAL_DETAILS", "VERIFIED_MEMBERS", "PRINCIPAL_DATA"));
        }
        else {
            await batch.delete(doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "PRINCIPAL_DETAILS", "VERIFICATION_REQUESTS", userId));
        }

        await batch.commit().then(() => {
            setbtnLoading(false)
            history.push('/user')
        }).catch(err => alert(err.message))
    }

    return (
        <div className='SidebarESchoolingStatusShowPrincipal'>
            <div className='rowData'>
                <h2>User E-Schooling Status</h2>
                <h4>:</h4>
                <h3>"PRINCIPAL"</h3>
            </div>
            <div className='rowData'>
                <h2>School</h2>
                <h4>:</h4>
                <h3>{userSchoolFullName}</h3>
            </div>
            <div className='rowData '>
                <h2>Verification Status</h2>
                <h4>:</h4>
                <h3>{princiVerifi?.VERIFIED_PRINCIPAL ? 'Verified' : 'Not Verified'}</h3>
            </div>
            <div className='princiButton'>
                <Button onClick={handleOpenModal}>Remove</Button>
            </div>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='deletePostModal'>
                        <div className='deletePostModalText'>
                            <h4 style={{ textAlign: 'center' }}>Do you really want to remove yourself as a Principal?</h4>
                        </div>
                        <div className='deletePostModalButtons'>
                            <LoadingButton
                                onClick={handleClickRemovePrincipal}
                                variant='outlined'
                                loading={btnLoading}
                            >
                                Yes
                            </LoadingButton>
                            <Button onClick={handleCloseModal}>No</Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default SidebarESchoolingStatusShowPrincipal
