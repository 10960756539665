import React, { useState } from 'react'
import '../ProjectsHomeworks/HomeWorksTeachersClassNSubject.css'
// import dB, { storage } from '../firebase';
import { deleteDoc, doc } from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Modal } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB, { storage } from '../../firebase';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const imageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
};


function HomeWorksTeachersClassNSubjectAssignments(props) {

    const {
        questionPic, questionNoteIfAny, questionStatement, submitOnBefore, uploadedOn, questionId,
        userId, userPathCollection, userSchoolId, userSchoolPathCollection,
        isTeachSubVerified, teacherMedClsSec, teacherSubject, teacherSubjectId } = props

    // const classes = useStyles();

    const [btnLoading, setbtnLoading] = useState(false)

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = (e) => { setOpenModal(true); };
    const handleCloseModal = (e) => { setOpenModal(false); };

    const handleClickRemoveAssignements = async (e) => {
        e.preventDefault();
        setbtnLoading(true)

        if (!questionPic) {
            deleteDoc(doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", teacherMedClsSec, "PROJECTS_&_HOMEWORKS", teacherSubject, "ASSIGNMENT", questionId))
                .then(() => {
                    setbtnLoading(false)
                    setOpenModal(false)
                }).catch((err) => err.message);
        } else {
            const homeRef = ref(storage, questionPic);
            await deleteObject(homeRef).then(async () => {
                await deleteDoc(doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, 'CLASSES_DETAILS', 'CLASSES', teacherMedClsSec, 'PROJECTS_&_HOMEWORKS', teacherSubject, 'ASSIGNMENT', questionId)).then(() => {
                    setbtnLoading(false)
                    setOpenModal(false)
                })
            }).catch((error) => alert(error.message));
        }
    }

    const [selectedPic, setselectedPic] = useState('')

    const [openImage, setOpenImage] = React.useState(false);
    const handleOpenImage = (pic) => {
        setOpenImage(true);
        setselectedPic(pic)
    }
    const handleCloseImage = () => setOpenImage(false);

    return (
        <div className='HomeWorksTeachersClassNSubjectAssignments' >
            <Box sx={{ position: 'relative', }} id='HomeWorksTeachers'>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className='teacherhomewrokaccordianTop'>
                            <div className='teacherhomewrokaccordianTop_Head UploadedOn'>
                                <h4>Uploaded On :</h4>
                                <h3>{uploadedOn?.toDate().toDateString()}</h3>
                            </div>
                            <div className='teacherhomewrokaccordianTop_Head SubmitOn'>
                                <h4>Sumbit On/Before :</h4>
                                <h3>{submitOnBefore}</h3>
                            </div>
                            <Button onClick={handleOpenModal}>Remove</Button>
                            <Modal
                                open={openModal}
                                onClose={handleCloseModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <div className='deletePostModal'>
                                        <div className='deletePostModalText'>
                                            <h4 style={{ textAlign: 'center' }}>Do you really want to delete the assignement with question "{questionStatement}"?</h4>
                                        </div>
                                        <div className='deletePostModalButtons'>
                                            <LoadingButton
                                                onClick={handleClickRemoveAssignements}
                                                variant='outlined'
                                                loading={btnLoading}
                                            >
                                                Yes
                                            </LoadingButton>
                                            {/* <Button disabled={btnLoading} >Yes</Button> */}
                                            <Button onClick={handleCloseModal}>No</Button>
                                        </div>
                                    </div>
                                </Box>
                            </Modal>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='homeworksteachersclassnsubject__rightDataHomework'>
                            {/* question */}
                            {
                                questionStatement && (
                                    <div className='homeworksteachersclassnsubject__rightDataHomeworkQue'>
                                        <h4><span className='spanQuestion'>Question :</span> {questionStatement}.</h4>
                                    </div>
                                )
                            }
                            {
                                questionPic !== '' && (
                                    <div className='homeworksteachersclassnsubject__rightDataHomeworkImage'>
                                        {/* image if any */}
                                        <center><img src={questionPic} alt={questionPic} onClick={() => handleOpenImage(questionPic)} /></center>
                                        <Modal
                                            open={openImage}
                                            onClose={handleCloseImage}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box sx={imageStyle}>
                                                <div className='profileImageBig'>
                                                    <img src={selectedPic} alt={selectedPic} />
                                                </div>
                                            </Box>
                                        </Modal>
                                    </div>
                                )
                            }


                            {/* submit on/before */}
                            <h5>Uploaded On : <strong className='strong'>{uploadedOn?.toDate().toDateString()}</strong ></h5>
                            <h5>Submit On/Before : <strong className='strong'>{submitOnBefore} (YYYY-MM-DD)</strong ></h5>

                            {/* note/remarks if any */}
                            {questionNoteIfAny && (<p><strong>Note : </strong> {questionNoteIfAny}</p>)}

                        </div>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </div>
    )
}

export default HomeWorksTeachersClassNSubjectAssignments
