import React, { useEffect, useState } from 'react'
import '../Pages/UserProfile.css'
import Login from './Login'
import Header from '../Components/Header/Header'
import HomePageFeed from '../Components/Social/HomePageFeed'
import UserPhotos from '../Components/Social/UserPhotos'
import UserFriends from '../Components/Social/UserFriends'
import UserAbout from '../Components/Social/UserAbout'
import UserProfileSidebar from '../Components/Social/UserProfileSidebar'
import UserProfileTop from '../Components/Social/UserProfileTop'

import dB, { auth } from '../firebase'
import { collection, doc, onSnapshot } from 'firebase/firestore'
import { onAuthStateChanged } from 'firebase/auth'

import PropTypes from 'prop-types';
import { AppBar, Box, Tab, Tabs, Typography } from '@mui/material'


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function UserProfile() {

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => { setValue(newValue); };

    const [userId, setuserId] = useState('')
    const [userData, setuserData] = useState(null)
    useEffect(() => {
        const unSubscribe = onAuthStateChanged(auth, async (userCredentials) => {
            if (userCredentials) {
                onSnapshot(doc(dB, "USERS", userCredentials.uid), (snap) => {
                    if (snap.exists()) {
                        setuserId(userCredentials.uid)
                        setuserData(snap.data())
                    }
                })

            }
        });
        return () => { unSubscribe(); }
    }, [])


    const [regInfoData, setregInfoData] = useState(null)
    const [userSocialInfoData, setuserSocialInfoData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData?.USER_NAME && userId) {
                onSnapshot(doc(dB, "USERS", userId, userData?.USER_NAME, "REGISTRATION_INFO"), (snap) => {
                    if (snap.exists()) { setregInfoData(snap.data()) }
                })
                onSnapshot(doc(dB, "USERS", userId, userData?.USER_NAME, "SOCIAL_INFO"), (snap) => {
                    if (snap.exists()) { setuserSocialInfoData(snap.data()) }
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userData?.USER_NAME])


    const [userStatuses, setuserStatuses] = useState([])
    useEffect(() => {
        if (userStatuses.length > 0) {
            userStatuses.splice(0, userStatuses.length)
        }
        var aaa = true
        if (aaa === true) {
            if (userId && userData?.USER_NAME) {
                onSnapshot(doc(dB, "USERS", userId, userData?.USER_NAME, "E-SCHOOLING_INFO", "IS_TEACHER", "VERIFICATION_DETAILS"), (snap) => {
                    if (snap.exists()) { setuserStatuses(prev => { return [...prev, snap.data()?.USER_STATUS] }) }
                })
                onSnapshot(doc(dB, "USERS", userId, userData?.USER_NAME, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), (snap) => {
                    if (snap.exists()) { setuserStatuses(prev => { return [...prev, snap.data()?.USER_STATUS] }) }
                })
                onSnapshot(doc(dB, "USERS", userId, userData?.USER_NAME, "E-SCHOOLING_INFO", "IS_STUDENT", "VERIFICATION_DETAILS"), (snap) => {
                    if (snap.exists()) { setuserStatuses(prev => { return [...prev, snap.data()?.USER_STATUS] }) }
                })
                onSnapshot(doc(dB, "USERS", userId, userData?.USER_NAME, "E-SCHOOLING_INFO", "IS_PARENT", "VERIFICATION_DETAILS"), (snap) => {
                    if (snap.exists()) { setuserStatuses(prev => { return [...prev, snap.data()?.USER_STATUS] }) }
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userData?.USER_NAME])


    const [isStudentData, setisStudentData] = useState(null)
    const [studentVerificationDeatils, setstudentVerificationDeatils] = useState(null)
    const [princiVerificationDeatils, setprinciVerificationDeatils] = useState(null)
    const [isTeacherData, setisTeacherData] = useState(null)
    const [teacherVerificationDeatils, setteacherVerificationDeatils] = useState(null)

    // get data of students, teachers and principals
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userStatuses.length) {
                if (userStatuses.includes('TEACHER')) {
                    onSnapshot(doc(dB, "USERS", userId, userData?.USER_NAME, "E-SCHOOLING_INFO", "IS_TEACHER", "VERIFICATION_DETAILS"), (snap) => {
                        if (snap.exists()) { setteacherVerificationDeatils(snap.data()) }
                    })
                    onSnapshot(collection(dB, "USERS", userId, userData?.USER_NAME, "E-SCHOOLING_INFO", "IS_TEACHER", "IS_TEACHER_DATA", "SUBJECTS_TEACH"), (snap) => {
                        setisTeacherData(snap.docs.map((teachDoc) => ({
                            subId: teachDoc.id,
                            subData: teachDoc.data(),
                        })))
                    })
                }
                if (userStatuses.includes('STUDENT')) {
                    onSnapshot(doc(dB, "USERS", userId, userData?.USER_NAME, "E-SCHOOLING_INFO", "IS_STUDENT", "VERIFICATION_DETAILS"), (snap) => {
                        if (snap.exists()) { setstudentVerificationDeatils(snap.data()) }
                    })
                    onSnapshot(doc(dB, "USERS", userId, userData?.USER_NAME, "E-SCHOOLING_INFO", "IS_STUDENT", "IS_STUDENT_DATA"), (snap) => {
                        if (snap.exists()) { setisStudentData(snap.data()) }
                    })
                }
                if (userStatuses.includes('PRINCIPAL')) {
                    onSnapshot(doc(dB, "USERS", userId, userData?.USER_NAME, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), (snap) => {
                        if (snap.exists()) { setprinciVerificationDeatils(snap.data()) }
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [userId, userData?.USER_NAME, userStatuses])

    return (
        <>
            {
                userId === '' ? (
                    <Login />
                ) : (
                    <div className='userprofile'>
                        {/* Header */}
                        <Header />
                        <div className='userprofile__body'>
                            <div className='userprofile__bodyTop'>
                                <UserProfileTop
                                    userId={userId}
                                    userCurrentStatus={userData?.USER_CURRENT_STATUS}
                                    userPathCollection={userData?.USER_NAME}
                                    userDP={userData?.USER_DISPLAY_PIC}

                                    friendId={null}
                                    friendCurrentStatus={null}
                                    friendPathCollection={null}

                                    userName={regInfoData?.USER_NAME}
                                    userFName={regInfoData?.F_NAME}
                                    userLName={regInfoData?.L_NAME}

                                    userBio={userSocialInfoData?.USER_BIO}
                                    userDOB={regInfoData?.USER_DOB}
                                    userGender={regInfoData?.USER_GENDER}
                                    userMobNo={regInfoData?.USER_MOB_NO}
                                    userEmail={userSocialInfoData?.USER_EMAIL_ID}

                                    userDegree1={userSocialInfoData?.USER_DEGREE_1}
                                    userDegree2={userSocialInfoData?.USER_DEGREE_2}
                                    userDegree3={userSocialInfoData?.USER_DEGREE_3}

                                    userAchievements={userSocialInfoData?.USER_ACHIEVEMENTS}
                                    userStatuses={userStatuses}
                                />
                            </div>
                            <div className='userprofile__bodyTopNavbar'>
                                <Box id='userprofile__bodyTopNavbarAppBar' sx={{ flexGrow: 1, backgroundColor: 'var(--connected-background)', }}>
                                    <AppBar color='inherit' position="static">
                                        <Tabs indicatorColor='primary' textColor='primary' className='tab' value={value} onChange={handleChange} centered>
                                            <Tab sx={{ fontSize: '16px', fontWeight: '600', width: "150px", color: 'black' }} label="Timeline" {...a11yProps(0)} />
                                            <Tab sx={{ fontSize: '16px', fontWeight: '600', width: "150px", color: 'black' }} label="About" {...a11yProps(1)} />
                                            <Tab sx={{ fontSize: '16px', fontWeight: '600', width: "150px", color: 'black' }} label="Photos" {...a11yProps(2)} />
                                            <Tab sx={{ fontSize: '16px', fontWeight: '600', width: "150px", color: 'black' }} label="Connections" {...a11yProps(3)} />
                                        </Tabs>
                                    </AppBar>
                                    <TabPanel value={value} index={0}>
                                        <div id="feed" className='userprofile__bodyBottom'>
                                            <div className='userprofile__bodyBottomLeft'>
                                                {/* Sidebar -> Intro / Photos / Friends */}
                                                <UserProfileSidebar
                                                    userBio={userSocialInfoData?.USER_BIO}
                                                    userDOB={regInfoData?.USER_DOB}
                                                    userGender={regInfoData?.USER_GENDER}
                                                    userMobNo={regInfoData?.USER_MOB_NO}
                                                />
                                            </div>
                                            <div className='userprofile__bodyBottomRight'>
                                                {/* Feed -> Posts */}
                                                <HomePageFeed
                                                    userId={userId}
                                                    userFullName={regInfoData?.USER_NAME}
                                                    userPathCollection={userData?.USER_NAME}
                                                    userCurrentStatus={userData?.USER_CURRENT_STATUS}
                                                    userDP={userData?.USER_DISPLAY_PIC}

                                                    friendId={null}
                                                    friendFullName={null}
                                                    friendPathCollection={null}
                                                    friendCurrentStatus={null}
                                                    friendDP={null}

                                                    MainHomePage={false}
                                                />
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <div id="about" className='userprofile__bodyBottom'>
                                            <UserAbout
                                                userBio={userSocialInfoData?.USER_BIO}
                                                userDOB={regInfoData?.USER_DOB}
                                                userGender={regInfoData?.USER_GENDER}
                                                userMobNo={regInfoData?.USER_MOB_NO}
                                                userEmail={userSocialInfoData?.USER_EMAIL_ID}

                                                userDegree1={userSocialInfoData?.USER_DEGREE_1}
                                                userDegree2={userSocialInfoData?.USER_DEGREE_2}
                                                userDegree3={userSocialInfoData?.USER_DEGREE_3}

                                                userAchievements={userSocialInfoData?.USER_ACHIEVEMENTS}
                                                userStatuses={userStatuses}
                                                isStudentData={isStudentData}
                                                studentVerificationDeatils={studentVerificationDeatils}
                                                princiVerificationDeatils={princiVerificationDeatils}
                                                isTeacherData={isTeacherData}
                                                teacherVerificationDeatils={teacherVerificationDeatils}
                                                userId={userId}
                                                userCurrentStatus={userData?.USER_CURRENT_STATUS}
                                                userPathCollection={userData?.USER_NAME}
                                                friendId={null}
                                                friendFullName={null}
                                                friendPathCollection={null}
                                                friendCurrentStatus={null}
                                                friendStatuses={null}
                                            />
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <div id="friends" className='userprofile__bodyBottom'>
                                            <UserPhotos
                                                userId={userId}
                                                userCurrentStatus={userData?.USER_CURRENT_STATUS}
                                                userPathCollection={userData?.USER_NAME}

                                                friendId={null}
                                                friendCurrentStatus={null}
                                                friendPathCollection={null}
                                            />
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={3}>
                                        <div id="photos" className='userprofile__bodyBottom'>
                                            <UserFriends
                                                userId={userId}
                                                userCurrentStatus={userData?.USER_CURRENT_STATUS}
                                                userPathCollection={userData?.USER_NAME}
                                                friendId={null}
                                                friendFullName={null}
                                                friendPathCollection={null}
                                                friendCurrentStatus={null}
                                            />
                                        </div>
                                    </TabPanel>
                                </Box>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default UserProfile
