
import '../Social/SchoolAccountTop.css'
import '../Social/SchoolProfileSidebar.css'

import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SchoolIcon from '@mui/icons-material/School';

function SchoolProfileSidebar(props) {
    const { schoolEstablished, schoolRestAddres, schoolPhone, schoolAbout, schoolMission,
        schoolVision, schoolAffiliation } = props

    return (
        <div className='schoolprofilesidebar'>
            {
                (schoolRestAddres || schoolEstablished || schoolPhone || schoolAffiliation) && (
                    <div className='schoolprofilesidebar__intro'>
                        <h4>Intro</h4>
                        <div className='schoolprofilesidebar__introOptions'>
                            {
                                schoolEstablished && (
                                    <div className='schoolprofilesidebar__introOption'>
                                        <AccountBalanceIcon />
                                        <p>Established in <strong>{schoolEstablished}</strong></p>
                                    </div>
                                )
                            }
                            {
                                schoolAffiliation && (
                                    <div className='schoolprofilesidebar__introOption'>
                                        <SchoolIcon />
                                        <p><strong>{schoolAffiliation}</strong></p>
                                    </div>
                                )
                            }
                            {
                                schoolRestAddres && (
                                    <div className='schoolprofilesidebar__introOption'>
                                        <LocationOnIcon />
                                        <p><strong>{schoolRestAddres}</strong></p>
                                    </div>
                                )
                            }
                            {
                                schoolPhone && (
                                    <div className='schoolprofilesidebar__introOption'>
                                        <PhoneIcon />
                                        <p><strong>{schoolPhone}</strong></p>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                )
            }

            {
                (schoolVision || schoolMission) && (
                    <>
                        {
                            schoolVision ? (
                                <div className='schoolprofilesidebar__bio'>
                                    <h4>School Vision</h4>
                                    <p>{schoolVision}</p>
                                </div>
                            ) : (
                                <div className='schoolprofilesidebar__bio'>
                                    <h4>School Mission</h4>
                                    <p>{schoolMission}</p>
                                </div>
                            )
                        }
                    </>
                )
            }
        </div>
    )
}

export default SchoolProfileSidebar
