import React, { useEffect, useState } from 'react'
import '../ProjectsHomeworks/HomeWorksParentschildsSubject.css'
import '../ProjectsHomeworks/HomeWorksStudentsSubject.css'
import { doc, onSnapshot } from 'firebase/firestore';

import Accordion from '@mui/material/Accordion';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dB from '../../firebase';
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec';
import SyllabusCoveredSubjectwise from './SyllabusCoveredSubjectwise';

function SyllabusCoveredParentsChildSubject(props) {
    const {
        userId,
        userPathCollection,
        userCurrentStatus,
        userSchoolId,
        childName,
        childMedium,
        childClass,
        childSection,
        childVerified
    } = props

    const [schoolPathCollection, setschoolPathCollection] = useState('')
    // get schoolPathCollection
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userSchoolId) {
                onSnapshot(doc(dB, "SCHOOLS", userSchoolId), (childDOc) => {
                    if (childDOc.exists()) {
                        setschoolPathCollection(childDOc.data()?.SCHOOL_NAME)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userSchoolId])

    const [childMedClsSec, setchildMedClsSec] = useState('')
    // set the childMedClsSec path
    useEffect(() => {
        setchildMedClsSec('')
        var aaa = true
        if (aaa === true) {
            if (childMedium && childClass && childSection) {
                var abc = returnMedClsSec(childMedium, childClass, childSection)
                setchildMedClsSec(abc)
            }
        }
        return () => { aaa = false }
    }, [childMedium, childClass, childSection])


    const [subjectsList, setsubjectsList] = useState([])
    // get the list of subjects
    useEffect(() => {
        setsubjectsList([])
        var aaa = true
        if (aaa === true) {
            if (childMedClsSec && schoolPathCollection && userSchoolId) {
                onSnapshot(doc(dB, "SCHOOLS", userSchoolId, schoolPathCollection, "CLASSES_DETAILS", "CLASSES", childMedClsSec), (subDoc) => {
                    if (subDoc.exists()) {
                        setsubjectsList(subDoc.data()?.SUBJECTS_LIST)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [schoolPathCollection, childMedClsSec, userSchoolId])



    return (
        <div className='HomeWorksParentschildsSubject'>
            {
                subjectsList.map((subName, i) => (
                    <Accordion key={i}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className='buttonText'>
                                <h4>{subName}</h4>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SyllabusCoveredSubjectwise
                                userId={userId}
                                userPathCollection={userPathCollection}
                                userCurrentStatus={userCurrentStatus}
                                userSchoolId={userSchoolId}
                                userSchoolPathCollection={schoolPathCollection}
                                isPrinciVerified={null}
                                classPath={childMedClsSec}
                                medium={childMedium}
                                classNum={childClass}
                                section={childSection}
                                subName={subName}
                            />
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </div>
    )
}

export default SyllabusCoveredParentsChildSubject