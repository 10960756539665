import React, { useEffect, useState } from 'react';
import '../Social/UserFriends.css';
import '../FIndToFollow/FindToFollowTeachersNFriends.css';
import '../Social/UserProfileTop.css';
// import dB from '../firebase';
import { collection, doc, onSnapshot, serverTimestamp, writeBatch } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { Avatar, Button, Card } from '@mui/material';
import dB from '../../firebase';

function FriendsCard(props) {

    const {
        friendCardId, friendCardFullName, friendCardPathCollection, friendCardImage, friendCardCurrentStatus, userId,
        userPathCollection, userCurrentStatus, friendImage, friendId, friendCurrentStatus, friendFullName, friendPathCollection,
    } = props


    const [userConnectionIds, setuserConnectionIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "CONNECTIONS", "CONNECTED_USERS"), (snap) => {
                    setuserConnectionIds(snap.docs.map((conDoc) => conDoc.id))
                })

            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const [userRequestSentIds, setuserRequestSentIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "REQUESTS_SENT", "REQUESTS_SENT_IDs"), (snap) => {
                    setuserRequestSentIds(snap.docs.map((reqSentDoc) => reqSentDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const [userRequestRecievedIds, setuserRequestRecievedIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "REQUESTS_RECIEVED", "REQUESTS_RECIEVED_IDs"), (snap) => {
                    setuserRequestRecievedIds(snap.docs.map((reqRecDoc) => reqRecDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const [userFollowingIds, setuserFollowingIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS"), (snap) => {
                    setuserFollowingIds(snap.docs.map((schlDoc) => schlDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const [schoolFollowingIds, setschoolFollowingIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "SCHOOLS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS"), (snap) => {
                    setschoolFollowingIds(snap.docs.map((sclDoc) => sclDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    var batch = writeBatch(dB)

    const [btnDisabled, setbtnDisabled] = useState({
        btnAccept: false, btnReject: false, btnRemove: false, btnSend: false, btnCancel: false,
        btnSchoolFollowUser: false, btnSchoolUnFollowUser: false,
        btnUserFollowUser: false, btnUserUnFollowUser: false,
    })

    const handleSendRequest = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnSend: true } })

        batch.set(doc(dB, "USERS", userId, userPathCollection, "REQUESTS_SENT", "REQUESTS_SENT_IDs", friendCardId), {
            TIMESTAMP: serverTimestamp()
        })
        batch.set(doc(dB, "USERS", friendCardId, friendCardPathCollection, "REQUESTS_RECIEVED", "REQUESTS_RECIEVED_IDs", userId), {
            TIMESTAMP: serverTimestamp()
        })
        batch.set(doc(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS", friendCardId), {
            TIMESTAMP: serverTimestamp()
        })
        batch.set(doc(dB, "USERS", friendCardId, friendCardPathCollection, "FOLLOWERS", "FOLLOWER_USERS", userId), {
            TIMESTAMP: serverTimestamp()
        })

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnSend: false } })
        }).catch(err => alert(err.message))

    }
    const handleCancelRequest = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnCancel: true } })

        batch.delete(doc(dB, "USERS", userId, userPathCollection, "REQUESTS_SENT", "REQUESTS_SENT_IDs", friendCardId))

        batch.delete(doc(dB, "USERS", friendCardId, friendCardPathCollection, "REQUESTS_RECIEVED", "REQUESTS_RECIEVED_IDs", userId))

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnCancel: false } })
        }).catch(err => alert(err.message))

    }


    const handleRemoveFrnd = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnRemove: true } })

        batch.delete(doc(dB, "USERS", userId, userPathCollection, "CONNECTIONS", "CONNECTED_USERS", friendCardId))

        batch.delete(doc(dB, "USERS", friendCardId, friendCardPathCollection, "CONNECTIONS", "CONNECTED_USERS", userId))

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnRemove: false } })
        }).catch(err => alert(err.message))
    }
    const handleAcceptRequest = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnAccept: true } })

        batch.delete(doc(dB, "USERS", userId, userPathCollection, "REQUESTS_RECIEVED", "REQUESTS_RECIEVED_IDs", friendCardId))

        batch.delete(doc(dB, "USERS", friendCardId, friendCardPathCollection, "REQUESTS_SENT", "REQUESTS_SENT_IDs", userId))

        batch.set(doc(dB, "USERS", userId, userPathCollection, "CONNECTIONS", "CONNECTED_USERS", friendCardId), {
            TIMESTAMP: serverTimestamp()
        })

        batch.set(doc(dB, "USERS", friendCardId, friendCardPathCollection, "CONNECTIONS", "CONNECTED_USERS", userId), {
            TIMESTAMP: serverTimestamp()
        })

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnAccept: false } })
        }).catch(err => alert(err.message))
    }
    const handleRejectRequest = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnReject: true } })

        batch.delete(doc(dB, "USERS", userId, userPathCollection, "REQUESTS_RECIEVED", "REQUESTS_RECIEVED_IDs", friendCardId))

        batch.delete(doc(dB, "USERS", friendCardId, friendCardPathCollection, "REQUESTS_SENT", "REQUESTS_SENT_IDs", userId))

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnReject: false } })
        })
    }


    const handleSchoolFollowUser = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnSchoolFollowUser: true } })

        batch.set(doc(dB, "SCHOOLS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS", friendCardId), {
            TIMESTAMP: serverTimestamp()
        })
        batch.set(doc(dB, "USERS", friendCardId, friendCardPathCollection, "FOLLOWERS", "FOLLOWER_SCHOOLS", userId), {
            TIMESTAMP: serverTimestamp()
        })

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnSchoolFollowUser: false } })
        }).catch(err => alert(err.message))
    }
    const handleSchoolUnfollowUser = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnSchoolUnFollowUser: true } })

        batch.delete(doc(dB, "SCHOOLS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS", friendCardId))

        batch.delete(doc(dB, "USERS", friendCardId, friendCardPathCollection, "FOLLOWERS", "FOLLOWER_SCHOOLS", userId))

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnSchoolUnFollowUser: false } })
        }).catch(err => alert(err.message))
    }

    const handleUserFollowUser = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnUserFollowUser: true } })

        batch.set(doc(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS", friendCardId), {
            TIMESTAMP: serverTimestamp()
        })

        batch.set(doc(dB, "USERS", friendCardId, friendCardPathCollection, "FOLLOWERS", "FOLLOWER_USERS", userId), {
            TIMESTAMP: serverTimestamp()
        })

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnUserFollowUser: false } })
        }).catch(err => alert(err.message))
    }
    const handleUserUnfollowUser = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnUserUnFollowUser: true } })

        batch.delete(doc(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS", friendCardId))

        batch.delete(doc(dB, "USERS", friendCardId, friendCardPathCollection, "FOLLOWERS", "FOLLOWER_USERS", userId))

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnUserUnFollowUser: false } })
        }).catch(err => alert(err.message))
    }

    // const classes = useStyles();

    return (
        <div className='FriendsCardBlock' >
            <Card sx={{ width: 140, }} >
                <div className='FriendsCard' >
                    <Link to={`/friendsprofile/${friendCardId}`} style={{ textDecoration: 'none', color: 'black' }}>
                        <Avatar
                            alt={friendCardFullName}
                            src={friendCardImage}
                            // className={classes.root}
                            sx={{ width: 50, height: 50 }}
                        />
                    </Link>
                    <p>
                        <Link to={`/friendsprofile/${friendCardId}`} style={{ textDecoration: 'none', color: 'black' }}>
                            {friendCardFullName}
                        </Link>
                    </p>
                    {
                        friendCardCurrentStatus === 'PRINCIPAL' ? (
                            <h5>[{friendCardCurrentStatus}]</h5>
                        ) : friendCardCurrentStatus === 'STUDENT' ? (
                            <h5>[{friendCardCurrentStatus}]</h5>
                        ) : friendCardCurrentStatus === 'TEACHER' ? (
                            <h5>[{friendCardCurrentStatus}]</h5>
                        ) : (
                            <h5>[{friendCardCurrentStatus}]</h5>
                        )
                    }
                    {
                        friendId === null ? (
                            <>
                                <div className='userprofilenavbar__rightButtonRemoveCard'>
                                    <Button disabled={btnDisabled.btnRemove} color="primary" onClick={handleRemoveFrnd}>Remove</Button>
                                </div>

                            </>
                        ) : (
                            <>
                                {
                                    userId === friendCardId ? (
                                        <div className='youCard'>
                                            <h4>You</h4>
                                        </div>
                                    ) : (
                                        <>
                                            {
                                                userCurrentStatus === 'SCHOOL' ? (
                                                    <>
                                                        {
                                                            schoolFollowingIds.includes(friendCardId) ? (
                                                                <div className='cardButton buttonOrange'>
                                                                    <Button disabled={btnDisabled.btnSchoolUnFollowUser} color="primary" onClick={handleSchoolUnfollowUser}>Unfollow</Button>
                                                                </div>
                                                            ) : (
                                                                <div className='cardButton buttonBlue'>
                                                                    <Button disabled={btnDisabled.btnSchoolFollowUser} color="primary" onClick={handleSchoolFollowUser}>Follow</Button>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                ) : (
                                                    <>
                                                        {
                                                            userConnectionIds.includes(friendCardId) ? (
                                                                <div className='cardButton buttonOrange'>
                                                                    {/* remove connection */}
                                                                    <Button disabled={btnDisabled.btnRemove} color="primary" onClick={handleRemoveFrnd}>Remove</Button>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    {
                                                                        userRequestSentIds.includes(friendCardId) ? (

                                                                            <div className='cardButton buttonOrange'>
                                                                                {/* cancel sent */}
                                                                                <Button disabled={btnDisabled.btnCancel} color="primary" onClick={handleCancelRequest}>Cancel Sent</Button>
                                                                            </div>

                                                                        ) : (
                                                                            <>
                                                                                {
                                                                                    userRequestRecievedIds.includes(friendCardId) ? (
                                                                                        <>
                                                                                            {/*Accept recieved */}
                                                                                            <div className='cardButton buttonGreen'>
                                                                                                <Button disabled={btnDisabled.btnAccept} color="primary" onClick={handleAcceptRequest}>Accept</Button>
                                                                                            </div>
                                                                                            {/* reject/cancel recieved */}
                                                                                            <div className='cardButton buttonOrange'>
                                                                                                <Button disabled={btnDisabled.btnReject} color="primary" onClick={handleRejectRequest}>Reject</Button>
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        <div className='cardButton buttonBlue'>
                                                                                            {/* connect */}
                                                                                            <Button disabled={btnDisabled.btnSend} color="primary" onClick={handleSendRequest}>Connect +</Button>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            !userRequestRecievedIds.includes(friendCardId) && (
                                                                <>
                                                                    {
                                                                        userFollowingIds.includes(friendCardId) ? (
                                                                            <div className='cardButton buttonOrange'>
                                                                                <Button disabled={btnDisabled.btnUserUnFollowUser} color="primary" onClick={handleUserUnfollowUser}>Unfollow</Button>
                                                                            </div>
                                                                        ) : (
                                                                            <div className='cardButton buttonBlue'>
                                                                                <Button disabled={btnDisabled.btnUserFollowUser} color="primary" onClick={handleUserFollowUser}>Follow</Button>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }

                            </>
                        )
                    }
                </div>
            </Card>
        </div>
    )
}

export default FriendsCard