import React, { useEffect, useState } from 'react'
// import '../Components/SchoolAbout.css'
import '../Social/SchoolAbout.css'
// import dB from '../firebase';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import { Link } from 'react-router-dom'
import { Avatar } from '@mui/material';
import dB from '../../firebase';

function SchoolAboutTeachersList({ teacherId }) {

    const [teacherData, setteacherData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (teacherId) {
                onSnapshot(doc(dB, "USERS", teacherId), (snap) => {
                    if (snap.exists()) { setteacherData(snap.data()) }
                })
            }
        }
        return () => { aaa = false }
    }, [teacherId])

    const [teacherSubjectIds, setteacherSubjectIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (teacherData !== null) {
                onSnapshot(collection(dB, "USERS", teacherId, teacherData?.USER_NAME, "E-SCHOOLING_INFO", "IS_TEACHER", "IS_TEACHER_DATA", "SUBJECTS_TEACH"), (snap) => {
                    setteacherSubjectIds(snap.docs.map((teachDOc) => teachDOc.id,))
                })
            }
        }
        return () => { aaa = false }
    }, [teacherData, teacherId])

    const [teacherSujects, setteacherSujects] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (teacherSubjectIds.length > 0) {
                teacherSubjectIds.forEach(id => {
                    onSnapshot(doc(dB, "USERS", teacherId, teacherData?.USER_NAME, "E-SCHOOLING_INFO", "IS_TEACHER", "IS_TEACHER_DATA", "SUBJECTS_TEACH", id), (snap) => {
                        if (snap.exists()) {
                            if (!teacherSujects.includes(snap.data()?.TEACH_CLASS)) {
                                const a = snap.data()?.TEACH_CLASS
                                setteacherSujects(prev => [...prev, a])
                            }
                        }
                    })
                })
            }
        }
        return () => { aaa = false }
    }, [teacherData, teacherId, teacherSubjectIds])

    return (
        <div className='staffNameLinkTeachers'>
            <Link to={`/friendsprofile/${teacherId}`} style={{ textDecoration: 'none', color: 'gray' }}>
                <Avatar
                    alt={teacherData?.USER_NAME}
                    src={teacherData?.USER_DISPLAY_PIC}
                    // className={classes.small} 
                    sx={{ width: 30, height: 30 }}
                />
            </Link>
            <h3>
                <Link to={`/friendsprofile/${teacherId}`} style={{ textDecoration: 'none', color: 'black' }}>
                    {teacherData?.USER_NAME}
                </Link>
            </h3>
            <p>({teacherSujects.map(sub => { return (`${sub}, `) })})</p>
        </div>
    )
}

export default SchoolAboutTeachersList
