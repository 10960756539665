import React, { useEffect, useState } from 'react'
import '../VerificationRequest/SchoolVerificationRequestsStudents.css'
import { deleteDoc, doc, onSnapshot, serverTimestamp, writeBatch } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';

function SchoolVerificationRequestsStudents(props) {

    const { studentMedium, studentClass,
        studentSection, studentCollectionId, studentsMedClsSec,
        schoolId, schoolShortName, schoolFullName, schoolAffiliation } = props

    const [studentsData, setstudentsData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (studentCollectionId) {
                onSnapshot(doc(dB, "USERS", studentCollectionId), (snap) => {
                    if (snap.exists()) { setstudentsData(snap.data()) }
                })
            }
        }
        return () => { aaa = false }
    }, [studentCollectionId])
    const [studentRegName, setstudentRegName] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (studentCollectionId && studentsData) {
                onSnapshot(doc(dB, "USERS", studentCollectionId, studentsData?.USER_NAME, "REGISTRATION_INFO"), (snap) => {
                    if (snap.exists()) { setstudentRegName(snap.data()?.USER_NAME) }
                })
            }
        }
        return () => { aaa = false }
    }, [studentCollectionId, studentsData])

    var batch = writeBatch(dB);
    const [btnLoading, setbtnLoading] = useState(false)
    const handleStudentConfirm = async (e) => {
        e.preventDefault();
        setbtnLoading(true)

        deleteDoc(doc(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", studentsMedClsSec, "STUDENTS", "VERIFICATION_REQUESTS", "VERIFICATION_REQUESTS_DATA", studentCollectionId))

        await batch.set(doc(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", studentsMedClsSec, "STUDENTS", "VERIFIED_MEMBERS", "VERIFIED_MEMBERS_DATA", studentCollectionId), {
            TIMESTAMP: serverTimestamp()
        });

        await batch.update(doc(dB, "USERS", studentCollectionId, studentsData?.USER_NAME, "E-SCHOOLING_INFO", "IS_STUDENT", "VERIFICATION_DETAILS"), {
            VERIFIED_SCHOOL_ID: schoolId,
            VERIFIED_STUDENT: true
        })

        // Commit the await batch
        await batch.commit().then(() => {
            setbtnLoading(false)
        }).catch(err => alert(err.message));
    }

    const handleStudentCancel = async (e) => {
        e.preventDefault();
        setbtnLoading(true)

        deleteDoc(doc(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", studentsMedClsSec, "STUDENTS", "VERIFICATION_REQUESTS", "VERIFICATION_REQUESTS_DATA", studentCollectionId))
            .then(() => {
                setbtnLoading(false)
            }).catch(err => alert(err.message))

    }

    return (
        <div className='SchoolVerificationRequestsStudents' >
            <div className='schoolverificationrequests__containerRightStudentsData' >
                <div className='schoolverificationrequests__containerRightStudentsDataName dataNameParent'>
                    <Link to={`/friendsprofile/${studentCollectionId}`} style={{ textDecoration: 'none', color: 'gray' }}>
                        <Avatar
                            alt={studentRegName}
                            src={studentsData?.USER_DISPLAY_PIC}
                            sx={{ width: 40, height: 40, }}
                        />
                    </Link>
                    <h3>
                        <Link to={`/friendsprofile/${studentCollectionId}`} style={{ textDecoration: 'none', color: 'black' }}>
                            {studentRegName}
                        </Link>
                    </h3>
                </div>
                <div className='schoolverificationrequests__containerRightStudentsDataButtons'>
                    <LoadingButton
                        onClick={handleStudentConfirm}
                        variant='outlined'
                        loading={btnLoading}
                    >
                        Confirm
                    </LoadingButton>
                    <LoadingButton
                        onClick={handleStudentCancel}
                        variant='outlined'
                        loading={btnLoading}
                    >
                        Cancel
                    </LoadingButton>
                </div>
            </div>
        </div>
    )
}

export default SchoolVerificationRequestsStudents
