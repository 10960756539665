import React, { useEffect, useState } from 'react'
import ProjectNHomework from './ProjectNHomework';
import '../ProjectsHomeworks/HomeWorksParentschildsSubject.css'
import '../ProjectsHomeworks/HomeWorksStudentsSubject.css'
import { collection, doc, onSnapshot } from 'firebase/firestore';
import dB from '../../firebase';

function HomeWorksParentschildsSubjectData(props) {
    const { subjectName, userId, userPathCollection, userCurrentStatus,
        schoolId, schoolPathCollection, medClsSec, } = props

    const [assData, setassData] = useState([])
    const [homeData, sethomeData] = useState([])
    const [proData, setproData] = useState([])

    const [teachersData, setteachersData] = useState({
        teacherId: '', teacherPathCollection: '', teacherName: '',
    })
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            onSnapshot(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "CLASSES_DETAILS", "CLASSES", medClsSec, "PROJECTS_&_HOMEWORKS", subjectName, "ASSIGNMENT"), (snap) => {
                setassData(snap.docs.map((assDoc) => ({
                    assId: assDoc.id,
                    assData: assDoc.data(),
                })))
            })
            onSnapshot(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "CLASSES_DETAILS", "CLASSES", medClsSec, "PROJECTS_&_HOMEWORKS", subjectName, "HOMEWORK"), (snap) => {
                sethomeData(snap.docs.map((homeDoc) => ({
                    homeId: homeDoc.id,
                    homeData: homeDoc.data(),
                })))
            })
            onSnapshot(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "CLASSES_DETAILS", "CLASSES", medClsSec, "PROJECTS_&_HOMEWORKS", subjectName, "PROJECT"), (snap) => {
                setproData(snap.docs.map((proDoc) => ({
                    proId: proDoc.id,
                    proData: proDoc.data(),
                })))
            })
            onSnapshot(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "CLASSES_DETAILS", "CLASSES", medClsSec, "PROJECTS_&_HOMEWORKS", subjectName), (subListDoc) => {
                if (subListDoc.exists()) {
                    if (subListDoc.data()?.SUBJECT_TEACHER_ID !== undefined) {
                        setteachersData(prev => { return { ...prev, teacherId: subListDoc.data()?.SUBJECT_TEACHER_ID } })
                    }
                }
            })
        }
        return () => { aaa = false }
    }, [schoolPathCollection, medClsSec, schoolId, subjectName])

    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (teachersData.teacherId) {
                onSnapshot(doc(dB, "USERS", teachersData.teacherId), (docName) => {
                    if (docName.exists()) {
                        setteachersData(prev => { return { ...prev, teacherName: docName.data()?.USER_NAME } })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [teachersData.teacherId])

    return (
        <div className="subRowPanel">
            {
                assData.length > 0 || homeData.length > 0 || proData.length > 0 ? (
                    <div className='subRowPanelData'>
                        <h3>Projects/Homeworks for <b style={{ color: 'var(--connected-color)' }}>{subjectName}</b> by <span style={{ color: 'black' }}>{teachersData.teacherName}</span></h3>
                        {
                            homeData.length > 0 && (
                                <div key={`${subjectName}homework`} className='subRowPanelDataSegment'>
                                    <h4>Homeworks</h4>
                                    {
                                        homeData.map((data, i) => (
                                            <ProjectNHomework
                                                key={i}
                                                proNHomeId={data?.homeId}
                                                uploadedOn={data?.homeData?.UPLOADED_ON}
                                                submitOn={data?.homeData?.SUBMIT_BEFORE}
                                                questionStatement={data?.homeData?.QUESTION_STATEMENT}
                                                questionImage={data?.homeData?.QUESTION_IMAGE}
                                                questionNote={data?.homeData?.QUESTION_NOTE}
                                                viewedBy={data?.homeData?.VIEWED_BY}
                                                userId={userId}
                                                schoolId={schoolId}
                                                schoolPathCollection={schoolPathCollection}
                                                medClsSec={medClsSec}
                                                subjectName={subjectName}
                                                userCurrentStatus={userCurrentStatus}
                                                assignment={false}
                                                project={false}
                                                homework={true}
                                                homeworkId={data?.homeId}
                                                assignmentId={''}
                                                projectId={''}
                                            />
                                        ))
                                    }
                                </div>
                            )
                        }
                        {
                            assData.length > 0 && (
                                <div key={`${subjectName}assignment`} className='subRowPanelDataSegment'>
                                    <h4>Assignments</h4>
                                    {
                                        assData.map((data, i) => (
                                            <ProjectNHomework
                                                key={i}
                                                proNHomeId={data?.assId}
                                                uploadedOn={data?.assData?.UPLOADED_ON}
                                                submitOn={data?.assData?.SUBMIT_BEFORE}
                                                questionStatement={data?.assData?.QUESTION_STATEMENT}
                                                questionImage={data?.assData?.QUESTION_IMAGE}
                                                questionNote={data?.assData?.QUESTION_NOTE}
                                                viewedBy={data?.assData?.VIEWED_BY}
                                                userId={userId}
                                                schoolId={schoolId}
                                                schoolPathCollection={schoolPathCollection}
                                                medClsSec={medClsSec}
                                                subjectName={subjectName}
                                                userCurrentStatus={userCurrentStatus}
                                                assignment={true}
                                                project={false}
                                                homework={false}
                                                homeworkId={''}
                                                assignmentId={data?.assId}
                                                projectId={''}
                                            />
                                        ))
                                    }
                                </div>
                            )
                        }
                        {
                            proData.length > 0 && (
                                <div key={`${subjectName}project`} className='subRowPanelDataSegment'>
                                    <h4>Projects</h4>
                                    {
                                        proData.map((data, i) => (
                                            <ProjectNHomework
                                                key={i}
                                                proNHomeId={data?.proId}
                                                uploadedOn={data?.proData?.UPLOADED_ON}
                                                submitOn={data?.proData?.SUBMIT_BEFORE}
                                                questionStatement={data?.proData?.QUESTION_STATEMENT}
                                                questionImage={data?.proData?.QUESTION_IMAGE}
                                                questionNote={data?.proData?.QUESTION_NOTE}
                                                viewedBy={data?.proData?.VIEWED_BY}
                                                userId={userId}
                                                schoolId={schoolId}
                                                schoolPathCollection={schoolPathCollection}
                                                medClsSec={medClsSec}
                                                subjectName={subjectName}
                                                userCurrentStatus={userCurrentStatus}
                                                assignment={false}
                                                project={true}
                                                homework={false}
                                                homeworkId={''}
                                                assignmentId={''}
                                                projectId={data?.proId}
                                            />
                                        ))
                                    }
                                </div>
                            )
                        }
                    </div>
                ) : (
                    <div className='noProjectNHomework'>
                        <h3>No Homeworks by <span style={{ color: 'black' }}>{teachersData.teacherName ? teachersData.teacherName : '(none verified yet)'}</span> for the subject of <span style={{ color: 'black' }}>{subjectName}</span> at the moment</h3>
                    </div>
                )
            }
        </div>
    )
}

export default HomeWorksParentschildsSubjectData
