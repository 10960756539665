import React, { useEffect, useState } from 'react'
import '../VerificationRequest/SchoolVerificationRequestsParents.css'
import SchoolVerificationRequestsParentsChild from './SchoolVerificationRequestsParentsChild';
// import dB from '../firebase';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import dB from '../../firebase';

function SchoolVerificationRequestsParents(props) {
    const { childsMedium, childsClass, childsSection, parentsId,
        parentsMedClsSec, schoolId, schoolShortName, schoolFullName, schoolAffiliation,
    } = props

    const [parentInfo, setparentInfo] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (parentsId) {
                onSnapshot(doc(dB, "USERS", parentsId), (snap) => {
                    if (snap.exists()) { setparentInfo(snap.data()) }
                })
            }
        }
        return () => { aaa = false }
    }, [parentsId])

    const [parentsData, setparentsData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolShortName && parentsMedClsSec) {
                onSnapshot(collection(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", parentsMedClsSec, "PARENTS", "VERIFICATION_REQUESTS", "VERIFICATION_REQUESTS_DATA", parentsId, "CHILDS_LIST"), (snap) => {
                    setparentsData(snap.docs.map((childDoc) => ({
                        childsId: childDoc.id,
                        childsData: childDoc.data(),
                    })))
                })
            }
        }
        return () => { aaa = false }
    }, [parentsId, parentsMedClsSec, schoolId, schoolShortName])

    return (
        <>
            {
                parentsData?.map((data, i) => (
                    <SchoolVerificationRequestsParentsChild
                        key={i}
                        childsMedium={childsMedium}
                        childsClass={childsClass}
                        childsSection={childsSection}
                        parentsId={parentsId}
                        parentsPathCollection={parentInfo?.USER_NAME}
                        parentsDP={parentInfo?.USER_DISPLAY_PIC}
                        parentsMedClsSec={parentsMedClsSec}
                        schoolId={schoolId}
                        schoolShortName={schoolShortName}
                        schoolFullName={schoolFullName}
                        schoolAffiliation={schoolAffiliation}
                        childsId={data?.childsId}
                    />
                ))
            }
        </>
    )
}

export default SchoolVerificationRequestsParents

