import React, { useEffect, useState } from 'react'
import '../Social/UserProfileTop.css'
import { collection, doc, onSnapshot, serverTimestamp, writeBatch } from 'firebase/firestore';
import { Avatar, Box, Button, Dialog, Modal, TextField } from '@mui/material';
import dB from '../../firebase';

const imageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
};

function FriendsProfileTop(props) {
    const { friendId, friendCurrentStatus, friendPathCollection, friendFullName,
        userId, userFullName, userPathCollection, userCurrentStatus, friendDP } = props

    const [schoolFollowingIds, setschoolFollowingIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "SCHOOLS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS"), (snap) => {
                    setschoolFollowingIds(snap.docs.map((schlDoc) => schlDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const [userConnectionIds, setuserConnectionIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "CONNECTIONS", "CONNECTED_USERS"), (snap) => {
                    setuserConnectionIds(snap.docs.map((conDoc) => conDoc.id))
                })

            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const [userRequestSentIds, setuserRequestSentIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "REQUESTS_SENT", "REQUESTS_SENT_IDs"), (snap) => {
                    setuserRequestSentIds(snap.docs.map((reqSentDoc) => reqSentDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const [userRequestRecievedIds, setuserRequestRecievedIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "REQUESTS_RECIEVED", "REQUESTS_RECIEVED_IDs"), (snap) => {
                    setuserRequestRecievedIds(snap.docs.map((reqRecDoc) => reqRecDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const [userFollowingIds, setuserFollowingIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS"), (snap) => {
                    setuserFollowingIds(snap.docs.map((schlDoc) => schlDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])


    // const classes = useStyles();

    var batch = writeBatch(dB)

    const [btnDisabled, setbtnDisabled] = useState({
        btnAccept: false, btnReject: false, btnRemove: false, btnSend: false, btnCancel: false,
        btnSchoolFollowUser: false, btnSchoolUnFollowUser: false,
        btnUserFollowUser: false, btnUserUnFollowUser: false,
    })

    // done
    const handleSendRequest = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnSend: true } })

        batch.set(doc(dB, "USERS", userId, userPathCollection, "REQUESTS_SENT", "REQUESTS_SENT_IDs", friendId), {
            TIMESTAMP: serverTimestamp()
        })
        batch.set(doc(dB, "USERS", friendId, friendPathCollection, "REQUESTS_RECIEVED", "REQUESTS_RECIEVED_IDs", userId), {
            TIMESTAMP: serverTimestamp()
        })
        batch.set(doc(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS", friendId), {
            TIMESTAMP: serverTimestamp()
        })
        batch.set(doc(dB, "USERS", friendId, friendPathCollection, "FOLLOWERS", "FOLLOWER_USERS", userId), {
            TIMESTAMP: serverTimestamp()
        })

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnSend: false } })
        }).catch(err => alert(err.message))

    }
    const handleCancelRequest = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnCancel: true } })

        batch.delete(doc(dB, "USERS", userId, userPathCollection, "REQUESTS_SENT", "REQUESTS_SENT_IDs", friendId))

        batch.delete(doc(dB, "USERS", friendId, friendPathCollection, "REQUESTS_RECIEVED", "REQUESTS_RECIEVED_IDs", userId))

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnCancel: false } })
        }).catch(err => alert(err.message))

    }


    const handleRemoveFrnd = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnRemove: true } })

        batch.delete(doc(dB, "USERS", userId, userPathCollection, "CONNECTIONS", "CONNECTED_USERS", friendId))

        batch.delete(doc(dB, "USERS", friendId, friendPathCollection, "CONNECTIONS", "CONNECTED_USERS", userId))

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnRemove: false } })
        }).catch(err => alert(err.message))
    }
    const handleAcceptRequest = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnAccept: true } })

        batch.delete(doc(dB, "USERS", userId, userPathCollection, "REQUESTS_RECIEVED", "REQUESTS_RECIEVED_IDs", friendId))

        batch.delete(doc(dB, "USERS", friendId, friendPathCollection, "REQUESTS_SENT", "REQUESTS_SENT_IDs", userId))

        batch.set(doc(dB, "USERS", userId, userPathCollection, "CONNECTIONS", "CONNECTED_USERS", friendId), {
            TIMESTAMP: serverTimestamp()
        })

        batch.set(doc(dB, "USERS", friendId, friendPathCollection, "CONNECTIONS", "CONNECTED_USERS", userId), {
            TIMESTAMP: serverTimestamp()
        })

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnAccept: false } })
        }).catch(err => alert(err.message))
    }
    const handleRejectRequest = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnReject: true } })

        batch.delete(doc(dB, "USERS", userId, userPathCollection, "REQUESTS_RECIEVED", "REQUESTS_RECIEVED_IDs", friendId))

        batch.delete(doc(dB, "USERS", friendId, friendPathCollection, "REQUESTS_SENT", "REQUESTS_SENT_IDs", userId))

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnReject: false } })
        })
    }


    // done
    const handleSchoolFollowUser = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnSchoolFollowUser: true } })

        batch.set(doc(dB, "SCHOOLS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS", friendId), {
            TIMESTAMP: serverTimestamp()
        })
        batch.set(doc(dB, "USERS", friendId, friendPathCollection, "FOLLOWERS", "FOLLOWER_SCHOOLS", userId), {
            TIMESTAMP: serverTimestamp()
        })

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnSchoolFollowUser: false } })
        }).catch(err => alert(err.message))
    }
    const handleSchoolUnfollowUser = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnSchoolUnFollowUser: true } })

        batch.delete(doc(dB, "SCHOOLS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS", friendId))

        batch.delete(doc(dB, "USERS", friendId, friendPathCollection, "FOLLOWERS", "FOLLOWER_SCHOOLS", userId))

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnSchoolUnFollowUser: false } })
        }).catch(err => alert(err.message))
    }

    // done
    const handleUserFollowUser = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnUserFollowUser: true } })

        batch.set(doc(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS", friendId), {
            TIMESTAMP: serverTimestamp()
        })

        batch.set(doc(dB, "USERS", friendId, friendPathCollection, "FOLLOWERS", "FOLLOWER_USERS", userId), {
            TIMESTAMP: serverTimestamp()
        })

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnUserFollowUser: false } })
        }).catch(err => alert(err.message))
    }
    const handleUserUnfollowUser = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnUserUnFollowUser: true } })

        batch.delete(doc(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS", friendId))

        batch.delete(doc(dB, "USERS", friendId, friendPathCollection, "FOLLOWERS", "FOLLOWER_USERS", userId))

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnUserUnFollowUser: false } })
        }).catch(err => alert(err.message))
    }

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => { setOpen(false); setmsgToSend('') };

    const [sentMessages, setsentMessages] = useState([])
    useEffect(() => {
        if (userId && userPathCollection) {
            if (userCurrentStatus === 'SCHOOL') {
                onSnapshot(collection(dB, "SCHOOLS", userId, userPathCollection, "MESSAGES_SENT", "MESSAGES"), (snap) => {
                    setsentMessages(snap.docs.map((msgDoc) => msgDoc.id))
                })
            } else {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "MESSAGES_SENT", "MESSAGES"), (snap) => {
                    setsentMessages(snap.docs.map((msgDoc) => msgDoc.id))
                })
            }
        }
    }, [userCurrentStatus, userId, userPathCollection])
    const [receivedMessages, setreceivedMessages] = useState([])
    useEffect(() => {
        if (userId && userPathCollection) {
            if (userCurrentStatus === 'SCHOOL') {
                onSnapshot(collection(dB, "SCHOOLS", userId, userPathCollection, "MESSAGES_RECIEVED", "MESSAGES"), (snap) => {
                    setreceivedMessages(snap.docs.map((msgDoc) => msgDoc.id))
                })
            } else {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "MESSAGES_RECIEVED", "MESSAGES"), (snap) => {
                    setreceivedMessages(snap.docs.map((msgDoc) => msgDoc.id))
                })
            }
        }
    }, [userCurrentStatus, userId, userPathCollection])

    // generate 14 digit random number
    const [randomKey, setrandomKey] = useState({ senderNum: '', reciverNum: '' })
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var a = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
            var b = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
            setrandomKey(prev => { return { ...prev, senderNum: a, reciverNum: b } })
        }
        return () => { aaa = false }

    }, [])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (sentMessages?.length > 0 && randomKey.senderNum) {
                if (sentMessages.includes(randomKey.senderNum)) {
                    setrandomKey(prev => { return { ...prev, senderNum: randomKey.senderNum + 1 } })
                }
            }
        }
        return () => { aaa = false }

    }, [randomKey.senderNum, sentMessages])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (receivedMessages?.length > 0 && randomKey.reciverNum) {
                if (receivedMessages.includes(randomKey.reciverNum)) {
                    setrandomKey(prev => { return { ...prev, reciverNum: randomKey.reciverNum + 1 } })
                }
            }
        }
        return () => { aaa = false }

    }, [randomKey.reciverNum, receivedMessages])

    const [disabledtn, setdisabledtn] = useState(false)
    const [showSent, setshowSent] = useState(false)
    const [msgToSend, setmsgToSend] = useState('')
    const handleClickSendMessage = async (e) => {
        e.preventDefault()
        if (msgToSend) {
            setdisabledtn(true)
            await batch.set(doc(dB, "USERS", friendId, friendPathCollection, "MESSAGES_RECIEVED", "MESSAGES", randomKey.reciverNum), {
                MSG_TEXT: msgToSend,
                MSG_TIMESTAMP: serverTimestamp(),
                MSG_FROM: userId,
                MSG_SENDER_STATUS: userCurrentStatus === 'SCHOOL' ? 'SCHOOL' : 'USER',
                IS_VIEWED: false
            })

            // add details to userId in sent message
            if (userCurrentStatus === 'SCHOOL') {
                await batch.set(doc(dB, "SCHOOLS", userId, userPathCollection, "MESSAGES_SENT", "MESSAGES", randomKey.senderNum), {
                    MSG_TEXT: msgToSend,
                    MSG_TIMESTAMP: serverTimestamp(),
                    MSG_TO: friendId,
                    MSG_RECEIVER_STATUS: 'USER'
                })
            } else {
                await batch.set(doc(dB, "USERS", userId, userPathCollection, "MESSAGES_SENT", "MESSAGES", randomKey.senderNum), {
                    MSG_TEXT: msgToSend,
                    MSG_TIMESTAMP: serverTimestamp(),
                    MSG_TO: friendId,
                    MSG_RECEIVER_STATUS: 'USER'
                })
            }

            await batch.commit().then(() => {
                setdisabledtn(false)
                setshowSent(true)
                setmsgToSend('')
                var c = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
                var d = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
                setrandomKey(prev => { return { ...prev, senderNum: c, reciverNum: d } })
            }).catch(err => alert(err.message))
        }
    }

    const [openImage, setOpenImage] = React.useState(false);
    const handleOpenImage = () => setOpenImage(true);
    const handleCloseImage = () => setOpenImage(false);

    return (
        <div className='userprofiletop'>
            {/* Top -> User display picture / Name / Status(student/parent/teacher/etc)/ School Name / Verified tag */}
            <div className='userprofiletop__image'>
                <Box id='avtarNameProfileTop' sx={{ display: 'flex', '& > *': { margin: 1 } }}>
                    <Avatar
                        alt={friendFullName}
                        src={friendDP}
                        // className={classes.large}
                        onClick={handleOpenImage}
                        sx={{ width: 150, height: 150 }}
                    />
                    <Modal
                        open={openImage}
                        onClose={handleCloseImage}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={imageStyle}>
                            <div className='profileImageBig'>
                                <img src={friendDP} alt={friendFullName} />
                            </div>
                        </Box>
                    </Modal>
                    <div id='avtarNameProfileName'>
                        <h5 className='userprofiletop__infoName'>{friendFullName}</h5>
                        <div className='userprofilenavbar__rightButton'>
                            <Button color="primary" onClick={handleClickOpen}>Message</Button>
                            {
                                userCurrentStatus === 'SCHOOL' ? (
                                    <>
                                        {
                                            schoolFollowingIds.includes(friendId) ? (
                                                <div className='userprofilenavbar__rightButtonRemove'>
                                                    <Button disabled={btnDisabled.btnSchoolUnFollowUser} color="primary" onClick={handleSchoolUnfollowUser}>Unfollow User</Button>
                                                </div>
                                            ) : (
                                                <div className='userprofilenavbar__rightButtonConnect'>
                                                    <Button disabled={btnDisabled.btnSchoolFollowUser} color="primary" onClick={handleSchoolFollowUser}>Follow</Button>
                                                </div>
                                            )
                                        }
                                    </>
                                ) : (
                                    <>
                                        {
                                            userRequestSentIds.includes(friendId) ? (
                                                <div className='userprofilenavbar__rightButtonRemove'>
                                                    <Button disabled={btnDisabled.btnCancel} color="primary" onClick={handleCancelRequest}>Cancel Sent Request</Button>
                                                </div>
                                            ) : (
                                                <>
                                                    {
                                                        userRequestRecievedIds.includes(friendId) ? (
                                                            <>
                                                                <div className='userprofilenavbar__rightButtonAccept'>
                                                                    <Button disabled={btnDisabled.btnAccept} color="primary" onClick={handleAcceptRequest}>Accept</Button>
                                                                </div>
                                                                <div className='userprofilenavbar__rightButtonReject'>
                                                                    <Button disabled={btnDisabled.btnReject} color="primary" onClick={handleRejectRequest}>Reject</Button>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {
                                                                    userConnectionIds.includes(friendId) ? (
                                                                        <div className='userprofilenavbar__rightButtonRemove'>
                                                                            <Button disabled={btnDisabled.btnRemove} color="primary" onClick={handleRemoveFrnd}>Remove Connection</Button>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='userprofilenavbar__rightButtonConnect'>
                                                                            <Button disabled={btnDisabled.btnSend} color="primary" onClick={handleSendRequest}>Connect +</Button>
                                                                        </div>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                        {
                                            userFollowingIds.includes(friendId) ? (
                                                <div className='userprofilenavbar__rightButtonRemove'>
                                                    <Button disabled={btnDisabled.btnUserUnFollowUser} color="primary" onClick={handleUserUnfollowUser}>Unfollow</Button>
                                                </div>
                                            ) : (
                                                <div className='userprofilenavbar__rightButtonConnect'>
                                                    <Button disabled={btnDisabled.btnUserFollowUser} color="primary" onClick={handleUserFollowUser}>Follow</Button>
                                                </div>
                                            )
                                        }
                                    </>
                                )
                            }

                        </div>
                        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                            <div className='sendMsgBox'>
                                <TextField
                                    id="outlined-multiline-static"
                                    multiline
                                    minRows={4}
                                    placeholder='Type your message here..'
                                    value={msgToSend}
                                    onChange={(e) => { e.preventDefault(); setmsgToSend(e.target.value); setshowSent(false) }}
                                />
                                <Button disabled={disabledtn} onClick={handleClickSendMessage}>Send Message</Button>
                                {
                                    showSent && (<h3>Message Sent..</h3>)
                                }

                            </div>
                        </Dialog>
                    </div>
                </Box>
            </div>
        </div>
    )
}

export default FriendsProfileTop