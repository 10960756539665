import React, { useEffect, useState } from 'react'
import HomeWorksParentschildsSubjectData from './HomeWorksParentschildsSubjectData';
import '../ProjectsHomeworks/HomeWorksParentschildsSubject.css'
import '../ProjectsHomeworks/HomeWorksStudentsSubject.css'
import { doc, onSnapshot } from 'firebase/firestore';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import dB from '../../firebase';
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec';

function HomeWorksStudentsSubject(props) {

    const { userId, userPathCollection, userCurrentStatus, userSchoolId,
        studentName, studentMedium, studentClass, studentSection
    } = props

    const [schoolPathCollection, setschoolPathCollection] = useState('')
    // get schoolPathCollection
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userSchoolId) {
                onSnapshot(doc(dB, "SCHOOLS", userSchoolId), (snap) => {
                    if (snap.exists()) {
                        setschoolPathCollection(snap.data()?.SCHOOL_NAME)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userSchoolId])

    const [studentMedClsSec, setstudentMedClsSec] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (studentMedium && studentClass && studentSection) {
                setstudentMedClsSec('')
                var abc = returnMedClsSec(studentMedium, studentClass, studentSection)
                setstudentMedClsSec(abc)

            }
        }
        return () => { aaa = false }
    }, [studentMedium, studentClass, studentSection])


    // get the list of subjects
    const [subjectsList, setsubjectsList] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            async function getSubjects(params) {
                if (subjectsList.length > 0) {
                    subjectsList.splice(0, subjectsList.length)
                }
                if (studentMedClsSec && schoolPathCollection) {
                    onSnapshot(doc(dB, "SCHOOLS", userSchoolId, schoolPathCollection, "CLASSES_DETAILS", "CLASSES", studentMedClsSec), (snap) => {
                        if (snap.exists()) {
                            setsubjectsList(snap.data()?.SUBJECTS_LIST)
                        }
                    })
                }
            }
            getSubjects();
        }
        return () => { aaa = false }
    }, [schoolPathCollection, studentMedClsSec, userSchoolId])

    return (
        <div className='HomeWorksParentschildsSubject'>
            {
                subjectsList.map(subName => (
                    <Accordion key={subName}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className='buttonText'>
                                <h4>{subName}</h4>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <HomeWorksParentschildsSubjectData
                                subjectName={subName}
                                userId={userId}
                                userPathCollection={userPathCollection}
                                userCurrentStatus={userCurrentStatus}
                                schoolId={userSchoolId}
                                schoolPathCollection={schoolPathCollection}
                                medClsSec={studentMedClsSec}
                            />
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </div>
    )
}

export default HomeWorksStudentsSubject