import React, { useEffect, useState } from 'react'
import '../Sidebars/HomePageSidebarParents.css'
import '../Sidebars/HomePageSidebar.css'
import HomePageEducationSidebar from './HomePageEducationSidebar';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import dB from '../../firebase';
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec';


function HomePageSidebarParents(props) {

    const { userId, userPathCollection, userCurrentStatus } = props

    const [currentSelectedChildId, setcurrentSelectedChildId] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA"), async (parDoc) => {
                    if (parDoc.exists()) { await setcurrentSelectedChildId(parDoc.data()?.CURRENT_SELECTED_CHILD) }
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])


    const [selectedChildData, setselectedChildData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection && currentSelectedChildId) {
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", currentSelectedChildId), async (childDoc) => {
                    if (childDoc.exists()) {
                        await setselectedChildData(childDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [currentSelectedChildId, userId, userPathCollection])


    const [childsSchoolData, setchildsSchoolData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (selectedChildData?.CHILDS_SCHOOL_ID) {
                onSnapshot(doc(dB, "SCHOOLS", selectedChildData?.CHILDS_SCHOOL_ID), (schlDoc) => {
                    if (schlDoc.exists()) { setchildsSchoolData(schlDoc.data()) }
                })
            }
        }
        return () => { aaa = false }
    }, [selectedChildData?.CHILDS_SCHOOL_ID])


    const [childMedClsSec, setchildMedClsSec] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (selectedChildData?.CHILDS_MEDIUM && selectedChildData?.CHILDS_CLASS && selectedChildData?.CHILDS_SECTION) {
                var abc = returnMedClsSec(selectedChildData?.CHILDS_MEDIUM, selectedChildData?.CHILDS_CLASS, selectedChildData?.CHILDS_SECTION)
                setchildMedClsSec(abc)
            }
        }
        return () => { aaa = false }
    }, [selectedChildData?.CHILDS_MEDIUM, selectedChildData?.CHILDS_CLASS, selectedChildData?.CHILDS_SECTION])


    const [subList, setsubList] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (selectedChildData?.CHILDS_SCHOOL_ID && childsSchoolData?.SCHOOL_NAME && childMedClsSec) {
                onSnapshot(doc(dB, "SCHOOLS", selectedChildData?.CHILDS_SCHOOL_ID, childsSchoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", childMedClsSec), (snap) => {
                    if (snap.exists()) {
                        setsubList(snap.data()?.SUBJECTS_LIST)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [childsSchoolData?.SCHOOL_NAME, childMedClsSec, selectedChildData?.CHILDS_SCHOOL_ID])

    const [assignmentData, setassignmentData] = useState(null)
    const [homeworkData, sethomeworkData] = useState(null)
    const [projectData, setprojectData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (subList?.length > 0) {
                if (selectedChildData?.CHILDS_SCHOOL_ID && childsSchoolData?.SCHOOL_NAME && childMedClsSec) {
                    subList.forEach(sub => {
                        onSnapshot(collection(dB, "SCHOOLS", selectedChildData?.CHILDS_SCHOOL_ID, childsSchoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", childMedClsSec, "PROJECTS_&_HOMEWORKS", sub, "ASSIGNMENT"), (snap) => {
                            setassignmentData(snap.docs.map((assDoc) => ({
                                assId: assDoc.id,
                                assData: assDoc.data(),
                            })))
                        })
                        onSnapshot(collection(dB, "SCHOOLS", selectedChildData?.CHILDS_SCHOOL_ID, childsSchoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", childMedClsSec, "PROJECTS_&_HOMEWORKS", sub, "HOMEWORK"), (snap) => {
                            sethomeworkData(snap.docs.map((homDoc) => ({
                                homeId: homDoc.id,
                                homeData: homDoc.data(),
                            })))
                        })
                        onSnapshot(collection(dB, "SCHOOLS", selectedChildData?.CHILDS_SCHOOL_ID, childsSchoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", childMedClsSec, "PROJECTS_&_HOMEWORKS", sub, "PROJECT"), (snap) => {
                            setprojectData(snap.docs.map((proDoc) => ({
                                proId: proDoc.id,
                                proData: proDoc.data(),
                            })))
                        })
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [childsSchoolData?.SCHOOL_NAME, childMedClsSec, selectedChildData?.CHILDS_SCHOOL_ID, subList])


    const [assIds, setassIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (assignmentData !== null) {
                assignmentData?.forEach(data => {
                    if (!data?.assData?.VIEWED_BY.includes(userId)) {
                        setassIds(prev => { return [...prev, data?.assId] })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [assignmentData, userId])
    const [homeIds, sethomeIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (homeworkData !== null) {
                homeworkData?.forEach(data => {
                    if (!data?.homeData?.VIEWED_BY.includes(userId)) {
                        sethomeIds(prev => { return [...prev, data?.homeId] })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [homeworkData, userId])
    const [proIds, setproIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (projectData !== null) {
                projectData?.forEach(data => {
                    if (!data?.proData?.VIEWED_BY.includes(userId)) {
                        setproIds(prev => { return [...prev, data?.proId] })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [projectData, userId])
    const totalHomeworks = assIds.length + homeIds.length + proIds.length


    return (
        <div className='HomePageSidebarParents'>

            {/* educational info */}
            <HomePageEducationSidebar
                userCurrentStatus={userCurrentStatus}
                schoolId={selectedChildData?.CHILDS_SCHOOL_ID}
                schoolPathCollection={childsSchoolData?.SCHOOL_NAME}
                userId={userId}
                totalHomeworks={totalHomeworks}
            />
            {/* </div> */}

        </div>
    )
}

export default HomePageSidebarParents
