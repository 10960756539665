import React, { useEffect, useState } from 'react'
import SchoolVerificationRequestsTeachingStaffSubject from './SchoolVerificationRequestsTeachingStaffSubject';
import '../VerificationRequest/SchoolVerificationRequestsTeachingStaff.css'
import { collection, doc, onSnapshot } from 'firebase/firestore';
import dB from '../../firebase';

function SchoolVerificationRequestsTeachingStaff(props) {
    const {
        subjectName, staffTeachMedium, staffTeachClassNum, staffTeachSection, staffTeacherMedClsSec,
        schoolId, schoolShortName, schoolFullName, schoolAffiliation,
    } = props

    const [recievedRequest, setrecievedRequest] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolShortName && staffTeacherMedClsSec) {
                onSnapshot(collection(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", staffTeacherMedClsSec, "TEACHERS", "VERIFICATION_REQUESTS", subjectName), (snap) => {
                    setrecievedRequest(snap.docs.map((subDoc) => ({
                        subName: subjectName,
                        teacherId: subDoc.id,
                        teacherData: subDoc.data(),
                    })))
                })
            }
        }
        return () => { aaa = false }
    }, [schoolId, schoolShortName, staffTeacherMedClsSec, subjectName])

    const [verifiedMemberData, setverifiedMemberData] = useState({
        teachersId: '', teachersSubjectId: '', teachersPathCollection: '', teachersName: '', teachersDP: ''
    })
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolShortName && staffTeacherMedClsSec && subjectName) {
                onSnapshot(doc(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", staffTeacherMedClsSec, "TEACHERS", "VERIFIED_MEMBERS", subjectName, "VERIFIED_MEMBERS_DETAILS"), (snap) => {
                    if (snap.exists()) {
                        setverifiedMemberData(prev => {
                            return {
                                ...prev,
                                teachersId: snap.data()?.TEACHERS_ID,
                                teachersSubjectId: snap.data()?.TEACHERS_SUBJECT_ID
                            }
                        })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [schoolId, schoolShortName, staffTeacherMedClsSec, subjectName])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (verifiedMemberData.teachersId) {
                onSnapshot(doc(dB, "USERS", verifiedMemberData.teachersId), (snap) => {
                    if (snap.exists()) {
                        setverifiedMemberData(prev => {
                            return {
                                ...prev,
                                teachersPathCollection: snap.data()?.USER_NAME,
                                teachersName: snap.data()?.USER_NAME,
                                teachersDP: snap.data()?.USER_DISPLAY_PIC
                            }
                        })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [verifiedMemberData.teachersId])

    return (
        <div className='SchoolVerificationRequestsTeachingStaff'  >
            <hr />
            <div className='schoolverificationrequests__containerRightTeachingStaffData'  >
                <p>{subjectName}</p>
                <>
                    {
                        recievedRequest?.length > 0 && verifiedMemberData.teachersId ? (
                            <div className='subDataRowBox__memberVerAndreq'>
                                <div className='subDataRowBox'>
                                    {
                                        recievedRequest?.map((data, i) => {
                                            return (
                                                <SchoolVerificationRequestsTeachingStaffSubject
                                                    key={i}
                                                    subTeacherId={data?.teacherId}
                                                    subTeacherSubjectId={data?.teacherData?.TEACHERS_SUBJECT_ID}
                                                    schoolId={schoolId}
                                                    verfiedTeacherId={verifiedMemberData.teachersId}
                                                    verfiedTeachersSubjectId={verifiedMemberData.teachersSubjectId}
                                                    verfiedTeacherPathCollection={verifiedMemberData.teachersPathCollection}
                                                    schoolShortName={schoolShortName}
                                                    staffTeacherMedClsSec={staffTeacherMedClsSec}
                                                    subjectName={data?.subName}
                                                />
                                            )
                                        })
                                    }
                                </div>
                                <div className='memberVerAndreq' >
                                    <h3>You have verified a teacher for subject of {subjectName}. Please cancel all these requests or check if you have verified a wrong member.</h3>
                                </div>
                            </div>
                        ) : (
                            <>
                                {
                                    recievedRequest?.length > 0 ? (
                                        <div className='subDataRowBox' >
                                            {
                                                recievedRequest?.map((data, i) => {
                                                    return (
                                                        <SchoolVerificationRequestsTeachingStaffSubject
                                                            key={i}
                                                            subTeacherId={data?.teacherId}
                                                            subTeacherSubjectId={data?.teacherData?.TEACHERS_SUBJECT_ID}
                                                            schoolId={schoolId}
                                                            verfiedTeacherId={verifiedMemberData.teachersId}
                                                            verfiedTeacherPathCollection={verifiedMemberData.teachersPathCollection}
                                                            verfiedTeachersSubjectId={verifiedMemberData.teachersSubjectId}
                                                            schoolShortName={schoolShortName}
                                                            staffTeacherMedClsSec={staffTeacherMedClsSec}
                                                            subjectName={data?.subName}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    ) : (
                                        <>
                                            {
                                                verifiedMemberData.teachersId ? (
                                                    <>
                                                        <div className='SchoolVerificationRequestsTeachingStaff__verifiedMember'  >
                                                            <h3>You have verified a teacher for subject of {subjectName}</h3>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className='SchoolVerificationRequestsTeachingStaff__noRequest' >
                                                            <h3>No Request at the moment for subject of {subjectName}</h3>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }

                            </>
                        )

                    }
                </>
            </div>
        </div>
    )
}

export default SchoolVerificationRequestsTeachingStaff