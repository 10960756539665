import React, { useEffect, useState } from 'react'
import '../Pages/SchoolAccountProfile.css'
import Login from './Login'
import Header from '../Components/Header/Header'
import SchoolAccountProfile from './SchoolAccountProfile'
import SchoolProfileSidebar from '../Components/Social/SchoolProfileSidebar'
import HomePageFeed from '../Components/Social/HomePageFeed'
import SchoolAbout from '../Components/Social/SchoolAbout'
import UserPhotos from '../Components/Social/UserPhotos'
import SchoolFriendTop from '../Components/Social/SchoolFriendTop'
// import SchoolEvents from '../Components/SchoolEvents'

import { useParams } from 'react-router-dom'
import dB, { auth } from '../firebase'
import { doc, onSnapshot } from 'firebase/firestore'
import { onAuthStateChanged } from 'firebase/auth'

import PropTypes from 'prop-types';
import { AppBar, Box, Tab, Tabs, Typography } from '@mui/material'
import SchoolEvents from '../Components/UpcomingEvents/SchoolEvents'


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function SchoolFriendProfile() {

    const { schoolFriendHandleId } = useParams();

    const [userData, setuserData] = useState({ userId: '', userCurrentStatus: '', userPathCollection: '', userFullName: '' })
    useEffect(() => {
        const unSubscribe = onAuthStateChanged(auth, (userCredentials) => {
            if (userCredentials) {
                var uid = userCredentials.uid;

                onSnapshot(doc(dB, "USERS", uid), (userDoc) => {
                    if (userDoc.exists()) {
                        setuserData(prev => {
                            return {
                                ...prev,
                                userId: uid,
                                userCurrentStatus: userDoc.data()?.USER_CURRENT_STATUS,
                                userPathCollection: userDoc.data()?.USER_NAME,
                            }
                        })
                    } else {
                        onSnapshot(doc(dB, "SCHOOLS", uid), (schlDoc) => {
                            if (schlDoc.exists()) {
                                setuserData(prev => {
                                    return {
                                        ...prev,
                                        userId: uid,
                                        userCurrentStatus: schlDoc.data()?.USER_STATUS,
                                        userPathCollection: schlDoc.data()?.SCHOOL_NAME,
                                        userFullName: schlDoc.data()?.SCHOOL_FULL_NAME
                                    }
                                })
                            }
                        })
                    }
                })
            }
        });
        return () => { unSubscribe(); }
    }, [])

    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userCurrentStatus !== 'SCHOOL' && userData.userId && userData.userPathCollection) {
                onSnapshot(doc(dB, "USERS", userData.userId, userData.userPathCollection, "REGISTRATION_INFO"), (regDoc) => {
                    if (regDoc.exists()) {
                        setuserData(prev => { return { ...prev, userFullName: regDoc.data()?.USER_NAME } })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userData.userCurrentStatus, userData.userId, userData.userPathCollection])

    const [schoolInfoData, setschoolInfoData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolFriendHandleId) {
                onSnapshot(doc(dB, "SCHOOLS", schoolFriendHandleId), (idDoc) => {
                    if (idDoc.exists()) { setschoolInfoData(idDoc.data()) }
                })
            }
        }
        return () => { aaa = false }
    }, [schoolFriendHandleId])


    const [schoolDetails, setschoolDetails] = useState(null)
    const [schoolRegInfo, setschoolRegInfo] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolInfoData && schoolFriendHandleId) {
                onSnapshot(doc(dB, "SCHOOLS", schoolFriendHandleId, schoolInfoData?.SCHOOL_NAME, "SCHOOL_DETAILS"), (schlDetDOc) => {
                    if (schlDetDOc.exists()) { setschoolDetails(schlDetDOc.data()) }
                })
                onSnapshot(doc(dB, "SCHOOLS", schoolFriendHandleId, schoolInfoData?.SCHOOL_NAME, "REGISTRATION_INFO"), (schlregDOc) => {
                    if (schlregDOc.exists()) { setschoolRegInfo(schlregDOc.data()) }
                })
            }

        }
        return () => { aaa = false }
    }, [schoolFriendHandleId, schoolInfoData])

    // const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => { event.preventDefault(); setValue(newValue); };


    return (
        <>
            {
                userData.userId === '' ? (
                    <Login />
                ) : (
                    <>
                        {
                            userData.userId === schoolFriendHandleId ? (
                                <SchoolAccountProfile />
                            ) : (
                                <div className='schoolaccount'>
                                    <Header />
                                    <div className='schoolaccount__body'>
                                        <div className='schoolaccount__bodyTop'>
                                            {/* image and info */}
                                            <SchoolFriendTop
                                                schoolId={schoolFriendHandleId}
                                                schoolPathCollection={schoolInfoData?.SCHOOL_NAME}
                                                schoolFullName={schoolInfoData?.SCHOOL_FULL_NAME}
                                                schoolAffiliation={schoolRegInfo?.SCHOOL_AFFILIATION}
                                                friendDP={schoolInfoData?.USER_DISPLAY_PIC}
                                                userId={userData.userId}
                                                userFullName={userData.userFullName}
                                                userPathCollection={userData.userPathCollection}
                                                userCurrentStatus={userData.userCurrentStatus}
                                            />
                                        </div>
                                        <div className='schoolaccount__bodyBottom'>
                                            <Box id='schoolprofile__bodyTopNavbarAppBar' sx={{ flexGrow: 1, backgroundColor: 'var(--connected-background)', }}>
                                                <AppBar color='inherit' position="static">
                                                    <Tabs indicatorColor='primary' textColor='primary' className='tab' value={value} onChange={handleChange} centered>
                                                        <Tab sx={{ fontSize: '16px', fontWeight: '600', width: "150px", color: 'black' }} label="Timeline" {...a11yProps(0)} />
                                                        <Tab sx={{ fontSize: '16px', fontWeight: '600', width: "150px", color: 'black' }} label="About" {...a11yProps(1)} />
                                                        <Tab sx={{ fontSize: '16px', fontWeight: '600', width: "150px", color: 'black' }} label="Photos" {...a11yProps(2)} />
                                                        <Tab sx={{ fontSize: '16px', fontWeight: '600', width: "150px", color: 'black' }} label={`Events`} {...a11yProps(3)} />
                                                    </Tabs>
                                                </AppBar>
                                                <TabPanel value={value} index={0}>
                                                    <div id="feed" className='schoolprofile__bodyBottom'>
                                                        <div className='schoolprofile__bodyBottomLeft'>
                                                            {/* Sidebar -> Intro /about */}
                                                            <SchoolProfileSidebar
                                                                schoolPhone={schoolRegInfo?.SCHOOL_PHONE_NO}
                                                                schoolAbout={schoolDetails?.SCHOOL_ABOUT}
                                                                schoolEstablished={schoolDetails?.SCHOOL_ESTABLISHMENT}
                                                                schoolRestAddres={schoolDetails?.SCHOOL_REST_ADDRESS}
                                                                schoolAffiliation={schoolRegInfo?.SCHOOL_AFFILIATION}
                                                                schoolMission={schoolDetails?.SCHOOL_MISSION}
                                                                schoolVision={schoolDetails?.SCHOOL_VISION}
                                                            />
                                                        </div>
                                                        <div className='schoolprofile__bodyBottomRight'>
                                                            {/* Feed -> Posts */}
                                                            <HomePageFeed
                                                                userId={userData.userId}
                                                                userFullName={userData.userFullName}
                                                                userPathCollection={userData.userPathCollection}
                                                                userCurrentStatus={userData.userCurrentStatus}
                                                                userDP={userData?.USER_DISPLAY_PIC}

                                                                friendId={schoolFriendHandleId}
                                                                friendFullName={schoolInfoData?.SCHOOL_FULL_NAME}
                                                                friendPathCollection={schoolInfoData?.SCHOOL_NAME}
                                                                friendDP={schoolInfoData?.USER_DISPLAY_PIC}
                                                                friendCurrentStatus={'SCHOOL'}

                                                                MainHomePage={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel value={value} index={1}>
                                                    <div id="about" className='schoolprofile__bodyBottom'>
                                                        <SchoolAbout
                                                            schoolPhone={schoolRegInfo?.SCHOOL_PHONE_NO}
                                                            schoolEmail={schoolInfoData?.SCHOOL_EMAIL}
                                                            schoolAbout={schoolDetails?.SCHOOL_ABOUT}
                                                            schoolAchievements={schoolDetails?.SCHOOL_ACHIEVEMENTS}
                                                            schoolEstablished={schoolDetails?.SCHOOL_ESTABLISHMENT}
                                                            schoolfacilities={schoolDetails?.SCHOOL_FACILITIES}
                                                            schoolMission={schoolDetails?.SCHOOL_MISSION}
                                                            schoolRestAddres={schoolDetails?.SCHOOL_REST_ADDRESS}
                                                            schoolVision={schoolDetails?.SCHOOL_VISION}
                                                            schoolId={schoolFriendHandleId}
                                                            schoolPathCollection={schoolInfoData?.SCHOOL_NAME}
                                                        />
                                                    </div>
                                                </TabPanel>
                                                <TabPanel value={value} index={2}>
                                                    <div id="friends" className='schoolprofile__bodyBottom'>
                                                        <UserPhotos
                                                            userId={userData.userId}
                                                            userPathCollection={userData.userPathCollection}
                                                            userCurrentStatus={userData.userCurrentStatus}

                                                            friendId={schoolFriendHandleId}
                                                            friendCurrentStatus='SCHOOL'
                                                            friendPathCollection={schoolInfoData?.SCHOOL_NAME}
                                                        />
                                                    </div>
                                                </TabPanel>
                                                <TabPanel value={value} index={3}>
                                                    <div id="friends" className='schoolprofile__bodyBottom'>
                                                        <SchoolEvents
                                                            schoolId={schoolFriendHandleId}
                                                            schoolPathCollection={schoolInfoData?.SCHOOL_NAME}
                                                            SchoolFriendProfile={true}
                                                        />
                                                    </div>
                                                </TabPanel>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </>
                )
            }

        </>
    )
}

export default SchoolFriendProfile
