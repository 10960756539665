import React, { useEffect, useState } from 'react'
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec'
import '../Timetable/SchoolTimeTableSchoolClsNSubUpload.css'

import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

import { LoadingButton } from '@mui/lab';
import { addDoc, collection, doc, onSnapshot, serverTimestamp, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import dB, { storage } from '../../firebase';
import { Box, Modal } from '@mui/material';

const imageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
};

function SchoolTimeTableSchoolClsNSubUpload(props) {
    const { userId, userPathCollection, userCurrentStatus, userSchoolId,
        userSchoolPathCollection, isPrinciVerified, classPath, medium, classNum, section, TTtimeStamp, TTImage
    } = props

    const [open, setOpen] = React.useState(false);
    // const [openedImg, setopenedImg] = useState('')
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const [imgData, setImgData] = useState(null);
    const [picture, setPicture] = useState(null);
    const [sentText, setsentText] = useState(false)
    const onChangePicture = async (e) => {
        // setImgData(null)
        // setPicture(null)
        setsentText(false)
        if (e.target.files[0]) {
            setPicture(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);

        }
    };

    const [mediumClsSec, setmediumClsSec] = useState('')
    useEffect(() => {
        setmediumClsSec('')
        var aaa = true
        if (aaa === true) {
            var abc = returnMedClsSec(`${medium} Medium`, classNum, section)
            setmediumClsSec(abc)
        }
        return () => { aaa = false }
    }, [classNum, medium, section])

    const [btnLoading, setbtnLoading] = useState(false)
    const handleClickUploadTimetable = () => {
        setbtnLoading(true)
        const imageRef = ref(storage, `SCHOOLS/${userId}/Timetable/${mediumClsSec}`);
        const metadata = { contentType: "image/jpeg", };

        uploadBytes(imageRef, picture, metadata)
            .then(async (snapshot) => {
                const downloadURL = await getDownloadURL(imageRef);
                setDoc(doc(dB, "SCHOOLS", userId, userPathCollection, "CLASSES_DETAILS", "CLASSES", mediumClsSec, "TIMETABLE", "TIMETABLE_IMAGE"), {
                    TIMETABLE_IMAGE: downloadURL,
                    TIMESTAMP: serverTimestamp(),
                }).then(() => {
                    setPicture(null)
                    setImgData(null)
                    setbtnLoading(false)
                    setsentText(true)
                }).catch(err => { alert(err) })
            }).catch(err => { alert(err) })
    }

    return (
        <div className='SchoolTimeTableSchoolClsNSubUpload'>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={imageStyle}>
                    <div className='imageBig'>
                        <img src={TTImage} alt={userId} />
                    </div>
                </Box>
            </Modal>

            {
                TTImage && (
                    <div className='recentlyUploaded'>
                        <h4>Updated On : {TTtimeStamp?.toDate().toDateString()}</h4>
                        <img src={TTImage} alt={TTImage} onClick={handleOpen} />
                    </div>
                )
            }

            {
                userCurrentStatus === "SCHOOL" && (
                    <div className='updateNew'>
                        <h4>Update New timetable</h4>
                        <div className='uploadBtnInput'>
                            {/* <button>Upload</button> */}
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                            >
                                <input
                                    hidden
                                    type="file"
                                    id="postImage"
                                    name="postImage"
                                    accept=".jpg,.jpeg,.png,"
                                    // multiple={userCurrentStatus === 'SCHOOL' ? true : false}
                                    onChange={onChangePicture}
                                />
                                <PhotoCamera sx={{ fontSize: "30px", color: 'lightblue' }} />
                                <h3 style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "grey"
                                }}>Add Image (Optional)</h3>
                            </IconButton>
                            <div className='uploadBtnImg'>
                                {imgData && <img alt={imgData} src={imgData} />}
                                <LoadingButton
                                    variant='outlined'
                                    onClick={handleClickUploadTimetable}
                                    loading={btnLoading}
                                    disabled={imgData ? false : true}
                                >
                                    Update
                                </LoadingButton>
                                {
                                    sentText && (<h3>Timetable Image Uploaded Successfully..</h3>)
                                }

                            </div>
                        </div>
                    </div>
                )
            }


            {/* no timetable uploaded. plz upload one */}

            {/* timetable uploaded. update the new one */}
        </div>
    )
}

export default SchoolTimeTableSchoolClsNSubUpload