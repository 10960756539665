

export const returnSection = (med, cls, returnedClsList) => {

    var secObj = {
        SectionA: false,
        SectionB: false,
        SectionC: false,
        SectionD: false,
        SectionE: false,
    }

    if (med === 'English Medium' && cls === 'Pre Nursery') {
        if (returnedClsList.includes('EngPreNurserySecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('EngPreNurserySecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('EngPreNurserySecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('EngPreNurserySecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('EngPreNurserySecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'English Medium' && cls === 'Nursery') {
        if (returnedClsList.includes('EngNurserySecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('EngNurserySecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('EngNurserySecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('EngNurserySecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('EngNurserySecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'English Medium' && cls === 'Kindergarten 1(KG-1)') {
        if (returnedClsList.includes('EngKG1SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('EngKG1SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('EngKG1SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('EngKG1SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('EngKG1SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'English Medium' && cls === 'Kindergarten 2(KG-2)') {
        if (returnedClsList.includes('EngKG2SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('EngKG2SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('EngKG2SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('EngKG2SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('EngKG2SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'English Medium' && cls === 'Class 1') {
        if (returnedClsList.includes('EngClass1SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('EngClass1SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('EngClass1SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('EngClass1SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('EngClass1SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'English Medium' && cls === 'Class 2') {
        if (returnedClsList.includes('EngClass2SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('EngClass2SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('EngClass2SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('EngClass2SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('EngClass2SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'English Medium' && cls === 'Class 3') {
        if (returnedClsList.includes('EngClass3SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('EngClass3SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('EngClass3SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('EngClass3SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('EngClass3SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'English Medium' && cls === 'Class 4') {
        if (returnedClsList.includes('EngClass4SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('EngClass4SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('EngClass4SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('EngClass4SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('EngClass4SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'English Medium' && cls === 'Class 5') {
        if (returnedClsList.includes('EngClass5SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('EngClass5SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('EngClass5SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('EngClass5SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('EngClass5SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'English Medium' && cls === 'Class 6') {
        if (returnedClsList.includes('EngClass6SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('EngClass6SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('EngClass6SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('EngClass6SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('EngClass6SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'English Medium' && cls === 'Class 7') {
        if (returnedClsList.includes('EngClass7SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('EngClass7SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('EngClass7SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('EngClass7SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('EngClass7SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'English Medium' && cls === 'Class 8') {
        if (returnedClsList.includes('EngClass8SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('EngClass8SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('EngClass8SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('EngClass8SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('EngClass8SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'English Medium' && cls === 'Class 9') {
        if (returnedClsList.includes('EngClass9SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('EngClass9SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('EngClass9SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('EngClass9SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('EngClass9SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'English Medium' && cls === 'Class 10') {
        if (returnedClsList.includes('EngClass10SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('EngClass10SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('EngClass10SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('EngClass10SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('EngClass10SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'English Medium' && cls === 'Class 11(Science)') {
        if (returnedClsList.includes('EngClass11ScienceSecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('EngClass11ScienceSecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('EngClass11ScienceSecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('EngClass11ScienceSecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('EngClass11ScienceSecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'English Medium' && cls === 'Class 11(Commerce)') {
        if (returnedClsList.includes('EngClass11CommerceSecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('EngClass11CommerceSecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('EngClass11CommerceSecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('EngClass11CommerceSecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('EngClass11CommerceSecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'English Medium' && cls === 'Class 12(Science)') {
        if (returnedClsList.includes('EngClass12ScienceSecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('EngClass12ScienceSecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('EngClass12ScienceSecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('EngClass12ScienceSecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('EngClass12ScienceSecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'English Medium' && cls === 'Class 12(Commerce)') {
        if (returnedClsList.includes('EngClass12CommerceSecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('EngClass12CommerceSecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('EngClass12CommerceSecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('EngClass12CommerceSecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('EngClass12CommerceSecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }




    if (med === 'Marathi Medium' && cls === 'Pre Nursery') {
        if (returnedClsList.includes('MarPreNurserySecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('MarPreNurserySecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('MarPreNurserySecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('MarPreNurserySecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('MarPreNurserySecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'Marathi Medium' && cls === 'Nursery') {
        if (returnedClsList.includes('MarNurserySecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('MarNurserySecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('MarNurserySecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('MarNurserySecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('MarNurserySecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'Marathi Medium' && cls === 'Kindergarten 1(KG-1)') {
        if (returnedClsList.includes('MarKG1SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('MarKG1SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('MarKG1SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('MarKG1SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('MarKG1SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'Marathi Medium' && cls === 'Kindergarten 2(KG-2)') {
        if (returnedClsList.includes('MarKG2SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('MarKG2SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('MarKG2SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('MarKG2SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('MarKG2SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }





    if (med === 'Marathi Medium' && cls === 'Class 1') {
        if (returnedClsList.includes('MarClass1SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('MarClass1SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('MarClass1SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('MarClass1SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('MarClass1SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'Marathi Medium' && cls === 'Class 2') {
        if (returnedClsList.includes('MarClass2SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('MarClass2SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('MarClass2SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('MarClass2SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('MarClass2SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'Marathi Medium' && cls === 'Class 3') {
        if (returnedClsList.includes('MarClass3SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('MarClass3SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('MarClass3SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('MarClass3SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('MarClass3SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'Marathi Medium' && cls === 'Class 4') {
        if (returnedClsList.includes('MarClass4SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('MarClass4SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('MarClass4SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('MarClass4SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('MarClass4SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'Marathi Medium' && cls === 'Class 5') {
        if (returnedClsList.includes('MarClass5SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('MarClass5SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('MarClass5SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('MarClass5SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('MarClass5SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'Marathi Medium' && cls === 'Class 6') {
        if (returnedClsList.includes('MarClass6SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('MarClass6SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('MarClass6SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('MarClass6SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('MarClass6SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'Marathi Medium' && cls === 'Class 7') {
        if (returnedClsList.includes('MarClass7SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('MarClass7SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('MarClass7SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('MarClass7SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('MarClass7SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'Marathi Medium' && cls === 'Class 8') {
        if (returnedClsList.includes('MarClass8SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('MarClass8SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('MarClass8SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('MarClass8SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('MarClass8SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'Marathi Medium' && cls === 'Class 9') {
        if (returnedClsList.includes('MarClass9SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('MarClass9SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('MarClass9SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('MarClass9SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('MarClass9SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'Marathi Medium' && cls === 'Class 10') {
        if (returnedClsList.includes('MarClass10SecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('MarClass10SecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('MarClass10SecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('MarClass10SecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('MarClass10SecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'Marathi Medium' && cls === 'Class 11(Arts)') {
        if (returnedClsList.includes('MarClass11ArtsSecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('MarClass11ArtsSecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('MarClass11ArtsSecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('MarClass11ArtsSecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('MarClass11ArtsSecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'Marathi Medium' && cls === 'Class 11(Commerce)') {
        if (returnedClsList.includes('MarClass11CommerceSecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('MarClass11CommerceSecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('MarClass11CommerceSecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('MarClass11CommerceSecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('MarClass11CommerceSecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'Marathi Medium' && cls === 'Class 12(Arts)') {
        if (returnedClsList.includes('MarClass12ArtsSecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('MarClass12ArtsSecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('MarClass12ArtsSecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('MarClass12ArtsSecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('MarClass12ArtsSecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }
    if (med === 'Marathi Medium' && cls === 'Class 12(Commerce)') {
        if (returnedClsList.includes('MarClass12CommerceSecA')) {
            secObj.SectionA = true
            if (returnedClsList.includes('MarClass12CommerceSecB')) {
                secObj.SectionB = true
                if (returnedClsList.includes('MarClass12CommerceSecC')) {
                    secObj.SectionC = true
                    if (returnedClsList.includes('MarClass12CommerceSecD')) {
                        secObj.SectionD = true
                        if (returnedClsList.includes('MarClass12CommerceSecE')) {
                            secObj.SectionE = true
                        }
                    }
                }
            }
        }
    }

    return secObj
}

// export default FuncMedium