import React from 'react'
import '../ProjectsHomeworks/HomeWorksParents.css'
import '../ComplainsAndFeedback/Complains.css'
import { useState } from 'react'
import { useEffect } from 'react'
import { addDoc, collection, doc, onSnapshot, serverTimestamp, setDoc } from 'firebase/firestore'
import dB from '../../firebase'
import { Accordion, AccordionDetails, AccordionSummary, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ComplaintsParentsAll from './ComplaintsParentsAll'

function ComplaintsTeachers(props) {

    const { userId, userPathCollection, userCurrentStatus, userSchoolId } = props

    const [teacherSchoolData, setteacherSchoolData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userSchoolId) {
                onSnapshot(doc(dB, "SCHOOLS", userSchoolId), (snap) => {
                    if (snap.exists()) {
                        setteacherSchoolData(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userSchoolId])

    const [btnLoading, setbtnLoading] = useState(false)

    const [suggestion, setsuggestion] = useState('')
    const [complaint, setcomplaint] = useState('')
    const handleSendSuggestion = (e) => {
        e.preventDefault();

        if (suggestion) {
            setbtnLoading(true)

            addDoc(collection(dB, "SCHOOLS", userSchoolId, teacherSchoolData?.SCHOOL_NAME, "COMPLAINTS_AND_SUGGESTIONS", "SUGGESTIONS"), {
                TIMESTAMP: serverTimestamp(),
                SUGGESTION_TEXT: suggestion,
                SUGGESTION_FROM: userId,
                USER_PATH_COLLECTION: userPathCollection,
                USER_STATUS: userCurrentStatus,
                CHILD_ID: null
            }).then((docRef) => {
                setDoc(doc(dB, "USERS", userId, userPathCollection, "COMPLAINTS_AND_SUGGESTIONS", "SUGGESTIONS", docRef.id), {
                    TIMESTAMP: serverTimestamp(),
                    SUGGESTION_TEXT: suggestion,
                    CHILD_ID: null,
                    SCHOOL_ID: userSchoolId,
                    SCHOOL_PATH_COLLECTION: teacherSchoolData?.SCHOOL_NAME
                }).then(() => {
                    setbtnLoading(false)
                    setsuggestion('')
                }).catch((err) => {
                    setbtnLoading(false)
                    setsuggestion('')
                    alert(err)
                })

            }).catch((err) => {
                setbtnLoading(false)
                setsuggestion('')
                alert(err)
            })
        }
    }
    const handleSendComplaint = (e) => {
        e.preventDefault();

        if (complaint) {
            setbtnLoading(true)

            addDoc(collection(dB, "SCHOOLS", userSchoolId, teacherSchoolData?.SCHOOL_NAME, "COMPLAINTS_AND_SUGGESTIONS", "COMPLAINTS"), {
                TIMESTAMP: serverTimestamp(),
                COMPLAINT_TEXT: complaint,
                COMPLAINT_FROM: userId,
                USER_PATH_COLLECTION: userPathCollection,
                USER_STATUS: userCurrentStatus,
                CHILD_ID: null
            }).then((docRef) => {
                setDoc(doc(dB, "USERS", userId, userPathCollection, "COMPLAINTS_AND_SUGGESTIONS", "COMPLAINTS", docRef.id), {
                    TIMESTAMP: serverTimestamp(),
                    COMPLAINT_TEXT: complaint,
                    CHILD_ID: null,
                    SCHOOL_ID: userSchoolId,
                    SCHOOL_PATH_COLLECTION: teacherSchoolData?.SCHOOL_NAME
                }).then(() => {
                    setbtnLoading(false)
                    setcomplaint('')
                }).catch((err) => {
                    setbtnLoading(false)
                    setcomplaint('')
                    alert(err)
                })
            }).catch((err) => {
                setbtnLoading(false)
                setcomplaint('')
                alert(err)
            })
        }
    }

    const [allSuggestions, setallSuggestions] = useState([])
    useEffect(() => {
        if (userId && userPathCollection) {
            onSnapshot(collection(dB, "USERS", userId, userPathCollection, "COMPLAINTS_AND_SUGGESTIONS", "SUGGESTIONS"), (snap) => {
                setallSuggestions(snap.docs.map((sugDoc) => ({
                    suggestionId: sugDoc.id,
                    suggestionData: sugDoc.data()
                })))
            })
        }
    }, [userId, userPathCollection])

    const [allComplaints, setallComplaints] = useState([])
    useEffect(() => {
        if (userId && userPathCollection) {
            onSnapshot(collection(dB, "USERS", userId, userPathCollection, "COMPLAINTS_AND_SUGGESTIONS", "COMPLAINTS"), (snap) => {
                setallComplaints(snap.docs.map((compDoc) => ({
                    complaintId: compDoc.id,
                    complaintData: compDoc.data()
                })))
            })
        }
    }, [userId, userPathCollection])

    return (
        <div className='homeworkteachers'>
            <div className='homeworkteachers__top'>
                <h3>{teacherSchoolData?.SCHOOL_FULL_NAME}</h3>
                <h4>Complaints & Feedback</h4>
                <hr />
            </div>
            <div className='complaintAndFeedback'>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className='Complaintitle'>Complaint (if any)</p>

                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='complainBox'>
                            {/* complaint message */}

                            <TextField
                                id="outlined-multiline-static"
                                label="Write your complaint here."
                                multiline
                                rows={4}
                                value={complaint}
                                onChange={(e) => { e.preventDefault(); setcomplaint(e.target.value) }}
                            // placeholder="Write your complaint here."
                            />
                            <LoadingButton
                                loading={btnLoading}
                                onClick={handleSendComplaint}
                                disabled={btnLoading}
                            >
                                Submit Complaint
                            </LoadingButton>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className='complaintAndFeedback'>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className='Complaintitle'>Suggestions (if any)</p>

                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='complainBox'>
                            {/* complaint message */}

                            <TextField
                                id="outlined-multiline-static"
                                label="Write your suggestions here."
                                multiline
                                rows={4}
                                value={suggestion}
                                onChange={(e) => { e.preventDefault(); setsuggestion(e.target.value) }}
                            // placeholder="Write your suggestions here."
                            />
                            <LoadingButton
                                loading={btnLoading}
                                onClick={handleSendSuggestion}
                                disabled={btnLoading}
                            >
                                Submit Suggestion
                            </LoadingButton>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className='complaintAndFeedback'>
                <ComplaintsParentsAll
                    allSuggestions={allSuggestions}
                    allComplaints={allComplaints}
                />
            </div>
        </div>
    )
}

export default ComplaintsTeachers