import React, { useEffect, useState } from 'react'
import '../Sidebars/SidebarESchoolingStatusShowParent.css'
import { doc, onSnapshot, updateDoc, writeBatch } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useHistory } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, IconButton, Modal } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PhotoCamera } from '@mui/icons-material';
import dB, { storage } from '../../firebase';
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function SidebarESchoolingStatusShowParentChild(props) {
    const { childsId, childName, isChildVerified, childMedium, childSchoolId, childClass,
        childSection, userId, userPathCollection, userStatuses, parentsChildIdList, userCurrentStatus, childPic } = props

    const history = useHistory()

    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (childPic) {
                setImgData(childPic)
            }
        }
        return () => { aaa = false }
    }, [childPic])

    const [schoolData, setschoolData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (childSchoolId) {
                onSnapshot(doc(dB, "SCHOOLS", childSchoolId), (snap) => {
                    if (snap.exists()) {
                        setschoolData(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [childSchoolId])

    var batch = writeBatch(dB)
    const [medClsSec, setmedClsSec] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var abc = returnMedClsSec(childMedium, childClass, childSection)
            setmedClsSec(abc)

        }
        return () => { aaa = false }
    }, [childClass, childMedium, childSection])

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = (e) => { setOpenModal(true); };
    const handleCloseModal = (e) => { setOpenModal(false); };

    const [btnLoading, setbtnLoading] = useState(false)
    const handleClickRemoveChild = async (e) => {
        e.preventDefault()
        setbtnLoading(true)
        setdisabledBtn(prev => { return { ...prev, btnRemove: true } })

        await batch.delete(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", childsId));

        if (parentsChildIdList.length === 1) {
            batch.delete(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA"))
            batch.delete(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "VERIFICATION_DETAILS"))
            if (userStatuses.length === 1) {
                await batch.update(doc(dB, "USERS", userId), {
                    USER_CURRENT_STATUS: '',
                });
            } else {
                await batch.update(doc(dB, "USERS", userId), {
                    USER_CURRENT_STATUS: userStatuses[0] === userCurrentStatus ? userStatuses[1] : userStatuses[0],
                });
            }

        } else {
            await batch.update(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA"), {
                CURRENT_SELECTED_CHILD: parentsChildIdList[0] === childsId ? parentsChildIdList[1] : parentsChildIdList[0]
            })
        }

        if (isChildVerified) {
            await batch.delete(doc(dB, "SCHOOLS", childSchoolId, schoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", medClsSec, "PARENTS", "VERIFIED_MEMBERS", "VERIFIED_MEMBERS_DATA", userId, "CHILDS_LIST", childsId));
        }
        else {
            await batch.delete(doc(dB, "SCHOOLS", childSchoolId, schoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", medClsSec, "PARENTS", "VERIFICATION_REQUESTS", "VERIFICATION_REQUESTS_DATA", userId, "CHILDS_LIST", childsId));
        }

        await batch.commit().then(() => {
            setdisabledBtn(prev => { return { ...prev, btnRemove: false } })
            // let pictureRef = storage.refFromURL(childPic);
            // pictureRef.delete().catch((err) => err.message);
            setbtnLoading(false)
            setOpenModal(false)
            if (parentsChildIdList.length === 1) {
                history.push('/')
            }
        }).catch(err => alert(err.message))
    }

    const [disabledBtn, setdisabledBtn] = useState({
        btnRemove: false, btnPicture: false
    })

    const [randomKey, setrandomKey] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var a = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
            setrandomKey(a)
        }
        return () => { aaa = false }
    }, [])
    var resizeImage = function (settings) {
        var file = settings.file;
        var maxSize = settings.maxSize;
        var reader = new FileReader();
        var image = new Image();
        var canvas = document.createElement('canvas');
        var dataURItoBlob = function (dataURI) {
            var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
                atob(dataURI.split(',')[1]) :
                unescape(dataURI.split(',')[1]);
            var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var max = bytes.length;
            var ia = new Uint8Array(max);
            for (var i = 0; i < max; i++)
                ia[i] = bytes.charCodeAt(i);
            return new Blob([ia], { type: mime });
        };
        var resize = function () {
            var width = image.width;
            var height = image.height;
            if (width > height) {
                if (width > maxSize) {
                    height *= maxSize / width;
                    width = maxSize;
                }
            } else {
                if (height > maxSize) {
                    width *= maxSize / height;
                    height = maxSize;
                }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            var dataUrl = canvas.toDataURL('image/jpeg');
            return dataURItoBlob(dataUrl);
        };
        return new Promise(function (ok, no) {
            if (!file.type.match(/image.*/)) {
                no(new Error("Not an image"));
                return;
            }
            reader.onload = function (readerEvent) {
                image.onload = function () { return ok(resize()); };
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        });
    };

    const [sentText, setsentText] = useState(false)
    const [progress, setprogress] = useState(0);
    const [imgData, setImgData] = useState(null);
    const [picture, setPicture] = useState(null);

    const onChangePicture = async (e) => {
        if (e.target.files[0]) {
            setPicture(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);

            const config = {
                file: e.target.files[0],
                maxSize: 500
            };
            const resizedImage = await resizeImage(config)
            setPicture(resizedImage);
        }
    };

    const handleUpdateDisplayPic = async (e) => {
        e.preventDefault()
        setbtnLoading(true)
        setdisabledBtn(prev => { return { ...prev, btnPicture: true } })

        const imageRef = ref(storage, `USERS/${userId}/Profile/${randomKey}`);
        const metadata = {
            contentType: "image/jpeg",
        };
        await uploadBytes(imageRef, picture, metadata)
            .then(async (snapshot) => {
                const downloadURL = await getDownloadURL(imageRef);
                await updateDoc(doc(dB, "USERS", userId, userPathCollection, 'E-SCHOOLING_INFO', 'IS_PARENT', 'IS_PARENT_DATA', 'CHILDS_INFO', childsId), {
                    CHILD_DISPLAY_PIC: downloadURL,
                }).then(() => {
                    setdisabledBtn(prev => { return { ...prev, btnPicture: false } })
                    setsentText(true)
                    setprogress(0);
                    var b = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
                    setrandomKey(b)
                    setbtnLoading(false)
                }).catch(err => alert(err))
            })
    }

    return (
        <Box sx={{ width: '800px', marginLeft: '50px', marginTop: '20px' }} className={`SidebarESchoolingStatusShowParentChild`} >
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className='accordianTop'>
                        {/* <Typography className={classes.heading}> */}
                        <div className='accordianTopName'>
                            <h3>Name : </h3>
                            <h4>{childName}</h4>
                        </div>
                        <div className='accordianTopClass'>
                            <h5>Class : </h5>
                            <p>{childClass}</p>
                        </div>
                        <h4 style={{ color: isChildVerified ? 'var(--connected-color)' : 'black' }}>{isChildVerified ? 'Verified By School' : 'Not Verified'}</h4>
                        {/* </Typography> */}
                        <Button onClick={handleOpenModal}>Remove</Button>
                        <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div className='deletePostModal'>
                                    <div className='deletePostModalText'>
                                        <h4 style={{ textAlign: 'center' }}>Do you really want to delete "{childName}"  ?</h4>
                                    </div>
                                    <div className='deletePostModalButtons'>
                                        <LoadingButton
                                            onClick={handleClickRemoveChild}
                                            variant='outlined'
                                            loading={btnLoading}
                                        >
                                            Yes
                                        </LoadingButton>
                                        {/* <Button disabled={disabledBtn.btnRemove} >Yes</Button> */}
                                        <Button onClick={handleCloseModal}>No</Button>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='AccordionDetails'>

                        <div className='editDisplayPic'>
                            <Avatar
                                alt={childName}
                                src={imgData}
                                sx={{ width: 110, height: 110, }}
                            // className={classes.large}
                            />
                            <div className='editUpdateButton imageButtons'>
                                {/* <input
                                    type="file"
                                    onChange={onChangePicture}
                                    accept='.jpg, .jpeg, .png'
                                /> */}
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    sx={{ marginRight: '80px' }}
                                >
                                    <input
                                        hidden
                                        type="file"
                                        id="postImage"
                                        name="postImage"
                                        accept=".jpg,.jpeg,.png,"
                                        // multiple={userCurrentStatus === 'SCHOOL' ? true : false}
                                        onChange={onChangePicture}
                                    />
                                    <PhotoCamera sx={{ fontSize: "30px", color: 'lightblue' }} />
                                    <h3 style={{
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        color: "grey"
                                    }}>Select Photo</h3>
                                </IconButton>
                                <LoadingButton
                                    onClick={handleUpdateDisplayPic}
                                    variant='outlined'
                                    loading={btnLoading}
                                >
                                    Update
                                </LoadingButton>
                                {/* <Button load disabled={disabledBtn.btnPicture} ></Button> */}

                            </div>
                            {/* <progress
                                className='imageUpload__progress'
                                value={progress}
                                max='100'
                            /> */}
                            {
                                sentText && (<h2>Profile Pic updated..</h2>)
                            }

                        </div>

                        {/* child name */}
                        <div className='AccordionDetailsData'>
                            <h4>Child Name</h4>
                            <h5>:</h5>
                            <p>{childName}</p>
                        </div>

                        {/* school name */}
                        <div className='AccordionDetailsData'>
                            <h4>Studying at</h4>
                            <h5>:</h5>
                            <p>{schoolData?.SCHOOL_FULL_NAME}</p>
                        </div>
                        {/* medium */}
                        <div className='AccordionDetailsData'>
                            <h4>Medium</h4>
                            <h5>:</h5>
                            <p>{childMedium}</p>
                        </div>
                        {/* class */}
                        <div className='AccordionDetailsData'>
                            <h4>Class</h4>
                            <h5>:</h5>
                            <p>{childClass}</p>
                        </div>
                        {/* section */}
                        <div className='AccordionDetailsData'>
                            <h4>Section</h4>
                            <h5>:</h5>
                            <p>{childSection}</p>
                        </div>
                        {/* isverified */}
                        <div className='AccordionDetailsData'>
                            <h4>verification status</h4>
                            <h5>:</h5>
                            <p>{isChildVerified ? 'Verified By School' : 'Not Verified'}</p>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export default SidebarESchoolingStatusShowParentChild