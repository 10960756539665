import React, { useEffect, useState } from 'react'
import '../VerificationRequest/SchoolVerificationRequestsPrincipal.css'
import { Link } from 'react-router-dom';
import { deleteDoc, doc, onSnapshot, serverTimestamp, writeBatch } from 'firebase/firestore';
import { Avatar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';

function SchoolVerificationRequestsPrincipal(props) {
    const { requestId, schoolId, schoolShortName, schoolFullName, schoolAffiliation } = props

    var batch = writeBatch(dB);

    const [userData, setuserData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (requestId) {
                onSnapshot(doc(dB, "USERS", requestId), (snap) => {
                    if (snap.exists()) {
                        setuserData(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [requestId])
    const [princiRegName, setprinciRegName] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (requestId && userData) {
                onSnapshot(doc(dB, "USERS", requestId, userData?.USER_NAME, "REGISTRATION_INFO"), (snap) => {
                    if (snap.exists()) {
                        setprinciRegName(snap.data()?.USER_NAME)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [requestId, userData])

    const [princiData, setprinciData] = useState({ princiId: '', princiPathCollection: '' })
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolShortName) {
                onSnapshot(doc(dB, "SCHOOLS", schoolId, schoolShortName, "PRINCIPAL_DETAILS", "VERIFIED_MEMBERS", "PRINCIPAL_DATA"), (snap) => {
                    if (snap.exists()) {
                        setprinciData(prev => { return { ...prev, princiId: snap.data()?.PRINCIPAL_ID } })
                    }
                })
            }
        }
        return () => { aaa = false }

    }, [schoolId, schoolShortName])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (princiData.princiId) {
                onSnapshot(doc(dB, "USERS", princiData.princiId), (snap) => {
                    if (snap.exists()) {
                        setprinciData(prev => { return { ...prev, princiPathCollection: snap.data()?.USER_NAME } })
                    }
                })
            }
        }
        return () => { aaa = false }

    }, [princiData.princiId])


    const [btnLoading, setbtnLoading] = useState(false)
    const handlePrinciConfirm = async (e) => {
        e.preventDefault();
        setbtnLoading(true)

        batch.delete(doc(dB, "SCHOOLS", schoolId, schoolShortName, "PRINCIPAL_DETAILS", "VERIFICATION_REQUESTS", requestId))

        batch.update(doc(dB, "USERS", requestId, userData?.USER_NAME, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), {
            VERIFIED_PRINCIPAL: true,
            VERIFIED_SCHOOL_ID: schoolId
        })

        if (princiData.princiId) {
            await batch.update(doc(dB, "USERS", princiData.princiId, princiData.princiPathCollection, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), {
                VERIFIED_PRINCIPAL: false,
            });
            await batch.update(doc(dB, "SCHOOLS", schoolId, schoolShortName, "PRINCIPAL_DETAILS", "VERIFIED_MEMBERS", "PRINCIPAL_DATA"), {
                PRINCIPAL_ID: requestId,
                TIMESTAMP: serverTimestamp()
            });
        } else {
            await batch.set(doc(dB, "SCHOOLS", schoolId, schoolShortName, "PRINCIPAL_DETAILS", "VERIFIED_MEMBERS", "PRINCIPAL_DATA"), {
                PRINCIPAL_ID: requestId,
                TIMESTAMP: serverTimestamp()
            });
        }

        await batch.commit().then(() => {
            setbtnLoading(false)
        }).catch(err => alert(err.message));


    }

    const handlePrinciCancel = async (e) => {
        e.preventDefault();
        setbtnLoading(true)
        deleteDoc(doc(dB, "SCHOOLS", schoolId, schoolShortName, "PRINCIPAL_DETAILS", "VERIFICATION_REQUESTS", requestId))
            .then(() => {
                setbtnLoading(false)
            }).catch(err => alert(err.message));
    }

    return (
        <div className='SchoolVerificationRequestsPrincipal'  >
            <div className='schoolverificationrequests__containerRightPrincipalData' >
                <div className='schoolverificationrequests__containerRightPrincipalDataName dataNameParent'>
                    <Link to={`/friendsprofile/${requestId}`} style={{ textDecoration: 'none', color: 'gray' }}>
                        <Avatar
                            alt={princiRegName}
                            src={userData?.USER_DISPLAY_PIC}
                            sx={{ width: 50, height: 50, }}
                        />
                    </Link>
                    <h3>
                        <Link to={`/friendsprofile/${requestId}`} style={{ textDecoration: 'none', color: 'black' }}>
                            {princiRegName}
                        </Link>
                    </h3>

                </div>
                <div className='schoolverificationrequests__containerRightPrincipalDataButtons'>
                    <LoadingButton
                        onClick={handlePrinciConfirm}
                        variant='outlined'
                        loading={btnLoading}
                    >
                        Confirm
                    </LoadingButton>
                    <LoadingButton
                        onClick={handlePrinciCancel}
                        variant='outlined'
                        loading={btnLoading}
                    >
                        Cancel
                    </LoadingButton>
                </div>
            </div>
        </div>
    )
}

export default SchoolVerificationRequestsPrincipal


