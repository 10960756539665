import React, { useEffect, useState } from 'react'
import '../Pages/FindToFollow.css'
import Login from './Login';
import Header from '../Components/Header/Header';
import FindToFollowAccounts from '../Components/FIndToFollow/FindToFollowAccounts';
// import dB, { auth } from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import dB, { auth } from '../firebase';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function FindToFollow() {

    const [userData, setuserData] = useState({ userId: '', userCurrentStatus: '', userPathCollection: '', userFullName: '' })
    useEffect(() => {
        const unSubscribe = onAuthStateChanged(auth, (userCredentials) => {
            if (userCredentials) {
                onSnapshot(doc(dB, "USERS", userCredentials.uid), (userDoc) => {
                    if (userDoc.exists()) {
                        setuserData(prev => {
                            return {
                                ...prev,
                                userId: userCredentials.uid,
                                userCurrentStatus: userDoc.data()?.USER_CURRENT_STATUS,
                                userPathCollection: userDoc.data()?.USER_NAME,
                                userFullName: userDoc.data()?.USER_FULL_NAME,
                            }
                        })
                    } else {
                        onSnapshot(doc(dB, "SCHOOLS", userCredentials.uid), (schlDoc) => {
                            if (schlDoc.exists()) {
                                setuserData(prev => {
                                    return {
                                        ...prev,
                                        userId: userCredentials.uid,
                                        userCurrentStatus: 'SCHOOL',
                                        userPathCollection: schlDoc.data()?.SCHOOL_NAME,
                                        userFullName: schlDoc.data()?.SCHOOL_FULL_NAME,
                                    }
                                })
                            }
                        })
                    }
                })
            }
        });
        return () => { unSubscribe(); }
    }, [])

    return (
        <>
            {
                userData.userId === '' ? (
                    <Login />
                ) : (
                    <div className='FindToFollow'>
                        <Header />
                        <div className='FindToFollow__Container'>
                            <FindToFollowAccounts
                                userId={userData.userId}
                                userPathCollection={userData.userPathCollection}
                                userCurrentStatus={userData.userCurrentStatus}
                                userFullName={userData.userFullName}
                            />
                        </div>
                    </div>
                )
            }
        </>

    )
}

export default FindToFollow
