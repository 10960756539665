import React from 'react'
import '../../Pages/HowToUse.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function HowToUseAddRem() {
    return (
        <>
            <div className='HowToUse'>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className='HowToUseSummary'>
                            <h3>How to Add a Subject?</h3>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='HowToUseDetails'>
                            <div className='HowToData'>
                                <h3>Add a Subject</h3>
                                <h4>If you found out that you have not added a subject of a particular class by mistake, then you can add that subject here.</h4>
                                <p><span>Step 1 : </span> Click on “Add/Remove” link on the left side of the homepage.</p>
                                <p><span>Step 2 : </span> Click on “Subject”.</p>
                                <p><span>Step 3 : </span> Click on “Add Subject”.</p>
                                <p><span>Step 4 : </span> Select Medium, Class, Section & Subject.</p>
                                <p><span>Step 5 : </span> Click on the “Add Subject” button.</p>
                                <p><span>Step 6 : </span> If subject added successfully then you will see a message as “Subject is added”.</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className='HowToUse'>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className='HowToUseSummary'>
                            <h3>How to Remove a Subject?</h3>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='HowToUseDetails'>
                            <div className='HowToData'>
                                <h3>Remove a Subject</h3>
                                <h4>If you found out that you have by mistake added a wrong subject to a particular class, then you can remove that subject here.</h4>
                                <p><span>Step 1 : </span> Click on “Add/Remove” link on the left side of the homepage.</p>
                                <p><span>Step 2 : </span> Click on “Subject”.</p>
                                <p><span>Step 3 : </span> Click on “Remove Subject”.</p>
                                <p><span>Step 4 : </span> Select Medium, Class, Section & Subject.</p>
                                <p><span>Step 5 : </span> Click on the “Remove Subject” button.</p>
                                <p><span>Step 6 : </span> If subject removed successfully then you will see a message as “Subject is removed”.</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className='HowToUse'>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className='HowToUseSummary'>
                            <h3>How to Add a Class?</h3>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='HowToUseDetails'>
                            <div className='HowToData'>
                                <h3>Add a Class</h3>
                                <h4>If you found out that you have not added a class or any section of a class, then you can add that class here.</h4>
                                <p><span>Step 1 : </span> Click on “Add/Remove” link on the left side of the homepage.</p>
                                <p><span>Step 2 : </span> Click on “Class”.</p>
                                <p><span>Step 3 : </span> Click on “Add Class”.</p>
                                <p><span>Step 4 : </span> Select Medium, Class & Section.</p>
                                <p><span>Step 5 : </span> Select all the subjects of that class (you can select multiple subjects here).</p>
                                <p><span>Step 6 : </span> Click on the “Add Class” button.</p>
                                <p><span>Step 7 : </span> If class is added successfully then you will see a message as “Class is added”.</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className='HowToUse'>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className='HowToUseSummary'>
                            <h3>How to Remove a Class?</h3>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='HowToUseDetails'>
                            <div className='HowToData'>
                                <h3>Remove a Class</h3>
                                <h4>If you found out that you have added a class or any section of a class by mistake or accidentally, then you can remove that class here.</h4>
                                <p><span>Step 1 : </span> Click on “Add/Remove” link on the left side of the homepage.</p>
                                <p><span>Step 2 : </span> Click on “Class”.</p>
                                <p><span>Step 3 : </span> Click on “Remove Class”.</p>
                                <p><span>Step 4 : </span> Select Medium, Class & Section.</p>
                                <p><span>Step 5 : </span> Click on the “Remove Class” button.</p>
                                <p><span>Step 6 : </span> If class is removed successfully then you will see a message as “Class is removed”.</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
}

export default HowToUseAddRem