import React, { useEffect, useState } from 'react'
import '../ProjectsHomeworks/HomeWorksTeachersClassNSubject.css'
import dB from '../../firebase'
import { collection, onSnapshot } from 'firebase/firestore'
import SchoolTimeTableUsersTeachersClassData from './SchoolTimeTableUsersTeachersClassData'
// import { returnMedClsSec } from '../../Constants/FunctionClsMedSec'

function SchoolTimeTableUsersTeachersClass(props) {
    const { userId, userPathCollection, userSchoolId, userSchoolPathCollection } = props

    const [teachersData, setteachersData] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "IS_TEACHER_DATA", "SUBJECTS_TEACH"), (snap) => {
                    setteachersData(snap.docs.map((subDoc) => ({
                        subjectId: subDoc.id,
                        subjectData: subDoc.data(),
                    })))
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const [selectedSubData, setselectedSubData] = useState({
        isTeachSubVerified: '', teacherClass: '', teacherMedium: '',
        teacherSection: '', teacherSubject: '', teacherSubjectId: ''
    })
    const handleClickSelectClass = async (data) => {
        await setselectedSubData(prev => {
            return {
                ...prev,
                isTeachSubVerified: data?.subjectData?.VERIFIED_TEACHER,
                teacherClass: data?.subjectData?.TEACH_CLASS,
                teacherMedium: data?.subjectData?.TEACH_MEDIUM,
                teacherSection: data?.subjectData?.TEACH_CLASS_SEC,
                teacherSubject: data?.subjectData?.TEACH_CLASS_SEC_SUB,
                teacherSubjectId: data?.subjectId
            }
        })
    }

    return (
        <div>
            <div className='homeworksteachersclassnsubject'>
                <div className='homeworksteachersclassnsubject__left'>
                    {
                        teachersData.map(data => (
                            <div key={data?.subjectId} className={`teacherClassTab`} onClick={(e) => { e.preventDefault(); handleClickSelectClass(data) }}>
                                <h4>{data?.subjectData?.TEACH_MEDIUM}</h4>
                                <h3>{data?.subjectData?.TEACH_CLASS} | {data?.subjectData?.TEACH_CLASS_SEC}</h3>
                                <h2>{data?.subjectData?.TEACH_CLASS_SEC_SUB}</h2>
                            </div>
                        ))
                    }
                </div>

                {
                    selectedSubData.teacherSubjectId && (
                        <div className='homeworksteachersclassnsubject__right'>
                            {
                                selectedSubData.isTeachSubVerified ? (
                                    <SchoolTimeTableUsersTeachersClassData
                                        teacherMedium={selectedSubData.teacherMedium}
                                        teacherClass={selectedSubData.teacherClass}
                                        teacherSection={selectedSubData.teacherSection}
                                        teacherSubject={selectedSubData.teacherSubject}
                                        userSchoolId={userSchoolId}
                                        userSchoolPathCollection={userSchoolPathCollection}
                                    />
                                ) : (
                                    <div className='teacherNotVerified'>
                                        <h2>You are not verified for the subject of <b>{selectedSubData.teacherSubject}</b> for <b>{selectedSubData.teacherMedium}/{selectedSubData.teacherClass}/{selectedSubData.teacherSection},</b> by your school, to update homeworks. Get yourself verified for this subject by your school first. For that, send verification request to your school and wait till they verify you. Till then you can use this platform to follow other schools and teachers and make new friends.</h2>
                                    </div>
                                )
                            }
                        </div>
                    )
                }




            </div >
        </div>
    )
}

export default SchoolTimeTableUsersTeachersClass