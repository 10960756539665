import React from 'react'
import '../Pages/HowToUse.css'
// import Header from '../Components/Header'
import HowToUseAddRem from '../Components/HowToUse/HowToUseAddRem'
import HowToUseVerReq from '../Components/HowToUse/HowToUseVerReq'
import HowToUseVerMem from '../Components/HowToUse/HowToUseVerMem'
import HowToUseProNHW from '../Components/HowToUse/HowToUseProNHW'
import HowToUseNoticeboard from '../Components/HowToUse/HowToUseNoticeboard'
import HowToUseEvents from '../Components/HowToUse/HowToUseEvents'
import Header from '../Components/Header/Header'

function HowToUse() {
    return (
        <div className='HowToUse'>
            <Header />
            <div className='HowToUse__Body'>
                <h2>How to use E-Schooling?</h2>
                <h3>Add/Remove</h3>
                <HowToUseAddRem />
                <h3>Verification Requests</h3>
                <HowToUseVerReq />
                <h3>Verified Members</h3>
                <HowToUseVerMem />
                <h3>Projects & Homeworks</h3>
                <HowToUseProNHW />
                <h3>Notice Board</h3>
                <HowToUseNoticeboard />
                <h3>Upcoming Events</h3>
                <HowToUseEvents />
            </div>
        </div>
    )
}

export default HowToUse