import React from 'react';
// import '../Components/UserProfileSidebar.css'
import '../Social/UserProfileSidebar.css'
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

function UserProfileSidebar(props) {
    const { userBio, userDOB, userGender, userMobNo, } = props

    return (
        <div className='userprofilesidebar'>
            {
                userBio && (
                    <div className='userprofilesidebar__bio'>
                        <h4>Bio</h4>
                        {userBio ? (<p>{userBio}</p>) : (<p>Please add your bio</p>)}
                    </div>
                )
            }

            <div className='userprofilesidebar__intro'>
                <h4>Intro</h4>
                <div className='userprofilesidebar__introOptions'>
                    {
                        userDOB && (
                            <div className='userprofilesidebar__introOption'>
                                <CakeOutlinedIcon />
                                <p><strong>{userDOB}</strong></p>
                            </div>
                        )
                    }
                    {
                        userGender && (
                            <div className='userprofilesidebar__introOption'>
                                <PermIdentityIcon />
                                <p> <strong>{userGender}</strong></p>
                            </div>
                        )
                    }
                    {
                        userMobNo && (
                            <div className='userprofilesidebar__introOption'>
                                <PhoneAndroidIcon />
                                <p>+91 <strong>{userMobNo}</strong></p>
                            </div>
                        )
                    }

                </div>
            </div>
        </div>
    )
}

export default UserProfileSidebar
