import React, { useEffect, useState } from 'react'
import Header from '../Components/Header/Header';
import SchoolSyllabusCoveredClass from '../Components/SyllabusCovered/SchoolSyllabusCoveredClass';

import dB, { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import Login from './Login';
import SyllabusCoveredTeachers from '../Components/SyllabusCovered/SyllabusCoveredTeachers';
import SyllabusCoveredPrincipals from '../Components/SyllabusCovered/SyllabusCoveredPrincipals';
import SyllabusCoveredParents from '../Components/SyllabusCovered/SyllabusCoveredParents';
import SyllabusCoveredStudents from '../Components/SyllabusCovered/SyllabusCoveredStudents';
import SyllabusCoveredSchool from '../Components/SyllabusCovered/SyllabusCoveredSchool';


function SchoolSyllabusCovered() {

    const [userData, setuserData] = useState(null)
    const [userId, setuserId] = useState('')
    const [userSchoolId, setuserSchoolId] = useState('')
    useEffect(() => {
        const unSubscribe = onAuthStateChanged(auth, (userCredentials) => {
            if (userCredentials) {
                setuserId(userCredentials.uid)
                onSnapshot(doc(dB, "USERS", userCredentials.uid), (userDoc) => {
                    if (userDoc.exists()) {
                        setuserData(userDoc.data())
                    } else {
                        onSnapshot(doc(dB, "SCHOOLS", userCredentials.uid), (schlDOc) => {
                            if (schlDOc.exists()) {
                                setuserData(schlDOc.data())
                            }
                        })
                    }
                })
            }
        });
        return () => { unSubscribe(); }
    }, [])

    // userSchoolId for teachers
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData?.USER_CURRENT_STATUS === 'TEACHER') {
                if (userId && userData?.USER_NAME) {
                    onSnapshot(doc(dB, "USERS", userId, userData?.USER_NAME, "E-SCHOOLING_INFO", "IS_TEACHER", "VERIFICATION_DETAILS"), (teachDoc) => {
                        if (teachDoc.exists()) {
                            setuserSchoolId(teachDoc.data())
                        }
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [userData?.USER_CURRENT_STATUS, userId, userData?.USER_NAME])
    // userSchoolId for principal
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData?.USER_CURRENT_STATUS === 'PRINCIPAL') {
                if (userId && userData?.USER_NAME) {
                    onSnapshot(doc(dB, "USERS", userId, userData?.USER_NAME, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), (princiDoc) => {
                        if (princiDoc.exists()) {
                            setuserSchoolId(princiDoc.data())
                        }
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [userData?.USER_CURRENT_STATUS, userId, userData?.USER_NAME])
    // userSchoolId for students
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData?.USER_CURRENT_STATUS === 'STUDENT') {
                if (userId && userData?.USER_NAME) {
                    onSnapshot(doc(dB, "USERS", userId, userData?.USER_NAME, "E-SCHOOLING_INFO", "IS_STUDENT", "VERIFICATION_DETAILS"), (stuDoc) => {
                        if (stuDoc.exists()) {
                            setuserSchoolId(stuDoc.data())
                        }
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [userData?.USER_CURRENT_STATUS, userId, userData?.USER_NAME])

    // userSchoolId for parents
    const [currentSelectedChild, setcurrentSelectedChild] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData?.USER_CURRENT_STATUS === 'PARENT') {
                if (userId && userData?.USER_NAME) {
                    onSnapshot(doc(dB, "USERS", userId, userData?.USER_NAME, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA"), (parDoc) => {
                        if (parDoc.exists()) {
                            setcurrentSelectedChild(parDoc.data())
                        }
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [userData?.USER_CURRENT_STATUS, userId, userData?.USER_NAME])
    const [childsData, setchildsData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (currentSelectedChild !== '') {
                onSnapshot(doc(dB, "USERS", userId, userData?.USER_NAME, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", currentSelectedChild?.CURRENT_SELECTED_CHILD), (childDoc) => {
                    if (childDoc.exists()) {
                        setchildsData(childDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [currentSelectedChild, userId, userData?.USER_NAME])

    const [showSyllabus, setshowSyllabus] = useState({
        isTeacher: false, isPrincipal: false, isParent: false, isStudent: false, isSchool: false
    })
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData?.USER_CURRENT_STATUS || userData?.USER_STATUS) {
                if (userData?.USER_CURRENT_STATUS === 'TEACHER') { setshowSyllabus(prev => { return { ...prev, isTeacher: true } }) }
                if (userData?.USER_CURRENT_STATUS === 'PRINCIPAL') { setshowSyllabus(prev => { return { ...prev, isPrincipal: true } }) }
                if (userData?.USER_CURRENT_STATUS === 'PARENT') { setshowSyllabus(prev => { return { ...prev, isParent: true } }) }
                if (userData?.USER_CURRENT_STATUS === 'STUDENT') { setshowSyllabus(prev => { return { ...prev, isStudent: true } }) }
                if (userData?.USER_STATUS === 'SCHOOL') { setshowSyllabus(prev => { return { ...prev, isSchool: true } }) }
            }
        }
        return () => { aaa = false }
    }, [userData?.USER_CURRENT_STATUS, userData?.USER_STATUS])

    return (
        <>
            {
                userId === '' ? (
                    <Login />
                ) : (
                    <div className='sidebarhomeworks'>
                        <Header />
                        {
                            showSyllabus.isTeacher && (
                                <SyllabusCoveredTeachers
                                    userId={userId}
                                    userPathCollection={userData?.USER_NAME}
                                    userCurrentStatus={userData?.USER_CURRENT_STATUS}
                                    userSchoolId={userSchoolId?.USER_SCHOOL_ID}
                                />
                            )
                        }
                        {
                            showSyllabus.isPrincipal && (
                                <SyllabusCoveredPrincipals
                                    userId={userId}
                                    userPathCollection={userData?.USER_NAME}
                                    userCurrentStatus={userData?.USER_CURRENT_STATUS}
                                    userSchoolId={userSchoolId?.USER_SCHOOL_ID}
                                />
                            )
                        }

                        {
                            showSyllabus.isStudent && (
                                <SyllabusCoveredStudents
                                    userId={userId}
                                    userPathCollection={userData?.USER_NAME}
                                    userCurrentStatus={userData?.USER_CURRENT_STATUS}
                                    userSchoolId={userSchoolId?.USER_SCHOOL_ID}
                                />
                            )
                        }

                        {
                            showSyllabus.isParent && (
                                <>
                                    <SyllabusCoveredParents
                                        userId={userId}
                                        userPathCollection={userData?.USER_NAME}
                                        userCurrentStatus={userData?.USER_CURRENT_STATUS}
                                        childSchoolId={childsData?.CHILDS_SCHOOL_ID}
                                        childId={currentSelectedChild?.CURRENT_SELECTED_CHILD}
                                    />
                                </>
                            )
                        }
                        {
                            showSyllabus.isSchool && (
                                <SyllabusCoveredSchool
                                    userId={userId}
                                    userPathCollection={userData?.SCHOOL_NAME}
                                    userCurrentStatus={userData?.USER_STATUS}
                                />
                            )
                        }
                    </div>
                )
            }
        </>
    )
}

export default SchoolSyllabusCovered