
export const functionClsStr = (clsStr) => {

    var abc = {
        classPath: clsStr,
        classNum: '',
        medium: '',
        section: ''
    }

    if (clsStr === 'EngPreNurserySecA') { abc.classNum = 'Pre Nursery'; abc.section = 'Section A'; abc.medium = 'English' }
    if (clsStr === 'EngPreNurserySecB') { abc.classNum = 'Pre Nursery'; abc.section = 'Section B'; abc.medium = 'English' }
    if (clsStr === 'EngPreNurserySecC') { abc.classNum = 'Pre Nursery'; abc.section = 'Section C'; abc.medium = 'English' }
    if (clsStr === 'EngPreNurserySecD') { abc.classNum = 'Pre Nursery'; abc.section = 'Section D'; abc.medium = 'English' }
    if (clsStr === 'EngPreNurserySecE') { abc.classNum = 'Pre Nursery'; abc.section = 'Section E'; abc.medium = 'English' }

    if (clsStr === 'EngNurserySecA') { abc.classNum = 'Nursery'; abc.section = 'Section A'; abc.medium = 'English' }
    if (clsStr === 'EngNurserySecB') { abc.classNum = 'Nursery'; abc.section = 'Section B'; abc.medium = 'English' }
    if (clsStr === 'EngNurserySecC') { abc.classNum = 'Nursery'; abc.section = 'Section C'; abc.medium = 'English' }
    if (clsStr === 'EngNurserySecD') { abc.classNum = 'Nursery'; abc.section = 'Section D'; abc.medium = 'English' }
    if (clsStr === 'EngNurserySecE') { abc.classNum = 'Nursery'; abc.section = 'Section E'; abc.medium = 'English' }

    if (clsStr === 'EngKG1SecA') { abc.classNum = 'Kindergarten 1(KG-1)'; abc.section = 'Section A'; abc.medium = 'English' }
    if (clsStr === 'EngKG1SecB') { abc.classNum = 'Kindergarten 1(KG-1)'; abc.section = 'Section B'; abc.medium = 'English' }
    if (clsStr === 'EngKG1SecC') { abc.classNum = 'Kindergarten 1(KG-1)'; abc.section = 'Section C'; abc.medium = 'English' }
    if (clsStr === 'EngKG1SecD') { abc.classNum = 'Kindergarten 1(KG-1)'; abc.section = 'Section D'; abc.medium = 'English' }
    if (clsStr === 'EngKG1SecE') { abc.classNum = 'Kindergarten 1(KG-1)'; abc.section = 'Section E'; abc.medium = 'English' }

    if (clsStr === 'EngKG2SecA') { abc.classNum = 'Kindergarten 2(KG-2)'; abc.section = 'Section A'; abc.medium = 'English' }
    if (clsStr === 'EngKG2SecB') { abc.classNum = 'Kindergarten 2(KG-2)'; abc.section = 'Section B'; abc.medium = 'English' }
    if (clsStr === 'EngKG2SecC') { abc.classNum = 'Kindergarten 2(KG-2)'; abc.section = 'Section C'; abc.medium = 'English' }
    if (clsStr === 'EngKG2SecD') { abc.classNum = 'Kindergarten 2(KG-2)'; abc.section = 'Section D'; abc.medium = 'English' }
    if (clsStr === 'EngKG2SecE') { abc.classNum = 'Kindergarten 2(KG-2)'; abc.section = 'Section E'; abc.medium = 'English' }

    if (clsStr === 'EngClass1SecA') { abc.classNum = 'Class 1'; abc.section = 'Section A'; abc.medium = 'English' }
    if (clsStr === 'EngClass1SecB') { abc.classNum = 'Class 1'; abc.section = 'Section B'; abc.medium = 'English' }
    if (clsStr === 'EngClass1SecC') { abc.classNum = 'Class 1'; abc.section = 'Section C'; abc.medium = 'English' }
    if (clsStr === 'EngClass1SecD') { abc.classNum = 'Class 1'; abc.section = 'Section D'; abc.medium = 'English' }
    if (clsStr === 'EngClass1SecE') { abc.classNum = 'Class 1'; abc.section = 'Section E'; abc.medium = 'English' }

    if (clsStr === 'EngClass2SecA') { abc.classNum = 'Class 2'; abc.section = 'Section A'; abc.medium = 'English' }
    if (clsStr === 'EngClass2SecB') { abc.classNum = 'Class 2'; abc.section = 'Section B'; abc.medium = 'English' }
    if (clsStr === 'EngClass2SecC') { abc.classNum = 'Class 2'; abc.section = 'Section C'; abc.medium = 'English' }
    if (clsStr === 'EngClass2SecD') { abc.classNum = 'Class 2'; abc.section = 'Section D'; abc.medium = 'English' }
    if (clsStr === 'EngClass2SecE') { abc.classNum = 'Class 2'; abc.section = 'Section E'; abc.medium = 'English' }

    if (clsStr === 'EngClass3SecA') { abc.classNum = 'Class 3'; abc.section = 'Section A'; abc.medium = 'English' }
    if (clsStr === 'EngClass3SecB') { abc.classNum = 'Class 3'; abc.section = 'Section B'; abc.medium = 'English' }
    if (clsStr === 'EngClass3SecC') { abc.classNum = 'Class 3'; abc.section = 'Section C'; abc.medium = 'English' }
    if (clsStr === 'EngClass3SecD') { abc.classNum = 'Class 3'; abc.section = 'Section D'; abc.medium = 'English' }
    if (clsStr === 'EngClass3SecE') { abc.classNum = 'Class 3'; abc.section = 'Section E'; abc.medium = 'English' }

    if (clsStr === 'EngClass4SecA') { abc.classNum = 'Class 4'; abc.section = 'Section A'; abc.medium = 'English' }
    if (clsStr === 'EngClass4SecB') { abc.classNum = 'Class 4'; abc.section = 'Section B'; abc.medium = 'English' }
    if (clsStr === 'EngClass4SecC') { abc.classNum = 'Class 4'; abc.section = 'Section C'; abc.medium = 'English' }
    if (clsStr === 'EngClass4SecD') { abc.classNum = 'Class 4'; abc.section = 'Section D'; abc.medium = 'English' }
    if (clsStr === 'EngClass4SecE') { abc.classNum = 'Class 4'; abc.section = 'Section E'; abc.medium = 'English' }

    if (clsStr === 'EngClass5SecA') { abc.classNum = 'Class 5'; abc.section = 'Section A'; abc.medium = 'English' }
    if (clsStr === 'EngClass5SecB') { abc.classNum = 'Class 5'; abc.section = 'Section B'; abc.medium = 'English' }
    if (clsStr === 'EngClass5SecC') { abc.classNum = 'Class 5'; abc.section = 'Section C'; abc.medium = 'English' }
    if (clsStr === 'EngClass5SecD') { abc.classNum = 'Class 5'; abc.section = 'Section D'; abc.medium = 'English' }
    if (clsStr === 'EngClass5SecE') { abc.classNum = 'Class 5'; abc.section = 'Section E'; abc.medium = 'English' }

    if (clsStr === 'EngClass6SecA') { abc.classNum = 'Class 6'; abc.section = 'Section A'; abc.medium = 'English' }
    if (clsStr === 'EngClass6SecB') { abc.classNum = 'Class 6'; abc.section = 'Section B'; abc.medium = 'English' }
    if (clsStr === 'EngClass6SecC') { abc.classNum = 'Class 6'; abc.section = 'Section C'; abc.medium = 'English' }
    if (clsStr === 'EngClass6SecD') { abc.classNum = 'Class 6'; abc.section = 'Section D'; abc.medium = 'English' }
    if (clsStr === 'EngClass6SecE') { abc.classNum = 'Class 6'; abc.section = 'Section E'; abc.medium = 'English' }

    if (clsStr === 'EngClass7SecA') { abc.classNum = 'Class 7'; abc.section = 'Section A'; abc.medium = 'English' }
    if (clsStr === 'EngClass7SecB') { abc.classNum = 'Class 7'; abc.section = 'Section B'; abc.medium = 'English' }
    if (clsStr === 'EngClass7SecC') { abc.classNum = 'Class 7'; abc.section = 'Section C'; abc.medium = 'English' }
    if (clsStr === 'EngClass7SecD') { abc.classNum = 'Class 7'; abc.section = 'Section D'; abc.medium = 'English' }
    if (clsStr === 'EngClass7SecE') { abc.classNum = 'Class 7'; abc.section = 'Section E'; abc.medium = 'English' }

    if (clsStr === 'EngClass8SecA') { abc.classNum = 'Class 8'; abc.section = 'Section A'; abc.medium = 'English' }
    if (clsStr === 'EngClass8SecB') { abc.classNum = 'Class 8'; abc.section = 'Section B'; abc.medium = 'English' }
    if (clsStr === 'EngClass8SecC') { abc.classNum = 'Class 8'; abc.section = 'Section C'; abc.medium = 'English' }
    if (clsStr === 'EngClass8SecD') { abc.classNum = 'Class 8'; abc.section = 'Section D'; abc.medium = 'English' }
    if (clsStr === 'EngClass8SecE') { abc.classNum = 'Class 8'; abc.section = 'Section E'; abc.medium = 'English' }

    if (clsStr === 'EngClass9SecA') { abc.classNum = 'Class 9'; abc.section = 'Section A'; abc.medium = 'English' }
    if (clsStr === 'EngClass9SecB') { abc.classNum = 'Class 9'; abc.section = 'Section B'; abc.medium = 'English' }
    if (clsStr === 'EngClass9SecC') { abc.classNum = 'Class 9'; abc.section = 'Section C'; abc.medium = 'English' }
    if (clsStr === 'EngClass9SecD') { abc.classNum = 'Class 9'; abc.section = 'Section D'; abc.medium = 'English' }
    if (clsStr === 'EngClass9SecE') { abc.classNum = 'Class 9'; abc.section = 'Section E'; abc.medium = 'English' }

    if (clsStr === 'EngClass10SecA') { abc.classNum = 'Class 10'; abc.section = 'Section A'; abc.medium = 'English' }
    if (clsStr === 'EngClass10SecB') { abc.classNum = 'Class 10'; abc.section = 'Section B'; abc.medium = 'English' }
    if (clsStr === 'EngClass10SecC') { abc.classNum = 'Class 10'; abc.section = 'Section C'; abc.medium = 'English' }
    if (clsStr === 'EngClass10SecD') { abc.classNum = 'Class 10'; abc.section = 'Section D'; abc.medium = 'English' }
    if (clsStr === 'EngClass10SecE') { abc.classNum = 'Class 10'; abc.section = 'Section E'; abc.medium = 'English' }

    if (clsStr === 'EngClass11CommerceSecA') { abc.classNum = 'Class 11(Commerce)'; abc.section = 'Section A'; abc.medium = 'English' }
    if (clsStr === 'EngClass11CommerceSecB') { abc.classNum = 'Class 11(Commerce)'; abc.section = 'Section B'; abc.medium = 'English' }
    if (clsStr === 'EngClass11CommerceSecC') { abc.classNum = 'Class 11(Commerce)'; abc.section = 'Section C'; abc.medium = 'English' }
    if (clsStr === 'EngClass11CommerceSecD') { abc.classNum = 'Class 11(Commerce)'; abc.section = 'Section D'; abc.medium = 'English' }
    if (clsStr === 'EngClass11CommerceSecE') { abc.classNum = 'Class 11(Commerce)'; abc.section = 'Section E'; abc.medium = 'English' }

    if (clsStr === 'EngClass11ScienceSecA') { abc.classNum = 'Class 11(Science)'; abc.section = 'Section A'; abc.medium = 'English' }
    if (clsStr === 'EngClass11ScienceSecB') { abc.classNum = 'Class 11(Science)'; abc.section = 'Section B'; abc.medium = 'English' }
    if (clsStr === 'EngClass11ScienceSecC') { abc.classNum = 'Class 11(Science)'; abc.section = 'Section C'; abc.medium = 'English' }
    if (clsStr === 'EngClass11ScienceSecD') { abc.classNum = 'Class 11(Science)'; abc.section = 'Section D'; abc.medium = 'English' }
    if (clsStr === 'EngClass11ScienceSecE') { abc.classNum = 'Class 11(Science)'; abc.section = 'Section E'; abc.medium = 'English' }

    if (clsStr === 'EngClass12CommerceSecA') { abc.classNum = 'Class 12(Commerce)'; abc.section = 'Section A'; abc.medium = 'English' }
    if (clsStr === 'EngClass12CommerceSecB') { abc.classNum = 'Class 12(Commerce)'; abc.section = 'Section B'; abc.medium = 'English' }
    if (clsStr === 'EngClass12CommerceSecC') { abc.classNum = 'Class 12(Commerce)'; abc.section = 'Section C'; abc.medium = 'English' }
    if (clsStr === 'EngClass12CommerceSecD') { abc.classNum = 'Class 12(Commerce)'; abc.section = 'Section D'; abc.medium = 'English' }
    if (clsStr === 'EngClass12CommerceSecE') { abc.classNum = 'Class 12(Commerce)'; abc.section = 'Section E'; abc.medium = 'English' }

    if (clsStr === 'EngClass12ScienceSecA') { abc.classNum = 'Class 12(Science)'; abc.section = 'Section A'; abc.medium = 'English' }
    if (clsStr === 'EngClass12ScienceSecB') { abc.classNum = 'Class 12(Science)'; abc.section = 'Section B'; abc.medium = 'English' }
    if (clsStr === 'EngClass12ScienceSecC') { abc.classNum = 'Class 12(Science)'; abc.section = 'Section C'; abc.medium = 'English' }
    if (clsStr === 'EngClass12ScienceSecD') { abc.classNum = 'Class 12(Science)'; abc.section = 'Section D'; abc.medium = 'English' }
    if (clsStr === 'EngClass12ScienceSecE') { abc.classNum = 'Class 12(Science)'; abc.section = 'Section E'; abc.medium = 'English' }



    if (clsStr === 'MarPreNurserySecA') { abc.classNum = 'Pre Nursery'; abc.section = 'Section A'; abc.medium = 'Marathi' }
    if (clsStr === 'MarPreNurserySecB') { abc.classNum = 'Pre Nursery'; abc.section = 'Section B'; abc.medium = 'Marathi' }
    if (clsStr === 'MarPreNurserySecC') { abc.classNum = 'Pre Nursery'; abc.section = 'Section C'; abc.medium = 'Marathi' }
    if (clsStr === 'MarPreNurserySecD') { abc.classNum = 'Pre Nursery'; abc.section = 'Section D'; abc.medium = 'Marathi' }
    if (clsStr === 'MarPreNurserySecE') { abc.classNum = 'Pre Nursery'; abc.section = 'Section E'; abc.medium = 'Marathi' }

    if (clsStr === 'MarNurserySecA') { abc.classNum = 'Nursery'; abc.section = 'Section A'; abc.medium = 'Marathi' }
    if (clsStr === 'MarNurserySecB') { abc.classNum = 'Nursery'; abc.section = 'Section B'; abc.medium = 'Marathi' }
    if (clsStr === 'MarNurserySecC') { abc.classNum = 'Nursery'; abc.section = 'Section C'; abc.medium = 'Marathi' }
    if (clsStr === 'MarNurserySecD') { abc.classNum = 'Nursery'; abc.section = 'Section D'; abc.medium = 'Marathi' }
    if (clsStr === 'MarNurserySecE') { abc.classNum = 'Nursery'; abc.section = 'Section E'; abc.medium = 'Marathi' }

    if (clsStr === 'MarKG1SecA') { abc.classNum = 'Kindergarten 1(KG-1)'; abc.section = 'Section A'; abc.medium = 'Marathi' }
    if (clsStr === 'MarKG1SecB') { abc.classNum = 'Kindergarten 1(KG-1)'; abc.section = 'Section B'; abc.medium = 'Marathi' }
    if (clsStr === 'MarKG1SecC') { abc.classNum = 'Kindergarten 1(KG-1)'; abc.section = 'Section C'; abc.medium = 'Marathi' }
    if (clsStr === 'MarKG1SecD') { abc.classNum = 'Kindergarten 1(KG-1)'; abc.section = 'Section D'; abc.medium = 'Marathi' }
    if (clsStr === 'MarKG1SecE') { abc.classNum = 'Kindergarten 1(KG-1)'; abc.section = 'Section E'; abc.medium = 'Marathi' }

    if (clsStr === 'MarKG2SecA') { abc.classNum = 'Kindergarten 2(KG-2)'; abc.section = 'Section A'; abc.medium = 'Marathi' }
    if (clsStr === 'MarKG2SecB') { abc.classNum = 'Kindergarten 2(KG-2)'; abc.section = 'Section B'; abc.medium = 'Marathi' }
    if (clsStr === 'MarKG2SecC') { abc.classNum = 'Kindergarten 2(KG-2)'; abc.section = 'Section C'; abc.medium = 'Marathi' }
    if (clsStr === 'MarKG2SecD') { abc.classNum = 'Kindergarten 2(KG-2)'; abc.section = 'Section D'; abc.medium = 'Marathi' }
    if (clsStr === 'MarKG2SecE') { abc.classNum = 'Kindergarten 2(KG-2)'; abc.section = 'Section E'; abc.medium = 'Marathi' }




    if (clsStr === 'MarClass1SecA') { abc.classNum = 'Class 1'; abc.section = 'Section A'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass1SecB') { abc.classNum = 'Class 1'; abc.section = 'Section B'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass1SecC') { abc.classNum = 'Class 1'; abc.section = 'Section C'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass1SecD') { abc.classNum = 'Class 1'; abc.section = 'Section D'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass1SecE') { abc.classNum = 'Class 1'; abc.section = 'Section E'; abc.medium = 'Marathi' }

    if (clsStr === 'MarClass2SecA') { abc.classNum = 'Class 2'; abc.section = 'Section A'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass2SecB') { abc.classNum = 'Class 2'; abc.section = 'Section B'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass2SecC') { abc.classNum = 'Class 2'; abc.section = 'Section C'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass2SecD') { abc.classNum = 'Class 2'; abc.section = 'Section D'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass2SecE') { abc.classNum = 'Class 2'; abc.section = 'Section E'; abc.medium = 'Marathi' }

    if (clsStr === 'MarClass3SecA') { abc.classNum = 'Class 3'; abc.section = 'Section A'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass3SecB') { abc.classNum = 'Class 3'; abc.section = 'Section B'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass3SecC') { abc.classNum = 'Class 3'; abc.section = 'Section C'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass3SecD') { abc.classNum = 'Class 3'; abc.section = 'Section D'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass3SecE') { abc.classNum = 'Class 3'; abc.section = 'Section E'; abc.medium = 'Marathi' }

    if (clsStr === 'MarClass4SecA') { abc.classNum = 'Class 4'; abc.section = 'Section A'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass4SecB') { abc.classNum = 'Class 4'; abc.section = 'Section B'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass4SecC') { abc.classNum = 'Class 4'; abc.section = 'Section C'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass4SecD') { abc.classNum = 'Class 4'; abc.section = 'Section D'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass4SecE') { abc.classNum = 'Class 4'; abc.section = 'Section E'; abc.medium = 'Marathi' }

    if (clsStr === 'MarClass5SecA') { abc.classNum = 'Class 5'; abc.section = 'Section A'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass5SecB') { abc.classNum = 'Class 5'; abc.section = 'Section B'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass5SecC') { abc.classNum = 'Class 5'; abc.section = 'Section C'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass5SecD') { abc.classNum = 'Class 5'; abc.section = 'Section D'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass5SecE') { abc.classNum = 'Class 5'; abc.section = 'Section E'; abc.medium = 'Marathi' }

    if (clsStr === 'MarClass6SecA') { abc.classNum = 'Class 6'; abc.section = 'Section A'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass6SecB') { abc.classNum = 'Class 6'; abc.section = 'Section B'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass6SecC') { abc.classNum = 'Class 6'; abc.section = 'Section C'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass6SecD') { abc.classNum = 'Class 6'; abc.section = 'Section D'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass6SecE') { abc.classNum = 'Class 6'; abc.section = 'Section E'; abc.medium = 'Marathi' }

    if (clsStr === 'MarClass7SecA') { abc.classNum = 'Class 7'; abc.section = 'Section A'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass7SecB') { abc.classNum = 'Class 7'; abc.section = 'Section B'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass7SecC') { abc.classNum = 'Class 7'; abc.section = 'Section C'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass7SecD') { abc.classNum = 'Class 7'; abc.section = 'Section D'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass7SecE') { abc.classNum = 'Class 7'; abc.section = 'Section E'; abc.medium = 'Marathi' }

    if (clsStr === 'MarClass8SecA') { abc.classNum = 'Class 8'; abc.section = 'Section A'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass8SecB') { abc.classNum = 'Class 8'; abc.section = 'Section B'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass8SecC') { abc.classNum = 'Class 8'; abc.section = 'Section C'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass8SecD') { abc.classNum = 'Class 8'; abc.section = 'Section D'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass8SecE') { abc.classNum = 'Class 8'; abc.section = 'Section E'; abc.medium = 'Marathi' }

    if (clsStr === 'MarClass9SecA') { abc.classNum = 'Class 9'; abc.section = 'Section A'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass9SecB') { abc.classNum = 'Class 9'; abc.section = 'Section B'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass9SecC') { abc.classNum = 'Class 9'; abc.section = 'Section C'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass9SecD') { abc.classNum = 'Class 9'; abc.section = 'Section D'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass9SecE') { abc.classNum = 'Class 9'; abc.section = 'Section E'; abc.medium = 'Marathi' }

    if (clsStr === 'MarClass10SecA') { abc.classNum = 'Class 10'; abc.section = 'Section A'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass10SecB') { abc.classNum = 'Class 10'; abc.section = 'Section B'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass10SecC') { abc.classNum = 'Class 10'; abc.section = 'Section C'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass10SecD') { abc.classNum = 'Class 10'; abc.section = 'Section D'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass10SecE') { abc.classNum = 'Class 10'; abc.section = 'Section E'; abc.medium = 'Marathi' }

    if (clsStr === 'MarClass11CommerceSecA') { abc.classNum = 'Class 11(Commerce)'; abc.section = 'Section A'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass11CommerceSecB') { abc.classNum = 'Class 11(Commerce)'; abc.section = 'Section B'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass11CommerceSecC') { abc.classNum = 'Class 11(Commerce)'; abc.section = 'Section C'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass11CommerceSecD') { abc.classNum = 'Class 11(Commerce)'; abc.section = 'Section D'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass11CommerceSecE') { abc.classNum = 'Class 11(Commerce)'; abc.section = 'Section E'; abc.medium = 'Marathi' }

    if (clsStr === 'MarClass11ArtsSecA') { abc.classNum = 'Class 11(Arts)'; abc.section = 'Section A'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass11ArtsSecB') { abc.classNum = 'Class 11(Arts)'; abc.section = 'Section B'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass11ArtsSecC') { abc.classNum = 'Class 11(Arts)'; abc.section = 'Section C'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass11ArtsSecD') { abc.classNum = 'Class 11(Arts)'; abc.section = 'Section D'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass11ArtsSecE') { abc.classNum = 'Class 11(Arts)'; abc.section = 'Section E'; abc.medium = 'Marathi' }

    if (clsStr === 'MarClass12CommerceSecA') { abc.classNum = 'Class 12(Commerce)'; abc.section = 'Section A'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass12CommerceSecB') { abc.classNum = 'Class 12(Commerce)'; abc.section = 'Section B'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass12CommerceSecC') { abc.classNum = 'Class 12(Commerce)'; abc.section = 'Section C'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass12CommerceSecD') { abc.classNum = 'Class 12(Commerce)'; abc.section = 'Section D'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass12CommerceSecE') { abc.classNum = 'Class 12(Commerce)'; abc.section = 'Section E'; abc.medium = 'Marathi' }

    if (clsStr === 'MarClass12ArtsSecA') { abc.classNum = 'Class 12(Arts)'; abc.section = 'Section A'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass12ArtsSecB') { abc.classNum = 'Class 12(Arts)'; abc.section = 'Section B'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass12ArtsSecC') { abc.classNum = 'Class 12(Arts)'; abc.section = 'Section C'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass12ArtsSecD') { abc.classNum = 'Class 12(Arts)'; abc.section = 'Section D'; abc.medium = 'Marathi' }
    if (clsStr === 'MarClass12ArtsSecE') { abc.classNum = 'Class 12(Arts)'; abc.section = 'Section E'; abc.medium = 'Marathi' }

    return abc;
}