import React from 'react'
import SchoolComplaintsAccordian from './SchoolComplaintsAccordian'

function ComplaintsSchoolData(props) {

    const { allSuggestions, allComplaints } = props

    return (
        <div>
            <div className='compSuggTitle'>
                <h3>Complaints</h3>
                {
                    allComplaints?.length > 0 ? (
                        <>
                            {
                                allComplaints.map((comp, i) => (
                                    <SchoolComplaintsAccordian
                                        key={i}
                                        type='Complaint'
                                        childId={comp?.complaintData?.CHILD_ID}
                                        compSuggUser={comp?.complaintData?.COMPLAINT_FROM}
                                        compSuggUserPathCollection={comp?.complaintData?.USER_PATH_COLLECTION}
                                        timestamp={comp?.complaintData?.TIMESTAMP}
                                        compSuggText={comp?.complaintData?.COMPLAINT_TEXT}
                                        userStatus={comp?.complaintData?.USER_STATUS}
                                    />
                                ))
                            }
                        </>

                    ) : (
                        <>
                            <h3 style={{ marginLeft: '100px', color: 'var(--connected-color)' }}>No Complaints by parents and teachers yet.</h3>
                        </>
                    )
                }

            </div>
            <div className='compSuggTitle'>
                <h3>Suggestions</h3>
                {
                    allSuggestions?.length > 0 ? (
                        <>
                            {
                                allSuggestions.map((sugg, i) => (
                                    <SchoolComplaintsAccordian
                                        key={i}
                                        type='Suggestion'
                                        childId={sugg?.suggestionData?.CHILD_ID}
                                        compSuggUser={sugg?.suggestionData?.SUGGESTION_FROM}
                                        compSuggUserPathCollection={sugg?.suggestionData?.USER_PATH_COLLECTION}
                                        timestamp={sugg?.suggestionData?.TIMESTAMP}
                                        compSuggText={sugg?.suggestionData?.SUGGESTION_TEXT}
                                        userStatus={sugg?.suggestionData?.USER_STATUS}
                                    />
                                ))
                            }
                        </>

                    ) : (
                        <>
                            <h3 style={{ marginLeft: '100px', color: 'var(--connected-color)' }}>No suggestions by parents and teachers yet.</h3>
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default ComplaintsSchoolData