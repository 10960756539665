import React, { useEffect, useState } from 'react'
import '../VerifiedMembers/SchoolVerifiedMembersStudents.css'
import '../../Pages/SchoolVerifiedMembers.css'
import { Link } from 'react-router-dom';
import { doc, onSnapshot, writeBatch } from 'firebase/firestore';
import { Avatar, Box, Button, Modal } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function SchoolVerifiedMembersStudents(props) {
    const { studentMedium, studentClass, studentSection, studentCollectionId, studentsMedClsSec,
        schoolId, schoolShortName, schoolFullName, schoolAffiliation,
    } = props

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // const classes = useStyles();

    var batch = writeBatch(dB)

    const [studentsData, setstudentsData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (studentCollectionId) {
                onSnapshot(doc(dB, "USERS", studentCollectionId), (snap) => {
                    if (snap.exists()) { setstudentsData(snap.data()) }
                })
            }
        }
        return () => { aaa = false }
    }, [studentCollectionId])
    const [studentRegName, setstudentRegName] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (studentCollectionId && studentsData) {
                onSnapshot(doc(dB, "USERS", studentCollectionId, studentsData?.USER_NAME, "REGISTRATION_INFO"), (snap) => {
                    if (snap.exists()) { setstudentRegName(snap.data()?.USER_NAME) }
                })
            }
        }
        return () => { aaa = false }
    }, [studentCollectionId, studentsData])

    const [btnLoading, setbtnLoading] = useState(false)

    const handleStudentRemove = async (e) => {
        e.preventDefault();
        setbtnLoading(true)

        await batch.delete(doc(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", studentsMedClsSec, "STUDENTS", "VERIFIED_MEMBERS", "VERIFIED_MEMBERS_DATA", studentCollectionId));

        await batch.update(doc(dB, "USERS", studentCollectionId, studentsData?.USER_NAME, "E-SCHOOLING_INFO", "IS_STUDENT", "VERIFICATION_DETAILS"), {
            VERIFIED_STUDENT: false
        })

        await batch.commit().then(() => {
            setOpen(false)
            setbtnLoading(false)
        }).catch(err => alert(err.message));
    }

    return (
        <div className='SchoolVerifiedMembersStudents' >
            <div className='SchoolVerifiedMembersStudents__containerRightStudentsData' >
                <div className='SchoolVerifiedMembersStudents__containerRightStudentsDataName dataNameParent'>
                    <Link to={`/friendsprofile/${studentCollectionId}`} style={{ textDecoration: 'none', color: 'gray' }}>
                        <Avatar
                            alt={studentRegName}
                            src={studentsData?.USER_DISPLAY_PIC}
                            sx={{ width: 40, height: 40, }}
                        />
                    </Link>
                    <h3>
                        <Link to={`/friendsprofile/${studentCollectionId}`} style={{ textDecoration: 'none', color: 'black' }}>
                            {studentRegName}
                        </Link>
                    </h3>
                </div>
                <div className='SchoolVerifiedMembersStudents__containerRightStudentsDataButtons'>
                    <Button onClick={handleOpen}>Remove</Button>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div className='removeVerifiedUser'>
                                <div className='removeVerifiedUserText'>
                                    <h4>Do you really want to remove <br /><strong>{studentRegName}</strong> from your student's list?</h4>
                                </div>
                                <div className='removeVerifiedUserButtons'>

                                    <LoadingButton
                                        variant='outlined'
                                        loading={btnLoading}
                                        onClick={handleStudentRemove}>
                                        Yes
                                    </LoadingButton>
                                    <Button onClick={handleClose}>No</Button>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default SchoolVerifiedMembersStudents
