import React, { useEffect, useState } from 'react'
import '../Header/MessageBody.css'
// import dB from '../firebase';
import { Link } from 'react-router-dom';
import { deleteDoc, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Button, Modal } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dB from '../../firebase';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function MessageBody(props) {

    const { msgRecieverSenderStatus, msgId, msgText, msgTimestamp,
        userId, userPathCollection, userCurrentStatus, userFullName,
        msgFrom, msgTo, msgToFromId, isViewed } = props


    const [msgData, setmsgData] = useState(null)
    const [msgToFromRegName, setmsgToFromRegName] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (msgRecieverSenderStatus && msgToFromId) {
                if (msgRecieverSenderStatus === 'SCHOOL') {
                    onSnapshot(doc(dB, "SCHOOLS", msgToFromId), (snap) => {
                        if (snap.exists()) { setmsgData(snap.data()) }
                    })
                } else {
                    onSnapshot(doc(dB, "USERS", msgToFromId), (snap) => {
                        if (snap.exists()) { setmsgData(snap.data()) }
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [msgRecieverSenderStatus, msgToFromId])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (msgRecieverSenderStatus && msgToFromId && msgData) {
                if (msgRecieverSenderStatus === 'SCHOOL') {
                    onSnapshot(doc(dB, "SCHOOLS", msgToFromId, msgData?.SCHOOL_NAME, "REGISTRATION_INFO"), (snap) => {
                        if (snap.exists()) { setmsgToFromRegName(snap.data()?.SCHOOL_FULL_NAME) }
                    })
                } else {
                    onSnapshot(doc(dB, "USERS", msgToFromId, msgData?.USER_NAME, "REGISTRATION_INFO"), (snap) => {
                        if (snap.exists()) { setmsgToFromRegName(snap.data()?.USER_NAME) }
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [msgRecieverSenderStatus, msgToFromId, msgData])

    const [btnLoading, setbtnLoading] = useState(false)

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // const MuiClasses = useStyles();
    const [msgDeleted, setmsgDeleted] = useState(false)
    const handleDeleteMsg = async (e) => {
        e.preventDefault();
        setbtnLoading(true)
        if (userCurrentStatus === 'SCHOOL') {
            if (msgTo) {
                deleteDoc(doc(dB, "SCHOOLS", userId, userPathCollection, "MESSAGES_SENT", "MESSAGES", msgId))
                    .then(() => {
                        setbtnLoading(false)
                        setOpen(false)
                        setmsgDeleted(true)
                    })
                    .catch(err => alert(err.message))

            } else {
                deleteDoc(doc(dB, "SCHOOLS", userId, userPathCollection, "MESSAGES_RECIEVED", "MESSAGES", msgId))
                    .then(() => {
                        setbtnLoading(false)
                        setOpen(false)
                        setmsgDeleted(true)
                    })
                    .catch(err => alert(err.message))
            }
        } else {
            if (msgTo) {
                deleteDoc(doc(dB, "USERS", userId, userPathCollection, "MESSAGES_SENT", "MESSAGES", msgId))
                    .then(() => {
                        setbtnLoading(false)
                        setOpen(false)
                        setmsgDeleted(true)
                    })
                    .catch(err => alert(err.message))
            } else {
                deleteDoc(doc(dB, "USERS", userId, userPathCollection, "MESSAGES_RECIEVED", "MESSAGES", msgId))
                    .then(() => {
                        setbtnLoading(false)
                        setOpen(false)
                        setmsgDeleted(true)
                    })
                    .catch(err => alert(err.message))
            }
        }
    }

    const handleReadMsg = async (e) => {
        if (!msgTo) {
            if (!isViewed) {
                if (userCurrentStatus === 'SCHOOL') {
                    updateDoc(doc(dB, "SCHOOLS", userId, userPathCollection, "MESSAGES_RECIEVED", "MESSAGES", msgId), {
                        IS_VIEWED: true
                    }).catch(err => alert(err.message))
                } else {
                    updateDoc(doc(dB, "USERS", userId, userPathCollection, "MESSAGES_RECIEVED", "MESSAGES", msgId), {
                        IS_VIEWED: true
                    }).catch(err => alert(err.message))
                }
            }
        }
    }

    return (
        <div className='MessageBody'>
            {
                msgDeleted ? (
                    <div className='msgDeleted'>
                        <h3>Nessage from {msgToFromRegName} has been deleted.</h3>
                    </div>
                ) : (
                    <Box sx={{ width: '100%', }} id='messageBody'>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                onClick={handleReadMsg}
                            >
                                <div className='MessageBodyAccordionSummary'>
                                    {/* msg to/from */}
                                    {
                                        msgTo ? (
                                            <div className='msgFrom'>
                                                <h2>{msgTo ? 'To :' : 'From :'}</h2>
                                                <h3>{msgToFromRegName}</h3>
                                            </div>
                                        ) : (
                                            <>
                                                {
                                                    isViewed ? (
                                                        <div className='msgFrom'>
                                                            <h2>{msgTo ? 'To :' : 'From :'}</h2>
                                                            <h3>{msgToFromRegName}</h3>
                                                        </div>
                                                    ) : (
                                                        <Badge anchorOrigin={{ vertical: 'top', horizontal: 'left', }} color="secondary" badgeContent=" " variant="dot">
                                                            <div className='msgFrom'>
                                                                <h2>{msgTo ? 'To :' : 'From :'}</h2>
                                                                <h3>{msgToFromRegName}</h3>
                                                            </div>
                                                        </Badge>
                                                    )
                                                }
                                            </>
                                        )
                                    }


                                    {/* msg sent/recieved on date */}
                                    <div className='msgOnDate'>
                                        <h2>Date :</h2>
                                        <h3>{msgTimestamp?.toDate().toDateString()}</h3>
                                    </div>

                                    {/* delete message button */}
                                    <Button onClick={handleOpen}>Delete</Button>
                                </div>
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <div className='deletePostModal'>
                                            <div className='deletePostModalText'>
                                                <h4 style={{ textAlign: 'center' }}>Do you really want to delete the message from {msgToFromRegName}?</h4>
                                            </div>
                                            <div className='deletePostModalButtons'>
                                                <LoadingButton
                                                    onClick={handleDeleteMsg}
                                                    variant='outlined'
                                                    loading={btnLoading}
                                                >
                                                    Yes
                                                </LoadingButton>
                                                {/* <Button disabled={btnLoading}>Yes</Button> */}
                                                <Button onClick={handleClose}>No</Button>
                                            </div>
                                        </div>
                                    </Box>
                                </Modal>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='MessageBodyAccordionDetails'>
                                    <div className='msgSentToBody'>
                                        <h4>{msgTo ? 'Sent To' : 'From'}</h4>
                                        <p>:</p>
                                        <h3>
                                            <Link to={msgRecieverSenderStatus === 'SCHOOL' ? `${`/schoolfriendprofile/${msgToFromId}`}` : `${`/friendsprofile/${msgToFromId}`}`} style={{ textDecoration: 'none', color: 'gray' }}>
                                                {msgToFromRegName}
                                            </Link>
                                        </h3>
                                    </div>
                                    <div className='msgSentDateBody'>
                                        <h4>Date</h4>
                                        <p>:</p>
                                        <h3>{msgTimestamp?.toDate().toDateString()}</h3>
                                    </div>
                                    <div className='msgSentTextBody'>
                                        <h4>Message</h4>
                                        <p>:</p>
                                        <h3>{msgText}</h3>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                )
            }

        </div>
    )
}

export default MessageBody
