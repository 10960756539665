import React, { useEffect, useState } from 'react'
import '../ProjectsHomeworks/HomeWorksPrincipalClassNSubject.css'
import '../ProjectsHomeworks/HomeWorksStudentsSubject.css'
import dB from '../../firebase'
import { collection, onSnapshot } from 'firebase/firestore'
import { functionClsStr } from '../../Constants/FunctionClsStr'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SyllabusCoveredPrincipalSubjects from './SyllabusCoveredPrincipalSubjects'

function SyllabusCoveredPrincipalsClsNSubject(props) {
    const {
        userId, userPathCollection, userCurrentStatus, userSchoolId, userSchoolPathCollection, isPrinciVerified,
    } = props

    // fetch class list from firebase
    const [gotClsList, setgotClsList] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userSchoolId && userSchoolPathCollection) {
                onSnapshot(collection(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES"), (snap) => {
                    setgotClsList(snap.docs.map((listDoc) => listDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [userSchoolId, userSchoolPathCollection])

    // sort english, marathi in order
    const [totalClsList, settotalClsList] = useState([])
    useEffect(() => {
        if (totalClsList.length > 0) {
            totalClsList.splice(0, totalClsList.length)
        }
        var aaa = true
        if (aaa === true) {
            if (gotClsList.length > 0) {
                var bothCls = []
                var engCls = []
                var marCls = []
                gotClsList.forEach(cls => {
                    if (cls.includes('Eng')) {
                        engCls.push(cls)
                    } else {
                        marCls.push(cls)
                    }
                })
                bothCls = engCls.concat(marCls)
                settotalClsList(bothCls)
            }
        }
        return () => { aaa = false }

    }, [gotClsList])

    const [classInfoData, setclassInfoData] = useState([])
    useEffect(() => {
        if (classInfoData.length > 0) {
            classInfoData.splice(0, classInfoData.length)
        }
        var aaa = true
        if (aaa === true) {
            if (totalClsList.length > 0) {
                totalClsList.forEach(clsStr => {
                    var xyz = functionClsStr(clsStr)
                    setclassInfoData(prev => { return [...prev, xyz] })
                })
            }
        }
        return () => { aaa = false }
    }, [totalClsList])

    return (
        <div>
            <div className='HomeWorksPrincipalClassNSubject'>
                {
                    classInfoData.map(oneClassData => (
                        <div key={oneClassData.classPath} className='princiSubRow'>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <div key={oneClassData.classPath} className='princiButtonText'>
                                        <h4>{oneClassData.medium} Medium | {oneClassData.classNum} | {oneClassData.section}</h4>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div key={oneClassData.classPath} className='princiSubRowPanelData'>

                                        <SyllabusCoveredPrincipalSubjects
                                            userId={userId}
                                            userPathCollection={userPathCollection}
                                            userCurrentStatus={userCurrentStatus}
                                            userSchoolId={userSchoolId}
                                            userSchoolPathCollection={userSchoolPathCollection}
                                            isPrinciVerified={isPrinciVerified}
                                            classPath={oneClassData.classPath}
                                            medium={oneClassData.medium}
                                            classNum={oneClassData.classNum}
                                            section={oneClassData.section}
                                        />
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default SyllabusCoveredPrincipalsClsNSubject