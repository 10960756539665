import React, { useState, useEffect } from 'react'
import '../AllocateTeacher/Allocate.css'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { collection, doc, onSnapshot, writeBatch } from 'firebase/firestore'
import dB from '../../firebase'

function AllocateOneTeacher(props) {

    const { medium, classNum, section, classPath, schoolId,
        schoolPathCollection, schoolFullName } = props

    const [AllTeachersIds, setAllTeachersIds] = useState([])
    useEffect(() => {
        if (schoolId && schoolPathCollection) {
            onSnapshot(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "ALL_TEACHERS", "ALL_TEACHERS_LIST"), (snap) => {
                setAllTeachersIds(snap.docs.map(teachDoc => teachDoc.id,))
            })
        }
    }, [schoolId, schoolPathCollection])

    const [clsPathData, setclsPathData] = useState(null)
    useEffect(() => {
        if (schoolId && schoolPathCollection && classPath) {
            onSnapshot(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "CLASSES_DETAILS", "CLASSES", classPath), (clsTeachDoc) => {
                if (clsTeachDoc.exists()) {
                    setclsPathData(clsTeachDoc.data())
                }
            })
        }
    }, [schoolId, classPath, schoolPathCollection])






    const [allTeachersData, setallTeachersData] = useState([])
    useEffect(() => {
        setallTeachersData([])
        if (AllTeachersIds.length > 0) {
            AllTeachersIds.map((teachId) => {
                onSnapshot(doc(dB, "USERS", teachId), (teachDoc) => {
                    if (teachDoc.exists()) {
                        var abc = {
                            teacherId: teachId,
                            teacherFullName: teachDoc.data()?.USER_FULL_NAME,
                            teacherPathCollection: teachDoc.data()?.USER_NAME,
                            teacherDisplayPic: teachDoc.data()?.USER_DISPLAY_PIC,
                        }
                        setallTeachersData(prev => [...prev, abc])
                    }
                })
            })
        }
    }, [AllTeachersIds])

    const [showAllocate, setshowAllocate] = useState(false)

    const [allocatedTeachData, setallocatedTeachData] = useState(null)
    useEffect(() => {
        if (clsPathData?.CLASS_TEACHER && clsPathData?.CLASS_TEACHER_PATH_COLLECTION) {
            onSnapshot(doc(dB, "USERS", clsPathData?.CLASS_TEACHER), (userDoc) => {
                if (userDoc.exists()) {
                    setallocatedTeachData(userDoc.data())
                }
            })
        }
    }, [clsPathData?.CLASS_TEACHER, clsPathData?.CLASS_TEACHER_PATH_COLLECTION])





    const [selectedTeacher, setselectedTeacher] = useState(null)
    const [btnLoading, setbtnLoading] = useState(false)
    const batch = writeBatch(dB)
    const handleSelectTeacher = (e) => {
        e.preventDefault();
        if (showAllocate) {
            if (selectedTeacher) {
                setbtnLoading(true)

                batch.update(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "CLASSES_DETAILS", "CLASSES", classPath), {
                    CLASS_TEACHER: selectedTeacher?.teacherId,
                    CLASS_TEACHER_PATH_COLLECTION: selectedTeacher?.teacherPathCollection
                })
                batch.set(doc(dB, "USERS", selectedTeacher?.teacherId, selectedTeacher?.teacherPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "CLASS_TEACHER_DATA"), {
                    CLASS_TEACHER: true,
                    MEDIUM: medium,
                    SECTION: section,
                    CLASSNUM: classNum,
                    classPath: classPath,
                })

                batch.delete(doc(dB, "USERS", clsPathData?.CLASS_TEACHER, clsPathData?.CLASS_TEACHER_PATH_COLLECTION, "E-SCHOOLING_INFO", "IS_TEACHER", "CLASS_TEACHER_DATA"))

                batch.commit().then(() => {
                    setbtnLoading(false)
                    setselectedTeacher(null)
                    setshowAllocate(false)
                }).catch((err) => {
                    alert(err)
                })
            }
        } else {
            if (selectedTeacher) {
                setbtnLoading(true)

                batch.update(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "CLASSES_DETAILS", "CLASSES", classPath), {
                    CLASS_TEACHER: selectedTeacher?.teacherId,
                    CLASS_TEACHER_PATH_COLLECTION: selectedTeacher?.teacherPathCollection
                })
                batch.set(doc(dB, "USERS", selectedTeacher?.teacherId, selectedTeacher?.teacherPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "CLASS_TEACHER_DATA"), {
                    CLASS_TEACHER: true,
                    MEDIUM: medium,
                    SECTION: section,
                    CLASSNUM: classNum,
                    classPath: classPath,
                })

                batch.commit().then(() => {
                    setbtnLoading(false)
                    setselectedTeacher(null)
                    setshowAllocate(false)
                }).catch((err) => {
                    alert(err)
                })
            }
        }

    }

    const selectorElement = (
        <FormControl name='selectedTeacher' variant="outlined">
            <InputLabel htmlFor="selectedTeacher">Select Teacher</InputLabel>
            <Select
                sx={{ width: '200px', height: '50px' }}
                id="selectedTeacher"
                name='selectedTeacher'
                label="Select Teacher "
                inputProps={{
                    name: 'selectedTeacher',
                    id: 'selectedTeacher',
                }}
                value={selectedTeacher}
                onChange={(e) => {
                    e.preventDefault();
                    setselectedTeacher(e.target.value)
                }}
            >
                <MenuItem key='' value=''></MenuItem>
                {
                    allTeachersData.map(teacher => (
                        <MenuItem style={{ display: clsPathData?.CLASS_TEACHER === teacher.teacherId ? 'none' : 'block' }} key={teacher.teacherFullName} value={teacher}>{teacher.teacherFullName}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )

    const buttonElement = (
        <LoadingButton
            variant="contained"
            disabled={btnLoading}
            loading={btnLoading}
            onClick={handleSelectTeacher}
        >
            Allocate
        </LoadingButton>
    )

    return (
        <div className='AllocateOneTeacher'>
            <p>{medium} Medium | {classNum} | {section}</p>

            {
                clsPathData?.CLASS_TEACHER ? (
                    <>
                        {
                            showAllocate ?
                                selectorElement : (
                                    <h3>{allocatedTeachData?.USER_FULL_NAME}</h3>
                                )
                        }
                    </>
                ) : selectorElement
            }

            {
                clsPathData?.CLASS_TEACHER ? (
                    <>
                        {
                            showAllocate ? (
                                <>
                                    {buttonElement}
                                    <h5 h5
                                        onClick={() => { setshowAllocate(false) }}
                                        style={{ textAlign: 'left', }}
                                    >
                                        Cancel
                                    </h5>
                                </>
                            ) : (
                                <>
                                    <h5
                                        onClick={() => { setshowAllocate(true) }}
                                    >
                                        Change
                                    </h5>
                                </>
                            )

                        }
                    </>
                ) : buttonElement
            }


        </div >
    )
}

export default AllocateOneTeacher