import React, { useState } from 'react';
import '../../Pages/CreateSchoolAccountSchoolDetails.css'
import { useHistory } from 'react-router-dom'
import {
    Box, FormControl, FormHelperText, IconButton, InputAdornment,
    InputLabel, MenuItem, Select, TextField
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button } from '@mui/material';

function CreateSchoolAccountSchoolDetailsNext(props) {

    const { adminFName, adminLName } = props

    const history = useHistory();

    const affiliations = ['CBSE', 'ICSE', 'Kendriya Vidyalaya', ' Jawahar Navoday Vidyalaya', ' Kasturbha Gandhi Balika Vidyalaya', ' Indian Army Public School', ' State Board School']

    const [textInput, setTextInput] = useState({
        inputSchoolName: '', inputSchoolEmail: '', inputSchoolPhoneNo: '',
        inputAddress: '', inputVillage: '', inputTehsil: '', inputCity: '', inputDistrict: '', inputPinCode: ''
    })
    const handleChangeInputs = (event) => {
        event.preventDefault()
        setTextInput(prevState => { return { ...prevState, [event.target.name]: event.target.value } });
        setshowEnteredSchool(prevState => { return { ...prevState, [event.target.name]: { error: false, helperText: '' } } })
    }

    const [dropDown, setDropDown] = useState({ affiliation: '', indianState: '' })
    const handleChnangeDropDown = (event) => {
        event.preventDefault()
        setDropDown(prevState => { return { ...prevState, [event.target.name]: event.target.value } });
        setshowEnteredSchool(prevState => { return { ...prevState, [event.target.name]: { error: false, helperText: '' } } })
    }
    const [password, setPassword] = useState({ newSchoolPassword: '', confirmSchoolPassword: '' })
    const handleChangePassword = (event) => {
        event.preventDefault()
        setPassword(prevState => { return { ...prevState, [event.target.name]: event.target.value } });
        setshowEnteredSchool(prevState => { return { ...prevState, [event.target.name]: { error: false, helperText: '' } } })
    }

    const [showNewSchoolPassword, setShowNewSchoolPassword] = useState(false)
    const handleClickShowNewSchoolPassword = (e) => { e.preventDefault(); setShowNewSchoolPassword(!showNewSchoolPassword); };
    const handleMouseDownNewSchoolPassword = (event) => { event.preventDefault(); };

    const [showConfirmSchoolPassword, setshowConfirmSchoolPassword] = useState(false)
    const handleClickShowConfirmSchoolPassword = (e) => { e.preventDefault(); setshowConfirmSchoolPassword(!showConfirmSchoolPassword); };
    const handleMouseDownConfirmSchoolPassword = (event) => { event.preventDefault(); };

    const [showEnteredSchool, setshowEnteredSchool] = useState({
        inputSchoolName: { error: false, helperText: '' },
        affiliation: { error: false, helperText: '' },
        inputSchoolPhoneNo: { error: false, helperText: '' },
        inputSchoolEmail: { error: false, helperText: '' },
        newSchoolPassword: { error: false, helperText: '' },
        confirmSchoolPassword: { error: false, helperText: '' },

        inputAddress: { error: false, helperText: '' },
        inputCity: { error: false, helperText: '' },
        inputDistrict: { error: false, helperText: '' },
        inputPinCode: { error: false, helperText: '' },
        indianState: { error: false, helperText: '' },
    })


    const states = ['Andhra Pradesh', 'Andaman & Nicobar Islands', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chandigarh', 'Chhattisgarh', 'Dadra & Nagar Haveli', ' Daman & Diu', 'Delhi', 'Goa', 'Gujrat', 'Haryana', 'Himachal Pradesh', 'Jammu & Kashmir', 'Jharkhand', 'Karnataka', 'Kerala', 'Ladakh', 'Lakshadweep', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Puducherry', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal']

    const handleNext = (e) => {
        e.preventDefault()
        if (textInput.inputSchoolName && textInput.inputSchoolEmail && textInput.inputSchoolPhoneNo &&
            dropDown.affiliation && password.newSchoolPassword && password.confirmSchoolPassword && textInput.inputAddress &&
            dropDown.indianState && textInput.inputCity && textInput.inputDistrict && textInput.inputPinCode
        ) {
            if (/\S+@\S+\.\S+/.test(textInput.inputSchoolEmail) && password.newSchoolPassword.length >= 8 && password.newSchoolPassword === password.confirmSchoolPassword) {
                sessionStorage.setItem('adminFName', adminFName)
                sessionStorage.setItem('adminLName', adminLName)
                sessionStorage.setItem('inputSchoolName', textInput.inputSchoolName)
                sessionStorage.setItem('inputSchoolPhoneNo', textInput.inputSchoolPhoneNo)
                sessionStorage.setItem('inputSchoolEmail', textInput.inputSchoolEmail)
                sessionStorage.setItem('affiliation', dropDown.affiliation)
                sessionStorage.setItem('confirmPassword', password.confirmSchoolPassword)
                sessionStorage.setItem('inputAddress', textInput.inputAddress)
                sessionStorage.setItem('inputCity', textInput.inputCity)
                sessionStorage.setItem('inputDistrict', textInput.inputDistrict)
                sessionStorage.setItem('inputPinCode', textInput.inputPinCode)
                sessionStorage.setItem('indianState', dropDown.indianState)
                sessionStorage.setItem('inputVillage', textInput.inputVillage)
                sessionStorage.setItem('inputTehsil', textInput.inputTehsil)

                history.push('/createschoolaccount/selectclasses')
            } else {
                if (/\S+@\S+\.\S+/.test(textInput.inputSchoolEmail)) { }
                else { setshowEnteredSchool(prevState => { return { ...prevState, inputSchoolEmail: { error: true, helperText: 'Please enter a valid email-id in the format of xyz@email.com' } } }) }

                if (password.newSchoolPassword.length >= 8) { }
                else { setshowEnteredSchool(prevState => { return { ...prevState, newSchoolPassword: { error: true, helperText: 'Password must contain at least 8 characters' } } }) }

                if (password.newSchoolPassword === password.confirmSchoolPassword) { }
                else { setshowEnteredSchool(prevState => { return { ...prevState, confirmSchoolPassword: { error: true, helperText: 'password and confirm password do not match' } } }) }
            }
        } else {
            if (!textInput.inputSchoolName) { setshowEnteredSchool(prevState => { return { ...prevState, inputSchoolName: { error: true, helperText: 'Please enter your school name' } } }) }
            if (!textInput.inputSchoolPhoneNo) { setshowEnteredSchool(prevState => { return { ...prevState, inputSchoolPhoneNo: { error: true, helperText: 'Please enter your school contact number' } } }) }
            if (!textInput.inputSchoolEmail) { setshowEnteredSchool(prevState => { return { ...prevState, inputSchoolEmail: { error: true, helperText: 'Please enter your schools Email-Id' } } }) }
            if (!dropDown.affiliation) { setshowEnteredSchool(prevState => { return { ...prevState, affiliation: { error: true, helperText: 'Please select your schools affiliation' } } }) }
            if (!password.newSchoolPassword) { setshowEnteredSchool(prevState => { return { ...prevState, newSchoolPassword: { error: true, helperText: 'Please enter the password' } } }) }
            if (!password.confirmSchoolPassword) { setshowEnteredSchool(prevState => { return { ...prevState, confirmSchoolPassword: { error: true, helperText: 'Please enter the password again to confirm' } } }) }

            if (!textInput.inputAddress) { setshowEnteredSchool(prevState => { return { ...prevState, inputAddress: { error: true, helperText: 'Please enter address or landmark.' } } }) }
            if (!textInput.inputCity) { setshowEnteredSchool(prevState => { return { ...prevState, inputCity: { error: true, helperText: 'Please enter city' } } }) }
            if (!textInput.inputDistrict) { setshowEnteredSchool(prevState => { return { ...prevState, inputDistrict: { error: true, helperText: 'Please enter district' } } }) }
            if (!textInput.inputPinCode) { setshowEnteredSchool(prevState => { return { ...prevState, inputPinCode: { error: true, helperText: 'Please enter pin code' } } }) }
            if (!dropDown.indianState) { setshowEnteredSchool(prevState => { return { ...prevState, indianState: { error: true, helperText: 'Please select state' } } }) }
        }

    }

    return (
        <>
            <div className='schoolDetailsContainerParent'>
                <div className='schoolDetailsContainerHeading'>
                    <h1>ConnectEdApp</h1>
                    <p>Create a New School Account</p>
                    <hr />
                    <div className='schoolDetailsContainer'>
                        <h4>Add School Details</h4>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '75ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    error={showEnteredSchool.inputSchoolName.error}
                                    id="inputSchoolName"
                                    type='text'
                                    name='inputSchoolName'
                                    label="School Name"
                                    value={textInput.inputSchoolName}
                                    onChange={handleChangeInputs}
                                    helperText={showEnteredSchool.inputSchoolName.helperText}
                                />
                            </div>
                            <div className='example'>
                                <h5><b>Note :</b> Dont mention village, tehsil, city or district in the school name. Enter the school name only.<br /> <b>For Example :</b> <em>"Little Angels High School".</em></h5>
                            </div>
                        </Box>
                        <div className='createschoolaccountschooldetails__nameadminaddressAddressData'>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '75ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div>
                                    <TextField
                                        error={showEnteredSchool.inputAddress.error}
                                        id="inputAddress"
                                        type='text'
                                        name='inputAddress'
                                        label="Address of School"
                                        value={textInput.inputAddress}
                                        onChange={handleChangeInputs}
                                        helperText={showEnteredSchool.inputAddress.helperText}
                                    />
                                </div>
                            </Box>
                            <div className='createschoolaccountschooldetails__nameadminaddressAddressDeatils'>

                                <div className='createschoolaccountschooldetails__nameadminaddressAddressDeatilsCityVillageTalluka'>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, width: '36.5ch' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <div>
                                            <TextField
                                                error={showEnteredSchool.inputAddress.error}
                                                id="inputVillage"
                                                type='text'
                                                name='inputVillage'
                                                label="Village Name (Optional)"
                                                value={textInput.inputVillage}
                                                onChange={handleChangeInputs}
                                                helperText={showEnteredSchool.inputAddress.helperText}
                                            />
                                        </div>
                                    </Box>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, width: '36.5ch' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <div>
                                            <TextField
                                                error={showEnteredSchool.inputAddress.error}
                                                id="inputTehsil"
                                                type='text'
                                                name='inputTehsil'
                                                label="Tehsil/Taluka Name (Optional)"
                                                value={textInput.inputTehsil}
                                                onChange={handleChangeInputs}
                                                helperText={showEnteredSchool.inputAddress.helperText}
                                            />
                                        </div>
                                    </Box>
                                </div>

                                <div className='createschoolaccountschooldetails__nameadminaddressAddressDeatilsCity'>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, width: '36.5ch' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <div>
                                            <TextField
                                                error={showEnteredSchool.inputCity.error}
                                                id="inputCity"
                                                type='text'
                                                name='inputCity'
                                                label="City"
                                                value={textInput.inputCity}
                                                onChange={handleChangeInputs}
                                                helperText={showEnteredSchool.inputCity.helperText}
                                            />
                                        </div>
                                    </Box>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, width: '36.5ch' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <div>
                                            <TextField
                                                error={showEnteredSchool.inputDistrict.error}
                                                id="inputDistrict"
                                                type='text'
                                                name='inputDistrict'
                                                label="District"
                                                value={textInput.inputDistrict}
                                                onChange={handleChangeInputs}
                                                helperText={showEnteredSchool.inputDistrict.helperText}
                                            />
                                        </div>
                                    </Box>
                                </div>
                                <div className='createschoolaccountschooldetails__nameadminaddressAddressDeatilsPinStates'>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, width: '36.5ch' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <div>
                                            <TextField
                                                error={showEnteredSchool.inputPinCode.error}
                                                id="inputPinCode"
                                                type='number'
                                                name='inputPinCode'
                                                label="PIN CODE"
                                                value={textInput.inputPinCode}
                                                onChange={handleChangeInputs}
                                                helperText={showEnteredSchool.inputPinCode.helperText}
                                            />
                                        </div>
                                    </Box>
                                    <div className='createschoolaccountschooldetails__nameadminaddressAddressDeatilsStates'>
                                        <FormControl name='studentsSchoolClassSection' error={showEnteredSchool.indianState.error} sx={{ m: 1, minWidth: "36.5ch" }}>
                                            <InputLabel name='studentsSchoolClassSection' id="studentsSchoolClassSection">State</InputLabel>
                                            <Select
                                                labelId="studentsSchoolClassSection"
                                                id="indianState"
                                                name='indianState'
                                                value={dropDown.indianState}
                                                onChange={handleChnangeDropDown}
                                                label="State"
                                            >
                                                <MenuItem aria-label="None" value="" />
                                                {states.map((state) => (
                                                    <MenuItem key={state} value={state}>
                                                        {state}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText id="studentsSchoolClassSection"><p>{showEnteredSchool.indianState.helperText}</p></FormHelperText>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='createschoolaccountschooldetails__nameadminaddressAddressDeatilsAffiliation'>
                            <div>
                                <FormControl name='studentsSchoolClassSection' error={showEnteredSchool.affiliation.error} sx={{ m: 1, minWidth: "36.5ch" }}>
                                    <InputLabel name='studentsSchoolClassSection' id="studentsSchoolClassSection">Affiliated To :</InputLabel>
                                    <Select
                                        labelId="studentsSchoolClassSection"
                                        id="affiliation"
                                        name='affiliation'
                                        value={dropDown.affiliation}
                                        onChange={handleChnangeDropDown}
                                        label="Affiliated To :"
                                    >
                                        <MenuItem aria-label="None" value="" />
                                        {affiliations.map((affiliation) => (
                                            <MenuItem key={affiliation} value={affiliation}>
                                                {affiliation}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText id="studentsSchoolClassSection"><p>{showEnteredSchool.affiliation.helperText}</p></FormHelperText>
                                </FormControl>
                            </div>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '35.5ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div>
                                    <TextField
                                        error={showEnteredSchool.inputSchoolPhoneNo.error}
                                        id="inputSchoolPhoneNo"
                                        type='number'
                                        name='inputSchoolPhoneNo'
                                        label="Phone No."
                                        value={textInput.inputSchoolPhoneNo}
                                        onChange={handleChangeInputs}
                                        helperText={showEnteredSchool.inputSchoolPhoneNo.helperText}
                                    />
                                </div>
                            </Box>

                        </div>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '75ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    error={showEnteredSchool.inputSchoolEmail.error}
                                    id="inputSchoolEmail"
                                    type='email'
                                    name='inputSchoolEmail'
                                    label="Email Id of School"
                                    value={textInput.inputSchoolEmail}
                                    onChange={handleChangeInputs}
                                    helperText={showEnteredSchool.inputSchoolEmail.helperText}
                                />
                            </div>
                        </Box>
                        <div className='createschoolaccountschooldetails__nameadminaddress'>
                            <h4>Password</h4>
                            <p>Please enter the password and remember it for the future use. Dont Share with others.</p>
                            <div>
                                <FormControl error={showEnteredSchool.newSchoolPassword.error} sx={{ m: 1, width: '36.5ch' }} variant="outlined">
                                    <InputLabel htmlFor="newSchoolPassword">Enter New Password</InputLabel>
                                    <OutlinedInput
                                        id="newSchoolPassword"
                                        type={showNewSchoolPassword ? 'text' : 'password'}
                                        name='newSchoolPassword'
                                        value={password.newSchoolPassword}
                                        onChange={handleChangePassword}

                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowNewSchoolPassword}
                                                    onMouseDown={handleMouseDownNewSchoolPassword}
                                                    edge="end"
                                                >
                                                    {showNewSchoolPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Enter New Password"
                                    />
                                    <FormHelperText id="newSchoolPassword"><p>{showEnteredSchool.newSchoolPassword.helperText}</p></FormHelperText>
                                </FormControl>
                                <FormControl error={showEnteredSchool.confirmSchoolPassword.error} sx={{ m: 1, width: '36.5ch' }} variant="outlined">
                                    <InputLabel htmlFor="newSchoolPassword">Confirm New Password</InputLabel>
                                    <OutlinedInput
                                        id="confirmSchoolPassword"
                                        type={showConfirmSchoolPassword ? 'text' : 'password'}
                                        name='confirmSchoolPassword'
                                        value={password.confirmSchoolPassword}
                                        onChange={handleChangePassword}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmSchoolPassword}
                                                    onMouseDown={handleMouseDownConfirmSchoolPassword}
                                                    edge="end"
                                                >
                                                    {showConfirmSchoolPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Confirm New Password"
                                    />
                                    <FormHelperText id="confirmSchoolPassword"><p>{showEnteredSchool.confirmSchoolPassword.helperText}</p></FormHelperText>
                                </FormControl>
                            </div>
                            <Button onClick={handleNext}>Continue</Button>
                            <hr />
                        </div>

                        <hr />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateSchoolAccountSchoolDetailsNext