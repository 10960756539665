import React, { useEffect, useState } from 'react'
import SchoolTimeTableSchoolClsNSubUpload from './SchoolTimeTableSchoolClsNSubUpload'

import '../ProjectsHomeworks/HomeWorksPrincipalClassNSubject.css'
import '../ProjectsHomeworks/HomeWorksStudentsSubject.css'

import dB from '../../firebase';
import { collection, doc, onSnapshot } from 'firebase/firestore'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

function SchoolTimeTableSchoolClsNSubData(props) {

    const { classPath, medium, classNum, section, userId, userPathCollection,
        userCurrentStatus, userSchoolId, userSchoolPathCollection, isPrinciVerified } = props;

    const [timeTableData, settimeTableData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userSchoolId && userSchoolPathCollection && classPath) {
                onSnapshot(doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", classPath, "TIMETABLE", "TIMETABLE_IMAGE"), (snap) => {
                    if (snap.exists()) {
                        settimeTableData(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [classPath, userSchoolId, userSchoolPathCollection])


    return (
        <div className='princiSubRow'>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <div className='princiButtonText' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h4>{medium} Medium | {classNum} | {section} |
                            {
                                timeTableData ? (
                                    <span style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600', color: 'gray' }}>
                                        Updated On : <span style={{ color: 'var(--connected-color)' }}>{timeTableData?.TIMESTAMP?.toDate().toDateString()} </span>
                                    </span>
                                ) : (
                                    <>
                                        <span style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600', color: 'red' }}>Not yet Updated</span>
                                    </>
                                )
                            }

                        </h4>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='princiSubRowPanelData'>
                        <h3>Timetable of <b style={{ color: 'var(--connected-color)' }}>({medium} Medium · {classNum} · {section})</b></h3>

                        <SchoolTimeTableSchoolClsNSubUpload
                            userId={userId}
                            userPathCollection={userPathCollection}
                            userCurrentStatus={userCurrentStatus}
                            userSchoolId={userSchoolId}
                            userSchoolPathCollection={userSchoolPathCollection}
                            isPrinciVerified={isPrinciVerified}
                            classPath={classPath}
                            medium={medium}
                            classNum={classNum}
                            section={section}
                            TTtimeStamp={timeTableData?.TIMESTAMP}
                            TTImage={timeTableData?.TIMETABLE_IMAGE}
                        />
                    </div>
                </AccordionDetails>

            </Accordion>

        </div>
    )
}

export default SchoolTimeTableSchoolClsNSubData