import React, { useState, useEffect } from 'react'
import '../CreateNewAccount/CreateNewAccountInfoParents.css';

// import dB, { auth } from '../firebase';
import { useHistory } from 'react-router';
import { collection, doc, onSnapshot, serverTimestamp, writeBatch } from 'firebase/firestore';
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';

import {
    Autocomplete, Box, FormControl, FormControlLabel, FormHelperText, FormLabel,
    InputLabel, MenuItem, Radio, RadioGroup, Select, TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB, { auth } from '../../firebase';
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec';
import { returnSection } from '../../Constants/FuncMedium';


function CreateNewAccountInfoParents(props) {

    const { fName, lName, userFullName, userMobNum, userPwd, userGender, userDOB, userEmailId } = props
    const history = useHistory()

    const [requestSent, setrequestSent] = useState(false)

    // ////////////////////////////////////////////////////////////////////////////

    const [inputParentsChild, setinputParentsChild] = useState({ childFName: '', childLName: '' })
    const handleChangeParentsInputChildFName = (e) => {
        e.preventDefault()
        setinputParentsChild(prevState => { return { ...prevState, childFName: e.target.value } })
        setshowEnteredParents(prevState => { return { ...prevState, childFName: { error: false, helperText: '' } } })
    }
    const handleChangeParentsInputChildLName = (e) => {
        e.preventDefault()
        setinputParentsChild(prevState => { return { ...prevState, childLName: e.target.value } })
        setshowEnteredParents(prevState => { return { ...prevState, childLName: { error: false, helperText: '' } } })
    }

    const [parentsInputValue, setparentsInputValue] = useState('');
    const [inputChildSchoolName, setinputChildSchoolName] = useState(null);

    const [parentsChildsClass, setparentsChildsClass] = useState({ childClass: '', childSection: '', errorMessage: '' })
    const handleChangeChildClass = (e) => {
        e.preventDefault();
        setparentsChildsClass(prevState => { return { ...prevState, childSection: '', childClass: e.target.value } })
        setshowEnteredParents(prevState => { return { ...prevState, childClass: { error: false, helperText: '' } } })
    }
    const handleChangeChildSection = (e) => {
        e.preventDefault();
        setparentsChildsClass(prevState => { return { ...prevState, childSection: e.target.value } })
        setshowEnteredParents(prevState => { return { ...prevState, childSection: { error: false, helperText: '' } } })
    }

    const [parentsOptions, setparentsOptions] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (parentsOptions.length > 0) {
                parentsOptions.splice(0, parentsOptions.length)
            }
            onSnapshot(collection(dB, "SCHOOLS"), (querySnapshot) => {
                querySnapshot.forEach((schoolDoc) => {
                    const newdata = schoolDoc.data()?.SCHOOL_FULL_NAME;
                    setparentsOptions(prev => { return [...prev, { id: schoolDoc.id, schoolName: newdata }] })
                });
            })
        }
        return () => { aaa = false }
    }, [])

    const [parentOptionSchoolId, setparentOptionSchoolId] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (inputChildSchoolName) {
                parentsOptions.map(option => {
                    if (option.schoolName === inputChildSchoolName) {
                        setparentOptionSchoolId(option.id)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [inputChildSchoolName, parentsOptions])

    const [returnedChildsClassList, setreturnedChildsClassList] = useState([])
    const [returnedChildsSectionList, setreturnedChildsSectionList] = useState(null);

    const [parentsSchoolPath, setparentsSchoolPath] = useState({ schoolSmallName: '', schoolId: '', })
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (returnedChildsClassList.length > 0) { returnedChildsClassList.splice(0, returnedChildsClassList.length) }
            onSnapshot(collection(dB, "SCHOOLS"), (querySnapshot) => {
                querySnapshot.forEach((queryDoc) => {
                    const newdata = queryDoc.data()?.SCHOOL_FULL_NAME;
                    if (newdata === inputChildSchoolName) {
                        const schoolCollection = queryDoc.data()?.SCHOOL_NAME;
                        setparentsSchoolPath(prevState => { return { ...prevState, schoolSmallName: schoolCollection, schoolId: queryDoc.id } })
                        onSnapshot(doc(dB, "SCHOOLS", queryDoc.id, schoolCollection, "CLASSES_DETAILS"), (clsDoc) => {
                            if (clsDoc.exists()) {
                                setreturnedChildsClassList(clsDoc.data()?.CLASS_LIST)
                            }
                        })
                    }
                });
            })
        }
        return () => { aaa = false }
    }, [inputChildSchoolName])

    const [childsMedium, setchildsMedium] = useState('')
    const handleMediumValueChange = (event) => {
        event.preventDefault()
        setchildsMedium(event.target.value);
    };
    const handleMediumChange = (e) => {
        e.preventDefault();
        setparentsChildsClass(prevState => { return { ...prevState, childClass: '', childSection: '' } })
        setshowEnteredParents(prevState => { return { ...prevState, childsMedium: { error: false, helperText: '' } } })
    }

    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            setreturnedChildsSectionList(null)
            var sectionObject = returnSection(childsMedium, parentsChildsClass.childClass, returnedChildsClassList)
            setreturnedChildsSectionList(sectionObject)
        }
        return () => { aaa = false }
    }, [childsMedium, parentsChildsClass.childClass, returnedChildsClassList])

    const [showEnteredParents, setshowEnteredParents] = useState({
        inputChildSchoolName: { error: false },
        childFName: { error: false, helperText: '' },
        childLName: { error: false, helperText: '' },
        childClass: { error: false, helperText: '' },
        childSection: { error: false, helperText: '' },
        childsMedium: { error: false, helperText: '' },
    })

    const [showBlock, setshowBlock] = useState({
        hideDataBlock: false,
        btn1Disabled: false,
        btnLoginDisabled: false,
        showGoToProfile: false
    })



    const [paarentChildClassSection, setpaarentChildClassSection] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var abc = returnMedClsSec(childsMedium, parentsChildsClass.childClass, parentsChildsClass.childSection)
            setpaarentChildClassSection(abc)

        }
        return () => { aaa = false }
    }, [childsMedium, parentsChildsClass])

    // generate 20 digit random number
    const [randomKey, setrandomKey] = useState({ randomId1: '' })
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var a = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
            setrandomKey(prevState => { return { ...prevState, randomId1: a, } })
        }
        return () => { aaa = false }
    }, [])

    const [btnLoading, setbtnLoading] = useState(false)
    var batch1 = writeBatch(dB)
    const sendRequestChild1 = async (e) => {
        e.preventDefault()
        setbtnLoading(true)
        setshowBlock(prev => { return { ...prev, btn1Disabled: true } })
        if (inputParentsChild.childFName && inputParentsChild.childLName && inputChildSchoolName && parentsChildsClass.childClass && parentsChildsClass.childSection && childsMedium) {

            const childFullName = inputParentsChild.childFName + ' ' + inputParentsChild.childLName

            await createUserWithEmailAndPassword(auth, userEmailId, userPwd).then(async (authUser) => {

                await batch1.set(doc(dB, "USERS", authUser.user.uid), {
                    USER_CREATED_ON: serverTimestamp(),
                    USER_EMAIL_ID: userEmailId,
                    USER_NAME: userFullName,
                    USER_CURRENT_STATUS: 'PARENT',
                    USER_FULL_NAME: userFullName
                });

                await batch1.set(doc(dB, "USERS", authUser.user.uid, userFullName, "REGISTRATION_INFO"), {
                    USER_NAME: userFullName,
                    USER_MOB_NO: userMobNum,
                    USER_PWD: userPwd,
                    USER_GENDER: userGender,
                    USER_DOB: userDOB,
                    F_NAME: fName,
                    L_NAME: lName,
                });

                await batch1.set(doc(dB, "USERS", authUser.user.uid, userFullName, "E-SCHOOLING_INFO", "IS_PARENT", "VERIFICATION_DETAILS"), {
                    USER_STATUS: 'PARENT',
                    VERIFIED_PARENT: false,
                });

                await batch1.set(doc(dB, "USERS", authUser.user.uid, userFullName, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", randomKey.randomId1), {
                    CHILDS_FULL_NAME: childFullName,
                    CHILDS_CLASS: parentsChildsClass.childClass,
                    CHILDS_SECTION: parentsChildsClass.childSection,
                    CHILDS_MEDIUM: childsMedium,
                    VERIFIED_CHILD: false,
                    CHILDS_SCHOOL_ID: parentOptionSchoolId
                });

                await batch1.set(doc(dB, "USERS", authUser.user.uid, userFullName, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA"), {
                    CURRENT_SELECTED_CHILD: randomKey.randomId1
                })

                await batch1.set(doc(dB, "SCHOOLS", parentsSchoolPath.schoolId, parentsSchoolPath.schoolSmallName, "CLASSES_DETAILS", "CLASSES", paarentChildClassSection, "PARENTS", "VERIFICATION_REQUESTS", "VERIFICATION_REQUESTS_DATA", authUser.user.uid, "CHILDS_LIST", randomKey.randomId1), {
                    TIMESTAMP: serverTimestamp()
                });

                await batch1.commit().then(() => {
                    setrequestSent(true)
                    setbtnLoading(false)
                    setshowBlock(prevState => { return { ...prevState, showGoToProfile: true, hideDataBlock: true } })
                    setshowBlock(prev => { return { ...prev, btn1Disabled: false } })
                }).catch(error => setparentsChildsClass(prevState => { return { ...prevState, errorMessage: error.message } }));

            }).catch(error => setparentsChildsClass(prevState => { return { ...prevState, errorMessage: error.message } }))
        } else {
            setbtnLoading(false)
            if (!inputParentsChild.childFName) { setshowEnteredParents(prevState => { return { ...prevState, childFName: { error: true, helperText: 'Please enter your childs First Name.' } } }) }
            if (!inputParentsChild.childLName) { setshowEnteredParents(prevState => { return { ...prevState, childLName: { error: true, helperText: 'Please enter your childs Last Name.' } } }) }
            if (!inputChildSchoolName) { setshowEnteredParents(prevState => { return { ...prevState, inputChildSchoolName: { error: true, helperText: 'Please select your childs school name.' } } }) }
            if (!parentsChildsClass.childClass) { setshowEnteredParents(prevState => { return { ...prevState, childClass: { error: true, helperText: 'Please select the class your child is studying in.' } } }) }
            if (!parentsChildsClass.childSection) { setshowEnteredParents(prevState => { return { ...prevState, childSection: { error: true, helperText: 'Please select your childs section here.' } } }) }
            if (!childsMedium) { setshowEnteredParents(prevState => { return { ...prevState, childsMedium: { error: true, helperText: 'Please select your childs medium here.' } } }) }
        }

    }

    const parentGoToProfile = () => {
        setbtnLoading(true)
        setshowBlock(prev => { return { ...prev, btnLoginDisabled: true } })
        signInWithEmailAndPassword(auth, userEmailId, userPwd)
            .then((userCredential) => {
                // Signed in
                setbtnLoading(false)
                setshowBlock(prev => { return { ...prev, btnLoginDisabled: false } })
            })
            .catch(error => setparentsChildsClass(prevState => { return { ...prevState, errorMessage: error.message } }))

        onAuthStateChanged(auth, (user) => {
            if (user) {
                history.push('/user');
            }
        })
        // auth.onAuthStateChanged();


    }

    return (
        <div className='createnewaccountinfo__containerParents'>
            <h3>Parent/Local-Guardian</h3>
            <h4>Please add the details of your child who is studying in Kindergarten-1(KG-1) to Class 12th</h4>
            <h5 style={{ color: 'var(--connected-orange)' }}><b>Note :</b> You can add more childs once you sign in to your profile.</h5>
            <div id='child1' style={{ display: showBlock.hideDataBlock ? 'none' : 'flex' }}>
                <div className='childOne'>
                    <div className='childsname'>
                        <div className='h5__1ChildFName'>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '36ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div>
                                    <TextField
                                        error={showEnteredParents.childFName.error}
                                        name='childFName1'
                                        id="childFName1"
                                        type='text'
                                        label="Childs First Name"
                                        // placeholder='E-mail Id'
                                        value={inputParentsChild.childFName}
                                        onChange={handleChangeParentsInputChildFName}
                                        helperText={showEnteredParents.childFName.helperText}
                                    />
                                </div>
                            </Box>
                        </div>
                        <div className='h5__1ChildLName'>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '36ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div>
                                    <TextField
                                        error={showEnteredParents.childLName.error}
                                        id="childLName1"
                                        name='childLName1'
                                        type='text'
                                        label="Childs Last Name"
                                        // placeholder='E-mail Id'
                                        value={inputParentsChild.childLName}
                                        onChange={handleChangeParentsInputChildLName}
                                        helperText={showEnteredParents.childLName.helperText}
                                    />
                                </div>
                            </Box>
                        </div>
                    </div>
                    <div className='h5__1ChildSChoolName'>
                        <Autocomplete
                            value={inputChildSchoolName}
                            onChange={(e, newValue) => {
                                setinputChildSchoolName(newValue)
                            }}
                            inputValue={parentsInputValue}
                            onInputChange={(event, newInputValue) => {
                                setparentsInputValue(newInputValue);
                                if (returnedChildsClassList.length > 0) {
                                    returnedChildsClassList.splice(0, returnedChildsClassList.length)
                                }
                                setshowEnteredParents(prevState => { return { ...prevState, inputChildSchoolName: { error: false, helperText: '' } } })
                                setparentsChildsClass(prevState => { return { ...prevState, childClass: '', childSection: '' } })
                                setchildsMedium('')
                            }}

                            disablePortal
                            id="inputChildSchoolName1"
                            name='inputChildSchoolName1'
                            options={parentsOptions.map((option) => option.schoolName)}
                            sx={{ width: '75ch' }}
                            renderInput={(params) =>
                                <TextField
                                    id="inputChildSchoolName1"
                                    name='inputChildSchoolName1'
                                    {...params}
                                    margin="normal"
                                    label="Search your child's school here by school name, village ,city or district"
                                    variant="outlined"
                                    error={showEnteredParents.inputChildSchoolName.error}
                                />
                            }
                        />
                        <div className='noAvailable'>
                            <h5><b>Note : </b>If School you are looking for does not appear in the list, then your school is yet to create an account. Ask your school to create account first. Till then you can use this platform to follow other schools and make new friends.</h5>
                        </div>
                    </div>
                    <div className='ChildsClassnSection'>
                        <div className='h5__1ChildMedium'>
                            <FormControl error={showEnteredParents.childsMedium.error} component="fieldset" onChange={handleMediumChange}>
                                <div className='parents__radioMediumMuiDiv'>
                                    <FormLabel component="legend">Medium</FormLabel>
                                    <RadioGroup aria-label="studentsMedium" name="studentsMedium" value={childsMedium} onChange={handleMediumValueChange}>
                                        <div className='parents__radioMediumMui'>
                                            <FormControlLabel value="English Medium" control={<Radio />} label="English Medium" />
                                            <FormControlLabel value="Marathi Medium" control={<Radio />} label="Marathi Medium" />
                                        </div>
                                    </RadioGroup>
                                </div>
                            </FormControl>
                            <FormHelperText id="childsMedium"><p>{showEnteredParents.childsMedium.helperText}</p></FormHelperText>
                        </div>
                        <div className='class_section'>
                            <div className='ChildsClassNum'>
                                <div className='h5__1ChildClass'>
                                    <FormControl name='childClass' error={showEnteredParents.childClass.error} sx={{ m: 1, minWidth: "40ch" }}>
                                        <InputLabel id="childClass" name='childClass'>Class</InputLabel>
                                        <Select
                                            labelId="childClass"
                                            id="childClass"
                                            name='childClass'
                                            value={parentsChildsClass.childClass}
                                            onChange={handleChangeChildClass}
                                            label="Class"
                                        // onChange={handleChange}
                                        >
                                            <MenuItem style={{ display: childsMedium != null ? 'none' : 'block' }} aria-label="None" value="" />

                                            <MenuItem style={{ display: childsMedium === 'English Medium' && returnedChildsClassList.includes('EngPreNurserySecA') ? 'block' : 'none' }} id='Eng Pre Nursery' key='Eng Pre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'English Medium' && returnedChildsClassList.includes('EngNurserySecA') ? 'block' : 'none' }} id='Eng Nursery' key='Eng Nursery' value='Nursery'>Nursery</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'English Medium' && returnedChildsClassList.includes('EngKG1SecA') ? 'block' : 'none' }} id='Eng Kindergarten 1(KG-1)' key='Eng Kindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'English Medium' && returnedChildsClassList.includes('EngKG2SecA') ? 'block' : 'none' }} id='Eng Kindergarten 2(KG-2)' key='Eng Kindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass1SecA') ? 'block' : 'none' }} id='Eng Class 1' key='Eng Class 1' value='Class 1'>Class 1</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass2SecA') ? 'block' : 'none' }} id='Eng Class 2' key='Eng Class 2' value='Class 2'>Class 2</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass3SecA') ? 'block' : 'none' }} id='Eng Class 3' key='Eng Class 3' value='Class 3'>Class 3</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass4SecA') ? 'block' : 'none' }} id='Eng Class 4' key='Eng Class 4' value='Class 4'>Class 4</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass5SecA') ? 'block' : 'none' }} id='Eng Class 5' key='Eng Class 5' value='Class 5'>Class 5</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass6SecA') ? 'block' : 'none' }} id='Eng Class 6' key='Eng Class 6' value='Class 6'>Class 6</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass7SecA') ? 'block' : 'none' }} id='Eng Class 7' key='Eng Class 7' value='Class 7'>Class 7</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass8SecA') ? 'block' : 'none' }} id='Eng Class 8' key='Eng Class 8' value='Class 8'>Class 8</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass9SecA') ? 'block' : 'none' }} id='Eng Class 9' key='Eng Class 9' value='Class 9'>Class 9</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass10SecA') ? 'block' : 'none' }} id='Eng Class 10' key='Eng Class 10' value='Class 10'>Class 10</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass11ScienceSecA') ? 'block' : 'none' }} id='Eng Class 11(Science)' key='Eng Class 11(Science)' value='Class 11(Science)'>Class 11(Science)</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass11CommerceSecA') ? 'block' : 'none' }} id='Eng Class 11(Commerce)' key='Eng Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass12ScienceSecA') ? 'block' : 'none' }} id='Eng Class 12(Science)' key='Eng Class 12(Science)' value='Class 12(Science)'>Class 12(Science)</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass12CommerceSecA') ? 'block' : 'none' }} id='Eng Class 12(Commerce)' key='Eng Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>

                                            <MenuItem style={{ display: childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarPreNurserySecA') ? 'block' : 'none' }} id='Mar Pre Nursery' key='Mar Pre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarNurserySecA') ? 'block' : 'none' }} id='Mar Nursery' key='Mar Nursery' value='Nursery'>Nursery</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarKG1SecA') ? 'block' : 'none' }} id='Mar Kindergarten 1(KG-1)' key='Mar Kindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarKG2SecA') ? 'block' : 'none' }} id='Mar Kindergarten 2(KG-2)' key='Mar Kindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>

                                            <MenuItem style={{ display: childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass1SecA') ? 'block' : 'none' }} id='Mar Class 1' key='Mar Class 1' value='Class 1'>Class 1</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass2SecA') ? 'block' : 'none' }} id='Mar Class 2' key='Mar Class 2' value='Class 2'>Class 2</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass3SecA') ? 'block' : 'none' }} id='Mar Class 3' key='Mar Class 3' value='Class 3'>Class 3</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass4SecA') ? 'block' : 'none' }} id='Mar Class 4' key='Mar Class 4' value='Class 4'>Class 4</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass5SecA') ? 'block' : 'none' }} id='Mar Class 5' key='Mar Class 5' value='Class 5'>Class 5</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass6SecA') ? 'block' : 'none' }} id='Mar Class 6' key='Mar Class 6' value='Class 6'>Class 6</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass7SecA') ? 'block' : 'none' }} id='Mar Class 7' key='Mar Class 7' value='Class 7'>Class 7</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass8SecA') ? 'block' : 'none' }} id='Mar Class 8' key='Mar Class 8' value='Class 8'>Class 8</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass9SecA') ? 'block' : 'none' }} id='Mar Class 9' key='Mar Class 9' value='Class 9'>Class 9</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass10SecA') ? 'block' : 'none' }} id='Mar Class 10' key='Mar Class 10' value='Class 10'>Class 10</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass11ArtsSecA') ? 'block' : 'none' }} id='Mar Class 11(Arts)' key='Mar Class 11(Arts)' value='Class 11(Arts)'>Class 11(Arts)</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass11CommerceSecA') ? 'block' : 'none' }} id='Mar Class 11(Commerce)' key='Mar Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass12ArtsSecA') ? 'block' : 'none' }} id='Mar Class 12(Arts)' key='Mar Class 12(Arts)' value='Class 12(Arts)'>Class 12(Arts)</MenuItem>
                                            <MenuItem style={{ display: childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass12CommerceSecA') ? 'block' : 'none' }} id='Mar Class 12(Commerce)' key='Mar Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>
                                        </Select>
                                        <FormHelperText id="childClass"><p>{showEnteredParents.childClass.helperText}</p></FormHelperText>
                                    </FormControl>
                                </div>
                            </div>
                            <div className='ChildsClassSection'>
                                <div className='h5__1ChildSection'>
                                    <FormControl name='childSection' error={showEnteredParents.childSection.error} sx={{ m: 1, minWidth: "30ch" }}>
                                        <InputLabel name='childSection' id="childSection">Section</InputLabel>
                                        <Select
                                            labelId="childSection"
                                            id="childSection"
                                            name='childSection'
                                            value={parentsChildsClass.childSection}
                                            onChange={handleChangeChildSection}
                                            label="Section"
                                        >
                                            <MenuItem style={{ display: parentsChildsClass.childClass === '' ? 'block' : 'none' }} aria-label="None" value="" />

                                            <MenuItem style={{ display: returnedChildsSectionList?.SectionA === true ? 'block' : 'none' }} key='Section A' value='Section A'>Section A</MenuItem>
                                            <MenuItem style={{ display: returnedChildsSectionList?.SectionB === true ? 'block' : 'none' }} key='Section B' value='Section B'>Section B</MenuItem>
                                            <MenuItem style={{ display: returnedChildsSectionList?.SectionC === true ? 'block' : 'none' }} key='Section C' value='Section C'>Section C</MenuItem>
                                            <MenuItem style={{ display: returnedChildsSectionList?.SectionD === true ? 'block' : 'none' }} key='Section D' value='Section D'>Section D</MenuItem>
                                            <MenuItem style={{ display: returnedChildsSectionList?.SectionE === true ? 'block' : 'none' }} key='Section E' value='Section E'>Section E</MenuItem>
                                        </Select>
                                        <FormHelperText id="childSection"><p>{showEnteredParents.childSection.helperText}</p></FormHelperText>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LoadingButton
                        onClick={sendRequestChild1}
                        loading={btnLoading}
                        variant="outlined"
                    >
                        Send Verification Request to School
                    </LoadingButton>
                </div>

            </div>
            <div id='afterClick1' className='afterClick1'>
                <h4>{parentsChildsClass.errorMessage}</h4>
                {
                    requestSent && (
                        <h5 id='requestSent1'>Request Sent to School..</h5>
                    )
                }

            </div>

            {
                showBlock.showGoToProfile && (
                    <div id='goToProfileButton' className='goToProfileButton'>
                        <LoadingButton
                            onClick={parentGoToProfile}
                            loading={btnLoading}
                            variant="outlined"
                        >
                            Go to profile
                        </LoadingButton>
                    </div>
                )
            }

        </div >
    )
}

export default CreateNewAccountInfoParents