import React, { useState, useEffect } from 'react'
import '../ProjectsHomeworks/HomeWorksTeachersClassNSubject.css'
import '../SyllabusCovered/SyllabusCovered.css'
import dB from '../../firebase'
import { arrayUnion, collection, doc, onSnapshot, setDoc, writeBatch } from 'firebase/firestore'
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec'

import { LoadingButton } from '@mui/lab'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, TextField } from '@mui/material'

function SyllabusCoveredTeachersClsNSub(props) {

    const { userId, userPathCollection, userSchoolId, userSchoolPathCollection } = props

    const [teachersData, setteachersData] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "IS_TEACHER_DATA", "SUBJECTS_TEACH"), (snap) => {
                    setteachersData(snap.docs.map((subDoc) => ({
                        subjectId: subDoc.id,
                        subjectData: subDoc.data(),
                    })))
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])


    const [selectedSubData, setselectedSubData] = useState({
        isTeachSubVerified: '', teacherClass: '', teacherMedium: '',
        teacherSection: '', teacherSubject: '', teacherSubjectId: ''
    })
    const handleClickSelectClass = async (data) => {
        await setselectedSubData(prev => {
            return {
                ...prev,
                isTeachSubVerified: data?.subjectData?.VERIFIED_TEACHER,
                teacherClass: data?.subjectData?.TEACH_CLASS,
                teacherMedium: data?.subjectData?.TEACH_MEDIUM,
                teacherSection: data?.subjectData?.TEACH_CLASS_SEC,
                teacherSubject: data?.subjectData?.TEACH_CLASS_SEC_SUB,
                teacherSubjectId: data?.subjectId
            }
        })
        setsentText(false)
    }


    // Setting the Teachers Medium Class n Section
    const [teacherMedClsSec, setteacherMedClsSec] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (selectedSubData.isTeachSubVerified) {
                setteacherMedClsSec('')
                var abc = returnMedClsSec(selectedSubData.teacherMedium, selectedSubData.teacherClass, selectedSubData.teacherSection)
                setteacherMedClsSec(abc)
            }
        }
        return () => { aaa = false }
    }, [selectedSubData.teacherMedium, selectedSubData.teacherClass, selectedSubData.teacherSection, selectedSubData.isTeachSubVerified])

    const [syllabusCovered, setsyllabusCovered] = useState(null)
    useEffect(() => {
        setsyllabusCovered(null)
        if (userSchoolId && userSchoolPathCollection && selectedSubData.teacherSubject && teacherMedClsSec) {
            onSnapshot(doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", teacherMedClsSec, "SYLLABUS_COMPLETED", selectedSubData.teacherSubject), (covData) => {
                if (covData.exists()) {
                    setsyllabusCovered(covData.data())
                }
            })
        }
    }, [selectedSubData.teacherSubject, teacherMedClsSec, userSchoolId, userSchoolPathCollection])


    const [sentText, setsentText] = useState(false)
    const batch = writeBatch(dB);
    const [btnLoading, setbtnLoading] = useState(false)
    const [chapterName, setchapterName] = useState('')
    const handlePublishChapter = (e) => {
        e.preventDefault();
        if (chapterName) {
            setbtnLoading(true)

            if (syllabusCovered) {

                const abc = doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", teacherMedClsSec, "SYLLABUS_COMPLETED", selectedSubData.teacherSubject)

                batch.update(abc, { CURRENT_CHAPTER: chapterName })

                batch.update(abc, { CHAPTERS_COMPLETED: arrayUnion(syllabusCovered?.CURRENT_CHAPTER) })

                batch.commit()
                    .then(() => {
                        setbtnLoading(false)
                        setchapterName('')
                        setsentText(true)
                    }).catch((error) => {
                        alert(error);
                    })
            } else {
                setDoc(doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", teacherMedClsSec, "SYLLABUS_COMPLETED", selectedSubData.teacherSubject), {
                    CURRENT_CHAPTER: chapterName,
                    CHAPTERS_COMPLETED: []
                }).then(() => {
                    setbtnLoading(false)
                    setchapterName('')
                    setsentText(true)
                }).catch((error) => {
                    alert(error);
                })
            }

        }
    }

    const handleMoveChapter = (e) => {
        e.preventDefault();
        setbtnLoading(true)
        const abc = doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", teacherMedClsSec, "SYLLABUS_COMPLETED", selectedSubData.teacherSubject)

        batch.update(abc, { CURRENT_CHAPTER: '' })

        batch.update(abc, { CHAPTERS_COMPLETED: arrayUnion(syllabusCovered?.CURRENT_CHAPTER) })

        batch.commit()
            .then(() => {
                setbtnLoading(false)
            }).catch((error) => {
                alert(error);
            })

    }

    return (
        <div className='homeworksteachersclassnsubject'>
            <div className='homeworksteachersclassnsubject__left'>
                {
                    teachersData.map(data => (
                        <div key={data?.subjectId} className={`teacherClassTab`} onClick={(e) => { e.preventDefault(); handleClickSelectClass(data) }}>
                            <h4>{data?.subjectData?.TEACH_MEDIUM}</h4>
                            <h3>{data?.subjectData?.TEACH_CLASS} | {data?.subjectData?.TEACH_CLASS_SEC}</h3>
                            <h2>{data?.subjectData?.TEACH_CLASS_SEC_SUB}</h2>
                        </div>
                    ))
                }
            </div>

            {
                selectedSubData.teacherSubjectId && (
                    <div className='homeworksteachersclassnsubject__right'>
                        {
                            selectedSubData.isTeachSubVerified ? (
                                <div>
                                    <div className='headingHeading'>
                                        <h4>{selectedSubData.teacherMedium} | {selectedSubData.teacherClass} | {selectedSubData.teacherSection} </h4>
                                        <div className='headingHeadingSubject'>
                                            <h2>Subject</h2>
                                            <h3>:</h3>
                                            <h4>{selectedSubData.teacherSubject}</h4>
                                        </div>
                                        <hr />
                                    </div>

                                    <Box sx={{ position: 'relative' }} id='addChapter'>
                                        <Accordion >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <div className='publishHomeWrokHere'>
                                                    <h3>Click Here to Add Chapter you are currently teaching</h3>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className='addnewHw__Details'>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: "20px" }} className='addnewHw recentlyAdddedHead'>
                                                        {/* <h2>Add Next Chapter</h2> */}
                                                        <TextField
                                                            sx={{ width: '500px' }}
                                                            name='nextChapter'
                                                            value={chapterName}
                                                            onChange={(e) => {
                                                                e.preventDefault();
                                                                setchapterName(e.target.value);
                                                                setsentText(false)
                                                            }}
                                                            id="nextChapter"
                                                            type='text'
                                                            label={`Type here the Name of the Chapter you are currently teaching`}
                                                            variant="outlined"
                                                        />
                                                        <div className='buttonPublishCancel'>
                                                            <LoadingButton
                                                                onClick={handlePublishChapter}
                                                                variant='outlined'
                                                                disabled={btnLoading}
                                                                loading={btnLoading}
                                                            >
                                                                Publish
                                                            </LoadingButton>
                                                        </div>
                                                    </div>
                                                    {
                                                        sentText && (
                                                            <div className='publishedSuccess'>
                                                                <h3> New Chapter added successfully</h3>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>

                                    <div style={{ marginTop: "10px", marginLeft: "40px" }} className='recentlyAdddedHW recentlyAdddedHead'>
                                        <h2>Ongoing/Current Chapter</h2>
                                        {
                                            syllabusCovered?.CURRENT_CHAPTER ? (
                                                <div className='syllabusCovered' style={{ marginLeft: "50px", marginTop: "10px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    {
                                                        syllabusCovered?.CURRENT_CHAPTER && (
                                                            <p style={{ fontSize: "18px", fontWeight: "700" }}>{syllabusCovered?.CHAPTERS_COMPLETED?.length > 0 ? syllabusCovered?.CHAPTERS_COMPLETED?.length + 1 : 0 + 1}. {syllabusCovered?.CURRENT_CHAPTER}</p>
                                                        )
                                                    }
                                                    <LoadingButton
                                                        style={{ marginLeft: '20px' }}
                                                        disabled={btnLoading}
                                                        loading={btnLoading}
                                                        onClick={handleMoveChapter}
                                                    >
                                                        Move to Completed
                                                    </LoadingButton>
                                                </div>
                                            ) : (
                                                <p style={{ marginTop: "5px", fontSize: "18px", fontWeight: "700", marginLeft: '50px' }}>None</p>
                                            )
                                        }

                                    </div>

                                    <div style={{ marginTop: "20px", marginLeft: "40px" }} className='recentlyAdddedHW recentlyAdddedHead'>
                                        <h2>Completed Chapters</h2>
                                        <div style={{ marginLeft: "50px", marginTop: "10px" }}>
                                            {
                                                syllabusCovered?.CHAPTERS_COMPLETED?.length > 0 ? (
                                                    <>
                                                        {
                                                            syllabusCovered?.CHAPTERS_COMPLETED.map((chapter, i) => (
                                                                <p style={{ marginTop: "5px", fontSize: "18px", fontWeight: "700" }}>{i + 1}. {chapter}</p>
                                                            ))
                                                        }
                                                    </>
                                                ) : (
                                                    <p style={{ marginTop: "5px", fontSize: "18px", fontWeight: "700" }}>None</p>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='teacherNotVerified'>
                                    <h2>You are not verified for the subject of <b>{selectedSubData.teacherSubject}</b> for <b>{selectedSubData.teacherMedium}/{selectedSubData.teacherClass}/{selectedSubData.teacherSection},</b> by your school, to update homeworks. Get yourself verified for this subject by your school first. For that, send verification request to your school and wait till they verify you. Till then you can use this platform to follow other schools and teachers and make new friends.</h2>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div >
    )
}

export default SyllabusCoveredTeachersClsNSub