import React from 'react'
import '../../Pages/HowToUse.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function HowToUseProNHW() {
    return (
        <div className='HowToUse'>
            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className='HowToUseSummary'>
                        <h3>How to see Projects & Homeworks of all Subjects of all Classes?</h3>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='HowToUseDetails'>
                        <div className='HowToData'>
                            <h4>You can view all the homeworks, assignments and projects of all the subjects of all classes with the teachers name here. This way you can monitor if the teacher is actively giving homeworks of his/her subjects or not. You, as a school account, can just read the homeworks and cannot update or delete the homeworks, assignments or projects.</h4>
                            <p><span>Step 1 : </span> Click on “Projects & Homeworks” link on the left side of the homepage.</p>
                            <p><span>Step 2 : </span> Vertical list of all the classes will appear.</p>
                            <p><span>Step 3 : </span> Click on any class you want to see homeworks of. For example, English Medium | Kindergarten 1(KG-1) | Section A.</p>
                            <p><span>Step 4 : </span> List of all the subjects will appear.</p>
                            <p><span>Step 5 : </span> Click on any subject you want to see the homework of. For example, English</p>
                            <p><span>Step 6 : </span> List of all the homeworks, assignments, projects will appear.</p>
                            <p><span>Step 7 : </span> List of all homeworks will appear below the title of “Homeworks”</p>
                            <p><span>Step 8 : </span> List of all assignments will appear below the title of “Assignments”</p>
                            <p><span>Step 9 : </span> List of all projects will appear below the title of “Projects”</p>
                            <p><span>Step 10 : </span> Click on any of the homework to open that homework in which you will see the “question” statement, “image”(if any), “uploaded On” date, “Submit On/Before” date and “Note/Remarks” (if any).</p>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default HowToUseProNHW