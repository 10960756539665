import React, { useState, useEffect } from 'react'
import '../ComplainsAndFeedback/Complains.css'

import {
    Accordion, AccordionDetails, AccordionSummary, Avatar,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { doc, onSnapshot } from 'firebase/firestore';
import dB from '../../firebase';


function SchoolComplaintsAccordian(props) {

    const { type, childId, compSuggUser, compSuggUserPathCollection, timeStamp, compSuggText, userStatus } = props

    const [userData, setuserData] = useState(null)
    useEffect(() => {
        if (compSuggUser) {
            onSnapshot(doc(dB, "USERS", compSuggUser), (userDOc) => {
                if (userDOc.exists()) {
                    setuserData(userDOc.data())
                }
            })
        }
    }, [compSuggUser, userStatus])

    const [childData, setchildData] = useState(null)
    useEffect(() => {
        if (userStatus === "PARENT" && childId && compSuggUser && compSuggUserPathCollection) {
            onSnapshot(doc(dB, "USERS", compSuggUser, compSuggUserPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", childId), (childDoc) => {
                if (childDoc.exists()) {
                    setchildData(childDoc.data())
                }
            })
        }
    }, [childId, compSuggUser, compSuggUserPathCollection, userStatus])






    return (
        <div className="" >
            <div className="complainAcco" id=''>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        // onClick={handleViewNotice}
                        sx={{ backgroundColor: "rgb(245, 244, 244)" }}
                    >
                        <div className="AccoHead">
                            <p style={{ fontSize: '16px', fontWeight: '600', color: 'grey', marginRight: '20px', marginLeft: '20px' }}>From :</p>
                            <div className="AccoHead">
                                <Avatar src={userData?.USER_DISPLAY_PIC} sx={{ width: 30, height: 30 }} />
                                <h3 style={{ fontSize: '16px', fontWeight: '700', marginLeft: '6px', width: '300px' }}>{userData?.USER_FULL_NAME}</h3>
                            </div>
                            <p style={{ fontSize: '16px', fontWeight: '600', color: 'var(--connected-orange)' }}>{timeStamp}</p>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='suggBody'>

                            {/* about user */}
                            <div className='userAbout'>
                                <p>About User : </p>
                                {
                                    userStatus === 'PARENT' ? (
                                        <h3><span style={{ color: 'var(--connected-orange)' }}>{userStatus}</span> of <span style={{ color: 'var(--connected-orange)' }}>{childData?.CHILDS_FULL_NAME}</span> ( {childData?.CHILDS_MEDIUM} | {childData?.CHILDS_CLASS} | {childData?.CHILDS_SECTION} )</h3>
                                    ) : (
                                        <h3><span style={{ color: 'var(--connected-orange)' }}>{userStatus}</span></h3>
                                    )
                                }
                            </div>

                            {/* Complain/Suggestion Body */}
                            <div className='userAbout'>
                                <p>{type} : </p>
                                <h3>{compSuggText}</h3>
                            </div>

                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}

export default SchoolComplaintsAccordian