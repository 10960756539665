import { doc, onSnapshot } from 'firebase/firestore'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import dB from '../../firebase'
import '../Timetable/SchoolTimeTableDesign.css'
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec'

import { Box, Modal } from '@mui/material';

const imageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
};

function SchoolTimeTableUsersParentsChild(props) {

    const { userId, userPathCollection, userCurrentStatus, childSchoolId, childName,
        childMedium, childClass, childSection, childVerified, childId, childSchoolPathCollection } = props

    const [open, setOpen] = React.useState(false);
    // const [openedImg, setopenedImg] = useState('')
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [classPath, setclassPath] = useState('')
    useEffect(() => {
        if (childMedium && childClass && childSection) {
            const abc = returnMedClsSec(childMedium, childClass, childSection)
            setclassPath(abc)
        }
    }, [childClass, childMedium, childSection])

    const [timeTableData, settimeTableData] = useState(null)
    useEffect(() => {
        if (childSchoolId && childSchoolPathCollection && classPath) {
            onSnapshot(doc(dB, "SCHOOLS", childSchoolId, childSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", classPath, "TIMETABLE", "TIMETABLE_IMAGE"), (snap) => {
                if (snap.exists()) {
                    settimeTableData(snap.data())
                }
            })
        }
    }, [classPath, childSchoolId, childSchoolPathCollection])

    return (
        <div className='timetableImage'>
            {
                timeTableData ? (
                    <>
                        <img
                            src={timeTableData?.TIMETABLE_IMAGE}
                            alt={timeTableData?.TIMETABLE_IMAGE}
                            onClick={handleOpen}
                        />
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={imageStyle}>
                                <div className='imageBig'>
                                    <img src={timeTableData?.TIMETABLE_IMAGE} alt={timeTableData?.TIMETABLE_IMAGE} />
                                </div>
                            </Box>
                        </Modal>
                    </>
                ) : (
                    <>
                        <h3 style={{ fontSize: '18px', fontWeight: '700', color: 'var(--connected-orange)', textAlign: 'center' }}>No timetable has been updated by school yet.</h3>
                    </>
                )
            }

        </div>
    )
}

export default SchoolTimeTableUsersParentsChild