import React, { useEffect, useState } from 'react'
import dB from '../../firebase'
import { doc, onSnapshot } from 'firebase/firestore'
import SchoolTimeTableUsersTeachersClass from './SchoolTimeTableUsersTeachersClass'

function SchoolTimeTableUsersTeachers(props) {

    const { userId, userPathCollection, userCurrentStatus, userSchoolId } = props

    const [teacherSchoolData, setteacherSchoolData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userSchoolId) {
                onSnapshot(doc(dB, "SCHOOLS", userSchoolId), (snap) => {
                    if (snap.exists()) {
                        setteacherSchoolData(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userSchoolId])

    return (
        <div>
            <div className='homeworkteachers'>
                <div className='homeworkteachers__top'>
                    <h3>{teacherSchoolData?.SCHOOL_FULL_NAME}</h3>
                    <h4>Timetables</h4>
                    <hr />
                </div>
                <SchoolTimeTableUsersTeachersClass
                    userId={userId}
                    userPathCollection={userPathCollection}
                    userCurrentStatus={userCurrentStatus}
                    userSchoolId={userSchoolId}
                    userSchoolPathCollection={teacherSchoolData?.SCHOOL_NAME}
                />
            </div>
        </div>
    )
}

export default SchoolTimeTableUsersTeachers