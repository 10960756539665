import React, { useEffect, useState } from 'react'
import ProjectNHomework from './ProjectNHomework';
import '../ProjectsHomeworks/HomeWorksPrincipalClassNSubject.css'
import '../ProjectsHomeworks/HomeWorksStudentsSubject.css'
import { collection, doc, onSnapshot } from 'firebase/firestore';
import dB from '../../firebase';
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec';

function PrincipalProjectsNHomeworksData(props) {

    const { userId, userPathCollection, userCurrentStatus, userSchoolId,
        userSchoolPathCollection, isPrinciVerified, classPath, medium, classNum, section, subName } = props

    const [teacherData, setteacherData] = useState({ teacherId: '', teacherName: '' })
    useEffect(() => {
        async function fetchSubjectTeacherName() {
            if (teacherData.teacherId) {
                onSnapshot(doc(dB, "USERS", teacherData.teacherId), (snap) => {
                    if (snap.exists()) {
                        setteacherData(prev => { return { ...prev, teacherName: snap.data()?.USER_NAME } })
                    }
                })
            }
        }
        fetchSubjectTeacherName();
    }, [teacherData.teacherId])

    const [mediumClsSec, setmediumClsSec] = useState('')
    useEffect(() => {
        setmediumClsSec('')
        var aaa = true
        if (aaa === true) {
            var abc = returnMedClsSec(`${medium} Medium`, classNum, section)
            setmediumClsSec(abc)
        }
        return () => { aaa = false }
    }, [classNum, medium, section])

    const [assData, setassData] = useState([])
    const [homeData, sethomeData] = useState([])
    const [proData, setproData] = useState([])

    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            async function fetchAssignmentsData() {
                if (userSchoolId && userSchoolPathCollection && classPath) {
                    onSnapshot(collection(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", classPath, "PROJECTS_&_HOMEWORKS", subName, "ASSIGNMENT"), (snap) => {
                        setassData(snap.docs.map((assDoc) => ({
                            assId: assDoc.id,
                            assData: assDoc.data(),
                        })))
                    })
                    onSnapshot(collection(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", classPath, "PROJECTS_&_HOMEWORKS", subName, "HOMEWORK"), (snap) => {
                        sethomeData(snap.docs.map((homeDOc) => ({
                            homeId: homeDOc.id,
                            homeData: homeDOc.data(),
                        })))
                    })
                    onSnapshot(collection(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", classPath, "PROJECTS_&_HOMEWORKS", subName, "PROJECT"), (snap) => {
                        setproData(snap.docs.map((proDoc) => ({
                            proId: proDoc.id,
                            proData: proDoc.data(),
                        })))
                    })
                    onSnapshot(doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", classPath, "PROJECTS_&_HOMEWORKS", subName), (newDoc) => {
                        if (newDoc.exists()) {
                            if (newDoc.data()?.SUBJECT_TEACHER_ID !== undefined) {
                                setteacherData(prev => { return { ...prev, teacherId: newDoc.data()?.SUBJECT_TEACHER_ID } })
                            }
                        }
                    })
                }
            }
            fetchAssignmentsData();
        }
        return () => { aaa = false }
    }, [classPath, subName, userSchoolId, userSchoolPathCollection])

    return (
        <div>
            {
                assData?.length > 0 || proData?.length > 0 || homeData?.length > 0 ? (
                    <div className='subAccordionDetails'>
                        <h3>Projects/Homeworks of <b style={{ color: 'var(--connected-color)' }}>{subName}</b> by <span style={{ color: 'black' }}>{teacherData.teacherName}</span></h3>
                        {
                            homeData.length > 0 && (
                                <div >
                                    <div className='subAccordDetHAP'>
                                        <h4>Homeworks</h4>
                                        {
                                            homeData.map((data, i) => (
                                                <ProjectNHomework
                                                    key={i}
                                                    proNHomeId={data?.homeId}
                                                    uploadedOn={data?.homeData?.UPLOADED_ON}
                                                    submitOn={data?.homeData?.SUBMIT_BEFORE}
                                                    questionStatement={data?.homeData?.QUESTION_STATEMENT}
                                                    questionImage={data?.homeData?.QUESTION_IMAGE}
                                                    questionNote={data?.homeData?.QUESTION_NOTE}
                                                    viewedBy={data?.homeData?.VIEWED_BY}
                                                    userId={userId}
                                                    schoolId={userSchoolId}
                                                    schoolPathCollection={userSchoolPathCollection}
                                                    medClsSec={mediumClsSec}
                                                    subjectName={subName}
                                                    userCurrentStatus={userCurrentStatus}
                                                    assignment={false}
                                                    project={false}
                                                    homework={true}
                                                    homeworkId={data?.homeId}
                                                    assignmentId={''}
                                                    projectId={''}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {
                            assData.length > 0 && (
                                <div >
                                    <div className='subAccordDetHAP'>
                                        <h4>Assignements</h4>
                                        {
                                            assData.map((data, i) => (
                                                <ProjectNHomework
                                                    key={i}
                                                    proNHomeId={data?.assId}
                                                    uploadedOn={data?.assData?.UPLOADED_ON}
                                                    submitOn={data?.assData?.SUBMIT_BEFORE}
                                                    questionStatement={data?.assData?.QUESTION_STATEMENT}
                                                    questionImage={data?.assData?.QUESTION_IMAGE}
                                                    questionNote={data?.assData?.QUESTION_NOTE}
                                                    viewedBy={data?.assData?.VIEWED_BY}
                                                    userId={userId}
                                                    schoolId={userSchoolId}
                                                    schoolPathCollection={userSchoolPathCollection}
                                                    medClsSec={mediumClsSec}
                                                    subjectName={subName}
                                                    userCurrentStatus={userCurrentStatus}
                                                    assignment={true}
                                                    project={false}
                                                    homework={false}
                                                    homeworkId={''}
                                                    assignmentId={data?.assId}
                                                    projectId={''}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {
                            proData.length > 0 && (
                                <div >
                                    <div className='subAccordDetHAP'>
                                        <h4>Projects</h4>
                                        {
                                            proData.map((data, i) => (
                                                <ProjectNHomework
                                                    key={i}
                                                    proNHomeId={data?.proId}
                                                    uploadedOn={data?.proData?.UPLOADED_ON}
                                                    submitOn={data?.proData?.SUBMIT_BEFORE}
                                                    questionStatement={data?.proData?.QUESTION_STATEMENT}
                                                    questionImage={data?.proData?.QUESTION_IMAGE}
                                                    questionNote={data?.proData?.QUESTION_NOTE}
                                                    viewedBy={data?.proData?.VIEWED_BY}
                                                    userId={userId}
                                                    schoolId={userSchoolId}
                                                    schoolPathCollection={userSchoolPathCollection}
                                                    medClsSec={mediumClsSec}
                                                    subjectName={subName}
                                                    userCurrentStatus={userCurrentStatus}
                                                    assignment={false}
                                                    project={true}
                                                    homework={false}
                                                    homeworkId={''}
                                                    assignmentId={''}
                                                    projectId={data?.proId}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        }

                    </div>
                ) : (
                    <div>
                        <h3 style={{ color: 'gray', fontSize: '17px', fontWeight: '700', marginLeft: '100px' }}>No homeworks at the moment for <span style={{ color: 'var(--connected-orange)' }}>{subName}</span> by {teacherData.teacherName}</h3>
                    </div>
                )
            }
        </div>
    )
}

export default PrincipalProjectsNHomeworksData
