import React, { useEffect, useState } from 'react';
import '../Pages/MainHomePage.css'
import Login from './Login';
import Header from '../Components/Header/Header';
import HomePageFeed from '../Components/Social/HomePageFeed';
import HomePageSidebar from '../Components/Sidebars/HomePageSidebar';

import dB, { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import SchoolHomePageSidebar from '../Components/Sidebars/SchoolHomePageSidebar';


function MainHomePage() {

    const [loggedInUserData, setloggedInUserData] = useState(null)
    const [userId, setuserId] = useState('')
    const [schoolId, setschoolId] = useState('')
    const [schoolData, setschoolData] = useState(null)
    useEffect(() => {
        const unSubscribe = onAuthStateChanged(auth, (userCredentials) => {
            if (userCredentials) {
                var uid = userCredentials.uid;
                setuserId(userCredentials.uid)
                onSnapshot(doc(dB, "USERS", userCredentials.uid), (snap) => {
                    if (snap.exists()) {
                        setloggedInUserData(snap.data())
                    } else {
                        setschoolId(uid)
                        onSnapshot(doc(dB, "SCHOOLS", uid), (schoolDoc) => {
                            if (schoolDoc.exists()) {
                                setschoolData(schoolDoc.data())
                            }
                        })
                    }
                })
            }
        });
        return () => { unSubscribe(); }
    }, [])

    const [userRegName, setuserRegName] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && loggedInUserData) {
                onSnapshot(doc(dB, "USERS", userId, loggedInUserData?.USER_NAME, "REGISTRATION_INFO"), (snap) => {
                    if (snap.exists()) {
                        setuserRegName(snap.data()?.USER_NAME)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [loggedInUserData, userId])

    return (
        userId === '' ? (
            <Login />
        ) : (
            <>
                {
                    schoolData?.USER_STATUS === 'SCHOOL' && (
                        <div className='schoolhomepage'>
                            <Header />
                            <div className='schoolhomepage__body'>
                                <div className='schoolhomepage__bodyLeft'>
                                    <SchoolHomePageSidebar
                                        schoolFullName={schoolData?.SCHOOL_FULL_NAME}
                                        schoolPathCollection={schoolData?.SCHOOL_NAME}
                                        userDP={schoolData?.USER_DISPLAY_PIC}
                                    />
                                </div>
                                <div className='schoolhomepage__bodyRight'>
                                    <HomePageFeed
                                        userId={schoolId}
                                        userFullName={schoolData?.SCHOOL_FULL_NAME}
                                        userPathCollection={schoolData?.SCHOOL_NAME}
                                        userCurrentStatus={schoolData?.USER_STATUS}
                                        userDP={schoolData?.USER_DISPLAY_PIC}

                                        friendId={null}
                                        friendFullName={null}
                                        friendPathCollection={null}
                                        friendCurrentStatus={null}
                                        friendDP={null}

                                        MainHomePage={true}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    schoolData?.USER_STATUS !== 'SCHOOL' && (
                        <>
                            < Header />
                            <div className='homepage__body'>
                                <div className='homepage__bodyLeft'>
                                    <HomePageSidebar
                                        userName={userRegName}
                                        userCurrentStatus={loggedInUserData?.USER_CURRENT_STATUS}
                                        userId={userId}
                                        userPathCollection={loggedInUserData?.USER_NAME}
                                        userDP={loggedInUserData?.USER_DISPLAY_PIC}
                                    />
                                </div>
                                <div className='homepage__bodyRight'>
                                    <HomePageFeed
                                        userId={userId}
                                        userFullName={userRegName}
                                        userPathCollection={loggedInUserData?.USER_NAME}
                                        userCurrentStatus={loggedInUserData?.USER_CURRENT_STATUS}
                                        userDP={loggedInUserData?.USER_DISPLAY_PIC}
                                        friendId={null}
                                        friendFullName={null}
                                        friendPathCollection={null}
                                        friendCurrentStatus={null}
                                        friendDP={null}
                                        MainHomePage={true}
                                    />
                                </div>
                            </div>
                        </>
                    )
                }
            </>
        )

    )
}

export default MainHomePage
