import React from 'react'
import '../FIndToFollow/FindToFollowAccounts.css'
import FindToFollowSchools from './FindToFollowSchools'
import FindToFollowTeachersNFriends from './FindToFollowTeachersNFriends'

function FindToFollowAccounts(props) {

    const { userId, userPathCollection, userCurrentStatus, userFullName } = props

    return (
        <div className='FindToFollowAccounts'>
            <div className='FindToFollowAccountsSchools'>
                <h3>Schools to follow</h3>
                <hr />
                <FindToFollowSchools
                    userId={userId}
                    userPathCollection={userPathCollection}
                    userCurrentStatus={userCurrentStatus}
                />
            </div>

            <div className='FindToFollowAccountsUsers'>
                <h3>Teachers, Principals & Friends to follow</h3>
                <hr />
                <FindToFollowTeachersNFriends
                    userId={userId}
                    userPathCollection={userPathCollection}
                    userCurrentStatus={userCurrentStatus}
                />
            </div>
        </div>
    )
}

export default FindToFollowAccounts
