import React, { useEffect, useState } from 'react'
import '../Social/HomePagePosts.css'
import HomePagePostsLikes from './HomePagePostsLikes'
import FriendsComment from './FriendsComment'
import { addDoc, collection, doc, getDocs, limit, onSnapshot, orderBy, query, serverTimestamp, startAfter, writeBatch } from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';
import { Link } from 'react-router-dom';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendIcon from '@mui/icons-material/Send';
import { Avatar, Box, Button, Fade, Modal, Paper, Popover, Popper, TextField } from '@mui/material';
import SimpleImageSlider from "react-simple-image-slider";
import { LoadingButton } from '@mui/lab';
import dB, { storage } from '../../firebase';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const imageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
};

function HomePagePosts(props) {

    const { userName, timeStamp, postText, postImage, postId,
        userId, userPathCollection, userCurrentStatus,
        friendId, friendPathCollection, friendCurrentStatus, friendFullName, userDP, friendDP
    } = props

    // open delete button from vertical icon
    const [popOverDelButton, setpopOverDelButton] = useState(null);
    const handleClickPopOverDeleteButton = (event) => { setpopOverDelButton(event.currentTarget); };
    const handleClosePopOverDeleteButton = (e) => { setpopOverDelButton(null); };
    const openPopOverDeleteButton = Boolean(popOverDelButton);
    const idPopOverDeleteButton = openPopOverDeleteButton ? 'simple-popover' : undefined;


    // open yes/no modal for deleting post
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = (e) => { setOpenModal(true); };
    const handleCloseModal = (e) => { setOpenModal(false); };

    const [open, setOpen] = React.useState(false);
    const [openedImg, setopenedImg] = useState('')
    const handleOpen = (imgNum) => { setOpen(true); setopenedImg(imgNum) };
    const handleClose = () => setOpen(false);

    // show the list of the ones who has liked the post 
    const [clickLike, setclickLike] = useState(null);
    const [openLike, setOpenLike] = useState(false);
    const [placement, setPlacement] = useState();
    const handleClickOpenLikes = (newPlacement) => (event) => {
        setclickLike(event.currentTarget);
        setOpenLike((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const [disabledButtons, setdisabledButtons] = useState({ yesDisabled: false, noDisabled: false })

    var batch = writeBatch(dB)

    const [showBox, setshowBox] = useState({ showCommentBox: false, showComments: false })
    const handleOpenCommentBox = (e) => {
        e.preventDefault();
        setshowBox(prev => { return { ...prev, showCommentBox: !showBox.showCommentBox } })
    }
    const handleShowComments = (e) => {
        e.preventDefault();
        setshowBox(prev => { return { ...prev, showComments: !showBox.showComments } })
    }

    // POST A COMMENT
    const [commentIds, setcommentIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (!friendId) {
                if (userCurrentStatus === 'SCHOOL') {
                    onSnapshot(query(collection(dB, "SCHOOLS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS")), orderBy('COMMENT_TIMESTAMP', 'desc'), (snap) => {
                        setcommentIds(snap.docs.map((commDoc) => commDoc.id))
                    })
                } else {
                    onSnapshot(query(collection(dB, "USERS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS")), orderBy('COMMENT_TIMESTAMP', 'desc'), (snap) => {
                        setcommentIds(snap.docs.map((commDoc) => commDoc.id))
                    })
                }
            } else {
                if (friendCurrentStatus === 'SCHOOL') {
                    onSnapshot(query(collection(dB, "SCHOOLS", friendId, friendPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS")), orderBy('COMMENT_TIMESTAMP', 'desc'), (snap) => {
                        setcommentIds(snap.docs.map((commDoc) => commDoc.id))
                    })
                } else {
                    onSnapshot(query(collection(dB, "USERS", friendId, friendPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS")), orderBy('COMMENT_TIMESTAMP', 'desc'), (snap) => {
                        setcommentIds(snap.docs.map((commDoc) => commDoc.id))
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [friendCurrentStatus, friendId, friendPathCollection, postId, userCurrentStatus, userId, userPathCollection])


    const [commentsData, setcommentsData] = useState([])
    useEffect(() => {
        if (commentsData?.length > 0) {
            commentsData?.splice(0, commentsData?.length)
        }
        var aaa = true
        if (aaa === true) {
            if (!friendId) {
                if (userCurrentStatus === 'SCHOOL') {
                    onSnapshot(query(collection(dB, "SCHOOLS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS"), orderBy('COMMENT_TIMESTAMP', 'desc'), limit(2)), (snap) => {
                        fetchMore(snap)
                    })
                } else {
                    onSnapshot(query(collection(dB, "USERS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS"), orderBy('COMMENT_TIMESTAMP', 'desc'), limit(2)), (snap) => {
                        fetchMore(snap)
                    })
                }
            } else {
                if (friendCurrentStatus === 'SCHOOL') {
                    onSnapshot(query(collection(dB, "SCHOOLS", friendId, friendPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS"), orderBy('COMMENT_TIMESTAMP', 'desc'), limit(2)), (snap) => {
                        fetchMore(snap)
                    })
                } else {
                    onSnapshot(query(collection(dB, "USERS", friendId, friendPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS"), orderBy('COMMENT_TIMESTAMP', 'desc'), limit(2)), (snap) => {
                        fetchMore(snap)
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [friendCurrentStatus, friendId, friendPathCollection, postId, userCurrentStatus, userId, userPathCollection])
    const [lastVisible, setlastVisible] = useState()
    const fetchMore = (snap) => {
        let comments = snap.docs.map((commDoc) => ({
            commentId: commDoc.id,
            commentData: commDoc.data(),
        }))
        setcommentsData(prev => { return [...prev, ...comments] })
        var lastVisibleComment = snap.docs[snap.docs.length - 1];
        setlastVisible(lastVisibleComment)
    }
    const [totalComments, settotalComments] = useState()
    const loadMoreComments = async (commentsDataLength) => {
        settotalComments(commentsDataLength)
        if (!friendId) {
            if (userCurrentStatus === 'SCHOOL') {
                const ref = query(collection(dB, "SCHOOLS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS"), orderBy('COMMENT_TIMESTAMP', 'desc'), startAfter(lastVisible), limit(5))
                const querySnapshot = await getDocs(ref);
                fetchMore(querySnapshot)
            } else {
                const ref = query(collection(dB, "USERS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS"), orderBy('COMMENT_TIMESTAMP', 'desc'), startAfter(lastVisible), limit(5))
                const querySnapshot = await getDocs(ref);
                fetchMore(querySnapshot)
            }
        } else {
            if (friendCurrentStatus === 'SCHOOL') {
                const ref = query(collection(dB, "SCHOOLS", friendId, friendPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS"), orderBy('COMMENT_TIMESTAMP', 'desc'), startAfter(lastVisible), limit(5))
                const querySnapshot = await getDocs(ref);
                fetchMore(querySnapshot)
            } else {
                const ref = query(collection(dB, "USERS", friendId, friendPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS"), orderBy('COMMENT_TIMESTAMP', 'desc'), startAfter(lastVisible), limit(5))
                const querySnapshot = await getDocs(ref);
                fetchMore(querySnapshot)
            }
        }
    }


    // upload a comment on a post

    const [commentInput, setcommentInput] = useState('')
    const handleSendComment = async (e) => {
        e.preventDefault();
        if (commentInput) {
            if (!friendId) {
                if (userCurrentStatus === 'SCHOOL') {
                    addDoc(collection(dB, "SCHOOLS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS"), {
                        COMMENT_FROM: userId,
                        COMMENT_FROM_STATUS: 'SCHOOL',
                        COMMENT_TEXT: commentInput,
                        COMMENT_TIMESTAMP: serverTimestamp()
                    }).then(() => { setcommentInput('') })
                        .catch(err => alert(err.message))
                } else {
                    addDoc(collection(dB, "USERS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS"), {
                        COMMENT_FROM: userId,
                        COMMENT_FROM_STATUS: 'USER',
                        COMMENT_TEXT: commentInput,
                        COMMENT_TIMESTAMP: serverTimestamp()
                    }).then(() => { setcommentInput('') })
                        .catch(err => alert(err.message))
                }
            } else {
                if (friendCurrentStatus === 'SCHOOL') {
                    addDoc(collection(dB, "SCHOOLS", friendId, friendPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS"), {
                        COMMENT_FROM: userId,
                        COMMENT_FROM_STATUS: userCurrentStatus !== 'SCHOOL' ? 'USER' : 'SCHOOL',
                        COMMENT_TEXT: commentInput,
                        COMMENT_TIMESTAMP: serverTimestamp()
                    }).then(() => { setcommentInput('') })
                        .catch(err => alert(err.message))
                } else {
                    addDoc(collection(dB, "USERS", friendId, friendPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS"), {
                        COMMENT_FROM: userId,
                        COMMENT_FROM_STATUS: userCurrentStatus !== 'SCHOOL' ? 'USER' : 'SCHOOL',
                        COMMENT_TEXT: commentInput,
                        COMMENT_TIMESTAMP: serverTimestamp()
                    }).then(() => { setcommentInput('') })
                        .catch(err => alert(err.message))
                }
            }
        }
    }

    const [btnLoading, setbtnLoading] = useState(false)
    // DELETE A POST
    const handleDeletePost = async (e) => {
        e.preventDefault();
        setbtnLoading(true)
        setdisabledButtons(prev => { return { ...prev, yesDisabled: true } })

        if (userCurrentStatus === 'SCHOOL') {
            const schlPostImages = [];
            if (postImage?.length > 0) {
                postImage.map(async pstImg => {
                    const postPic = ref(storage, await pstImg);

                    await deleteObject(postPic).then(async () => {
                        await schlPostImages.push(pstImg)
                        if (postImage?.length === schlPostImages?.length) {
                            if (commentIds?.length) {
                                await commentIds.map(async commId => {
                                    await batch.delete(doc(dB, "SCHOOLS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS", commId))
                                })
                            }
                            if (likeIds?.length) {
                                await likeIds.map(async likeId => {
                                    await batch.delete(doc(dB, "SCHOOLS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_LIKES", likeId))
                                })
                            }
                            await batch.delete(doc(dB, "SCHOOLS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId))

                            await batch.commit().then(() => {
                                setOpenModal(false)
                                setpopOverDelButton(null)
                                setbtnLoading(false)
                                setdisabledButtons(prev => { return { ...prev, yesDisabled: false } })
                                setpostDeleted(true)
                            }).catch(err => alert(err.message))
                        }
                    })
                })
            } else {
                if (commentIds?.length) {
                    await commentIds.map(async commId => {
                        await batch.delete(doc(dB, "SCHOOLS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS", commId))
                    })
                }
                if (likeIds?.length) {
                    await likeIds.map(async likeId => {
                        await batch.delete(doc(dB, "SCHOOLS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_LIKES", likeId))
                    })
                }

                await batch.delete(doc(dB, "SCHOOLS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId))

                await batch.commit().then(() => {
                    setOpenModal(false)
                    setpopOverDelButton(null)
                    setbtnLoading(false)
                    setdisabledButtons(prev => { return { ...prev, yesDisabled: false } })
                    setpostDeleted(true)
                }).catch(err => alert(err.message))
            }
        } else {
            if (postImage?.length > 0) {
                const postPic = ref(storage, await postImage[0]);

                await deleteObject(postPic).then(async () => {
                    if (commentIds?.length) {
                        await commentIds.map(async commId => {
                            await batch.delete(doc(dB, "USERS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS", commId))
                        })
                    }
                    if (likeIds?.length) {
                        await likeIds.map(async likeId => {
                            await batch.delete(doc(dB, "USERS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_LIKES", likeId))
                        })
                    }

                    await batch.delete(doc(dB, "USERS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId))

                    await batch.commit().then(() => {
                        setOpenModal(false)
                        setpopOverDelButton(null)
                        setbtnLoading(false)
                        setdisabledButtons(prev => { return { ...prev, yesDisabled: false } })
                        setpostDeleted(true)
                    }).catch(err => alert(err.message))
                })
            } else {
                if (commentIds?.length) {
                    await commentIds.map(async commId => {
                        await batch.delete(doc(dB, "USERS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_COMMENTS", commId))
                    })
                }
                if (likeIds?.length) {
                    await likeIds.map(async likeId => {
                        await batch.delete(doc(dB, "USERS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_LIKES", likeId))
                    })
                }

                await batch.delete(doc(dB, "USERS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId))

                await batch.commit().then(() => {
                    setOpenModal(false)
                    setpopOverDelButton(null)
                    setbtnLoading(false)
                    setdisabledButtons(prev => { return { ...prev, yesDisabled: false } })
                    setpostDeleted(true)
                }).catch(err => alert(err.message))
            }
        }
    }

    // LIKE/DISLIKE POSTS
    const [likeIds, setlikeIds] = useState([])
    const [likesData, setlikesData] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (!friendId) {
                if (userCurrentStatus === 'SCHOOL') {
                    onSnapshot(collection(dB, "SCHOOLS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_LIKES"), (snap) => {
                        setlikesData(snap.docs.map((likeDoc) => ({
                            likerId: likeDoc.id,
                            likerData: likeDoc.data(),
                        })))
                        setlikeIds(snap.docs.map((likeIdDoc) => likeIdDoc.id))
                    })
                } else {
                    onSnapshot(collection(dB, "USERS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_LIKES"), (snap) => {
                        setlikesData(snap.docs.map((likeDoc) => ({
                            likerId: likeDoc.id,
                            likerData: likeDoc.data(),
                        })))
                        setlikeIds(snap.docs.map((likeIdDoc) => likeIdDoc.id))
                    })
                }
            } else {
                if (friendCurrentStatus === 'SCHOOL') {
                    onSnapshot(collection(dB, "SCHOOLS", friendId, friendPathCollection, "POSTS", "ALL_POSTS", postId, "POST_LIKES"), (snap) => {
                        setlikesData(snap.docs.map((likeDoc) => ({
                            likerId: likeDoc.id,
                            likerData: likeDoc.data(),
                        })))
                        setlikeIds(snap.docs.map((likeIdDoc) => likeIdDoc.id))
                    })
                } else {
                    onSnapshot(collection(dB, "USERS", friendId, friendPathCollection, "POSTS", "ALL_POSTS", postId, "POST_LIKES"), (snap) => {
                        setlikesData(snap.docs.map((likeDoc) => ({
                            likerId: likeDoc.id,
                            likerData: likeDoc.data(),
                        })))
                        setlikeIds(snap.docs.map((likeIdDoc) => likeIdDoc.id))
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [friendCurrentStatus, friendId, friendPathCollection, postId, userCurrentStatus, userId, userPathCollection])
    // like/dislike a post 
    const handleClickLikePost = async (e) => {
        e.preventDefault();
        if (likeIds.includes(userId)) {
            if (!friendId) {
                if (userCurrentStatus === 'SCHOOL') {
                    await batch.delete(doc(dB, "SCHOOLS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_LIKES", userId))
                } else {
                    await batch.delete(doc(dB, "USERS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_LIKES", userId))
                }
            } else {
                if (friendCurrentStatus === 'SCHOOL') {
                    await batch.delete(doc(dB, "SCHOOLS", friendId, friendPathCollection, "POSTS", "ALL_POSTS", postId, "POST_LIKES", userId))
                } else {
                    await batch.delete(doc(dB, "USERS", friendId, friendPathCollection, "POSTS", "ALL_POSTS", postId, "POST_LIKES", userId))
                }
            }
        } else {
            if (!friendId) {
                if (userCurrentStatus === 'SCHOOL') {
                    await batch.set(doc(dB, "SCHOOLS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_LIKES", userId), {
                        LIKE_FROM: userId,
                        LIKE_FROM_STATUS: 'SCHOOL',
                        LIKE_TIMESTAMP: serverTimestamp()
                    })
                } else {
                    await batch.set(doc(dB, "USERS", userId, userPathCollection, "POSTS", "ALL_POSTS", postId, "POST_LIKES", userId), {
                        LIKE_FROM: userId,
                        LIKE_FROM_STATUS: 'USER',
                        LIKE_TIMESTAMP: serverTimestamp()
                    })
                }
            } else {
                if (friendCurrentStatus === 'SCHOOL') {
                    await batch.set(doc(dB, "SCHOOLS", friendId, friendPathCollection, "POSTS", "ALL_POSTS", postId, "POST_LIKES", userId), {
                        LIKE_FROM: userId,
                        LIKE_FROM_STATUS: userCurrentStatus === 'SCHOOL' ? 'SCHOOL' : 'USER',
                        LIKE_TIMESTAMP: serverTimestamp()
                    })
                } else {
                    await batch.set(doc(dB, "USERS", friendId, friendPathCollection, "POSTS", "ALL_POSTS", postId, "POST_LIKES", userId), {
                        LIKE_FROM: userId,
                        LIKE_FROM_STATUS: userCurrentStatus === 'SCHOOL' ? 'SCHOOL' : 'USER',
                        LIKE_TIMESTAMP: serverTimestamp()
                    })
                }

            }
        }

        await batch.commit().catch(err => alert(err.message))
    }

    const [postDeleted, setpostDeleted] = useState(false)

    const [showMore, setshowMore] = useState(false)
    return (
        <div>
            {
                postDeleted ? (
                    <div className='postDeleted'>
                        <h4>Post Deleted...</h4>
                    </div>
                ) : (
                    <div className='posts' >
                        <div className='posts__top'>
                            <div className='posts__topTop'>
                                <Link to={!friendId ? userCurrentStatus === 'SCHOOL' ? `/schoolfriendprofile/${userId}` : `/friendsprofile/${userId}` : friendCurrentStatus === 'SCHOOL' ? `/schoolfriendprofile/${friendId}` : `/friendsprofile/${friendId}`} >
                                    <Avatar
                                        src={!friendId ? userDP : friendDP}
                                        alt={!friendId ? userName : friendFullName}
                                        sx={{ width: 40, height: 40 }}
                                    />
                                </Link>
                                <div className='posts__topInfo'>
                                    <h5 id='userNameh5'>
                                        <Link to={!friendId ? userCurrentStatus === 'SCHOOL' ? `/schoolfriendprofile/${userId}` : `/friendsprofile/${userId}` : friendCurrentStatus === 'SCHOOL' ? `/schoolfriendprofile/${friendId}` : `/friendsprofile/${friendId}`} style={{ textDecoration: 'none', color: 'black' }}>
                                            {!friendId ? userName : friendFullName}
                                        </Link>
                                    </h5>
                                    <p>{timeStamp?.toDate().toDateString()}</p>
                                </div>
                            </div>
                            <div className='posts__topRight'>
                                {
                                    !friendId && (
                                        <div className='moreVertIcon' onClick={handleClickPopOverDeleteButton}>
                                            <MoreVertIcon />
                                        </div>
                                    )
                                }
                                <Popover
                                    id={idPopOverDeleteButton}
                                    open={openPopOverDeleteButton}
                                    anchorEl={popOverDelButton}
                                    onClose={handleClosePopOverDeleteButton}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <div className='btnDeletePost'>
                                        <Button onClick={handleOpenModal}>Delete Post</Button>
                                    </div>
                                    <Modal
                                        open={openModal}
                                        onClose={handleCloseModal}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <div className='deletePostModal'>
                                                <div className='deletePostModalText'>
                                                    <h4>Do you really want to delete this post?</h4>
                                                </div>
                                                <div className='deletePostModalButtons'>
                                                    <LoadingButton
                                                        onClick={handleDeletePost}
                                                        variant='outlined'
                                                        loading={btnLoading}
                                                    >
                                                        Yes
                                                    </LoadingButton>
                                                    {/* <Button disabled={disabledButtons.yesDisabled} >Yes</Button> */}
                                                    <Button disabled={disabledButtons.noDisabled} onClick={handleCloseModal}>No</Button>
                                                </div>
                                            </div>
                                        </Box>
                                    </Modal>
                                </Popover>
                            </div>
                        </div>
                        <div className='posts__middle'>
                            {
                                postText.length > 180 ? (
                                    <>
                                        {
                                            showMore ? (
                                                <div className='postText' onClick={() => { setshowMore(false) }}>
                                                    <p>{postText}</p>
                                                    <h3>show less..</h3>
                                                </div>
                                            ) : (
                                                <div className='postText' onClick={() => { setshowMore(true) }}>
                                                    <p>{`${postText.substring(0, 180)}`}</p>
                                                    <h3>show more..</h3>
                                                </div>
                                            )
                                        }

                                    </>
                                ) : (
                                    <>
                                        <p>{postText}</p>
                                    </>
                                )
                            }

                            {
                                postImage?.length > 0 && (
                                    <>
                                        {
                                            postImage?.length > 1 ? (
                                                <div className='imgSlide'>
                                                    <SimpleImageSlider
                                                        // style={{ width: '550px', height: '300px', objectFit: 'contain' }}
                                                        width={550}
                                                        height={300}
                                                        // onClick={handleOpen}
                                                        onClick={(idx, event) => { handleOpen(idx) }}
                                                        images={postImage}
                                                        showBullets={true}
                                                        showNavs={true}
                                                    />
                                                    <></>
                                                </div>
                                            ) : (
                                                <div className='postOneImg'>
                                                    <img src={postImage[0]} onClick={() => { handleOpen('0') }} alt={!friendId ? userName : friendFullName} />
                                                    <></>
                                                </div>
                                            )
                                        }
                                    </>

                                )
                            }

                            {/* {
                                (postImage) && <img src={postImage} onClick={handleOpen} alt={!friendId ? userName : friendFullName} />
                            } */}
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={imageStyle}>
                                    <div className='imageBig'>
                                        <img src={postImage[openedImg]} alt={!friendId ? userName : friendFullName} />
                                    </div>
                                </Box>
                            </Modal>
                        </div>
                        <div style={{ display: likeIds?.length > 0 || commentIds?.length > 0 ? 'flex' : 'none' }} className='likesCommentsNumbers'>
                            {likeIds?.length > 0 && (<p onClick={handleClickOpenLikes('right-start')}>{likeIds?.length} Likes</p>)}
                            <Popper open={openLike} anchorEl={clickLike} placement={placement} transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper>
                                            <div className='likerNames'>
                                                {
                                                    likesData.map((data, i) => (
                                                        <HomePagePostsLikes
                                                            key={i}
                                                            likerStatus={data?.likerData?.LIKE_FROM_STATUS}
                                                            likedById={data?.likerData?.LIKE_FROM}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                            {
                                commentIds?.length > 0 && (<p onClick={handleShowComments}>{commentIds?.length} Comments</p>)
                            }

                        </div>
                        <div className='posts__bottom'>
                            <div className={`posts__option ${likeIds?.includes(userId) && 'likedCommentTrue'}`} onClick={handleClickLikePost}>
                                <ThumbUpIcon />
                                <p>Like</p>
                            </div>
                            <div className='posts__option' onClick={handleOpenCommentBox}>
                                <ModeCommentIcon />
                                <p>Comment</p>
                            </div>
                        </div>
                        {
                            showBox.showCommentBox && (
                                <div className='commentBoxBlock'>
                                    <div className='commentBox'>
                                        <TextField
                                            variant='standard'
                                            id="outlined-multiline-static"
                                            multiline
                                            minRows={1}
                                            maxRows={4}
                                            placeholder='Add a comment here..'
                                            value={commentInput}
                                            onChange={(e) => { setcommentInput(e.target.value) }}
                                        />
                                        <div onClick={handleSendComment} className='commentSendIcon'>
                                            <SendIcon />
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        {
                            showBox.showComments && (
                                <div className='friendsCommentsBlock'>
                                    {
                                        commentsData.map((data, i) => (
                                            <FriendsComment
                                                key={i}
                                                commentId={data?.commentId}
                                                commentText={data?.commentData?.COMMENT_TEXT}
                                                commentorId={data?.commentData?.COMMENT_FROM}
                                                commentorStatus={data?.commentData?.COMMENT_FROM_STATUS}
                                                commentTimestamp={data?.commentData?.COMMENT_TIMESTAMP}
                                                friendId={friendId}
                                                friendPathCollection={friendPathCollection}
                                                friendCurrentStatus={friendCurrentStatus}
                                                friendFullName={friendFullName}
                                                friendDP={friendDP}
                                                userDP={userDP}
                                                userId={userId}
                                                userPathCollection={userPathCollection}
                                                userCurrentStatus={userCurrentStatus}
                                                postId={postId}
                                            />
                                        ))
                                    }
                                    {
                                        commentsData?.length > 1 && (
                                            <>
                                                {
                                                    totalComments !== commentsData?.length ? (
                                                        <div className='loadMoreComments'>
                                                            <h3 onClick={(e) => { e.preventDefault(); loadMoreComments(commentsData?.length) }}>More Comments...</h3>
                                                        </div>
                                                    ) : (
                                                        <div className='noMoreComments'>
                                                            <h4>No more comments to show...</h4>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }

                                </div>
                            )
                        }


                    </div>
                )
            }
        </div>

    )
}

export default HomePagePosts