import React, { useState } from 'react'
import '../CreateNewAccount/CreateNewAccountInfoUser.css';
// import dB, { auth } from '../firebase';
import { useHistory } from 'react-router-dom';
import { doc, serverTimestamp, writeBatch } from 'firebase/firestore';
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { LoadingButton } from '@mui/lab';
import dB, { auth } from '../../firebase';

function CreateNewAccountInfoUser(props) {
    const { fName, lName, userFullName, userMobNum, userPwd, userGender, userDOB, userEmailId } = props
    const history = useHistory();

    var batch = writeBatch(dB)
    const [btnLoading, setbtnLoading] = useState(false)
    const finshAndLogin = async (e) => {
        e.preventDefault()
        setbtnLoading(true)

        await createUserWithEmailAndPassword(auth, userEmailId, userPwd).then(async (authUser) => {

            await batch.set(doc(dB, "USERS", authUser.user.uid), {
                USER_CREATED_ON: serverTimestamp(),
                USER_EMAIL_ID: userEmailId,
                USER_CURRENT_STATUS: '',
                USER_NAME: userFullName,
                USER_FULL_NAME: userFullName
            });

            await batch.set(doc(dB, "USERS", authUser.user.uid, userFullName, "REGISTRATION_INFO"), {
                USER_NAME: userFullName,
                USER_MOB_NO: userMobNum,
                USER_PWD: userPwd,
                USER_GENDER: userGender,
                USER_DOB: userDOB,
                F_NAME: fName,
                L_NAME: lName,
            });

            await batch.commit().then(() => {
                setbtnLoading(false)
                sessionStorage.setItem('Just_USER_ID', authUser.user.uid)
                signInWithEmailAndPassword(auth, userEmailId, userPwd)
                    .then((userCredential) => { })
                    .catch(err => alert(err.message));

                onAuthStateChanged(auth, (user) => {
                    if (user) {
                        history.push('/user');
                    }
                })
            }).catch(err => alert(err.message));
        }).catch(err => alert(err.message));
    }

    return (
        <div className='createnewaccountinfo__containerUsers'>
            <h3>Just a User</h3>
            <LoadingButton
                onClick={finshAndLogin}
                loading={btnLoading}
                variant="outlined"
            >
                Finish & Sign In
            </LoadingButton>
        </div>
    )
}

export default CreateNewAccountInfoUser
