import React from 'react'
import '../ComplainsAndFeedback/Complains.css'
import '../ProjectsHomeworks/HomeWorksPrincipal.css'
import { collection, doc, onSnapshot } from 'firebase/firestore'
import { useEffect } from 'react'
import { useState } from 'react'
import dB from '../../firebase'
import ComplaintsSchoolData from './ComplaintsSchoolData'

function ComplaintsSchool(props) {

    const { userId, userPathCollection, userCurrentStatus, userSchoolId } = props

    const [schoolData, setschoolData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userSchoolId) {
                onSnapshot(doc(dB, "SCHOOLS", userSchoolId), (schlDoc) => {
                    if (schlDoc.exists()) {
                        setschoolData(schlDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userSchoolId])

    const [princiVerified, setprinciVerified] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), (princiDoc) => {
                    if (princiDoc.exists()) {
                        setprinciVerified(princiDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const [allSuggestions, setallSuggestions] = useState([])
    useEffect(() => {
        if (userCurrentStatus === "SCHOOL") {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "SCHOOLS", userId, userPathCollection, "COMPLAINTS_AND_SUGGESTIONS", "SUGGESTIONS"), (snap) => {
                    setallSuggestions(snap.docs.map((sugDoc) => ({
                        suggestionId: sugDoc.id,
                        suggestionData: sugDoc.data()
                    })))
                })
            }
        } else {
            if (userSchoolId && schoolData?.SCHOOL_NAME) {
                onSnapshot(collection(dB, "SCHOOLS", userSchoolId, schoolData?.SCHOOL_NAME, "COMPLAINTS_AND_SUGGESTIONS", "SUGGESTIONS"), (snap) => {
                    setallSuggestions(snap.docs.map((sugDoc) => ({
                        suggestionId: sugDoc.id,
                        suggestionData: sugDoc.data()
                    })))
                })
            }
        }
    }, [schoolData?.SCHOOL_NAME, userCurrentStatus, userId, userPathCollection, userSchoolId])

    const [allComplaints, setallComplaints] = useState([])
    useEffect(() => {
        if (userCurrentStatus === "SCHOOL") {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "SCHOOLS", userId, userPathCollection, "COMPLAINTS_AND_SUGGESTIONS", "COMPLAINTS"), (snap) => {
                    setallComplaints(snap.docs.map((compDoc) => ({
                        complaintId: compDoc.id,
                        complaintData: compDoc.data()
                    })))
                })
            }
        } else {
            if (userSchoolId && schoolData?.SCHOOL_NAME) {
                onSnapshot(collection(dB, "SCHOOLS", userSchoolId, schoolData?.SCHOOL_NAME, "COMPLAINTS_AND_SUGGESTIONS", "COMPLAINTS"), (snap) => {
                    setallComplaints(snap.docs.map((compDoc) => ({
                        complaintId: compDoc.id,
                        complaintData: compDoc.data()
                    })))
                })
            }
        }
    }, [schoolData?.SCHOOL_NAME, userCurrentStatus, userId, userPathCollection, userSchoolId])

    return (
        <>
            {
                userCurrentStatus === 'SCHOOL' ? (
                    <div className='HomeWorksPrincipal'>
                        <div className='HomeWorksPrincipal__top'>
                            <h3>{schoolData?.SCHOOL_FULL_NAME}</h3>
                            <h4>Complaints And Suggestions</h4>
                            <hr />
                        </div>
                        <ComplaintsSchoolData
                            allSuggestions={allSuggestions}
                            allComplaints={allComplaints}
                        />
                    </div>
                ) : (
                    <div className='HomeWorksPrincipal'>
                        <div className='HomeWorksPrincipal__top'>
                            <h3>{schoolData?.SCHOOL_FULL_NAME}</h3>
                            <h4>Complaints And Suggestions</h4>
                            <hr />
                        </div>
                        {
                            princiVerified ? (
                                <ComplaintsSchoolData
                                    allSuggestions={allSuggestions}
                                    allComplaints={allComplaints}
                                />
                            ) : (
                                <div className='homeworkPrinciNotVerified'>
                                    <h3>You are not verified by your school at the moment. Please wait till your school verifies you.</h3>
                                </div>
                            )
                        }

                    </div>
                )
            }
        </>

    )
}

export default ComplaintsSchool