import React from 'react'
import '../../Pages/HowToUse.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function HowToUseVerMem() {
    return (
        <>
            <div className='HowToUse'>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className='HowToUseSummary'>
                            <h3>How to see the list of Verified Teachers?</h3>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='HowToUseDetails'>
                            <h2>The list of verified teachers will appear here. If you found out that you have accepted their request accidentally or by mistake, then you remove them from the list of verified member here.</h2>

                            <div className='HowToData'>
                                <h3>Teachers</h3>
                                <p><span>Step 1 : </span> Click on “Verified Members” link on the left side of the homepage.</p>
                                <p><span>Step 2 : </span> Click on “School Staff”.</p>
                                <p><span>Step 3 : </span> Click on “Teaching Staff”.</p>
                                <p><span>Step 4 : </span> Click on the Medium you want. For example “English Medium”.</p>
                                <p><span>Step 5 : </span> Click on the Class you want. For example “Kindergarten-1 (KG-1)”.</p>
                                <p><span>Step 6 : </span> Click on the Section you want. For example “Section A”.</p>
                                <p><span>Step 7 : </span> A vertical list of subjects will appear. </p>
                                <p><span>Step 8 : </span> For each subject, if you have verified a teacher for that particular subject, you will see teachers name with “Remove” button appear in front of that subject. </p>
                                <div className='HowToDataData'>
                                    <p><span style={{ fontSize: '20px', fontWeight: '700' }}>•</span> You can <b>remove</b> the verified member if you found out that the request was accepted by mistake and person does not teach at your school or person teaches at your school but other subjects or at other class and section.</p>
                                </div>
                                <p><span>Step 9 : </span> If no member is verified for that subject then you will see message as “No member is verified for the subject of English”.</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className='HowToUse'>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className='HowToUseSummary'>
                            <h3>How to see the Verified Principal?</h3>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='HowToUseDetails'>
                            <h2>Verified principal will appear here. If you found out that you have accepted their request accidentally or by mistake, then you remove them from the list of verified member here.</h2>

                            <div className='HowToData'>
                                <h3>Principal</h3>
                                <p><span>Step 1 : </span> Click on “Verified Members” link on the left side of the homepage.</p>
                                <p><span>Step 2 : </span> Click on “School Staff”.</p>
                                <p><span>Step 3 : </span> Click on “Principal / HM”.</p>
                                <p><span>Step 4 : </span> Verified principal will appear.</p>
                                <p><span>Step 5 : </span> You will see the principal’s name and image with buttons of “Remove” appearing beside name.</p>
                                <div className='HowToDataData'>
                                    <p><span style={{ fontSize: '20px', fontWeight: '700' }}>•</span> You can <b>remove</b> the principal if you found out that person is not genuine principal of your school and the request was accepted by mistake.</p>
                                </div>
                                <p><span>Step 6 : </span> If no principal is verified then you will see message as “No one is verified at the moment”.</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className='HowToUse'>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className='HowToUseSummary'>
                            <h3>How to see the list of Verified Parents?</h3>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='HowToUseDetails'>
                            <h2>The list of verified parents will appear here. If you found out that you have accepted their request accidentally or by mistake, then you remove them from the list of verified member here.</h2>

                            <div className='HowToData'>
                                <h3>Parents</h3>
                                <p><span>Step 1 : </span> Click on “Verified Members” link on the left side of the homepage.</p>
                                <p><span>Step 2 : </span> Click on “Parents”.</p>
                                <p><span>Step 3 : </span> Click on the Medium you want. For example “English Medium”.</p>
                                <p><span>Step 4 : </span> Click on the Class you want. For example “Kindergarten-1 (KG-1)”.</p>
                                <p><span>Step 5 : </span> Click on the Section you want. For example “Section A”.</p>
                                <p><span>Step 6 : </span> A list of verified parents will appear.</p>
                                <p><span>Step 7 : </span> For each verified parent, you will see the parents name and image with parental status, i.e. “Father of” or “Mother of” and child’s name and image. For each verified parent, “Remove” button will appear besides child’s name.</p>
                                <div className='HowToDataData'>
                                    <p><span style={{ fontSize: '20px', fontWeight: '700' }}>•</span> You can <b>remove</b> the verified member if you found out that child does not study at your school or if child studies at your school but in some other class or section or medium and the request was accepted by mistake or child studies in your school with request at the right class and section but parents name appearing is not his/her genuine father/mother.</p>
                                </div>
                                <p><span>Step 8 : </span> If no parent is verified then you will see message as “No members are verified at the moment”.</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className='HowToUse'>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className='HowToUseSummary'>
                            <h3>How to see the list of Verified Students?</h3>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='HowToUseDetails'>
                            <h2>The list of verified students will appear here. If you found out that you have accepted their request accidentally or by mistake, then you remove them from the list of verified member here.</h2>

                            <div className='HowToData'>
                                <h3>Students</h3>
                                <p><span>Step 1 : </span> Click on “Verified Members” link on the left side of the homepage.</p>
                                <p><span>Step 2 : </span> Click on “Students”.</p>
                                <p><span>Step 3 : </span> Click on the Medium you want. For example “English Medium”.</p>
                                <p><span>Step 4 : </span> Click on the Class you want. For example “Class 8”.</p>
                                <p><span>Step 5 : </span> Click on the Section you want. For example “Section A”.</p>
                                <p><span>Step 6 : </span> A list of verified students will appear.</p>
                                <p><span>Step 7 : </span> For each verified student, you will see the student’s name and image with button of “Remove” appearing beside name.</p>
                                <div className='HowToDataData'>
                                    <p><span style={{ fontSize: '20px', fontWeight: '700' }}>•</span> You can <b>remove</b> the verified student if you found out that student does not study at your school or if student studies at your school but in some other class or section or medium and the request was accepted by mistake.</p>
                                </div>
                                <p><span>Step 8 : </span> If no student is verified then you will see message as “No members are verified at the moment”.</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
}

export default HowToUseVerMem