import { doc, onSnapshot } from 'firebase/firestore'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import dB from '../../firebase'
import SchoolTimeTableUsersParentsChild from './SchoolTimeTableUsersParentsChild'

function SchoolTimeTableUsersParents(props) {
    const { userId, userPathCollection, userCurrentStatus,
        childSchoolId, childId, ischildVerified } = props

    const [childSchoolData, setchildSchoolData] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (childSchoolId) {
                onSnapshot(doc(dB, "SCHOOLS", childSchoolId), (childDOc) => {
                    if (childDOc.exists()) {
                        setchildSchoolData(childDOc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [childSchoolId])

    // get childents details like name, class, section, medium
    const [childsData, setchildsData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection && childId) {
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", childId), (childDOc) => {
                    if (childDOc.exists()) {
                        setchildsData(childDOc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [childId, userId, userPathCollection])
    return (
        <div>
            <div className='HomeWorksParents'>
                <div className='HomeWorksParents__top'>
                    <h3>{childSchoolData?.SCHOOL_FULL_NAME}</h3>
                    <h4>Timetable</h4>
                    <hr />
                </div>

                <div className='HomeWorksParents__bottom'>
                    <div className='childName'>
                        <h3>Students Name</h3>
                        <h4>:</h4>
                        <div className='childNameName'>
                            <h5>{childsData?.CHILDS_FULL_NAME}</h5>
                            <p>({childsData?.CHILDS_MEDIUM} · {childsData?.CHILDS_CLASS} · {childsData?.CHILDS_SECTION})</p>
                        </div>
                    </div>
                    {
                        ischildVerified ? (
                            <SchoolTimeTableUsersParentsChild
                                userId={userId}
                                userPathCollection={userPathCollection}
                                userCurrentStatus={userCurrentStatus}
                                childSchoolId={childSchoolId}
                                childName={childsData?.CHILDS_FULL_NAME}
                                childMedium={childsData?.CHILDS_MEDIUM}
                                childClass={childsData?.CHILDS_CLASS}
                                childSection={childsData?.CHILDS_SECTION}
                                childVerified={ischildVerified}
                                childId={childId}
                                childSchoolPathCollection={childSchoolData?.SCHOOL_NAME}
                            />
                        ) : (
                            <div className='HomeWorksParentsChildNotVerified'>
                                <h3>Your child is not verified by your school at the moment.<br /> Please wait till school verifies your child.</h3>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default SchoolTimeTableUsersParents