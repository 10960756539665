import React, { useEffect, useState } from 'react'
import '../Sidebars/HomePageSidebar.css'
import HomePageSidebarTeacher from './HomePageSidebarTeacher';
import HomePageSidebarPrincipal from './HomePageSidebarPrincipal';
import HomePageSidebarParents from './HomePageSidebarParents';
import HomePageSidebarJusAUser from './HomePageSidebarJusAUser';
import HomePageSidebarStudents from './HomePageSidebarStudents';
// import dB from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { Link } from "react-router-dom";
import { Avatar } from '@mui/material';
import dB from '../../firebase';


function HomePageSidebar(props) {
    const { userName, userCurrentStatus, userId, userPathCollection, userDP } = props

    const [schoolData, setschoolData] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection && userCurrentStatus === 'TEACHER') {
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "VERIFICATION_DETAILS"), (teachDoc) => {
                    if (teachDoc.exists()) {
                        setschoolData(teachDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userCurrentStatus, userId, userPathCollection])

    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection && userCurrentStatus === 'PRINCIPAL') {
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), (princiDoc) => {
                    if (princiDoc.exists()) {
                        setschoolData(princiDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userCurrentStatus, userId, userPathCollection])

    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection && userCurrentStatus === 'STUDENT') {
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_STUDENT", "VERIFICATION_DETAILS"), (studDoc) => {
                    if (studDoc.exists()) {
                        setschoolData(studDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userCurrentStatus, userId, userPathCollection])

    return (
        <div className='sidebar'>
            {
                userCurrentStatus === 'PARENT' ? (
                    <div className='sidebar__nameParent'>
                        <Link to='/userprofile' style={{ textDecoration: 'none', color: 'black' }}>
                            <Avatar
                                src={userDP}
                                alt={userName}
                                // className={classes.Extralarge} 
                                sx={{ width: 50, height: 50 }}
                            />
                        </Link>
                        <h5>
                            <Link to='/userprofile' style={{ textDecoration: 'none', color: 'black', fontSize: '20px', fontWeight: '700' }}>
                                {userName}
                            </Link>
                        </h5>
                    </div>
                ) : (
                    <div className='sidebar__name'>
                        <Link to='/userprofile' style={{ textDecoration: 'none', color: 'black' }}>
                            <Avatar
                                src={userDP}
                                alt={userName}
                                // className={classes.Extralarge} 
                                sx={{ width: 50, height: 50 }}
                            />
                        </Link>
                        <h5>
                            <Link to='/userprofile' style={{ textDecoration: 'none', color: 'black', fontSize: '20px', fontWeight: '700' }}>
                                {userName}
                            </Link>
                        </h5>
                    </div>
                )
            }
            {
                userCurrentStatus === 'TEACHER' ? (
                    <HomePageSidebarTeacher
                        userCurrentStatus={userCurrentStatus}
                        userId={userId}
                        userPathCollection={userPathCollection}
                        teacherSchoolData={schoolData}
                    />
                ) : (
                    userCurrentStatus === 'PRINCIPAL' ? (
                        <HomePageSidebarPrincipal
                            userCurrentStatus={userCurrentStatus}
                            userId={userId}
                            userPathCollection={userPathCollection}
                            princiSchoolData={schoolData}
                        />
                    ) : (
                        userCurrentStatus === 'PARENT' ? (
                            <HomePageSidebarParents
                                userCurrentStatus={userCurrentStatus}
                                userId={userId}
                                userPathCollection={userPathCollection}
                            />
                        ) : (
                            userCurrentStatus === '' ? (
                                <HomePageSidebarJusAUser
                                    userCurrentStatus={userCurrentStatus}
                                    userId={userId}
                                    userPathCollection={userPathCollection}
                                />
                            ) : (
                                <HomePageSidebarStudents
                                    userCurrentStatus={userCurrentStatus}
                                    userId={userId}
                                    userPathCollection={userPathCollection}
                                    studentSchoolData={schoolData}
                                />
                            )
                        )
                    )
                )
            }
        </div>
    )
}

export default HomePageSidebar
