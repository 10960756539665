import React, { useEffect, useState } from 'react'
import '../ProjectsHomeworks/HomeWorksTeachersClassNSubject.css'
import HomeWorksTeachersClassNSubjectAssignments from './HomeWorksTeachersClassNSubjectAssignments';
import HomeWorksTeachersClassNSubjectHomework from './HomeWorksTeachersClassNSubjectHomework';
import HomeWorksTeachersClassNSubjectProjects from './HomeWorksTeachersClassNSubjectProjects';
import { addDoc, collection, onSnapshot, serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import dB, { storage } from '../../firebase';
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec';

function HomeWorksTeachersClassNSubject(props) {
    const { userId, userPathCollection, userSchoolId, userSchoolPathCollection } = props

    const [teachersData, setteachersData] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "IS_TEACHER_DATA", "SUBJECTS_TEACH"), (snap) => {
                    setteachersData(snap.docs.map((subDoc) => ({
                        subjectId: subDoc.id,
                        subjectData: subDoc.data(),
                    })))
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])


    const [selectedSubData, setselectedSubData] = useState({
        isTeachSubVerified: '', teacherClass: '', teacherMedium: '',
        teacherSection: '', teacherSubject: '', teacherSubjectId: ''
    })
    const handleClickSelectClass = async (data) => {
        await setselectedSubData(prev => {
            return {
                ...prev,
                isTeachSubVerified: data?.subjectData?.VERIFIED_TEACHER,
                teacherClass: data?.subjectData?.TEACH_CLASS,
                teacherMedium: data?.subjectData?.TEACH_MEDIUM,
                teacherSection: data?.subjectData?.TEACH_CLASS_SEC,
                teacherSubject: data?.subjectData?.TEACH_CLASS_SEC_SUB,
                teacherSubjectId: data?.subjectId
            }
        })
        setsentText(false)
    }

    // Setting the Teachers Medium Class n Section
    const [teacherMedClsSec, setteacherMedClsSec] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (selectedSubData.isTeachSubVerified) {
                setteacherMedClsSec('')
                var abc = returnMedClsSec(selectedSubData.teacherMedium, selectedSubData.teacherClass, selectedSubData.teacherSection)
                setteacherMedClsSec(abc)
            }
        }
        return () => { aaa = false }
    }, [selectedSubData.teacherMedium, selectedSubData.teacherClass, selectedSubData.teacherSection, selectedSubData.isTeachSubVerified])

    const [assData, setassData] = useState([])
    const [homeData, sethomeData] = useState([])
    const [proData, setproData] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            async function fetchdataHome(params) {
                if (userSchoolId && userSchoolPathCollection && teacherMedClsSec && selectedSubData.teacherSubject) {
                    onSnapshot(collection(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", teacherMedClsSec, "PROJECTS_&_HOMEWORKS", selectedSubData.teacherSubject, "HOMEWORK"), (snap) => {
                        sethomeData(snap.docs.map((homeDoc) => ({
                            homeId: homeDoc.id,
                            homeData: homeDoc.data(),
                        })))
                    })
                    onSnapshot(collection(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", teacherMedClsSec, "PROJECTS_&_HOMEWORKS", selectedSubData.teacherSubject, "ASSIGNMENT"), (snap) => {
                        setassData(snap.docs.map((assDoc) => ({
                            assId: assDoc.id,
                            assData: assDoc.data(),
                        })))
                    })
                    onSnapshot(collection(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", teacherMedClsSec, "PROJECTS_&_HOMEWORKS", selectedSubData.teacherSubject, "PROJECT"), (snap) => {
                        setproData(snap.docs.map((proDoc) => ({
                            proId: proDoc.id,
                            proData: proDoc.data(),
                        })))
                    })
                }
            }
            fetchdataHome();
        }
        return () => { aaa = false }
    }, [selectedSubData.teacherSubject, teacherMedClsSec, userSchoolId, userSchoolPathCollection])


    // INPUT
    const [homeworkData, sethomeworkData] = useState({
        workType: '', questionStatement: '', submitOnBefore: '', noteIfAny: '', questionPic: ''
    })
    const [showErrorHW, setshowErrorHW] = useState({
        workType: { error: false }, submitOnBefore: { error: false },
    })

    const handleChangeworkType = (e) => {
        e.preventDefault()
        sethomeworkData(prev => { return { ...prev, workType: e.target.value } })
        setshowErrorHW(prev => { return { ...prev, workType: { ...prev.workType, error: false } } })
        setbuttonDisabled(prev => { return { ...prev, buttonPublish: false, buttonCancel: false } })
        setsentText(false)
    }
    const handleChangeQuestion = (e) => {
        e.preventDefault()
        sethomeworkData(prev => { return { ...prev, questionStatement: e.target.value } })
        setshowErrorHW(prev => { return { ...prev, questionStatement: { ...prev.questionStatement, error: false } } })
        setsentText(false)
    }
    const handleChangenoteIfAny = (e) => {
        e.preventDefault()
        sethomeworkData(prev => { return { ...prev, noteIfAny: e.target.value } })
        setsentText(false)
    }
    const handleChangeSubmitDate = (event) => {
        event.preventDefault();
        sethomeworkData(prev => { return { ...prev, submitOnBefore: event.target.value } })
        setshowErrorHW(prev => { return { ...prev, submitOnBefore: { ...prev.submitOnBefore, error: false } } })
        setsentText(false)
    }

    const [buttonDisabled, setbuttonDisabled] = useState({ buttonPublish: false, buttonCancel: false })

    const handleClickCancelHW = (e) => {
        e.preventDefault()
        sethomeworkData(prev => {
            return {
                ...prev, workType: '', questionStatement: '', questionPic: '',
                submitOnBefore: '', noteIfAny: '',
            }
        })
        setImgData(null)
        setPicture(null)
        setbuttonDisabled(prev => { return { ...prev, buttonPublish: false, buttonCancel: false } })
        setshowErrorHW(prev => {
            return {
                ...prev, workType: { error: false },
                questionStatement: { error: false },
                questionPic: { error: false },
                submitOnBefore: { error: false },
            }
        })
    }

    const [randomKey, setrandomKey] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var a = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
            setrandomKey(a)
        }
        return () => { aaa = false }
    }, [])
    var resizeImage = function (settings) {
        var file = settings.file;
        var maxSize = settings.maxSize;
        var reader = new FileReader();
        var image = new Image();
        var canvas = document.createElement('canvas');
        var dataURItoBlob = function (dataURI) {
            var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
                atob(dataURI.split(',')[1]) :
                unescape(dataURI.split(',')[1]);
            var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var max = bytes.length;
            var ia = new Uint8Array(max);
            for (var i = 0; i < max; i++)
                ia[i] = bytes.charCodeAt(i);
            return new Blob([ia], { type: mime });
        };
        var resize = function () {
            var width = image.width;
            var height = image.height;
            if (width > height) {
                if (width > maxSize) {
                    height *= maxSize / width;
                    width = maxSize;
                }
            } else {
                if (height > maxSize) {
                    width *= maxSize / height;
                    height = maxSize;
                }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            var dataUrl = canvas.toDataURL('image/jpeg');
            return dataURItoBlob(dataUrl);
        };
        return new Promise(function (ok, no) {
            if (!file.type.match(/image.*/)) {
                no(new Error("Not an image"));
                return;
            }
            reader.onload = function (readerEvent) {
                image.onload = function () { return ok(resize()); };
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        });
    };

    const [sentText, setsentText] = useState(false)
    // const [progress, setprogress] = useState(0);
    const [imgData, setImgData] = useState(null);
    const [picture, setPicture] = useState(null);
    const onChangePicture = async (e) => {
        if (e.target.files[0]) {
            setPicture(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
            setsentText(false)

            const config = {
                file: e.target.files[0],
                maxSize: 500
            };
            const resizedImage = await resizeImage(config)
            setPicture(resizedImage);
        }
    };

    const [btnLoading, setbtnLoading] = useState(false)
    const handleClickPublishHW = async (e) => {
        e.preventDefault()
        setbuttonDisabled(prev => { return { ...prev, buttonPublish: true, buttonCancel: false } })
        setbtnLoading(true)
        if (homeworkData.workType && homeworkData.submitOnBefore) {

            if (picture) {

                const imageRef = ref(storage, `USERS/${userId}/Homeworks/${randomKey}`);
                const metadata = { contentType: "image/jpeg", };

                await uploadBytes(imageRef, picture, metadata)
                    .then(async (snapshot) => {
                        const downloadURL = await getDownloadURL(imageRef);
                        if (homeworkData.workType === 'Daily Homework') {
                            addDoc(collection(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", teacherMedClsSec, "PROJECTS_&_HOMEWORKS", selectedSubData.teacherSubject, "HOMEWORK"), {
                                QUESTION_IMAGE: downloadURL,
                                QUESTION_NOTE: homeworkData.noteIfAny,
                                QUESTION_STATEMENT: homeworkData.questionStatement,
                                SUBMIT_BEFORE: homeworkData.submitOnBefore,
                                UPLOADED_ON: serverTimestamp(),
                                VIEWED_BY: []
                            }).then(() => {
                                setbtnLoading(false)
                                setbuttonDisabled(prev => { return { ...prev, buttonPublish: false, buttonCancel: false } })
                                setsentText(true)
                                setImgData(null)
                                setPicture(null)
                                sethomeworkData(prev => {
                                    return {
                                        ...prev, workType: '', questionStatement: '', questionPic: '',
                                        submitOnBefore: '', noteIfAny: '',
                                    }
                                })
                                var b = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
                                setrandomKey(b)
                            }).catch(err => alert(err.message))
                        }
                        if (homeworkData.workType === 'Assignment Work') {
                            addDoc(collection(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", teacherMedClsSec, "PROJECTS_&_HOMEWORKS", selectedSubData.teacherSubject, "ASSIGNMENT"), {
                                QUESTION_IMAGE: downloadURL,
                                QUESTION_NOTE: homeworkData.noteIfAny,
                                QUESTION_STATEMENT: homeworkData.questionStatement,
                                SUBMIT_BEFORE: homeworkData.submitOnBefore,
                                UPLOADED_ON: serverTimestamp(),
                                VIEWED_BY: []
                            }).then(() => {
                                setbtnLoading(false)
                                setbuttonDisabled(prev => { return { ...prev, buttonPublish: false, buttonCancel: false } })
                                setsentText(true)
                                setImgData(null)
                                setPicture(null)
                                sethomeworkData(prev => {
                                    return {
                                        ...prev, workType: '', questionStatement: '', questionPic: '',
                                        submitOnBefore: '', noteIfAny: '',
                                    }
                                })
                                var b = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
                                setrandomKey(b)
                            }).catch(err => alert(err.message))
                        }
                        if (homeworkData.workType === 'Project Work') {
                            addDoc(collection(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", teacherMedClsSec, "PROJECTS_&_HOMEWORKS", selectedSubData.teacherSubject, "PROJECT"), {
                                QUESTION_IMAGE: downloadURL,
                                QUESTION_NOTE: homeworkData.noteIfAny,
                                QUESTION_STATEMENT: homeworkData.questionStatement,
                                SUBMIT_BEFORE: homeworkData.submitOnBefore,
                                UPLOADED_ON: serverTimestamp(),
                                VIEWED_BY: []
                            }).then(() => {
                                setbtnLoading(false)
                                setbuttonDisabled(prev => { return { ...prev, buttonPublish: false, buttonCancel: false } })
                                setsentText(true)
                                setImgData(null)
                                setPicture(null)
                                sethomeworkData(prev => {
                                    return {
                                        ...prev, workType: '', questionStatement: '', questionPic: '',
                                        submitOnBefore: '', noteIfAny: '',
                                    }
                                })
                                var b = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
                                setrandomKey(b)
                            }).catch(err => alert(err.message))
                        }
                    })
            } else {
                if (homeworkData.workType === 'Daily Homework') {
                    addDoc(collection(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", teacherMedClsSec, "PROJECTS_&_HOMEWORKS", selectedSubData.teacherSubject, "HOMEWORK"), {
                        QUESTION_IMAGE: '',
                        QUESTION_NOTE: homeworkData.noteIfAny,
                        QUESTION_STATEMENT: homeworkData.questionStatement,
                        SUBMIT_BEFORE: homeworkData.submitOnBefore,
                        UPLOADED_ON: serverTimestamp(),
                        VIEWED_BY: []
                    }).then(() => {
                        setbtnLoading(false)
                        setbuttonDisabled(prev => { return { ...prev, buttonPublish: false, buttonCancel: false } })
                        setsentText(true)
                        setImgData(null)
                        setPicture(null)
                        sethomeworkData(prev => {
                            return {
                                ...prev, workType: '', questionStatement: '', questionPic: '',
                                submitOnBefore: '', noteIfAny: '',
                            }
                        })
                    }).catch(err => alert(err.message))
                }
                if (homeworkData.workType === 'Assignment Work') {
                    addDoc(collection(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", teacherMedClsSec, "PROJECTS_&_HOMEWORKS", selectedSubData.teacherSubject, "ASSIGNMENT"), {
                        QUESTION_IMAGE: '',
                        QUESTION_NOTE: homeworkData.noteIfAny,
                        QUESTION_STATEMENT: homeworkData.questionStatement,
                        SUBMIT_BEFORE: homeworkData.submitOnBefore,
                        UPLOADED_ON: serverTimestamp(),
                        VIEWED_BY: []
                    }).then(() => {
                        setbuttonDisabled(prev => { return { ...prev, buttonPublish: false, buttonCancel: false } })
                        setsentText(true)
                        setImgData(null)
                        setPicture(null)
                        setbtnLoading(false)
                        sethomeworkData(prev => {
                            return {
                                ...prev, workType: '', questionStatement: '', questionPic: '',
                                submitOnBefore: '', noteIfAny: '',
                            }
                        })
                    }).catch(err => alert(err.message))
                }
                if (homeworkData.workType === 'Project Work') {
                    addDoc(collection(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", teacherMedClsSec, "PROJECTS_&_HOMEWORKS", selectedSubData.teacherSubject, "PROJECT"), {
                        QUESTION_IMAGE: '',
                        QUESTION_NOTE: homeworkData.noteIfAny,
                        QUESTION_STATEMENT: homeworkData.questionStatement,
                        SUBMIT_BEFORE: homeworkData.submitOnBefore,
                        UPLOADED_ON: serverTimestamp(),
                        VIEWED_BY: []
                    }).then(() => {
                        setbtnLoading(false)
                        setbuttonDisabled(prev => { return { ...prev, buttonPublish: false, buttonCancel: false } })
                        setsentText(true)
                        setImgData(null)
                        setPicture(null)
                        sethomeworkData(prev => {
                            return {
                                ...prev, workType: '', questionStatement: '', questionPic: '',
                                submitOnBefore: '', noteIfAny: '',
                            }
                        })
                    }).catch(err => alert(err.message))
                }
            }
        } else {
            setbtnLoading(false)
            if (!homeworkData.workType) { setshowErrorHW(prev => { return { ...prev, workType: { ...prev.workType, error: true } } }) }
            if (!homeworkData.submitOnBefore) { setshowErrorHW(prev => { return { ...prev, submitOnBefore: { ...prev.submitOnBefore, error: true } } }) }
        }
    }

    return (
        <div className='homeworksteachersclassnsubject'>
            <div className='homeworksteachersclassnsubject__left'>
                {
                    teachersData.map(data => (
                        <div key={data?.subjectId} className={`teacherClassTab`} onClick={(e) => { e.preventDefault(); handleClickSelectClass(data) }}>
                            <h4>{data?.subjectData?.TEACH_MEDIUM}</h4>
                            <h3>{data?.subjectData?.TEACH_CLASS} | {data?.subjectData?.TEACH_CLASS_SEC}</h3>
                            <h2>{data?.subjectData?.TEACH_CLASS_SEC_SUB}</h2>
                        </div>
                    ))
                }
            </div>

            {
                selectedSubData.teacherSubjectId && (
                    <div className='homeworksteachersclassnsubject__right'>
                        {
                            selectedSubData.isTeachSubVerified ? (
                                <div>
                                    <div className='headingHeading'>
                                        <h4>{selectedSubData.teacherMedium} | {selectedSubData.teacherClass} | {selectedSubData.teacherSection} </h4>
                                        <div className='headingHeadingSubject'>
                                            <h2>Subject</h2>
                                            <h3>:</h3>
                                            <h4>{selectedSubData.teacherSubject}</h4>
                                        </div>
                                        <hr />
                                    </div>

                                    <div className='recentlyAdddedHW recentlyAdddedHead'>
                                        <h2>Recently Published Homeworks/Projects</h2>
                                        {
                                            homeData.length !== 0 || assData.length !== 0 || proData.length !== 0 ? (
                                                <div>
                                                    {
                                                        homeData.length > 0 && (
                                                            <div className='recentlyAdddedHWHomework recentlyAdddedData'>
                                                                <h3>Homeworks</h3>
                                                                {homeData.length === 7 && (<h4>(You have exhausted your homeworks limit. You can store Max 7 homeworks at a time. Delete few homeworks to add new homeworks.)</h4>)}
                                                                {
                                                                    homeData.map((data, i) => (
                                                                        <HomeWorksTeachersClassNSubjectHomework
                                                                            key={i}
                                                                            questionPic={data?.homeData?.QUESTION_IMAGE}
                                                                            questionNoteIfAny={data?.homeData?.QUESTION_NOTE}
                                                                            questionStatement={data?.homeData?.QUESTION_STATEMENT}
                                                                            submitOnBefore={data?.homeData?.SUBMIT_BEFORE}
                                                                            uploadedOn={data?.homeData?.UPLOADED_ON}
                                                                            questionId={data?.homeId}
                                                                            userId={userId}
                                                                            userPathCollection={userPathCollection}
                                                                            userSchoolId={userSchoolId}
                                                                            userSchoolPathCollection={userSchoolPathCollection}
                                                                            isTeachSubVerified={selectedSubData.isTeachSubVerified}
                                                                            teacherMedClsSec={teacherMedClsSec}
                                                                            teacherSubject={selectedSubData.teacherSubject}
                                                                            teacherSubjectId={selectedSubData.teacherSubjectId}
                                                                        />
                                                                    ))
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        assData.length > 0 && (
                                                            <div className='recentlyAdddedHWAssignment recentlyAdddedData'>
                                                                <h3>Assignments</h3>
                                                                {assData.length === 7 && (<h4>(You have exhausted your assignments limit. You can store Max 7 assignments at a time. Delete few homeworks to add new assignments.)</h4>)}
                                                                {
                                                                    assData.map((data, i) => (
                                                                        <HomeWorksTeachersClassNSubjectAssignments
                                                                            key={i}
                                                                            questionPic={data?.assData?.QUESTION_IMAGE}
                                                                            questionNoteIfAny={data?.assData?.QUESTION_NOTE}
                                                                            questionStatement={data?.assData?.QUESTION_STATEMENT}
                                                                            submitOnBefore={data?.assData?.SUBMIT_BEFORE}
                                                                            uploadedOn={data?.assData?.UPLOADED_ON}
                                                                            questionId={data?.assId}

                                                                            userId={userId}
                                                                            userPathCollection={userPathCollection}
                                                                            userSchoolId={userSchoolId}
                                                                            userSchoolPathCollection={userSchoolPathCollection}
                                                                            isTeachSubVerified={selectedSubData.isTeachSubVerified}
                                                                            teacherMedClsSec={teacherMedClsSec}
                                                                            teacherSubject={selectedSubData.teacherSubject}
                                                                            teacherSubjectId={selectedSubData.teacherSubjectId}
                                                                        />
                                                                    ))
                                                                }
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        proData.length > 0 && (
                                                            <div className='recentlyAdddedHWProject recentlyAdddedData'>
                                                                <h3>Projects</h3>
                                                                {proData.length === 7 && (<h4>(You have exhausted your projectss limit. You can store Max 7 projectss at a time. Delete few homeworks to add new projects.)</h4>)}
                                                                {
                                                                    proData.map((data, i) => (
                                                                        <HomeWorksTeachersClassNSubjectProjects
                                                                            key={i}
                                                                            questionPic={data?.proData?.QUESTION_IMAGE}
                                                                            questionNoteIfAny={data?.proData?.QUESTION_NOTE}
                                                                            questionStatement={data?.proData?.QUESTION_STATEMENT}
                                                                            submitOnBefore={data?.proData?.SUBMIT_BEFORE}
                                                                            uploadedOn={data?.proData?.UPLOADED_ON}
                                                                            questionId={data?.proId}

                                                                            userId={userId}
                                                                            userPathCollection={userPathCollection}
                                                                            userSchoolId={userSchoolId}
                                                                            userSchoolPathCollection={userSchoolPathCollection}
                                                                            isTeachSubVerified={selectedSubData.isTeachSubVerified}
                                                                            teacherMedClsSec={teacherMedClsSec}
                                                                            teacherSubject={selectedSubData.teacherSubject}
                                                                            teacherSubjectId={selectedSubData.teacherSubjectId}
                                                                        />
                                                                    ))
                                                                }
                                                            </div>
                                                        )
                                                    }

                                                </div>
                                            ) : (
                                                <div className='noHomeworkProject'>
                                                    <h2>There is no homework/project published yet. Published homework/project will be shown here.</h2>
                                                </div>

                                            )
                                        }
                                    </div>
                                    <div className='addnewHw recentlyAdddedHead'>
                                        <h2>Add New Homeworks/Projects</h2>
                                        <Box sx={{ position: 'relative', }} key={'data.childsId'} id='addNewHWPA'>
                                            <Accordion >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <div className='publishHomeWrokHere'>
                                                        <h3>Click Here to Publish new homework/project</h3>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className='addnewHw__Details'>
                                                        {/* type of work */}
                                                        <FormControl error={showErrorHW.workType.error} variant="outlined">
                                                            <InputLabel htmlFor="outlined-age-native-simple">Work Type</InputLabel>
                                                            <Select
                                                                sx={{ width: '400px', marginBottom: '10px' }}
                                                                // className={Muiclasses.workType}
                                                                // native
                                                                value={homeworkData.workType}
                                                                onChange={handleChangeworkType}
                                                                label="Work Type"
                                                                inputProps={{
                                                                    name: 'age',
                                                                    id: 'outlined-age-native-simple',
                                                                }}
                                                            >
                                                                <MenuItem aria-label="None" value="" />

                                                                <MenuItem style={{ display: homeData.length === 7 ? 'none' : 'flex' }} key='Homework' value='Daily Homework'>Daily Homework</MenuItem>
                                                                <MenuItem style={{ display: assData.length === 7 ? 'none' : 'flex' }} key='Assignment' value='Assignment Work'>Assignment Work</MenuItem>
                                                                <MenuItem style={{ display: proData.length === 7 ? 'none' : 'flex' }} key='Project' value='Project Work'>Project Work</MenuItem>
                                                            </Select>
                                                        </FormControl>

                                                        {/* question statement */}
                                                        <TextField
                                                            sx={{ width: '400px', marginBottom: '10px' }}
                                                            // className={classes.question}
                                                            name='inputVision'
                                                            value={homeworkData.questionStatement}
                                                            onChange={handleChangeQuestion}
                                                            id="inputVision"
                                                            type='text'
                                                            label={`Type your question here.(Optional)`}
                                                            multiline
                                                            minRows={2}
                                                            maxRows={8}
                                                            variant="outlined"
                                                        />
                                                        {/* submit on/before */}
                                                        <div className='addnewHw__DetailsSubmit'>
                                                            <div className='addnewHw__DetailsSubmitOn' value={homeworkData.submitOnBefore} onChange={handleChangeSubmitDate}>
                                                                <h5>Submit On/Before : </h5>
                                                                <input
                                                                    type='date'
                                                                    data-date=""
                                                                    data-date-format="DD-MMMM-YYYY"
                                                                />

                                                            </div>
                                                            {showErrorHW.submitOnBefore.error && (<h5 id='student__SelectDOB'>Please select the date to submit homework/project.</h5>)}
                                                        </div>

                                                        {/* note if any */}
                                                        <TextField
                                                            // className={classes.notesIfAny}
                                                            sx={{ width: '400px' }}
                                                            name='notesIfAny'
                                                            value={homeworkData.noteIfAny}
                                                            onChange={handleChangenoteIfAny}
                                                            id="notesIfAny"
                                                            type='text'
                                                            label={`Note/Remrks (Optional)`}
                                                            multiline
                                                            minRows={1}
                                                            maxRows={8}
                                                            variant="outlined"
                                                        />

                                                        <div className='homeworkImage'>

                                                            {/* <h4>Add relevant image (Optional)</h4> */}

                                                            {/* <input
                                                                type="file"
                                                                
                                                                accept='.jpg, .jpeg, .png'
                                                            /> */}
                                                            <IconButton
                                                                color="primary"
                                                                aria-label="upload picture"
                                                                component="label"
                                                            >
                                                                <input
                                                                    hidden
                                                                    type="file"
                                                                    id="postImage"
                                                                    name="postImage"
                                                                    accept=".jpg,.jpeg,.png,"
                                                                    // multiple={userCurrentStatus === 'SCHOOL' ? true : false}
                                                                    onChange={onChangePicture}
                                                                />
                                                                <PhotoCamera sx={{ fontSize: "30px", color: 'lightblue' }} />
                                                                <h3 style={{
                                                                    fontSize: "14px",
                                                                    fontWeight: "600",
                                                                    color: "grey"
                                                                }}>Add Image (Optional)</h3>
                                                            </IconButton>
                                                            {/* <Button disabled={disabledButton} onClick={handleUpdateDisplayPic}>Update</Button> */}

                                                            {imgData && <img alt={imgData} src={imgData} />}

                                                            {/* <progress
                                                                className='imageUpload__progress'
                                                                value={progress}
                                                                max='100'
                                                            /> */}
                                                        </div>

                                                        <div className='buttonPublishCancel'>
                                                            <LoadingButton
                                                                onClick={handleClickPublishHW}
                                                                variant='outlined'
                                                                loading={btnLoading}
                                                            >
                                                                Publish
                                                            </LoadingButton>
                                                            {/* <Button disabled={buttonDisabled.buttonPublish} ></Button> */}
                                                            <Button disabled={buttonDisabled.buttonCancel} onClick={handleClickCancelHW}>Cancel</Button>
                                                        </div>
                                                        {
                                                            sentText && (
                                                                <div className='publishedSuccess'>
                                                                    <h3><strong>{homeworkData.workType}</strong> published successfully</h3>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>
                                    </div>
                                </div>

                            ) : (
                                <div className='teacherNotVerified'>
                                    <h2>You are not verified for the subject of <b>{selectedSubData.teacherSubject}</b> for <b>{selectedSubData.teacherMedium}/{selectedSubData.teacherClass}/{selectedSubData.teacherSection},</b> by your school, to update homeworks. Get yourself verified for this subject by your school first. For that, send verification request to your school and wait till they verify you. Till then you can use this platform to follow other schools and teachers and make new friends.</h2>
                                </div>
                            )
                        }

                    </div>
                )
            }




        </div >
    )
}

export default HomeWorksTeachersClassNSubject