import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyB2qBxJRjbPSchxtdXbmhc40Zmih1wGg6o",
    authDomain: "random-21953.firebaseapp.com",
    projectId: "random-21953",
    storageBucket: "random-21953.appspot.com",
    messagingSenderId: "703309943811",
    appId: "1:703309943811:web:1ee251010a74a029d56a36",
    measurementId: "G-PD54D0LQKM"
};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app)
const auth = getAuth(app)
const dB = getFirestore(app)
const storage = getStorage(app);

export default dB;
export { auth, storage, analytics };

// import firebase from 'firebase';
// import 'firebase/firestore'
// import "firebase/analytics";

// //     apiKey: "AIzaSyCjezqFCxuqZMABjxOsCRK_qhc8gajiCAs",
// //     authDomain: "getconnected-30a05.firebaseapp.com",
// //     projectId: "getconnected-30a05",
// //     storageBucket: "getconnected-30a05.appspot.com",
// //     messagingSenderId: "482861275451",
// //     appId: "1:482861275451:web:cb46a4356bbb56ea783f1e",
// //     measurementId: "G-W0BYLY9PD8"

// const firebaseApp = firebase.initializeApp({
//     apiKey: "AIzaSyB2qBxJRjbPSchxtdXbmhc40Zmih1wGg6o",
//     authDomain: "random-21953.firebaseapp.com",
//     projectId: "random-21953",
//     storageBucket: "random-21953.appspot.com",
//     messagingSenderId: "703309943811",
//     appId: "1:703309943811:web:1ee251010a74a029d56a36",
//     measurementId: "G-PD54D0LQKM"
// });

// const analytics = firebase.analytics();
// const dB = firebaseApp.firestore();
// const storage = firebase.storage();
// const auth = firebase.auth();

// // const provider = new firebase.auth.GoogleAuthProvider();

// dB.settings({ timestampsInSnapshots: true })

// // console.log(firebase);

// export { storage, auth, analytics };
// export default dB;