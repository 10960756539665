import React, { useEffect, useState } from 'react'
import '../FIndToFollow/FindToFollowSchools.css'
import { collection, doc, onSnapshot, serverTimestamp, writeBatch } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { Avatar, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';

function FindToFollowSchoolsCard(props) {

    const { userId, userPathCollection, userCurrentStatus, schoolName, schoolImage, schoolId } = props

    const [schoolPathCollection, setschoolPathCollection] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId) {
                onSnapshot(doc(dB, "SCHOOLS", schoolId), (snap) => {
                    if (snap.exists()) {
                        setschoolPathCollection(snap.data()?.SCHOOL_NAME)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [schoolId])

    const [followingSchlsIds, setfollowingSchlsIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                if (userCurrentStatus === 'SCHOOL') {
                    onSnapshot(collection(dB, "SCHOOLS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_SCHOOLS"), (snap) => {
                        setfollowingSchlsIds(snap.docs.map((schlDOc) => schlDOc.id))
                    })
                } else {
                    onSnapshot(collection(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_SCHOOLS"), (snap) => {
                        setfollowingSchlsIds(snap.docs.map((userDoc) => userDoc.id))
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [userCurrentStatus, userId, userPathCollection])

    const [btnLoading, setbtnLoading] = useState(false)

    var batch = writeBatch(dB)
    const handleFollowSchool = async (e) => {
        e.preventDefault();
        setbtnLoading(true)
        if (userCurrentStatus === 'SCHOOL') {
            batch.set(doc(dB, "SCHOOLS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_SCHOOLS", schoolId), {
                TIMESTAMP: serverTimestamp()
            })

            batch.set(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWERS", "FOLLOWER_SCHOOLS", userId), {
                TIMESTAMP: serverTimestamp()
            })
        }
        else {
            batch.set(doc(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_SCHOOLS", schoolId), {
                TIMESTAMP: serverTimestamp()
            })

            batch.set(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWERS", "FOLLOWER_SCHOOLS", userId), {
                TIMESTAMP: serverTimestamp()
            })
        }

        await batch.commit().then(() => {
            setbtnLoading(false)
        }).catch(err => alert(err.message))
    }
    const handleUnFollowSchool = async (e) => {
        e.preventDefault();
        if (userCurrentStatus === 'SCHOOL') {
            batch.delete(doc(dB, "SCHOOLS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_SCHOOLS", schoolId))
            batch.delete(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWERS", "FOLLOWER_SCHOOLS", userId))
        } else {
            batch.delete(doc(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_SCHOOLS", schoolId))
            batch.delete(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWERS", "FOLLOWER_SCHOOLS", userId))
        }
        await batch.commit().catch(err => alert(err.message))
    }

    return (
        <div className='FindToFollowSchoolsRow'>
            <Card sx={{ width: 170, }}>
                <div className='FindToFollowSchoolsCard'>
                    <Link to={`/schoolfriendprofile/${schoolId}`} style={{ textDecoration: 'none', color: 'black' }}>
                        <Avatar
                            alt={schoolName}
                            src={schoolImage}
                            // className={classes.root}
                            sx={{ width: 80, height: 80 }}
                        />
                    </Link>
                    <p>
                        <Link to={`/schoolfriendprofile/${schoolId}`} style={{ textDecoration: 'none', color: 'black' }}>
                            {schoolName}
                        </Link>
                    </p>
                    {
                        userId === schoolId ? (
                            <div className='schoolSelf'>
                                <h4>You</h4>
                            </div>
                        ) : (
                            <>
                                {
                                    followingSchlsIds.includes(schoolId) ? (
                                        <h5 onClick={handleUnFollowSchool}>Unfollow</h5>
                                    ) : (
                                        <LoadingButton
                                            onClick={handleFollowSchool}
                                            loading={btnLoading}
                                            variant='outlined'
                                        >
                                            Follow
                                        </LoadingButton>
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </Card>
        </div>
    )
}

export default FindToFollowSchoolsCard
