import React from 'react'
import '../Header/HeaderSentRequestCard.css'
import '../FIndToFollow/FindToFollowTeachersNFriends.css'
import '../Header/Header.css';
// import dB from '../firebase';
import { doc, writeBatch } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { Avatar, Card } from '@mui/material';
import dB from '../../firebase';

function HeaderSentRequestCard(props) {

    const { userId, userCurrentStatus, userPathCollection,
        userFullName, reqAvatar, reqId, reqUserName, reqCurrrentStatus } = props

    // const classes = useStyles();
    var batch = writeBatch(dB)
    const handleCancelRequest = async (e) => {
        e.preventDefault();

        const followingRef = doc(dB, "USERS", userId, userPathCollection, "REQUESTS_SENT", "REQUESTS_SENT_IDs", reqId);
        batch.delete(followingRef);

        const followerRef = doc(dB, "USERS", reqId, reqUserName, "REQUESTS_RECIEVED", "REQUESTS_RECIEVED_IDs", userId);
        batch.delete(followerRef);

        await batch.commit().catch(err => alert(err.message))

    }

    return (
        <div className='HeaderSentRequestCard'>
            <Card sx={{ width: 140, }}>
                <div className='HeaderSentRequestCardData'>
                    <Link to={`/friendsprofile/${reqId}`} style={{}}>
                        <Avatar
                            alt={reqUserName}
                            src={reqAvatar}
                            style={{ marginLeft: 'auto', marginRight: 'auto' }}
                            sx={{ width: 45, height: 45 }}
                        />
                    </Link>

                    <p>
                        <Link to={`/friendsprofile/${reqId}`} style={{ textDecoration: 'none', color: 'black' }}>
                            {reqUserName}
                        </Link>
                    </p>
                    {
                        reqCurrrentStatus === 'PRINCIPAL' ? (
                            <h5>[{reqCurrrentStatus}]</h5>
                        ) : reqCurrrentStatus === 'STUDENT' ? (
                            <h5>[{reqCurrrentStatus}]</h5>
                        ) : reqCurrrentStatus === 'TEACHER' ? (
                            <h5>[{reqCurrrentStatus}]</h5>
                        ) : (
                            <h5>[USER]</h5>
                        )
                    }
                    <div className='HeaderSentRequestCardDataH3'>
                        <h3 onClick={handleCancelRequest}>Cancel Sent</h3>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default HeaderSentRequestCard
