import React, { useEffect, useState } from 'react'
import '../Pages/SchoolUpcomingEvents.css'
import '../Pages/SidebarNoticeBoard.css'
import Header from '../Components/Header/Header';
import SchoolUpcomingEventData from '../Components/UpcomingEvents/SchoolUpcomingEventData';
// import Header from '../Components/Header'
// import SchoolUpcomingEventData from '../Components/SchoolUpcomingEventData';
import dB, { auth } from '../firebase';
import { addDoc, collection, doc, onSnapshot, orderBy, query, serverTimestamp } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

import AddBoxIcon from '@mui/icons-material/AddBox';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, FormControlLabel, FormHelperText, OutlinedInput, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';



function SchoolUpcomingEvents() {

    const [userData, setuserData] = useState({
        schoolId: '', schoolFullName: '', userCurrentStatus: '', userPathCollection: '',
    })
    useEffect(() => {
        const unSubscribe = onAuthStateChanged(auth, async (userCredentials) => {
            if (userCredentials) {
                var uid = userCredentials.uid;
                onSnapshot(doc(dB, "SCHOOLS", uid), (schlDOc) => {
                    if (schlDOc.exists()) {
                        setuserData(prevState => {
                            return {
                                ...prevState,
                                schoolId: uid,
                                schoolFullName: schlDOc.data()?.SCHOOL_FULL_NAME,
                                userCurrentStatus: 'SCHOOL',
                                userPathCollection: schlDOc.data()?.SCHOOL_NAME,
                            }
                        })
                    }
                })
            }
        });
        return () => { unSubscribe(); }
    }, [userData.schoolId, userData.userCurrentStatus, userData.userId, userData.userPathCollection])

    const [activeTab, setactiveTab] = useState({ createEvent: '', sentEvent: '' })
    const handleClickCreateNewNotices = (e) => {
        e.preventDefault()
        setactiveTab(prevState => { return { ...prevState, createEvent: 'active', sentEvent: '' } })
    }
    const handleClickAlreadyPublicNotices = (e) => {
        e.preventDefault()
        setactiveTab(prevState => { return { ...prevState, createEvent: '', sentEvent: 'active' } })
    }

    const [showError, setshowError] = useState({
        eventName: { error: false, helperText: '' },
        eventCrowd: { error: false, helperText: '' },
        eventType: { error: false, helperText: '' },
        eventDate: { helperText: '' },
    })


    const [eventData, seteventData] = useState({
        eventName: '', eventDate: '', eventDescription: '', eventCrowd: '', eventType: '',
        eventChiefGuests: {
            chiefGuest1: '', chiefGuest2: '', chiefGuest3: '', chiefGuest4: '',
        }
    })
    const handleChangeEventName = (event) => {
        event.preventDefault();
        seteventPublished(false)
        seteventData(prevState => { return { ...prevState, eventName: event.target.value } });
        setshowError(prevState => { return { ...prevState, eventName: { error: false, helperText: '' } } })
    }
    const handleChangeEventDate = (event) => {
        event.preventDefault();
        seteventPublished(false)
        seteventData(prevState => { return { ...prevState, eventDate: event.target.value } });
        setshowError(prev => { return { ...prev, eventDate: { helperText: '' }, } })
    }
    const handleChangeEventDescription = (event) => {
        event.preventDefault();
        seteventPublished(false)
        seteventData(prevState => { return { ...prevState, eventDescription: event.target.value } });
    }
    const handleChangeEventChiefGuest = (event) => {
        event.preventDefault();
        seteventPublished(false)
        seteventData(prevState => {
            return {
                ...prevState,
                eventChiefGuests: {
                    ...prevState.eventChiefGuests,
                    [event.target.name]: event.target.value
                }
            }
        });
    }
    const handleClickEventCrowd = (e) => {
        e.preventDefault();
        seteventPublished(false)
        seteventData(prevState => { return { ...prevState, eventCrowd: e.target.value } });
        setshowError(prevState => { return { ...prevState, eventCrowd: { error: false, helperText: '' } } })
    }
    const handleChangeEventType = (event) => {
        event.preventDefault();
        seteventPublished(false)
        seteventData(prevState => { return { ...prevState, eventType: event.target.value } });
        setshowError(prevState => { return { ...prevState, eventType: { error: false, helperText: '' } } })
    }

    // const MuiClasses = useStyles();

    const [eventPublished, seteventPublished] = useState(false)

    // const [btnLoading, setbtnLoading] = useState(false)
    const [btnLoading, setbtnLoading] = useState(false)
    const handleClickPublishEvent = (e) => {
        e.preventDefault();
        if (eventData.eventName && eventData.eventDate && eventData.eventCrowd) {
            setbtnLoading(true)
            const abc = []
            if (eventData.eventChiefGuests.chiefGuest1) { abc.push(eventData.eventChiefGuests.chiefGuest1) }
            if (eventData.eventChiefGuests.chiefGuest2) { abc.push(eventData.eventChiefGuests.chiefGuest2) }
            if (eventData.eventChiefGuests.chiefGuest3) { abc.push(eventData.eventChiefGuests.chiefGuest3) }
            if (eventData.eventChiefGuests.chiefGuest4) { abc.push(eventData.eventChiefGuests.chiefGuest4) }

            addDoc(collection(dB, "SCHOOLS", userData.schoolId, userData.userPathCollection, "SCHOOL_EVENTS", "ALL_EVENTS"), {
                EVENT_NAME: eventData.eventName,
                EVENT_DATE: eventData.eventDate,
                EVENT_TYPE: eventData.eventType,
                EVENT_DESCRIPTION: eventData.eventDescription,
                EVENT_CROWD: eventData.eventCrowd,
                EVENT_CHIEF_GUESTS: abc,
                TIMESTAMP: serverTimestamp(),
                VIEWED_BY: []
            }).then(() => {
                setbtnLoading(false)
                seteventPublished(true)
                seteventData(prev => {
                    return {
                        ...prev,
                        eventName: '', eventDate: '', eventDescription: '', eventCrowd: '', eventType: '',
                        eventChiefGuests: {
                            chiefGuest1: '', chiefGuest2: '', chiefGuest3: '', chiefGuest4: '',
                        }
                    }
                })
            }).catch(err => alert(err.message))

        } else {
            if (!eventData.eventName) {
                setshowError(prev => { return { ...prev, eventName: { error: true, helperText: 'Please enter the name of the event' }, } })
            }
            if (!eventData.eventType) {
                setshowError(prev => { return { ...prev, eventType: { error: true, helperText: 'Please select type of the event' }, } })
            }
            if (!eventData.eventDate) {
                setshowError(prev => { return { ...prev, eventDate: { helperText: 'Please select the date for the event' }, } })
            }
            if (!eventData.eventCrowd) {
                setshowError(prev => { return { ...prev, eventCrowd: { helperText: 'Please select one' }, } })
            }
        }
    }

    const [allEvents, setallEvents] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.schoolId && userData.userPathCollection) {
                onSnapshot(query(collection(dB, "SCHOOLS", userData.schoolId, userData.userPathCollection, "SCHOOL_EVENTS", "ALL_EVENTS"), orderBy('TIMESTAMP', 'desc')), (snap) => {
                    setallEvents(snap.docs.map((evDoc) => ({
                        eventId: evDoc.id,
                        eventData: evDoc.data(),
                    })))
                })
            }
        }
        return () => { aaa = false }
    }, [userData.schoolId, userData.userPathCollection])

    return (
        <div className='SchoolUpcomingEvents'>
            <Header />
            <div className='SidebarNoticeBoard__Container'>
                <div className='SidebarNoticeBoard__ContainerTop'>
                    <h3>{userData.schoolFullName}</h3>
                    <h2>School Events</h2>
                    <hr />
                </div>
                <div className='SidebarNoticeBoard__ContainerBottom'>

                    <div className='SidebarNoticeBoard__ContainerBottomLeft'>
                        <nav className='SidebarNoticeBoard__containerLeft'>
                            <div key='CreateNewNotice' className={`SidebarNoticeBoard__containerLeft__title ${activeTab.createEvent}`} onClick={handleClickCreateNewNotices}>
                                <AddBoxIcon />
                                <h2>Create New Event</h2>
                            </div>
                            <div key='sentEventsLeft' className={`SidebarNoticeBoard__containerLeft__title ${activeTab.sentEvent}`} onClick={handleClickAlreadyPublicNotices}>
                                <PresentToAllIcon />
                                <h2>Published Events</h2>
                            </div>
                        </nav>
                    </div>

                    <div className='SidebarNoticeBoard__ContainerBottomRight'>
                        {
                            activeTab.createEvent === 'active' && (
                                <div >
                                    {
                                        allEvents.length === 20 ? (
                                            <div className='limitEvents'>
                                                <h4>You have exhausted your limit of events. You can store Max 20 events at a time. Delete few events to add new one.</h4>
                                            </div>
                                        ) : (
                                            <div className='createEventBlock' >
                                                <h3>Create a New Event</h3>
                                                <div className='createEvent__Name'>
                                                    <h3>Event Name : </h3>
                                                    <FormControl error={showError.eventName.error} name='eventName' sx={{ width: '700px' }} variant="outlined">
                                                        <OutlinedInput
                                                            name='eventName'
                                                            id="eventName"
                                                            type='text'
                                                            value={eventData.eventName}
                                                            onChange={handleChangeEventName}

                                                            placeholder='Name of the Event'
                                                        />
                                                        <FormHelperText id="eventName"><p>{showError.eventName.helperText}</p></FormHelperText>
                                                    </FormControl>
                                                    <h5>For Example: Independence Day Celebration, Gandhi Jayanti, School Gathering, Teachers Day etc.</h5>
                                                </div>

                                                <div className='createEvent__Type'>
                                                    <h3>Event Type : </h3>
                                                    <FormControl sx={{ miWidth: 120, width: '300px', m: 1, marginBottom: '20px' }} error={showError.eventType.error}>
                                                        <Select
                                                            labelId="eventType"
                                                            id="eventType"
                                                            value={eventData.eventType}

                                                            onChange={handleChangeEventType}
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            <MenuItem value={'Cultural'}>Cultural</MenuItem>
                                                            <MenuItem value={'Sports'}>Sports</MenuItem>
                                                            <MenuItem value={'Competition'}>Competition</MenuItem>
                                                            <MenuItem value={'Conference'}>Conference</MenuItem>
                                                            <MenuItem value={'Seminar'}>Seminar</MenuItem>
                                                            <MenuItem value={'Training'}>Training</MenuItem>
                                                            <MenuItem value={'Workshop'}>Workshop</MenuItem>
                                                        </Select>
                                                        <FormHelperText>{showError.eventName.helperText}</FormHelperText>
                                                    </FormControl>
                                                </div>


                                                {/* event date */}
                                                <div className='createEvent__Date' onChange={handleChangeEventDate}>
                                                    <h5>Event Date : </h5>
                                                    <input type='date' id="eventDate" name="eventDate" />
                                                    <h4>{showError.eventDate.helperText}</h4>
                                                </div>


                                                {/* event description */}
                                                <div className='createEvent__Description'>
                                                    <h4>Event Description :</h4>
                                                    <TextField
                                                        // className={MuiClasses.eventDescription}
                                                        sx={{ width: '700px', marginBottom: '20px' }}
                                                        name='eventDescription'
                                                        value={eventData.eventDescription}
                                                        onChange={handleChangeEventDescription}
                                                        id="eventDescription"
                                                        type='text'
                                                        placeholder='Describe your event here..'
                                                        multiline
                                                        minRows={3}
                                                        maxRows={8}
                                                        variant="outlined"
                                                    />
                                                </div>


                                                {/* event chief guests */}
                                                <div className='createEvent__ChiefGuests'>
                                                    <h4>Chief Guests :</h4>
                                                    <div className='createEvent__ChiefGuestsList'>
                                                        <div className='createEvent__ChiefGuestsListRow'>
                                                            <div className='ChiefGuest'>
                                                                <p>1. </p>
                                                                <FormControl name='chiefGuest1' sx={{ width: '300px', marginRight: '10px' }} variant="outlined">
                                                                    <OutlinedInput
                                                                        name='chiefGuest1'
                                                                        id="chiefGuest1"
                                                                        type='text'
                                                                        value={eventData.eventChiefGuests.chiefGuest1}
                                                                        onChange={handleChangeEventChiefGuest}
                                                                        placeholder='(Optional)'
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                            <div className='ChiefGuest'>
                                                                <p>2. </p>
                                                                <FormControl name='chiefGuest2' sx={{ width: '300px', marginRight: '10px' }} variant="outlined">
                                                                    <OutlinedInput
                                                                        name='chiefGuest2'
                                                                        id="chiefGuest2"
                                                                        type='text'
                                                                        value={eventData.eventChiefGuests.chiefGuest2}
                                                                        onChange={handleChangeEventChiefGuest}
                                                                        placeholder='(Optional)'
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className='createEvent__ChiefGuestsListRow'>
                                                            <div className='ChiefGuest'>
                                                                <p>3. </p>
                                                                <FormControl name='chiefGuest3' sx={{ width: '300px', marginRight: '10px' }} variant="outlined">
                                                                    <OutlinedInput
                                                                        name='chiefGuest3'
                                                                        id="chiefGuest3"
                                                                        type='text'
                                                                        value={eventData.eventChiefGuests.chiefGuest3}
                                                                        onChange={handleChangeEventChiefGuest}
                                                                        placeholder='(Optional)'
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                            <div className='ChiefGuest'>
                                                                <p>4. </p>
                                                                <FormControl name='chiefGuest4' sx={{ width: '300px', marginRight: '10px' }} variant="outlined">
                                                                    <OutlinedInput
                                                                        name='chiefGuest4'
                                                                        id="chiefGuest4"
                                                                        type='text'
                                                                        value={eventData.eventChiefGuests.chiefGuest4}
                                                                        onChange={handleChangeEventChiefGuest}
                                                                        placeholder='(Optional)'
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='createEvent__Crowd'>
                                                    <h4>Event Status</h4>
                                                    <FormControl error={showError.eventCrowd.error} component="fieldset">
                                                        <div className='createnewaccount__gendersdiv'>
                                                            <RadioGroup aria-label="eventCrowd" name="eventCrowd" value={eventData.eventCrowd} onChange={handleClickEventCrowd}>
                                                                <div className=''>
                                                                    <FormControlLabel value="Private (Outsiders are not allowed)" control={<Radio />} label="Private (Outsiders are not allowed)" />
                                                                    <FormControlLabel value="Public (Outsiders are allowed)" control={<Radio />} label="Public (Outsiders are allowed)" />
                                                                </div>
                                                            </RadioGroup>
                                                        </div>
                                                    </FormControl>
                                                    <FormHelperText id="eventName"><p>{showError.eventCrowd.helperText}</p></FormHelperText>
                                                </div>

                                                <div className='buttonPublishEvent'>
                                                    <LoadingButton
                                                        onClick={handleClickPublishEvent}
                                                        loading={btnLoading}
                                                        variant="outlined"
                                                    >
                                                        Publish
                                                    </LoadingButton>
                                                    {/* <Button  ></Button> */}
                                                    {eventPublished && (<h3>Event published successfully</h3>)}

                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                            )
                        }

                        {
                            activeTab.sentEvent === 'active' && (
                                <div className='sentEvent'>
                                    <h3>Published Events</h3>
                                    {
                                        allEvents.length === 20 && (
                                            <div className='limitEvents'>
                                                <h4>You have exhausted your limit of events. You can store Max 20 events at a time. Delete few events to add new one.</h4>
                                            </div>
                                        )
                                    }
                                    {
                                        allEvents.length > 0 ? (
                                            allEvents.map((data, i) => (
                                                <SchoolUpcomingEventData
                                                    key={i}
                                                    eventId={data?.eventId}
                                                    eventName={data?.eventData?.EVENT_NAME}
                                                    eventDate={data?.eventData?.EVENT_DATE}
                                                    eventDescription={data?.eventData?.EVENT_DESCRIPTION}
                                                    eventCrowd={data?.eventData?.EVENT_CROWD}
                                                    eventType={data?.eventData?.EVENT_TYPE}
                                                    uploadedOn={data?.eventData?.TIMESTAMP}
                                                    chiefGuests={data?.eventData?.EVENT_CHIEF_GUESTS}
                                                    SchoolFriendProfile={false}
                                                    userSchoolId={userData.schoolId}
                                                    userSchoolPathCollection={userData.userPathCollection}
                                                />
                                            ))
                                        ) : (
                                            <div className='noEvent'>
                                                <h4>No event has been published yet.</h4>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }

                    </div>
                </div>
                <hr />
            </div>
        </div>
    )
}

export default SchoolUpcomingEvents
