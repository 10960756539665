import React, { createContext, useEffect, useState } from 'react'
import '../Social/HomePageFeed.css'
import FollowingSchoolsPosts from './FollowingSchoolsPosts'
import HomePagePosts from './HomePagePosts'
import HomePagePostSender from './HomePagePostSender'
import FollowingUsersPosts from './FollowingUsersPosts'
import { collection, doc, getDocs, limit, onSnapshot, orderBy, query, startAfter } from 'firebase/firestore'

import { Button, Typography } from '@mui/material'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import dB from '../../firebase'



export const FrnzPostsContext = createContext();
export const SchlzPostsContext = createContext();

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0.5}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function HomePageFeed(props) {

    const { userId, userFullName, userPathCollection, userCurrentStatus, friendId,
        friendFullName, friendPathCollection, friendCurrentStatus, userDP, friendDP, MainHomePage } = props

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => { setValue(newValue); };

    const [followingSchools, setfollowingSchools] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection && userCurrentStatus !== null && userCurrentStatus !== undefined) {
                if (userCurrentStatus === 'SCHOOL') {
                    onSnapshot(collection(dB, "SCHOOLS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_SCHOOLS"), (snap) => {
                        setfollowingSchools(snap.docs.map((schlDoc) => schlDoc.id))
                    })
                } else {
                    onSnapshot(collection(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_SCHOOLS"), (snap) => {
                        setfollowingSchools(snap.docs.map((userDoc) => userDoc.id))
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [userCurrentStatus, userId, userPathCollection])


    const [followingSchlPosts, setfollowingSchlPosts] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (followingSchools?.length > 0) {
                followingSchools?.forEach(school => {
                    onSnapshot(doc(dB, "SCHOOLS", school), (snap) => {
                        if (snap.exists()) {
                            onSnapshot(query(collection(dB, "SCHOOLS", school, snap.data()?.SCHOOL_NAME, "POSTS", "ALL_POSTS"), orderBy('POST_TIMESTAMP', 'desc'), limit(7)), (allPosts) => {
                                let posts = allPosts.docs.map((snapshot) => ({
                                    postId: snapshot.id,
                                    postData: snapshot.data(),
                                    postSchoolId: school,
                                    postSchoolPathCollection: snap.data()?.SCHOOL_NAME,
                                    postSchoolFullName: snap.data()?.SCHOOL_FULL_NAME,
                                    postSchoolDP: snap.data()?.USER_DISPLAY_PIC,
                                    postSchoolStatus: snap.data()?.USER_STATUS,
                                }))
                                setfollowingSchlPosts(prev => { return [...prev, ...posts] })
                            })
                        }
                    })
                })
            }
        }
        return () => { aaa = false }
    }, [followingSchools])



    const [followingUsers, setfollowingUsers] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection && userCurrentStatus !== null && userCurrentStatus !== undefined) {
                if (userCurrentStatus === 'SCHOOL') {
                    onSnapshot(collection(dB, "SCHOOLS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS"), (snap) => {
                        setfollowingUsers(snap.docs.map((sclDoc) => sclDoc.id))
                    })
                } else {
                    onSnapshot(collection(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS"), (snap) => {
                        setfollowingUsers(snap.docs.map((userDoc) => userDoc.id))
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [userCurrentStatus, userId, userPathCollection])


    const [followingUserPosts, setfollowingUserPosts] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (followingUsers?.length) {
                followingUsers?.forEach(user => {
                    onSnapshot(doc(dB, "USERS", user), (snap) => {
                        if (snap.exists()) {
                            onSnapshot(query(collection(dB, "USERS", user, snap.data()?.USER_NAME, "POSTS", "ALL_POSTS"), orderBy('POST_TIMESTAMP', 'desc'), limit(3)), (allPostsDoc) => {
                                let posts = allPostsDoc.docs.map((document) => ({
                                    postId: document.id,
                                    postData: document.data(),
                                    postUserId: user,
                                    postUserPathCollection: snap.data()?.USER_NAME,
                                    postUserFullName: snap.data()?.USER_NAME,
                                    postUserDP: snap.data()?.USER_DISPLAY_PIC,
                                    postUserCurrentStatus: snap.data()?.USER_CURRENT_STATUS,
                                }))
                                setfollowingUserPosts(prev => { return [...prev, ...posts] })
                            })
                        }
                    })
                })
            }
        }
        return () => { aaa = false }
    }, [followingUsers])


    const [userPosts, setuserPosts] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (!friendId) {
                if (userId && userPathCollection && userCurrentStatus !== null && userCurrentStatus !== undefined) {
                    if (userCurrentStatus === 'SCHOOL') {
                        onSnapshot(query(collection(dB, "SCHOOLS", userId, userPathCollection, "POSTS", "ALL_POSTS"), orderBy('POST_TIMESTAMP', 'desc'), limit(10)), (snap) => {
                            fetchPosts(snap)
                        })
                    } else {
                        onSnapshot(query(collection(dB, "USERS", userId, userPathCollection, "POSTS", "ALL_POSTS"), orderBy('POST_TIMESTAMP', 'desc'), limit(10)), (snap) => {
                            fetchPosts(snap)
                        })
                    }
                }
            } else {
                if (friendId && friendPathCollection && friendCurrentStatus) {
                    if (friendCurrentStatus === 'SCHOOL') {
                        onSnapshot(query(collection(dB, "SCHOOLS", friendId, friendPathCollection, "POSTS", "ALL_POSTS"), orderBy('POST_TIMESTAMP', 'desc'), limit(10)), (snap) => {
                            fetchPosts(snap)
                        })
                    } else {
                        onSnapshot(query(collection(dB, "USERS", friendId, friendPathCollection, "POSTS", "ALL_POSTS"), orderBy('POST_TIMESTAMP', 'desc'), limit(10)), (snap) => {
                            fetchPosts(snap)
                        })
                    }
                }
            }
        }
        return () => { aaa = false }
    }, [friendCurrentStatus, friendId, friendPathCollection, userCurrentStatus, userId, userPathCollection])
    const fetchPosts = (snap) => {
        let posts = snap.docs.map((postDoc) => ({
            postId: postDoc.id,
            postData: postDoc.data()
        }))
        setuserPosts(prev => { return [...prev, ...posts] })

        var lastVisiblePost = snap.docs[snap.docs.length - 1];
        setlastVisible(lastVisiblePost)
    }
    const [lastVisible, setlastVisible] = useState()
    const [totalPosts, settotalPosts] = useState()
    const loadMorePosts = async (postsDataLength) => {
        settotalPosts(postsDataLength)
        if (!friendId) {
            if (userId && userPathCollection && userCurrentStatus !== null && userCurrentStatus !== undefined) {
                if (userCurrentStatus === 'SCHOOL') {
                    const q = query(collection(dB, "SCHOOLS", userId, userPathCollection, "POSTS", "ALL_POSTS"), orderBy('POST_TIMESTAMP', 'desc'), startAfter(lastVisible), limit(10))
                    const snap = await getDocs(q).catch(err => alert(err.message))
                    await fetchPosts(snap)
                } else {
                    const q = query(collection(dB, "USERS", userId, userPathCollection, "POSTS", "ALL_POSTS"), orderBy('POST_TIMESTAMP', 'desc'), startAfter(lastVisible), limit(10))
                    const snap = await getDocs(q).catch(err => alert(err.message))
                    await fetchPosts(snap)
                }
            }
        } else {
            if (friendId && friendPathCollection && friendCurrentStatus) {
                if (friendCurrentStatus === 'SCHOOL') {
                    const q = query(collection(dB, "SCHOOLS", friendId, friendPathCollection, "POSTS", "ALL_POSTS"), orderBy('POST_TIMESTAMP', 'desc'), startAfter(lastVisible), limit(10))
                    const snap = await getDocs(q).catch(err => alert(err.message))
                    await fetchPosts(snap)
                } else {
                    const q = query(collection(dB, "USERS", friendId, friendPathCollection, "POSTS", "ALL_POSTS"), orderBy('POST_TIMESTAMP', 'desc'), startAfter(lastVisible), limit(10))
                    const snap = await getDocs(q).catch(err => alert(err.message))
                    await fetchPosts(snap)
                }
            }
        }
    }



    return (
        <div className='feed'>
            {
                !MainHomePage && (
                    <>
                        {
                            friendId === null && (
                                <HomePagePostSender
                                    userId={userId}
                                    userPathCollection={userPathCollection}
                                    userCurrentStatus={userCurrentStatus}
                                    userDP={userDP}
                                />
                            )
                        }
                    </>
                )
            }

            {
                MainHomePage ? (
                    <>
                        <div className='BoxTabsHomePage'>
                            <Box className='tabPanelHomePageFeed' sx={{ width: '100%', bgcolor: 'background.paper', }}>
                                <Tabs value={value} onChange={handleChange} centered>
                                    <Tab label="Schools Posts" sx={{ fontSize: 15, fontWeight: '600' }} />
                                    <Tab label="Friends Posts" sx={{ fontSize: 15, fontWeight: '600' }} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <SchlzPostsContext.Provider value={followingSchlPosts}>
                                    <FollowingSchoolsPosts
                                        MainHomePage={MainHomePage}
                                        userFullName={userFullName}
                                        userId={userId}
                                        userPathCollection={userPathCollection}
                                        userCurrentStatus={userCurrentStatus}
                                        userDP={userDP}
                                        friendId={friendId}
                                        friendPathCollection={friendPathCollection}
                                        friendCurrentStatus={friendCurrentStatus}
                                        friendFullName={friendFullName}
                                        friendDP={friendDP}
                                    />
                                </SchlzPostsContext.Provider>

                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <FrnzPostsContext.Provider value={followingUserPosts}>
                                    <FollowingUsersPosts
                                        userFullName={userFullName}
                                        userId={userId}
                                        userPathCollection={userPathCollection}
                                        userCurrentStatus={userCurrentStatus}
                                        userDP={userDP}
                                        friendId={friendId}
                                        friendPathCollection={friendPathCollection}
                                        friendCurrentStatus={friendCurrentStatus}
                                        friendFullName={friendFullName}
                                        friendDP={friendDP}
                                    />
                                </FrnzPostsContext.Provider>


                            </TabPanel>
                        </div>
                    </>
                ) : (
                    <>
                        {
                            userPosts?.length > 0 ? (
                                <>
                                    {
                                        userPosts.map((poData, i) => (
                                            <HomePagePosts
                                                key={i}
                                                userName={userFullName}
                                                timeStamp={poData.postData?.POST_TIMESTAMP}
                                                postText={poData.postData?.POST_TEXT}
                                                postImage={poData.postData?.POST_IMAGE}
                                                postId={poData.postId}
                                                userId={userId}
                                                userPathCollection={userPathCollection}
                                                userCurrentStatus={userCurrentStatus}
                                                userDP={userDP}
                                                friendId={friendId}
                                                friendPathCollection={friendPathCollection}
                                                friendCurrentStatus={friendCurrentStatus}
                                                friendFullName={friendFullName}
                                                friendDP={friendDP}
                                            />
                                        ))
                                    }
                                    {
                                        userPosts?.length > 8 ? (
                                            <>
                                                {
                                                    totalPosts !== userPosts?.length ? (
                                                        <div className='showMoreBtn'>
                                                            <Button color="primary" onClick={() => loadMorePosts(userPosts?.length)}>Show More Posts..</Button>
                                                        </div>
                                                    ) : (
                                                        <div className='noMorePosts'>
                                                            <h4 >No more posts to show</h4>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        ) : (<></>)
                                    }
                                </>
                            ) : (
                                <div className='notCreatedPost'>
                                    <h3>No posts to show. Posts created by you will appear here.</h3>
                                </div>
                            )
                        }
                    </>
                )
            }





        </div>
    )
}

export default HomePageFeed