import React, { useEffect, useState } from 'react'
import Header from '../Components/Header/Header'
import MessageBody from '../Components/Header/MessageBody';
import Login from './Login';

import '../Pages/AllMessages.css'
import '../Pages/FindToFollow.css'
// import dB, { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

import { collection, doc, getDocs, limit, onSnapshot, orderBy, query, startAfter } from 'firebase/firestore';
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import PropTypes from 'prop-types';
import dB, { auth } from '../firebase';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function AllMessages() {

    const [userData, setuserData] = useState({ userId: '', userCurrentStatus: '', userPathCollection: '', userFullName: '' })
    useEffect(() => {
        const unSubscribe = onAuthStateChanged(auth, (userCredentials) => {
            if (userCredentials) {
                onSnapshot(doc(dB, "USERS", userCredentials.uid), (userDoc) => {
                    if (userDoc.exists()) {
                        setuserData(prev => {
                            return {
                                ...prev,
                                userId: userCredentials.uid,
                                userCurrentStatus: userDoc.data()?.USER_CURRENT_STATUS,
                                userPathCollection: userDoc.data()?.USER_NAME,
                            }
                        })
                    } else {
                        onSnapshot(doc(dB, "SCHOOLS", userCredentials.uid), (schlDoc) => {
                            if (schlDoc.exists()) {
                                setuserData(prev => {
                                    return {
                                        ...prev,
                                        userId: userCredentials.uid,
                                        userCurrentStatus: 'SCHOOL',
                                        userPathCollection: schlDoc.data()?.SCHOOL_NAME,
                                        userFullName: schlDoc.data()?.SCHOOL_FULL_NAME,
                                    }
                                })
                            }
                        })
                    }
                });
            }
        });
        return () => { unSubscribe(); }
    }, [])
    useEffect(() => {
        if (userData.userCurrentStatus !== 'SCHOOL') {
            if (userData.userId && userData.userPathCollection) {
                onSnapshot(doc(dB, "USERS", userData.userId), (userDoc) => {
                    if (userDoc.exists()) {
                        setuserData(prevState => {
                            return {
                                ...prevState,
                                userFullName: userDoc.data()?.USER_NAME,
                            }
                        })
                    }
                })
            }
        }
    }, [userData.userCurrentStatus, userData.userId, userData.userPathCollection])

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => { event.preventDefault(); setValue(newValue); };


    const [btndisabledSent, setbtndisabledSent] = useState(false)
    const [sentMessagesAll, setsentMessagesAll] = useState([]);
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userId && userData.userPathCollection && userData.userCurrentStatus) {
                if (userData.userCurrentStatus === 'SCHOOL') {
                    onSnapshot(query(collection(dB, "SCHOOLS", userData.userId, userData.userPathCollection, "MESSAGES_SENT", "MESSAGES"), orderBy('MSG_TIMESTAMP', 'desc'), limit(8)), (snap) => {
                        fetchMoreSent(snap)
                    })
                } else {
                    onSnapshot(query(collection(dB, "USERS", userData.userId, userData.userPathCollection, "MESSAGES_SENT", "MESSAGES"), orderBy('MSG_TIMESTAMP', 'desc'), limit(8)), (snap) => {
                        fetchMoreSent(snap)
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [userData.userCurrentStatus, userData.userId, userData.userPathCollection])

    const [lastVisibleSent, setlastVisibleSent] = useState()
    const fetchMoreSent = (snap) => {
        let sentMsgAll = snap.docs.map((sentDoc) => ({
            sentMsgId: sentDoc.id,
            sentMsgData: sentDoc.data(),
        }))
        setsentMessagesAll(prev => { return [...prev, ...sentMsgAll] })
        var lastVisible = snap.docs[snap.docs.length - 1];
        setlastVisibleSent(lastVisible)
    }
    const [totalSentMsgs, settotalSentMsgs] = useState()
    const loadMoreSent = async (sentMessagesAllLength) => {
        settotalSentMsgs(sentMessagesAllLength)
        setbtndisabledSent(true)
        if (userData.userCurrentStatus === 'SCHOOL') {
            const q = query(collection(dB, "SCHOOLS", userData.userId, userData.userPathCollection, "MESSAGES_SENT", "MESSAGES"), orderBy('MSG_TIMESTAMP', 'desc'), startAfter(lastVisibleSent), limit(6))
            const snap = await getDocs(q).catch(err => alert(err.message))
            await fetchMoreSent(snap)
            setbtndisabledSent(false)
        } else {
            const q = query(collection(dB, "USERS", userData.userId, userData.userPathCollection, "MESSAGES_SENT", "MESSAGES"), orderBy('MSG_TIMESTAMP', 'desc'), startAfter(lastVisibleSent), limit(6))
            const snap = await getDocs(q).catch(err => alert(err.message))
            await fetchMoreSent(snap)
            setbtndisabledSent(false)
        }
    }


    const [btndisabledRecieved, setbtndisabledRecieved] = useState(false)
    const [recivedMessagesAll, setrecivedMessagesAll] = useState([]);
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userId && userData.userPathCollection && userData.userCurrentStatus) {
                if (userData.userCurrentStatus === 'SCHOOL') {
                    onSnapshot(query(collection(dB, "SCHOOLS", userData.userId, userData.userPathCollection, "MESSAGES_RECIEVED", "MESSAGES"), orderBy('MSG_TIMESTAMP', 'desc'), limit(8)), (snap) => {
                        fetchMoreRecieved(snap)
                    })
                } else {
                    onSnapshot(query(collection(dB, "USERS", userData.userId, userData.userPathCollection, "MESSAGES_RECIEVED", "MESSAGES"), orderBy('MSG_TIMESTAMP', 'desc'), limit(8)), (snap) => {
                        fetchMoreRecieved(snap)
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [userData.userCurrentStatus, userData.userId, userData.userPathCollection])
    const [lastVisibleRecieved, setlastVisibleRecieved] = useState()
    const fetchMoreRecieved = (snap) => {
        let recievedMsgAll = snap.docs.map((recDoc) => ({
            recievedMsgId: recDoc.id,
            recievedMsgData: recDoc.data(),
        }))
        setrecivedMessagesAll(prev => { return [...prev, ...recievedMsgAll] })
        var lastVisible = snap.docs[snap.docs.length - 1];
        setlastVisibleRecieved(lastVisible)
    }
    const [totalRecievedMsgs, settotalRecievedMsgs] = useState()
    const loadMoreRecieved = async (recivedMessagesAllLength) => {
        settotalRecievedMsgs(recivedMessagesAllLength)
        setbtndisabledRecieved(true)
        if (userData.userCurrentStatus === 'SCHOOL') {
            const q = query(collection(dB, "SCHOOLS", userData.userId, userData.userPathCollection, "MESSAGES_RECIEVED", "MESSAGES"), orderBy('MSG_TIMESTAMP', 'desc'), startAfter(lastVisibleRecieved), limit(6))
            const snap = await getDocs(q).catch(err => alert(err.message))
            await fetchMoreRecieved(snap)
            setbtndisabledRecieved(false)
        } else {
            const q = query(collection(dB, "USERS", userData.userId, userData.userPathCollection, "MESSAGES_RECIEVED", "MESSAGES"), orderBy('MSG_TIMESTAMP', 'desc'), startAfter(lastVisibleRecieved), limit(6))
            const snap = await getDocs(q).catch(err => alert(err.message))
            await fetchMoreRecieved(snap)
            setbtndisabledRecieved(false)
        }
    }

    return (
        <>
            {
                userData.userId === '' ? (
                    <Login />
                ) : (
                    <div className='allMessages'>
                        <Header />
                        <div className='allMessagesBlock'>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ width: '100%', bgcolor: 'background.paper', borderBottom: 0, color: 'divider' }}>
                                    <Tabs value={value} onChange={handleChange} centered>
                                        <Tab sx={{ width: '220px', fontSize: '15px', fontWeight: '600', color: 'rgb(153, 152, 152)', }} label={`Sent Messages`} />
                                        <Tab sx={{ width: '220px', fontSize: '15px', fontWeight: '600', color: 'rgb(153, 152, 152)' }} label={`Recieved Messages`} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    {
                                        sentMessagesAll?.length > 0 ? (
                                            <>
                                                <div>
                                                    {
                                                        sentMessagesAll.map((msg, i) => (
                                                            <MessageBody
                                                                key={i}
                                                                msgRecieverSenderStatus={msg?.sentMsgData?.MSG_RECEIVER_STATUS}
                                                                msgId={msg?.sentMsgId}
                                                                msgText={msg?.sentMsgData?.MSG_TEXT}
                                                                msgTimestamp={msg?.sentMsgData?.MSG_TIMESTAMP}

                                                                userId={userData.userId}
                                                                userPathCollection={userData.userPathCollection}
                                                                userCurrentStatus={userData.userCurrentStatus}
                                                                userFullName={userData.userFullName}

                                                                msgFrom={false}
                                                                msgTo={true}
                                                                msgToFromId={msg?.sentMsgData?.MSG_TO}
                                                            />
                                                        ))
                                                    }
                                                </div>
                                                {
                                                    sentMessagesAll?.length > 7 && (
                                                        <>
                                                            {
                                                                totalSentMsgs !== sentMessagesAll?.length ? (
                                                                    <div className='ButtonSeeMore'>
                                                                        <LoadingButton
                                                                            onClick={(e) => { e.preventDefault(); loadMoreSent(sentMessagesAll?.length) }}
                                                                            disabled={btndisabledSent}
                                                                        >
                                                                            show more..
                                                                        </LoadingButton>
                                                                        {/* <Button ></Button> */}
                                                                    </div>
                                                                ) : (
                                                                    <div className='noMoreSchools'>
                                                                        <h4>No more sent messages to show...</h4>
                                                                    </div>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }

                                            </>
                                        ) : (
                                            <div className='noMessagesToShow'>
                                                <h2>No messages to show</h2>
                                            </div>
                                        )
                                    }
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    {
                                        recivedMessagesAll?.length > 0 ? (
                                            <>
                                                <div>
                                                    {
                                                        recivedMessagesAll?.map((msg, i) => (
                                                            <MessageBody
                                                                key={i}
                                                                msgRecieverSenderStatus={msg?.recievedMsgData?.MSG_SENDER_STATUS}
                                                                msgId={msg?.recievedMsgId}
                                                                msgText={msg?.recievedMsgData?.MSG_TEXT}
                                                                msgTimestamp={msg?.recievedMsgData?.MSG_TIMESTAMP}
                                                                isViewed={msg?.recievedMsgData?.IS_VIEWED}

                                                                userId={userData.userId}
                                                                userPathCollection={userData.userPathCollection}
                                                                userCurrentStatus={userData.userCurrentStatus}
                                                                userFullName={userData.userFullName}
                                                                msgFrom={true}
                                                                msgTo={false}
                                                                msgToFromId={msg?.recievedMsgData?.MSG_FROM}
                                                            />
                                                        ))
                                                    }
                                                </div>
                                                {
                                                    recivedMessagesAll?.length > 7 && (
                                                        <>
                                                            {
                                                                totalRecievedMsgs !== recivedMessagesAll?.length ? (
                                                                    <div className='ButtonSeeMore'>
                                                                        <LoadingButton
                                                                            onClick={(e) => { e.preventDefault(); loadMoreRecieved(recivedMessagesAll?.length) }}
                                                                            disabled={btndisabledRecieved}
                                                                        >
                                                                            show more..
                                                                        </LoadingButton>
                                                                    </div>
                                                                ) : (
                                                                    <div className='noMoreSchools'>
                                                                        <h4>No more recieved messages to show...</h4>
                                                                    </div>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }

                                            </>
                                        ) : (
                                            <div className='noMessagesToShow'>
                                                <h2>No messages to show</h2>
                                            </div>
                                        )
                                    }
                                </TabPanel>
                            </Box>
                        </div>
                    </div>
                )
            }
        </>

    )
}

export default AllMessages
