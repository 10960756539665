import React, { useEffect, useState } from 'react'
import '../Sidebars/SidebarESchoolingStatusShowStudent.css'
import { useHistory } from 'react-router-dom';
import { doc, onSnapshot, writeBatch } from 'firebase/firestore';
import { Box, Button, Modal } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function SidebarESchoolingStatusShowStudent(props) {
    const { userId, userPathCollection, userSchoolFullName, userSchoolId, userStatuses,
        userSchoolPathCollection, userCurrentStatus } = props

    const history = useHistory()

    var batch = writeBatch(dB)

    const [btnLoading, setbtnLoading] = useState(false)

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = (e) => { setOpenModal(true); };
    const handleCloseModal = (e) => { setOpenModal(false); };


    const [studentVerf, setstudentVerf] = useState(null)
    const [studentDataInfo, setstudentDataInfo] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_STUDENT", "IS_STUDENT_DATA"), (snap) => {
                    if (snap.exists()) {
                        setstudentDataInfo(snap.data())
                    }
                })
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_STUDENT", "VERIFICATION_DETAILS"), (snap) => {
                    if (snap.exists()) {
                        setstudentVerf(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const [studMedClsSection, setstudMedClsSection] = useState('')
    useEffect(() => {
        setstudMedClsSection('')
        var aaa = true
        if (aaa === true) {
            if (studentDataInfo?.MEDIUM && studentDataInfo?.STUDENTS_CLASS && studentDataInfo?.STUDENTS_SECTION) {
                var abc = returnMedClsSec(studentDataInfo?.MEDIUM, studentDataInfo?.STUDENTS_CLASS, studentDataInfo?.STUDENTS_SECTION)
                setstudMedClsSection(abc)
            }
        }
        return () => { aaa = false }
    }, [studentDataInfo?.STUDENTS_CLASS, studentDataInfo?.MEDIUM, studentDataInfo?.STUDENTS_SECTION])

    const handleClickRemoveStudent = async () => {
        setbtnLoading(true)
        await batch.delete(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_STUDENT", "IS_STUDENT_DATA"))

        await batch.delete(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_STUDENT", "VERIFICATION_DETAILS"))

        if (userStatuses.length === 1) {
            await batch.update(doc(dB, "USERS", userId), {
                USER_CURRENT_STATUS: '',
            });
        } else {
            await batch.update(doc(dB, "USERS", userId), {
                USER_CURRENT_STATUS: userStatuses[0] === userCurrentStatus ? userStatuses[1] : userStatuses[0],
            });
        }

        if (studentVerf?.VERIFIED_STUDENT) {
            await batch.delete(doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", studMedClsSection, "STUDENTS", "VERIFIED_MEMBERS", "VERIFIED_MEMBERS_DATA", userId));
        }
        else {
            await batch.delete(doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", studMedClsSection, "STUDENTS", "VERIFICATION_REQUESTS", "VERIFICATION_REQUESTS_DATA", userId));
        }
        await batch.commit().then(() => {
            setbtnLoading(false)
            history.push('/user')
        }).catch(err => alert(err.message))
    }

    return (
        <div className='SidebarESchoolingStatusShowStudent'>
            <div className='rowData studentStatus'>
                <h2>User E-Schooling Status</h2>
                <h4>:</h4>
                <h3>"STUDENT"</h3>
            </div>
            <div className='rowData studentSchool'>
                <h2>School</h2>
                <h4>:</h4>
                <h3>{userSchoolFullName}</h3>
            </div>
            <div className='rowData studentMedium'>
                <h2>Medium</h2>
                <h4>:</h4>
                <h3>{studentDataInfo?.MEDIUM}</h3>
            </div>
            <div className='rowData studentClass'>
                <h2>Class</h2>
                <h4>:</h4>
                <h3>{studentDataInfo?.STUDENTS_CLASS}</h3>
            </div>
            <div className='rowData studentSection'>
                <h2>Section</h2>
                <h4>:</h4>
                <h3>{studentDataInfo?.STUDENTS_SECTION}</h3>
            </div>
            <div className='rowData studentSection'>
                <h2>Verification Status</h2>
                <h4>:</h4>
                <h3>{studentVerf?.VERIFIED_STUDENT ? 'Verified' : 'Not Verified'}</h3>
            </div>
            <div className='studentButton'>
                <Button onClick={handleOpenModal}>Remove</Button>
            </div>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='deletePostModal'>
                        <div className='deletePostModalText'>
                            <h4 style={{ textAlign: 'center' }}>Do you really want to remove yourself as a Student  ?</h4>
                        </div>
                        <div className='deletePostModalButtons'>
                            <LoadingButton
                                onClick={handleClickRemoveStudent}
                                variant='outlined'
                                loading={btnLoading}
                            >
                                Yes
                            </LoadingButton>
                            {/* <Button disabled={btnLoading} >Yes</Button> */}
                            <Button onClick={handleCloseModal}>No</Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default SidebarESchoolingStatusShowStudent
