import React from 'react'
import HomePageEducationSidebar from './HomePageEducationSidebar'
// import HomePageEducationSidebar from './HomePageEducationSidebar'

function HomePageSidebarJusAUser(props) {
    const { userCurrentStatus, userId, userPathCollection } = props
    return (
        <div>
            <HomePageEducationSidebar
                userCurrentStatus={userCurrentStatus}
            />
        </div>
    )
}

export default HomePageSidebarJusAUser
