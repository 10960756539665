import React, { useEffect, useState } from 'react'
import '../Sidebars/SidebarESchoolingStatusAddPrincipal.css'
import '../CreateNewAccount/CreateNewAccountInfoTeachers.css'
// import dB from '../firebase';
import { collection, doc, onSnapshot, serverTimestamp, writeBatch } from 'firebase/firestore';
import { useHistory } from 'react-router-dom';
import { Autocomplete, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';

function SidebarESchoolingStatusAddPrincipal(props) {
    const { userId, userPathCollection, userStatuses, princiFullName,
        teacherSchoolId, teacherSchoolPathCollection, teacherSchoolFullName,
    } = props

    const history = useHistory();

    const [showEnteredPrincipal, setshowEnteredPrincipal] = useState({
        schoolName: { error: false }, isPrincipal: { error: false }
    })
    const [principalEnteredData, setprincipalEnteredData] = useState({
        inputPrincipalSchoolName: null, principalInputValue: '', isPrincipal: 'No',
        princiOptionSchoolId: '', princiOptionSchoolShortName: '',
    })
    const [msgAndButton, setmsgAndButton] = useState({
        msgSent: false, showButton: false, disabledButton: false
    })

    const [principalOptions, setprincipalOptions] = useState([])
    useEffect(() => {
        if (principalOptions.length > 0) {
            principalOptions.splice(0, principalOptions.length)
        }
        var aaa = true
        if (aaa === true) {
            onSnapshot(collection(dB, "SCHOOLS"), (snap) => {
                snap.forEach((schlDoc) => {
                    const newdata = schlDoc.data()?.SCHOOL_FULL_NAME;
                    setprincipalOptions(prev => { return [...prev, { id: schlDoc.id, schoolName: newdata }] })
                });
            })
        }
        return () => { aaa = false }
    }, [])
    const [principalSchoolId, setprincipalSchoolId] = useState('')
    const [princiSchoolPathCollection, setprinciSchoolPathCollection] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (!teacherSchoolId) {
                if (principalEnteredData.inputPrincipalSchoolName) {
                    onSnapshot(collection(dB, "SCHOOLS"), (snap) => {
                        snap.forEach((schlDoc) => {
                            const newdata = schlDoc.data()?.SCHOOL_FULL_NAME;
                            if (newdata === principalEnteredData.inputPrincipalSchoolName) {
                                setprinciSchoolPathCollection(schlDoc.data()?.SCHOOL_NAME)
                                setprincipalSchoolId(schlDoc.id)
                            }
                        });
                    })
                }
            } else {
                setprinciSchoolPathCollection(teacherSchoolPathCollection)
                setprincipalSchoolId(teacherSchoolId)
            }
        }
        return () => { aaa = false }
    }, [principalEnteredData.inputPrincipalSchoolName, principalOptions, teacherSchoolId, teacherSchoolPathCollection])


    const handleChangePrincipalValue = (event) => {
        event.preventDefault()
        setprincipalEnteredData(prev => { return { ...prev, isPrincipal: event.target.value } })
    };

    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (principalEnteredData.isPrincipal === 'Yes') {
                setmsgAndButton(prev => { return { ...prev, showButton: true, msgSent: false } })
            } else {
                setmsgAndButton(prev => { return { ...prev, showButton: false, msgSent: false } })
            }
        }
        return () => { aaa = false }
    }, [principalEnteredData.isPrincipal])

    var batch = writeBatch(dB)

    const [btnLoading, setbtnLoading] = useState(false)
    const handleSendVerificationRequest = async (e) => {
        e.preventDefault();
        setbtnLoading(true)
        setmsgAndButton(prev => { return { ...prev, disabledButton: true } })

        await batch.set(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO"), {
            USER_SCHOOL_ID: principalSchoolId
        });

        if (userStatuses.length === 0) {
            await batch.update(doc(dB, "USERS", userId), {
                USER_CURRENT_STATUS: 'PRINCIPAL',
            });
        }

        await batch.set(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), {
            VERIFIED_PRINCIPAL: false,
            USER_STATUS: 'PRINCIPAL',
            USER_SCHOOL_ID: principalSchoolId
        });

        await batch.set(doc(dB, "SCHOOLS", principalSchoolId, princiSchoolPathCollection, "PRINCIPAL_DETAILS", "VERIFICATION_REQUESTS", userId), {
            TIMESTAMP: serverTimestamp()
        });

        await batch.commit().then(() => {
            setbtnLoading(false)
            setmsgAndButton(prev => { return { ...prev, msgSent: true } })
            history.push('/user')
        }).catch(err => alert(err.message))
    }

    return (
        <div className='SidebarESchoolingStatusAddPrincipal'>
            <div className='principal'>
                <FormControl component="fieldset">
                    <div className='pricipalYesNo'>
                        <FormLabel component="legend">Are you Principal/HM at your school?</FormLabel>
                        <RadioGroup aria-label="principal" name="principal1" value={principalEnteredData.isPrincipal} onChange={handleChangePrincipalValue}>
                            <div className='pricipalYesNoOptions'>
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                            </div>
                        </RadioGroup>
                    </div>
                </FormControl>
            </div>
            {
                principalEnteredData.isPrincipal === 'Yes' && (
                    <>
                        {
                            teacherSchoolId ? (
                                <div className='princiSchoolName'>
                                    <h2>School Name</h2>
                                    <h3>:</h3>
                                    <h4>{teacherSchoolFullName}</h4>
                                </div>
                            ) : (
                                <>
                                    <Autocomplete
                                        value={teacherSchoolFullName}
                                        onChange={(e, newValue) => {
                                            e.preventDefault();
                                            setprincipalEnteredData(prev => { return { ...prev, inputPrincipalSchoolName: newValue } })
                                        }}
                                        inputValue={principalEnteredData.principalInputValue}
                                        onInputChange={(event, newInputValue) => {
                                            event.preventDefault();
                                            setprincipalEnteredData(prev => { return { ...prev, principalInputValue: newInputValue } })
                                            setshowEnteredPrincipal(prevState => { return { ...prevState, schoolName: { error: false } } })
                                        }}
                                        id="inputPrincipalSchoolName"
                                        name='inputPrincipalSchoolName'
                                        options={principalOptions.map((option) => option.schoolName)}
                                        // className={clsx(Muiclasses.extralongWidth)}
                                        sx={{ width: '700px', }}
                                        renderInput={(params) =>
                                            <TextField
                                                id="inputPrincipalSchoolName"
                                                name='inputPrincipalSchoolName'
                                                {...params}
                                                margin="normal"
                                                label="Search your school here by school name, village ,city or district"
                                                variant="outlined"
                                                error={showEnteredPrincipal.schoolName.error}
                                            />
                                        }
                                    />
                                    <div className='noAvailable_teacher'>
                                        <h5><b>Note : </b>If School you are looking for does not appear in the list, then your school is yet to create an account. Ask your school to create account first. Till then you can use this platform to follow other schools and make new friends.</h5>
                                    </div>
                                </>
                            )
                        }
                    </>
                )
            }

            {msgAndButton.showButton && (
                <LoadingButton
                    onClick={handleSendVerificationRequest}
                    variant='outlined'
                    loading={btnLoading}
                >
                    Send Verification Request to School
                </LoadingButton>
                // <Button disabled={msgAndButton.disabledButton}  ></Button>
            )}
            {msgAndButton.msgSent && (<h5 id='requestSentTeacher' >Request Sent to School..</h5>)}
        </div>
    )
}

export default SidebarESchoolingStatusAddPrincipal
