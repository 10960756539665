import React from 'react'
import '../../Pages/HowToUse.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function HowToUseEvents() {
    return (
        <>
            <div className='HowToUse'>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className='HowToUseSummary'>
                            <h3>How to Create and Publish Upcoming School Event?</h3>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='HowToUseDetails'>
                            <div className='HowToData'>
                                <h3>Add New Event</h3>
                                <h4>You can add a new event which will be seen by all, whoever visits your profile. </h4>
                                <p><span>Step 1 : </span> Click on “Upcoming Events” link on the left side of the homepage.</p>
                                <p><span>Step 2 : </span> Click on “Create New Event”.</p>
                                <p><span>Step 3 : </span> Fill in all the relevant and required information like event name, event type, event date, event description, event chief guests and event status.</p>
                                <p><span>Step 4 : </span> Click on the “Publish” button.</p>
                                <p><span>Step 5 : </span> If event is published successfully then you will see a message as “Event Published Successfully”.</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className='HowToUse'>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className='HowToUseSummary'>
                            <h3>How to See Already Published School Events?</h3>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='HowToUseDetails'>
                            <div className='HowToData'>
                                <h3>See Already Published Events</h3>
                                <h4>You can see all the events which you already have published, which are visible all, whoever visits your profile.</h4>
                                <p><span>Step 1 : </span> Click on “Upcoming Events” link on the left side of the homepage.</p>
                                <p><span>Step 2 : </span> Click on “Published Events”.</p>
                                <p><span>Step 3 : </span> List of all the events which you already have published, will appear.</p>
                                <p><span>Step 4 : </span> Click on any event and you will see the information like event name, event type, event date, event description, event chief guests, event status and the date event was published.</p>
                                <p><span>Step 5 : </span> If you found out that it has been sent by mistake or there are some mistakes in the events data itself then you can delete it by clicking the “Delete” button. </p>
                                <p><span>Step 6 : </span> The moment you delete the event, it will disappear from the list of all the notices and no one can see it again.</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
}

export default HowToUseEvents