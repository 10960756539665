import React, { useState, useEffect } from 'react'
import '../LeaveApplication/LeaveApp.css'
import { doc, onSnapshot, serverTimestamp, updateDoc, writeBatch } from 'firebase/firestore';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion, AccordionDetails, AccordionSummary, Badge, Box, Button, Modal,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function LeaveApplication(props) {

    const [openModalAccept, setOpenModalAccept] = useState(false);
    const handleOpenModalAccept = (e) => { setOpenModalAccept(true); };
    const handleCloseModalAccept = (e) => { setOpenModalAccept(false); };

    const [openModalReject, setOpenModalReject] = useState(false);
    const handleOpenModalReject = (e) => { setOpenModalReject(true); };
    const handleCloseModalReject = (e) => { setOpenModalReject(false); };

    const [btnLoading, setbtnLoading] = useState(false)

    const { leaveAppId, leaveAppData, userId, userPathCollection, userCurrentStatus, userSchoolId,
        teacherSchoolData, clsTeacherData, teachMedClsSec, appplicationStatus } = props

    const [applicantsData, setapplicantsData] = useState(null)
    useEffect(() => {
        if (leaveAppData) {
            onSnapshot(doc(dB, "USERS", leaveAppData?.APPLICANT_ID), (userDoc) => {
                if (userDoc.exists()) {
                    setapplicantsData(userDoc.data())
                }
            })
        }
    }, [leaveAppData])

    const [childsData, setchildsData] = useState(null)
    useEffect(() => {
        if (leaveAppData) {
            onSnapshot(doc(dB, "USERS", leaveAppData?.APPLICANT_ID, leaveAppData?.APPLICANT_PATH_COLLECTION, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", leaveAppData?.CHILD_ID), (childDoc) => {
                if (childDoc.exists()) {
                    setchildsData(childDoc.data())
                }
            })
        }
    }, [leaveAppData])

    // const [status, setstatus] = useState('recieved')
    const batch = writeBatch(dB)
    const handleApproveApplication = async (e) => {
        e.preventDefault();
        setbtnLoading(true)

        batch.delete(doc(dB, "SCHOOLS", userSchoolId, teacherSchoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", teachMedClsSec, "LEAVE_APPLICATION", "RECIEVED", "RECIEVED_APPLICATIONS", leaveAppId))
        // deleteDoc(doc(dB, "SCHOOLS", userSchoolId, teacherSchoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", teachMedClsSec, "LEAVE_APPLICATION", "RECIEVED", "RECIEVED_APPLICATIONS", leaveAppId))

        batch.set(doc(dB, "SCHOOLS", userSchoolId, teacherSchoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", teachMedClsSec, "LEAVE_APPLICATION", "ACCEPTED", "ACCEPTED_APPLICATIONS", leaveAppId), {
            APPLICATION_STATUS: 'ACCEPTED',
            CHILD_ID: leaveAppData?.CHILD_ID,
            APPLICANT_ID: leaveAppData?.APPLICANT_ID,
            APPLICANT_PATH_COLLECTION: leaveAppData?.APPLICANT_PATH_COLLECTION,
            APPLICANT_STATUS: leaveAppData?.APPLICANT_STATUS,
            TIMESTAMP: serverTimestamp(),
            ONE_DAY: leaveAppData?.ONE_DAY,
            ON_DATE: leaveAppData?.ON_DATE,
            APPLICATION_SUBJECT: leaveAppData?.APPLICATION_SUBJECT,
            APPLICATION_BODY: leaveAppData?.APPLICATION_BODY,
            FROM_TO_DATE: {
                START_DATE: leaveAppData?.FROM_TO_DATE?.START_DATE,
                END_DATE: leaveAppData?.FROM_TO_DATE?.END_DATE
            },
            VIEWED_BY: true
        })

        batch.update(doc(dB, "USERS", leaveAppData?.APPLICANT_ID, leaveAppData?.APPLICANT_PATH_COLLECTION, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", leaveAppData?.CHILD_ID, "LEAVE_APPLICATIONS", leaveAppId), {
            APPLICATION_STATUS: 'ACCEPTED',
        })

        batch.commit()
            .then(() => {
                // setstatus('accepted')
                setbtnLoading(false)
                setOpenModalAccept(false)
            })
            .catch(err => alert(err))
    }
    const handleRejectApplication = (e) => {
        e.preventDefault();
        setbtnLoading(true)

        batch.delete(doc(dB, "SCHOOLS", userSchoolId, teacherSchoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", teachMedClsSec, "LEAVE_APPLICATION", "RECIEVED", "RECIEVED_APPLICATIONS", leaveAppId))
        // deleteDoc(doc(dB, "SCHOOLS", userSchoolId, teacherSchoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", teachMedClsSec, "LEAVE_APPLICATION", "RECIEVED", "RECIEVED_APPLICATIONS", leaveAppId))

        batch.set(doc(dB, "SCHOOLS", userSchoolId, teacherSchoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", teachMedClsSec, "LEAVE_APPLICATION", "REJECTED", "REJECTED_APPLICATIONS", leaveAppId), {
            APPLICATION_STATUS: 'REJECTED',
            CHILD_ID: leaveAppData?.CHILD_ID,
            APPLICANT_ID: leaveAppData?.APPLICANT_ID,
            APPLICANT_PATH_COLLECTION: leaveAppData?.APPLICANT_PATH_COLLECTION,
            APPLICANT_STATUS: leaveAppData?.APPLICANT_STATUS,
            TIMESTAMP: serverTimestamp(),
            ONE_DAY: leaveAppData?.ONE_DAY,
            ON_DATE: leaveAppData?.ON_DATE,
            APPLICATION_SUBJECT: leaveAppData?.APPLICATION_SUBJECT,
            APPLICATION_BODY: leaveAppData?.APPLICATION_BODY,
            FROM_TO_DATE: {
                START_DATE: leaveAppData?.FROM_TO_DATE?.START_DATE,
                END_DATE: leaveAppData?.FROM_TO_DATE?.END_DATE
            },
            VIEWED_BY: true
        })

        batch.update(doc(dB, "USERS", leaveAppData?.APPLICANT_ID, leaveAppData?.APPLICANT_PATH_COLLECTION, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", leaveAppData?.CHILD_ID, "LEAVE_APPLICATIONS", leaveAppId), {
            APPLICATION_STATUS: 'REJECTED',
        })

        batch.commit()
            .then(() => {
                // setstatus('rejected')
                setbtnLoading(false)
                setOpenModalReject(false)
            })
            .catch(err => alert(err))
    }

    const handleViewNotice = (e) => {
        e.preventDefault();
        if (appplicationStatus === "recieved") {
            updateDoc(doc(dB, "SCHOOLS", userSchoolId, teacherSchoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", teachMedClsSec, "LEAVE_APPLICATION", "RECIEVED", "RECIEVED_APPLICATIONS", leaveAppId), {
                VIEWED_BY: true
            }).catch(err => alert(err.message))
        }
    }

    return (
        <div className='SidebarNoticeBoardAccordian'>
            <div sx={{ width: '100%' }} id='acoordianNoticeBoard'>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={handleViewNotice}
                        sx={{ backgroundColor: "rgb(245, 244, 244)" }}
                    >
                        {
                            leaveAppData?.VIEWED_BY ? (
                                <div className='AccordionSummary'>
                                    <div className='AccordionSummary__SentOn'>
                                        <h3>Date : <strong style={{ marginLeft: '10px' }} >{typeof leaveAppData?.TIMESTAMP === 'object' ? leaveAppData?.TIMESTAMP?.toDate().toDateString() : leaveAppData?.TIMESTAMP}</strong></h3>
                                    </div>
                                    {
                                        leaveAppData?.APPLICANT_STATUS === "TEACHER" ? (
                                            <div className='AccordionSummary__Subject'>
                                                <h3>from : <strong>{applicantsData?.USER_FULL_NAME}</strong></h3>
                                            </div>
                                        ) : (
                                            <div className='AccordionSummary__Subject'>
                                                <h3>from : <strong>{applicantsData?.USER_FULL_NAME}</strong> parent of <strong>{childsData?.CHILDS_FULL_NAME}</strong></h3>
                                            </div>
                                        )
                                    }

                                </div>
                            ) : (
                                <Badge anchorOrigin={{ vertical: 'top', horizontal: 'left', }} color="error" badgeContent=" " variant="dot">
                                    <div className='AccordionSummary'>
                                        <div className='AccordionSummary__SentOn'>
                                            <h3>Date : <strong style={{ marginLeft: '10px' }} >{typeof leaveAppData?.TIMESTAMP === 'object' ? leaveAppData?.TIMESTAMP?.toDate().toDateString() : leaveAppData?.TIMESTAMP}</strong></h3>
                                        </div>
                                        {
                                            leaveAppData?.APPLICANT_STATUS === "TEACHER" ? (
                                                <div className='AccordionSummary__Subject'>
                                                    <h3>from : <strong>{applicantsData?.USER_FULL_NAME}</strong></h3>
                                                </div>
                                            ) : (
                                                <div className='AccordionSummary__Subject'>
                                                    <h3>from : <strong>{applicantsData?.USER_FULL_NAME}</strong> parent of <strong>{childsData?.CHILDS_FULL_NAME}</strong></h3>
                                                </div>
                                            )
                                        }
                                    </div>
                                </Badge>
                            )
                        }
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='AccordionDetails '>
                            <div className='leaveDataAcco'>
                                <div className='leaveData'>
                                    <p>Leave on :</p>
                                    <h5>{leaveAppData?.ONE_DAY ? typeof leaveAppData?.ON_DATE === 'object' ? leaveAppData?.ON_DATE.toDate().toDateString() : leaveAppData?.ON_DATE : `${typeof leaveAppData?.FROM_TO_DATE?.START_DATE === 'object' ? leaveAppData?.FROM_TO_DATE?.START_DATE?.toDate().toDateString() : leaveAppData?.FROM_TO_DATE?.START_DATE} to ${typeof leaveAppData?.FROM_TO_DATE?.END_DATE === 'object' ? leaveAppData?.FROM_TO_DATE?.END_DATE?.toDate().toDateString() : leaveAppData?.FROM_TO_DATE?.END_DATE}`}</h5>
                                </div>
                                {
                                    (leaveAppData?.APPLICATION_STATUS === "ACCEPTED" || leaveAppData?.APPLICATION_STATUS === "REJECTED") && (
                                        <div className='leaveData'>
                                            <p>Status :</p>
                                            <h5>{leaveAppData?.APPLICATION_STATUS}</h5>
                                        </div>
                                    )
                                }

                                <div className='leaveData'>
                                    <p>Subject :</p>
                                    <h5>{leaveAppData?.APPLICATION_SUBJECT}</h5>
                                </div>
                                <div className='leaveData'>
                                    <p>Message :</p>
                                    <h5>{leaveAppData?.APPLICATION_BODY}</h5>
                                </div>
                                {
                                    appplicationStatus === 'recieved' && (
                                        <div className='leaveButtons'>
                                            <Button onClick={handleOpenModalAccept}>Approve</Button>
                                            <Button onClick={handleOpenModalReject}>Reject</Button>
                                        </div>
                                    )
                                }

                            </div>

                        </div>
                    </AccordionDetails>
                </Accordion>
                <Modal
                    open={openModalAccept}
                    onClose={handleCloseModalAccept}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className='deletePostModal'>
                            <div className='deletePostModalText'>
                                <h4 style={{ textAlign: 'center' }}>Do you really want to Approve the leave Application of "{applicantsData?.USER_FULL_NAME}"?</h4>
                            </div>
                            <div className='deletePostModalButtons'>
                                <LoadingButton
                                    onClick={handleApproveApplication}
                                    loading={btnLoading}
                                    disabled={btnLoading}
                                    variant='outlined'
                                >
                                    Yes
                                </LoadingButton>
                                {/* <Button isabled={btnLoading} ></Button> */}
                                <Button onClick={handleCloseModalAccept}>No</Button>
                            </div>
                        </div>
                    </Box>
                </Modal>
                <Modal
                    open={openModalReject}
                    onClose={handleCloseModalReject}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className='deletePostModal'>
                            <div className='deletePostModalText'>
                                <h4 style={{ textAlign: 'center' }}>Do you really want to Reject the leave Application of "{applicantsData?.USER_FULL_NAME}"?</h4>
                            </div>
                            <div className='deletePostModalButtons'>
                                <LoadingButton
                                    onClick={handleRejectApplication}
                                    loading={btnLoading}
                                    disabled={btnLoading}
                                    variant='outlined'
                                >
                                    Yes
                                </LoadingButton>
                                {/* <Button isabled={btnLoading} ></Button> */}
                                <Button onClick={handleCloseModalReject}>No</Button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}

export default LeaveApplication