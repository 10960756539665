import React, { useEffect, useState } from 'react'
import '../Header/HeaderMessages.css'
// import dB from '../firebase'
import { doc, onSnapshot } from 'firebase/firestore'
import { Link } from 'react-router-dom'

import { Avatar } from '@mui/material'
import dB from '../../firebase'

function HeaderMessages(props) {

    const { msgToFromId, msgRecieverSenderStatus, msgId, msgText, msgTimestamp, isViewed,
        userId, userPathCollection, userCurrentStatus, userFullName } = props


    const [msgData, setmsgData] = useState({ msgAvatar: '', msgToFrom: '' })
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (msgRecieverSenderStatus && msgToFromId) {
                if (msgRecieverSenderStatus === 'SCHOOL') {
                    onSnapshot(doc(dB, "SCHOOLS", msgToFromId), (snap) => {
                        if (snap.exists()) {
                            setmsgData(prev => {
                                return {
                                    ...prev, msgToFrom: snap.data()?.SCHOOL_FULL_NAME,
                                    msgAvatar: snap.data()?.USER_DISPLAY_PIC
                                }
                            })
                        }
                    })
                } else {
                    onSnapshot(doc(dB, "USERS", msgToFromId), (snap) => {
                        if (snap.exists()) {
                            setmsgData(prev => {
                                return {
                                    ...prev, msgToFrom: snap.data()?.USER_NAME,
                                    msgAvatar: snap.data()?.USER_DISPLAY_PIC
                                }
                            })
                        }
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [msgRecieverSenderStatus, msgToFromId])

    return (
        <div className={`headermessages ${isViewed ? '' : 'isNotViewed'}`} key={msgId}>
            <div className='headermessages__left'>
                {/* avatar */}
                <Link to={`/friendsprofile/${msgToFromId}`} style={{ textDecoration: 'none', color: 'black' }}>
                    <Avatar
                        className='headermessages__leftavatar'
                        src={msgData.msgAvatar}
                        alt={msgData.msgToFrom}
                        sx={{ width: 45, height: 45 }}
                    />
                </Link>

            </div>
            <div className='headermessages__right'>
                {/* username */}

                <h5>
                    <Link to={msgRecieverSenderStatus === 'SCHOOL' ? `${`/schoolfriendprofile/${msgToFromId}`}` : `${`/friendsprofile/${msgToFromId}`}`} style={{ textDecoration: 'none', color: 'black' }}>
                        {msgData.msgToFrom}
                    </Link>
                </h5>
                <span style={{ fontSize: '10px', fontWeight: '600', color: 'gray' }}> ({msgTimestamp?.toDate().toDateString()})</span>
                {/* message */}
                <p>Message : {msgText}</p>
            </div>
        </div>
    )
}

export default HeaderMessages
