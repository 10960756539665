import React, { useEffect, useState } from 'react'
import SchoolVerifiedMembersTeachersSubject from './SchoolVerifiedMembersTeachersSubject';
import '../VerifiedMembers/SchoolVerifiedMembersTeachers.css'

// import dB from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import dB from '../../firebase';

function SchoolVerifiedMembersTeachers(props) {
    const {
        subjectName, staffTeachMedium, staffTeachClassNum, staffTeachSection, staffTeacherMedClsSec,
        schoolId, schoolShortName, schoolFullName, schoolAffiliation,
    } = props

    const [verifiedMemberData, setverifiedMemberData] = useState({
        teachersId: '', teachersSubjectId: '', teachersPathCollection: '', teachersName: '', teachersDP: ''
    })
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolShortName && staffTeacherMedClsSec && subjectName) {
                onSnapshot(doc(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", staffTeacherMedClsSec, "TEACHERS", "VERIFIED_MEMBERS", subjectName, "VERIFIED_MEMBERS_DETAILS"), (snap) => {
                    if (snap.exists()) {
                        setverifiedMemberData(prev => {
                            return {
                                ...prev,
                                teachersId: snap.data()?.TEACHERS_ID,
                                teachersSubjectId: snap.data()?.TEACHERS_SUBJECT_ID
                            }
                        })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [schoolId, schoolShortName, staffTeacherMedClsSec, subjectName])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (verifiedMemberData.teachersId) {
                onSnapshot(doc(dB, "USERS", verifiedMemberData.teachersId), (snap) => {
                    if (snap.exists()) {
                        setverifiedMemberData(prev => {
                            return {
                                ...prev,
                                teachersPathCollection: snap.data()?.USER_NAME,
                                teachersName: snap.data()?.USER_NAME,
                                teachersDP: snap.data()?.USER_DISPLAY_PIC
                            }
                        })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [verifiedMemberData.teachersId])

    return (
        <div className='SchoolVerifiedMembersTeachers'>
            <hr />
            <div className='SchoolVerifiedMembersTeachers__containerRightTeachingStaffData' >
                <p>{subjectName}</p>
                {
                    verifiedMemberData.teachersId ? (
                        <SchoolVerifiedMembersTeachersSubject
                            subTeacherId={verifiedMemberData.teachersId}
                            subTeacherPathCollection={verifiedMemberData.teachersPathCollection}
                            subTeacherName={verifiedMemberData.teachersName}
                            subTeacherSubjectId={verifiedMemberData.teachersSubjectId}
                            teachersDP={verifiedMemberData.teachersDP}
                            schoolId={schoolId}
                            schoolShortName={schoolShortName}
                            staffTeacherMedClsSec={staffTeacherMedClsSec}
                            subjectName={subjectName}
                        />
                    ) : (
                        <>
                            <div className='verifiedMember'>
                                <h3>No member is verified for the subject of {subjectName}.</h3>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default SchoolVerifiedMembersTeachers
