import React, { useEffect, useState } from 'react'
import '../Pages/SidebarESchoolingStatus.css'
import Login from './Login';
import Header from '../Components/Header/Header';
import SidebarESchoolingStatusShowStudent from '../Components/Sidebars/SidebarESchoolingStatusShowStudent';
import SidebarESchoolingStatusShowPrincipal from '../Components/Sidebars/SidebarESchoolingStatusShowPrincipal';
import SidebarESchoolingStatusShowParent from '../Components/Sidebars/SidebarESchoolingStatusShowParent';
import SidebarESchoolingStatusShowTeacher from '../Components/Sidebars/SidebarESchoolingStatusShowTeacher';
import SidebarESchoolingStatusAddTeacher from '../Components/Sidebars/SidebarESchoolingStatusAddTeacher';
import SidebarESchoolingStatusAddPrincipal from '../Components/Sidebars/SidebarESchoolingStatusAddPrincipal';
import SidebarESchoolingStatusAddStudent from '../Components/Sidebars/SidebarESchoolingStatusAddStudent';
import SidebarESchoolingStatusAddParent from '../Components/Sidebars/SidebarESchoolingStatusAddParent';

import dB, { auth } from '../firebase'
import { doc, onSnapshot } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


function SidebarESchoolingStatus() {

    const [showBlock, setshowBlock] = useState({
        showTeacher: false, showPrincipal: false, showStudent: false, showParent: false,
        addTeacher: false, addPrincipal: false, addStudent: false, addParent: false,
    })

    const [userId, setuserId] = useState('')
    useEffect(() => {
        const unSubscribe = onAuthStateChanged(auth, (userCredentials) => {
            if (userCredentials) {
                setuserId(userCredentials.uid)
            }
        });
        return () => { unSubscribe(); }
    }, [])

    const [userDataInfo, setuserDataInfo] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId) {
                onSnapshot(doc(dB, "USERS", userId), (snap) => {
                    if (snap.exists()) {
                        setuserDataInfo(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userId])


    const [userStatuses, setuserStatuses] = useState([])
    useEffect(() => {
        if (userStatuses.length > 0) {
            userStatuses.splice(0, userStatuses.length)
        }
        var aaa = true
        if (aaa === true) {
            if (userId && userDataInfo?.USER_NAME) {
                onSnapshot(doc(dB, "USERS", userId, userDataInfo?.USER_NAME, "E-SCHOOLING_INFO", "IS_TEACHER", "VERIFICATION_DETAILS"), (teachDOc) => {
                    if (teachDOc.exists()) { setuserStatuses(prev => { return [...prev, teachDOc.data()?.USER_STATUS] }) }
                })
                onSnapshot(doc(dB, "USERS", userId, userDataInfo?.USER_NAME, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), (prnciDOc) => {
                    if (prnciDOc.exists()) { setuserStatuses(prev => { return [...prev, prnciDOc.data()?.USER_STATUS] }) }
                })
                onSnapshot(doc(dB, "USERS", userId, userDataInfo?.USER_NAME, "E-SCHOOLING_INFO", "IS_STUDENT", "VERIFICATION_DETAILS"), (suDoc) => {
                    if (suDoc.exists()) { setuserStatuses(prev => { return [...prev, suDoc.data()?.USER_STATUS] }) }
                })
                onSnapshot(doc(dB, "USERS", userId, userDataInfo?.USER_NAME, "E-SCHOOLING_INFO", "IS_PARENT", "VERIFICATION_DETAILS"), (parDOc) => {
                    if (parDOc.exists()) { setuserStatuses(prev => { return [...prev, parDOc.data()?.USER_STATUS] }) }
                })
            }
        }
        return () => { aaa = false }
    }, [userDataInfo?.USER_NAME, userId])


    const [studentData, setstudentData] = useState(null)
    const [princiData, setprinciData] = useState(null)
    const [teacherData, setteacherData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userStatuses.length) {
                if (userStatuses.includes('TEACHER')) {
                    onSnapshot(doc(dB, "USERS", userId, userDataInfo?.USER_NAME, "E-SCHOOLING_INFO", "IS_TEACHER", "VERIFICATION_DETAILS"), (userDoc) => {
                        if (userDoc.exists()) {
                            setteacherData(userDoc.data())
                        }
                    })
                }
                if (userStatuses.includes('STUDENT')) {
                    onSnapshot(doc(dB, "USERS", userId, userDataInfo?.USER_NAME, "E-SCHOOLING_INFO", "IS_STUDENT", "VERIFICATION_DETAILS"), (stuDoc) => {
                        if (stuDoc.exists()) {
                            setstudentData(stuDoc.data())
                        }
                    })
                }
                if (userStatuses.includes('PRINCIPAL')) {
                    onSnapshot(doc(dB, "USERS", userId, userDataInfo?.USER_NAME, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), (eschlDoc) => {
                        if (eschlDoc.exists()) {
                            setprinciData(eschlDoc.data())
                        }
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [userDataInfo?.USER_NAME, userId, userStatuses])

    const [princiSchool, setprinciSchool] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (princiData) {
                onSnapshot(doc(dB, "SCHOOLS", princiData?.USER_SCHOOL_ID), (snap) => {
                    if (snap.exists()) {
                        setprinciSchool(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [princiData])
    const [teacherSchool, setteacherSchool] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (teacherData) {
                onSnapshot(doc(dB, "SCHOOLS", teacherData?.USER_SCHOOL_ID), (snap) => {
                    if (snap.exists()) {
                        setteacherSchool(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [teacherData])
    const [studentSchool, setstudentSchool] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (studentData) {
                onSnapshot(doc(dB, "SCHOOLS", studentData?.USER_SCHOOL_ID), (snap) => {
                    if (snap.exists()) {
                        setstudentSchool(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [studentData])


    const [dropdown, setdropdown] = useState({ dropdownShowStatus: false, dropdownAddStatus: false })
    const handleDropdownShowStatus = (e) => {
        e.preventDefault();
        setdropdown(prevState => { return { ...prevState, dropdownShowStatus: !dropdown.dropdownShowStatus } })
    }
    const handleDropdownAddStatus = (e) => {
        e.preventDefault();
        setdropdown(prevState => { return { ...prevState, dropdownAddStatus: !dropdown.dropdownAddStatus } })
    }


    // show status
    const handleShowTeacherStatus = (e) => {
        e.preventDefault();
        setshowBlock(prevState => {
            return {
                ...prevState,
                showTeacher: true,
                showPrincipal: false,
                showStudent: false,
                showParent: false,
                addTeacher: false,
                addPrincipal: false,
                addStudent: false,
                addParent: false,
            }
        })
    }
    const handleShowPrincipalStatus = (e) => {
        e.preventDefault();
        setshowBlock(prevState => {
            return {
                ...prevState,
                showTeacher: false,
                showPrincipal: true,
                showStudent: false,
                showParent: false,
                addTeacher: false,
                addPrincipal: false,
                addStudent: false,
                addParent: false,
            }
        })
    }
    const handleShowParentStatus = (e) => {
        e.preventDefault();
        setshowBlock(prevState => {
            return {
                ...prevState,
                showTeacher: false,
                showPrincipal: false,
                showStudent: false,
                showParent: true,
                addTeacher: false,
                addPrincipal: false,
                addStudent: false,
                addParent: false,
            }
        })
    }
    const handleShowStudentStatus = (e) => {
        e.preventDefault();
        setshowBlock(prevState => {
            return {
                ...prevState,
                showTeacher: false,
                showPrincipal: false,
                showStudent: true,
                showParent: false,
                addTeacher: false,
                addPrincipal: false,
                addStudent: false,
                addParent: false,
            }
        })
    }

    // add status
    const handleAddTeacherStatus = (e) => {
        e.preventDefault();
        setshowBlock(prevState => {
            return {
                ...prevState,
                showTeacher: false,
                showPrincipal: false,
                showStudent: false,
                showParent: false,
                addTeacher: true,
                addPrincipal: false,
                addStudent: false,
                addParent: false,
            }
        })
    }
    const handleAddPrincipalStatus = (e) => {
        e.preventDefault();
        setshowBlock(prevState => {
            return {
                ...prevState,
                showTeacher: false,
                showPrincipal: false,
                showStudent: false,
                showParent: false,
                addTeacher: false,
                addPrincipal: true,
                addStudent: false,
                addParent: false,
            }
        })
    }
    const handleAddParentStatus = (e) => {
        e.preventDefault();
        setshowBlock(prevState => {
            return {
                ...prevState,
                showTeacher: false,
                showPrincipal: false,
                showStudent: false,
                showParent: false,
                addTeacher: false,
                addPrincipal: false,
                addStudent: false,
                addParent: true,
            }
        })
    }
    const handleAddStudentStatus = (e) => {
        e.preventDefault();
        setshowBlock(prevState => {
            return {
                ...prevState,
                showTeacher: false,
                showPrincipal: false,
                showStudent: false,
                showParent: false,
                addTeacher: false,
                addPrincipal: false,
                addStudent: true,
                addParent: false,
            }
        })
    }

    return (
        <>
            {
                userId === '' ? (
                    <Login />
                ) : (
                    <div className='SidebarESchoolingStatus'>
                        <Header />
                        <div className='SidebarESchoolingStatus__Container'>
                            <div className='SidebarESchoolingStatus__ContainerTop'>
                                <h2>Add/Remove E-Schooling Status</h2>
                                <hr />
                            </div>
                            <div className='SidebarESchoolingStatus__ContainerBottom'>
                                <div key='main1' className='SidebarESchoolingStatus__ContainerBottomLeft'>
                                    <nav className='SidebarESchoolingStatus__ContainerBottomLeftNav'>
                                        <div key='existing' id='existingStatus' className='existingStatus'>
                                            <div key='heading1' name='statusTitle' className='statusTitle' onClick={handleDropdownShowStatus}>
                                                <h2>Existing E-Schooling Status</h2>
                                                {dropdown.dropdownShowStatus ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                                            </div>
                                            {
                                                dropdown.dropdownShowStatus && (
                                                    <div key='heading1Options' className='existingESchoolStatus'>
                                                        {
                                                            userStatuses.includes('TEACHER') && (
                                                                <div key='showteacher' className='showExistingESchoolStatus' onClick={handleShowTeacherStatus}>
                                                                    <ArrowRightIcon />
                                                                    <h2>Teacher</h2>
                                                                </div>
                                                            )
                                                        }

                                                        {
                                                            userStatuses.includes('PRINCIPAL') && (
                                                                <div key='showprincipal' className='showExistingESchoolStatus' onClick={handleShowPrincipalStatus}>
                                                                    <ArrowRightIcon />
                                                                    <h2>Principal</h2>
                                                                </div>
                                                            )
                                                        }

                                                        {
                                                            userStatuses.includes('PARENT') && (
                                                                <div key='showparent' className='showExistingESchoolStatus' onClick={handleShowParentStatus}>
                                                                    <ArrowRightIcon />
                                                                    <h2>Parent</h2>
                                                                </div>
                                                            )
                                                        }

                                                        {
                                                            userStatuses.includes('STUDENT') && (
                                                                <div key='showstudent' className='showExistingESchoolStatus' onClick={handleShowStudentStatus}>
                                                                    <ArrowRightIcon />
                                                                    <h2>Student</h2>
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                )
                                            }

                                        </div>
                                        <div key='addNew' style={{ display: userDataInfo?.USER_CURRENT_STATUS === 'STUDENT' ? 'none' : 'flex' }} id='AddStatus' className='AddStatus'>
                                            <div key='heading2' name='addStatusTitle' className='addStatusTitle' onClick={handleDropdownAddStatus}>
                                                <h2>Add Another E-Schooling Status</h2>
                                                {dropdown.dropdownAddStatus ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                                            </div>
                                            {
                                                dropdown.dropdownAddStatus && (
                                                    <div key='heading2Options' className='existingESchoolStatus'>
                                                        <div key='addteacher' style={{ display: userStatuses.includes('TEACHER') ? 'none' : 'flex' }} className='addExistingESchoolStatus' onClick={handleAddTeacherStatus}>
                                                            <ArrowRightIcon />
                                                            <h2>Teacher</h2>
                                                        </div>
                                                        <div key='addprincipal' style={{ display: userStatuses.includes('PRINCIPAL') ? 'none' : 'flex' }} className='addExistingESchoolStatus' onClick={handleAddPrincipalStatus}>
                                                            <ArrowRightIcon />
                                                            <h2>Principal</h2>
                                                        </div>
                                                        <div key='addparent' style={{ display: userStatuses.includes('PARENT') ? 'none' : 'flex' }} className='addExistingESchoolStatus' onClick={handleAddParentStatus}>
                                                            <ArrowRightIcon />
                                                            <h2>Parent</h2>
                                                        </div>
                                                        {
                                                            userStatuses.length === 0 && (
                                                                <div key='addstudent' className='addExistingESchoolStatus' onClick={handleAddStudentStatus}>
                                                                    <ArrowRightIcon />
                                                                    <h2>Student</h2>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </nav>
                                </div>
                                <div key='main2' className='SidebarESchoolingStatus__ContainerBottomRight'>
                                    {
                                        showBlock.showStudent && (
                                            <div key='SidebarESchoolingStatusShowStudent' className='SidebarESchoolingStatus__ContainerBottomRightShowStudent'>
                                                <SidebarESchoolingStatusShowStudent
                                                    userSchoolFullName={studentSchool?.SCHOOL_FULL_NAME}
                                                    userSchoolId={studentData?.USER_SCHOOL_ID}
                                                    userSchoolPathCollection={studentSchool?.SCHOOL_NAME}
                                                    userId={userId}
                                                    userPathCollection={userDataInfo?.USER_NAME}
                                                    userStatuses={userStatuses}
                                                    userCurrentStatus={userDataInfo?.USER_CURRENT_STATUS}
                                                />
                                            </div>
                                        )
                                    }

                                    {
                                        showBlock.showPrincipal && (
                                            <div key='SidebarESchoolingStatusShowPrincipal' className='SidebarESchoolingStatus__ContainerBottomRightShowPrincipal'>
                                                <SidebarESchoolingStatusShowPrincipal
                                                    userSchoolFullName={princiSchool?.SCHOOL_FULL_NAME}
                                                    userSchoolId={princiData?.USER_SCHOOL_ID}
                                                    userSchoolPathCollection={princiSchool?.SCHOOL_NAME}
                                                    userId={userId}
                                                    userPathCollection={userDataInfo?.USER_NAME}
                                                    userStatuses={userStatuses}
                                                    userCurrentStatus={userDataInfo?.USER_CURRENT_STATUS}
                                                />
                                            </div>
                                        )
                                    }

                                    {
                                        showBlock.showParent && (
                                            <div key='SidebarESchoolingStatusShowParent' className='SidebarESchoolingStatus__ContainerBottomRightShowParent'>
                                                <SidebarESchoolingStatusShowParent
                                                    userId={userId}
                                                    userPathCollection={userDataInfo?.USER_NAME}
                                                    userStatuses={userStatuses}
                                                    userCurrentStatus={userDataInfo?.USER_CURRENT_STATUS}
                                                />
                                            </div>
                                        )
                                    }

                                    {
                                        showBlock.showTeacher && (
                                            <div key='SidebarESchoolingStatusShowTeacher' className='SidebarESchoolingStatus__ContainerBottomRightShowTeacher'>
                                                <SidebarESchoolingStatusShowTeacher
                                                    userSchoolFullName={teacherSchool?.SCHOOL_FULL_NAME}
                                                    userSchoolPathCollection={teacherSchool?.SCHOOL_NAME}
                                                    userSchoolId={teacherData?.USER_SCHOOL_ID}
                                                    userId={userId}
                                                    userPathCollection={userDataInfo?.USER_NAME}
                                                    userStatuses={userStatuses}
                                                    userCurrentStatus={userDataInfo?.USER_CURRENT_STATUS}
                                                />
                                            </div>
                                        )
                                    }










                                    {
                                        showBlock.addTeacher && (
                                            <div key='SidebarESchoolingStatusAddTeacher' className='SidebarESchoolingStatus__ContainerBottomRightAddTeacher'>
                                                <SidebarESchoolingStatusAddTeacher
                                                    princiSchoolId={userStatuses.includes('PRINCIPAL') ? princiData?.USER_SCHOOL_ID : null}
                                                    princiSchoolPathCollection={userStatuses.includes('PRINCIPAL') ? princiSchool?.SCHOOL_NAME : null}
                                                    princiSchoolFullName={userStatuses.includes('PRINCIPAL') ? princiSchool?.SCHOOL_FULL_NAME : null}
                                                    userId={userId}
                                                    userPathCollection={userDataInfo?.USER_NAME}
                                                    userStatuses={userStatuses}
                                                    userCurrentStatus={userDataInfo?.USER_CURRENT_STATUS}
                                                />
                                            </div>
                                        )
                                    }

                                    {
                                        showBlock.addPrincipal && (
                                            <div key='SidebarESchoolingStatusAddPrincipal' className='SidebarESchoolingStatus__ContainerBottomRightAddPrincipal'>
                                                <SidebarESchoolingStatusAddPrincipal
                                                    teacherSchoolId={userStatuses.includes('TEACHER') ? teacherData?.USER_SCHOOL_ID : null}
                                                    teacherSchoolPathCollection={userStatuses.includes('TEACHER') ? teacherSchool?.SCHOOL_NAME : null}
                                                    teacherSchoolFullName={userStatuses.includes('TEACHER') ? teacherSchool?.SCHOOL_FULL_NAME : null}
                                                    userId={userId}
                                                    userPathCollection={userDataInfo?.USER_NAME}
                                                    princiFullName={userDataInfo?.USER_NAME}
                                                    userStatuses={userStatuses}
                                                    userCurrentStatus={userDataInfo?.USER_CURRENT_STATUS}
                                                />
                                            </div>
                                        )
                                    }

                                    {
                                        showBlock.addStudent && (
                                            <div key='SidebarESchoolingStatusAddStudent' className='SidebarESchoolingStatus__ContainerBottomRightAddStudent'>
                                                <SidebarESchoolingStatusAddStudent
                                                    userId={userId}
                                                    userPathCollection={userDataInfo?.USER_NAME}
                                                    userCurrentStatus={userDataInfo?.USER_CURRENT_STATUS}
                                                />
                                            </div>
                                        )
                                    }

                                    {
                                        showBlock.addParent && (
                                            <div key='SidebarESchoolingStatusAddParent' className='SidebarESchoolingStatus__ContainerBottomRightAddParent'>
                                                <SidebarESchoolingStatusAddParent
                                                    userId={userId}
                                                    userPathCollection={userDataInfo?.USER_NAME}
                                                    userStatuses={userStatuses}
                                                    userCurrentStatus={userDataInfo?.USER_CURRENT_STATUS}
                                                />
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div >
                )
            }
        </>

    )
}

export default SidebarESchoolingStatus