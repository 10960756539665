import React, { useEffect, useState } from 'react'
import '../ProjectsHomeworks/HomeWorksStudents.css'
import { doc, onSnapshot } from 'firebase/firestore'
import dB from '../../firebase'
import SyllabusCoveredParentsChildSubject from './SyllabusCoveredParentsChildSubject'

function SyllabusCoveredStudents(props) {
    const { userId, userPathCollection, userCurrentStatus, userSchoolId } = props

    // get students school name
    const [schoolName, setschoolName] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userSchoolId) {
                onSnapshot(doc(dB, "SCHOOLS", userSchoolId), (schlDoc) => {
                    if (schlDoc.exists()) {
                        setschoolName(schlDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userSchoolId])

    // get students details like name, class, section, medium
    const [studentsData, setstudentsData] = useState(null)
    const [studentsName, setstudentsName] = useState('')
    const [studVerifData, setstudVerifData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(doc(dB, "USERS", userId), (userDoc) => {
                    if (userDoc.exists()) {
                        setstudentsName(userDoc.data())
                    }
                })
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_STUDENT", "IS_STUDENT_DATA"), (studDOc) => {
                    if (studDOc.exists()) {
                        setstudentsData(studDOc.data())
                    }
                })
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_STUDENT", "VERIFICATION_DETAILS"), (verDoc) => {
                    if (verDoc.exists()) {
                        setstudVerifData(verDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])
    return (
        <div className='homeworkstudents'>
            <div className='homeworkstudents__top'>
                <h3>{schoolName?.SCHOOL_FULL_NAME}</h3>
                <h4>Syllabus Covered</h4>
                <hr />
            </div>

            <div className='homeworkstudents__bottom'>
                <div className='studentsName'>
                    <h3>Student Name</h3>
                    <h4>:</h4>
                    <div className='studentsNameName'>
                        <h5>{studentsName?.USER_NAME}</h5>
                        <p>({studentsData?.MEDIUM} · {studentsData?.STUDENTS_CLASS} · {studentsData?.STUDENTS_SECTION})</p>
                    </div>
                </div>
                {
                    studVerifData?.VERIFIED_STUDENT ? (
                        <SyllabusCoveredParentsChildSubject
                            userId={userId}
                            userPathCollection={userPathCollection}
                            userCurrentStatus={userCurrentStatus}
                            userSchoolId={userSchoolId}
                            childName={studentsName?.USER_NAME}
                            childMedium={studentsData?.MEDIUM}
                            childClass={studentsData?.STUDENTS_CLASS}
                            childSection={studentsData?.STUDENTS_SECTION}
                            childVerified={studVerifData?.VERIFIED_STUDENT}
                        />
                    ) : (
                        <div className='homeworkStudentNotVerified'>
                            <h3>You are not verified by your school at the moment. Please wait till your school verifies you.</h3>
                        </div>
                    )
                }
            </div>

        </div>
    )
}

export default SyllabusCoveredStudents