

export const returnMedClsSec = (med, cls, sec) => {

    if (med === 'English Medium' && cls === 'Pre Nursery' && sec === 'Section A') { return 'EngPreNurserySecA' }
    if (med === 'English Medium' && cls === 'Pre Nursery' && sec === 'Section B') { return 'EngPreNurserySecB' }
    if (med === 'English Medium' && cls === 'Pre Nursery' && sec === 'Section C') { return 'EngPreNurserySecC' }
    if (med === 'English Medium' && cls === 'Pre Nursery' && sec === 'Section D') { return 'EngPreNurserySecD' }
    if (med === 'English Medium' && cls === 'Pre Nursery' && sec === 'Section E') { return 'EngPreNurserySecE' }

    if (med === 'English Medium' && cls === 'Nursery' && sec === 'Section A') { return 'EngNurserySecA' }
    if (med === 'English Medium' && cls === 'Nursery' && sec === 'Section B') { return 'EngNurserySecB' }
    if (med === 'English Medium' && cls === 'Nursery' && sec === 'Section C') { return 'EngNurserySecC' }
    if (med === 'English Medium' && cls === 'Nursery' && sec === 'Section D') { return 'EngNurserySecD' }
    if (med === 'English Medium' && cls === 'Nursery' && sec === 'Section E') { return 'EngNurserySecE' }

    if (med === 'English Medium' && cls === 'Kindergarten 1(KG-1)' && sec === 'Section A') { return 'EngKG1SecA' }
    if (med === 'English Medium' && cls === 'Kindergarten 1(KG-1)' && sec === 'Section B') { return 'EngKG1SecB' }
    if (med === 'English Medium' && cls === 'Kindergarten 1(KG-1)' && sec === 'Section C') { return 'EngKG1SecC' }
    if (med === 'English Medium' && cls === 'Kindergarten 1(KG-1)' && sec === 'Section D') { return 'EngKG1SecD' }
    if (med === 'English Medium' && cls === 'Kindergarten 1(KG-1)' && sec === 'Section E') { return 'EngKG1SecE' }

    if (med === 'English Medium' && cls === 'Kindergarten 2(KG-2)' && sec === 'Section A') { return 'EngKG2SecA' }
    if (med === 'English Medium' && cls === 'Kindergarten 2(KG-2)' && sec === 'Section B') { return 'EngKG2SecB' }
    if (med === 'English Medium' && cls === 'Kindergarten 2(KG-2)' && sec === 'Section C') { return 'EngKG2SecC' }
    if (med === 'English Medium' && cls === 'Kindergarten 2(KG-2)' && sec === 'Section D') { return 'EngKG2SecD' }
    if (med === 'English Medium' && cls === 'Kindergarten 2(KG-2)' && sec === 'Section E') { return 'EngKG2SecE' }

    if (med === 'English Medium' && cls === 'Class 1' && sec === 'Section A') { return 'EngClass1SecA' }
    if (med === 'English Medium' && cls === 'Class 1' && sec === 'Section B') { return 'EngClass1SecB' }
    if (med === 'English Medium' && cls === 'Class 1' && sec === 'Section C') { return 'EngClass1SecC' }
    if (med === 'English Medium' && cls === 'Class 1' && sec === 'Section D') { return 'EngClass1SecD' }
    if (med === 'English Medium' && cls === 'Class 1' && sec === 'Section E') { return 'EngClass1SecE' }

    if (med === 'English Medium' && cls === 'Class 2' && sec === 'Section A') { return 'EngClass2SecA' }
    if (med === 'English Medium' && cls === 'Class 2' && sec === 'Section B') { return 'EngClass2SecB' }
    if (med === 'English Medium' && cls === 'Class 2' && sec === 'Section C') { return 'EngClass2SecC' }
    if (med === 'English Medium' && cls === 'Class 2' && sec === 'Section D') { return 'EngClass2SecD' }
    if (med === 'English Medium' && cls === 'Class 2' && sec === 'Section E') { return 'EngClass2SecE' }

    if (med === 'English Medium' && cls === 'Class 3' && sec === 'Section A') { return 'EngClass3SecA' }
    if (med === 'English Medium' && cls === 'Class 3' && sec === 'Section B') { return 'EngClass3SecB' }
    if (med === 'English Medium' && cls === 'Class 3' && sec === 'Section C') { return 'EngClass3SecC' }
    if (med === 'English Medium' && cls === 'Class 3' && sec === 'Section D') { return 'EngClass3SecD' }
    if (med === 'English Medium' && cls === 'Class 3' && sec === 'Section E') { return 'EngClass3SecE' }

    if (med === 'English Medium' && cls === 'Class 4' && sec === 'Section A') { return 'EngClass4SecA' }
    if (med === 'English Medium' && cls === 'Class 4' && sec === 'Section B') { return 'EngClass4SecB' }
    if (med === 'English Medium' && cls === 'Class 4' && sec === 'Section C') { return 'EngClass4SecC' }
    if (med === 'English Medium' && cls === 'Class 4' && sec === 'Section D') { return 'EngClass4SecD' }
    if (med === 'English Medium' && cls === 'Class 4' && sec === 'Section E') { return 'EngClass4SecE' }

    if (med === 'English Medium' && cls === 'Class 5' && sec === 'Section A') { return 'EngClass5SecA' }
    if (med === 'English Medium' && cls === 'Class 5' && sec === 'Section B') { return 'EngClass5SecB' }
    if (med === 'English Medium' && cls === 'Class 5' && sec === 'Section C') { return 'EngClass5SecC' }
    if (med === 'English Medium' && cls === 'Class 5' && sec === 'Section D') { return 'EngClass5SecD' }
    if (med === 'English Medium' && cls === 'Class 5' && sec === 'Section E') { return 'EngClass5SecE' }

    if (med === 'English Medium' && cls === 'Class 6' && sec === 'Section A') { return 'EngClass6SecA' }
    if (med === 'English Medium' && cls === 'Class 6' && sec === 'Section B') { return 'EngClass6SecB' }
    if (med === 'English Medium' && cls === 'Class 6' && sec === 'Section C') { return 'EngClass6SecC' }
    if (med === 'English Medium' && cls === 'Class 6' && sec === 'Section D') { return 'EngClass6SecD' }
    if (med === 'English Medium' && cls === 'Class 6' && sec === 'Section E') { return 'EngClass6SecE' }

    if (med === 'English Medium' && cls === 'Class 7' && sec === 'Section A') { return 'EngClass7SecA' }
    if (med === 'English Medium' && cls === 'Class 7' && sec === 'Section B') { return 'EngClass7SecB' }
    if (med === 'English Medium' && cls === 'Class 7' && sec === 'Section C') { return 'EngClass7SecC' }
    if (med === 'English Medium' && cls === 'Class 7' && sec === 'Section D') { return 'EngClass7SecD' }
    if (med === 'English Medium' && cls === 'Class 7' && sec === 'Section E') { return 'EngClass7SecE' }

    if (med === 'English Medium' && cls === 'Class 8' && sec === 'Section A') { return 'EngClass8SecA' }
    if (med === 'English Medium' && cls === 'Class 8' && sec === 'Section B') { return 'EngClass8SecB' }
    if (med === 'English Medium' && cls === 'Class 8' && sec === 'Section C') { return 'EngClass8SecC' }
    if (med === 'English Medium' && cls === 'Class 8' && sec === 'Section D') { return 'EngClass8SecD' }
    if (med === 'English Medium' && cls === 'Class 8' && sec === 'Section E') { return 'EngClass8SecE' }

    if (med === 'English Medium' && cls === 'Class 9' && sec === 'Section A') { return 'EngClass9SecA' }
    if (med === 'English Medium' && cls === 'Class 9' && sec === 'Section B') { return 'EngClass9SecB' }
    if (med === 'English Medium' && cls === 'Class 9' && sec === 'Section C') { return 'EngClass9SecC' }
    if (med === 'English Medium' && cls === 'Class 9' && sec === 'Section D') { return 'EngClass9SecD' }
    if (med === 'English Medium' && cls === 'Class 9' && sec === 'Section E') { return 'EngClass9SecE' }

    if (med === 'English Medium' && cls === 'Class 10' && sec === 'Section A') { return 'EngClass10SecA' }
    if (med === 'English Medium' && cls === 'Class 10' && sec === 'Section B') { return 'EngClass10SecB' }
    if (med === 'English Medium' && cls === 'Class 10' && sec === 'Section C') { return 'EngClass10SecC' }
    if (med === 'English Medium' && cls === 'Class 10' && sec === 'Section D') { return 'EngClass10SecD' }
    if (med === 'English Medium' && cls === 'Class 10' && sec === 'Section E') { return 'EngClass10SecE' }

    if (med === 'English Medium' && cls === 'Class 11(Science)' && sec === 'Section A') { return 'EngClass11ScienceSecA' }
    if (med === 'English Medium' && cls === 'Class 11(Science)' && sec === 'Section B') { return 'EngClass11ScienceSecB' }
    if (med === 'English Medium' && cls === 'Class 11(Science)' && sec === 'Section C') { return 'EngClass11ScienceSecC' }
    if (med === 'English Medium' && cls === 'Class 11(Science)' && sec === 'Section D') { return 'EngClass11ScienceSecD' }
    if (med === 'English Medium' && cls === 'Class 11(Science)' && sec === 'Section E') { return 'EngClass11ScienceSecE' }

    if (med === 'English Medium' && cls === 'Class 11(Commerce)' && sec === 'Section A') { return 'EngClass11CommerceSecA' }
    if (med === 'English Medium' && cls === 'Class 11(Commerce)' && sec === 'Section B') { return 'EngClass11CommerceSecB' }
    if (med === 'English Medium' && cls === 'Class 11(Commerce)' && sec === 'Section C') { return 'EngClass11CommerceSecC' }
    if (med === 'English Medium' && cls === 'Class 11(Commerce)' && sec === 'Section D') { return 'EngClass11CommerceSecD' }
    if (med === 'English Medium' && cls === 'Class 11(Commerce)' && sec === 'Section E') { return 'EngClass11CommerceSecE' }

    if (med === 'English Medium' && cls === 'Class 12(Science)' && sec === 'Section A') { return 'EngClass12ScienceSecA' }
    if (med === 'English Medium' && cls === 'Class 12(Science)' && sec === 'Section B') { return 'EngClass12ScienceSecB' }
    if (med === 'English Medium' && cls === 'Class 12(Science)' && sec === 'Section C') { return 'EngClass12ScienceSecC' }
    if (med === 'English Medium' && cls === 'Class 12(Science)' && sec === 'Section D') { return 'EngClass12ScienceSecD' }
    if (med === 'English Medium' && cls === 'Class 12(Science)' && sec === 'Section E') { return 'EngClass12ScienceSecE' }

    if (med === 'English Medium' && cls === 'Class 12(Commerce)' && sec === 'Section A') { return 'EngClass12CommerceSecA' }
    if (med === 'English Medium' && cls === 'Class 12(Commerce)' && sec === 'Section B') { return 'EngClass12CommerceSecB' }
    if (med === 'English Medium' && cls === 'Class 12(Commerce)' && sec === 'Section C') { return 'EngClass12CommerceSecC' }
    if (med === 'English Medium' && cls === 'Class 12(Commerce)' && sec === 'Section D') { return 'EngClass12CommerceSecD' }
    if (med === 'English Medium' && cls === 'Class 12(Commerce)' && sec === 'Section E') { return 'EngClass12CommerceSecE' }



    if (med === 'Marathi Medium' && cls === 'Pre Nursery' && sec === 'Section A') { return 'MarPreNurserySecA' }
    if (med === 'Marathi Medium' && cls === 'Pre Nursery' && sec === 'Section B') { return 'MarPreNurserySecB' }
    if (med === 'Marathi Medium' && cls === 'Pre Nursery' && sec === 'Section C') { return 'MarPreNurserySecC' }
    if (med === 'Marathi Medium' && cls === 'Pre Nursery' && sec === 'Section D') { return 'MarPreNurserySecD' }
    if (med === 'Marathi Medium' && cls === 'Pre Nursery' && sec === 'Section E') { return 'MarPreNurserySecE' }

    if (med === 'Marathi Medium' && cls === 'Nursery' && sec === 'Section A') { return 'MarNurserySecA' }
    if (med === 'Marathi Medium' && cls === 'Nursery' && sec === 'Section B') { return 'MarNurserySecB' }
    if (med === 'Marathi Medium' && cls === 'Nursery' && sec === 'Section C') { return 'MarNurserySecC' }
    if (med === 'Marathi Medium' && cls === 'Nursery' && sec === 'Section D') { return 'MarNurserySecD' }
    if (med === 'Marathi Medium' && cls === 'Nursery' && sec === 'Section E') { return 'MarNurserySecE' }

    if (med === 'Marathi Medium' && cls === 'Kindergarten 1(KG-1)' && sec === 'Section A') { return 'MarKG1SecA' }
    if (med === 'Marathi Medium' && cls === 'Kindergarten 1(KG-1)' && sec === 'Section B') { return 'MarKG1SecB' }
    if (med === 'Marathi Medium' && cls === 'Kindergarten 1(KG-1)' && sec === 'Section C') { return 'MarKG1SecC' }
    if (med === 'Marathi Medium' && cls === 'Kindergarten 1(KG-1)' && sec === 'Section D') { return 'MarKG1SecD' }
    if (med === 'Marathi Medium' && cls === 'Kindergarten 1(KG-1)' && sec === 'Section E') { return 'MarKG1SecE' }

    if (med === 'Marathi Medium' && cls === 'Kindergarten 2(KG-2)' && sec === 'Section A') { return 'MarKG2SecA' }
    if (med === 'Marathi Medium' && cls === 'Kindergarten 2(KG-2)' && sec === 'Section B') { return 'MarKG2SecB' }
    if (med === 'Marathi Medium' && cls === 'Kindergarten 2(KG-2)' && sec === 'Section C') { return 'MarKG2SecC' }
    if (med === 'Marathi Medium' && cls === 'Kindergarten 2(KG-2)' && sec === 'Section D') { return 'MarKG2SecD' }
    if (med === 'Marathi Medium' && cls === 'Kindergarten 2(KG-2)' && sec === 'Section E') { return 'MarKG2SecE' }



    if (med === 'Marathi Medium' && cls === 'Class 1' && sec === 'Section A') { return 'MarClass1SecA' }
    if (med === 'Marathi Medium' && cls === 'Class 1' && sec === 'Section B') { return 'MarClass1SecB' }
    if (med === 'Marathi Medium' && cls === 'Class 1' && sec === 'Section C') { return 'MarClass1SecC' }
    if (med === 'Marathi Medium' && cls === 'Class 1' && sec === 'Section D') { return 'MarClass1SecD' }
    if (med === 'Marathi Medium' && cls === 'Class 1' && sec === 'Section E') { return 'MarClass1SecE' }

    if (med === 'Marathi Medium' && cls === 'Class 2' && sec === 'Section A') { return 'MarClass2SecA' }
    if (med === 'Marathi Medium' && cls === 'Class 2' && sec === 'Section B') { return 'MarClass2SecB' }
    if (med === 'Marathi Medium' && cls === 'Class 2' && sec === 'Section C') { return 'MarClass2SecC' }
    if (med === 'Marathi Medium' && cls === 'Class 2' && sec === 'Section D') { return 'MarClass2SecD' }
    if (med === 'Marathi Medium' && cls === 'Class 2' && sec === 'Section E') { return 'MarClass2SecE' }

    if (med === 'Marathi Medium' && cls === 'Class 3' && sec === 'Section A') { return 'MarClass3SecA' }
    if (med === 'Marathi Medium' && cls === 'Class 3' && sec === 'Section B') { return 'MarClass3SecB' }
    if (med === 'Marathi Medium' && cls === 'Class 3' && sec === 'Section C') { return 'MarClass3SecC' }
    if (med === 'Marathi Medium' && cls === 'Class 3' && sec === 'Section D') { return 'MarClass3SecD' }
    if (med === 'Marathi Medium' && cls === 'Class 3' && sec === 'Section E') { return 'MarClass3SecE' }

    if (med === 'Marathi Medium' && cls === 'Class 4' && sec === 'Section A') { return 'MarClass4SecA' }
    if (med === 'Marathi Medium' && cls === 'Class 4' && sec === 'Section B') { return 'MarClass4SecB' }
    if (med === 'Marathi Medium' && cls === 'Class 4' && sec === 'Section C') { return 'MarClass4SecC' }
    if (med === 'Marathi Medium' && cls === 'Class 4' && sec === 'Section D') { return 'MarClass4SecD' }
    if (med === 'Marathi Medium' && cls === 'Class 4' && sec === 'Section E') { return 'MarClass4SecE' }

    if (med === 'Marathi Medium' && cls === 'Class 5' && sec === 'Section A') { return 'MarClass5SecA' }
    if (med === 'Marathi Medium' && cls === 'Class 5' && sec === 'Section B') { return 'MarClass5SecB' }
    if (med === 'Marathi Medium' && cls === 'Class 5' && sec === 'Section C') { return 'MarClass5SecC' }
    if (med === 'Marathi Medium' && cls === 'Class 5' && sec === 'Section D') { return 'MarClass5SecD' }
    if (med === 'Marathi Medium' && cls === 'Class 5' && sec === 'Section E') { return 'MarClass5SecE' }

    if (med === 'Marathi Medium' && cls === 'Class 6' && sec === 'Section A') { return 'MarClass6SecA' }
    if (med === 'Marathi Medium' && cls === 'Class 6' && sec === 'Section B') { return 'MarClass6SecB' }
    if (med === 'Marathi Medium' && cls === 'Class 6' && sec === 'Section C') { return 'MarClass6SecC' }
    if (med === 'Marathi Medium' && cls === 'Class 6' && sec === 'Section D') { return 'MarClass6SecD' }
    if (med === 'Marathi Medium' && cls === 'Class 6' && sec === 'Section E') { return 'MarClass6SecE' }

    if (med === 'Marathi Medium' && cls === 'Class 7' && sec === 'Section A') { return 'MarClass7SecA' }
    if (med === 'Marathi Medium' && cls === 'Class 7' && sec === 'Section B') { return 'MarClass7SecB' }
    if (med === 'Marathi Medium' && cls === 'Class 7' && sec === 'Section C') { return 'MarClass7SecC' }
    if (med === 'Marathi Medium' && cls === 'Class 7' && sec === 'Section D') { return 'MarClass7SecD' }
    if (med === 'Marathi Medium' && cls === 'Class 7' && sec === 'Section E') { return 'MarClass7SecE' }

    if (med === 'Marathi Medium' && cls === 'Class 8' && sec === 'Section A') { return 'MarClass8SecA' }
    if (med === 'Marathi Medium' && cls === 'Class 8' && sec === 'Section B') { return 'MarClass8SecB' }
    if (med === 'Marathi Medium' && cls === 'Class 8' && sec === 'Section C') { return 'MarClass8SecC' }
    if (med === 'Marathi Medium' && cls === 'Class 8' && sec === 'Section D') { return 'MarClass8SecD' }
    if (med === 'Marathi Medium' && cls === 'Class 8' && sec === 'Section E') { return 'MarClass8SecE' }

    if (med === 'Marathi Medium' && cls === 'Class 9' && sec === 'Section A') { return 'MarClass9SecA' }
    if (med === 'Marathi Medium' && cls === 'Class 9' && sec === 'Section B') { return 'MarClass9SecB' }
    if (med === 'Marathi Medium' && cls === 'Class 9' && sec === 'Section C') { return 'MarClass9SecC' }
    if (med === 'Marathi Medium' && cls === 'Class 9' && sec === 'Section D') { return 'MarClass9SecD' }
    if (med === 'Marathi Medium' && cls === 'Class 9' && sec === 'Section E') { return 'MarClass9SecE' }

    if (med === 'Marathi Medium' && cls === 'Class 10' && sec === 'Section A') { return 'MarClass10SecA' }
    if (med === 'Marathi Medium' && cls === 'Class 10' && sec === 'Section B') { return 'MarClass10SecB' }
    if (med === 'Marathi Medium' && cls === 'Class 10' && sec === 'Section C') { return 'MarClass10SecC' }
    if (med === 'Marathi Medium' && cls === 'Class 10' && sec === 'Section D') { return 'MarClass10SecD' }
    if (med === 'Marathi Medium' && cls === 'Class 10' && sec === 'Section E') { return 'MarClass10SecE' }

    if (med === 'Marathi Medium' && cls === 'Class 11(Arts)' && sec === 'Section A') { return 'MarClass11ArtsSecA' }
    if (med === 'Marathi Medium' && cls === 'Class 11(Arts)' && sec === 'Section B') { return 'MarClass11ArtsSecB' }
    if (med === 'Marathi Medium' && cls === 'Class 11(Arts)' && sec === 'Section C') { return 'MarClass11ArtsSecC' }
    if (med === 'Marathi Medium' && cls === 'Class 11(Arts)' && sec === 'Section D') { return 'MarClass11ArtsSecD' }
    if (med === 'Marathi Medium' && cls === 'Class 11(Arts)' && sec === 'Section E') { return 'MarClass11ArtsSecE' }

    if (med === 'Marathi Medium' && cls === 'Class 11(Commerce)' && sec === 'Section A') { return 'MarClass11CommerceSecA' }
    if (med === 'Marathi Medium' && cls === 'Class 11(Commerce)' && sec === 'Section B') { return 'MarClass11CommerceSecB' }
    if (med === 'Marathi Medium' && cls === 'Class 11(Commerce)' && sec === 'Section C') { return 'MarClass11CommerceSecC' }
    if (med === 'Marathi Medium' && cls === 'Class 11(Commerce)' && sec === 'Section D') { return 'MarClass11CommerceSecD' }
    if (med === 'Marathi Medium' && cls === 'Class 11(Commerce)' && sec === 'Section E') { return 'MarClass11CommerceSecE' }

    if (med === 'Marathi Medium' && cls === 'Class 12(Arts)' && sec === 'Section A') { return 'MarClass12ArtsSecA' }
    if (med === 'Marathi Medium' && cls === 'Class 12(Arts)' && sec === 'Section B') { return 'MarClass12ArtsSecB' }
    if (med === 'Marathi Medium' && cls === 'Class 12(Arts)' && sec === 'Section C') { return 'MarClass12ArtsSecC' }
    if (med === 'Marathi Medium' && cls === 'Class 12(Arts)' && sec === 'Section D') { return 'MarClass12ArtsSecD' }
    if (med === 'Marathi Medium' && cls === 'Class 12(Arts)' && sec === 'Section E') { return 'MarClass12ArtsSecE' }

    if (med === 'Marathi Medium' && cls === 'Class 12(Commerce)' && sec === 'Section A') { return 'MarClass12CommerceSecA' }
    if (med === 'Marathi Medium' && cls === 'Class 12(Commerce)' && sec === 'Section B') { return 'MarClass12CommerceSecB' }
    if (med === 'Marathi Medium' && cls === 'Class 12(Commerce)' && sec === 'Section C') { return 'MarClass12CommerceSecC' }
    if (med === 'Marathi Medium' && cls === 'Class 12(Commerce)' && sec === 'Section D') { return 'MarClass12CommerceSecD' }
    if (med === 'Marathi Medium' && cls === 'Class 12(Commerce)' && sec === 'Section E') { return 'MarClass12CommerceSecE' }

}
// export default FunctionClsMedSec
