import React, { useEffect, useState } from 'react'
import '../ProjectsHomeworks/HomeWorksPrincipalClassNSubject.css'
import '../ProjectsHomeworks/HomeWorksStudentsSubject.css'
import '../ProjectsHomeworks/HomeWorksTeachersClassNSubject.css'

import dB from '../../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec';

function SyllabusCoveredSubjectwise(props) {
    const { userId, userPathCollection, userCurrentStatus, userSchoolId,
        userSchoolPathCollection, isPrinciVerified, classPath, medium, classNum, section, subName } = props

    const [teacherData, setteacherData] = useState({ teacherId: '', teacherName: '' })
    useEffect(() => {
        async function fetchSubjectTeacherName() {
            if (teacherData.teacherId) {
                onSnapshot(doc(dB, "USERS", teacherData.teacherId), (snap) => {
                    if (snap.exists()) {
                        setteacherData(prev => { return { ...prev, teacherName: snap.data()?.USER_NAME } })
                    }
                })
            }
        }
        fetchSubjectTeacherName();
    }, [teacherData.teacherId])

    const [mediumClsSec, setmediumClsSec] = useState('')
    useEffect(() => {
        setmediumClsSec('')
        var aaa = true
        if (aaa === true) {
            var abc = returnMedClsSec(medium, classNum, section)
            setmediumClsSec(abc)
        }
        return () => { aaa = false }
    }, [classNum, medium, section])

    const [syllabusCovered, setsyllabusCovered] = useState(null)
    useEffect(() => {
        setsyllabusCovered(null)
        if (userSchoolId && userSchoolPathCollection && subName && mediumClsSec) {
            onSnapshot(doc(dB, "SCHOOLS", userSchoolId, userSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", mediumClsSec, "SYLLABUS_COMPLETED", subName), (covData) => {
                if (covData.exists()) {
                    setsyllabusCovered(covData.data())
                }
            })
        }
    }, [subName, mediumClsSec, userSchoolId, userSchoolPathCollection])


    return (
        <div className='subAccordionDetails'>
            <h3>Syllabus Covered of <b style={{ color: 'var(--connected-color)' }}>{subName}</b> by <span style={{ color: 'black' }}>{teacherData.teacherName}</span></h3>

            <div style={{ marginTop: "10px", marginLeft: "100px" }} className='recentlyAdddedHW recentlyAdddedHead'>
                <h2>Ongoing/Current Chapter</h2>
                {
                    syllabusCovered?.CURRENT_CHAPTER ? (
                        <div className='syllabusCovered' style={{ marginLeft: "50px", marginTop: "10px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {
                                syllabusCovered?.CURRENT_CHAPTER && (
                                    <p style={{ fontSize: "18px", fontWeight: "700" }}>{syllabusCovered?.CHAPTERS_COMPLETED?.length > 0 ? syllabusCovered?.CHAPTERS_COMPLETED?.length + 1 : 0 + 1}. {syllabusCovered?.CURRENT_CHAPTER}</p>
                                )
                            }
                        </div>
                    ) : (
                        <p style={{ marginTop: "5px", fontSize: "18px", fontWeight: "700", marginLeft: '50px' }}>None</p>
                    )
                }
            </div>
            <div style={{ marginTop: "20px", marginLeft: "100px" }} className='recentlyAdddedHW recentlyAdddedHead'>
                <h2>Completed Chapters</h2>
                <div style={{ marginLeft: "50px", marginTop: "10px" }}>
                    {
                        syllabusCovered?.CHAPTERS_COMPLETED?.length > 0 ? (
                            <>
                                {
                                    syllabusCovered?.CHAPTERS_COMPLETED.map((chapter, i) => (
                                        <p key={i} style={{ marginTop: "5px", fontSize: "18px", fontWeight: "700" }}>{i + 1}. {chapter}</p>
                                    ))
                                }
                            </>
                        ) : (
                            <p style={{ marginTop: "5px", fontSize: "18px", fontWeight: "700" }}>None</p>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default SyllabusCoveredSubjectwise