import React, { useEffect, useState } from 'react'
import '../Header/RecievedRequest.css'
// import dB from '../firebase'
import { doc, onSnapshot, serverTimestamp, writeBatch } from 'firebase/firestore'
import { Link } from 'react-router-dom'

import { Avatar } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import dB from '../../firebase'

function RecievedRequest(props) {

    const { reqAvatar, reqId, reqUserName, userId,
        userCurrentStatus, userPathCollection, userFullName } = props

    const [friendPathCollection, setfriendPathCollection] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (reqId) {
                onSnapshot(doc(dB, "USERS", reqId), (snap) => {
                    if (snap.exists()) {
                        setfriendPathCollection(snap.data()?.USER_NAME)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [reqId])

    var batch = writeBatch(dB)


    const [reqAccepted, setreqAccepted] = useState(false)
    const [reqRejected, setreqRejected] = useState(false)

    const [btnLoading, setbtnLoading] = useState(false)
    const handleAcceptRequest = async (e) => {
        e.preventDefault();
        setbtnLoading(true)


        batch.delete(doc(dB, "USERS", userId, userPathCollection, "REQUESTS_RECIEVED", "REQUESTS_RECIEVED_IDs", reqId));

        batch.delete(doc(dB, "USERS", reqId, friendPathCollection, "REQUESTS_SENT", "REQUESTS_SENT_IDs", userId));

        batch.set(doc(dB, "USERS", userId, userPathCollection, "CONNECTIONS", "CONNECTED_USERS", reqId), {
            TIMESTAMP: serverTimestamp()
        });

        batch.set(doc(dB, "USERS", reqId, friendPathCollection, "CONNECTIONS", "CONNECTED_USERS", userId), {
            TIMESTAMP: serverTimestamp()
        });

        await batch.commit().then(() => {
            setreqAccepted(true)
            setbtnLoading(false)

        }).catch(err => alert(err.message))
    }
    const handleRejectRequest = async (e) => {
        e.preventDefault();
        setbtnLoading(true)

        batch.delete(doc(dB, "USERS", userId, userPathCollection, "REQUESTS_RECIEVED", "REQUESTS_RECIEVED_IDs", reqId))

        batch.delete(doc(dB, "USERS", reqId, friendPathCollection, "REQUESTS_SENT", "REQUESTS_SENT_IDs", userId))

        await batch.commit().then(() => {
            setreqRejected(true)
            setbtnLoading(false)
        })
    }

    return (
        <div className='recievedrequest' >
            <div className='recievedrequest__left'>
                {/* avatar */}
                <Link to={`/friendsprofile/${reqId}`} style={{ textDecoration: 'none', color: 'black' }}>
                    <Avatar
                        className='recievedrequest__leftavatar'
                        src={reqAvatar}
                        alt={reqUserName}
                        sx={{ width: 40, height: 40 }}
                    />
                </Link>

                {/* name */}
                <h5>
                    <Link to={`/friendsprofile/${reqId}`} style={{ textDecoration: 'none', color: 'black' }}>
                        {reqUserName}
                    </Link>
                </h5>
            </div>
            {
                reqAccepted ? (
                    <>
                        <h3 style={{ fontSize: '14px', fontWeight: '700', color: 'green' }}>Accepted</h3>
                    </>
                ) : reqRejected ? (
                    <>
                        <h3 style={{ fontSize: '14px', fontWeight: '700', color: 'var(--connected-orange)' }}>Rejected</h3>
                    </>
                ) : (
                    <div className='recievedrequest__right'>
                        {/* buttons */}
                        {/* <Button disabled={btnDisabled.btnAccept} onClick={handleAcceptRequest}>Accept</Button> */}
                        <LoadingButton
                            onClick={handleAcceptRequest}
                            loading={btnLoading}
                            variant="outlined"
                        >
                            Accept
                        </LoadingButton>
                        {/* <Button disabled={btnDisabled.btnReject} onClick={handleRejectRequest}>Reject</Button> */}
                        <LoadingButton
                            onClick={handleRejectRequest}
                            loading={btnLoading}
                            variant="outlined"
                        >
                            Reject
                        </LoadingButton>
                    </div>
                )
            }

        </div>
    )
}

export default RecievedRequest
