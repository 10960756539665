import React, { useEffect, useState } from 'react'
import '../FIndToFollow/FindToFollowSchools.css'
import FindToFollowSchoolsCard from './FindToFollowSchoolsCard';
import { collection, doc, getDocs, limit, onSnapshot, query, startAfter } from 'firebase/firestore';
import { Autocomplete, Button, TextField } from '@mui/material';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import dB from '../../firebase';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function FindToFollowSchools(props) {
    const { userId, userPathCollection, userCurrentStatus } = props

    const [FindToFollowSchoolsCardData, setFindToFollowSchoolsCardData] = useState([]);
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            onSnapshot(query(collection(dB, "SCHOOLS"), limit(6)), (snap) => {
                setFindToFollowSchoolsCardData(snap.docs.map((schlDoc) => ({
                    schoolId: schlDoc.id,
                    schoolName: schlDoc.data()?.SCHOOL_FULL_NAME,
                    schoolImage: schlDoc.data()?.USER_DISPLAY_PIC
                })))
            })
        }
        return () => { aaa = false }
    }, [])

    const [btndisabled, setbtndisabled] = useState(false)
    const [FindToFollowSchoolsCardSet, setFindToFollowSchoolsCardSet] = useState([]);
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            onSnapshot(query(collection(dB, "SCHOOLS"), limit(6)), (snap) => {
                fetchMore(snap)
            })
        }
        return () => { aaa = false }
    }, [])
    const [lastVisible, setlastVisible] = useState()
    const fetchMore = (snap) => {
        let schoolsList = snap.docs.map((schlDOc) => ({
            schoolId: schlDOc.id,
            schoolName: schlDOc.data()?.SCHOOL_FULL_NAME,
            schoolImage: schlDOc.data()?.USER_DISPLAY_PIC
        }))
        setFindToFollowSchoolsCardSet(prev => { return [...prev, ...schoolsList] })
        var lastVisibleComment = snap.docs[snap.docs.length - 1];
        setlastVisible(lastVisibleComment)
    }
    const [totalSchools, settotalSchools] = useState()
    const loadMoreSchools = async (FindToFollowSchoolsCardSetLength) => {
        settotalSchools(FindToFollowSchoolsCardSetLength)
        setbtndisabled(true)

        const q = query(collection(dB, "SCHOOLS"), startAfter(lastVisible), limit(6))
        const snap = await getDocs(q).catch(err => alert(err.message))
        fetchMore(snap)
        setbtndisabled(false)

    }



    const [open, setOpen] = useState(false);
    const handleClickOpen = (e) => { e.preventDefault(); setOpen(true); };
    const handleClose = (e) => { e.preventDefault(); setOpen(false); };

    const [schoolToSearch, setschoolToSearch] = useState('')
    const [schoolToSearchInputValue, setschoolToSearchInputValue] = useState('')

    const [searchOptions, setsearchOptions] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            onSnapshot(collection(dB, "SCHOOLS"), (snap) => {
                setsearchOptions(snap.docs.map((SchlDOc) => ({
                    schoolId: SchlDOc.id,
                    schoolName: SchlDOc.data()?.SCHOOL_FULL_NAME,
                    schoolDP: SchlDOc.data()?.USER_DISPLAY_PIC
                })))
            })
        }
        return () => { aaa = false }
    }, [])

    const [searchOptionId, setsearchOptionId] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolToSearch) {
                searchOptions.map(option => {
                    if (option.schoolName === schoolToSearch) {
                        setsearchOptionId(option.schoolId)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [schoolToSearch, searchOptions])

    const [selectedSchoolData, setselectedSchoolData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (searchOptionId) {
                onSnapshot(doc(dB, "SCHOOLS", searchOptionId), (snap) => {
                    if (snap.exists()) {
                        setselectedSchoolData(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [searchOptionId])

    return (
        <div className='FindToFollowSchools'>
            {
                FindToFollowSchoolsCardData.map((cardData, i) => (
                    <FindToFollowSchoolsCard
                        key={i}
                        userId={userId}
                        userPathCollection={userPathCollection}
                        userCurrentStatus={userCurrentStatus}
                        schoolName={cardData.schoolName}
                        schoolImage={cardData.schoolImage}
                        schoolId={cardData.schoolId}
                    />
                ))
            }

            {
                FindToFollowSchoolsCardData.length > 5 && (
                    <h2 onClick={handleClickOpen}>See<br />More</h2>
                )
            }


            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <div className='seeAllSchools'>
                    <div className='seeAllSchoolsTitleSearch'>
                        <h3>Schools to Follow</h3>
                        <Autocomplete
                            value={schoolToSearch}
                            onChange={(event, newValue) => {
                                event.preventDefault()
                                setschoolToSearch(newValue);
                            }}
                            inputValue={schoolToSearchInputValue}
                            onInputChange={(event, newInputValue) => {
                                setschoolToSearchInputValue(newInputValue);
                            }}
                            id="controllable-states-demo"
                            options={searchOptions.map((option) => option.schoolName)}
                            renderInput={(params) =>
                                <TextField
                                    id="schoolToSearchName"
                                    name='schoolToSearchName'
                                    {...params}
                                    margin="normal"
                                    label="Search schools here.."
                                    variant="outlined"
                                />
                            }
                        />
                    </div>
                    <hr />
                    <div >
                        {
                            selectedSchoolData !== null ? (
                                <>
                                    <FindToFollowSchoolsCard
                                        userId={userId}
                                        userPathCollection={userPathCollection}
                                        userCurrentStatus={userCurrentStatus}
                                        schoolName={selectedSchoolData?.SCHOOL_FULL_NAME}
                                        schoolImage={selectedSchoolData?.USER_DISPLAY_PIC}
                                        schoolId={searchOptionId}
                                    />
                                </>
                            ) : (
                                <div className='showMoreSchools'>
                                    <div className='schoolsAllFollow'>
                                        {
                                            FindToFollowSchoolsCardSet.map((listData, i) => (
                                                <FindToFollowSchoolsCard
                                                    key={i}
                                                    userId={userId}
                                                    userPathCollection={userPathCollection}
                                                    userCurrentStatus={userCurrentStatus}
                                                    schoolName={listData?.schoolName}
                                                    schoolImage={listData?.schoolImage}
                                                    schoolId={listData?.schoolId}
                                                />
                                            ))
                                        }
                                    </div>
                                    {
                                        totalSchools !== FindToFollowSchoolsCardSet.length ? (
                                            <div className='ButtonSeeMore'>
                                                <Button onClick={(e) => { e.preventDefault(); loadMoreSchools(FindToFollowSchoolsCardSet.length) }} disabled={btndisabled}>show more..</Button>
                                            </div>
                                        ) : (
                                            <div className='noMoreSchools'>
                                                <h4>No more schools to show...</h4>
                                            </div>
                                        )
                                    }
                                </div>

                            )
                        }
                    </div>
                </div>
            </BootstrapDialog>
        </div>
    )
}

export default FindToFollowSchools