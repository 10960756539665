import React, { useEffect, useState } from 'react'
import '../Sidebars/HomePageSidebar.css'
import HomePageEducationSidebar from './HomePageEducationSidebar';
import { doc, onSnapshot } from 'firebase/firestore';
import dB from '../../firebase';

function HomePageSidebarTeacher(props) {

    const { userId, userPathCollection, userCurrentStatus, teacherSchoolData } = props

    const [schoolData, setschoolData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (teacherSchoolData?.USER_SCHOOL_ID) {
                onSnapshot(doc(dB, "SCHOOLS", teacherSchoolData?.USER_SCHOOL_ID), (teachDoc) => {
                    if (teachDoc.exists()) {
                        setschoolData(teachDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [teacherSchoolData?.USER_SCHOOL_ID])

    return (
        <div className='HomePageSidebarTeacher'>

            {/* <div style={{ display: showBlock === 'show' ? 'none' : 'flex' }}> */}
            {/* educational info */}
            <HomePageEducationSidebar
                userCurrentStatus={userCurrentStatus}
                schoolId={teacherSchoolData?.USER_SCHOOL_ID}
                schoolPathCollection={schoolData?.SCHOOL_NAME}
                userId={userId}
            />
            {/* </div> */}

        </div>
    )
}

export default HomePageSidebarTeacher
