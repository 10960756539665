import React, { useEffect, useState } from 'react'
// import '../Components/UserProfileTop.css'
import '../Social/UserProfileTop.css'

// import dB, { storage } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import {
    Avatar, Box, Button, Dialog, FormControl, FormControlLabel, FormLabel, IconButton,
    InputLabel, MenuItem, Modal, OutlinedInput, Radio, RadioGroup, Select, TextField
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import dB, { storage } from '../../firebase';

const imageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
};

function UserProfileTop(props) {
    const { userId, userCurrentStatus, userPathCollection, userName, userBio, userDOB, userGender,
        userMobNo, userEmail, userDegree1, userDegree2, userDegree3, userAchievements, friendId,
        friendCurrentStatus, userFName, userLName, userStatuses, userDP,
        friendPathCollection } = props

    // const classes = useStyles();

    const [progress, setprogress] = useState(0);

    const UgDegrees = ['B.A.', 'B.Sc.', 'B.Com.', 'B.E.', 'B.Tech.', 'BBA', 'BCA.', 'BCS', 'LLB', 'Other Bachelors']
    const PgDegrees = ['Ph.D.', 'M.A.', 'M.Sc.', 'M.Com.', 'M.E.', 'M.Tech.', 'MBA', 'MCA.', 'MCS', 'Other Masters']
    const passYear = ['2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010', '2009', '2008', '2007', '2006', '2005', '2004', '2003', '2002', '2001', '2000', '1999', '1998', '1997', '1996', '1995', '1994', '1993', '1992', '1991', '1990', '1989', '1988', '1987', '1986', '1985', '1984', '1983', '1982', '1981', '1980', '1979', '1978', '1977', '1976', '1975', '1974', '1973', '1972', '1971', '1970', '1969', '1968', '1967', '1966', '1965', '1964', '1963', '1962', '1961']

    const [open, setOpen] = useState(false);
    const handleClickOpen = (e) => { e.preventDefault(); setOpen(true); };
    const handleClose = (e) => { e.preventDefault(); setOpen(false); };

    const [userDetails, setuserDetails] = useState({
        userGender: '', userDOB: '', userBio: '', userMobNo: '', userEmailId: '', FName: '', LName: ''
    })
    const handleChangeUserDetails = (e) => {
        e.preventDefault();
        setuserDetails(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userDP) {
                setImgData(userDP)
            }
        }
        return () => { aaa = false }
    }, [userDP])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userBio !== '' && userBio !== undefined && userBio !== null) {
                setuserDetails(prev => { return { ...prev, userBio: userBio } })
            }
        }
        return () => { aaa = false }
    }, [userBio])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userGender !== '' && userGender !== undefined && userGender !== null) {
                setuserDetails(prev => { return { ...prev, userGender: userGender } })
            }
        }
        return () => { aaa = false }
    }, [userGender])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userMobNo !== '' && userMobNo !== undefined && userMobNo !== null) {
                setuserDetails(prev => { return { ...prev, userMobNo: userMobNo } })
            }
        }
        return () => { aaa = false }
    }, [userMobNo])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userFName !== '' && userFName !== undefined && userFName !== null) {
                setuserDetails(prev => { return { ...prev, FName: userFName } })
            }
        }
        return () => { aaa = false }
    }, [userFName])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userLName !== '' && userLName !== undefined && userLName !== null) {
                setuserDetails(prev => { return { ...prev, LName: userLName } })
            }
        }
        return () => { aaa = false }
    }, [userLName])

    const [eduQualInfo, seteduQualInfo] = useState({
        degree1: '', specialization1: '', paaYear1: '', university1: '',
        degree2: '', specialization2: '', paaYear2: '', university2: '',
        degree3: '', specialization3: '', paaYear3: '', university3: '',
    })
    const handleChangeDegSpeYUni = (e) => {
        e.preventDefault()
        seteduQualInfo(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userDegree1) {
                seteduQualInfo(prev => {
                    return {
                        ...prev,
                        degree1: userDegree1?.USER_QUAL_DEGREE,
                        specialization1: userDegree1?.USER_QUAL_SPECIALIZATION,
                        paaYear1: userDegree1?.USER_QUAL_PASSOUT_YEAR,
                        university1: userDegree1?.USER_QUAL_UNIVERSITY,
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userDegree1])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userDegree2) {
                seteduQualInfo(prev => {
                    return {
                        ...prev,
                        degree2: userDegree2?.USER_QUAL_DEGREE,
                        specialization2: userDegree2?.USER_QUAL_SPECIALIZATION,
                        paaYear2: userDegree2?.USER_QUAL_PASSOUT_YEAR,
                        university2: userDegree2?.USER_QUAL_UNIVERSITY,
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userDegree2])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userDegree3) {
                seteduQualInfo(prev => {
                    return {
                        ...prev,
                        degree3: userDegree3?.USER_QUAL_DEGREE,
                        specialization3: userDegree3?.USER_QUAL_SPECIALIZATION,
                        paaYear3: userDegree3?.USER_QUAL_PASSOUT_YEAR,
                        university3: userDegree3?.USER_QUAL_UNIVERSITY,
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userDegree3])

    const [fieldDisabled, setfieldDisabled] = useState({
        specialization1: true, paaYear1: true, university1: true,
        specialization2: true, paaYear2: true, university2: true,
        specialization3: true, paaYear3: true, university3: true,
    })

    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (eduQualInfo.degree1 !== '') {
                setfieldDisabled(prev => { return { ...prev, specialization1: false, paaYear1: false, university1: false, } })
            } else {
                setfieldDisabled(prev => { return { ...prev, specialization1: true, paaYear1: true, university1: true, } })
                seteduQualInfo(prev => { return { ...prev, specialization1: '', paaYear1: '', university1: '', } })
            }
        }
        return () => { aaa = false }
    }, [eduQualInfo.degree1])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (eduQualInfo.degree2 !== '') {
                setfieldDisabled(prev => { return { ...prev, specialization2: false, paaYear2: false, university2: false, } })
            } else {
                setfieldDisabled(prev => { return { ...prev, specialization2: true, paaYear2: true, university2: true, } })
                seteduQualInfo(prev => { return { ...prev, specialization2: '', paaYear2: '', university2: '', } })
            }
        }
        return () => { aaa = false }
    }, [eduQualInfo.degree2])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (eduQualInfo.degree3 !== '') {
                setfieldDisabled(prev => { return { ...prev, specialization3: false, paaYear3: false, university3: false, } })
            } else {
                setfieldDisabled(prev => { return { ...prev, specialization3: true, paaYear3: true, university3: true, } })
                seteduQualInfo(prev => { return { ...prev, specialization3: '', paaYear3: '', university3: '', } })
            }
        }
        return () => { aaa = false }
    }, [eduQualInfo.degree3])

    const [awardsNAchievements, setawardsNAchievements] = useState({
        awaNAchieve1: '', awaNAchieve2: '', awaNAchieve3: '', awaNAchieve4: '', awaNAchieve5: '',
    })
    const handleChangeAwardNAchieve = (e) => {
        e.preventDefault()
        setawardsNAchievements(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userAchievements?.length > 0) {
                setawardsNAchievements(prev => {
                    return {
                        ...prev,
                        awaNAchieve1: userAchievements[0] !== undefined ? userAchievements[0] : '',
                        awaNAchieve2: userAchievements[1] !== undefined ? userAchievements[1] : '',
                        awaNAchieve3: userAchievements[2] !== undefined ? userAchievements[2] : '',
                        awaNAchieve4: userAchievements[3] !== undefined ? userAchievements[3] : '',
                        awaNAchieve5: userAchievements[4] !== undefined ? userAchievements[4] : '',
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userAchievements])

    const [disableButtons, setdisableButtons] = useState({
        image: false, bio: false, mobNo: false, emailId: false, birthDay: false,
        gender: false, qualifications: false, achievements: false, FNameLName: false
    })

    const [updatedText, setupdatedText] = useState({
        image: false, bio: false, mobNo: false, emailId: false, birthDay: false,
        gender: false, qualifications: false, achievements: false, FNameLName: false
    })

    const handleUpdateBio = async (e) => {
        e.preventDefault();
        setdisableButtons(prev => { return { ...prev, bio: true } })
        const q = doc(dB, "USERS", userId, userPathCollection, "SOCIAL_INFO")
        const snap = await getDoc(q).catch(err => alert(err.message))
        if (snap.exists()) {
            updateDoc(doc(dB, "USERS", userId, userPathCollection, "SOCIAL_INFO"), {
                USER_BIO: userDetails.userBio,
            }).then(() => {
                setuserDetails(prev => { return { ...prev, userBio: '' } })
                setdisableButtons(prev => { return { ...prev, bio: true } })
                setupdatedText(prev => { return { ...prev, bio: true } })
            }).catch(err => alert(err.message))
        } else {
            setDoc(doc(dB, "USERS", userId, userPathCollection, "SOCIAL_INFO"), {
                USER_BIO: userDetails.userBio,
            }).then(() => {
                setuserDetails(prev => { return { ...prev, userBio: '' } })
                setdisableButtons(prev => { return { ...prev, bio: true } })
                setupdatedText(prev => { return { ...prev, bio: true } })
            }).catch(err => alert(err.message))
        }
    }
    const handleUpdateMobNo = async (e) => {
        e.preventDefault();
        setdisableButtons(prev => { return { ...prev, mobNo: true } })

        const q = doc(dB, "USERS", userId, userPathCollection, "REGISTRATION_INFO")
        const snap = await getDoc(q).catch(err => alert(err.message))
        if (snap.exists()) {
            updateDoc(doc(dB, "USERS", userId, userPathCollection, "REGISTRATION_INFO"), {
                USER_MOB_NO: userDetails.userMobNo,
            }).then(() => {
                setuserDetails(prev => { return { ...prev, userMobNo: '', } })
                setdisableButtons(prev => { return { ...prev, mobNo: true } })
                setupdatedText(prev => { return { ...prev, mobNo: true } })
            }).catch(err => alert(err.message))
        } else {
            setDoc(doc(dB, "USERS", userId, userPathCollection, "REGISTRATION_INFO"), {
                USER_MOB_NO: userDetails.userMobNo,
            }).then(() => {
                setuserDetails(prev => { return { ...prev, userMobNo: '', } })
                setdisableButtons(prev => { return { ...prev, mobNo: true } })
                setupdatedText(prev => { return { ...prev, mobNo: true } })
            }).catch(err => alert(err.message))
        }
    }
    const handleUpdateBirthDay = async (e) => {
        e.preventDefault();
        setdisableButtons(prev => { return { ...prev, birthDay: true } })

        const q = doc(dB, "USERS", userId, userPathCollection, "REGISTRATION_INFO")
        const snap = await getDoc(q).catch(err => alert(err.message))
        if (snap.exists()) {
            updateDoc(doc(dB, "USERS", userId, userPathCollection, "REGISTRATION_INFO"), {
                USER_DOB: userDetails.userDOB,
            }).then(() => {
                setuserDetails(prev => { return { ...prev, userDOB: '', } })
                setdisableButtons(prev => { return { ...prev, birthDay: true } })
                setupdatedText(prev => { return { ...prev, birthDay: true } })
            }).catch(err => alert(err.message))
        } else {
            setDoc(doc(dB, "USERS", userId, userPathCollection, "REGISTRATION_INFO"), {
                USER_DOB: userDetails.userDOB,
            }).then(() => {
                setuserDetails(prev => { return { ...prev, userDOB: '', } })
                setdisableButtons(prev => { return { ...prev, birthDay: true } })
                setupdatedText(prev => { return { ...prev, birthDay: true } })
            }).catch(err => alert(err.message))
        }
    }
    const handleUpdateGender = async (e) => {
        e.preventDefault();
        setdisableButtons(prev => { return { ...prev, gender: true } })

        const q = doc(dB, "USERS", userId, userPathCollection, "REGISTRATION_INFO")
        const snap = await getDoc(q).catch(err => alert(err.message))
        if (snap.exists()) {
            updateDoc(doc(dB, "USERS", userId, userPathCollection, "REGISTRATION_INFO"), {
                USER_GENDER: userDetails.userGender,
            }).then(() => {
                setuserDetails(prev => { return { ...prev, userGender: '', } })
                setdisableButtons(prev => { return { ...prev, gender: true } })
                setupdatedText(prev => { return { ...prev, gender: true } })
            }).catch(err => alert(err.message))
        } else {
            setDoc(doc(dB, "USERS", userId, userPathCollection, "REGISTRATION_INFO"), {
                USER_GENDER: userDetails.userGender,
            }).then(() => {
                setuserDetails(prev => { return { ...prev, userGender: '', } })
                setdisableButtons(prev => { return { ...prev, gender: true } })
                setupdatedText(prev => { return { ...prev, gender: true } })
            }).catch(err => alert(err.message))
        }
    }
    const handleUpdateQualification = async (e) => {
        e.preventDefault();
        setdisableButtons(prev => { return { ...prev, qualifications: true } })

        const userDegree1 = {
            USER_QUAL_DEGREE: eduQualInfo.degree1,
            USER_QUAL_PASSOUT_YEAR: eduQualInfo.paaYear1,
            USER_QUAL_SPECIALIZATION: eduQualInfo.specialization1,
            USER_QUAL_UNIVERSITY: eduQualInfo.university1
        }
        const userDegree2 = {
            USER_QUAL_DEGREE: eduQualInfo.degree2,
            USER_QUAL_PASSOUT_YEAR: eduQualInfo.paaYear2,
            USER_QUAL_SPECIALIZATION: eduQualInfo.specialization2,
            USER_QUAL_UNIVERSITY: eduQualInfo.university2
        }
        const userDegree3 = {
            USER_QUAL_DEGREE: eduQualInfo.degree3,
            USER_QUAL_PASSOUT_YEAR: eduQualInfo.paaYear3,
            USER_QUAL_SPECIALIZATION: eduQualInfo.specialization3,
            USER_QUAL_UNIVERSITY: eduQualInfo.university3
        }

        const q = doc(dB, "USERS", userId, userPathCollection, "SOCIAL_INFO")
        const snap = await getDoc(q).catch(err => alert(err.message))
        if (snap.exists()) {
            updateDoc(doc(dB, "USERS", userId, userPathCollection, "SOCIAL_INFO"), {
                USER_DEGREE_1: userDegree1,
                USER_DEGREE_2: userDegree2,
                USER_DEGREE_3: userDegree3,
            }).then(() => {
                seteduQualInfo(prev => {
                    return {
                        ...prev, degree1: '', specialization1: '', paaYear1: '', university1: '',
                        degree2: '', specialization2: '', paaYear2: '', university2: '',
                        degree3: '', specialization3: '', paaYear3: '', university3: '',
                    }
                })
                setdisableButtons(prev => { return { ...prev, qualifications: true } })
                setupdatedText(prev => { return { ...prev, qualifications: true } })
            }).catch(err => alert(err.message))
        } else {
            setDoc(doc(dB, "USERS", userId, userPathCollection, "SOCIAL_INFO"), {
                USER_DEGREE_1: userDegree1,
                USER_DEGREE_2: userDegree2,
                USER_DEGREE_3: userDegree3,
            }).then(() => {
                seteduQualInfo(prev => {
                    return {
                        ...prev, degree1: '', specialization1: '', paaYear1: '', university1: '',
                        degree2: '', specialization2: '', paaYear2: '', university2: '',
                        degree3: '', specialization3: '', paaYear3: '', university3: '',
                    }
                })
                setdisableButtons(prev => { return { ...prev, qualifications: true } })
                setupdatedText(prev => { return { ...prev, qualifications: true } })
            }).catch(err => alert(err.message))
        }
    }
    const handleUpdateAchievements = async (e) => {
        e.preventDefault();
        setdisableButtons(prev => { return { ...prev, achievements: true } })

        const abc = []
        if (awardsNAchievements.awaNAchieve1 !== '') { abc.push(awardsNAchievements.awaNAchieve1) }
        if (awardsNAchievements.awaNAchieve2 !== '') { abc.push(awardsNAchievements.awaNAchieve2) }
        if (awardsNAchievements.awaNAchieve3 !== '') { abc.push(awardsNAchievements.awaNAchieve3) }
        if (awardsNAchievements.awaNAchieve4 !== '') { abc.push(awardsNAchievements.awaNAchieve4) }
        if (awardsNAchievements.awaNAchieve5 !== '') { abc.push(awardsNAchievements.awaNAchieve5) }

        const q = doc(dB, "USERS", userId, userPathCollection, "SOCIAL_INFO")
        const snap = await getDoc(q).catch(err => alert(err.message))
        if (snap.exists()) {
            updateDoc(doc(dB, "USERS", userId, userPathCollection, "SOCIAL_INFO"), {
                USER_ACHIEVEMENTS: abc,
            }).then(() => {
                setdisableButtons(prev => { return { ...prev, achievements: true } })
                setupdatedText(prev => { return { ...prev, achievements: true } })
                setawardsNAchievements(prev => { return { ...prev, awaNAchieve1: '', awaNAchieve2: '', awaNAchieve3: '', awaNAchieve4: '', awaNAchieve5: '', } })
            }).catch(err => alert(err.message))
        } else {
            setDoc(doc(dB, "USERS", userId, userPathCollection, "SOCIAL_INFO"), {
                USER_ACHIEVEMENTS: abc,
            }).then(() => {
                setdisableButtons(prev => { return { ...prev, achievements: true } })
                setupdatedText(prev => { return { ...prev, achievements: true } })
                setawardsNAchievements(prev => { return { ...prev, awaNAchieve1: '', awaNAchieve2: '', awaNAchieve3: '', awaNAchieve4: '', awaNAchieve5: '', } })
            }).catch(err => alert(err.message))
        }
    }
    const handleUpdateFNameLName = async (e) => {
        e.preventDefault();
        setdisableButtons(prev => { return { ...prev, FNameLName: true } })

        const newUserName = userDetails.FName + ' ' + userDetails.LName

        updateDoc(doc(dB, "USERS", userId, userPathCollection, "REGISTRATION_INFO"), {
            USER_NAME: newUserName,
            F_NAME: userDetails.FName,
            L_NAME: userDetails.LName,
        }).then(() => {
            setuserDetails(prev => { return { ...prev, FName: '', LName: '' } })
            setdisableButtons(prev => { return { ...prev, FNameLName: true } })
            setupdatedText(prev => { return { ...prev, FNameLName: true } })
        }).catch(err => alert(err.message))
    }

    const [randomKey, setrandomKey] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var a = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
            setrandomKey(a)
        }
        return () => { aaa = false }
    }, [])
    var resizeImage = function (settings) {
        var file = settings.file;
        var maxSize = settings.maxSize;
        var reader = new FileReader();
        var image = new Image();
        var canvas = document.createElement('canvas');
        var dataURItoBlob = function (dataURI) {
            var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
                atob(dataURI.split(',')[1]) :
                unescape(dataURI.split(',')[1]);
            var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var max = bytes.length;
            var ia = new Uint8Array(max);
            for (var i = 0; i < max; i++)
                ia[i] = bytes.charCodeAt(i);
            return new Blob([ia], { type: mime });
        };
        var resize = function () {
            var width = image.width;
            var height = image.height;
            if (width > height) {
                if (width > maxSize) {
                    height *= maxSize / width;
                    width = maxSize;
                }
            } else {
                if (height > maxSize) {
                    width *= maxSize / height;
                    height = maxSize;
                }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            var dataUrl = canvas.toDataURL('image/jpeg');
            return dataURItoBlob(dataUrl);
        };
        return new Promise(function (ok, no) {
            if (!file.type.match(/image.*/)) {
                no(new Error("Not an image"));
                return;
            }
            reader.onload = function (readerEvent) {
                image.onload = function () { return ok(resize()); };
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        });
    };
    const [imgData, setImgData] = useState(null);
    const [picture, setPicture] = useState(null);
    const onChangePicture = async (e) => {
        if (e.target.files[0]) {
            setPicture(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);

            const config = {
                file: e.target.files[0],
                maxSize: 500
            };
            const resizedImage = await resizeImage(config)
            setPicture(resizedImage);
        }
    };
    const handleUpdateDisplayPic = async (e) => {
        e.preventDefault()
        if (picture) {
            setdisableButtons(prev => { return { ...prev, image: true } })
            // const uploadTask = storage.ref(`USERS/${userId}/Profile/${randomKey}`).put(picture);
            // uploadTask.on("state_changed",
            //     (snapshot) => {
            //         const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            //         setprogress(progress);
            //     },
            //     (error) => { },
            //     () => {
            //         storage
            //             .ref(`USERS/${userId}/Profile`)
            //             .child(randomKey)
            //             .getDownloadURL()
            //             .then(async url => {
            //                 updateDoc(doc(dB, "USERS", userId), {
            //                     USER_DISPLAY_PIC: url,
            //                 }).then(() => {
            //                     
            //                 })
            //                 // dB.collection('USERS').doc(userId)
            //                 //     .update({

            //                 //     })
            //             }).catch(err => alert(err.message))
            //     })

            const imageRef = ref(storage, `USERS/${userId}/Profile/${randomKey}`);
            const metadata = {
                contentType: "image/jpeg",
            };
            await uploadBytes(imageRef, picture, metadata)
                .then(async (snapshot) => {

                    const downloadURL = await getDownloadURL(imageRef);
                    await updateDoc(doc(dB, "USERS", userId), {
                        USER_DISPLAY_PIC: downloadURL,
                    }).then(() => {
                        setdisableButtons(prev => { return { ...prev, image: true } })
                        setupdatedText(prev => { return { ...prev, image: true } })
                        setImgData(null)
                        setPicture(null)
                        setprogress(0);
                        var b = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
                        setrandomKey(b)
                    }).catch(err => alert(err))
                })
        }
    }

    const [openImage, setOpenImage] = React.useState(false);
    const handleOpenImage = () => setOpenImage(true);
    const handleCloseImage = () => setOpenImage(false);

    return (
        <div className='userprofiletop'>
            <div className='userprofiletop__image'>
                <Box id='avtarNameProfileTop' sx={{ display: 'flex', '& > *': { margin: 1 } }}>
                    <Avatar
                        alt={userName}
                        src={userDP}
                        // className={classes.large}
                        onClick={handleOpenImage}
                        sx={{ width: 150, height: 150 }}
                    />
                    <Modal
                        open={openImage}
                        onClose={handleCloseImage}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={imageStyle}>
                            <div className='profileImageBig'>
                                <img src={userDP} alt={userName} />
                            </div>
                        </Box>
                    </Modal>
                    <div id='avtarNameProfileName'>
                        <h5 className='userprofiletop__infoName'>{userName}</h5>
                        <div className='userprofilenavbar__rightButton'>
                            <Button color="primary" onClick={handleClickOpen}>Edit Profile</Button>
                        </div>
                    </div>
                </Box>
            </div>
            <div className='userprofiletop__info'>
                <div className='userprofilenavbar__right'>
                    <div className='userprofilenavbar__right'>

                        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                            <div className='dialogEditProfile'>
                                <h2>Update Profile Information</h2>
                                {/* Display Pic */}
                                <div className='editDisplayPic'>
                                    <Avatar
                                        alt={userName}
                                        src={imgData}
                                        sx={{ width: 130, height: 130, }}
                                    // className={classes.large}
                                    />
                                    {/* <div className='editUpdateButton imageButtons'> */}
                                    {/* <input
                                        type="file"
                                        
                                        accept='.jpg, .jpeg, .png'
                                    /> */}
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="label"
                                    >
                                        <input
                                            hidden
                                            type="file"
                                            id="postImage"
                                            name="postImage"
                                            accept=".jpg,.jpeg,.png,"
                                            // multiple={userCurrentStatus === 'SCHOOL' ? true : false}
                                            onChange={onChangePicture}
                                        />
                                        <PhotoCamera sx={{ fontSize: "30px", color: 'lightblue' }} />
                                        <h3 style={{
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            color: "grey"
                                        }}>Select Photo</h3>
                                    </IconButton>
                                    <Button disabled={disableButtons.image} onClick={handleUpdateDisplayPic}>Update</Button>
                                    {/* </div> */}
                                    {/* <progress
                                        className='imageUpload__progress'
                                        value={progress}
                                        max='100'
                                    /> */}
                                    {updatedText.image && (<h2>Profile Pic updated..</h2>)}

                                </div>


                                {/* User Name */}
                                <div className='dialogEditUserName'>
                                    <div className='dialogEditUserNameBox'>
                                        <FormControl name='FName' sx={{ width: '240px', marginRight: '5px' }} variant="outlined">
                                            <InputLabel htmlFor="FName">First Name</InputLabel>
                                            <OutlinedInput
                                                id="FName"
                                                type='text'
                                                name='FName'
                                                value={userDetails.FName}
                                                onChange={handleChangeUserDetails}
                                                label='First Name'
                                            />
                                        </FormControl>
                                        <FormControl name='LName' sx={{ width: '240px', marginRight: '5px' }} variant="outlined">
                                            <InputLabel htmlFor="LName">Last Name</InputLabel>
                                            <OutlinedInput
                                                id="LName"
                                                type='text'
                                                name='LName'
                                                value={userDetails.LName}
                                                onChange={handleChangeUserDetails}
                                                label='Last Name'
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='editUpdateButton'>
                                        <Button disabled={disableButtons.FNameLName} onClick={handleUpdateFNameLName}>Update Name</Button>
                                    </div>
                                    {updatedText.FNameLName && (<h2>Name updated..</h2>)}

                                </div>

                                {/* Bio */}
                                <div className='dialogEditProfileBio'>
                                    <TextField
                                        name='userBio'
                                        value={userDetails.userBio}
                                        onChange={handleChangeUserDetails}
                                        id="outlined-multiline-static"
                                        label="Bio / About Yourself (Max 300 Characters)"
                                        multiline
                                        minRows={3}
                                        maxRows={5}
                                        variant={'outlined'}
                                        inputProps={{ maxLength: 300 }}
                                    />
                                    <div className='editUpdateButton'>
                                        <Button disabled={disableButtons.bio} onClick={handleUpdateBio}>Update Bio</Button>
                                    </div>
                                    {updatedText.bio && (<h2>Bio updated..</h2>)}

                                </div>

                                {/* Mob No */}
                                <div className='dialogEditProfileMobNo'>
                                    <TextField
                                        id="outlined-number"
                                        label="Mobile Number"
                                        type="number"
                                        variant={'outlined'}
                                        name='userMobNo'
                                        value={userDetails.userMobNo}
                                        onChange={handleChangeUserDetails}
                                    />
                                    <div className='editUpdateButton'>
                                        <Button disabled={disableButtons.mobNo} onClick={handleUpdateMobNo}>Update Mobile No</Button>
                                    </div>
                                    {updatedText.mobNo && (<h2>Mobile No. updated..</h2>)}

                                </div>

                                {/* DOB */}
                                <div className='editBirthday'>
                                    <div className='editBirthdayInput' >
                                        <h5>Date of Birth : </h5>
                                        <input type='date' id="birthdate" name="userDOB" value={userDetails.userDOB} onChange={handleChangeUserDetails} />
                                    </div>
                                    <div className='editUpdateButton'>
                                        <Button disabled={disableButtons.birthDay} onClick={handleUpdateBirthDay}>Update Birthday</Button>
                                    </div>
                                    {updatedText.birthDay && (<h2>Birthday date updated..</h2>)}

                                </div>

                                {/* gender */}
                                <div className='editgender'>
                                    <FormControl component="fieldset">
                                        <div className='editgenderOptions'>
                                            <FormLabel component="legend">Gender : </FormLabel>
                                            <RadioGroup aria-label="userGender" name="userGender" value={userDetails.userGender} onChange={handleChangeUserDetails}>
                                                <div className='editgenderFields'>
                                                    <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                                    <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                                    <FormControlLabel value="Others" control={<Radio />} label="Others" />
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </FormControl>
                                    <div className='editUpdateButton'>
                                        <Button disabled={disableButtons.gender} onClick={handleUpdateGender}>Update Gender</Button>
                                    </div>
                                    {updatedText.gender && (<h2>Gender updated..</h2>)}

                                </div>


                                <h2 style={{ display: userStatuses.length === 1 && userStatuses.includes('STUDENT') ? 'none' : 'flex' }}>Educational Details</h2>

                                {/* Education Qualifications */}
                                <div style={{ display: userStatuses.length === 1 && userStatuses.includes('STUDENT') ? 'none' : 'flex' }} className='editQualificationMain'>
                                    <div className='editQualification'>
                                        {/* degree */}
                                        <div className='editQualificationDegree'>
                                            <FormControl variant={'outlined'}>
                                                <InputLabel id="demo-simple-select-label">Degree</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={eduQualInfo.degree1}
                                                    label="Degree"
                                                    onChange={handleChangeDegSpeYUni}
                                                    name='degree1'
                                                >
                                                    <MenuItem value=''>{''}</MenuItem>
                                                    {PgDegrees.map(deg => (<MenuItem key={deg} value={deg}>{deg}</MenuItem>))}
                                                    {UgDegrees.map(deg => (<MenuItem key={deg} value={deg}>{deg}</MenuItem>))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {/* specialization */}
                                        <div className='editQualificationSpecialization'>
                                            <TextField
                                                name='specialization1'
                                                id="outlined-basic"
                                                label="Specialization"
                                                variant="outlined"
                                                value={eduQualInfo.specialization1}
                                                onChange={handleChangeDegSpeYUni}
                                                disabled={fieldDisabled.specialization1}
                                            />
                                        </div>
                                        {/* passout year */}
                                        <div className='editQualificationPassout'>
                                            <FormControl variant={'outlined'} disabled={fieldDisabled.paaYear1}>
                                                <InputLabel id="demo-simple-select-label">Pass.Year</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={eduQualInfo.paaYear1}
                                                    label="Pass.Year"
                                                    onChange={handleChangeDegSpeYUni}
                                                    name='paaYear1'
                                                >
                                                    {passYear.map(deg => (<MenuItem key={deg} value={deg}>{deg}</MenuItem>))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {/* university */}
                                        <div className='editQualificationUniversity'>
                                            <TextField
                                                id="outlined-basic"
                                                label="University"
                                                variant="outlined"
                                                value={eduQualInfo.university1}
                                                name='university1'
                                                onChange={handleChangeDegSpeYUni}
                                                disabled={fieldDisabled.university1}
                                            />
                                        </div>
                                    </div>

                                    <div className='editQualification'>
                                        {/* degree */}
                                        <div className='editQualificationDegree'>
                                            <FormControl variant={'outlined'}>
                                                <InputLabel id="demo-simple-select-label">Degree</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={eduQualInfo.degree2}
                                                    label="Degree"
                                                    onChange={handleChangeDegSpeYUni}
                                                    name='degree2'
                                                >
                                                    <MenuItem value=''>{''}</MenuItem>
                                                    {PgDegrees.map(deg => (<MenuItem key={deg} value={deg}>{deg}</MenuItem>))}
                                                    {UgDegrees.map(deg => (<MenuItem key={deg} value={deg}>{deg}</MenuItem>))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {/* specialization */}
                                        <div className='editQualificationSpecialization'>
                                            <TextField
                                                id="outlined-basic"
                                                label="Specialization"
                                                variant="outlined"
                                                value={eduQualInfo.specialization2}
                                                name='specialization2'
                                                onChange={handleChangeDegSpeYUni}
                                                disabled={fieldDisabled.specialization2}
                                            />
                                        </div>
                                        {/* passout year */}
                                        <div className='editQualificationPassout' >
                                            <FormControl variant={'outlined'} disabled={fieldDisabled.paaYear2}>
                                                <InputLabel id="demo-simple-select-label">Pass.Year</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={eduQualInfo.paaYear2}
                                                    label="Pass.Year"
                                                    onChange={handleChangeDegSpeYUni}
                                                    name='paaYear2'
                                                >
                                                    {passYear.map(deg => (<MenuItem key={deg} value={deg}>{deg}</MenuItem>))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {/* university */}
                                        <div className='editQualificationUniversity'>
                                            <TextField
                                                id="outlined-basic"
                                                label="University"
                                                variant="outlined"
                                                value={eduQualInfo.university2}
                                                name='university2'
                                                onChange={handleChangeDegSpeYUni}
                                                disabled={fieldDisabled.university2}
                                            />
                                        </div>
                                    </div>

                                    <div className='editQualification'>
                                        {/* degree */}
                                        <div className='editQualificationDegree'>
                                            <FormControl variant={'outlined'}>
                                                <InputLabel id="demo-simple-select-label">Degree</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={eduQualInfo.degree3}
                                                    label="Degree"
                                                    onChange={handleChangeDegSpeYUni}
                                                    name='degree3'
                                                >
                                                    <MenuItem value=''>{''}</MenuItem>
                                                    {PgDegrees.map(deg => (<MenuItem key={deg} value={deg}>{deg}</MenuItem>))}
                                                    {UgDegrees.map(deg => (<MenuItem key={deg} value={deg}>{deg}</MenuItem>))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {/* specialization */}
                                        <div className='editQualificationSpecialization'>
                                            <TextField
                                                id="outlined-basic"
                                                label="Specialization"
                                                variant="outlined"
                                                value={eduQualInfo.specialization3}
                                                name='specialization3'
                                                onChange={handleChangeDegSpeYUni}
                                                disabled={fieldDisabled.specialization3}
                                            />
                                        </div>
                                        {/* passout year */}
                                        <div className='editQualificationPassout' >
                                            <FormControl variant={'outlined'} disabled={fieldDisabled.paaYear3}>
                                                <InputLabel id="demo-simple-select-label">Pass.Year</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={eduQualInfo.paaYear3}
                                                    label="Pass.Year"
                                                    onChange={handleChangeDegSpeYUni}
                                                    name='paaYear3'
                                                >
                                                    {passYear.map(deg => (<MenuItem key={deg} value={deg}>{deg}</MenuItem>))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {/* university */}
                                        <div className='editQualificationUniversity'>
                                            <TextField
                                                id="outlined-basic"
                                                label="University"
                                                variant="outlined"
                                                value={eduQualInfo.university3}
                                                name='university3'
                                                onChange={handleChangeDegSpeYUni}
                                                disabled={fieldDisabled.university3}
                                            />
                                        </div>
                                    </div>

                                    <div className='editUpdateButton'>
                                        <Button disabled={disableButtons.qualifications} onClick={handleUpdateQualification}>Update Education</Button>
                                    </div>
                                    {updatedText.qualifications && (<h2>Educatioal details updated..</h2>)}

                                </div>

                                <h2>Educational Achievements</h2>
                                {/* E-schooling Achievements */}
                                <div className='editAchievements'>
                                    <TextField
                                        id="outlined-basic"
                                        label="Award / Achievement 1 (Max. 200 Characters)"
                                        variant="outlined"
                                        name='awaNAchieve1'
                                        value={awardsNAchievements.awaNAchieve1}
                                        onChange={handleChangeAwardNAchieve}
                                        inputProps={{ maxLength: 200 }}
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        label="Award / Achievement 2 (Max. 200 Characters)"
                                        variant="outlined"
                                        value={awardsNAchievements.awaNAchieve2}
                                        onChange={handleChangeAwardNAchieve}
                                        inputProps={{ maxLength: 200 }}
                                        name='awaNAchieve2'
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        label="Award / Achievement 3 (Max. 200 Characters)"
                                        variant="outlined"
                                        value={awardsNAchievements.awaNAchieve3}
                                        onChange={handleChangeAwardNAchieve}
                                        inputProps={{ maxLength: 200 }}
                                        name='awaNAchieve3'
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        label="Award / Achievement 4 (Max. 200 Characters)"
                                        variant="outlined"
                                        value={awardsNAchievements.awaNAchieve4}
                                        onChange={handleChangeAwardNAchieve}
                                        inputProps={{ maxLength: 200 }}
                                        name='awaNAchieve4'
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        label="Award / Achievement 5 (Max. 200 Characters)"
                                        variant="outlined"
                                        value={awardsNAchievements.awaNAchieve5}
                                        onChange={handleChangeAwardNAchieve}
                                        inputProps={{ maxLength: 200 }}
                                        name='awaNAchieve5'
                                    />
                                    <div className='editUpdateButton'>
                                        <Button disabled={disableButtons.achievements} onClick={handleUpdateAchievements}>Update Achievements</Button>
                                    </div>
                                    {updatedText.achievements && (<h2>Awards & Achievements updated..</h2>)}
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default UserProfileTop