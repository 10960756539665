import React from 'react'
import '../Sidebars/SchoolHomePageSidebar.css';
import { Link } from "react-router-dom";
import { Avatar } from '@mui/material';

import VerifiedIcon from '@mui/icons-material/Verified';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ListAltIcon from '@mui/icons-material/ListAlt';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ListIcon from '@mui/icons-material/List';
import PeopleIcon from '@mui/icons-material/People';
import TodayIcon from '@mui/icons-material/Today';
import HelpIcon from '@mui/icons-material/Help';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import StoreIcon from '@mui/icons-material/Store';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';

function SchoolHomePageSidebar({ schoolFullName, userDP }) {

    return (
        <div className='schoolhomepagesidebar'>


            {/* educational info */}
            <div className='schooleducationSidebar__top'>
                <Link to='/addorremove' style={{ textDecoration: 'none', color: 'gray' }}>
                    <div className='schooleducationSidebar__option'>
                        <ListIcon />
                        <p>Add/Remove</p>
                    </div>
                </Link>
                <Link to='/verificationrequests' style={{ textDecoration: 'none', color: 'gray' }}>
                    <div className='schooleducationSidebar__option'>
                        <GroupAddIcon />
                        <p>Verification Requests</p>
                    </div>
                </Link>
                <Link to='/verifiedmembers' style={{ textDecoration: 'none', color: 'gray' }}>
                    <div className='schooleducationSidebar__option'>
                        <VerifiedIcon />
                        <p>Verified Members</p>
                    </div>
                </Link>
                <Link to='/classteacher' style={{ textDecoration: 'none', color: 'gray' }}>
                    <div className='schooleducationSidebar__option'>
                        <PermContactCalendarIcon />
                        <p>Class Teacher</p>
                    </div>
                </Link>
                <Link to='/findtofollow' style={{ textDecoration: 'none', color: 'gray' }}>
                    <div className='schooleducationSidebar__option'>
                        <PeopleIcon />
                        <p>Follow</p>
                    </div>
                </Link>
                <Link to='/projects&homeworks' style={{ textDecoration: 'none', color: 'gray' }}>
                    <div className='schooleducationSidebar__option'>
                        <HomeWorkIcon />
                        <p>Projects & Homeworks</p>
                    </div>
                </Link>
                <Link to='/noticeboard' style={{ textDecoration: 'none', color: 'gray' }}>
                    <div className='schooleducationSidebar__option'>
                        <ListAltIcon />
                        <p>Notice Board</p>
                    </div>
                </Link>
                <Link to='/upcomingevents' style={{ textDecoration: 'none', color: 'gray' }}>
                    <div className='schooleducationSidebar__option'>
                        <TodayIcon />
                        <p>Upcoming Events</p>
                    </div>
                </Link>
                <Link to='/schooltimetable' style={{ textDecoration: 'none', color: 'gray' }}>
                    <div className='schooleducationSidebar__option'>
                        <ViewTimelineIcon />
                        <p>Timetables</p>
                    </div>
                </Link>
                <Link to='/leaveapplications' style={{ textDecoration: 'none', color: 'gray' }}>
                    <div className='schooleducationSidebar__option'>
                        <TransferWithinAStationIcon />
                        <p>Leave Applications</p>
                    </div>
                </Link>
                <Link to='/syllabuscovered' style={{ textDecoration: 'none', color: 'gray' }}>
                    <div className='schooleducationSidebar__option'>
                        <NoteAltIcon />
                        <p>Syllabus Covered</p>
                    </div>
                </Link>
                <Link to='/schoolcomplaints' style={{ textDecoration: 'none', color: 'gray' }}>
                    <div className='schooleducationSidebar__option'>
                        <ErrorOutlineIcon />
                        <p>Complaints & Feedback</p>
                    </div>
                </Link>



                {/* <Link to='/schoolresults' style={{ textDecoration: 'none', color: 'gray' }}>
                    <div className='schooleducationSidebar__option'>
                        <NotificationAddIcon />
                        <p>Results</p>
                    </div>
                </Link> */}

                <Link to='/subscription' style={{ textDecoration: 'none', color: 'gray' }}>
                    <div className='schooleducationSidebar__option'>
                        <StoreIcon />
                        <p>Subscription</p>
                    </div>
                </Link>
                <Link to='/howtouse' style={{ textDecoration: 'none', color: 'gray' }}>
                    <div className='schooleducationSidebar__option'>
                        <HelpIcon />
                        <p>How to use E-Schooling?</p>
                    </div>
                </Link>


            </div>
        </div>
    )
}

export default SchoolHomePageSidebar
