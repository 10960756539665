import React from 'react'
import '../../Pages/HowToUse.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function HowToUseNoticeboard() {
    return (
        <>
            <div className='HowToUse'>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className='HowToUseSummary'>
                            <h3>How to Create and Publish a School Notice?</h3>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='HowToUseDetails'>
                            <div className='HowToData'>
                                <h3>Add New Notice</h3>
                                <h4>You can add a new notice which will be seen by all the students, teachers or the parents of students, which are verified by you. Others cannot see it. </h4>
                                <p><span>Step 1 : </span> Click on “Notice Board” link on the left side of the homepage.</p>
                                <p><span>Step 2 : </span> Click on “Create New Notice”.</p>
                                <p><span>Step 3 : </span> Fill in all the relevant and required information like Subject, Notice Data and from whom you want to send it like Principal, School Administration, Student Coordinator etc.</p>
                                <p><span>Step 4 : </span> Click on the “Send” button.</p>
                                <p><span>Step 5 : </span> If notice is published successfully then you will see a message as “Notice Sent to all”.</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className='HowToUse'>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className='HowToUseSummary'>
                            <h3>How to See Already Sent School Notices?</h3>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='HowToUseDetails'>
                            <div className='HowToData'>
                                <h3>See Already Sent Notices</h3>
                                <h4>You can see all the notices which you already have published, which are visible to all the students, teachers or the parents of students verified by you.</h4>
                                <p><span>Step 1 : </span> Click on “Notice Board” link on the left side of the homepage.</p>
                                <p><span>Step 2 : </span> Click on “Sent Notices”.</p>
                                <p><span>Step 3 : </span> List of all the notices which you already have published, will appear.</p>
                                <p><span>Step 4 : </span> Click on any Notice and you will see the information like date on which you uploaded it, subject, notice data and from whom it has been sent.</p>
                                <p><span>Step 5 : </span> If you found out that it has been sent by mistake or there are some mistakes in the notice itself then you can delete it by clicking the “Delete” button. </p>
                                <p><span>Step 6 : </span> The moment you delete the notice, it will disappear from the list of all the notices and no one can see it again.</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
}

export default HowToUseNoticeboard