import React, { useState } from 'react'
import '../Pages/Login.css'

import dB, { auth } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { Link, useHistory } from 'react-router-dom'

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';


function Login() {

    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // const classes = useStyles();
    let history = useHistory();

    const [inputEmail, setInputEmail] = useState('')
    const handleChangeEmailId = (e) => {
        e.preventDefault();
        setInputEmail(e.target.value)
        setloginShowEntered(prevState => { return { ...prevState, loginEmailId: { error: false, helperText: '' } } })
    }

    const [UserLoginPassword, setUserLoginPassword] = useState('')
    const handleChangeUserLoginPassword = (event) => {
        event.preventDefault();
        setUserLoginPassword(event.target.value);
        setloginShowEntered(prevState => { return { ...prevState, NewUserPassword: { error: false, helperText: '' } } })
    };

    const [loginShowEntered, setloginShowEntered] = useState({
        loginEmailId: { error: false, helperText: '' },
        NewUserPassword: { error: false, helperText: '' },
        error: ''
    })

    const handleChangeRedirect = async (user) => {

        const schoolRef = doc(dB, "SCHOOLS", user.uid);
        const schoolDocSnap = await getDoc(schoolRef).catch(err => alert(err.message));
        if (schoolDocSnap.exists()) {
            history.push('/schoolaccountprofile');
        } else {
            const userRef = doc(dB, "USERS", user.uid);
            const userDocSnap = await getDoc(userRef).catch(err => alert(err.message));
            if (userDocSnap.exists()) {
                history.push('/user');
            }
        }
    }

    const [loading, setloading] = useState(false)
    const signIn = () => {
        setloading(true)
        if (inputEmail && UserLoginPassword) {
            signInWithEmailAndPassword(auth, inputEmail, UserLoginPassword)
                .catch(err => {
                    setloading(false)
                    setloginShowEntered(prevState => { return { ...prevState, error: err.message } })
                })

            onAuthStateChanged(auth, (user) => {
                if (user) {
                    handleChangeRedirect(user)
                }
            });
        } else {
            setloading(false)
            if (!inputEmail) { setloginShowEntered(prevState => { return { ...prevState, loginEmailId: { error: true, helperText: 'Please enter your Email-Id.' } } }) }
            if (!UserLoginPassword) { setloginShowEntered(prevState => { return { ...prevState, NewUserPassword: { error: true, helperText: 'Please enter the password.' } } }) }
        }
    }

    const createAccount = () => { history.push('/createschoolaccount'); }

    return (
        <div className='login'>
            <div className='login__container'>
                <div className='loginLogo'>
                    <img
                        alt=''
                        src='../Logo.png'
                        style={{ maxWidth: 70, maxHeight: 70 }}
                    />
                    <h1>ConnectEdApp</h1>
                </div>
                <p>Connecting All Educations</p>
                <h4>Sign-In</h4>
                <form>
                    <div className='login__containerInputs'>
                        <div className='login_username'>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '45ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div>
                                    <TextField
                                        error={loginShowEntered.loginEmailId.error}
                                        name='loginEmailId'
                                        id="loginEmailId"
                                        type='email'
                                        label="Email-Id"
                                        // placeholder='E-mail Id'
                                        value={inputEmail}
                                        onChange={handleChangeEmailId}
                                        helperText={loginShowEntered.loginEmailId.helperText}
                                    />
                                </div>
                            </Box>
                        </div >
                        <div className='login_password'>
                            <FormControl error={loginShowEntered.NewUserPassword.error} sx={{ m: 1, width: '45ch' }} variant="outlined">
                                <InputLabel htmlFor="NewUserPassword">Password</InputLabel>
                                <OutlinedInput
                                    name='NewUserPassword'
                                    id="NewUserPassword"
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={UserLoginPassword}
                                    onChange={handleChangeUserLoginPassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                                <FormHelperText id="NewUserPassword"><p>{loginShowEntered.NewUserPassword.helperText}</p></FormHelperText>
                            </FormControl>
                        </div>
                        <p>{loginShowEntered.error}</p>
                        <h4>Forgotten Password?</h4>
                    </div>
                    <LoadingButton
                        onClick={signIn}
                        loading={loading}
                        variant="outlined"
                    >
                        Sign in
                    </LoadingButton>
                </form>
                <hr />
                <div className='login__containerCreateButton'>
                    <Button onClick={createAccount}>Create a New School Account</Button>
                </div>

                <Link to='/createuseraccount' style={{ textDecoration: 'none' }}>
                    <div className='login__container__h5'>
                        <h5 className="text-14 font-bold cursor-pointer p-1 border-1 border-black-700 border-solid rounded  hover:bg-slate-200 hover:underline ">Create a New User Account</h5>
                    </div>
                </Link>
            </div>
        </div >
    )
}

export default Login