import React, { useEffect, useState } from 'react'
import '../VerificationRequest/SchoolVerificationRequestsParents.css'
import { doc, onSnapshot, serverTimestamp, writeBatch } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';

function SchoolVerificationRequestsParentsChild(props) {
    const {
        childsMedium, childsClass, childsSection, parentsId, parentsPathCollection, parentsMedClsSec, schoolId,
        schoolShortName, schoolFullName, schoolAffiliation, childsId, parentsDP } = props

    const [parentRegName, setparentRegName] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (parentsId && parentsPathCollection) {
                onSnapshot(doc(dB, "USERS", parentsId, parentsPathCollection, "REGISTRATION_INFO"), (snap) => {
                    if (snap.exists()) { setparentRegName(snap.data()?.USER_NAME) }
                })
            }
        }
        return () => { aaa = false }
    }, [parentsId, parentsPathCollection])

    const [childsData, setchildsData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (parentsId && parentsPathCollection) {
                onSnapshot(doc(dB, "USERS", parentsId, parentsPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", childsId), (snap) => {
                    if (snap.exists()) { setchildsData(snap.data()) }
                })
            }
        }
        return () => { aaa = false }
    }, [childsId, parentsId, parentsPathCollection])

    const [userGender, setuserGender] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (parentsId && parentsPathCollection) {
                onSnapshot(doc(dB, "USERS", parentsId, parentsPathCollection, "REGISTRATION_INFO"), (snap) => {
                    if (snap.exists()) {
                        setuserGender(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [childsId, parentsId, parentsPathCollection])



    const batch1 = writeBatch(dB)

    const [btnLoading, setbtnLoading] = useState(false)
    const handleParentConfirmChild1 = async (e) => {
        e.preventDefault();
        setbtnLoading(true)

        batch1.delete(doc(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", parentsMedClsSec, "PARENTS", "VERIFICATION_REQUESTS", "VERIFICATION_REQUESTS_DATA", parentsId, "CHILDS_LIST", childsId))

        batch1.set(doc(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", parentsMedClsSec, "PARENTS", "VERIFIED_MEMBERS", "VERIFIED_MEMBERS_DATA", parentsId, "CHILDS_LIST", childsId), {
            TIMESTAMP: serverTimestamp()
        })

        batch1.set(doc(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", parentsMedClsSec, "PARENTS", "VERIFIED_MEMBERS", "VERIFIED_MEMBERS_DATA", parentsId), {
            TIMESTAMP: serverTimestamp()
        })

        batch1.update(doc(dB, "USERS", parentsId, parentsPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", childsId), {
            VERIFIED_CHILD: true,
            CHILD_VERIFIED_SCHOOL_ID: schoolId
        })

        await batch1.commit().then(() => {
            setbtnLoading(false)
        }).catch(err => alert(err.message))
    }
    const handleParentCancelChild1 = async (e) => {
        e.preventDefault();
        setbtnLoading(true)

        batch1.delete(doc(dB, "SCHOOLS", schoolId, schoolShortName, "CLASSES_DETAILS", "CLASSES", parentsMedClsSec, "PARENTS", "VERIFICATION_REQUESTS", "VERIFICATION_REQUESTS_DATA", parentsId, "CHILDS_LIST", childsId))
            .then(() => {
                setbtnLoading(false)
            }).catch(err => alert(err.message))
    }

    return (
        <div className='SchoolVerificationRequestsParents' >
            <div className='schoolverificationrequests__containerRightParentsGuardiansData' >
                <div className='schoolverificationrequests__containerRightParentsGuardiansDataName dataNameParent'>
                    <Link to={`/friendsprofile/${parentsId}`} style={{ textDecoration: 'none', color: 'gray' }}>
                        <Avatar
                            alt={parentRegName}
                            src={parentsDP}
                            sx={{ width: 40, height: 40, }}
                        />
                    </Link>
                    <h3>
                        <Link to={`/friendsprofile/${parentsId}`} style={{ textDecoration: 'none', color: 'black' }}>
                            {parentRegName}
                        </Link>
                    </h3>
                </div>
                <h5>{userGender?.USER_GENDER === 'Female' ? 'Mother ' : 'Father '}of</h5>
                <div className='schoolverificationrequests__containerRightParentsGuardiansDataName'>
                    <Avatar
                        alt={childsData?.CHILDS_FULL_NAME}
                        src={childsData?.CHILD_DISPLAY_PIC}
                        sx={{ width: 40, height: 40, }}
                    />
                    <h3>{childsData?.CHILDS_FULL_NAME}</h3>
                </div>
                <div className='schoolverificationrequests__containerRightParentsGuardiansDataButtons'>
                    <LoadingButton
                        onClick={handleParentConfirmChild1}
                        variant='outlined'
                        loading={btnLoading}
                    >
                        Confirm
                    </LoadingButton>
                    <LoadingButton
                        onClick={handleParentCancelChild1}
                        variant='outlined'
                        loading={btnLoading}
                    >
                        Cancel
                    </LoadingButton>
                </div>
            </div>
        </div>
    )
}

export default SchoolVerificationRequestsParentsChild
