import React, { useEffect, useState } from 'react'
import '../CreateNewAccount/CreateNewAccountInfoStudents.css';
// import dB, { auth } from '../firebase';
import { collection, doc, onSnapshot, serverTimestamp, writeBatch } from 'firebase/firestore';
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { useHistory } from 'react-router';

import {
    Autocomplete, FormControl, FormControlLabel, FormHelperText, FormLabel,
    InputLabel, MenuItem, Radio, RadioGroup, Select, TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB, { auth } from '../../firebase';
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec';
import { returnSection } from '../../Constants/FuncMedium';

function CreateNewAccountInfoStudents(props) {
    const { fName, lName, userFullName, userMobNum, userPwd, userGender, userDOB, userEmailId } = props
    const history = useHistory();

    const [studentsInputValue, setstudentsInputValue] = useState('');
    const [inputStudentSchoolName, setinputStudentSchoolName] = useState(null)

    const [showEnteredStudents, setshowEnteredStudents] = useState({
        studentsSchoolName: { error: false, helperText: '' },
        studentsSchoolClass: { error: false, helperText: '' },
        studentsSchoolClassSection: { error: false, helperText: '' },
        studentSelectMedium: '',
    })


    const [studentMedium, setstudentMedium] = useState('')
    const handleMediumValueChange = (event) => {
        event.preventDefault()
        setstudentMedium(event.target.value);
    };
    const handleMediumChange = (e) => {
        e.preventDefault();
        setStudentsData(prev => { return { ...prev, studentsSchoolClass: '', studentsSchoolClassSection: '' } })
    }

    const [studentsData, setStudentsData] = useState({ studentsSchoolClass: '', studentsSchoolClassSection: '', })
    const [returnedSectionList, setreturnedSectionList] = useState(null);
    const handleChangeStudentsDataClass = (e) => {
        e.preventDefault();
        setshowEnteredStudents(prev => { return { ...prev, [e.target.name]: { error: false, helperText: '' } } })
        setStudentsData(prev => { return { ...prev, studentsSchoolClass: e.target.value } })
        setStudentsData(prev => { return { ...prev, studentsSchoolClassSection: '' } })
    }
    const handleChangeStudentsDataSection = (e) => {
        e.preventDefault()
        setStudentsData(prev => { return { ...prev, studentsSchoolClassSection: e.target.value } })
        setshowEnteredStudents(prev => { return { ...prev, studentsSchoolClassSection: { error: false, helperText: '' } } })
    }

    const [studentsOptions, setstudentsOptions] = useState([])
    useEffect(() => {
        if (studentsOptions.length > 0) {
            studentsOptions.splice(0, studentsOptions.length)
        }
        var aaa = true
        if (aaa === true) {
            onSnapshot(collection(dB, "SCHOOLS"), (querySnapshot) => {
                querySnapshot.forEach((schoolDoc) => {
                    const newdata = schoolDoc.data()?.SCHOOL_FULL_NAME;
                    setstudentsOptions(prev => { return [...prev, { id: schoolDoc.id, schoolName: newdata }] })
                });
            })
        }
        return () => { aaa = false }
    }, [])

    // fetch school id's for students to select
    const [studentsOptionSchoolId, setstudentsOptionSchoolId] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (inputStudentSchoolName) {
                studentsOptions.map(option => {
                    if (option.schoolName === inputStudentSchoolName) {
                        setstudentsOptionSchoolId(option.id)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [inputStudentSchoolName, studentsOptions])

    const [returnedStudentsClassList, setreturnedStudentsClassList] = useState([])
    const [studentSchoolPath, setstudentSchoolPath] = useState({ schoolSmallName: '', schoolId: '', })
    useEffect(() => {
        if (returnedStudentsClassList.length > 0) {
            returnedStudentsClassList.splice(0, returnedStudentsClassList.length)
        }
        var aaa = true
        if (aaa === true) {
            onSnapshot(collection(dB, "SCHOOLS"), (querySnapshot) => {
                querySnapshot.forEach((queryDoc) => {
                    const newdata = queryDoc.data()?.SCHOOL_FULL_NAME;
                    if (newdata === inputStudentSchoolName) {
                        const schoolCollection = queryDoc.data()?.SCHOOL_NAME;
                        setstudentSchoolPath(prev => { return { ...prev, schoolSmallName: schoolCollection, schoolId: queryDoc.id } })
                        onSnapshot(doc(dB, "SCHOOLS", queryDoc.id, schoolCollection, "CLASSES_DETAILS"), (clsDoc) => {
                            if (clsDoc.exists()) {
                                setreturnedStudentsClassList(clsDoc.data()?.CLASS_LIST)
                            }
                        })
                    }
                });
            })
        }
        return () => { aaa = false }
    }, [inputStudentSchoolName])

    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            setreturnedSectionList(null)
            var sectionObject = returnSection(studentMedium, studentsData.studentsSchoolClass, returnedStudentsClassList)
            setreturnedSectionList(sectionObject)
        }
        return () => { aaa = false }
    }, [studentMedium, studentsData.studentsSchoolClass, returnedStudentsClassList])

    const [studentsClassSection, setstudentsClassSection] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var abc = returnMedClsSec(studentMedium, studentsData.studentsSchoolClass, studentsData.studentsSchoolClassSection)
            setstudentsClassSection(abc)

        }
        return () => { aaa = false }
    }, [studentMedium, studentsData])

    const [showHideData, setshowHideData] = useState({
        btnGoToDisabled: false, btnSendReqDisabled: false,
        showBtnGoTo: false, showBtnSendReq: true, showSentText: false,
        errorMessage: '', showBlock: true
    })
    const [btnLoading, setbtnLoading] = useState(false)

    var batch = writeBatch(dB)
    const sendVerificationRequest = async (e) => {
        e.preventDefault()
        setbtnLoading(true)
        setshowHideData(prev => { return { ...prev, btnSendReqDisabled: true } })
        if (inputStudentSchoolName && studentsData.studentsSchoolClass && studentsData.studentsSchoolClassSection && studentMedium) {

            await createUserWithEmailAndPassword(auth, userEmailId, userPwd).then(async (authUser) => {

                await batch.set(doc(dB, "USERS", authUser.user.uid), {
                    USER_CREATED_ON: serverTimestamp(),
                    USER_EMAIL_ID: userEmailId,
                    USER_CURRENT_STATUS: 'STUDENT',
                    USER_NAME: userFullName,
                    USER_FULL_NAME: userFullName
                });

                await batch.set(doc(dB, "USERS", authUser.user.uid, userFullName, "REGISTRATION_INFO"), {
                    USER_NAME: userFullName,
                    USER_MOB_NO: userMobNum,
                    USER_PWD: userPwd,
                    USER_GENDER: userGender,
                    USER_DOB: userDOB,
                    F_NAME: fName,
                    L_NAME: lName,
                });

                await batch.set(doc(dB, "USERS", authUser.user.uid, userFullName, "E-SCHOOLING_INFO", "IS_STUDENT", "IS_STUDENT_DATA"), {
                    MEDIUM: studentMedium,
                    STUDENTS_SCHOOL_NAME: inputStudentSchoolName,
                    STUDENTS_CLASS: studentsData.studentsSchoolClass,
                    STUDENTS_SECTION: studentsData.studentsSchoolClassSection,
                });

                await batch.set(doc(dB, "USERS", authUser.user.uid, userFullName, "E-SCHOOLING_INFO", "IS_STUDENT", "VERIFICATION_DETAILS"), {
                    USER_STATUS: 'STUDENT',
                    VERIFIED_STUDENT: false,
                    USER_SCHOOL_ID: studentsOptionSchoolId
                });

                await batch.set(doc(dB, "SCHOOLS", studentSchoolPath.schoolId, studentSchoolPath.schoolSmallName, "CLASSES_DETAILS", "CLASSES", studentsClassSection, "STUDENTS", "VERIFICATION_REQUESTS", "VERIFICATION_REQUESTS_DATA", authUser.user.uid), {
                    TIMESTAMP: serverTimestamp()
                });

                await batch.commit().then(() => {
                    sessionStorage.setItem('Student_USER_ID', authUser.user.uid)
                    setinputStudentSchoolName('');
                    setStudentsData('')
                    setshowHideData(prev => {
                        return {
                            ...prev,
                            showSentText: true,
                            showBtnGoTo: true,
                            showBtnSendReq: false,
                            showBlock: false,
                            btnSendReqDisabled: false
                        }
                    })
                    setbtnLoading(false)
                }).catch(err => setshowHideData(prev => { return { ...prev, errorMessage: err.message } }));
            }).catch(err => setshowHideData(prev => { return { ...prev, errorMessage: err.message } }));
        } else {
            setbtnLoading(false)
            if (!inputStudentSchoolName) { setshowEnteredStudents(prev => { return { ...prev, studentsSchoolName: { error: true, helperText: 'Please enter your school name.' } } }) }
            if (!studentsData.studentsSchoolClass) { setshowEnteredStudents(prev => { return { ...prev, studentsSchoolClass: { error: true, helperText: 'Please select the class you are studying in.' } } }) }
            if (!studentsData.studentsSchoolClassSection) { setshowEnteredStudents(prev => { return { ...prev, studentsSchoolClassSection: { error: true, helperText: 'Please select your section here.' } } }) }
            if (!studentMedium) { setshowEnteredStudents(prev => { return { ...prev, studentSelectMedium: 'show' } }) }
        }
    }

    const studentGoToProfile = () => {
        setbtnLoading(true)
        setshowHideData(prev => { return { ...prev, btnGoToDisabled: true } })
        signInWithEmailAndPassword(auth, userEmailId, userPwd)
            .then((userCredential) => {
                setshowHideData(prev => { return { ...prev, btnGoToDisabled: false } })
                setbtnLoading(false)
            })
            .catch(err => setshowHideData(prev => { return { ...prev, errorMessage: err.message } }));

        onAuthStateChanged(auth, (user) => {
            if (user) {
                history.push('/user');
            }
        })
    }

    return (
        <div >
            {
                showHideData.showBlock && (
                    <div className='createnewaccountinfostudents'>
                        <div id='createnewaccountinfo__containerStudent' className='createnewaccountinfo__containerStudent'>
                            <h3>Student</h3>
                            <div className='h5__schooName'>
                                <Autocomplete
                                    value={inputStudentSchoolName}
                                    onChange={(event, newValue) => {
                                        event.preventDefault()
                                        setinputStudentSchoolName(newValue);
                                    }}
                                    inputValue={studentsInputValue}
                                    onInputChange={(event, newInputValue) => {
                                        setstudentsInputValue(newInputValue);
                                        if (returnedStudentsClassList.length > 0) {
                                            returnedStudentsClassList.splice(0, returnedStudentsClassList.length)
                                        }
                                        setshowEnteredStudents(prev => { return { ...prev, studentsSchoolName: { error: false, helperText: '' } } })
                                        setStudentsData(prev => { return { ...prev, studentsSchoolClass: '', studentsSchoolClassSection: '' } })
                                        setstudentMedium('')
                                    }}

                                    disablePortal
                                    id="controllable-states-demo"
                                    options={studentsOptions.map((option) => option.schoolName)}
                                    sx={{ width: '75ch' }}
                                    renderInput={(params) =>
                                        <TextField
                                            id="studentsSchoolName"
                                            name='studentsSchoolName'
                                            {...params}
                                            margin="normal"
                                            label="Search your school here by school name, village ,city or district"
                                            variant="outlined"
                                            error={showEnteredStudents.studentsSchoolName.error}
                                        />
                                    }
                                />
                                <div className='noAvailable'>
                                    <h5><b>Note : </b>If School you are looking for does not appear in the list, then your school is yet to create an account. Ask your school to create account first. Till then you can use this platform to follow other schools and make new friends.</h5>
                                </div>
                            </div>
                            <div className='h5__medium'>
                                <FormControl onChange={handleMediumChange}>
                                    <div className='students__radioMediumMuiDiv'>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Medium</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={studentMedium} onChange={handleMediumValueChange}
                                        >
                                            <div className='students__radioMediumMui'>
                                                <FormControlLabel value="English Medium" control={<Radio />} label="English Medium" />
                                                <FormControlLabel value="Marathi Medium" control={<Radio />} label="Marathi Medium" />
                                            </div>
                                        </RadioGroup>
                                    </div>
                                </FormControl>
                            </div>
                            <div className='ClassnSection'>
                                <div className='ClassNum'>
                                    <div className='h5__Class'>
                                        <FormControl name='studentsSchoolClass' error={showEnteredStudents.studentsSchoolClass.error} sx={{ m: 1, minWidth: "40ch" }}>
                                            <InputLabel id="studentsSchoolClass" name='studentsSchoolClass'>Class</InputLabel>
                                            <Select
                                                labelId="studentsSchoolClass"
                                                id="studentsSchoolClass"
                                                name='studentsSchoolClass'
                                                value={studentsData.studentsSchoolClass}
                                                onChange={handleChangeStudentsDataClass}
                                                label="Class"
                                            // onChange={handleChange}
                                            >
                                                <MenuItem style={{ display: studentMedium != null ? 'none' : 'block' }} aria-label="None" value="" />

                                                <MenuItem style={{ display: studentMedium === 'English Medium' && returnedStudentsClassList.includes('EngClass8SecA') ? 'block' : 'none' }} id='Eng Class 8' key='Eng Class 8' value='Class 8'>Class 8</MenuItem>
                                                <MenuItem style={{ display: studentMedium === 'English Medium' && returnedStudentsClassList.includes('EngClass9SecA') ? 'block' : 'none' }} id='Eng Class 9' key='Eng Class 9' value='Class 9'>Class 9</MenuItem>
                                                <MenuItem style={{ display: studentMedium === 'English Medium' && returnedStudentsClassList.includes('EngClass10SecA') ? 'block' : 'none' }} id='Eng Class 10' key='Eng Class 10' value='Class 10'>Class 10</MenuItem>
                                                <MenuItem style={{ display: studentMedium === 'English Medium' && returnedStudentsClassList.includes('EngClass11ScienceSecA') ? 'block' : 'none' }} id='Eng Class 11(Science)' key='Eng Class 11(Science)' value='Class 11(Science)'>Class 11(Science)</MenuItem>
                                                <MenuItem style={{ display: studentMedium === 'English Medium' && returnedStudentsClassList.includes('EngClass11CommerceSecA') ? 'block' : 'none' }} id='Eng Class 11(Commerce)' key='Eng Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                                <MenuItem style={{ display: studentMedium === 'English Medium' && returnedStudentsClassList.includes('EngClass12ScienceSecA') ? 'block' : 'none' }} id='Eng Class 12(Science)' key='Eng Class 12(Science)' value='Class 12(Science)'>Class 12(Science)</MenuItem>
                                                <MenuItem style={{ display: studentMedium === 'English Medium' && returnedStudentsClassList.includes('EngClass12CommerceSecA') ? 'block' : 'none' }} id='Eng Class 12(Commerce)' key='Eng Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>

                                                <MenuItem style={{ display: studentMedium === 'Marathi Medium' && returnedStudentsClassList.includes('MarClass8SecA') ? 'block' : 'none' }} id='Mar Class 8' key='Mar Class 8' value='Class 8'>Class 8</MenuItem>
                                                <MenuItem style={{ display: studentMedium === 'Marathi Medium' && returnedStudentsClassList.includes('MarClass9SecA') ? 'block' : 'none' }} id='Mar Class 9' key='Mar Class 9' value='Class 9'>Class 9</MenuItem>
                                                <MenuItem style={{ display: studentMedium === 'Marathi Medium' && returnedStudentsClassList.includes('MarClass10SecA') ? 'block' : 'none' }} id='Mar Class 10' key='Mar Class 10' value='Class 10'>Class 10</MenuItem>
                                                <MenuItem style={{ display: studentMedium === 'Marathi Medium' && returnedStudentsClassList.includes('MarClass11ArtsSecA') ? 'block' : 'none' }} id='Mar Class 11(Arts)' key='Mar Class 11(Arts)' value='Class 11(Arts)'>Class 11(Arts)</MenuItem>
                                                <MenuItem style={{ display: studentMedium === 'Marathi Medium' && returnedStudentsClassList.includes('MarClass11CommerceSecA') ? 'block' : 'none' }} id='Mar Class 11(Commerce)' key='Mar Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                                <MenuItem style={{ display: studentMedium === 'Marathi Medium' && returnedStudentsClassList.includes('MarClass12ArtsSecA') ? 'block' : 'none' }} id='Mar Class 12(Arts)' key='Mar Class 12(Arts)' value='Class 12(Arts)'>Class 12(Arts)</MenuItem>
                                                <MenuItem style={{ display: studentMedium === 'Marathi Medium' && returnedStudentsClassList.includes('MarClass12CommerceSecA') ? 'block' : 'none' }} id='Mar Class 12(Commerce)' key='Mar Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>
                                            </Select>
                                            <FormHelperText id="studentsSchoolClass"><p>{showEnteredStudents.studentsSchoolClass.helperText}</p></FormHelperText>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='ClassSection'>
                                    <div className='h5__section'>
                                        <FormControl name='studentsSchoolClassSection' error={showEnteredStudents.studentsSchoolClassSection.error} sx={{ m: 1, minWidth: "30ch" }}>
                                            <InputLabel name='studentsSchoolClassSection' id="studentsSchoolClassSection">Section</InputLabel>
                                            <Select
                                                labelId="studentsSchoolClassSection"
                                                id="studentsSchoolClassSection"
                                                name='studentsSchoolClassSection'
                                                value={studentsData.studentsSchoolClassSection}
                                                onChange={handleChangeStudentsDataSection}
                                                label="Section"
                                            >
                                                <MenuItem style={{ display: studentsData.studentsSchoolClass === '' ? 'block' : 'none' }} aria-label="None" value="" />

                                                <MenuItem style={{ display: returnedSectionList?.SectionA === true ? 'block' : 'none' }} key='Section A' value='Section A'>Section A</MenuItem>
                                                <MenuItem style={{ display: returnedSectionList?.SectionB === true ? 'block' : 'none' }} key='Section B' value='Section B'>Section B</MenuItem>
                                                <MenuItem style={{ display: returnedSectionList?.SectionC === true ? 'block' : 'none' }} key='Section C' value='Section C'>Section C</MenuItem>
                                                <MenuItem style={{ display: returnedSectionList?.SectionD === true ? 'block' : 'none' }} key='Section D' value='Section D'>Section D</MenuItem>
                                                <MenuItem style={{ display: returnedSectionList?.SectionE === true ? 'block' : 'none' }} key='Section E' value='Section E'>Section E</MenuItem>
                                            </Select>
                                            <FormHelperText id="studentsSchoolClassSection"><p>{showEnteredStudents.studentsSchoolClassSection.helperText}</p></FormHelperText>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            {
                                showHideData.showBtnSendReq && (
                                    <LoadingButton
                                        onClick={sendVerificationRequest}
                                        loading={btnLoading}
                                        variant="outlined"
                                    >
                                        Send Verification Request to School
                                    </LoadingButton>
                                    // <Button id='sendVerification'  disabled={showHideData.btnSendReqDisabled}></Button>
                                )
                            }

                            <h4>{showHideData.errorMessage}</h4>
                        </div>
                        {
                            showHideData.showSentText && (
                                <h5 id='requestSent'>Request Sent to School..</h5>
                            )
                        }
                        {
                            showHideData.showBtnGoTo && (
                                <LoadingButton
                                    onClick={studentGoToProfile}
                                    loading={btnLoading}
                                    variant="outlined"
                                >
                                    Go to profile
                                </LoadingButton>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}

export default CreateNewAccountInfoStudents
