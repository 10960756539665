import React, { useEffect, useState } from 'react'
import '../UpcomingEvents/SchoolEvents.css'

import SchoolUpcomingEventData from './SchoolUpcomingEventData'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore'
import dB from '../../firebase'


function SchoolEvents(props) {
    const { schoolId, SchoolFriendProfile, schoolPathCollection } = props

    const [allEvents, setallEvents] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolPathCollection) {
                onSnapshot(query(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "SCHOOL_EVENTS", "ALL_EVENTS"), orderBy("TIMESTAMP", "desc")), (snap) => {
                    setallEvents(snap.docs.map((evDoc) => ({
                        eventId: evDoc.id,
                        eventData: evDoc.data(),
                    })))
                })
            }
        }
        return () => { aaa = false }
    }, [schoolId, schoolPathCollection])

    return (
        <div className='SchoolEvents'>
            <h2>School Upcoming Events ({allEvents.length})</h2>
            <hr />
            <div>
                {
                    allEvents.length > 0 ? (
                        allEvents.map((data, i) => (
                            <SchoolUpcomingEventData
                                key={i}
                                eventId={data?.eventId}
                                eventName={data?.eventData?.EVENT_NAME}
                                eventDate={data?.eventData?.EVENT_DATE}
                                eventDescription={data?.eventData?.EVENT_DESCRIPTION}
                                eventCrowd={data?.eventData?.EVENT_CROWD}
                                eventType={data?.eventData?.EVENT_TYPE}
                                uploadedOn={data?.eventData?.TIMESTAMP}
                                chiefGuests={data?.eventData?.EVENT_CHIEF_GUESTS}
                                SchoolFriendProfile={true}
                                userSchoolId={null}
                                userSchoolPathCollection={null}
                            />
                        ))
                    ) : (
                        <div className='noEventsSchool'>
                            <h4>No events has been published by the school yet.</h4>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default SchoolEvents