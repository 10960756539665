import React, { useEffect, useState } from 'react'
// import '../Components/HomePagePosts.css'
import '../Social/HomePagePosts.css'
import { Link } from 'react-router-dom'
// import dB from '../firebase'
import { doc, onSnapshot } from 'firebase/firestore'
import dB from '../../firebase'

function HomePagePostsLikes(props) {
    const { likerStatus, likedById } = props

    const [likerRegName, setlikerRegName] = useState('')
    const [likerpathCollection, setlikerpathCollection] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (likerStatus && likedById) {
                if (likerStatus === 'SCHOOL') {
                    onSnapshot(doc(dB, "SCHOOLS", likedById), (snap) => {
                        if (snap.exists()) {
                            setlikerpathCollection(snap.data()?.SCHOOL_NAME)
                        }
                    })
                } else {
                    onSnapshot(doc(dB, "USERS", likedById), (snap) => {
                        if (snap.exists()) {
                            setlikerpathCollection(snap.data()?.USER_NAME)
                        }
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [likedById, likerStatus])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (likerStatus && likedById && likerpathCollection) {
                if (likerStatus === 'SCHOOL') {
                    onSnapshot(doc(dB, "SCHOOLS", likedById, likerpathCollection, "REGISTRATION_INFO"), (snap) => {
                        if (snap.exists()) {
                            setlikerRegName(snap.data()?.SCHOOL_FULL_NAME)
                        }
                    })
                } else {
                    onSnapshot(doc(dB, "USERS", likedById, likerpathCollection, "REGISTRATION_INFO"), (snap) => {
                        if (snap.exists()) {
                            setlikerRegName(snap.data()?.USER_NAME)
                        }
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [likedById, likerStatus, likerpathCollection])
    return (
        <h5 >
            <Link to={likerStatus === 'SCHOOL' ? `/schoolfriendprofile/${likedById}` : `/friendsprofile/${likedById}`} style={{ textDecoration: 'none', color: 'black' }}>
                {likerRegName}
            </Link>
        </h5>
    )
}

export default HomePagePostsLikes
