import React, { useEffect, useState } from 'react'
import HomePageEducationSidebar from './HomePageEducationSidebar';
import '../Sidebars/HomePageSidebar.css'
import dB from '../../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
function HomePageSidebarPrincipal(props) {
    const { userCurrentStatus, userId, userPathCollection, princiSchoolData } = props

    const [schoolData, setschoolData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (princiSchoolData?.USER_SCHOOL_ID) {
                onSnapshot(doc(dB, "SCHOOLS", princiSchoolData?.USER_SCHOOL_ID), (princiDoc) => {
                    if (princiDoc.exists()) {
                        setschoolData(princiDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [princiSchoolData?.USER_SCHOOL_ID])

    return (
        <div className='HomePageSidebarPrincipal'>

            {/* <div style={{ display: showBlock === 'show' ? 'none' : 'flex' }}> */}
            {/* educational info */}
            <HomePageEducationSidebar
                userCurrentStatus={userCurrentStatus}
                schoolId={princiSchoolData?.USER_SCHOOL_ID}
                schoolPathCollection={schoolData?.SCHOOL_NAME}
                userId={userId}
            />
            {/* </div> */}

        </div>
    )
}

export default HomePageSidebarPrincipal
