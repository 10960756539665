import React, { useState } from 'react';
import '../Pages/CreateSchoolAccountSchoolDetails.css'
import CreateSchoolAccountSchoolDetailsNext from '../Components/CreateNewAccount/CreateSchoolAccountSchoolDetailsNext';
// import CreateSchoolAccountSchoolDetailsNext from '../Components/CreateSchoolAccountSchoolDetailsNext';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';

function CreateSchoolAccountSchoolDetails() {


    const [textInput, setTextInput] = useState({
        inputCreatorFName: '', inputCreatorLName: '',
    })
    const handleChangeInputs = (event) => {
        event.preventDefault()
        setTextInput(prevState => { return { ...prevState, [event.target.name]: event.target.value } });
        setshowEnteredSchool(prevState => { return { ...prevState, [event.target.name]: { error: false, helperText: '' } } })
    }

    const [showEnteredSchool, setshowEnteredSchool] = useState({
        inputCreatorFName: { error: false, helperText: '' },
        inputCreatorLName: { error: false, helperText: '' },
    })

    const [showBlock, setshowBlock] = useState({
        showAdmin: true, showSchoolInfo: false
    })

    const handleContinue = () => {
        if (textInput.inputCreatorFName && textInput.inputCreatorLName) {
            setshowBlock(prev => { return { ...prev, showAdmin: false, showSchoolInfo: true } })
        } else {
            if (!textInput.inputCreatorFName) { setshowEnteredSchool(prev => { return { ...prev, inputCreatorFName: { error: true, helperText: 'Please enter your first name' }, } }) }
            if (!textInput.inputCreatorLName) { setshowEnteredSchool(prev => { return { ...prev, inputCreatorLName: { error: true, helperText: 'Please enter your last name' }, } }) }
        }
    }

    return (
        <div className='createschoolaccountschooldetails'>
            <div style={{ display: showBlock.showAdmin ? 'flex' : 'none' }} className='createschoolaccount__containerinDetails'>
                <h1>ConnectEdApp</h1>
                <p>Create a New School Account</p>
                <hr />
                <div className='createschoolaccountschooldetails__nameadminaddress'>
                    <p><span style={{ fontSize: '16px', fontWeight: '700' }}>Please Enter Your Name.</span> You will be the admin of this account unless you share your login details with others. (We recommend not to share your login details with others)</p>
                    <div className='adminName'>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '45ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    error={showEnteredSchool.inputCreatorFName.error}
                                    id="inputCreatorFName"
                                    name='inputCreatorFName'
                                    type='text'
                                    label="First Name of admin"
                                    // placeholder='E-mail Id'
                                    value={textInput.inputCreatorFName}
                                    onChange={handleChangeInputs}
                                    helperText={showEnteredSchool.inputCreatorFName.helperText}
                                />
                            </div>
                        </Box>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '45ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    error={showEnteredSchool.inputCreatorLName.error}
                                    id="inputCreatorLName"
                                    name='inputCreatorLName'
                                    type='text'
                                    label="Last Name of admin"
                                    // placeholder='E-mail Id'
                                    value={textInput.inputCreatorLName}
                                    onChange={handleChangeInputs}
                                    helperText={showEnteredSchool.inputCreatorLName.helperText}
                                />
                            </div>
                        </Box>
                    </div>
                    <Button onClick={handleContinue}>Continue</Button>
                    <hr />
                </div>

            </div>
            <div style={{ display: showBlock.showSchoolInfo ? 'flex' : 'none' }} >
                <CreateSchoolAccountSchoolDetailsNext
                    adminFName={textInput.inputCreatorFName}
                    adminLName={textInput.inputCreatorLName}
                />
            </div>
        </div>
    )

}
export default CreateSchoolAccountSchoolDetails