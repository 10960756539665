import React, { useEffect } from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ComplaintBody from './ComplaintBody';

function ComplaintsParentsAll(props) {

    const { allComplaints, allSuggestions } = props


    return (
        <>
            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <p className='Complaintitle'>Previously given</p>

                </AccordionSummary>
                <AccordionDetails>
                    <div className='complainBox'>
                        <h3>Suggestions</h3>
                        {
                            allSuggestions?.length > 0 ? (
                                <>
                                    {
                                        allSuggestions.map((sugg, i) => (
                                            <ComplaintBody
                                                key={i}
                                                index={i}
                                                childId={sugg?.suggestionData?.CHILD_ID}
                                                compSuggText={sugg?.suggestionData?.SUGGESTION_TEXT}
                                                timestamp={sugg?.suggestionData?.TIMESTAMP}
                                                schoolId={sugg?.suggestionData?.SCHOOL_ID}
                                                schoolPathCollection={sugg?.suggestionData?.SCHOOL_PATH_COLLECTION}
                                                type={"Suggestion"}
                                            />
                                        ))
                                    }
                                </>
                            ) : (
                                <>
                                    <h3 style={{ color: 'var(--connected-orange)' }}>You have not sent any suggestion to school yet.</h3>
                                </>
                            )
                        }




                        <h3>Complaints</h3>
                        {
                            allComplaints?.length > 0 ? (
                                <>
                                    {
                                        allComplaints.map((comp, i) => (
                                            <ComplaintBody
                                                key={i}
                                                index={i}
                                                childId={comp?.complaintData?.CHILD_ID}
                                                compSuggText={comp?.complaintData?.COMPLAINT_TEXT}
                                                timestamp={comp?.complaintData?.TIMESTAMP}
                                                schoolId={comp?.complaintData?.SCHOOL_ID}
                                                schoolPathCollection={comp?.complaintData?.SCHOOL_PATH_COLLECTION}
                                                type={"Complaint"}
                                            />
                                        ))
                                    }
                                </>
                            ) : (
                                <>
                                    <h3 style={{ color: 'var(--connected-orange)' }}>You have not sent any complaint to school yet.</h3>
                                </>
                            )
                        }


                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default ComplaintsParentsAll