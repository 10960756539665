import React, { useEffect, useState } from 'react'
import '../Pages/SchoolAddOrRemove.css'

import dB, { auth } from '../firebase';
import { arrayRemove, arrayUnion, collection, doc, getDoc, getDocs, onSnapshot, updateDoc, writeBatch } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
    Autocomplete, Chip, createFilterOptions, FormControl, FormControlLabel, FormHelperText,
    FormLabel, Input, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import Login from './Login';
import Header from '../Components/Header/Header';
import { returnMedClsSec } from '../Constants/FunctionClsMedSec';
import { returnSection } from '../Constants/FuncMedium';

const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            minWidth: 100,
            maxWidth: 300,
        },
    },
};

function SchoolAddOrRemove() {


    var batch = writeBatch(dB);

    const subjectList = ['Accountancy', 'Agriculture', 'Algebra', 'Animal Science', 'Applied Mathematics', 'Applied Physics',
        'Applied Chemistry', 'Applied Science', 'Arabic', 'Astronomy',
        'Automobile Technology', 'Bio Technology', 'Biology', 'Book Keeping & Accounts', 'Botany', 'Business Laws',
        'Business Studies', 'Chemistry', 'Civics', 'Computer Science', 'Computers', 'Creative Writing', 'Crop Production', 'Defence Studies',
        'Economics', 'Engineering Drawings', 'English', 'Entrepreneurship', 'Environment Education', 'Environmental Science',
        'Environmental Studies', 'Fashion Studies', 'Fine Arts', 'French', 'Fundamental Banking & Finance', 'General Awareness',
        'General Knowledge', 'General Mathematics', 'General Studies', 'Geography', 'Geometry', 'German', 'Graphics Design',
        'Geology', 'Heritage Crafts', 'Hindi', 'History', 'Home Science', 'Human Rights & Gender Studies', 'Informatics Practices',
        'Information Practices', 'Information Technology', 'Law', 'Legal Studies', 'Library & information',
        'Library & Information Science', 'Logic', 'Macroeconomics', 'Marathi', 'Mass Communication', 'Mas Media Studies',
        'Mathematics', 'Mathematics & Statistics', 'Microeconomics', 'Music', 'New Age Graphics Design', 'Organisation of Commerce', 'Pali', 'Persian',
        'Philosophy', 'Physical Education', 'Physics', 'Political Science', 'Psychology', 'Public Administration',
        'Punjabi', 'Retail Merchandising', 'Sanskrit', 'Secretarial Practice', 'Science', 'Science & Technology', 'Sociology',
        'Statistics', 'Statistics for Economics', 'Stenography', 'Urdu', 'Zoology']
    const subjectListDummy = [
        { title: 'Accountancy', year: 1994 },
        { title: 'Agriculture', year: 1994 },
        { title: 'Algebra', year: 1994 },
        { title: 'Animal Science', year: 1994 },
        { title: 'Applied Mathematics', year: 1994 },
        { title: 'Applied Physics', year: 1994 },
        { title: 'Applied Chemistry', year: 1994 },
        { title: 'Applied Science', year: 1994 },
        { title: 'Arabic', year: 1994 },
        { title: 'Astronomy', year: 1994 },
        { title: 'Automobile Technology', year: 1994 },
        { title: 'Bio Technology', year: 1994 },
        { title: 'Biology', year: 1994 },
        { title: 'Book Keeping & Accounts', year: 1994 },
        { title: 'Botany', year: 1994 },
        { title: 'Business Laws', year: 1994 },
        { title: 'Business Studies', year: 1994 },
        { title: 'Chemistry', year: 1994 },
        { title: 'Civics', year: 1994 },
        { title: 'Computer Science', year: 1994 },
        { title: 'Computers', year: 1994 },
        { title: 'Creative Writing', year: 1994 },
        { title: 'Crop Production', year: 1994 },
        { title: 'Defence Studies', year: 1994 },
        { title: 'Economics', year: 1994 },
        { title: 'Engineering Drawings', year: 1994 },
        { title: 'English', year: 1994 },
        { title: 'Entrepreneurship', year: 1994 },
        { title: 'Environment Education', year: 1994 },
        { title: 'Environmental Science', year: 1994 },
        { title: 'Environmental Studies', year: 1994 },
        { title: 'Fashion Studies', year: 1994 },
        { title: 'Fine Arts', year: 1994 },
        { title: 'French', year: 1994 },
        { title: 'Fundamental Banking & Finance', year: 1994 },
        { title: 'General Awareness', year: 1994 },
        { title: 'General Knowledge', year: 1994 },
        { title: 'General Mathematics', year: 1994 },
        { title: 'General Studies', year: 1994 },
        { title: 'Geography', year: 1994 },
        { title: 'Geometry', year: 1994 },
        { title: 'German', year: 1994 },
        { title: 'Graphics Design', year: 1994 },
        { title: 'Geology', year: 1994 },
        { title: 'Heritage Crafts', year: 1994 },
        { title: 'Hindi', year: 1994 },
        { title: 'History', year: 1994 },
        { title: 'Home Science', year: 1994 },
        { title: 'Human Rights & Gender Studies', year: 1994 },
        { title: 'Information Practices', year: 1994 },
        { title: 'Information Technology', year: 1994 },
        { title: 'Law', year: 1994 },
        { title: 'Legal Studies', year: 1994 },
        { title: 'Library & Information', year: 1994 },
        { title: 'Library & Information Science', year: 1994 },
        { title: 'Logic', year: 1994 },
        { title: 'Macroeconomics', year: 1994 },
        { title: 'Marathi', year: 1994 },
        { title: 'Mass Communication', year: 1994 },
        { title: 'Mas Media Studies', year: 1994 },
        { title: 'Mathematics', year: 1994 },
        { title: 'Mathematics & Statistics', year: 1994 },
        { title: 'Microeconomics', year: 1994 },
        { title: 'Music', year: 1994 },
        { title: 'New Age Graphics Design', year: 1994 },
        { title: 'Organisation of Commerce', year: 1994 },
        { title: 'Pali', year: 1994 },
        { title: 'Persian', year: 1994 },
        { title: 'Philosophy', year: 1994 },
        { title: 'Physical Education', year: 1994 },
        { title: 'Physics', year: 1994 },
        { title: 'Political Science', year: 1994 },
        { title: 'Psychology', year: 1994 },
        { title: 'Public Administration', year: 1994 },
        { title: 'Punjabi', year: 1994 },
        { title: 'Retail Merchandising', year: 1994 },
        { title: 'Sanskrit', year: 1994 },
        { title: 'Secretarial Practice', year: 1994 },
        { title: 'Science', year: 1994 },
        { title: 'Science & Technology', year: 1994 },
        { title: 'Sociology', year: 1994 },
        { title: 'Statistics', year: 1994 },
        { title: 'Statistics for Economics', year: 1994 },
        { title: 'Stenography', year: 1994 },
        { title: 'Urdu', year: 1994 },
        { title: 'Zoology', year: 1994 },
    ]

    const [showErrorStatus, setshowErrorStatus] = useState({
        addSchoolClassMedium: { error: false, helperText: '' },
        addSchoolClass: { error: false, helperText: '' },
        addSchoolClassSection: { error: false, helperText: '' },
        addSchoolClassSectionSubject: { error: false, helperText: '' },
        addSchoolClassSectionSubjectList: { error: false },
    })

    const [classListAddOrRem, setclassListAddOrRem] = useState([]);
    const [schoolId, setschoolId] = useState('')
    const [schoolRegInfo, setschoolRegInfo] = useState(null)
    const [schoolData, setschoolData] = useState(null)
    useEffect(() => {
        const unSubscribe = onAuthStateChanged(auth, async (userCredentials) => {
            if (userCredentials) {
                setschoolId(userCredentials.uid)
                var uid = userCredentials.uid;
                onSnapshot(doc(dB, "SCHOOLS", uid), (schlDoc) => {
                    if (schlDoc.exists()) {
                        setschoolData(schlDoc.data())
                    }
                })
            }
        });
        return () => { unSubscribe(); }
    }, [])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolData && schoolId) {
                onSnapshot(doc(dB, "SCHOOLS", schoolId, schoolData?.SCHOOL_NAME, "CLASSES_DETAILS"), (clsDOc) => {
                    if (clsDOc.exists()) { setclassListAddOrRem(clsDOc.data()?.CLASS_LIST) }
                })
                onSnapshot(doc(dB, "SCHOOLS", schoolId, schoolData?.SCHOOL_NAME, "REGISTRATION_INFO"), (regDoc) => {
                    if (regDoc.exists()) { setschoolRegInfo(regDoc.data()) }
                })
            }
        }
        return () => { aaa = false }
    }, [schoolData, schoolId])


    const [dropdownClass, setdropdownClass] = useState(false)
    const handleDropdownClass = (e) => {
        e.preventDefault();
        setdropdownClass(!dropdownClass)
    }
    const [dropdownSubject, setdropdownSubject] = useState(false)
    const handleDropdownSubject = (e) => {
        e.preventDefault();
        setdropdownSubject(!dropdownSubject)
    }

    const [showBlockData, setshowBlockData] = useState({
        addSchoolClass: false, removeSchoolClass: false, addClassSubject: false, removeClassSubject: false,
    })
    const handleAddSchoolClass = (e) => {
        e.preventDefault();
        setaddNewSubject(null)
        setshowBlockData(prev => { return { ...prev, addSchoolClass: true, removeSchoolClass: false, addClassSubject: false, removeClassSubject: false, } })
        setaddClassData(prev => { return { ...prev, schoolClass: '', schoolClassSection: '', schoolClassSectionSubject: '' } })
        setAddClassMedium('');
        setafterClick(prev => { return { ...prev, classAdded: false, classRemoved: false, SubjectAdded: false, SubjectRemoved: false, } })
        if (subjectsList.length > 0) { subjectsList.splice(0, subjectsList.length) }
        setshowErrorStatus(prev => {
            return {
                ...prev, addSchoolClassMedium: { error: false, helperText: '' },
                addSchoolClass: { error: false, helperText: '' },
                addSchoolClassSection: { error: false, helperText: '' },
                addSchoolClassSectionSubject: { error: false, helperText: '' },
                addSchoolClassSectionSubjectList: { error: false },
            }
        })
    }
    const handleRemoveSchoolClass = (e) => {
        e.preventDefault();
        setaddNewSubject(null)
        setshowBlockData(prev => { return { ...prev, addSchoolClass: false, removeSchoolClass: true, addClassSubject: false, removeClassSubject: false, } })
        setaddClassData(prev => { return { ...prev, schoolClass: '', schoolClassSection: '', schoolClassSectionSubject: '' } })
        setAddClassMedium('');
        setafterClick(prev => { return { ...prev, classAdded: false, classRemoved: false, SubjectAdded: false, SubjectRemoved: false, } })
        if (subjectsList.length > 0) { subjectsList.splice(0, subjectsList.length) }
        setshowErrorStatus(prev => {
            return {
                ...prev, addSchoolClassMedium: { error: false, helperText: '' },
                addSchoolClass: { error: false, helperText: '' },
                addSchoolClassSection: { error: false, helperText: '' },
                addSchoolClassSectionSubject: { error: false, helperText: '' },
                addSchoolClassSectionSubjectList: { error: false },
            }
        })
    }
    const handleAddClassSubject = (e) => {
        e.preventDefault();
        setaddNewSubject(null)
        setshowBlockData(prev => { return { ...prev, addSchoolClass: false, removeSchoolClass: false, addClassSubject: true, removeClassSubject: false, } })
        setaddClassData(prev => { return { ...prev, schoolClass: '', schoolClassSection: '', schoolClassSectionSubject: '' } })
        setAddClassMedium('');
        setafterClick(prev => { return { ...prev, classAdded: false, classRemoved: false, SubjectAdded: false, SubjectRemoved: false, } })
        if (subjectsList.length > 0) { subjectsList.splice(0, subjectsList.length) }
        setshowErrorStatus(prev => {
            return {
                ...prev, addSchoolClassMedium: { error: false, helperText: '' },
                addSchoolClass: { error: false, helperText: '' },
                addSchoolClassSection: { error: false, helperText: '' },
                addSchoolClassSectionSubject: { error: false, helperText: '' },
                addSchoolClassSectionSubjectList: { error: false },
            }
        })
    }
    const handleRemoveClassSubject = (e) => {
        e.preventDefault();
        setaddNewSubject(null)
        setshowBlockData(prev => { return { ...prev, addSchoolClass: false, removeSchoolClass: false, addClassSubject: false, removeClassSubject: true, } })
        setaddClassData(prev => { return { ...prev, schoolClass: '', schoolClassSection: '', schoolClassSectionSubject: '' } })
        setAddClassMedium('');
        setafterClick(prev => { return { ...prev, classAdded: false, classRemoved: false, SubjectAdded: false, SubjectRemoved: false, } })
        if (subjectsList.length > 0) { subjectsList.splice(0, subjectsList.length) }
        setshowErrorStatus(prev => {
            return {
                ...prev, addSchoolClassMedium: { error: false, helperText: '' },
                addSchoolClass: { error: false, helperText: '' },
                addSchoolClassSection: { error: false, helperText: '' },
                addSchoolClassSectionSubject: { error: false, helperText: '' },
                addSchoolClassSectionSubjectList: { error: false },
            }
        })
    }

    const [addClassMedium, setAddClassMedium] = useState('')
    const handleMediumValueChange = (event) => {
        event.preventDefault()
        setAddClassMedium(event.target.value);
    };
    const handleMediumChange = (e) => {
        e.preventDefault();
        setshowErrorStatus(prev => { return { ...prev, addSchoolClassMedium: { error: false, helperText: '' } } })
        setaddClassData(prev => { return { ...prev, schoolClass: '', schoolClassSection: '', schoolClassSectionSubject: '' } })
        setafterClick(prev => { return { ...prev, classAdded: false, classRemoved: false, SubjectAdded: false, SubjectRemoved: false, } })
        if (subjectsList.length > 0) { subjectsList.splice(0, subjectsList.length) }
    }

    const [addClassData, setaddClassData] = useState({
        schoolClass: '', schoolClassSection: '', schoolClassSectionSubject: ''
    })
    const handleChangeAddOrRemClass = (e) => {
        e.preventDefault();
        setaddClassData(prev => { return { ...prev, schoolClass: e.target.value } })
        setshowErrorStatus(prev => { return { ...prev, addSchoolClass: { error: false, helperText: '' } } })
        setaddClassData(prev => { return { ...prev, schoolClassSection: '', schoolClassSectionSubject: '' } })
        setafterClick(prev => { return { ...prev, classAdded: false, classRemoved: false, SubjectAdded: false, SubjectRemoved: false, } })
        if (subjectsList.length > 0) { subjectsList.splice(0, subjectsList.length) }
    }
    const handleChangeAddOrRemSection = (e) => {
        e.preventDefault();
        setaddClassData(prev => { return { ...prev, schoolClassSection: e.target.value } })
        setaddClassData(prev => { return { ...prev, schoolClassSectionSubject: '' } })
        setshowErrorStatus(prev => { return { ...prev, addSchoolClassSection: { error: false, helperText: '' } } })
        setafterClick(prev => { return { ...prev, classAdded: false, classRemoved: false, SubjectAdded: false, SubjectRemoved: false, } })
        if (subjectsList.length > 0) { subjectsList.splice(0, subjectsList.length) }
    }
    const handleChangeAddOrRemSubject = (e) => {
        e.preventDefault();
        setaddClassData(prev => { return { ...prev, schoolClassSectionSubject: e.target.value } })
        setshowErrorStatus(prev => { return { ...prev, addSchoolClassSectionSubject: { error: false, helperText: '' } } })
        setafterClick(prev => { return { ...prev, classAdded: false, classRemoved: false, SubjectAdded: false, SubjectRemoved: false, } })
    }




    const [returnedAddOrRemSectionList, setreturnedAddOrRemSectionList] = useState(null);
    useEffect(() => {
        setreturnedAddOrRemSectionList(null)
        var aaa = true
        if (aaa === true) {
            var sectionObject = returnSection(addClassMedium, addClassData.schoolClass, classListAddOrRem)
            setreturnedAddOrRemSectionList(sectionObject)
        }
        return () => { aaa = false }
    }, [addClassData.schoolClass, addClassMedium, classListAddOrRem])

    const [addOrRemMedClsSec, setaddOrRemMedClsSec] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var abc = returnMedClsSec(addClassMedium, addClassData.schoolClass, addClassData.schoolClassSection)
            setaddOrRemMedClsSec(abc)
        }
        return () => { aaa = false }
    }, [addClassData.schoolClass, addClassData.schoolClassSection, addClassMedium])

    const [subjectListAddOrRem, setsubjectListAddOrRem] = useState(null);
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolData?.SCHOOL_NAME && addOrRemMedClsSec &&
                addClassMedium && addClassData.schoolClass && addClassData.schoolClassSection) {
                onSnapshot(doc(dB, "SCHOOLS", schoolId, schoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", addOrRemMedClsSec), (mdclssecDoc) => {
                    if (mdclssecDoc.exists()) {
                        setsubjectListAddOrRem(mdclssecDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [addClassData.schoolClass, addClassData.schoolClassSection, addClassMedium, addOrRemMedClsSec, schoolData?.SCHOOL_NAME, schoolId])

    // const removeSubject = 



    const [subjectsList, setsubjectsList] = useState([]);
    const handleChange = (event) => {
        event.preventDefault()
        setsubjectsList(event.target.value);
        setshowErrorStatus(prev => { return { ...prev, addSchoolClassSectionSubjectList: { error: false } } })
    };

    const [afterClick, setafterClick] = useState({
        classAdded: false, classRemoved: false,
        SubjectAdded: false, SubjectRemoved: false,
    })
    const [btnLoading, setbtnLoading] = useState(false)
    const handleClickAddClass = async (e) => {
        e.preventDefault();
        if (addClassMedium && addClassData.schoolClass && addClassData.schoolClassSection && subjectsList.length > 0) {
            setbtnLoading(true)

            batch.update(doc(dB, "SCHOOLS", schoolId, schoolData?.SCHOOL_NAME, "CLASSES_DETAILS"), {
                CLASS_LIST: arrayUnion(addOrRemMedClsSec)
            })

            batch.set(doc(dB, "SCHOOLS", schoolId, schoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", addOrRemMedClsSec), {
                SUBJECTS_LIST: subjectsList
            })

            // Commit the batch
            await batch.commit().then(() => {
                setbtnLoading(false)
                // setbuttonDisabled(prev => { return { ...prev, classAdded: false } })
                setafterClick(prev => { return { ...prev, classAdded: true } })
                setaddClassData(prev => { return { ...prev, schoolClass: '', schoolClassSection: '', schoolClassSectionSubject: '' } })
                setAddClassMedium('');
            }).catch(err => alert(err.message));
        } else {
            if (!addClassMedium) { setshowErrorStatus(prev => { return { ...prev, addSchoolClassMedium: { error: true, helperText: 'Please Select the Medium' } } }) }
            if (!addClassData.schoolClass) { setshowErrorStatus(prev => { return { ...prev, addSchoolClass: { error: true, helperText: 'Please Select the Class' } } }) }
            if (!addClassData.schoolClassSection) { setshowErrorStatus(prev => { return { ...prev, addSchoolClassSection: { error: true, helperText: 'Please Select the Section' } } }) }
            if (!subjectsList.length > 0) { setshowErrorStatus(prev => { return { ...prev, addSchoolClassSectionSubjectList: { error: true } } }) }

        }
    }
    const handleClickRemoveClass = async (e) => {
        e.preventDefault();
        // setbuttonDisabled(prev => { return { ...prev, classRemoved: true } })
        if (addClassMedium && addClassData.schoolClass && addClassData.schoolClassSection) {
            setbtnLoading(true)
            const q = doc(dB, "SCHOOLS", schoolId, schoolData?.SCHOOL_NAME, "CLASSES_DETAILS")
            const snap = await getDoc(q).catch(err => alert(err.message))
            if (snap.exists()) {
                await batch.update(q, { CLASS_LIST: arrayRemove(addOrRemMedClsSec) })
            }

            batch.delete(doc(dB, "SCHOOLS", schoolId, schoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", addOrRemMedClsSec))

            // Commit the batch
            await batch.commit().then(() => {
                setbtnLoading(false)
                // setbuttonDisabled(prev => { return { ...prev, classRemoved: false } })
                setafterClick(prev => { return { ...prev, classRemoved: true } })
                setaddClassData(prev => { return { ...prev, schoolClass: '', schoolClassSection: '', schoolClassSectionSubject: '' } })
                setAddClassMedium('');
            }).catch(err => alert(err.message));
        } else {
            if (!addClassMedium) { setshowErrorStatus(prev => { return { ...prev, addSchoolClassMedium: { error: true, helperText: 'Please Select the Medium' } } }) }
            if (!addClassData.schoolClass) { setshowErrorStatus(prev => { return { ...prev, addSchoolClass: { error: true, helperText: 'Please Select the Class' } } }) }
            if (!addClassData.schoolClassSection) { setshowErrorStatus(prev => { return { ...prev, addSchoolClassSection: { error: true, helperText: 'Please Select the Section' } } }) }
        }
    }
    const handleClickAddSubject = async (e) => {
        e.preventDefault();
        // setbuttonDisabled(prev => { return { ...prev, SubjectAdded: true } })
        if (addClassMedium && addClassData.schoolClass && addClassData.schoolClassSection && addNewSubject?.title) {
            setbtnLoading(true)
            const q = doc(dB, "SCHOOLS", schoolId, schoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", addOrRemMedClsSec)
            const snap = await getDoc(q).catch(err => alert(err.message))
            if (snap.exists()) {
                updateDoc(q, {
                    SUBJECTS_LIST: arrayUnion(addNewSubject?.title)
                }).then(() => {
                    setbtnLoading(false)
                    // setbuttonDisabled(prev => { return { ...prev, SubjectAdded: false } })
                    setafterClick(prev => { return { ...prev, SubjectAdded: true } })
                    setaddClassData(prev => { return { ...prev, schoolClass: '', schoolClassSection: '', schoolClassSectionSubject: '' } })
                    setAddClassMedium('');
                    setaddNewSubject(null)
                }).catch(err => alert(err.message));
            }
        } else {
            if (!addClassMedium) { setshowErrorStatus(prev => { return { ...prev, addSchoolClassMedium: { error: true, helperText: 'Please Select the Medium' } } }) }
            if (!addClassData.schoolClass) { setshowErrorStatus(prev => { return { ...prev, addSchoolClass: { error: true, helperText: 'Please Select the Class' } } }) }
            if (!addClassData.schoolClassSection) { setshowErrorStatus(prev => { return { ...prev, addSchoolClassSection: { error: true, helperText: 'Please Select the Section' } } }) }
            if (!addNewSubject?.title) { setshowErrorStatus(prev => { return { ...prev, addSchoolClassSectionSubject: { error: true, helperText: 'Please Select the Subject to be added' } } }) }
        }
    }

    const [totalVeriRequs, settotalVeriRequs] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolData?.SCHOOL_NAME && addOrRemMedClsSec && addClassData.schoolClassSectionSubject) {
                onSnapshot(collection(dB, "SCHOOLS", schoolId, schoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", addOrRemMedClsSec, "TEACHERS", "VERIFICATION_REQUESTS", addClassData.schoolClassSectionSubject), (snap) => {
                    snap.forEach(idDoc => settotalVeriRequs(prev => [...prev, idDoc.id]))
                })
            }
        }
        return () => { aaa = false }
    }, [addClassData.schoolClassSectionSubject, addOrRemMedClsSec, schoolData?.SCHOOL_NAME, schoolId])
    const [verifiedteacher, setverifiedteacher] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolData?.SCHOOL_NAME && addOrRemMedClsSec && addClassData.schoolClassSectionSubject) {
                onSnapshot(doc(dB, "SCHOOLS", schoolId, schoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", addOrRemMedClsSec, "TEACHERS", "VERIFIED_MEMBERS", addClassData.schoolClassSectionSubject, "VERIFIED_MEMBERS_DETAILS"), (snap) => {
                    if (snap.exists()) {
                        setverifiedteacher(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [addClassData.schoolClassSectionSubject, addOrRemMedClsSec, schoolData?.SCHOOL_NAME, schoolId])
    const [teacherPathCollection, setteacherPathCollection] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (verifiedteacher) {
                onSnapshot(doc(dB, "USERS", verifiedteacher?.TEACHERS_ID), (snap) => {
                    if (snap.exists()) {
                        setteacherPathCollection(snap.data()?.USER_NAME)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [verifiedteacher])

    const handleClickRemoveSubject = async (e) => {
        e.preventDefault();
        // setbuttonDisabled(prev => { return { ...prev, SubjectRemoved: true } })
        if (addClassMedium && addClassData.schoolClass && addClassData.schoolClassSection && addClassData.schoolClassSectionSubject) {
            setbtnLoading(true)
            if (totalVeriRequs?.length) {
                const q = collection(dB, "SCHOOLS", schoolId, schoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", addOrRemMedClsSec, "TEACHERS", "VERIFICATION_REQUESTS", addClassData.schoolClassSectionSubject)
                const snap = getDocs(q)
                snap.forEach(async (snapDOc) => {
                    await batch.delete(doc(dB, "SCHOOLS", schoolId, schoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", addOrRemMedClsSec, "TEACHERS", "VERIFICATION_REQUESTS", addClassData.schoolClassSectionSubject, snapDOc.id))
                });
            }

            if (verifiedteacher) {
                await batch.delete(doc(dB, "SCHOOLS", schoolId, schoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", addOrRemMedClsSec, "TEACHERS", "VERIFIED_MEMBERS", addClassData.schoolClassSectionSubject, "VERIFIED_MEMBERS_DETAILS"))
                await batch.delete(doc(dB, "USERS", verifiedteacher?.TEACHERS_ID, teacherPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "IS_TEACHER_DATA", "SUBJECTS_TEACH", verifiedteacher?.TEACHERS_SUBJECT_ID))
            }


            batch.update(doc(dB, "SCHOOLS", schoolId, schoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", addOrRemMedClsSec), {
                SUBJECTS_LIST: arrayRemove(addClassData.schoolClassSectionSubject)
            })

            // Commit the batch
            batch.commit().then(() => {
                setbtnLoading(false)
                // setbuttonDisabled(prev => { return { ...prev, SubjectRemoved: false } })
                setafterClick(prev => { return { ...prev, SubjectRemoved: true } })
                setaddClassData(prev => { return { ...prev, schoolClass: '', schoolClassSection: '', schoolClassSectionSubject: '' } })
                setAddClassMedium('');
            }).catch(err => alert(err.message));

        } else {
            if (!addClassMedium) { setshowErrorStatus(prev => { return { ...prev, addSchoolClassMedium: { error: true, helperText: 'Please Select the Medium' } } }) }
            if (!addClassData.schoolClass) { setshowErrorStatus(prev => { return { ...prev, addSchoolClass: { error: true, helperText: 'Please Select the Class' } } }) }
            if (!addClassData.schoolClassSection) { setshowErrorStatus(prev => { return { ...prev, addSchoolClassSection: { error: true, helperText: 'Please Select the Section' } } }) }
            if (!addClassData.schoolClassSectionSubject) { setshowErrorStatus(prev => { return { ...prev, addSchoolClassSectionSubject: { error: true, helperText: 'Please Select the Subject to be removed' } } }) }
        }
    }

    const [addNewSubject, setaddNewSubject] = useState(null);
    const filter = createFilterOptions();

    return (
        <>
            {
                schoolId === '' ? (
                    <Login />
                ) : (
                    <div className='SchoolAddOrRemove'>
                        <Header />
                        <div className='SchoolAddOrRemove__Container'>
                            <div className='SchoolAddOrRemove__ContainerTop'>
                                <h3>{schoolData?.SCHOOL_FULL_NAME}</h3>
                                <h5>(K to 12th)</h5>
                                <h4>{schoolRegInfo?.SCHOOL_AFFILIATION}</h4>
                                <hr />
                            </div>
                            <div className='SchoolAddOrRemove__ContainerBottom'>

                                <div className='SchoolAddOrRemove__ContainerBottomLeft'>
                                    <nav className='schoolverifiedmembers__containerLeft'>
                                        <div id='schoolStaff' className='schoolStaff'>
                                            <div name='title' className='title' onClick={handleDropdownClass}>
                                                <h2>Class</h2>
                                                {dropdownClass ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                                            </div>
                                            <div style={{ display: dropdownClass ? 'flex' : 'none' }} className='schoolClass'>
                                                <div className='schoolClassAdd' onClick={handleAddSchoolClass}>
                                                    <ArrowRightIcon />
                                                    <h2>Add Class</h2>
                                                </div>
                                                <div className='schoolClassRemove' onClick={handleRemoveSchoolClass}>
                                                    <ArrowRightIcon />
                                                    <h2>Remove Class</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div id='parents' className='parents'>
                                            <div name='subTitle' className='title' onClick={handleDropdownSubject}>
                                                <h2>Subjects</h2>
                                                {dropdownSubject ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                                            </div>
                                            <div style={{ display: dropdownSubject ? 'flex' : 'none' }} className='schoolClassSubject'>
                                                <div className='schoolClassSubjectAdd' onClick={handleAddClassSubject}>
                                                    <ArrowRightIcon />
                                                    <h2>Add Subject</h2>
                                                </div>
                                                <div className='schoolClassSubjectRemove' onClick={handleRemoveClassSubject}>
                                                    <ArrowRightIcon />
                                                    <h2>Remove Subject</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>

                                <div className='SchoolAddOrRemove__ContainerBottomRight'>
                                    {
                                        showBlockData.addSchoolClass && (
                                            <div className='SchoolAddOrRemove__AddClass'>
                                                <h2>Add a Class</h2>
                                                <div className='SchoolAddOrRemove__AddClassDiv'>
                                                    <div className='SchoolAddOrRemove__radio'>
                                                        <FormControl error={showErrorStatus.addSchoolClassMedium.error} component="fieldset" onChange={handleMediumChange}>
                                                            <div className='SchoolAddOrRemove__radioMediumMuiDiv'>
                                                                <FormLabel component="legend">Select Medium</FormLabel>
                                                                <RadioGroup aria-label="addClassMedium" name="addClassMedium" value={addClassMedium} onChange={handleMediumValueChange}>
                                                                    <div className='SchoolAddOrRemove__radioMediumMui'>
                                                                        <FormControlLabel value="English Medium" control={<Radio />} label="English Medium" />
                                                                        <FormControlLabel value="Marathi Medium" control={<Radio />} label="Marathi Medium" />
                                                                    </div>
                                                                </RadioGroup>
                                                            </div>
                                                        </FormControl>
                                                        <FormHelperText id="addClassMedium"><p>{showErrorStatus.addSchoolClassMedium.helperText}</p></FormHelperText>
                                                    </div>

                                                    <div className='SchoolAddOrRemoveClassSection'>
                                                        <div className='SchoolAddOrRemove__schoolClass'>
                                                            <div className='SchoolAddOrRemove__schoolClass'>
                                                                <FormControl error={showErrorStatus.addSchoolClass.error} name='schoolClass' variant="outlined" /*sx={{width: '37ch',}}*/>
                                                                    <InputLabel htmlFor="schoolClass">Class :</InputLabel>
                                                                    <Select
                                                                        sx={{ width: '37ch', }}
                                                                        id="schoolClass"
                                                                        name='schoolClass'
                                                                        // native
                                                                        label="Class : "
                                                                        inputProps={{ name: 'schoolClass', id: 'schoolClass', }}
                                                                        value={addClassData.schoolClass}
                                                                        onChange={handleChangeAddOrRemClass}
                                                                    >
                                                                        <MenuItem sx={{ display: addClassMedium != null ? 'none' : 'block' }} aria-label="None" value="" />

                                                                        <MenuItem sx={{ display: addClassMedium === 'English Medium' ? 'block' : 'none' }} id='Eng Pre Nursery' key='Eng Pre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'English Medium' ? 'block' : 'none' }} id='Eng Nursery' key='Eng Nursery' value='Nursery'>Nursery</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'English Medium' ? 'block' : 'none' }} id='Eng Kindergarten 1(KG-1)' key='Eng Kindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'English Medium' ? 'block' : 'none' }} id='Eng Kindergarten 2(KG-2)' key='Eng Kindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'English Medium' ? 'block' : 'none' }} id='Eng Class 1' key='Eng Class 1' value='Class 1'>Class 1</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'English Medium' ? 'block' : 'none' }} id='Eng Class 2' key='Eng Class 2' value='Class 2'>Class 2</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'English Medium' ? 'block' : 'none' }} id='Eng Class 3' key='Eng Class 3' value='Class 3'>Class 3</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'English Medium' ? 'block' : 'none' }} id='Eng Class 4' key='Eng Class 4' value='Class 4'>Class 4</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'English Medium' ? 'block' : 'none' }} id='Eng Class 5' key='Eng Class 5' value='Class 5'>Class 5</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'English Medium' ? 'block' : 'none' }} id='Eng Class 6' key='Eng Class 6' value='Class 6'>Class 6</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'English Medium' ? 'block' : 'none' }} id='Eng Class 7' key='Eng Class 7' value='Class 7'>Class 7</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'English Medium' ? 'block' : 'none' }} id='Eng Class 8' key='Eng Class 8' value='Class 8'>Class 8</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'English Medium' ? 'block' : 'none' }} id='Eng Class 9' key='Eng Class 9' value='Class 9'>Class 9</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'English Medium' ? 'block' : 'none' }} id='Eng Class 10' key='Eng Class 10' value='Class 10'>Class 10</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'English Medium' ? 'block' : 'none' }} id='Eng Class 11(Science)' key='Eng Class 11(Science)' value='Class 11(Science)'>Class 11(Science)</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'English Medium' ? 'block' : 'none' }} id='Eng Class 11(Commerce)' key='Eng Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'English Medium' ? 'block' : 'none' }} id='Eng Class 12(Science)' key='Eng Class 12(Science)' value='Class 12(Science)'>Class 12(Science)</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'English Medium' ? 'block' : 'none' }} id='Eng Class 12(Commerce)' key='Eng Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>

                                                                        <MenuItem sx={{ display: addClassMedium === 'Marathi Medium' ? 'block' : 'none' }} id='Mar Pre Nursery' key='Mar Pre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'Marathi Medium' ? 'block' : 'none' }} id='Mar Nursery' key='Mar Nursery' value='Nursery'>Nursery</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'Marathi Medium' ? 'block' : 'none' }} id='Mar Kindergarten 1(KG-1)' key='Mar Kindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'Marathi Medium' ? 'block' : 'none' }} id='Mar Kindergarten 2(KG-2)' key='Mar Kindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>

                                                                        <MenuItem sx={{ display: addClassMedium === 'Marathi Medium' ? 'block' : 'none' }} id='Mar Class 1' key='Mar Class 1' value='Class 1'>Class 1</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'Marathi Medium' ? 'block' : 'none' }} id='Mar Class 2' key='Mar Class 2' value='Class 2'>Class 2</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'Marathi Medium' ? 'block' : 'none' }} id='Mar Class 3' key='Mar Class 3' value='Class 3'>Class 3</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'Marathi Medium' ? 'block' : 'none' }} id='Mar Class 4' key='Mar Class 4' value='Class 4'>Class 4</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'Marathi Medium' ? 'block' : 'none' }} id='Mar Class 5' key='Mar Class 5' value='Class 5'>Class 5</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'Marathi Medium' ? 'block' : 'none' }} id='Mar Class 6' key='Mar Class 6' value='Class 6'>Class 6</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'Marathi Medium' ? 'block' : 'none' }} id='Mar Class 7' key='Mar Class 7' value='Class 7'>Class 7</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'Marathi Medium' ? 'block' : 'none' }} id='Mar Class 8' key='Mar Class 8' value='Class 8'>Class 8</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'Marathi Medium' ? 'block' : 'none' }} id='Mar Class 9' key='Mar Class 9' value='Class 9'>Class 9</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'Marathi Medium' ? 'block' : 'none' }} id='Mar Class 10' key='Mar Class 10' value='Class 10'>Class 10</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'Marathi Medium' ? 'block' : 'none' }} id='Mar Class 11(Arts)' key='Mar Class 11(Arts)' value='Class 11(Arts)'>Class 11(Arts)</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'Marathi Medium' ? 'block' : 'none' }} id='Mar Class 11(Commerce)' key='Mar Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'Marathi Medium' ? 'block' : 'none' }} id='Mar Class 12(Arts)' key='Mar Class 12(Arts)' value='Class 12(Arts)'>Class 12(Arts)</MenuItem>
                                                                        <MenuItem sx={{ display: addClassMedium === 'Marathi Medium' ? 'block' : 'none' }} id='Mar Class 12(Commerce)' key='Mar Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>
                                                                    </Select>
                                                                    <FormHelperText id="schoolClass"><p>{showErrorStatus.addSchoolClass.helperText}</p></FormHelperText>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className='SchoolAddOrRemove__schoolClassSection'>
                                                            <div className='SchoolAddOrRemove__schoolClassSection'>
                                                                <FormControl error={showErrorStatus.addSchoolClassSection.error} name='schoolClassSection' variant="outlined" /*sx={{width: '37ch',}}*/>
                                                                    <InputLabel htmlFor="schoolClassSection">Section :</InputLabel>
                                                                    <Select
                                                                        sx={{ width: '37ch', }}
                                                                        id="schoolClassSection"
                                                                        name='schoolClassSection'
                                                                        // native 
                                                                        label="Section"
                                                                        inputProps={{
                                                                            name: 'schoolClassSection',
                                                                            id: 'schoolClassSection',
                                                                        }}
                                                                        value={addClassData.schoolClassSection}
                                                                        onChange={handleChangeAddOrRemSection}
                                                                    >
                                                                        <MenuItem key='' value=''></MenuItem>
                                                                        <MenuItem
                                                                            style={{ display: returnedAddOrRemSectionList?.SectionA ? 'none' : 'flex' }}
                                                                    /*disabled={returnedAddOrRemSectionList?.SectionA ? true : false}*/ key='Section A' value='Section A'>Section A</MenuItem>
                                                                        <MenuItem
                                                                            style={{ display: returnedAddOrRemSectionList?.SectionB ? 'none' : 'flex' }}
                                                                            disabled={!returnedAddOrRemSectionList?.SectionA ? true : false} key='Section B' value='Section B'>Section B</MenuItem>
                                                                        <MenuItem
                                                                            style={{ display: returnedAddOrRemSectionList?.SectionC ? 'none' : 'flex' }}
                                                                            disabled={!returnedAddOrRemSectionList?.SectionB ? true : false} key='Section C' value='Section C'>Section C</MenuItem>
                                                                        <MenuItem
                                                                            style={{ display: returnedAddOrRemSectionList?.SectionD ? 'none' : 'flex' }}
                                                                            disabled={!returnedAddOrRemSectionList?.SectionC ? true : false} key='Section D' value='Section D'>Section D</MenuItem>
                                                                        <MenuItem
                                                                            style={{ display: returnedAddOrRemSectionList?.SectionE ? 'none' : 'flex' }}
                                                                            disabled={!returnedAddOrRemSectionList?.SectionD ? true : false} key='Section E' value='Section E'>Section E</MenuItem>
                                                                    </Select>
                                                                    <FormHelperText id="schoolClassSection"><p>{showErrorStatus.addSchoolClassSection.helperText}</p></FormHelperText>
                                                                </FormControl>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div style={{ display: addClassMedium && addClassData.schoolClass && addClassData.schoolClassSection ? 'flex' : 'none' }} className='selectedSubjectsList'>
                                                        <FormControl error={showErrorStatus.addSchoolClassSectionSubjectList.error} sx={{ minWidth: 530, maxWidth: 530, }}>
                                                            <InputLabel id="addSubjectsList">Select Subjects for {addClassMedium} {addClassData.schoolClass} {addClassData.schoolClassSection}</InputLabel>
                                                            <Select
                                                                labelId="addSubjectsList"
                                                                id="addSubjectsList"
                                                                multiple
                                                                value={subjectsList}
                                                                onChange={handleChange}
                                                                input={<Input id="saddSubjectsList" />}
                                                                renderValue={(selected) => (
                                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', }}>
                                                                        {selected.map((value) => (
                                                                            <Chip key={value} label={value} sx={{ margin: 0.3, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />
                                                                        ))}
                                                                    </Box>
                                                                )}
                                                                MenuProps={MenuProps}
                                                            >
                                                                {subjectList.map((subject) => (
                                                                    <MenuItem key={subject} value={subject}>
                                                                        {subject}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            <FormHelperText>Total<span className='subNumber'><p>{subjectsList.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                        </FormControl>
                                                    </div>

                                                    <div className='SchoolAddOrRemove__Button'>
                                                        <LoadingButton
                                                            variant='outlined'
                                                            loading={btnLoading}
                                                            onClick={handleClickAddClass}
                                                        >
                                                            Add Class
                                                        </LoadingButton>
                                                        {/* <Button disabled={buttonDisabled.classAdded} ></Button> */}
                                                    </div>
                                                    <div style={{ display: afterClick.classAdded ? 'flex' : 'none' }} className='afterClickMsg'>
                                                        <h4>Class is added</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        showBlockData.removeSchoolClass && (
                                            <div className='SchoolAddOrRemove__AddClass'>
                                                <h2>Remove a Class</h2>
                                                <div className='SchoolAddOrRemove__AddClassDiv'>
                                                    <div className='SchoolAddOrRemove__radio'>
                                                        <FormControl error={showErrorStatus.addSchoolClassMedium.error} component="fieldset" onChange={handleMediumChange}>
                                                            <div className='SchoolAddOrRemove__radioMediumMuiDiv'>
                                                                <FormLabel component="legend">Select Medium</FormLabel>
                                                                <RadioGroup aria-label="addClassMedium" name="addClassMedium" value={addClassMedium} onChange={handleMediumValueChange}>
                                                                    <div className='SchoolAddOrRemove__radioMediumMui'>
                                                                        <FormControlLabel value="English Medium" control={<Radio />} label="English Medium" />
                                                                        <FormControlLabel value="Marathi Medium" control={<Radio />} label="Marathi Medium" />
                                                                    </div>
                                                                </RadioGroup>
                                                            </div>
                                                        </FormControl>
                                                        <FormHelperText id="addClassMedium"><p>{showErrorStatus.addSchoolClassMedium.helperText}</p></FormHelperText>
                                                    </div>

                                                    <div className='SchoolAddOrRemoveClassSection'>
                                                        <div className='SchoolAddOrRemove__schoolClass'>
                                                            <div className='SchoolAddOrRemove__schoolClass'>
                                                                <FormControl error={showErrorStatus.addSchoolClass.error} name='schoolClass' variant="outlined" /*sx={{width: '37ch',}}*/>
                                                                    <InputLabel htmlFor="schoolClass">Class :</InputLabel>
                                                                    <Select
                                                                        sx={{ width: '37ch', }}
                                                                        id="schoolClass"
                                                                        name='schoolClass'
                                                                        // native 
                                                                        label="Class : "
                                                                        inputProps={{ name: 'schoolClass', id: 'schoolClass', }}
                                                                        value={addClassData.schoolClass}
                                                                        onChange={handleChangeAddOrRemClass}
                                                                    >
                                                                        <MenuItem style={{ display: addClassMedium != null ? 'none' : 'block' }} aria-label="None" value="" />

                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngPreNurserySecA') ? 'block' : 'none' }} id='Eng Pre Nursery' key='Eng Pre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngNurserySecA') ? 'block' : 'none' }} id='Eng Nursery' key='Eng Nursery' value='Nursery'>Nursery</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngKG1SecA') ? 'block' : 'none' }} id='Eng Kindergarten 1(KG-1)' key='Eng Kindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngKG2SecA') ? 'block' : 'none' }} id='Eng Kindergarten 2(KG-2)' key='Eng Kindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass1SecA') ? 'block' : 'none' }} id='Eng Class 1' key='Eng Class 1' value='Class 1'>Class 1</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass2SecA') ? 'block' : 'none' }} id='Eng Class 2' key='Eng Class 2' value='Class 2'>Class 2</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass3SecA') ? 'block' : 'none' }} id='Eng Class 3' key='Eng Class 3' value='Class 3'>Class 3</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass4SecA') ? 'block' : 'none' }} id='Eng Class 4' key='Eng Class 4' value='Class 4'>Class 4</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass5SecA') ? 'block' : 'none' }} id='Eng Class 5' key='Eng Class 5' value='Class 5'>Class 5</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass6SecA') ? 'block' : 'none' }} id='Eng Class 6' key='Eng Class 6' value='Class 6'>Class 6</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass7SecA') ? 'block' : 'none' }} id='Eng Class 7' key='Eng Class 7' value='Class 7'>Class 7</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass8SecA') ? 'block' : 'none' }} id='Eng Class 8' key='Eng Class 8' value='Class 8'>Class 8</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass9SecA') ? 'block' : 'none' }} id='Eng Class 9' key='Eng Class 9' value='Class 9'>Class 9</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass10SecA') ? 'block' : 'none' }} id='Eng Class 10' key='Eng Class 10' value='Class 10'>Class 10</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass11ScienceSecA') ? 'block' : 'none' }} id='Eng Class 11(Science)' key='Eng Class 11(Science)' value='Class 11(Science)'>Class 11(Science)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass11CommerceSecA') ? 'block' : 'none' }} id='Eng Class 11(Commerce)' key='Eng Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass12ScienceSecA') ? 'block' : 'none' }} id='Eng Class 12(Science)' key='Eng Class 12(Science)' value='Class 12(Science)'>Class 12(Science)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass12CommerceSecA') ? 'block' : 'none' }} id='Eng Class 12(Commerce)' key='Eng Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>

                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarPreNurserySecA') ? 'block' : 'none' }} id='Mar Pre Nursery' key='Mar Pre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarNurserySecA') ? 'block' : 'none' }} id='Mar Nursery' key='Mar Nursery' value='Nursery'>Nursery</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarKG1SecA') ? 'block' : 'none' }} id='Mar Kindergarten 1(KG-1)' key='Mar Kindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarKG2SecA') ? 'block' : 'none' }} id='Mar Kindergarten 2(KG-2)' key='Mar Kindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>

                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass1SecA') ? 'block' : 'none' }} id='Mar Class 1' key='Mar Class 1' value='Class 1'>Class 1</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass2SecA') ? 'block' : 'none' }} id='Mar Class 2' key='Mar Class 2' value='Class 2'>Class 2</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass3SecA') ? 'block' : 'none' }} id='Mar Class 3' key='Mar Class 3' value='Class 3'>Class 3</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass4SecA') ? 'block' : 'none' }} id='Mar Class 4' key='Mar Class 4' value='Class 4'>Class 4</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass5SecA') ? 'block' : 'none' }} id='Mar Class 5' key='Mar Class 5' value='Class 5'>Class 5</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass6SecA') ? 'block' : 'none' }} id='Mar Class 6' key='Mar Class 6' value='Class 6'>Class 6</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass7SecA') ? 'block' : 'none' }} id='Mar Class 7' key='Mar Class 7' value='Class 7'>Class 7</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass8SecA') ? 'block' : 'none' }} id='Mar Class 8' key='Mar Class 8' value='Class 8'>Class 8</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass9SecA') ? 'block' : 'none' }} id='Mar Class 9' key='Mar Class 9' value='Class 9'>Class 9</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass10SecA') ? 'block' : 'none' }} id='Mar Class 10' key='Mar Class 10' value='Class 10'>Class 10</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass11ArtsSecA') ? 'block' : 'none' }} id='Mar Class 11(Arts)' key='Mar Class 11(Arts)' value='Class 11(Arts)'>Class 11(Arts)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass11CommerceSecA') ? 'block' : 'none' }} id='Mar Class 11(Commerce)' key='Mar Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass12ArtsSecA') ? 'block' : 'none' }} id='Mar Class 12(Arts)' key='Mar Class 12(Arts)' value='Class 12(Arts)'>Class 12(Arts)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass12CommerceSecA') ? 'block' : 'none' }} id='Mar Class 12(Commerce)' key='Mar Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>
                                                                    </Select>
                                                                    <FormHelperText id="schoolClass"><p>{showErrorStatus.addSchoolClass.helperText}</p></FormHelperText>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className='SchoolAddOrRemove__schoolClassSection'>
                                                            <div className='SchoolAddOrRemove__schoolClassSection'>
                                                                <FormControl error={showErrorStatus.addSchoolClassSection.error} name='schoolClassSection' variant="outlined" /*sx={{width: '37ch',}}*/>
                                                                    <InputLabel htmlFor="schoolClassSection">Section :</InputLabel>
                                                                    <Select
                                                                        sx={{ width: '37ch', }}
                                                                        id="schoolClassSection"
                                                                        name='schoolClassSection'
                                                                        // native 
                                                                        label="Section"
                                                                        inputProps={{
                                                                            name: 'schoolClassSection',
                                                                            id: 'schoolClassSection',
                                                                        }}
                                                                        value={addClassData.schoolClassSection}
                                                                        onChange={handleChangeAddOrRemSection}
                                                                    >
                                                                        <MenuItem key='' value=''></MenuItem>
                                                                        <MenuItem style={{ display: returnedAddOrRemSectionList?.SectionA ? 'flex' : 'none' }} disabled={returnedAddOrRemSectionList?.SectionB ? true : false} key='Section A' value='Section A'>Section A</MenuItem>
                                                                        <MenuItem style={{ display: returnedAddOrRemSectionList?.SectionB ? 'flex' : 'none' }} disabled={returnedAddOrRemSectionList?.SectionC ? true : false} key='Section B' value='Section B'>Section B</MenuItem>
                                                                        <MenuItem style={{ display: returnedAddOrRemSectionList?.SectionC ? 'flex' : 'none' }} disabled={returnedAddOrRemSectionList?.SectionD ? true : false} key='Section C' value='Section C'>Section C</MenuItem>
                                                                        <MenuItem style={{ display: returnedAddOrRemSectionList?.SectionD ? 'flex' : 'none' }} disabled={returnedAddOrRemSectionList?.SectionE ? true : false} key='Section D' value='Section D'>Section D</MenuItem>
                                                                        <MenuItem style={{ display: returnedAddOrRemSectionList?.SectionE ? 'flex' : 'none' }} /*disabled={returnedAddOrRemSectionList?.SectionE ? true : false}*/ key='Section E' value='Section E'>Section E</MenuItem>
                                                                    </Select>
                                                                    <FormHelperText id="schoolClassSection"><p>{showErrorStatus.addSchoolClassSection.helperText}</p></FormHelperText>
                                                                </FormControl>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className='SchoolAddOrRemove__Button'>
                                                        <LoadingButton
                                                            variant='outlined'
                                                            loading={btnLoading}
                                                            onClick={handleClickRemoveClass}
                                                        >
                                                            Remove Class
                                                        </LoadingButton>
                                                        {/* <Button disabled={buttonDisabled.classRemoved} ></Button> */}
                                                    </div>
                                                    <div style={{ display: afterClick.classRemoved ? 'flex' : 'none' }} className='afterClickMsg'>
                                                        <h4>Class is removed</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        showBlockData.addClassSubject && (
                                            <div className='SchoolAddOrRemove__AddClass'>
                                                <h2>Add a Subject</h2>
                                                <div className='SchoolAddOrRemove__AddClassDiv'>
                                                    <div className='SchoolAddOrRemove__radio'>
                                                        <FormControl error={showErrorStatus.addSchoolClassMedium.error} component="fieldset" onChange={handleMediumChange}>
                                                            <div className='SchoolAddOrRemove__radioMediumMuiDiv'>
                                                                <FormLabel component="legend">Select Medium</FormLabel>
                                                                <RadioGroup aria-label="addClassMedium" name="addClassMedium" value={addClassMedium} onChange={handleMediumValueChange}>
                                                                    <div className='SchoolAddOrRemove__radioMediumMui'>
                                                                        <FormControlLabel value="English Medium" control={<Radio />} label="English Medium" />
                                                                        <FormControlLabel value="Marathi Medium" control={<Radio />} label="Marathi Medium" />
                                                                    </div>
                                                                </RadioGroup>
                                                            </div>
                                                        </FormControl>
                                                        <FormHelperText id="addClassMedium"><p>{showErrorStatus.addSchoolClassMedium.helperText}</p></FormHelperText>
                                                    </div>

                                                    <div className='SchoolAddOrRemoveClassSection'>
                                                        <div className='SchoolAddOrRemove__schoolClass'>
                                                            <div className='SchoolAddOrRemove__schoolClass'>
                                                                <FormControl error={showErrorStatus.addSchoolClass.error} name='schoolClass' variant="outlined" /*sx={{width: '37ch',}}*/>
                                                                    <InputLabel htmlFor="schoolClass">Class :</InputLabel>
                                                                    <Select
                                                                        sx={{ width: '37ch', }}
                                                                        id="schoolClass"
                                                                        name='schoolClass'
                                                                        // native 
                                                                        label="Class : "
                                                                        inputProps={{ name: 'schoolClass', id: 'schoolClass', }}
                                                                        value={addClassData.schoolClass}
                                                                        onChange={handleChangeAddOrRemClass}
                                                                    >
                                                                        <MenuItem style={{ display: addClassMedium != null ? 'none' : 'block' }} aria-label="None" value="" />

                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngPreNurserySecA') ? 'block' : 'none' }} id='Eng Pre Nursery' key='Eng Pre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngNurserySecA') ? 'block' : 'none' }} id='Eng Nursery' key='Eng Nursery' value='Nursery'>Nursery</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngKG1SecA') ? 'block' : 'none' }} id='Eng Kindergarten 1(KG-1)' key='Eng Kindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngKG2SecA') ? 'block' : 'none' }} id='Eng Kindergarten 2(KG-2)' key='Eng Kindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass1SecA') ? 'block' : 'none' }} id='Eng Class 1' key='Eng Class 1' value='Class 1'>Class 1</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass2SecA') ? 'block' : 'none' }} id='Eng Class 2' key='Eng Class 2' value='Class 2'>Class 2</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass3SecA') ? 'block' : 'none' }} id='Eng Class 3' key='Eng Class 3' value='Class 3'>Class 3</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass4SecA') ? 'block' : 'none' }} id='Eng Class 4' key='Eng Class 4' value='Class 4'>Class 4</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass5SecA') ? 'block' : 'none' }} id='Eng Class 5' key='Eng Class 5' value='Class 5'>Class 5</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass6SecA') ? 'block' : 'none' }} id='Eng Class 6' key='Eng Class 6' value='Class 6'>Class 6</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass7SecA') ? 'block' : 'none' }} id='Eng Class 7' key='Eng Class 7' value='Class 7'>Class 7</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass8SecA') ? 'block' : 'none' }} id='Eng Class 8' key='Eng Class 8' value='Class 8'>Class 8</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass9SecA') ? 'block' : 'none' }} id='Eng Class 9' key='Eng Class 9' value='Class 9'>Class 9</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass10SecA') ? 'block' : 'none' }} id='Eng Class 10' key='Eng Class 10' value='Class 10'>Class 10</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass11ScienceSecA') ? 'block' : 'none' }} id='Eng Class 11(Science)' key='Eng Class 11(Science)' value='Class 11(Science)'>Class 11(Science)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass11CommerceSecA') ? 'block' : 'none' }} id='Eng Class 11(Commerce)' key='Eng Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass12ScienceSecA') ? 'block' : 'none' }} id='Eng Class 12(Science)' key='Eng Class 12(Science)' value='Class 12(Science)'>Class 12(Science)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass12CommerceSecA') ? 'block' : 'none' }} id='Eng Class 12(Commerce)' key='Eng Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>

                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarPreNurserySecA') ? 'block' : 'none' }} id='Mar Pre Nursery' key='Mar Pre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarNurserySecA') ? 'block' : 'none' }} id='Mar Nursery' key='Mar Nursery' value='Nursery'>Nursery</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarKG1SecA') ? 'block' : 'none' }} id='Mar Kindergarten 1(KG-1)' key='Mar Kindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarKG2SecA') ? 'block' : 'none' }} id='Mar Kindergarten 2(KG-2)' key='Mar Kindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>

                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass1SecA') ? 'block' : 'none' }} id='Mar Class 1' key='Mar Class 1' value='Class 1'>Class 1</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass2SecA') ? 'block' : 'none' }} id='Mar Class 2' key='Mar Class 2' value='Class 2'>Class 2</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass3SecA') ? 'block' : 'none' }} id='Mar Class 3' key='Mar Class 3' value='Class 3'>Class 3</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass4SecA') ? 'block' : 'none' }} id='Mar Class 4' key='Mar Class 4' value='Class 4'>Class 4</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass5SecA') ? 'block' : 'none' }} id='Mar Class 5' key='Mar Class 5' value='Class 5'>Class 5</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass6SecA') ? 'block' : 'none' }} id='Mar Class 6' key='Mar Class 6' value='Class 6'>Class 6</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass7SecA') ? 'block' : 'none' }} id='Mar Class 7' key='Mar Class 7' value='Class 7'>Class 7</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass8SecA') ? 'block' : 'none' }} id='Mar Class 8' key='Mar Class 8' value='Class 8'>Class 8</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass9SecA') ? 'block' : 'none' }} id='Mar Class 9' key='Mar Class 9' value='Class 9'>Class 9</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass10SecA') ? 'block' : 'none' }} id='Mar Class 10' key='Mar Class 10' value='Class 10'>Class 10</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass11ArtsSecA') ? 'block' : 'none' }} id='Mar Class 11(Arts)' key='Mar Class 11(Arts)' value='Class 11(Arts)'>Class 11(Arts)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass11CommerceSecA') ? 'block' : 'none' }} id='Mar Class 11(Commerce)' key='Mar Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass12ArtsSecA') ? 'block' : 'none' }} id='Mar Class 12(Arts)' key='Mar Class 12(Arts)' value='Class 12(Arts)'>Class 12(Arts)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass12CommerceSecA') ? 'block' : 'none' }} id='Mar Class 12(Commerce)' key='Mar Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>
                                                                    </Select>
                                                                    <FormHelperText id="schoolClass"><p>{showErrorStatus.addSchoolClass.helperText}</p></FormHelperText>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className='SchoolAddOrRemove__schoolClassSection'>
                                                            <div className='SchoolAddOrRemove__schoolClassSection'>
                                                                <FormControl error={showErrorStatus.addSchoolClassSection.error} name='schoolClassSection' variant="outlined" /*sx={{width: '37ch',}}*/>
                                                                    <InputLabel htmlFor="schoolClassSection">Section :</InputLabel>
                                                                    <Select
                                                                        sx={{ width: '37ch', }}
                                                                        id="schoolClassSection"
                                                                        name='schoolClassSection'
                                                                        // native 
                                                                        label="Section"
                                                                        inputProps={{ name: 'schoolClassSection', id: 'schoolClassSection', }}
                                                                        value={addClassData.schoolClassSection}
                                                                        onChange={handleChangeAddOrRemSection}
                                                                    >
                                                                        <MenuItem key='' value=''></MenuItem>
                                                                        <MenuItem style={{ display: returnedAddOrRemSectionList?.SectionA ? 'flex' : 'none' }} key='Section A' value='Section A'>Section A</MenuItem>
                                                                        <MenuItem style={{ display: returnedAddOrRemSectionList?.SectionB ? 'flex' : 'none' }} key='Section B' value='Section B'>Section B</MenuItem>
                                                                        <MenuItem style={{ display: returnedAddOrRemSectionList?.SectionC ? 'flex' : 'none' }} key='Section C' value='Section C'>Section C</MenuItem>
                                                                        <MenuItem style={{ display: returnedAddOrRemSectionList?.SectionD ? 'flex' : 'none' }} key='Section D' value='Section D'>Section D</MenuItem>
                                                                        <MenuItem style={{ display: returnedAddOrRemSectionList?.SectionE ? 'flex' : 'none' }} key='Section E' value='Section E'>Section E</MenuItem>
                                                                    </Select>
                                                                    <FormHelperText id="schoolClassSection"><p>{showErrorStatus.addSchoolClassSection.helperText}</p></FormHelperText>
                                                                </FormControl>
                                                            </div>
                                                        </div>

                                                        <div className='SchoolAddOrRemove__schoolClassSectionSubject'>
                                                            <div className='SchoolAddOrRemove__schoolClassSectionSubject'>

                                                                <Autocomplete
                                                                    value={addNewSubject}
                                                                    onChange={(event, newValue) => {
                                                                        if (typeof newValue === 'string') {
                                                                            setaddNewSubject({
                                                                                title: newValue,
                                                                            });
                                                                        } else if (newValue && newValue.inputValue) {
                                                                            // Create a new value from the user input
                                                                            setaddNewSubject({
                                                                                title: newValue.inputValue,
                                                                            });
                                                                        } else {
                                                                            setaddNewSubject(newValue);
                                                                        }
                                                                    }}
                                                                    filterOptions={(options, params) => {
                                                                        const filtered = filter(options, params);
                                                                        const { inputValue } = params;
                                                                        // Suggest the creation of a new value
                                                                        const isExisting = options.some((option) => inputValue === option.title);
                                                                        if (inputValue !== '' && !isExisting) {
                                                                            filtered.push({
                                                                                inputValue,
                                                                                title: `Add "${inputValue}"`,
                                                                            });
                                                                        }

                                                                        return filtered;
                                                                    }}
                                                                    selectOnFocus
                                                                    clearOnBlur
                                                                    handleHomeEndKeys
                                                                    id="schoolClassSectionSubject"
                                                                    options={subjectListDummy}
                                                                    getOptionLabel={(option) => {
                                                                        // Value selected with enter, right from the input
                                                                        if (typeof option === 'string') {
                                                                            return option;
                                                                        }
                                                                        // Add "xxx" option created dynamically
                                                                        if (option.inputValue) {
                                                                            return option.inputValue;
                                                                        }
                                                                        // Regular option
                                                                        return option.title;
                                                                    }}
                                                                    renderOption={(props, option) => <li {...props}>{option.title}</li>}
                                                                    freeSolo
                                                                    sx={{ width: '38ch' }}

                                                                    renderInput={(params) => (
                                                                        <TextField {...params} error={showErrorStatus.addSchoolClassSectionSubject.error} label="Subject : " variant='outlined' />
                                                                    )}

                                                                />
                                                                <FormHelperText id="schoolClassSectionSubject"><p>{showErrorStatus.addSchoolClassSectionSubject.helperText}</p></FormHelperText>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className='SchoolAddOrRemove__Button'>
                                                        <LoadingButton
                                                            variant='outlined'
                                                            loading={btnLoading}
                                                            onClick={handleClickAddSubject}
                                                        >
                                                            Add Subject
                                                        </LoadingButton>
                                                        {/* <Button disabled={buttonDisabled.SubjectAdded} ></Button> */}
                                                    </div>
                                                    <div style={{ display: afterClick.SubjectAdded ? 'flex' : 'none' }} className='afterClickMsg'>
                                                        <h4>Subject is added</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }


                                    {
                                        showBlockData.removeClassSubject && (
                                            <div className='SchoolAddOrRemove__AddClass'>
                                                <h2>Remove a Subject</h2>
                                                <div className='SchoolAddOrRemove__AddClassDiv'>
                                                    <div className='SchoolAddOrRemove__radio'>
                                                        <FormControl error={showErrorStatus.addSchoolClassMedium.error} component="fieldset" onChange={handleMediumChange}>
                                                            <div className='SchoolAddOrRemove__radioMediumMuiDiv'>
                                                                <FormLabel component="legend">Select Medium</FormLabel>
                                                                <RadioGroup aria-label="addClassMedium" name="addClassMedium" value={addClassMedium} onChange={handleMediumValueChange}>
                                                                    <div className='SchoolAddOrRemove__radioMediumMui'>
                                                                        <FormControlLabel value="English Medium" control={<Radio />} label="English Medium" />
                                                                        <FormControlLabel value="Marathi Medium" control={<Radio />} label="Marathi Medium" />
                                                                    </div>
                                                                </RadioGroup>
                                                            </div>
                                                        </FormControl>
                                                        <FormHelperText id="addClassMedium"><p>{showErrorStatus.addSchoolClassMedium.helperText}</p></FormHelperText>
                                                    </div>

                                                    <div className='SchoolAddOrRemoveClassSection'>
                                                        <div className='SchoolAddOrRemove__schoolClass'>
                                                            <div className='SchoolAddOrRemove__schoolClass'>
                                                                <FormControl error={showErrorStatus.addSchoolClass.error} name='schoolClass' variant="outlined" /*sx={{width: '37ch',}}*/>
                                                                    <InputLabel htmlFor="schoolClass">Class :</InputLabel>
                                                                    <Select
                                                                        sx={{ width: '37ch', }}
                                                                        id="schoolClass"
                                                                        name='schoolClass'
                                                                        // native 
                                                                        label="Class : "
                                                                        inputProps={{ name: 'schoolClass', id: 'schoolClass', }}
                                                                        value={addClassData.schoolClass}
                                                                        onChange={handleChangeAddOrRemClass}
                                                                    >
                                                                        <MenuItem style={{ display: addClassMedium != null ? 'none' : 'block' }} aria-label="None" value="" />

                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngPreNurserySecA') ? 'block' : 'none' }} id='Eng Pre Nursery' key='Eng Pre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngNurserySecA') ? 'block' : 'none' }} id='Eng Nursery' key='Eng Nursery' value='Nursery'>Nursery</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngKG1SecA') ? 'block' : 'none' }} id='Eng Kindergarten 1(KG-1)' key='Eng Kindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngKG2SecA') ? 'block' : 'none' }} id='Eng Kindergarten 2(KG-2)' key='Eng Kindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass1SecA') ? 'block' : 'none' }} id='Eng Class 1' key='Eng Class 1' value='Class 1'>Class 1</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass2SecA') ? 'block' : 'none' }} id='Eng Class 2' key='Eng Class 2' value='Class 2'>Class 2</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass3SecA') ? 'block' : 'none' }} id='Eng Class 3' key='Eng Class 3' value='Class 3'>Class 3</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass4SecA') ? 'block' : 'none' }} id='Eng Class 4' key='Eng Class 4' value='Class 4'>Class 4</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass5SecA') ? 'block' : 'none' }} id='Eng Class 5' key='Eng Class 5' value='Class 5'>Class 5</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass6SecA') ? 'block' : 'none' }} id='Eng Class 6' key='Eng Class 6' value='Class 6'>Class 6</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass7SecA') ? 'block' : 'none' }} id='Eng Class 7' key='Eng Class 7' value='Class 7'>Class 7</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass8SecA') ? 'block' : 'none' }} id='Eng Class 8' key='Eng Class 8' value='Class 8'>Class 8</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass9SecA') ? 'block' : 'none' }} id='Eng Class 9' key='Eng Class 9' value='Class 9'>Class 9</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass10SecA') ? 'block' : 'none' }} id='Eng Class 10' key='Eng Class 10' value='Class 10'>Class 10</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass11ScienceSecA') ? 'block' : 'none' }} id='Eng Class 11(Science)' key='Eng Class 11(Science)' value='Class 11(Science)'>Class 11(Science)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass11CommerceSecA') ? 'block' : 'none' }} id='Eng Class 11(Commerce)' key='Eng Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass12ScienceSecA') ? 'block' : 'none' }} id='Eng Class 12(Science)' key='Eng Class 12(Science)' value='Class 12(Science)'>Class 12(Science)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'English Medium' && classListAddOrRem.includes('EngClass12CommerceSecA') ? 'block' : 'none' }} id='Eng Class 12(Commerce)' key='Eng Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>

                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarPreNurserySecA') ? 'block' : 'none' }} id='Mar Pre Nursery' key='Mar Pre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarNurserySecA') ? 'block' : 'none' }} id='Mar Nursery' key='Mar Nursery' value='Nursery'>Nursery</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarKG1SecA') ? 'block' : 'none' }} id='Mar Kindergarten 1(KG-1)' key='Mar Kindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarKG2SecA') ? 'block' : 'none' }} id='Mar Kindergarten 2(KG-2)' key='Mar Kindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>

                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass1SecA') ? 'block' : 'none' }} id='Mar Class 1' key='Mar Class 1' value='Class 1'>Class 1</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass2SecA') ? 'block' : 'none' }} id='Mar Class 2' key='Mar Class 2' value='Class 2'>Class 2</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass3SecA') ? 'block' : 'none' }} id='Mar Class 3' key='Mar Class 3' value='Class 3'>Class 3</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass4SecA') ? 'block' : 'none' }} id='Mar Class 4' key='Mar Class 4' value='Class 4'>Class 4</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass5SecA') ? 'block' : 'none' }} id='Mar Class 5' key='Mar Class 5' value='Class 5'>Class 5</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass6SecA') ? 'block' : 'none' }} id='Mar Class 6' key='Mar Class 6' value='Class 6'>Class 6</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass7SecA') ? 'block' : 'none' }} id='Mar Class 7' key='Mar Class 7' value='Class 7'>Class 7</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass8SecA') ? 'block' : 'none' }} id='Mar Class 8' key='Mar Class 8' value='Class 8'>Class 8</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass9SecA') ? 'block' : 'none' }} id='Mar Class 9' key='Mar Class 9' value='Class 9'>Class 9</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass10SecA') ? 'block' : 'none' }} id='Mar Class 10' key='Mar Class 10' value='Class 10'>Class 10</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass11ArtsSecA') ? 'block' : 'none' }} id='Mar Class 11(Arts)' key='Mar Class 11(Arts)' value='Class 11(Arts)'>Class 11(Arts)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass11CommerceSecA') ? 'block' : 'none' }} id='Mar Class 11(Commerce)' key='Mar Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass12ArtsSecA') ? 'block' : 'none' }} id='Mar Class 12(Arts)' key='Mar Class 12(Arts)' value='Class 12(Arts)'>Class 12(Arts)</MenuItem>
                                                                        <MenuItem style={{ display: addClassMedium === 'Marathi Medium' && classListAddOrRem.includes('MarClass12CommerceSecA') ? 'block' : 'none' }} id='Mar Class 12(Commerce)' key='Mar Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>
                                                                    </Select>
                                                                    <FormHelperText id="schoolClass"><p>{showErrorStatus.addSchoolClass.helperText}</p></FormHelperText>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className='SchoolAddOrRemove__schoolClassSection'>
                                                            <div className='SchoolAddOrRemove__schoolClassSection'>
                                                                <FormControl error={showErrorStatus.addSchoolClassSection.error} name='schoolClassSection' variant="outlined" /*sx={{width: '37ch',}}*/>
                                                                    <InputLabel htmlFor="schoolClassSection">Section :</InputLabel>
                                                                    <Select
                                                                        sx={{ width: '37ch', }}
                                                                        id="schoolClassSection"
                                                                        name='schoolClassSection'
                                                                        // native 
                                                                        label="Section"
                                                                        inputProps={{ name: 'schoolClassSection', id: 'schoolClassSection', }}
                                                                        value={addClassData.schoolClassSection}
                                                                        onChange={handleChangeAddOrRemSection}
                                                                    >
                                                                        <MenuItem key='' value=''></MenuItem>
                                                                        <MenuItem style={{ display: returnedAddOrRemSectionList?.SectionA ? 'flex' : 'none' }} key='Section A' value='Section A'>Section A</MenuItem>
                                                                        <MenuItem style={{ display: returnedAddOrRemSectionList?.SectionB ? 'flex' : 'none' }} key='Section B' value='Section B'>Section B</MenuItem>
                                                                        <MenuItem style={{ display: returnedAddOrRemSectionList?.SectionC ? 'flex' : 'none' }} key='Section C' value='Section C'>Section C</MenuItem>
                                                                        <MenuItem style={{ display: returnedAddOrRemSectionList?.SectionD ? 'flex' : 'none' }} key='Section D' value='Section D'>Section D</MenuItem>
                                                                        <MenuItem style={{ display: returnedAddOrRemSectionList?.SectionE ? 'flex' : 'none' }} key='Section E' value='Section E'>Section E</MenuItem>
                                                                    </Select>
                                                                    <FormHelperText id="schoolClassSection"><p>{showErrorStatus.addSchoolClassSection.helperText}</p></FormHelperText>
                                                                </FormControl>
                                                            </div>
                                                        </div>

                                                        <div className='SchoolAddOrRemove__schoolClassSectionSubject'>
                                                            <div className='SchoolAddOrRemove__schoolClassSectionSubject'>
                                                                <FormControl error={showErrorStatus.addSchoolClassSectionSubject.error} name='schoolClassSectionSubject' variant="outlined" >
                                                                    <InputLabel htmlFor="schoolClassSectionSubject">Subject</InputLabel>
                                                                    <Select
                                                                        sx={{ width: '37ch', }}
                                                                        id="schoolClassSectionSubject"
                                                                        name='schoolClassSectionSubject'
                                                                        // native 
                                                                        label="Section"
                                                                        inputProps={{ name: 'schoolClassSectionSubject', id: 'schoolClassSectionSubject', }}
                                                                        value={addClassData.schoolClassSectionSubject}
                                                                        onChange={handleChangeAddOrRemSubject}
                                                                    >
                                                                        <MenuItem key='' value=''></MenuItem>
                                                                        {
                                                                            subjectList.map(sub =>
                                                                                <MenuItem style={{ display: subjectListAddOrRem?.SUBJECTS_LIST.includes(sub) ? 'flex' : 'none' }} key={sub} value={sub}>{sub}</MenuItem>
                                                                            )
                                                                        }
                                                                    </Select>
                                                                    <FormHelperText id="schoolClassSectionSubject"><p>{showErrorStatus.addSchoolClassSectionSubject.helperText}</p></FormHelperText>
                                                                </FormControl>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className='SchoolAddOrRemove__Button'>
                                                        <LoadingButton
                                                            variant='outlined'
                                                            loading={btnLoading}
                                                            onClick={handleClickRemoveSubject}
                                                        >
                                                            Remove Subject
                                                        </LoadingButton>
                                                        {/* <Button disabled={buttonDisabled.SubjectRemoved} ></Button> */}
                                                    </div>
                                                    <div style={{ display: afterClick.SubjectRemoved ? 'flex' : 'none' }} className='afterClickMsg'>
                                                        <h4>Subject is removed</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }


                                </div>
                            </div>
                            <hr />
                        </div>

                    </div >
                )
            }
        </>

    )
}

export default SchoolAddOrRemove
