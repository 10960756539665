import React, { useState } from 'react'
import '../Pages/CreateSchoolAccount.css'
import CreateSchoolAccountInfo from '../Components/CreateNewAccount/CreateSchoolAccountInfo';
// import CreateSchoolAccountInfo from '../Components/CreateSchoolAccountInfo';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Button, Checkbox, Chip, FormControl, FormHelperText, InputLabel, MenuItem, Select, Box, OutlinedInput
} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function CreateSchoolAccount() {

    const adminFName = sessionStorage.getItem('adminFName')
    const adminLName = sessionStorage.getItem('adminLName')
    const inputSchoolName = sessionStorage.getItem('inputSchoolName')
    const inputSchoolEmail = sessionStorage.getItem('inputSchoolEmail')
    const inputSchoolPhoneNo = sessionStorage.getItem('inputSchoolPhoneNo')
    const affiliation = sessionStorage.getItem('affiliation')
    const confirmPassword = sessionStorage.getItem('confirmPassword')
    const inputAddress = sessionStorage.getItem('inputAddress')
    const inputVillage = sessionStorage.getItem('inputVillage')
    const inputTehsil = sessionStorage.getItem('inputTehsil')
    const inputCity = sessionStorage.getItem('inputCity')
    const inputDistrict = sessionStorage.getItem('inputDistrict')
    const inputPinCode = sessionStorage.getItem('inputPinCode')
    const indianState = sessionStorage.getItem('indianState')

    // const Muiclasses = useStyles();

    const [schoolClassData, setSchoolClassData] = useState([]);

    const subjectList = ['Accountancy', 'Agriculture', 'Algebra', 'Animal Science', 'Arabic', 'Astronomy',
        'Automobile Technology', 'Bio Technology', 'Biology', 'Book Keeping & accounts', 'Botany', 'Business Laws',
        'Business Studies', 'Chemistry', 'Civics', 'Computer Science', 'Computers', 'Crop Production', 'Defence Studies',
        'Economics', 'Engineering Drawings', 'English', 'Entrepreneurship', 'Environment Education', 'Environmental Science',
        'Environmental Studies', 'Fashion Studies', 'Fine Arts', 'French', 'Fundamental Banking & Finance', 'General Awareness',
        'General Knowledge', 'General Mathematics', 'General Studies', 'Geography', 'Geometry', 'German', 'Graphics Design',
        'Geology', 'Hindi', 'History', 'Home Science', 'Human Rights & Gender Studies', 'Informatics Practices',
        'Information Practices', 'Information Technology', 'Law', 'Legal Studies', 'Library & information',
        'Library & Information Science', 'Logic', 'Macroeconomics', 'Marathi', 'Mass Communication', 'Mas Media Studies',
        'Mathematics', 'Mathematics & Statistics', 'Microeconomics', 'Music', 'Organisation of Commerce', 'Pali', 'Persian',
        'Philosophy', 'Physical Education', 'Physics', 'Political Science', 'Psychology', 'Public Administration',
        'Punjabi', 'Retail Merchandising', 'Sanskrit', 'Secretarial Practice', 'Science', 'Science & Technology', 'Sociology',
        'Statistics', 'Statistics for Economics', 'Stenography', 'Urdu', 'Zoology']

    const [subjectsOfClass, setSubjectsOfClass] = useState({
        subNamesEngNurserySecA: [],
        subNamesEngNurserySecB: [],
        subNamesEngNurserySecC: [],
        subNamesEngNurserySecD: [],
        subNamesEngNurserySecE: [],
        subNamesMarNurserySecA: [],
        subNamesMarNurserySecB: [],
        subNamesMarNurserySecC: [],
        subNamesMarNurserySecD: [],
        subNamesMarNurserySecE: [],

        subNamesEngPreNurserySecA: [],
        subNamesEngPreNurserySecB: [],
        subNamesEngPreNurserySecC: [],
        subNamesEngPreNurserySecD: [],
        subNamesEngPreNurserySecE: [],
        subNamesMarPreNurserySecA: [],
        subNamesMarPreNurserySecB: [],
        subNamesMarPreNurserySecC: [],
        subNamesMarPreNurserySecD: [],
        subNamesMarPreNurserySecE: [],

        subNamesEngKG1SecA: [],
        subNamesEngKG1SecB: [],
        subNamesEngKG1SecC: [],
        subNamesEngKG1SecD: [],
        subNamesEngKG1SecE: [],
        subNamesMarKG1SecA: [],
        subNamesMarKG1SecB: [],
        subNamesMarKG1SecC: [],
        subNamesMarKG1SecD: [],
        subNamesMarKG1SecE: [],

        subNamesEngKG2SecA: [],
        subNamesEngKG2SecB: [],
        subNamesEngKG2SecC: [],
        subNamesEngKG2SecD: [],
        subNamesEngKG2SecE: [],
        subNamesMarKG2SecA: [],
        subNamesMarKG2SecB: [],
        subNamesMarKG2SecC: [],
        subNamesMarKG2SecD: [],
        subNamesMarKG2SecE: [],

        subNamesEngClass1SecA: [],
        subNamesEngClass1SecB: [],
        subNamesEngClass1SecC: [],
        subNamesEngClass1SecD: [],
        subNamesEngClass1SecE: [],
        subNamesMarClass1SecA: [],
        subNamesMarClass1SecB: [],
        subNamesMarClass1SecC: [],
        subNamesMarClass1SecD: [],
        subNamesMarClass1SecE: [],

        subNamesEngClass2SecA: [],
        subNamesEngClass2SecB: [],
        subNamesEngClass2SecC: [],
        subNamesEngClass2SecD: [],
        subNamesEngClass2SecE: [],
        subNamesMarClass2SecA: [],
        subNamesMarClass2SecB: [],
        subNamesMarClass2SecC: [],
        subNamesMarClass2SecD: [],
        subNamesMarClass2SecE: [],

        subNamesEngClass3SecA: [],
        subNamesEngClass3SecB: [],
        subNamesEngClass3SecC: [],
        subNamesEngClass3SecD: [],
        subNamesEngClass3SecE: [],
        subNamesMarClass3SecA: [],
        subNamesMarClass3SecB: [],
        subNamesMarClass3SecC: [],
        subNamesMarClass3SecD: [],
        subNamesMarClass3SecE: [],

        subNamesEngClass4SecA: [],
        subNamesEngClass4SecB: [],
        subNamesEngClass4SecC: [],
        subNamesEngClass4SecD: [],
        subNamesEngClass4SecE: [],
        subNamesMarClass4SecA: [],
        subNamesMarClass4SecB: [],
        subNamesMarClass4SecC: [],
        subNamesMarClass4SecD: [],
        subNamesMarClass4SecE: [],

        subNamesEngClass5SecA: [],
        subNamesEngClass5SecB: [],
        subNamesEngClass5SecC: [],
        subNamesEngClass5SecD: [],
        subNamesEngClass5SecE: [],
        subNamesMarClass5SecA: [],
        subNamesMarClass5SecB: [],
        subNamesMarClass5SecC: [],
        subNamesMarClass5SecD: [],
        subNamesMarClass5SecE: [],

        subNamesEngClass6SecA: [],
        subNamesEngClass6SecB: [],
        subNamesEngClass6SecC: [],
        subNamesEngClass6SecD: [],
        subNamesEngClass6SecE: [],
        subNamesMarClass6SecA: [],
        subNamesMarClass6SecB: [],
        subNamesMarClass6SecC: [],
        subNamesMarClass6SecD: [],
        subNamesMarClass6SecE: [],

        subNamesEngClass7SecA: [],
        subNamesEngClass7SecB: [],
        subNamesEngClass7SecC: [],
        subNamesEngClass7SecD: [],
        subNamesEngClass7SecE: [],
        subNamesMarClass7SecA: [],
        subNamesMarClass7SecB: [],
        subNamesMarClass7SecC: [],
        subNamesMarClass7SecD: [],
        subNamesMarClass7SecE: [],

        subNamesEngClass8SecA: [],
        subNamesEngClass8SecB: [],
        subNamesEngClass8SecC: [],
        subNamesEngClass8SecD: [],
        subNamesEngClass8SecE: [],
        subNamesMarClass8SecA: [],
        subNamesMarClass8SecB: [],
        subNamesMarClass8SecC: [],
        subNamesMarClass8SecD: [],
        subNamesMarClass8SecE: [],

        subNamesEngClass9SecA: [],
        subNamesEngClass9SecB: [],
        subNamesEngClass9SecC: [],
        subNamesEngClass9SecD: [],
        subNamesEngClass9SecE: [],
        subNamesMarClass9SecA: [],
        subNamesMarClass9SecB: [],
        subNamesMarClass9SecC: [],
        subNamesMarClass9SecD: [],
        subNamesMarClass9SecE: [],

        subNamesEngClass10SecA: [],
        subNamesEngClass10SecB: [],
        subNamesEngClass10SecC: [],
        subNamesEngClass10SecD: [],
        subNamesEngClass10SecE: [],
        subNamesMarClass10SecA: [],
        subNamesMarClass10SecB: [],
        subNamesMarClass10SecC: [],
        subNamesMarClass10SecD: [],
        subNamesMarClass10SecE: [],

        subNamesMarClass11ArtsSecA: [],
        subNamesMarClass11ArtsSecB: [],
        subNamesMarClass11ArtsSecC: [],
        subNamesMarClass11ArtsSecD: [],
        subNamesMarClass11ArtsSecE: [],

        subNamesEngClass11CommerceSecA: [],
        subNamesEngClass11CommerceSecB: [],
        subNamesEngClass11CommerceSecC: [],
        subNamesEngClass11CommerceSecD: [],
        subNamesEngClass11CommerceSecE: [],
        subNamesMarClass11CommerceSecA: [],
        subNamesMarClass11CommerceSecB: [],
        subNamesMarClass11CommerceSecC: [],
        subNamesMarClass11CommerceSecD: [],
        subNamesMarClass11CommerceSecE: [],

        subNamesEngClass11ScienceSecA: [],
        subNamesEngClass11ScienceSecB: [],
        subNamesEngClass11ScienceSecC: [],
        subNamesEngClass11ScienceSecD: [],
        subNamesEngClass11ScienceSecE: [],

        subNamesMarClass12ArtsSecA: [],
        subNamesMarClass12ArtsSecB: [],
        subNamesMarClass12ArtsSecC: [],
        subNamesMarClass12ArtsSecD: [],
        subNamesMarClass12ArtsSecE: [],

        subNamesEngClass12CommerceSecA: [],
        subNamesEngClass12CommerceSecB: [],
        subNamesEngClass12CommerceSecC: [],
        subNamesEngClass12CommerceSecD: [],
        subNamesEngClass12CommerceSecE: [],
        subNamesMarClass12CommerceSecA: [],
        subNamesMarClass12CommerceSecB: [],
        subNamesMarClass12CommerceSecC: [],
        subNamesMarClass12CommerceSecD: [],
        subNamesMarClass12CommerceSecE: [],

        subNamesEngClass12ScienceSecA: [],
        subNamesEngClass12ScienceSecB: [],
        subNamesEngClass12ScienceSecC: [],
        subNamesEngClass12ScienceSecD: [],
        subNamesEngClass12ScienceSecE: [],
    })
    const addSubjectsToClass = (e) => {
        setSubjectsOfClass(prevState => { return { ...prevState, [e.target.name]: e.target.value } })
        setshowEnteredSchoolSub(prevState => { return { ...prevState, [e.target.name]: { error: false } } })
    }

    const [classesOfSchool, setClassesOfSchool] = useState({
        EngPreNurserySecA: { checked: false, disabled: true },
        EngPreNurserySecB: { checked: false, disabled: true },
        EngPreNurserySecC: { checked: false, disabled: true },
        EngPreNurserySecD: { checked: false, disabled: true },
        EngPreNurserySecE: { checked: false, disabled: true },
        MarPreNurserySecA: { checked: false, disabled: true },
        MarPreNurserySecB: { checked: false, disabled: true },
        MarPreNurserySecC: { checked: false, disabled: true },
        MarPreNurserySecD: { checked: false, disabled: true },
        MarPreNurserySecE: { checked: false, disabled: true },

        EngNurserySecA: { checked: false, disabled: true },
        EngNurserySecB: { checked: false, disabled: true },
        EngNurserySecC: { checked: false, disabled: true },
        EngNurserySecD: { checked: false, disabled: true },
        EngNurserySecE: { checked: false, disabled: true },
        MarNurserySecA: { checked: false, disabled: true },
        MarNurserySecB: { checked: false, disabled: true },
        MarNurserySecC: { checked: false, disabled: true },
        MarNurserySecD: { checked: false, disabled: true },
        MarNurserySecE: { checked: false, disabled: true },

        EngKG1SecA: { checked: false, disabled: true },
        EngKG1SecB: { checked: false, disabled: true },
        EngKG1SecC: { checked: false, disabled: true },
        EngKG1SecD: { checked: false, disabled: true },
        EngKG1SecE: { checked: false, disabled: true },
        MarKG1SecA: { checked: false, disabled: true },
        MarKG1SecB: { checked: false, disabled: true },
        MarKG1SecC: { checked: false, disabled: true },
        MarKG1SecD: { checked: false, disabled: true },
        MarKG1SecE: { checked: false, disabled: true },

        EngKG2SecA: { checked: false, disabled: true },
        EngKG2SecB: { checked: false, disabled: true },
        EngKG2SecC: { checked: false, disabled: true },
        EngKG2SecD: { checked: false, disabled: true },
        EngKG2SecE: { checked: false, disabled: true },
        MarKG2SecA: { checked: false, disabled: true },
        MarKG2SecB: { checked: false, disabled: true },
        MarKG2SecC: { checked: false, disabled: true },
        MarKG2SecD: { checked: false, disabled: true },
        MarKG2SecE: { checked: false, disabled: true },

        EngClass1SecA: { checked: false, disabled: true },
        EngClass1SecB: { checked: false, disabled: true },
        EngClass1SecC: { checked: false, disabled: true },
        EngClass1SecD: { checked: false, disabled: true },
        EngClass1SecE: { checked: false, disabled: true },
        MarClass1SecA: { checked: false, disabled: true },
        MarClass1SecB: { checked: false, disabled: true },
        MarClass1SecC: { checked: false, disabled: true },
        MarClass1SecD: { checked: false, disabled: true },
        MarClass1SecE: { checked: false, disabled: true },

        EngClass2SecA: { checked: false, disabled: true },
        EngClass2SecB: { checked: false, disabled: true },
        EngClass2SecC: { checked: false, disabled: true },
        EngClass2SecD: { checked: false, disabled: true },
        EngClass2SecE: { checked: false, disabled: true },
        MarClass2SecA: { checked: false, disabled: true },
        MarClass2SecB: { checked: false, disabled: true },
        MarClass2SecC: { checked: false, disabled: true },
        MarClass2SecD: { checked: false, disabled: true },
        MarClass2SecE: { checked: false, disabled: true },

        EngClass3SecA: { checked: false, disabled: true },
        EngClass3SecB: { checked: false, disabled: true },
        EngClass3SecC: { checked: false, disabled: true },
        EngClass3SecD: { checked: false, disabled: true },
        EngClass3SecE: { checked: false, disabled: true },
        MarClass3SecA: { checked: false, disabled: true },
        MarClass3SecB: { checked: false, disabled: true },
        MarClass3SecC: { checked: false, disabled: true },
        MarClass3SecD: { checked: false, disabled: true },
        MarClass3SecE: { checked: false, disabled: true },

        EngClass4SecA: { checked: false, disabled: true },
        EngClass4SecB: { checked: false, disabled: true },
        EngClass4SecC: { checked: false, disabled: true },
        EngClass4SecD: { checked: false, disabled: true },
        EngClass4SecE: { checked: false, disabled: true },
        MarClass4SecA: { checked: false, disabled: true },
        MarClass4SecB: { checked: false, disabled: true },
        MarClass4SecC: { checked: false, disabled: true },
        MarClass4SecD: { checked: false, disabled: true },
        MarClass4SecE: { checked: false, disabled: true },

        EngClass5SecA: { checked: false, disabled: true },
        EngClass5SecB: { checked: false, disabled: true },
        EngClass5SecC: { checked: false, disabled: true },
        EngClass5SecD: { checked: false, disabled: true },
        EngClass5SecE: { checked: false, disabled: true },
        MarClass5SecA: { checked: false, disabled: true },
        MarClass5SecB: { checked: false, disabled: true },
        MarClass5SecC: { checked: false, disabled: true },
        MarClass5SecD: { checked: false, disabled: true },
        MarClass5SecE: { checked: false, disabled: true },

        EngClass6SecA: { checked: false, disabled: true },
        EngClass6SecB: { checked: false, disabled: true },
        EngClass6SecC: { checked: false, disabled: true },
        EngClass6SecD: { checked: false, disabled: true },
        EngClass6SecE: { checked: false, disabled: true },
        MarClass6SecA: { checked: false, disabled: true },
        MarClass6SecB: { checked: false, disabled: true },
        MarClass6SecC: { checked: false, disabled: true },
        MarClass6SecD: { checked: false, disabled: true },
        MarClass6SecE: { checked: false, disabled: true },

        EngClass7SecA: { checked: false, disabled: true },
        EngClass7SecB: { checked: false, disabled: true },
        EngClass7SecC: { checked: false, disabled: true },
        EngClass7SecD: { checked: false, disabled: true },
        EngClass7SecE: { checked: false, disabled: true },
        MarClass7SecA: { checked: false, disabled: true },
        MarClass7SecB: { checked: false, disabled: true },
        MarClass7SecC: { checked: false, disabled: true },
        MarClass7SecD: { checked: false, disabled: true },
        MarClass7SecE: { checked: false, disabled: true },

        EngClass8SecA: { checked: false, disabled: true },
        EngClass8SecB: { checked: false, disabled: true },
        EngClass8SecC: { checked: false, disabled: true },
        EngClass8SecD: { checked: false, disabled: true },
        EngClass8SecE: { checked: false, disabled: true },
        MarClass8SecA: { checked: false, disabled: true },
        MarClass8SecB: { checked: false, disabled: true },
        MarClass8SecC: { checked: false, disabled: true },
        MarClass8SecD: { checked: false, disabled: true },
        MarClass8SecE: { checked: false, disabled: true },

        EngClass9SecA: { checked: false, disabled: true },
        EngClass9SecB: { checked: false, disabled: true },
        EngClass9SecC: { checked: false, disabled: true },
        EngClass9SecD: { checked: false, disabled: true },
        EngClass9SecE: { checked: false, disabled: true },
        MarClass9SecA: { checked: false, disabled: true },
        MarClass9SecB: { checked: false, disabled: true },
        MarClass9SecC: { checked: false, disabled: true },
        MarClass9SecD: { checked: false, disabled: true },
        MarClass9SecE: { checked: false, disabled: true },

        EngClass10SecA: { checked: false, disabled: true },
        EngClass10SecB: { checked: false, disabled: true },
        EngClass10SecC: { checked: false, disabled: true },
        EngClass10SecD: { checked: false, disabled: true },
        EngClass10SecE: { checked: false, disabled: true },
        MarClass10SecA: { checked: false, disabled: true },
        MarClass10SecB: { checked: false, disabled: true },
        MarClass10SecC: { checked: false, disabled: true },
        MarClass10SecD: { checked: false, disabled: true },
        MarClass10SecE: { checked: false, disabled: true },

        MarClass11ArtsSecA: { checked: false, disabled: true },
        MarClass11ArtsSecB: { checked: false, disabled: true },
        MarClass11ArtsSecC: { checked: false, disabled: true },
        MarClass11ArtsSecD: { checked: false, disabled: true },
        MarClass11ArtsSecE: { checked: false, disabled: true },

        EngClass11CommerceSecA: { checked: false, disabled: true },
        EngClass11CommerceSecB: { checked: false, disabled: true },
        EngClass11CommerceSecC: { checked: false, disabled: true },
        EngClass11CommerceSecD: { checked: false, disabled: true },
        EngClass11CommerceSecE: { checked: false, disabled: true },
        MarClass11CommerceSecA: { checked: false, disabled: true },
        MarClass11CommerceSecB: { checked: false, disabled: true },
        MarClass11CommerceSecC: { checked: false, disabled: true },
        MarClass11CommerceSecD: { checked: false, disabled: true },
        MarClass11CommerceSecE: { checked: false, disabled: true },

        EngClass11ScienceSecA: { checked: false, disabled: true },
        EngClass11ScienceSecB: { checked: false, disabled: true },
        EngClass11ScienceSecC: { checked: false, disabled: true },
        EngClass11ScienceSecD: { checked: false, disabled: true },
        EngClass11ScienceSecE: { checked: false, disabled: true },

        MarClass12ArtsSecA: { checked: false, disabled: true },
        MarClass12ArtsSecB: { checked: false, disabled: true },
        MarClass12ArtsSecC: { checked: false, disabled: true },
        MarClass12ArtsSecD: { checked: false, disabled: true },
        MarClass12ArtsSecE: { checked: false, disabled: true },

        EngClass12CommerceSecA: { checked: false, disabled: true },
        EngClass12CommerceSecB: { checked: false, disabled: true },
        EngClass12CommerceSecC: { checked: false, disabled: true },
        EngClass12CommerceSecD: { checked: false, disabled: true },
        EngClass12CommerceSecE: { checked: false, disabled: true },
        MarClass12CommerceSecA: { checked: false, disabled: true },
        MarClass12CommerceSecB: { checked: false, disabled: true },
        MarClass12CommerceSecC: { checked: false, disabled: true },
        MarClass12CommerceSecD: { checked: false, disabled: true },
        MarClass12CommerceSecE: { checked: false, disabled: true },

        EngClass12ScienceSecA: { checked: false, disabled: true },
        EngClass12ScienceSecB: { checked: false, disabled: true },
        EngClass12ScienceSecC: { checked: false, disabled: true },
        EngClass12ScienceSecD: { checked: false, disabled: true },
        EngClass12ScienceSecE: { checked: false, disabled: true },
    });

    const handleChanageClassesOfSchoolEngPreNursery = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, EngPreNurserySecA: { checked: false, disabled: true }, EngPreNurserySecB: { checked: false, disabled: true }, EngPreNurserySecC: { checked: false, disabled: true }, EngPreNurserySecD: { checked: false, disabled: true }, EngPreNurserySecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('EngPreNurserySecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesEngPreNurserySecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngPreNurserySecA: [] } }) }
                const b = schoolClassData.indexOf('EngPreNurserySecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngPreNurserySecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngPreNurserySecB: [] } }) }
                const c = schoolClassData.indexOf('EngPreNurserySecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngPreNurserySecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngPreNurserySecC: [] } }) }
                const d = schoolClassData.indexOf('EngPreNurserySecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngPreNurserySecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngPreNurserySecD: [] } }) }
                const e = schoolClassData.indexOf('EngPreNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngPreNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngPreNurserySecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, EngPreNurserySecB: { checked: false, disabled: true }, EngPreNurserySecC: { checked: false, disabled: true }, EngPreNurserySecD: { checked: false, disabled: true }, EngPreNurserySecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('EngPreNurserySecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngPreNurserySecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngPreNurserySecB: [] } }) }
                const c = schoolClassData.indexOf('EngPreNurserySecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngPreNurserySecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngPreNurserySecC: [] } }) }
                const d = schoolClassData.indexOf('EngPreNurserySecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngPreNurserySecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngPreNurserySecD: [] } }) }
                const e = schoolClassData.indexOf('EngPreNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngPreNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngPreNurserySecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, EngPreNurserySecC: { checked: false, disabled: true }, EngPreNurserySecD: { checked: false, disabled: true }, EngPreNurserySecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('EngPreNurserySecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngPreNurserySecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngPreNurserySecC: [] } }) }
                const d = schoolClassData.indexOf('EngPreNurserySecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngPreNurserySecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngPreNurserySecD: [] } }) }
                const e = schoolClassData.indexOf('EngPreNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngPreNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngPreNurserySecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, EngPreNurserySecD: { checked: false, disabled: true }, EngPreNurserySecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('EngPreNurserySecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngPreNurserySecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngPreNurserySecD: [] } }) }
                const e = schoolClassData.indexOf('EngPreNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngPreNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngPreNurserySecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, EngPreNurserySecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('EngPreNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngPreNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngPreNurserySecE: [] } }) }
            }
        }
    }
    const handleChanageClassesOfSchoolMarPreNursery = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, MarPreNurserySecA: { checked: false, disabled: true }, MarPreNurserySecB: { checked: false, disabled: true }, MarPreNurserySecC: { checked: false, disabled: true }, MarPreNurserySecD: { checked: false, disabled: true }, MarPreNurserySecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('MarPreNurserySecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesMarPreNurserySecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarPreNurserySecA: [] } }) }
                const b = schoolClassData.indexOf('MarPreNurserySecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarPreNurserySecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarPreNurserySecB: [] } }) }
                const c = schoolClassData.indexOf('MarPreNurserySecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarPreNurserySecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarPreNurserySecC: [] } }) }
                const d = schoolClassData.indexOf('MarPreNurserySecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarPreNurserySecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarPreNurserySecD: [] } }) }
                const e = schoolClassData.indexOf('MarPreNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarPreNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarPreNurserySecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, MarPreNurserySecB: { checked: false, disabled: true }, MarPreNurserySecC: { checked: false, disabled: true }, MarPreNurserySecD: { checked: false, disabled: true }, MarPreNurserySecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('MarPreNurserySecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarPreNurserySecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarPreNurserySecB: [] } }) }
                const c = schoolClassData.indexOf('MarPreNurserySecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarPreNurserySecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarPreNurserySecC: [] } }) }
                const d = schoolClassData.indexOf('MarPreNurserySecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarPreNurserySecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarPreNurserySecD: [] } }) }
                const e = schoolClassData.indexOf('MarPreNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarPreNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarPreNurserySecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, MarPreNurserySecC: { checked: false, disabled: true }, MarPreNurserySecD: { checked: false, disabled: true }, MarPreNurserySecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('MarPreNurserySecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarPreNurserySecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarPreNurserySecC: [] } }) }
                const d = schoolClassData.indexOf('MarPreNurserySecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarPreNurserySecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarPreNurserySecD: [] } }) }
                const e = schoolClassData.indexOf('MarPreNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarPreNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarPreNurserySecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, MarPreNurserySecD: { checked: false, disabled: true }, MarPreNurserySecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('MarPreNurserySecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarPreNurserySecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarPreNurserySecD: [] } }) }
                const e = schoolClassData.indexOf('MarPreNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarPreNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarPreNurserySecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, MarPreNurserySecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('MarPreNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarPreNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarPreNurserySecE: [] } }) }
            }
        }
    }

    const handleChanageClassesOfSchoolEngNursery = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, EngNurserySecA: { checked: false, disabled: true }, EngNurserySecB: { checked: false, disabled: true }, EngNurserySecC: { checked: false, disabled: true }, EngNurserySecD: { checked: false, disabled: true }, EngNurserySecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('EngNurserySecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesEngNurserySecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngNurserySecA: [] } }) }
                const b = schoolClassData.indexOf('EngNurserySecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngNurserySecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngNurserySecB: [] } }) }
                const c = schoolClassData.indexOf('EngNurserySecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngNurserySecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngNurserySecC: [] } }) }
                const d = schoolClassData.indexOf('EngNurserySecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngNurserySecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngNurserySecD: [] } }) }
                const e = schoolClassData.indexOf('EngNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngNurserySecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, EngNurserySecB: { checked: false, disabled: true }, EngNurserySecC: { checked: false, disabled: true }, EngNurserySecD: { checked: false, disabled: true }, EngNurserySecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('EngNurserySecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngNurserySecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngNurserySecB: [] } }) }
                const c = schoolClassData.indexOf('EngNurserySecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngNurserySecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngNurserySecC: [] } }) }
                const d = schoolClassData.indexOf('EngNurserySecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngNurserySecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngNurserySecD: [] } }) }
                const e = schoolClassData.indexOf('EngNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngNurserySecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, EngNurserySecC: { checked: false, disabled: true }, EngNurserySecD: { checked: false, disabled: true }, EngNurserySecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('EngNurserySecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngNurserySecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngNurserySecC: [] } }) }
                const d = schoolClassData.indexOf('EngNurserySecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngNurserySecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngNurserySecD: [] } }) }
                const e = schoolClassData.indexOf('EngNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngNurserySecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, EngNurserySecD: { checked: false, disabled: true }, EngNurserySecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('EngNurserySecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngNurserySecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngNurserySecD: [] } }) }
                const e = schoolClassData.indexOf('EngNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngNurserySecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, EngNurserySecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('EngNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngNurserySecE: [] } }) }
            }
        }
    }
    const handleChanageClassesOfSchoolMarNursery = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, MarNurserySecA: { checked: false, disabled: true }, MarNurserySecB: { checked: false, disabled: true }, MarNurserySecC: { checked: false, disabled: true }, MarNurserySecD: { checked: false, disabled: true }, MarNurserySecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('MarNurserySecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesMarNurserySecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarNurserySecA: [] } }) }
                const b = schoolClassData.indexOf('MarNurserySecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarNurserySecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarNurserySecB: [] } }) }
                const c = schoolClassData.indexOf('MarNurserySecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarNurserySecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarNurserySecC: [] } }) }
                const d = schoolClassData.indexOf('MarNurserySecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarNurserySecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarNurserySecD: [] } }) }
                const e = schoolClassData.indexOf('MarNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarNurserySecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, MarNurserySecB: { checked: false, disabled: true }, MarNurserySecC: { checked: false, disabled: true }, MarNurserySecD: { checked: false, disabled: true }, MarNurserySecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('MarNurserySecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarNurserySecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarNurserySecB: [] } }) }
                const c = schoolClassData.indexOf('MarNurserySecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarNurserySecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarNurserySecC: [] } }) }
                const d = schoolClassData.indexOf('MarNurserySecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarNurserySecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarNurserySecD: [] } }) }
                const e = schoolClassData.indexOf('MarNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarNurserySecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, MarNurserySecC: { checked: false, disabled: true }, MarNurserySecD: { checked: false, disabled: true }, MarNurserySecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('MarNurserySecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarNurserySecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarNurserySecC: [] } }) }
                const d = schoolClassData.indexOf('MarNurserySecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarNurserySecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarNurserySecD: [] } }) }
                const e = schoolClassData.indexOf('MarNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarNurserySecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, MarNurserySecD: { checked: false, disabled: true }, MarNurserySecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('MarNurserySecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarNurserySecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarNurserySecD: [] } }) }
                const e = schoolClassData.indexOf('MarNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarNurserySecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, MarNurserySecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('MarNurserySecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarNurserySecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarNurserySecE: [] } }) }
            }
        }
    }

    const handleChanageClassesOfSchoolEngKG1 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, EngKG1SecA: { checked: false, disabled: true }, EngKG1SecB: { checked: false, disabled: true }, EngKG1SecC: { checked: false, disabled: true }, EngKG1SecD: { checked: false, disabled: true }, EngKG1SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('EngKG1SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesEngKG1SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG1SecA: [] } }) }
                const b = schoolClassData.indexOf('EngKG1SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngKG1SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG1SecB: [] } }) }
                const c = schoolClassData.indexOf('EngKG1SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngKG1SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG1SecC: [] } }) }
                const d = schoolClassData.indexOf('EngKG1SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngKG1SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG1SecD: [] } }) }
                const e = schoolClassData.indexOf('EngKG1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngKG1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG1SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, EngKG1SecB: { checked: false, disabled: true }, EngKG1SecC: { checked: false, disabled: true }, EngKG1SecD: { checked: false, disabled: true }, EngKG1SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('EngKG1SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngKG1SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG1SecB: [] } }) }
                const c = schoolClassData.indexOf('EngKG1SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngKG1SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG1SecC: [] } }) }
                const d = schoolClassData.indexOf('EngKG1SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngKG1SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG1SecD: [] } }) }
                const e = schoolClassData.indexOf('EngKG1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngKG1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG1SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, EngKG1SecC: { checked: false, disabled: true }, EngKG1SecD: { checked: false, disabled: true }, EngKG1SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('EngKG1SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngKG1SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG1SecC: [] } }) }
                const d = schoolClassData.indexOf('EngKG1SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngKG1SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG1SecD: [] } }) }
                const e = schoolClassData.indexOf('EngKG1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngKG1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG1SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, EngKG1SecD: { checked: false, disabled: true }, EngKG1SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('EngKG1SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngKG1SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG1SecD: [] } }) }
                const e = schoolClassData.indexOf('EngKG1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngKG1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG1SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, EngKG1SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('EngKG1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngKG1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG1SecE: [] } }) }
            }
        }
    }
    const handleChanageClassesOfSchoolMarKG1 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, MarKG1SecA: { checked: false, disabled: true }, MarKG1SecB: { checked: false, disabled: true }, MarKG1SecC: { checked: false, disabled: true }, MarKG1SecD: { checked: false, disabled: true }, MarKG1SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('MarKG1SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesMarKG1SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG1SecA: [] } }) }
                const b = schoolClassData.indexOf('MarKG1SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarKG1SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG1SecB: [] } }) }
                const c = schoolClassData.indexOf('MarKG1SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarKG1SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG1SecC: [] } }) }
                const d = schoolClassData.indexOf('MarKG1SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarKG1SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG1SecD: [] } }) }
                const e = schoolClassData.indexOf('MarKG1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarKG1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG1SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, MarKG1SecB: { checked: false, disabled: true }, MarKG1SecC: { checked: false, disabled: true }, MarKG1SecD: { checked: false, disabled: true }, MarKG1SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('MarKG1SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarKG1SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG1SecB: [] } }) }
                const c = schoolClassData.indexOf('MarKG1SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarKG1SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG1SecC: [] } }) }
                const d = schoolClassData.indexOf('MarKG1SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarKG1SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG1SecD: [] } }) }
                const e = schoolClassData.indexOf('MarKG1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarKG1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG1SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, MarKG1SecC: { checked: false, disabled: true }, MarKG1SecD: { checked: false, disabled: true }, MarKG1SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('MarKG1SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarKG1SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG1SecC: [] } }) }
                const d = schoolClassData.indexOf('MarKG1SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarKG1SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG1SecD: [] } }) }
                const e = schoolClassData.indexOf('MarKG1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarKG1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG1SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, MarKG1SecD: { checked: false, disabled: true }, MarKG1SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('MarKG1SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarKG1SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG1SecD: [] } }) }
                const e = schoolClassData.indexOf('MarKG1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarKG1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG1SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, MarKG1SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('MarKG1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarKG1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG1SecE: [] } }) }
            }
        }
    }

    const handleChanageClassesOfSchoolEngKG2 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, EngKG2SecA: { checked: false, disabled: true }, EngKG2SecB: { checked: false, disabled: true }, EngKG2SecC: { checked: false, disabled: true }, EngKG2SecD: { checked: false, disabled: true }, EngKG2SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('EngKG2SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesEngKG2SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG2SecA: [] } }) }
                const b = schoolClassData.indexOf('EngKG2SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngKG2SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG2SecB: [] } }) }
                const c = schoolClassData.indexOf('EngKG2SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngKG2SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG2SecC: [] } }) }
                const d = schoolClassData.indexOf('EngKG2SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngKG2SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG2SecD: [] } }) }
                const e = schoolClassData.indexOf('EngKG2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngKG2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG2SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, EngKG2SecB: { checked: false, disabled: true }, EngKG2SecC: { checked: false, disabled: true }, EngKG2SecD: { checked: false, disabled: true }, EngKG2SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('EngKG2SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngKG2SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG2SecB: [] } }) }
                const c = schoolClassData.indexOf('EngKG2SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngKG2SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG2SecC: [] } }) }
                const d = schoolClassData.indexOf('EngKG2SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngKG2SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG2SecD: [] } }) }
                const e = schoolClassData.indexOf('EngKG2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngKG2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG2SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, EngKG2SecC: { checked: false, disabled: true }, EngKG2SecD: { checked: false, disabled: true }, EngKG2SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('EngKG2SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngKG2SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG2SecC: [] } }) }
                const d = schoolClassData.indexOf('EngKG2SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngKG2SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG2SecD: [] } }) }
                const e = schoolClassData.indexOf('EngKG2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngKG2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG2SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, EngKG2SecD: { checked: false, disabled: true }, EngKG2SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('EngKG2SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngKG2SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG2SecD: [] } }) }
                const e = schoolClassData.indexOf('EngKG2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngKG2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG2SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, EngKG2SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('EngKG2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngKG2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngKG2SecE: [] } }) }
            }
        }
    }
    const handleChanageClassesOfSchoolMarKG2 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, MarKG2SecA: { checked: false, disabled: true }, MarKG2SecB: { checked: false, disabled: true }, MarKG2SecC: { checked: false, disabled: true }, MarKG2SecD: { checked: false, disabled: true }, MarKG2SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('MarKG2SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesMarKG2SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG2SecA: [] } }) }
                const b = schoolClassData.indexOf('MarKG2SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarKG2SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG2SecB: [] } }) }
                const c = schoolClassData.indexOf('MarKG2SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarKG2SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG2SecC: [] } }) }
                const d = schoolClassData.indexOf('MarKG2SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarKG2SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG2SecD: [] } }) }
                const e = schoolClassData.indexOf('MarKG2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarKG2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG2SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, MarKG2SecB: { checked: false, disabled: true }, MarKG2SecC: { checked: false, disabled: true }, MarKG2SecD: { checked: false, disabled: true }, MarKG2SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('MarKG2SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarKG2SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG2SecB: [] } }) }
                const c = schoolClassData.indexOf('MarKG2SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarKG2SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG2SecC: [] } }) }
                const d = schoolClassData.indexOf('MarKG2SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarKG2SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG2SecD: [] } }) }
                const e = schoolClassData.indexOf('MarKG2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarKG2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG2SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, MarKG2SecC: { checked: false, disabled: true }, MarKG2SecD: { checked: false, disabled: true }, MarKG2SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('MarKG2SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarKG2SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG2SecC: [] } }) }
                const d = schoolClassData.indexOf('MarKG2SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarKG2SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG2SecD: [] } }) }
                const e = schoolClassData.indexOf('MarKG2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarKG2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG2SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, MarKG2SecD: { checked: false, disabled: true }, MarKG2SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('MarKG2SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarKG2SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG2SecD: [] } }) }
                const e = schoolClassData.indexOf('MarKG2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarKG2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG2SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, MarKG2SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('MarKG2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarKG2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarKG2SecE: [] } }) }
            }
        }
    }

    const handleChanageClassesOfSchoolEngClass1 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass1SecA: { checked: false, disabled: true }, EngClass1SecB: { checked: false, disabled: true }, EngClass1SecC: { checked: false, disabled: true }, EngClass1SecD: { checked: false, disabled: true }, EngClass1SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('EngClass1SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesEngClass1SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass1SecA: [] } }) }
                const b = schoolClassData.indexOf('EngClass1SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass1SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass1SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass1SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass1SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass1SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass1SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass1SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass1SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass1SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass1SecB: { checked: false, disabled: true }, EngClass1SecC: { checked: false, disabled: true }, EngClass1SecD: { checked: false, disabled: true }, EngClass1SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('EngClass1SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass1SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass1SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass1SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass1SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass1SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass1SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass1SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass1SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass1SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass1SecC: { checked: false, disabled: true }, EngClass1SecD: { checked: false, disabled: true }, EngClass1SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('EngClass1SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass1SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass1SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass1SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass1SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass1SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass1SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass1SecD: { checked: false, disabled: true }, EngClass1SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('EngClass1SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass1SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass1SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass1SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass1SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('EngClass1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass1SecE: [] } }) }
            }
        }
    }
    const handleChanageClassesOfSchoolMarClass1 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass1SecA: { checked: false, disabled: true }, MarClass1SecB: { checked: false, disabled: true }, MarClass1SecC: { checked: false, disabled: true }, MarClass1SecD: { checked: false, disabled: true }, MarClass1SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('MarClass1SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesMarClass1SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass1SecA: [] } }) }
                const b = schoolClassData.indexOf('MarClass1SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass1SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass1SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass1SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass1SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass1SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass1SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass1SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass1SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass1SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass1SecB: { checked: false, disabled: true }, MarClass1SecC: { checked: false, disabled: true }, MarClass1SecD: { checked: false, disabled: true }, MarClass1SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('MarClass1SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass1SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass1SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass1SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass1SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass1SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass1SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass1SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass1SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass1SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass1SecC: { checked: false, disabled: true }, MarClass1SecD: { checked: false, disabled: true }, MarClass1SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('MarClass1SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass1SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass1SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass1SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass1SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass1SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass1SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass1SecD: { checked: false, disabled: true }, MarClass1SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('MarClass1SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass1SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass1SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass1SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass1SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('MarClass1SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass1SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass1SecE: [] } }) }
            }
        }
    }

    const handleChanageClassesOfSchoolEngClass2 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass2SecA: { checked: false, disabled: true }, EngClass2SecB: { checked: false, disabled: true }, EngClass2SecC: { checked: false, disabled: true }, EngClass2SecD: { checked: false, disabled: true }, EngClass2SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('EngClass2SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesEngClass2SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass2SecA: [] } }) }
                const b = schoolClassData.indexOf('EngClass2SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass2SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass2SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass2SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass2SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass2SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass2SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass2SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass2SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass2SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass2SecB: { checked: false, disabled: true }, EngClass2SecC: { checked: false, disabled: true }, EngClass2SecD: { checked: false, disabled: true }, EngClass2SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('EngClass2SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass2SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass2SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass2SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass2SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass2SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass2SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass2SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass2SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass2SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass2SecC: { checked: false, disabled: true }, EngClass2SecD: { checked: false, disabled: true }, EngClass2SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('EngClass2SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass2SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass2SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass2SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass2SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass2SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass2SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass2SecD: { checked: false, disabled: true }, EngClass2SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('EngClass2SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass2SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass2SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass2SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass2SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('EngClass2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass2SecE: [] } }) }
            }
        }
    }
    const handleChanageClassesOfSchoolMarClass2 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass2SecA: { checked: false, disabled: true }, MarClass2SecB: { checked: false, disabled: true }, MarClass2SecC: { checked: false, disabled: true }, MarClass2SecD: { checked: false, disabled: true }, MarClass2SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('MarClass2SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesMarClass2SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass2SecA: [] } }) }
                const b = schoolClassData.indexOf('MarClass2SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass2SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass2SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass2SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass2SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass2SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass2SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass2SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass2SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass2SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass2SecB: { checked: false, disabled: true }, MarClass2SecC: { checked: false, disabled: true }, MarClass2SecD: { checked: false, disabled: true }, MarClass2SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('MarClass2SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass2SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass2SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass2SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass2SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass2SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass2SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass2SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass2SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass2SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass2SecC: { checked: false, disabled: true }, MarClass2SecD: { checked: false, disabled: true }, MarClass2SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('MarClass2SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass2SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass2SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass2SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass2SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass2SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass2SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass2SecD: { checked: false, disabled: true }, MarClass2SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('MarClass2SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass2SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass2SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass2SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass2SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('MarClass2SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass2SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass2SecE: [] } }) }
            }
        }
    }

    const handleChanageClassesOfSchoolEngClass3 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass3SecA: { checked: false, disabled: true }, EngClass3SecB: { checked: false, disabled: true }, EngClass3SecC: { checked: false, disabled: true }, EngClass3SecD: { checked: false, disabled: true }, EngClass3SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('EngClass3SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesEngClass3SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass3SecA: [] } }) }
                const b = schoolClassData.indexOf('EngClass3SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass3SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass3SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass3SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass3SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass3SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass3SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass3SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass3SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass3SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass3SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass3SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass3SecB: { checked: false, disabled: true }, EngClass3SecC: { checked: false, disabled: true }, EngClass3SecD: { checked: false, disabled: true }, EngClass3SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('EngClass3SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass3SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass3SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass3SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass3SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass3SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass3SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass3SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass3SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass3SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass3SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass3SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass3SecC: { checked: false, disabled: true }, EngClass3SecD: { checked: false, disabled: true }, EngClass3SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('EngClass3SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass3SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass3SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass3SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass3SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass3SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass3SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass3SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass3SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass3SecD: { checked: false, disabled: true }, EngClass3SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('EngClass3SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass3SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass3SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass3SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass3SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass3SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass3SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('EngClass3SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass3SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass3SecE: [] } }) }
            }
        }
    }
    const handleChanageClassesOfSchoolMarClass3 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass3SecA: { checked: false, disabled: true }, MarClass3SecB: { checked: false, disabled: true }, MarClass3SecC: { checked: false, disabled: true }, MarClass3SecD: { checked: false, disabled: true }, MarClass3SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('MarClass3SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesMarClass3SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass3SecA: [] } }) }
                const b = schoolClassData.indexOf('MarClass3SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass3SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass3SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass3SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass3SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass3SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass3SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass3SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass3SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass3SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass3SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass3SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass3SecB: { checked: false, disabled: true }, MarClass3SecC: { checked: false, disabled: true }, MarClass3SecD: { checked: false, disabled: true }, MarClass3SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('MarClass3SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass3SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass3SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass3SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass3SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass3SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass3SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass3SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass3SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass3SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass3SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass3SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass3SecC: { checked: false, disabled: true }, MarClass3SecD: { checked: false, disabled: true }, MarClass3SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('MarClass3SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass3SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass3SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass3SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass3SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass3SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass3SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass3SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass3SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass3SecD: { checked: false, disabled: true }, MarClass3SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('MarClass3SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass3SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass3SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass3SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass3SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass3SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass3SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('MarClass3SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass3SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass3SecE: [] } }) }
            }
        }
    }

    const handleChanageClassesOfSchoolEngClass4 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass4SecA: { checked: false, disabled: true }, EngClass4SecB: { checked: false, disabled: true }, EngClass4SecC: { checked: false, disabled: true }, EngClass4SecD: { checked: false, disabled: true }, EngClass4SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('EngClass4SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesEngClass4SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass4SecA: [] } }) }
                const b = schoolClassData.indexOf('EngClass4SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass4SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass4SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass4SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass4SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass4SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass4SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass4SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass4SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass4SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass4SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass4SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass4SecB: { checked: false, disabled: true }, EngClass4SecC: { checked: false, disabled: true }, EngClass4SecD: { checked: false, disabled: true }, EngClass4SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('EngClass4SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass4SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass4SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass4SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass4SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass4SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass4SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass4SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass4SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass4SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass4SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass4SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass4SecC: { checked: false, disabled: true }, EngClass4SecD: { checked: false, disabled: true }, EngClass4SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('EngClass4SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass4SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass4SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass4SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass4SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass4SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass4SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass4SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass4SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass4SecD: { checked: false, disabled: true }, EngClass4SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('EngClass4SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass4SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass4SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass4SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass4SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass4SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass4SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('EngClass4SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass4SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass4SecE: [] } }) }
            }
        }
    }
    const handleChanageClassesOfSchoolMarClass4 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass4SecA: { checked: false, disabled: true }, MarClass4SecB: { checked: false, disabled: true }, MarClass4SecC: { checked: false, disabled: true }, MarClass4SecD: { checked: false, disabled: true }, MarClass4SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('MarClass4SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesMarClass4SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass4SecA: [] } }) }
                const b = schoolClassData.indexOf('MarClass4SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass4SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass4SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass4SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass4SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass4SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass4SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass4SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass4SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass4SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass4SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass4SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass4SecB: { checked: false, disabled: true }, MarClass4SecC: { checked: false, disabled: true }, MarClass4SecD: { checked: false, disabled: true }, MarClass4SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('MarClass4SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass4SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass4SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass4SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass4SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass4SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass4SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass4SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass4SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass4SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass4SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass4SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass4SecC: { checked: false, disabled: true }, MarClass4SecD: { checked: false, disabled: true }, MarClass4SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('MarClass4SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass4SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass4SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass4SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass4SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass4SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass4SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass4SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass4SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass4SecD: { checked: false, disabled: true }, MarClass4SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('MarClass4SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass4SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass4SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass4SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass4SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass4SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass4SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('MarClass4SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass4SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass4SecE: [] } }) }
            }
        }
    }

    const handleChanageClassesOfSchoolEngClass5 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass5SecA: { checked: false, disabled: true }, EngClass5SecB: { checked: false, disabled: true }, EngClass5SecC: { checked: false, disabled: true }, EngClass5SecD: { checked: false, disabled: true }, EngClass5SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('EngClass5SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesEngClass5SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass5SecA: [] } }) }
                const b = schoolClassData.indexOf('EngClass5SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass5SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass5SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass5SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass5SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass5SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass5SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass5SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass5SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass5SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass5SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass5SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass5SecB: { checked: false, disabled: true }, EngClass5SecC: { checked: false, disabled: true }, EngClass5SecD: { checked: false, disabled: true }, EngClass5SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('EngClass5SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass5SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass5SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass5SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass5SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass5SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass5SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass5SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass5SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass5SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass5SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass5SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass5SecC: { checked: false, disabled: true }, EngClass5SecD: { checked: false, disabled: true }, EngClass5SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('EngClass5SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass5SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass5SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass5SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass5SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass5SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass5SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass5SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass5SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass5SecD: { checked: false, disabled: true }, EngClass5SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('EngClass5SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass5SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass5SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass5SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass5SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass5SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass5SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('EngClass5SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass5SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass5SecE: [] } }) }
            }
        }
    }
    const handleChanageClassesOfSchoolMarClass5 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass5SecA: { checked: false, disabled: true }, MarClass5SecB: { checked: false, disabled: true }, MarClass5SecC: { checked: false, disabled: true }, MarClass5SecD: { checked: false, disabled: true }, MarClass5SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('MarClass5SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesMarClass5SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass5SecA: [] } }) }
                const b = schoolClassData.indexOf('MarClass5SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass5SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass5SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass5SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass5SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass5SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass5SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass5SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass5SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass5SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass5SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass5SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass5SecB: { checked: false, disabled: true }, MarClass5SecC: { checked: false, disabled: true }, MarClass5SecD: { checked: false, disabled: true }, MarClass5SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('MarClass5SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass5SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass5SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass5SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass5SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass5SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass5SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass5SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass5SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass5SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass5SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass5SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass5SecC: { checked: false, disabled: true }, MarClass5SecD: { checked: false, disabled: true }, MarClass5SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('MarClass5SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass5SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass5SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass5SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass5SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass5SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass5SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass5SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass5SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass5SecD: { checked: false, disabled: true }, MarClass5SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('MarClass5SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass5SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass5SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass5SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass5SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass5SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass5SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('MarClass5SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass5SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass5SecE: [] } }) }
            }
        }
    }

    const handleChanageClassesOfSchoolEngClass6 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass6SecA: { checked: false, disabled: true }, EngClass6SecB: { checked: false, disabled: true }, EngClass6SecC: { checked: false, disabled: true }, EngClass6SecD: { checked: false, disabled: true }, EngClass6SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('EngClass6SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesEngClass6SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass6SecA: [] } }) }
                const b = schoolClassData.indexOf('EngClass6SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass6SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass6SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass6SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass6SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass6SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass6SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass6SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass6SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass6SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass6SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass6SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass6SecB: { checked: false, disabled: true }, EngClass6SecC: { checked: false, disabled: true }, EngClass6SecD: { checked: false, disabled: true }, EngClass6SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('EngClass6SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass6SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass6SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass6SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass6SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass6SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass6SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass6SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass6SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass6SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass6SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass6SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass6SecC: { checked: false, disabled: true }, EngClass6SecD: { checked: false, disabled: true }, EngClass6SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('EngClass6SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass6SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass6SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass6SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass6SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass6SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass6SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass6SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass6SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass6SecD: { checked: false, disabled: true }, EngClass6SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('EngClass6SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass6SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass6SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass6SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass6SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass6SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass6SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('EngClass6SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass6SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass6SecE: [] } }) }
            }
        }
    }
    const handleChanageClassesOfSchoolMarClass6 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass6SecA: { checked: false, disabled: true }, MarClass6SecB: { checked: false, disabled: true }, MarClass6SecC: { checked: false, disabled: true }, MarClass6SecD: { checked: false, disabled: true }, MarClass6SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('MarClass6SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesMarClass6SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass6SecA: [] } }) }
                const b = schoolClassData.indexOf('MarClass6SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass6SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass6SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass6SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass6SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass6SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass6SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass6SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass6SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass6SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass6SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass6SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass6SecB: { checked: false, disabled: true }, MarClass6SecC: { checked: false, disabled: true }, MarClass6SecD: { checked: false, disabled: true }, MarClass6SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('MarClass6SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass6SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass6SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass6SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass6SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass6SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass6SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass6SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass6SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass6SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass6SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass6SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass6SecC: { checked: false, disabled: true }, MarClass6SecD: { checked: false, disabled: true }, MarClass6SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('MarClass6SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass6SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass6SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass6SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass6SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass6SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass6SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass6SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass6SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass6SecD: { checked: false, disabled: true }, MarClass6SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('MarClass6SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass6SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass6SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass6SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass6SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass6SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass6SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('MarClass6SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass6SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass6SecE: [] } }) }
            }
        }
    }

    const handleChanageClassesOfSchoolEngClass7 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass7SecA: { checked: false, disabled: true }, EngClass7SecB: { checked: false, disabled: true }, EngClass7SecC: { checked: false, disabled: true }, EngClass7SecD: { checked: false, disabled: true }, EngClass7SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('EngClass7SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesEngClass7SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass7SecA: [] } }) }
                const b = schoolClassData.indexOf('EngClass7SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass7SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass7SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass7SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass7SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass7SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass7SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass7SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass7SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass7SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass7SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass7SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass7SecB: { checked: false, disabled: true }, EngClass7SecC: { checked: false, disabled: true }, EngClass7SecD: { checked: false, disabled: true }, EngClass7SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('EngClass7SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass7SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass7SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass7SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass7SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass7SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass7SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass7SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass7SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass7SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass7SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass7SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass7SecC: { checked: false, disabled: true }, EngClass7SecD: { checked: false, disabled: true }, EngClass7SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('EngClass7SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass7SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass7SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass7SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass7SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass7SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass7SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass7SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass7SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass7SecD: { checked: false, disabled: true }, EngClass7SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('EngClass7SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass7SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass7SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass7SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass7SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass7SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass7SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('EngClass7SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass7SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass7SecE: [] } }) }
            }
        }

    }
    const handleChanageClassesOfSchoolMarClass7 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass7SecA: { checked: false, disabled: true }, MarClass7SecB: { checked: false, disabled: true }, MarClass7SecC: { checked: false, disabled: true }, MarClass7SecD: { checked: false, disabled: true }, MarClass7SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('MarClass7SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesMarClass7SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass7SecA: [] } }) }
                const b = schoolClassData.indexOf('MarClass7SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass7SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass7SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass7SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass7SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass7SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass7SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass7SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass7SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass7SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass7SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass7SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass7SecB: { checked: false, disabled: true }, MarClass7SecC: { checked: false, disabled: true }, MarClass7SecD: { checked: false, disabled: true }, MarClass7SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('MarClass7SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass7SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass7SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass7SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass7SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass7SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass7SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass7SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass7SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass7SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass7SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass7SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass7SecC: { checked: false, disabled: true }, MarClass7SecD: { checked: false, disabled: true }, MarClass7SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('MarClass7SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass7SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass7SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass7SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass7SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass7SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass7SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass7SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass7SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass7SecD: { checked: false, disabled: true }, MarClass7SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('MarClass7SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass7SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass7SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass7SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass7SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass7SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass7SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('MarClass7SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass7SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass7SecE: [] } }) }
            }
        }

    }

    const handleChanageClassesOfSchoolEngClass8 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass8SecA: { checked: false, disabled: true }, EngClass8SecB: { checked: false, disabled: true }, EngClass8SecC: { checked: false, disabled: true }, EngClass8SecD: { checked: false, disabled: true }, EngClass8SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('EngClass8SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesEngClass8SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass8SecA: [] } }) }
                const b = schoolClassData.indexOf('EngClass8SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass8SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass8SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass8SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass8SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass8SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass8SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass8SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass8SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass8SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass8SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass8SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass8SecB: { checked: false, disabled: true }, EngClass8SecC: { checked: false, disabled: true }, EngClass8SecD: { checked: false, disabled: true }, EngClass8SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('EngClass8SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass8SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass8SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass8SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass8SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass8SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass8SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass8SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass8SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass8SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass8SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass8SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass8SecC: { checked: false, disabled: true }, EngClass8SecD: { checked: false, disabled: true }, EngClass8SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('EngClass8SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass8SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass8SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass8SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass8SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass8SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass8SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass8SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass8SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass8SecD: { checked: false, disabled: true }, EngClass8SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('EngClass8SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass8SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass8SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass8SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass8SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass8SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass8SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('EngClass8SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass8SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass8SecE: [] } }) }
            }
        }

    }
    const handleChanageClassesOfSchoolMarClass8 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass8SecA: { checked: false, disabled: true }, MarClass8SecB: { checked: false, disabled: true }, MarClass8SecC: { checked: false, disabled: true }, MarClass8SecD: { checked: false, disabled: true }, MarClass8SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('MarClass8SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesMarClass8SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass8SecA: [] } }) }
                const b = schoolClassData.indexOf('MarClass8SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass8SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass8SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass8SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass8SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass8SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass8SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass8SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass8SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass8SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass8SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass8SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass8SecB: { checked: false, disabled: true }, MarClass8SecC: { checked: false, disabled: true }, MarClass8SecD: { checked: false, disabled: true }, MarClass8SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('MarClass8SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass8SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass8SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass8SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass8SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass8SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass8SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass8SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass8SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass8SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass8SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass8SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass8SecC: { checked: false, disabled: true }, MarClass8SecD: { checked: false, disabled: true }, MarClass8SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('MarClass8SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass8SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass8SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass8SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass8SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass8SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass8SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass8SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass8SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass8SecD: { checked: false, disabled: true }, MarClass8SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('MarClass8SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass8SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass8SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass8SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass8SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass8SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass8SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('MarClass8SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass8SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass8SecE: [] } }) }
            }
        }

    }

    const handleChanageClassesOfSchoolEngClass9 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass9SecA: { checked: false, disabled: true }, EngClass9SecB: { checked: false, disabled: true }, EngClass9SecC: { checked: false, disabled: true }, EngClass9SecD: { checked: false, disabled: true }, EngClass9SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('EngClass9SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesEngClass9SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass9SecA: [] } }) }
                const b = schoolClassData.indexOf('EngClass9SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass9SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass9SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass9SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass9SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass9SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass9SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass9SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass9SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass9SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass9SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass9SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass9SecB: { checked: false, disabled: true }, EngClass9SecC: { checked: false, disabled: true }, EngClass9SecD: { checked: false, disabled: true }, EngClass9SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('EngClass9SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass9SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass9SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass9SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass9SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass9SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass9SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass9SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass9SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass9SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass9SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass9SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass9SecC: { checked: false, disabled: true }, EngClass9SecD: { checked: false, disabled: true }, EngClass9SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('EngClass9SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass9SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass9SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass9SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass9SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass9SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass9SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass9SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass9SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass9SecD: { checked: false, disabled: true }, EngClass9SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('EngClass9SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass9SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass9SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass9SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass9SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass9SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass9SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('EngClass9SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass9SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass9SecE: [] } }) }
            }
        }

    }
    const handleChanageClassesOfSchoolMarClass9 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass9SecA: { checked: false, disabled: true }, MarClass9SecB: { checked: false, disabled: true }, MarClass9SecC: { checked: false, disabled: true }, MarClass9SecD: { checked: false, disabled: true }, MarClass9SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('MarClass9SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesMarClass9SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass9SecA: [] } }) }
                const b = schoolClassData.indexOf('MarClass9SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass9SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass9SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass9SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass9SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass9SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass9SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass9SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass9SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass9SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass9SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass9SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass9SecB: { checked: false, disabled: true }, MarClass9SecC: { checked: false, disabled: true }, MarClass9SecD: { checked: false, disabled: true }, MarClass9SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('MarClass9SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass9SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass9SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass9SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass9SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass9SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass9SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass9SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass9SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass9SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass9SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass9SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass9SecC: { checked: false, disabled: true }, MarClass9SecD: { checked: false, disabled: true }, MarClass9SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('MarClass9SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass9SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass9SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass9SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass9SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass9SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass9SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass9SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass9SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass9SecD: { checked: false, disabled: true }, MarClass9SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('MarClass9SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass9SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass9SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass9SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass9SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass9SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass9SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('MarClass9SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass9SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass9SecE: [] } }) }
            }
        }

    }

    const handleChanageClassesOfSchoolEngClass10 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass10SecA: { checked: false, disabled: true }, EngClass10SecB: { checked: false, disabled: true }, EngClass10SecC: { checked: false, disabled: true }, EngClass10SecD: { checked: false, disabled: true }, EngClass10SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('EngClass10SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesEngClass10SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass10SecA: [] } }) }
                const b = schoolClassData.indexOf('EngClass10SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass10SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass10SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass10SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass10SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass10SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass10SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass10SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass10SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass10SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass10SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass10SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass10SecB: { checked: false, disabled: true }, EngClass10SecC: { checked: false, disabled: true }, EngClass10SecD: { checked: false, disabled: true }, EngClass10SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('EngClass10SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass10SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass10SecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass10SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass10SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass10SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass10SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass10SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass10SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass10SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass10SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass10SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass10SecC: { checked: false, disabled: true }, EngClass10SecD: { checked: false, disabled: true }, EngClass10SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('EngClass10SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass10SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass10SecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass10SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass10SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass10SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass10SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass10SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass10SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass10SecD: { checked: false, disabled: true }, EngClass10SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('EngClass10SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass10SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass10SecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass10SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass10SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass10SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass10SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('EngClass10SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass10SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass10SecE: [] } }) }
            }
        }

    }
    const handleChanageClassesOfSchoolMarClass10 = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass10SecA: { checked: false, disabled: true }, MarClass10SecB: { checked: false, disabled: true }, MarClass10SecC: { checked: false, disabled: true }, MarClass10SecD: { checked: false, disabled: true }, MarClass10SecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('MarClass10SecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesMarClass10SecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass10SecA: [] } }) }
                const b = schoolClassData.indexOf('MarClass10SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass10SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass10SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass10SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass10SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass10SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass10SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass10SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass10SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass10SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass10SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass10SecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass10SecB: { checked: false, disabled: true }, MarClass10SecC: { checked: false, disabled: true }, MarClass10SecD: { checked: false, disabled: true }, MarClass10SecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('MarClass10SecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass10SecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass10SecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass10SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass10SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass10SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass10SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass10SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass10SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass10SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass10SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass10SecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass10SecC: { checked: false, disabled: true }, MarClass10SecD: { checked: false, disabled: true }, MarClass10SecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('MarClass10SecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass10SecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass10SecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass10SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass10SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass10SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass10SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass10SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass10SecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass10SecD: { checked: false, disabled: true }, MarClass10SecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('MarClass10SecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass10SecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass10SecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass10SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass10SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass10SecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass10SecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('MarClass10SecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass10SecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass10SecE: [] } }) }
            }
        }

    }

    const handleChanageClassesOfSchoolMarClass11Arts = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass11ArtsSecA: { checked: false, disabled: true }, MarClass11ArtsSecB: { checked: false, disabled: true }, MarClass11ArtsSecC: { checked: false, disabled: true }, MarClass11ArtsSecD: { checked: false, disabled: true }, MarClass11ArtsSecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('MarClass11ArtsSecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesMarClass11ArtsSecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11ArtsSecA: [] } }) }
                const b = schoolClassData.indexOf('MarClass11ArtsSecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass11ArtsSecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11ArtsSecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass11ArtsSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass11ArtsSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11ArtsSecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass11ArtsSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass11ArtsSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11ArtsSecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass11ArtsSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass11ArtsSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11ArtsSecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass11ArtsSecB: { checked: false, disabled: true }, MarClass11ArtsSecC: { checked: false, disabled: true }, MarClass11ArtsSecD: { checked: false, disabled: true }, MarClass11ArtsSecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('MarClass11ArtsSecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass11ArtsSecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11ArtsSecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass11ArtsSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass11ArtsSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11ArtsSecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass11ArtsSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass11ArtsSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11ArtsSecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass11ArtsSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass11ArtsSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11ArtsSecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass11ArtsSecC: { checked: false, disabled: true }, MarClass11ArtsSecD: { checked: false, disabled: true }, MarClass11ArtsSecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('MarClass11ArtsSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass11ArtsSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11ArtsSecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass11ArtsSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass11ArtsSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11ArtsSecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass11ArtsSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass11ArtsSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11ArtsSecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass11ArtsSecD: { checked: false, disabled: true }, MarClass11ArtsSecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('MarClass11ArtsSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass11ArtsSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11ArtsSecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass11ArtsSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass11ArtsSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11ArtsSecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass11ArtsSecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('MarClass11ArtsSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass11ArtsSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11ArtsSecE: [] } }) }
            }
        }

    }
    const handleChanageClassesOfSchoolEngClass11Commerce = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass11CommerceSecA: { checked: false, disabled: true }, EngClass11CommerceSecB: { checked: false, disabled: true }, EngClass11CommerceSecC: { checked: false, disabled: true }, EngClass11CommerceSecD: { checked: false, disabled: true }, EngClass11CommerceSecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('EngClass11CommerceSecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesEngClass11CommerceSecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11CommerceSecA: [] } }) }
                const b = schoolClassData.indexOf('EngClass11CommerceSecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass11CommerceSecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11CommerceSecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass11CommerceSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass11CommerceSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11CommerceSecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass11CommerceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass11CommerceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11CommerceSecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass11CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass11CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11CommerceSecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass11CommerceSecB: { checked: false, disabled: true }, EngClass11CommerceSecC: { checked: false, disabled: true }, EngClass11CommerceSecD: { checked: false, disabled: true }, EngClass11CommerceSecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('EngClass11CommerceSecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass11CommerceSecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11CommerceSecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass11CommerceSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass11CommerceSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11CommerceSecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass11CommerceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass11CommerceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11CommerceSecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass11CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass11CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11CommerceSecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass11CommerceSecC: { checked: false, disabled: true }, EngClass11CommerceSecD: { checked: false, disabled: true }, EngClass11CommerceSecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('EngClass11CommerceSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass11CommerceSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11CommerceSecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass11CommerceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass11CommerceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11CommerceSecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass11CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass11CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11CommerceSecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass11CommerceSecD: { checked: false, disabled: true }, EngClass11CommerceSecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('EngClass11CommerceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass11CommerceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11CommerceSecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass11CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass11CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11CommerceSecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass11CommerceSecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('EngClass11CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass11CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11CommerceSecE: [] } }) }
            }
        }

    }
    const handleChanageClassesOfSchoolMarClass11Commerce = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass11CommerceSecA: { checked: false, disabled: true }, MarClass11CommerceSecB: { checked: false, disabled: true }, MarClass11CommerceSecC: { checked: false, disabled: true }, MarClass11CommerceSecD: { checked: false, disabled: true }, MarClass11CommerceSecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('MarClass11CommerceSecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesMarClass11CommerceSecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11CommerceSecA: [] } }) }
                const b = schoolClassData.indexOf('MarClass11CommerceSecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass11CommerceSecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11CommerceSecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass11CommerceSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass11CommerceSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11CommerceSecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass11CommerceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass11CommerceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11CommerceSecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass11CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass11CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11CommerceSecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass11CommerceSecB: { checked: false, disabled: true }, MarClass11CommerceSecC: { checked: false, disabled: true }, MarClass11CommerceSecD: { checked: false, disabled: true }, MarClass11CommerceSecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('MarClass11CommerceSecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass11CommerceSecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11CommerceSecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass11CommerceSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass11CommerceSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11CommerceSecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass11CommerceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass11CommerceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11CommerceSecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass11CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass11CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11CommerceSecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass11CommerceSecC: { checked: false, disabled: true }, MarClass11CommerceSecD: { checked: false, disabled: true }, MarClass11CommerceSecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('MarClass11CommerceSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass11CommerceSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11CommerceSecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass11CommerceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass11CommerceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11CommerceSecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass11CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass11CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11CommerceSecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass11CommerceSecD: { checked: false, disabled: true }, MarClass11CommerceSecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('MarClass11CommerceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass11CommerceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11CommerceSecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass11CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass11CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11CommerceSecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass11CommerceSecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('MarClass11CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass11CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass11CommerceSecE: [] } }) }
            }
        }

    }
    const handleChanageClassesOfSchoolEngClass11Science = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass11ScienceSecA: { checked: false, disabled: true }, EngClass11ScienceSecB: { checked: false, disabled: true }, EngClass11ScienceSecC: { checked: false, disabled: true }, EngClass11ScienceSecD: { checked: false, disabled: true }, EngClass11ScienceSecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('EngClass11ScienceSecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesEngClass11ScienceSecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11ScienceSecA: [] } }) }
                const b = schoolClassData.indexOf('EngClass11ScienceSecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass11ScienceSecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11ScienceSecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass11ScienceSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass11ScienceSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11ScienceSecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass11ScienceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass11ScienceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11ScienceSecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass11ScienceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass11ScienceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11ScienceSecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass11ScienceSecB: { checked: false, disabled: true }, EngClass11ScienceSecC: { checked: false, disabled: true }, EngClass11ScienceSecD: { checked: false, disabled: true }, EngClass11ScienceSecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('EngClass11ScienceSecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass11ScienceSecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11ScienceSecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass11ScienceSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass11ScienceSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11ScienceSecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass11ScienceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass11ScienceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11ScienceSecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass11ScienceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass11ScienceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11ScienceSecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass11ScienceSecC: { checked: false, disabled: true }, EngClass11ScienceSecD: { checked: false, disabled: true }, EngClass11ScienceSecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('EngClass11ScienceSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass11ScienceSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11ScienceSecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass11ScienceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass11ScienceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11ScienceSecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass11ScienceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass11ScienceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11ScienceSecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass11ScienceSecD: { checked: false, disabled: true }, EngClass11ScienceSecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('EngClass11ScienceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass11ScienceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11ScienceSecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass11ScienceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass11ScienceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11ScienceSecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass11ScienceSecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('EngClass11ScienceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass11ScienceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass11ScienceSecE: [] } }) }
            }
        }

    }

    const handleChanageClassesOfSchoolMarClass12Arts = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass12ArtsSecA: { checked: false, disabled: true }, MarClass12ArtsSecB: { checked: false, disabled: true }, MarClass12ArtsSecC: { checked: false, disabled: true }, MarClass12ArtsSecD: { checked: false, disabled: true }, MarClass12ArtsSecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('MarClass12ArtsSecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesMarClass12ArtsSecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12ArtsSecA: [] } }) }
                const b = schoolClassData.indexOf('MarClass12ArtsSecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass12ArtsSecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12ArtsSecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass12ArtsSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass12ArtsSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12ArtsSecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass12ArtsSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass12ArtsSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12ArtsSecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass12ArtsSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass12ArtsSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12ArtsSecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass12ArtsSecB: { checked: false, disabled: true }, MarClass12ArtsSecC: { checked: false, disabled: true }, MarClass12ArtsSecD: { checked: false, disabled: true }, MarClass12ArtsSecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('MarClass12ArtsSecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass12ArtsSecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12ArtsSecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass12ArtsSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass12ArtsSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12ArtsSecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass12ArtsSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass12ArtsSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12ArtsSecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass12ArtsSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass12ArtsSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12ArtsSecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass12ArtsSecC: { checked: false, disabled: true }, MarClass12ArtsSecD: { checked: false, disabled: true }, MarClass12ArtsSecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('MarClass12ArtsSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass12ArtsSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12ArtsSecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass12ArtsSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass12ArtsSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12ArtsSecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass12ArtsSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass12ArtsSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12ArtsSecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass12ArtsSecD: { checked: false, disabled: true }, MarClass12ArtsSecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('MarClass12ArtsSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass12ArtsSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12ArtsSecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass12ArtsSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass12ArtsSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12ArtsSecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass12ArtsSecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('MarClass12ArtsSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass12ArtsSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12ArtsSecE: [] } }) }
            }
        }

    }
    const handleChanageClassesOfSchoolEngClass12Commerce = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass12CommerceSecA: { checked: false, disabled: true }, EngClass12CommerceSecB: { checked: false, disabled: true }, EngClass12CommerceSecC: { checked: false, disabled: true }, EngClass12CommerceSecD: { checked: false, disabled: true }, EngClass12CommerceSecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('EngClass12CommerceSecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesEngClass12CommerceSecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12CommerceSecA: [] } }) }
                const b = schoolClassData.indexOf('EngClass12CommerceSecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass12CommerceSecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12CommerceSecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass12CommerceSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass12CommerceSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12CommerceSecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass12CommerceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass12CommerceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12CommerceSecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass12CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass12CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12CommerceSecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass12CommerceSecB: { checked: false, disabled: true }, EngClass12CommerceSecC: { checked: false, disabled: true }, EngClass12CommerceSecD: { checked: false, disabled: true }, EngClass12CommerceSecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('EngClass12CommerceSecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass12CommerceSecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12CommerceSecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass12CommerceSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass12CommerceSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12CommerceSecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass12CommerceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass12CommerceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12CommerceSecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass12CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass12CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12CommerceSecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass12CommerceSecC: { checked: false, disabled: true }, EngClass12CommerceSecD: { checked: false, disabled: true }, EngClass12CommerceSecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('EngClass12CommerceSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass12CommerceSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12CommerceSecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass12CommerceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass12CommerceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12CommerceSecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass12CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass12CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12CommerceSecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass12CommerceSecD: { checked: false, disabled: true }, EngClass12CommerceSecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('EngClass12CommerceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass12CommerceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12CommerceSecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass12CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass12CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12CommerceSecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass12CommerceSecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('EngClass12CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass12CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12CommerceSecE: [] } }) }
            }
        }

    }
    const handleChanageClassesOfSchoolMarClass12Commerce = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass12CommerceSecA: { checked: false, disabled: true }, MarClass12CommerceSecB: { checked: false, disabled: true }, MarClass12CommerceSecC: { checked: false, disabled: true }, MarClass12CommerceSecD: { checked: false, disabled: true }, MarClass12CommerceSecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('MarClass12CommerceSecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesMarClass12CommerceSecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12CommerceSecA: [] } }) }
                const b = schoolClassData.indexOf('MarClass12CommerceSecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass12CommerceSecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12CommerceSecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass12CommerceSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass12CommerceSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12CommerceSecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass12CommerceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass12CommerceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12CommerceSecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass12CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass12CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12CommerceSecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass12CommerceSecB: { checked: false, disabled: true }, MarClass12CommerceSecC: { checked: false, disabled: true }, MarClass12CommerceSecD: { checked: false, disabled: true }, MarClass12CommerceSecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('MarClass12CommerceSecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesMarClass12CommerceSecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12CommerceSecB: [] } }) }
                const c = schoolClassData.indexOf('MarClass12CommerceSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass12CommerceSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12CommerceSecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass12CommerceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass12CommerceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12CommerceSecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass12CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass12CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12CommerceSecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass12CommerceSecC: { checked: false, disabled: true }, MarClass12CommerceSecD: { checked: false, disabled: true }, MarClass12CommerceSecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('MarClass12CommerceSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesMarClass12CommerceSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12CommerceSecC: [] } }) }
                const d = schoolClassData.indexOf('MarClass12CommerceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass12CommerceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12CommerceSecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass12CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass12CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12CommerceSecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass12CommerceSecD: { checked: false, disabled: true }, MarClass12CommerceSecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('MarClass12CommerceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesMarClass12CommerceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12CommerceSecD: [] } }) }
                const e = schoolClassData.indexOf('MarClass12CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass12CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12CommerceSecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, MarClass12CommerceSecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('MarClass12CommerceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesMarClass12CommerceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesMarClass12CommerceSecE: [] } }) }
            }
        }

    }
    const handleChanageClassesOfSchoolEngClass12Science = (e) => {
        e.preventDefault()
        if (e.target.checked === true) {
            const checkedValue = e.target.checked;
            const disabledValue = false;
            setClassesOfSchool(prevState => { return { ...prevState, [e.target.name]: { checked: checkedValue, disabled: disabledValue } } });
            setSchoolClassData(prev => { return [...prev, e.target.name] })
            seterrorDisplay('')
        }
        else {
            const name = e.target.name;
            const lastWord = name.charAt(name.length - 1);
            if (lastWord === 'A') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass12ScienceSecA: { checked: false, disabled: true }, EngClass12ScienceSecB: { checked: false, disabled: true }, EngClass12ScienceSecC: { checked: false, disabled: true }, EngClass12ScienceSecD: { checked: false, disabled: true }, EngClass12ScienceSecE: { checked: false, disabled: true }, } });
                const a = schoolClassData.indexOf('EngClass12ScienceSecA');
                if (a >= 0) { schoolClassData.splice(a, 1); }
                if (subjectsOfClass.subNamesEngClass12ScienceSecA.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12ScienceSecA: [] } }) }
                const b = schoolClassData.indexOf('EngClass12ScienceSecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass12ScienceSecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12ScienceSecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass12ScienceSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass12ScienceSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12ScienceSecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass12ScienceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass12ScienceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12ScienceSecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass12ScienceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass12ScienceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12ScienceSecE: [] } }) }
            }
            if (lastWord === 'B') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass12ScienceSecB: { checked: false, disabled: true }, EngClass12ScienceSecC: { checked: false, disabled: true }, EngClass12ScienceSecD: { checked: false, disabled: true }, EngClass12ScienceSecE: { checked: false, disabled: true }, } });
                const b = schoolClassData.indexOf('EngClass12ScienceSecB');
                if (b >= 0) { schoolClassData.splice(b, 1); }
                if (subjectsOfClass.subNamesEngClass12ScienceSecB.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12ScienceSecB: [] } }) }
                const c = schoolClassData.indexOf('EngClass12ScienceSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass12ScienceSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12ScienceSecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass12ScienceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass12ScienceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12ScienceSecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass12ScienceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass12ScienceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12ScienceSecE: [] } }) }
            }
            if (lastWord === 'C') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass12ScienceSecC: { checked: false, disabled: true }, EngClass12ScienceSecD: { checked: false, disabled: true }, EngClass12ScienceSecE: { checked: false, disabled: true }, } });
                const c = schoolClassData.indexOf('EngClass12ScienceSecC');
                if (c >= 0) { schoolClassData.splice(c, 1); }
                if (subjectsOfClass.subNamesEngClass12ScienceSecC.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12ScienceSecC: [] } }) }
                const d = schoolClassData.indexOf('EngClass12ScienceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass12ScienceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12ScienceSecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass12ScienceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass12ScienceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12ScienceSecE: [] } }) }
            }
            if (lastWord === 'D') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass12ScienceSecD: { checked: false, disabled: true }, EngClass12ScienceSecE: { checked: false, disabled: true }, } });
                const d = schoolClassData.indexOf('EngClass12ScienceSecD');
                if (d >= 0) { schoolClassData.splice(d, 1); }
                if (subjectsOfClass.subNamesEngClass12ScienceSecD.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12ScienceSecD: [] } }) }
                const e = schoolClassData.indexOf('EngClass12ScienceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass12ScienceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12ScienceSecE: [] } }) }
            }
            if (lastWord === 'E') {
                setClassesOfSchool(prevState => { return { ...prevState, EngClass12ScienceSecE: { checked: false, disabled: true }, } });
                const e = schoolClassData.indexOf('EngClass12ScienceSecE');
                if (e >= 0) { schoolClassData.splice(e, 1); }
                if (subjectsOfClass.subNamesEngClass12ScienceSecE.length > 0) { setSubjectsOfClass(prevState => { return { ...prevState, subNamesEngClass12ScienceSecE: [] } }) }
            }
        }

    }

    const [showEnteredSchoolSub, setshowEnteredSchoolSub] = useState({
        subNamesEngPreNurserySecA: { error: false },
        subNamesEngPreNurserySecB: { error: false },
        subNamesEngPreNurserySecC: { error: false },
        subNamesEngPreNurserySecD: { error: false },
        subNamesEngPreNurserySecE: { error: false },
        subNamesMarPreNurserySecA: { error: false },
        subNamesMarPreNurserySecB: { error: false },
        subNamesMarPreNurserySecC: { error: false },
        subNamesMarPreNurserySecD: { error: false },
        subNamesMarPreNurserySecE: { error: false },
        subNamesEngNurserySecA: { error: false },
        subNamesEngNurserySecB: { error: false },
        subNamesEngNurserySecC: { error: false },
        subNamesEngNurserySecD: { error: false },
        subNamesEngNurserySecE: { error: false },
        subNamesMarNurserySecA: { error: false },
        subNamesMarNurserySecB: { error: false },
        subNamesMarNurserySecC: { error: false },
        subNamesMarNurserySecD: { error: false },
        subNamesMarNurserySecE: { error: false },
        subNamesEngKG1SecA: { error: false },
        subNamesEngKG1SecB: { error: false },
        subNamesEngKG1SecC: { error: false },
        subNamesEngKG1SecD: { error: false },
        subNamesEngKG1SecE: { error: false },
        subNamesMarKG1SecA: { error: false },
        subNamesMarKG1SecB: { error: false },
        subNamesMarKG1SecC: { error: false },
        subNamesMarKG1SecD: { error: false },
        subNamesMarKG1SecE: { error: false },
        subNamesEngKG2SecA: { error: false },
        subNamesEngKG2SecB: { error: false },
        subNamesEngKG2SecC: { error: false },
        subNamesEngKG2SecD: { error: false },
        subNamesEngKG2SecE: { error: false },
        subNamesMarKG2SecA: { error: false },
        subNamesMarKG2SecB: { error: false },
        subNamesMarKG2SecC: { error: false },
        subNamesMarKG2SecD: { error: false },
        subNamesMarKG2SecE: { error: false },
        subNamesEngClass1SecA: { error: false },
        subNamesEngClass1SecB: { error: false },
        subNamesEngClass1SecC: { error: false },
        subNamesEngClass1SecD: { error: false },
        subNamesEngClass1SecE: { error: false },
        subNamesMarClass1SecA: { error: false },
        subNamesMarClass1SecB: { error: false },
        subNamesMarClass1SecC: { error: false },
        subNamesMarClass1SecD: { error: false },
        subNamesMarClass1SecE: { error: false },
        subNamesEngClass2SecA: { error: false },
        subNamesEngClass2SecB: { error: false },
        subNamesEngClass2SecC: { error: false },
        subNamesEngClass2SecD: { error: false },
        subNamesEngClass2SecE: { error: false },
        subNamesMarClass2SecA: { error: false },
        subNamesMarClass2SecB: { error: false },
        subNamesMarClass2SecC: { error: false },
        subNamesMarClass2SecD: { error: false },
        subNamesMarClass2SecE: { error: false },
        subNamesEngClass3SecA: { error: false },
        subNamesEngClass3SecB: { error: false },
        subNamesEngClass3SecC: { error: false },
        subNamesEngClass3SecD: { error: false },
        subNamesEngClass3SecE: { error: false },
        subNamesMarClass3SecA: { error: false },
        subNamesMarClass3SecB: { error: false },
        subNamesMarClass3SecC: { error: false },
        subNamesMarClass3SecD: { error: false },
        subNamesMarClass3SecE: { error: false },
        subNamesEngClass4SecA: { error: false },
        subNamesEngClass4SecB: { error: false },
        subNamesEngClass4SecC: { error: false },
        subNamesEngClass4SecD: { error: false },
        subNamesEngClass4SecE: { error: false },
        subNamesMarClass4SecA: { error: false },
        subNamesMarClass4SecB: { error: false },
        subNamesMarClass4SecC: { error: false },
        subNamesMarClass4SecD: { error: false },
        subNamesMarClass4SecE: { error: false },
        subNamesEngClass5SecA: { error: false },
        subNamesEngClass5SecB: { error: false },
        subNamesEngClass5SecC: { error: false },
        subNamesEngClass5SecD: { error: false },
        subNamesEngClass5SecE: { error: false },
        subNamesMarClass5SecA: { error: false },
        subNamesMarClass5SecB: { error: false },
        subNamesMarClass5SecC: { error: false },
        subNamesMarClass5SecD: { error: false },
        subNamesMarClass5SecE: { error: false },
        subNamesEngClass6SecA: { error: false },
        subNamesEngClass6SecB: { error: false },
        subNamesEngClass6SecC: { error: false },
        subNamesEngClass6SecD: { error: false },
        subNamesEngClass6SecE: { error: false },
        subNamesMarClass6SecA: { error: false },
        subNamesMarClass6SecB: { error: false },
        subNamesMarClass6SecC: { error: false },
        subNamesMarClass6SecD: { error: false },
        subNamesMarClass6SecE: { error: false },
        subNamesEngClass7SecA: { error: false },
        subNamesEngClass7SecB: { error: false },
        subNamesEngClass7SecC: { error: false },
        subNamesEngClass7SecD: { error: false },
        subNamesEngClass7SecE: { error: false },
        subNamesMarClass7SecA: { error: false },
        subNamesMarClass7SecB: { error: false },
        subNamesMarClass7SecC: { error: false },
        subNamesMarClass7SecD: { error: false },
        subNamesMarClass7SecE: { error: false },
        subNamesEngClass8SecA: { error: false },
        subNamesEngClass8SecB: { error: false },
        subNamesEngClass8SecC: { error: false },
        subNamesEngClass8SecD: { error: false },
        subNamesEngClass8SecE: { error: false },
        subNamesMarClass8SecA: { error: false },
        subNamesMarClass8SecB: { error: false },
        subNamesMarClass8SecC: { error: false },
        subNamesMarClass8SecD: { error: false },
        subNamesMarClass8SecE: { error: false },
        subNamesEngClass9SecA: { error: false },
        subNamesEngClass9SecB: { error: false },
        subNamesEngClass9SecC: { error: false },
        subNamesEngClass9SecD: { error: false },
        subNamesEngClass9SecE: { error: false },
        subNamesMarClass9SecA: { error: false },
        subNamesMarClass9SecB: { error: false },
        subNamesMarClass9SecC: { error: false },
        subNamesMarClass9SecD: { error: false },
        subNamesMarClass9SecE: { error: false },
        subNamesEngClass10SecA: { error: false },
        subNamesEngClass10SecB: { error: false },
        subNamesEngClass10SecC: { error: false },
        subNamesEngClass10SecD: { error: false },
        subNamesEngClass10SecE: { error: false },
        subNamesMarClass10SecA: { error: false },
        subNamesMarClass10SecB: { error: false },
        subNamesMarClass10SecC: { error: false },
        subNamesMarClass10SecD: { error: false },
        subNamesMarClass10SecE: { error: false },
        subNamesMarClass11ArtsSecA: { error: false },
        subNamesMarClass11ArtsSecB: { error: false },
        subNamesMarClass11ArtsSecC: { error: false },
        subNamesMarClass11ArtsSecD: { error: false },
        subNamesMarClass11ArtsSecE: { error: false },
        subNamesEngClass11CommerceSecA: { error: false },
        subNamesEngClass11CommerceSecB: { error: false },
        subNamesEngClass11CommerceSecC: { error: false },
        subNamesEngClass11CommerceSecD: { error: false },
        subNamesEngClass11CommerceSecE: { error: false },
        subNamesMarClass11CommerceSecA: { error: false },
        subNamesMarClass11CommerceSecB: { error: false },
        subNamesMarClass11CommerceSecC: { error: false },
        subNamesMarClass11CommerceSecD: { error: false },
        subNamesMarClass11CommerceSecE: { error: false },
        subNamesEngClass11ScienceSecA: { error: false },
        subNamesEngClass11ScienceSecB: { error: false },
        subNamesEngClass11ScienceSecC: { error: false },
        subNamesEngClass11ScienceSecD: { error: false },
        subNamesEngClass11ScienceSecE: { error: false },
        subNamesMarClass12ArtsSecA: { error: false },
        subNamesMarClass12ArtsSecB: { error: false },
        subNamesMarClass12ArtsSecC: { error: false },
        subNamesMarClass12ArtsSecD: { error: false },
        subNamesMarClass12ArtsSecE: { error: false },
        subNamesEngClass12CommerceSecA: { error: false },
        subNamesEngClass12CommerceSecB: { error: false },
        subNamesEngClass12CommerceSecC: { error: false },
        subNamesEngClass12CommerceSecD: { error: false },
        subNamesEngClass12CommerceSecE: { error: false },
        subNamesMarClass12CommerceSecA: { error: false },
        subNamesMarClass12CommerceSecB: { error: false },
        subNamesMarClass12CommerceSecC: { error: false },
        subNamesMarClass12CommerceSecD: { error: false },
        subNamesMarClass12CommerceSecE: { error: false },
        subNamesEngClass12ScienceSecA: { error: false },
        subNamesEngClass12ScienceSecB: { error: false },
        subNamesEngClass12ScienceSecC: { error: false },
        subNamesEngClass12ScienceSecD: { error: false },
        subNamesEngClass12ScienceSecE: { error: false },
    })

    const [errorDisplay, seterrorDisplay] = useState('')
    const [errorArray, setErrorArray] = useState([]);

    const [showBlock, setshowBlock] = useState({
        showClsSubBlock: true, showVisMisBlock: false
    })

    const [butDisabled, setbutDisabled] = useState(false)

    const addDataToDatabase = async (e) => {
        e.preventDefault()
        setbutDisabled(true)
        if (schoolClassData.length === 0) {
            seterrorDisplay('show')
        } else {
            seterrorDisplay('')
            if (schoolClassData.includes('EngPreNurserySecA')) {
                if (subjectsOfClass.subNamesEngPreNurserySecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngPreNurserySecA: { error: true } } })
                    errorArray.push('EngPreNurserySecA');
                } else {
                    const e = errorArray.indexOf('EngPreNurserySecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngPreNurserySecB')) {
                if (subjectsOfClass.subNamesEngPreNurserySecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngPreNurserySecB: { error: true } } })
                    errorArray.push('EngPreNurserySecB');
                } else {
                    const e = errorArray.indexOf('EngPreNurserySecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngPreNurserySecC')) {
                if (subjectsOfClass.subNamesEngPreNurserySecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngPreNurserySecC: { error: true } } })
                    errorArray.push('EngPreNurserySecC');
                } else {
                    const e = errorArray.indexOf('EngPreNurserySecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngPreNurserySecD')) {
                if (subjectsOfClass.subNamesEngPreNurserySecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngPreNurserySecD: { error: true } } })
                    errorArray.push('EngPreNurserySecD');
                } else {
                    const e = errorArray.indexOf('EngPreNurserySecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngPreNurserySecE')) {
                if (subjectsOfClass.subNamesEngPreNurserySecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngPreNurserySecE: { error: true } } })
                    errorArray.push('EngPreNurserySecE');
                } else {
                    const e = errorArray.indexOf('EngPreNurserySecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarPreNurserySecA')) {
                if (subjectsOfClass.subNamesMarPreNurserySecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarPreNurserySecA: { error: true } } })
                    errorArray.push('MarPreNurserySecA');
                } else {
                    const e = errorArray.indexOf('MarPreNurserySecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarPreNurserySecB')) {
                if (subjectsOfClass.subNamesMarPreNurserySecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarPreNurserySecB: { error: true } } })
                    errorArray.push('MarPreNurserySecB');
                } else {
                    const e = errorArray.indexOf('MarPreNurserySecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarPreNurserySecC')) {
                if (subjectsOfClass.subNamesMarPreNurserySecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarPreNurserySecC: { error: true } } })
                    errorArray.push('MarPreNurserySecC');
                } else {
                    const e = errorArray.indexOf('MarPreNurserySecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarPreNurserySecD')) {
                if (subjectsOfClass.subNamesMarPreNurserySecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarPreNurserySecD: { error: true } } })
                    errorArray.push('MarPreNurserySecD');
                } else {
                    const e = errorArray.indexOf('MarPreNurserySecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarPreNurserySecE')) {
                if (subjectsOfClass.subNamesMarPreNurserySecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarPreNurserySecE: { error: true } } })
                    errorArray.push('MarPreNurserySecE');
                } else {
                    const e = errorArray.indexOf('MarPreNurserySecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (schoolClassData.includes('EngNurserySecA')) {
                if (subjectsOfClass.subNamesEngNurserySecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngNurserySecA: { error: true } } })
                    errorArray.push('EngNurserySecA');
                } else {
                    const e = errorArray.indexOf('EngNurserySecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngNurserySecB')) {
                if (subjectsOfClass.subNamesEngNurserySecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngNurserySecB: { error: true } } })
                    errorArray.push('EngNurserySecB');
                } else {
                    const e = errorArray.indexOf('EngNurserySecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngNurserySecC')) {
                if (subjectsOfClass.subNamesEngNurserySecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngNurserySecC: { error: true } } })
                    errorArray.push('EngNurserySecC');
                } else {
                    const e = errorArray.indexOf('EngNurserySecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngNurserySecD')) {
                if (subjectsOfClass.subNamesEngNurserySecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngNurserySecD: { error: true } } })
                    errorArray.push('EngNurserySecD');
                } else {
                    const e = errorArray.indexOf('EngNurserySecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngNurserySecE')) {
                if (subjectsOfClass.subNamesEngNurserySecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngNurserySecE: { error: true } } })
                    errorArray.push('EngNurserySecE');
                } else {
                    const e = errorArray.indexOf('EngNurserySecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarNurserySecA')) {
                if (subjectsOfClass.subNamesMarNurserySecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarNurserySecA: { error: true } } })
                    errorArray.push('MarNurserySecA');
                } else {
                    const e = errorArray.indexOf('MarNurserySecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarNurserySecB')) {
                if (subjectsOfClass.subNamesMarNurserySecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarNurserySecB: { error: true } } })
                    errorArray.push('MarNurserySecB');
                } else {
                    const e = errorArray.indexOf('MarNurserySecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarNurserySecC')) {
                if (subjectsOfClass.subNamesMarNurserySecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarNurserySecC: { error: true } } })
                    errorArray.push('MarNurserySecC');
                } else {
                    const e = errorArray.indexOf('MarNurserySecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarNurserySecD')) {
                if (subjectsOfClass.subNamesMarNurserySecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarNurserySecD: { error: true } } })
                    errorArray.push('MarNurserySecD');
                } else {
                    const e = errorArray.indexOf('MarNurserySecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarNurserySecE')) {
                if (subjectsOfClass.subNamesMarNurserySecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarNurserySecE: { error: true } } })
                    errorArray.push('MarNurserySecE');
                } else {
                    const e = errorArray.indexOf('MarNurserySecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (schoolClassData.includes('EngKG1SecA')) {
                if (subjectsOfClass.subNamesEngKG1SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngKG1SecA: { error: true } } })
                    errorArray.push('EngKG1SecA');
                } else {
                    const e = errorArray.indexOf('EngKG1SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngKG1SecB')) {
                if (subjectsOfClass.subNamesEngKG1SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngKG1SecB: { error: true } } })
                    errorArray.push('EngKG1SecB');
                } else {
                    const e = errorArray.indexOf('EngKG1SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngKG1SecC')) {
                if (subjectsOfClass.subNamesEngKG1SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngKG1SecC: { error: true } } })
                    errorArray.push('EngKG1SecC');
                } else {
                    const e = errorArray.indexOf('EngKG1SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngKG1SecD')) {
                if (subjectsOfClass.subNamesEngKG1SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngKG1SecD: { error: true } } })
                    errorArray.push('EngKG1SecD');
                } else {
                    const e = errorArray.indexOf('EngKG1SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngKG1SecE')) {
                if (subjectsOfClass.subNamesEngKG1SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngKG1SecE: { error: true } } })
                    errorArray.push('EngKG1SecE');
                } else {
                    const e = errorArray.indexOf('EngKG1SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarKG1SecA')) {
                if (subjectsOfClass.subNamesMarKG1SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarKG1SecA: { error: true } } })
                    errorArray.push('MarKG1SecA');
                } else {
                    const e = errorArray.indexOf('MarKG1SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarKG1SecB')) {
                if (subjectsOfClass.subNamesMarKG1SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarKG1SecB: { error: true } } })
                    errorArray.push('MarKG1SecB');
                } else {
                    const e = errorArray.indexOf('MarKG1SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarKG1SecC')) {
                if (subjectsOfClass.subNamesMarKG1SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarKG1SecC: { error: true } } })
                    errorArray.push('MarKG1SecC');
                } else {
                    const e = errorArray.indexOf('MarKG1SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarKG1SecD')) {
                if (subjectsOfClass.subNamesMarKG1SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarKG1SecD: { error: true } } })
                    errorArray.push('MarKG1SecD');
                } else {
                    const e = errorArray.indexOf('MarKG1SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarKG1SecE')) {
                if (subjectsOfClass.subNamesMarKG1SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarKG1SecE: { error: true } } })
                    errorArray.push('MarKG1SecE');
                } else {
                    const e = errorArray.indexOf('MarKG1SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (schoolClassData.includes('EngKG2SecA')) {
                if (subjectsOfClass.subNamesEngKG2SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngKG2SecA: { error: true } } })
                    errorArray.push('EngKG2SecA');
                } else {
                    const e = errorArray.indexOf('EngKG2SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngKG2SecB')) {
                if (subjectsOfClass.subNamesEngKG2SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngKG2SecB: { error: true } } })
                    errorArray.push('EngKG2SecB');
                } else {
                    const e = errorArray.indexOf('EngKG2SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngKG2SecC')) {
                if (subjectsOfClass.subNamesEngKG2SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngKG2SecC: { error: true } } })
                    errorArray.push('EngKG2SecC');
                } else {
                    const e = errorArray.indexOf('EngKG2SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngKG2SecD')) {
                if (subjectsOfClass.subNamesEngKG2SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngKG2SecD: { error: true } } })
                    errorArray.push('EngKG2SecD');
                } else {
                    const e = errorArray.indexOf('EngKG2SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngKG2SecE')) {
                if (subjectsOfClass.subNamesEngKG2SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngKG2SecE: { error: true } } })
                    errorArray.push('EngKG2SecE');
                } else {
                    const e = errorArray.indexOf('EngKG2SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarKG2SecA')) {
                if (subjectsOfClass.subNamesMarKG2SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarKG2SecA: { error: true } } })
                    errorArray.push('MarKG2SecA');
                } else {
                    const e = errorArray.indexOf('MarKG2SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarKG2SecB')) {
                if (subjectsOfClass.subNamesMarKG2SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarKG2SecB: { error: true } } })
                    errorArray.push('MarKG2SecB');
                } else {
                    const e = errorArray.indexOf('MarKG2SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarKG2SecC')) {
                if (subjectsOfClass.subNamesMarKG2SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarKG2SecC: { error: true } } })
                    errorArray.push('MarKG2SecC');
                } else {
                    const e = errorArray.indexOf('MarKG2SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarKG2SecD')) {
                if (subjectsOfClass.subNamesMarKG2SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarKG2SecD: { error: true } } })
                    errorArray.push('MarKG2SecD');
                } else {
                    const e = errorArray.indexOf('MarKG2SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarKG2SecE')) {
                if (subjectsOfClass.subNamesMarKG2SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarKG2SecE: { error: true } } })
                    errorArray.push('MarKG2SecE');
                } else {
                    const e = errorArray.indexOf('MarKG2SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (schoolClassData.includes('EngClass1SecA')) {
                if (subjectsOfClass.subNamesEngClass1SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass1SecA: { error: true } } })
                    errorArray.push('EngClass1SecA');
                } else {
                    const e = errorArray.indexOf('EngClass1SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass1SecB')) {
                if (subjectsOfClass.subNamesEngClass1SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass1SecB: { error: true } } })
                    errorArray.push('EngClass1SecB');
                } else {
                    const e = errorArray.indexOf('EngClass1SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass1SecC')) {
                if (subjectsOfClass.subNamesEngClass1SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass1SecC: { error: true } } })
                    errorArray.push('EngClass1SecC');
                } else {
                    const e = errorArray.indexOf('EngClass1SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass1SecD')) {
                if (subjectsOfClass.subNamesEngClass1SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass1SecD: { error: true } } })
                    errorArray.push('EngClass1SecD');
                } else {
                    const e = errorArray.indexOf('EngClass1SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass1SecE')) {
                if (subjectsOfClass.subNamesEngClass1SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass1SecE: { error: true } } })
                    errorArray.push('EngClass1SecE');
                } else {
                    const e = errorArray.indexOf('EngClass1SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass1SecA')) {
                if (subjectsOfClass.subNamesMarClass1SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass1SecA: { error: true } } })
                    errorArray.push('MarClass1SecA');
                } else {
                    const e = errorArray.indexOf('MarClass1SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass1SecB')) {
                if (subjectsOfClass.subNamesMarClass1SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass1SecB: { error: true } } })
                    errorArray.push('MarClass1SecB');
                } else {
                    const e = errorArray.indexOf('MarClass1SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass1SecC')) {
                if (subjectsOfClass.subNamesMarClass1SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass1SecC: { error: true } } })
                    errorArray.push('MarClass1SecC');
                } else {
                    const e = errorArray.indexOf('MarClass1SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass1SecD')) {
                if (subjectsOfClass.subNamesMarClass1SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass1SecD: { error: true } } })
                    errorArray.push('MarClass1SecD');
                } else {
                    const e = errorArray.indexOf('MarClass1SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass1SecE')) {
                if (subjectsOfClass.subNamesMarClass1SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass1SecE: { error: true } } })
                    errorArray.push('MarClass1SecE');
                } else {
                    const e = errorArray.indexOf('MarClass1SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (schoolClassData.includes('EngClass2SecA')) {
                if (subjectsOfClass.subNamesEngClass2SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass2SecA: { error: true } } })
                    errorArray.push('EngClass2SecA');
                } else {
                    const e = errorArray.indexOf('EngClass2SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass2SecB')) {
                if (subjectsOfClass.subNamesEngClass2SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass2SecB: { error: true } } })
                    errorArray.push('EngClass2SecB');
                } else {
                    const e = errorArray.indexOf('EngClass2SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass2SecC')) {
                if (subjectsOfClass.subNamesEngClass2SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass2SecC: { error: true } } })
                    errorArray.push('EngClass2SecC');
                } else {
                    const e = errorArray.indexOf('EngClass2SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass2SecD')) {
                if (subjectsOfClass.subNamesEngClass2SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass2SecD: { error: true } } })
                    errorArray.push('EngClass2SecD');
                } else {
                    const e = errorArray.indexOf('EngClass2SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass2SecE')) {
                if (subjectsOfClass.subNamesEngClass2SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass2SecE: { error: true } } })
                    errorArray.push('EngClass2SecE');
                } else {
                    const e = errorArray.indexOf('EngClass2SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass2SecA')) {
                if (subjectsOfClass.subNamesMarClass2SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass2SecA: { error: true } } })
                    errorArray.push('MarClass2SecA');
                } else {
                    const e = errorArray.indexOf('MarClass2SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass2SecB')) {
                if (subjectsOfClass.subNamesMarClass2SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass2SecB: { error: true } } })
                    errorArray.push('MarClass2SecB');
                } else {
                    const e = errorArray.indexOf('MarClass2SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass2SecC')) {
                if (subjectsOfClass.subNamesMarClass2SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass2SecC: { error: true } } })
                    errorArray.push('MarClass2SecC');
                } else {
                    const e = errorArray.indexOf('MarClass2SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass2SecD')) {
                if (subjectsOfClass.subNamesMarClass2SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass2SecD: { error: true } } })
                    errorArray.push('MarClass2SecD');
                } else {
                    const e = errorArray.indexOf('MarClass2SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass2SecE')) {
                if (subjectsOfClass.subNamesMarClass2SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass2SecE: { error: true } } })
                    errorArray.push('MarClass2SecE');
                } else {
                    const e = errorArray.indexOf('MarClass2SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (schoolClassData.includes('EngClass3SecA')) {
                if (subjectsOfClass.subNamesEngClass3SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass3SecA: { error: true } } })
                    errorArray.push('EngClass3SecA');
                } else {
                    const e = errorArray.indexOf('EngClass3SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass3SecB')) {
                if (subjectsOfClass.subNamesEngClass3SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass3SecB: { error: true } } })
                    errorArray.push('EngClass3SecB');
                } else {
                    const e = errorArray.indexOf('EngClass3SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass3SecC')) {
                if (subjectsOfClass.subNamesEngClass3SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass3SecC: { error: true } } })
                    errorArray.push('EngClass3SecC');
                } else {
                    const e = errorArray.indexOf('EngClass3SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass3SecD')) {
                if (subjectsOfClass.subNamesEngClass3SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass3SecD: { error: true } } })
                    errorArray.push('EngClass3SecD');
                } else {
                    const e = errorArray.indexOf('EngClass3SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass3SecE')) {
                if (subjectsOfClass.subNamesEngClass3SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass3SecE: { error: true } } })
                    errorArray.push('EngClass3SecE');
                } else {
                    const e = errorArray.indexOf('EngClass3SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass3SecA')) {
                if (subjectsOfClass.subNamesMarClass3SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass3SecA: { error: true } } })
                    errorArray.push('MarClass3SecA');
                } else {
                    const e = errorArray.indexOf('MarClass3SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass3SecB')) {
                if (subjectsOfClass.subNamesMarClass3SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass3SecB: { error: true } } })
                    errorArray.push('MarClass3SecB');
                } else {
                    const e = errorArray.indexOf('MarClass3SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass3SecC')) {
                if (subjectsOfClass.subNamesMarClass3SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass3SecC: { error: true } } })
                    errorArray.push('MarClass3SecC');
                } else {
                    const e = errorArray.indexOf('MarClass3SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass3SecD')) {
                if (subjectsOfClass.subNamesMarClass3SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass3SecD: { error: true } } })
                    errorArray.push('MarClass3SecD');
                } else {
                    const e = errorArray.indexOf('MarClass3SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass3SecE')) {
                if (subjectsOfClass.subNamesMarClass3SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass3SecE: { error: true } } })
                    errorArray.push('MarClass3SecE');
                } else {
                    const e = errorArray.indexOf('MarClass3SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (schoolClassData.includes('EngClass4SecA')) {
                if (subjectsOfClass.subNamesEngClass4SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass4SecA: { error: true } } })
                    errorArray.push('EngClass4SecA');
                } else {
                    const e = errorArray.indexOf('EngClass4SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass4SecB')) {
                if (subjectsOfClass.subNamesEngClass4SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass4SecB: { error: true } } })
                    errorArray.push('EngClass4SecB');
                } else {
                    const e = errorArray.indexOf('EngClass4SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass4SecC')) {
                if (subjectsOfClass.subNamesEngClass4SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass4SecC: { error: true } } })
                    errorArray.push('EngClass4SecC');
                } else {
                    const e = errorArray.indexOf('EngClass4SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass4SecD')) {
                if (subjectsOfClass.subNamesEngClass4SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass4SecD: { error: true } } })
                    errorArray.push('EngClass4SecD');
                } else {
                    const e = errorArray.indexOf('EngClass4SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass4SecE')) {
                if (subjectsOfClass.subNamesEngClass4SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass4SecE: { error: true } } })
                    errorArray.push('EngClass4SecE');
                } else {
                    const e = errorArray.indexOf('EngClass4SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass4SecA')) {
                if (subjectsOfClass.subNamesMarClass4SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass4SecA: { error: true } } })
                    errorArray.push('MarClass4SecA');
                } else {
                    const e = errorArray.indexOf('MarClass4SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass4SecB')) {
                if (subjectsOfClass.subNamesMarClass4SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass4SecB: { error: true } } })
                    errorArray.push('MarClass4SecB');
                } else {
                    const e = errorArray.indexOf('MarClass4SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass4SecC')) {
                if (subjectsOfClass.subNamesMarClass4SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass4SecC: { error: true } } })
                    errorArray.push('MarClass4SecC');
                } else {
                    const e = errorArray.indexOf('MarClass4SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass4SecD')) {
                if (subjectsOfClass.subNamesMarClass4SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass4SecD: { error: true } } })
                    errorArray.push('MarClass4SecD');
                } else {
                    const e = errorArray.indexOf('MarClass4SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass4SecE')) {
                if (subjectsOfClass.subNamesMarClass4SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass4SecE: { error: true } } })
                    errorArray.push('MarClass4SecE');
                } else {
                    const e = errorArray.indexOf('MarClass4SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (schoolClassData.includes('EngClass5SecA')) {
                if (subjectsOfClass.subNamesEngClass5SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass5SecA: { error: true } } })
                    errorArray.push('EngClass5SecA');
                } else {
                    const e = errorArray.indexOf('EngClass5SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass5SecB')) {
                if (subjectsOfClass.subNamesEngClass5SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass5SecB: { error: true } } })
                    errorArray.push('EngClass5SecB');
                } else {
                    const e = errorArray.indexOf('EngClass5SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass5SecC')) {
                if (subjectsOfClass.subNamesEngClass5SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass5SecC: { error: true } } })
                    errorArray.push('EngClass5SecC');
                } else {
                    const e = errorArray.indexOf('EngClass5SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass5SecD')) {
                if (subjectsOfClass.subNamesEngClass5SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass5SecD: { error: true } } })
                    errorArray.push('EngClass5SecD');
                } else {
                    const e = errorArray.indexOf('EngClass5SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass5SecE')) {
                if (subjectsOfClass.subNamesEngClass5SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass5SecE: { error: true } } })
                    errorArray.push('EngClass5SecE');
                } else {
                    const e = errorArray.indexOf('EngClass5SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass5SecA')) {
                if (subjectsOfClass.subNamesMarClass5SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass5SecA: { error: true } } })
                    errorArray.push('MarClass5SecA');
                } else {
                    const e = errorArray.indexOf('MarClass5SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass5SecB')) {
                if (subjectsOfClass.subNamesMarClass5SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass5SecB: { error: true } } })
                    errorArray.push('MarClass5SecB');
                } else {
                    const e = errorArray.indexOf('MarClass5SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass5SecC')) {
                if (subjectsOfClass.subNamesMarClass5SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass5SecC: { error: true } } })
                    errorArray.push('MarClass5SecC');
                } else {
                    const e = errorArray.indexOf('MarClass5SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass5SecD')) {
                if (subjectsOfClass.subNamesMarClass5SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass5SecD: { error: true } } })
                    errorArray.push('MarClass5SecD');
                } else {
                    const e = errorArray.indexOf('MarClass5SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass5SecE')) {
                if (subjectsOfClass.subNamesMarClass5SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass5SecE: { error: true } } })
                    errorArray.push('MarClass5SecE');
                } else {
                    const e = errorArray.indexOf('MarClass5SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (schoolClassData.includes('EngClass6SecA')) {
                if (subjectsOfClass.subNamesEngClass6SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass6SecA: { error: true } } })
                    errorArray.push('EngClass6SecA');
                } else {
                    const e = errorArray.indexOf('EngClass6SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass6SecB')) {
                if (subjectsOfClass.subNamesEngClass6SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass6SecB: { error: true } } })
                    errorArray.push('EngClass6SecB');
                } else {
                    const e = errorArray.indexOf('EngClass6SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass6SecC')) {
                if (subjectsOfClass.subNamesEngClass6SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass6SecC: { error: true } } })
                    errorArray.push('EngClass6SecC');
                } else {
                    const e = errorArray.indexOf('EngClass6SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass6SecD')) {
                if (subjectsOfClass.subNamesEngClass6SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass6SecD: { error: true } } })
                    errorArray.push('EngClass6SecD');
                } else {
                    const e = errorArray.indexOf('EngClass6SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass6SecE')) {
                if (subjectsOfClass.subNamesEngClass6SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass6SecE: { error: true } } })
                    errorArray.push('EngClass6SecE');
                } else {
                    const e = errorArray.indexOf('EngClass6SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass6SecA')) {
                if (subjectsOfClass.subNamesMarClass6SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass6SecA: { error: true } } })
                    errorArray.push('MarClass6SecA');
                } else {
                    const e = errorArray.indexOf('MarClass6SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass6SecB')) {
                if (subjectsOfClass.subNamesMarClass6SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass6SecB: { error: true } } })
                    errorArray.push('MarClass6SecB');
                } else {
                    const e = errorArray.indexOf('MarClass6SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass6SecC')) {
                if (subjectsOfClass.subNamesMarClass6SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass6SecC: { error: true } } })
                    errorArray.push('MarClass6SecC');
                } else {
                    const e = errorArray.indexOf('MarClass6SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass6SecD')) {
                if (subjectsOfClass.subNamesMarClass6SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass6SecD: { error: true } } })
                    errorArray.push('MarClass6SecD');
                } else {
                    const e = errorArray.indexOf('MarClass6SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass6SecE')) {
                if (subjectsOfClass.subNamesMarClass6SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass6SecE: { error: true } } })
                    errorArray.push('MarClass6SecE');
                } else {
                    const e = errorArray.indexOf('MarClass6SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (schoolClassData.includes('EngClass7SecA')) {
                if (subjectsOfClass.subNamesEngClass7SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass7SecA: { error: true } } })
                    errorArray.push('EngClass7SecA');
                } else {
                    const e = errorArray.indexOf('EngClass7SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass7SecB')) {
                if (subjectsOfClass.subNamesEngClass7SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass7SecB: { error: true } } })
                    errorArray.push('EngClass7SecB');
                } else {
                    const e = errorArray.indexOf('EngClass7SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass7SecC')) {
                if (subjectsOfClass.subNamesEngClass7SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass7SecC: { error: true } } })
                    errorArray.push('EngClass7SecC');
                } else {
                    const e = errorArray.indexOf('EngClass7SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass7SecD')) {
                if (subjectsOfClass.subNamesEngClass7SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass7SecD: { error: true } } })
                    errorArray.push('EngClass7SecD');
                } else {
                    const e = errorArray.indexOf('EngClass7SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass7SecE')) {
                if (subjectsOfClass.subNamesEngClass7SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass7SecE: { error: true } } })
                    errorArray.push('EngClass7SecE');
                } else {
                    const e = errorArray.indexOf('EngClass7SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass7SecA')) {
                if (subjectsOfClass.subNamesMarClass7SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass7SecA: { error: true } } })
                    errorArray.push('MarClass7SecA');
                } else {
                    const e = errorArray.indexOf('MarClass7SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass7SecB')) {
                if (subjectsOfClass.subNamesMarClass7SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass7SecB: { error: true } } })
                    errorArray.push('MarClass7SecB');
                } else {
                    const e = errorArray.indexOf('MarClass7SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass7SecC')) {
                if (subjectsOfClass.subNamesMarClass7SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass7SecC: { error: true } } })
                    errorArray.push('MarClass7SecC');
                } else {
                    const e = errorArray.indexOf('MarClass7SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass7SecD')) {
                if (subjectsOfClass.subNamesMarClass7SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass7SecD: { error: true } } })
                    errorArray.push('MarClass7SecD');
                } else {
                    const e = errorArray.indexOf('MarClass7SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass7SecE')) {
                if (subjectsOfClass.subNamesMarClass7SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass7SecE: { error: true } } })
                    errorArray.push('MarClass7SecE');
                } else {
                    const e = errorArray.indexOf('MarClass7SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (schoolClassData.includes('EngClass8SecA')) {
                if (subjectsOfClass.subNamesEngClass8SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass8SecA: { error: true } } })
                    errorArray.push('EngClass8SecA');
                } else {
                    const e = errorArray.indexOf('EngClass8SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass8SecB')) {
                if (subjectsOfClass.subNamesEngClass8SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass8SecB: { error: true } } })
                    errorArray.push('EngClass8SecB');
                } else {
                    const e = errorArray.indexOf('EngClass8SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass8SecC')) {
                if (subjectsOfClass.subNamesEngClass8SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass8SecC: { error: true } } })
                    errorArray.push('EngClass8SecC');
                } else {
                    const e = errorArray.indexOf('EngClass8SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass8SecD')) {
                if (subjectsOfClass.subNamesEngClass8SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass8SecD: { error: true } } })
                    errorArray.push('EngClass8SecD');
                } else {
                    const e = errorArray.indexOf('EngClass8SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass8SecE')) {
                if (subjectsOfClass.subNamesEngClass8SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass8SecE: { error: true } } })
                    errorArray.push('EngClass8SecE');
                } else {
                    const e = errorArray.indexOf('EngClass8SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass8SecA')) {
                if (subjectsOfClass.subNamesMarClass8SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass8SecA: { error: true } } })
                    errorArray.push('MarClass8SecA');
                } else {
                    const e = errorArray.indexOf('MarClass8SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass8SecB')) {
                if (subjectsOfClass.subNamesMarClass8SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass8SecB: { error: true } } })
                    errorArray.push('MarClass8SecB');
                } else {
                    const e = errorArray.indexOf('MarClass8SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass8SecC')) {
                if (subjectsOfClass.subNamesMarClass8SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass8SecC: { error: true } } })
                    errorArray.push('MarClass8SecC');
                } else {
                    const e = errorArray.indexOf('MarClass8SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass8SecD')) {
                if (subjectsOfClass.subNamesMarClass8SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass8SecD: { error: true } } })
                    errorArray.push('MarClass8SecD');
                } else {
                    const e = errorArray.indexOf('MarClass8SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass8SecE')) {
                if (subjectsOfClass.subNamesMarClass8SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass8SecE: { error: true } } })
                    errorArray.push('MarClass8SecE');
                } else {
                    const e = errorArray.indexOf('MarClass8SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (schoolClassData.includes('EngClass9SecA')) {
                if (subjectsOfClass.subNamesEngClass9SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass9SecA: { error: true } } })
                    errorArray.push('EngClass9SecA');
                } else {
                    const e = errorArray.indexOf('EngClass9SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass9SecB')) {
                if (subjectsOfClass.subNamesEngClass9SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass9SecB: { error: true } } })
                    errorArray.push('EngClass9SecB');
                } else {
                    const e = errorArray.indexOf('EngClass9SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass9SecC')) {
                if (subjectsOfClass.subNamesEngClass9SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass9SecC: { error: true } } })
                    errorArray.push('EngClass9SecC');
                } else {
                    const e = errorArray.indexOf('EngClass9SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass9SecD')) {
                if (subjectsOfClass.subNamesEngClass9SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass9SecD: { error: true } } })
                    errorArray.push('EngClass9SecD');
                } else {
                    const e = errorArray.indexOf('EngClass9SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass9SecE')) {
                if (subjectsOfClass.subNamesEngClass9SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass9SecE: { error: true } } })
                    errorArray.push('EngClass9SecE');
                } else {
                    const e = errorArray.indexOf('EngClass9SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass9SecA')) {
                if (subjectsOfClass.subNamesMarClass9SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass9SecA: { error: true } } })
                    errorArray.push('MarClass9SecA');
                } else {
                    const e = errorArray.indexOf('MarClass9SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass9SecB')) {
                if (subjectsOfClass.subNamesMarClass9SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass9SecB: { error: true } } })
                    errorArray.push('MarClass9SecB');
                } else {
                    const e = errorArray.indexOf('MarClass9SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass9SecC')) {
                if (subjectsOfClass.subNamesMarClass9SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass9SecC: { error: true } } })
                    errorArray.push('MarClass9SecC');
                } else {
                    const e = errorArray.indexOf('MarClass9SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass9SecD')) {
                if (subjectsOfClass.subNamesMarClass9SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass9SecD: { error: true } } })
                    errorArray.push('MarClass9SecD');
                } else {
                    const e = errorArray.indexOf('MarClass9SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass9SecE')) {
                if (subjectsOfClass.subNamesMarClass9SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass9SecE: { error: true } } })
                    errorArray.push('MarClass9SecE');
                } else {
                    const e = errorArray.indexOf('MarClass9SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (schoolClassData.includes('EngClass10SecA')) {
                if (subjectsOfClass.subNamesEngClass10SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass10SecA: { error: true } } })
                    errorArray.push('EngClass10SecA');
                } else {
                    const e = errorArray.indexOf('EngClass10SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass10SecB')) {
                if (subjectsOfClass.subNamesEngClass10SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass10SecB: { error: true } } })
                    errorArray.push('EngClass10SecB');
                } else {
                    const e = errorArray.indexOf('EngClass10SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass10SecC')) {
                if (subjectsOfClass.subNamesEngClass10SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass10SecC: { error: true } } })
                    errorArray.push('EngClass10SecC');
                } else {
                    const e = errorArray.indexOf('EngClass10SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass10SecD')) {
                if (subjectsOfClass.subNamesEngClass10SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass10SecD: { error: true } } })
                    errorArray.push('EngClass10SecD');
                } else {
                    const e = errorArray.indexOf('EngClass10SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass10SecE')) {
                if (subjectsOfClass.subNamesEngClass10SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass10SecE: { error: true } } })
                    errorArray.push('EngClass10SecE');
                } else {
                    const e = errorArray.indexOf('EngClass10SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass10SecA')) {
                if (subjectsOfClass.subNamesMarClass10SecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass10SecA: { error: true } } })
                    errorArray.push('MarClass10SecA');
                } else {
                    const e = errorArray.indexOf('MarClass10SecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass10SecB')) {
                if (subjectsOfClass.subNamesMarClass10SecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass10SecB: { error: true } } })
                    errorArray.push('MarClass10SecB');
                } else {
                    const e = errorArray.indexOf('MarClass10SecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass10SecC')) {
                if (subjectsOfClass.subNamesMarClass10SecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass10SecC: { error: true } } })
                    errorArray.push('MarClass10SecC');
                } else {
                    const e = errorArray.indexOf('MarClass10SecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass10SecD')) {
                if (subjectsOfClass.subNamesMarClass10SecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass10SecD: { error: true } } })
                    errorArray.push('MarClass10SecD');
                } else {
                    const e = errorArray.indexOf('MarClass10SecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass10SecE')) {
                if (subjectsOfClass.subNamesMarClass10SecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass10SecE: { error: true } } })
                    errorArray.push('MarClass10SecE');
                } else {
                    const e = errorArray.indexOf('MarClass10SecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (schoolClassData.includes('MarClass11ArtsSecA')) {
                if (subjectsOfClass.subNamesMarClass11ArtsSecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass11ArtsSecA: { error: true } } })
                    errorArray.push('MarClass11ArtsSecA');
                } else {
                    const e = errorArray.indexOf('MarClass11ArtsSecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass11ArtsSecB')) {
                if (subjectsOfClass.subNamesMarClass11ArtsSecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass11ArtsSecB: { error: true } } })
                    errorArray.push('MarClass11ArtsSecB');
                } else {
                    const e = errorArray.indexOf('MarClass11ArtsSecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass11ArtsSecC')) {
                if (subjectsOfClass.subNamesMarClass11ArtsSecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass11ArtsSecC: { error: true } } })
                    errorArray.push('MarClass11ArtsSecC');
                } else {
                    const e = errorArray.indexOf('MarClass11ArtsSecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass11ArtsSecD')) {
                if (subjectsOfClass.subNamesMarClass11ArtsSecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass11ArtsSecD: { error: true } } })
                    errorArray.push('MarClass11ArtsSecD');
                } else {
                    const e = errorArray.indexOf('MarClass11ArtsSecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass11ArtsSecE')) {
                if (subjectsOfClass.subNamesMarClass11ArtsSecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass11ArtsSecE: { error: true } } })
                    errorArray.push('MarClass11ArtsSecE');
                } else {
                    const e = errorArray.indexOf('MarClass11ArtsSecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (schoolClassData.includes('EngClass11CommerceSecA')) {
                if (subjectsOfClass.subNamesEngClass11CommerceSecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass11CommerceSecA: { error: true } } })
                    errorArray.push('EngClass11CommerceSecA');
                } else {
                    const e = errorArray.indexOf('EngClass11CommerceSecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass11CommerceSecB')) {
                if (subjectsOfClass.subNamesEngClass11CommerceSecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass11CommerceSecB: { error: true } } })
                    errorArray.push('EngClass11CommerceSecB');
                } else {
                    const e = errorArray.indexOf('EngClass11CommerceSecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass11CommerceSecC')) {
                if (subjectsOfClass.subNamesEngClass11CommerceSecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass11CommerceSecC: { error: true } } })
                    errorArray.push('EngClass11CommerceSecC');
                } else {
                    const e = errorArray.indexOf('EngClass11CommerceSecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass11CommerceSecD')) {
                if (subjectsOfClass.subNamesEngClass11CommerceSecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass11CommerceSecD: { error: true } } })
                    errorArray.push('EngClass11CommerceSecD');
                } else {
                    const e = errorArray.indexOf('EngClass11CommerceSecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass11CommerceSecE')) {
                if (subjectsOfClass.subNamesEngClass11CommerceSecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass11CommerceSecE: { error: true } } })
                    errorArray.push('EngClass11CommerceSecE');
                } else {
                    const e = errorArray.indexOf('EngClass11CommerceSecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass11CommerceSecA')) {
                if (subjectsOfClass.subNamesMarClass11CommerceSecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass11CommerceSecA: { error: true } } })
                    errorArray.push('MarClass11CommerceSecA');
                } else {
                    const e = errorArray.indexOf('MarClass11CommerceSecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass11CommerceSecB')) {
                if (subjectsOfClass.subNamesMarClass11CommerceSecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass11CommerceSecB: { error: true } } })
                    errorArray.push('MarClass11CommerceSecB');
                } else {
                    const e = errorArray.indexOf('MarClass11CommerceSecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass11CommerceSecC')) {
                if (subjectsOfClass.subNamesMarClass11CommerceSecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass11CommerceSecC: { error: true } } })
                    errorArray.push('MarClass11CommerceSecC');
                } else {
                    const e = errorArray.indexOf('MarClass11CommerceSecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass11CommerceSecD')) {
                if (subjectsOfClass.subNamesMarClass11CommerceSecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass11CommerceSecD: { error: true } } })
                    errorArray.push('MarClass11CommerceSecD');
                } else {
                    const e = errorArray.indexOf('MarClass11CommerceSecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass11CommerceSecE')) {
                if (subjectsOfClass.subNamesMarClass11CommerceSecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass11CommerceSecE: { error: true } } })
                    errorArray.push('MarClass11CommerceSecE');
                } else {
                    const e = errorArray.indexOf('MarClass11CommerceSecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (schoolClassData.includes('EngClass11ScienceSecA')) {
                if (subjectsOfClass.subNamesEngClass11ScienceSecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass11ScienceSecA: { error: true } } })
                    errorArray.push('EngClass11ScienceSecA');
                } else {
                    const e = errorArray.indexOf('EngClass11ScienceSecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass11ScienceSecB')) {
                if (subjectsOfClass.subNamesEngClass11ScienceSecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass11ScienceSecB: { error: true } } })
                    errorArray.push('EngClass11ScienceSecB');
                } else {
                    const e = errorArray.indexOf('EngClass11ScienceSecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass11ScienceSecC')) {
                if (subjectsOfClass.subNamesEngClass11ScienceSecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass11ScienceSecC: { error: true } } })
                    errorArray.push('EngClass11ScienceSecC');
                } else {
                    const e = errorArray.indexOf('EngClass11ScienceSecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass11ScienceSecD')) {
                if (subjectsOfClass.subNamesEngClass11ScienceSecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass11ScienceSecD: { error: true } } })
                    errorArray.push('EngClass11ScienceSecD');
                } else {
                    const e = errorArray.indexOf('EngClass11ScienceSecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass11ScienceSecE')) {
                if (subjectsOfClass.subNamesEngClass11ScienceSecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass11ScienceSecE: { error: true } } })
                    errorArray.push('EngClass11ScienceSecE');
                } else {
                    const e = errorArray.indexOf('EngClass11ScienceSecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (schoolClassData.includes('MarClass12ArtsSecA')) {
                if (subjectsOfClass.subNamesMarClass12ArtsSecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass12ArtsSecA: { error: true } } })
                    errorArray.push('MarClass12ArtsSecA');
                } else {
                    const e = errorArray.indexOf('MarClass12ArtsSecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass12ArtsSecB')) {
                if (subjectsOfClass.subNamesMarClass12ArtsSecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass12ArtsSecB: { error: true } } })
                    errorArray.push('MarClass12ArtsSecB');
                } else {
                    const e = errorArray.indexOf('MarClass12ArtsSecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass12ArtsSecC')) {
                if (subjectsOfClass.subNamesMarClass12ArtsSecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass12ArtsSecC: { error: true } } })
                    errorArray.push('MarClass12ArtsSecC');
                } else {
                    const e = errorArray.indexOf('MarClass12ArtsSecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass12ArtsSecD')) {
                if (subjectsOfClass.subNamesMarClass12ArtsSecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass12ArtsSecD: { error: true } } })
                    errorArray.push('MarClass12ArtsSecD');
                } else {
                    const e = errorArray.indexOf('MarClass12ArtsSecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass12ArtsSecE')) {
                if (subjectsOfClass.subNamesMarClass12ArtsSecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass12ArtsSecE: { error: true } } })
                    errorArray.push('MarClass12ArtsSecE');
                } else {
                    const e = errorArray.indexOf('MarClass12ArtsSecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (schoolClassData.includes('EngClass12CommerceSecA')) {
                if (subjectsOfClass.subNamesEngClass12CommerceSecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass12CommerceSecA: { error: true } } })
                    errorArray.push('EngClass12CommerceSecA');
                } else {
                    const e = errorArray.indexOf('EngClass12CommerceSecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass12CommerceSecB')) {
                if (subjectsOfClass.subNamesEngClass12CommerceSecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass12CommerceSecB: { error: true } } })
                    errorArray.push('EngClass12CommerceSecB');
                } else {
                    const e = errorArray.indexOf('EngClass12CommerceSecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass12CommerceSecC')) {
                if (subjectsOfClass.subNamesEngClass12CommerceSecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass12CommerceSecC: { error: true } } })
                    errorArray.push('EngClass12CommerceSecC');
                } else {
                    const e = errorArray.indexOf('EngClass12CommerceSecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass12CommerceSecD')) {
                if (subjectsOfClass.subNamesEngClass12CommerceSecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass12CommerceSecD: { error: true } } })
                    errorArray.push('EngClass12CommerceSecD');
                } else {
                    const e = errorArray.indexOf('EngClass12CommerceSecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass12CommerceSecE')) {
                if (subjectsOfClass.subNamesEngClass12CommerceSecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass12CommerceSecE: { error: true } } })
                    errorArray.push('EngClass12CommerceSecE');
                } else {
                    const e = errorArray.indexOf('EngClass12CommerceSecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass12CommerceSecA')) {
                if (subjectsOfClass.subNamesMarClass12CommerceSecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass12CommerceSecA: { error: true } } })
                    errorArray.push('MarClass12CommerceSecA');
                } else {
                    const e = errorArray.indexOf('MarClass12CommerceSecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass12CommerceSecB')) {
                if (subjectsOfClass.subNamesMarClass12CommerceSecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass12CommerceSecB: { error: true } } })
                    errorArray.push('MarClass12CommerceSecB');
                } else {
                    const e = errorArray.indexOf('MarClass12CommerceSecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass12CommerceSecC')) {
                if (subjectsOfClass.subNamesMarClass12CommerceSecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass12CommerceSecC: { error: true } } })
                    errorArray.push('MarClass12CommerceSecC');
                } else {
                    const e = errorArray.indexOf('MarClass12CommerceSecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass12CommerceSecD')) {
                if (subjectsOfClass.subNamesMarClass12CommerceSecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass12CommerceSecD: { error: true } } })
                    errorArray.push('MarClass12CommerceSecD');
                } else {
                    const e = errorArray.indexOf('MarClass12CommerceSecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('MarClass12CommerceSecE')) {
                if (subjectsOfClass.subNamesMarClass12CommerceSecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesMarClass12CommerceSecE: { error: true } } })
                    errorArray.push('MarClass12CommerceSecE');
                } else {
                    const e = errorArray.indexOf('MarClass12CommerceSecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (schoolClassData.includes('EngClass12ScienceSecA')) {
                if (subjectsOfClass.subNamesEngClass12ScienceSecA.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass12ScienceSecA: { error: true } } })
                    errorArray.push('EngClass12ScienceSecA');
                } else {
                    const e = errorArray.indexOf('EngClass12ScienceSecA');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass12ScienceSecB')) {
                if (subjectsOfClass.subNamesEngClass12ScienceSecB.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass12ScienceSecB: { error: true } } })
                    errorArray.push('EngClass12ScienceSecB');
                } else {
                    const e = errorArray.indexOf('EngClass12ScienceSecB');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass12ScienceSecC')) {
                if (subjectsOfClass.subNamesEngClass12ScienceSecC.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass12ScienceSecC: { error: true } } })
                    errorArray.push('EngClass12ScienceSecC');
                } else {
                    const e = errorArray.indexOf('EngClass12ScienceSecC');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass12ScienceSecD')) {
                if (subjectsOfClass.subNamesEngClass12ScienceSecD.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass12ScienceSecD: { error: true } } })
                    errorArray.push('EngClass12ScienceSecD');
                } else {
                    const e = errorArray.indexOf('EngClass12ScienceSecD');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }
            if (schoolClassData.includes('EngClass12ScienceSecE')) {
                if (subjectsOfClass.subNamesEngClass12ScienceSecE.length === 0) {
                    setshowEnteredSchoolSub(prevState => { return { ...prevState, subNamesEngClass12ScienceSecE: { error: true } } })
                    errorArray.push('EngClass12ScienceSecE');
                } else {
                    const e = errorArray.indexOf('EngClass12ScienceSecE');
                    if (e >= 0) { errorArray.splice(e, 1); }
                }
            }

            if (errorArray.length === 0) {

                setshowBlock(prev => { return { ...prev, showClsSubBlock: false, showVisMisBlock: true } })

            }
        }
    }



    return (
        <div className='createschoolaccount'>
            {
                showBlock.showClsSubBlock && (
                    <form className='createschoolaccount__container'>
                        <h1>ConnectEdApp</h1>
                        <p>Add Classes and their subjects individually</p>
                        <hr />
                        <div className='createschoolaccount__containerTablesData'>
                            <h4>Academic Structural Details</h4>
                            <div className='dummyDataAccordian'>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div className='howToSelect'>
                                            <h3>How to select the classes?</h3>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='createnewaccount__note'>
                                            <h3>Note:<span> Please read the dummy data in this box carefully to know how to select classes and their sections</span></h3>
                                            <div className='createnewaccount__noteData'>
                                                <h4>See Examples below</h4>
                                                <div className='createnewaccount__noteDataexamples'>
                                                    <p><span>Example 1.</span> If your school has Class <strong>KG-1</strong> with <strong>3 sections</strong> and that too in <strong>English</strong> medium only. </p>
                                                    <p><span>Example 2.</span> If your school has class <strong>8th</strong>, which has <strong>2 sections</strong> in both <strong>English</strong> & <strong>Marathi</strong> Medium</p>
                                                    <p><span>Example 3.</span> If your school has class <strong>11th (Arts)</strong>, which has <strong>2 sections</strong> in <strong>Marathi</strong> Medium only</p>
                                                    <p><span>Example 4.</span> If your school has class <strong>12th (Commerce)</strong>, which has only <strong>1 section</strong> each in <strong>English</strong> & <strong>Marathi</strong> Medium.</p>
                                                    <p><span>Example 5.</span> If your school does not have class <strong>12th (Science)</strong> at all.</p>
                                                    <h5>Then the selection table will be like below</h5>
                                                    <table className='dummytable'>
                                                        <thead>
                                                            <tr >
                                                                <th className='dummyTh' rowSpan='12'><p >Classes</p></th>
                                                                <th className='dummyTh' colSpan='5'><p>English Medium</p></th>
                                                                <th className='dummyTh' colSpan='5'><p>Marathi Medium</p></th>
                                                            </tr>
                                                            <tr >
                                                                <th rowSpan='12'><p>Section <br />A</p></th>
                                                                <th rowSpan='12'><p>Section <br />B</p></th>
                                                                <th rowSpan='12'><p>Section <br />C</p></th>
                                                                <th rowSpan='12'><p>Section <br />D</p></th>
                                                                <th rowSpan='12'><p>Section <br />E</p></th>
                                                                <th rowSpan='12'><p>Section <br />A</p></th>
                                                                <th rowSpan='12'><p>Section <br />B</p></th>
                                                                <th rowSpan='12'><p>Section <br />C</p></th>
                                                                <th rowSpan='12'><p>Section <br />D</p></th>
                                                                <th rowSpan='12'><p>Section <br />E</p></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            < tr >
                                                                <td><p className='cls'>Kindergarten 1(KG-1)</p></td>
                                                                <td><input type='checkbox' checked disabled /></td>
                                                                <td><input type='checkbox' checked disabled /></td>
                                                                <td><input type='checkbox' checked disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                            </tr>
                                                            < tr >
                                                                <td><p className='cls'>Class 8</p></td>
                                                                <td><input type='checkbox' checked disabled /></td>
                                                                <td><input type='checkbox' checked disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' checked disabled /></td>
                                                                <td><input type='checkbox' checked disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                            </tr>
                                                            < tr >
                                                                <td><p className='cls'>Class 11 (Arts)</p></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' checked disabled /></td>
                                                                <td><input type='checkbox' checked disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                            </tr>
                                                            < tr >
                                                                <td><p className='cls'>Class 12 (Commerce)</p></td>
                                                                <td><input type='checkbox' checked disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' checked disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                            </tr>
                                                            < tr >
                                                                <td><p className='cls'>Class 12 (Science)</p></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                                <td><input type='checkbox' disabled /></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className='extraDiv'>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                            <h4 className='checkbox__heading' style={{ color: 'black' }}>Please select the appropriate checkboxes of the classes and their sections carefully in the below table.<br />(Take reference from the above table of dummy examples)</h4>
                            <div className='note'>
                                <h3>Note :<span> Select Section A checkbox to enable Section B checkbox, select Section B checkbox to enable Section C checkbox,<br /> and so on.</span></h3>
                            </div>
                            <table className='Classtable'>
                                <thead>
                                    <tr className='Classtable__mainheading'>
                                        <th rowSpan='12'><p>Classes</p></th>
                                        <th colSpan='5'><p>English Medium</p></th>
                                        <th colSpan='5'><p>Marathi Medium</p></th>
                                    </tr>
                                    <tr className='Classtable__subheading'>
                                        <th rowSpan='12'><p>Section <br />A</p></th>
                                        <th rowSpan='12'><p>Section <br />B</p></th>
                                        <th rowSpan='12'><p>Section <br />C</p></th>
                                        <th rowSpan='12'><p>Section <br />D</p></th>
                                        <th rowSpan='12'><p>Section <br />E</p></th>
                                        <th rowSpan='12'><p>Section <br />A</p></th>
                                        <th rowSpan='12'><p>Section <br />B</p></th>
                                        <th rowSpan='12'><p>Section <br />C</p></th>
                                        <th rowSpan='12'><p>Section <br />D</p></th>
                                        <th rowSpan='12'><p>Section <br />E</p></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    < tr >
                                        <td><p className='cls'>Pre Nursery</p></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngPreNurserySecA.checked} key='EngPreNurserySecA' name='EngPreNurserySecA' onChange={handleChanageClassesOfSchoolEngPreNursery} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngPreNurserySecB.checked} disabled={classesOfSchool.EngPreNurserySecA.disabled} key='EngPreNurserySecB' name='EngPreNurserySecB' onChange={handleChanageClassesOfSchoolEngPreNursery} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngPreNurserySecC.checked} disabled={classesOfSchool.EngPreNurserySecB.disabled} key='EngPreNurserySecC' name='EngPreNurserySecC' onChange={handleChanageClassesOfSchoolEngPreNursery} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngPreNurserySecD.checked} disabled={classesOfSchool.EngPreNurserySecC.disabled} key='EngPreNurserySecD' name='EngPreNurserySecD' onChange={handleChanageClassesOfSchoolEngPreNursery} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngPreNurserySecE.checked} disabled={classesOfSchool.EngPreNurserySecD.disabled} key='EngPreNurserySecE' name='EngPreNurserySecE' onChange={handleChanageClassesOfSchoolEngPreNursery} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarPreNurserySecA.checked} key='MarPreNurserySecA' name='MarPreNurserySecA' onChange={handleChanageClassesOfSchoolMarPreNursery} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarPreNurserySecB.checked} disabled={classesOfSchool.MarPreNurserySecA.disabled} key='MarPreNurserySecB' name='MarPreNurserySecB' onChange={handleChanageClassesOfSchoolMarPreNursery} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarPreNurserySecC.checked} disabled={classesOfSchool.MarPreNurserySecB.disabled} key='MarPreNurserySecC' name='MarPreNurserySecC' onChange={handleChanageClassesOfSchoolMarPreNursery} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarPreNurserySecD.checked} disabled={classesOfSchool.MarPreNurserySecC.disabled} key='MarPreNurserySecD' name='MarPreNurserySecD' onChange={handleChanageClassesOfSchoolMarPreNursery} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarPreNurserySecE.checked} disabled={classesOfSchool.MarPreNurserySecD.disabled} key='MarPreNurserySecE' name='MarPreNurserySecE' onChange={handleChanageClassesOfSchoolMarPreNursery} /></td>

                                        {/* <td><Checkbox size='small' color='primary' disabled key='MarPreNurserySecA' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngPreNurserySecB' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngPreNurserySecC' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngPreNurserySecD' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngPreNurserySecE' /></td> */}
                                    </tr>

                                    < tr >
                                        <td><p className='cls'>Nursery</p></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngNurserySecA.checked} key='EngNurserySecA' name='EngNurserySecA' onChange={handleChanageClassesOfSchoolEngNursery} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngNurserySecB.checked} disabled={classesOfSchool.EngNurserySecA.disabled} key='EngNurserySecB' name='EngNurserySecB' onChange={handleChanageClassesOfSchoolEngNursery} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngNurserySecC.checked} disabled={classesOfSchool.EngNurserySecB.disabled} key='EngNurserySecC' name='EngNurserySecC' onChange={handleChanageClassesOfSchoolEngNursery} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngNurserySecD.checked} disabled={classesOfSchool.EngNurserySecC.disabled} key='EngNurserySecD' name='EngNurserySecD' onChange={handleChanageClassesOfSchoolEngNursery} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngNurserySecE.checked} disabled={classesOfSchool.EngNurserySecD.disabled} key='EngNurserySecE' name='EngNurserySecE' onChange={handleChanageClassesOfSchoolEngNursery} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarNurserySecA.checked} key='MarNurserySecA' name='MarNurserySecA' onChange={handleChanageClassesOfSchoolMarNursery} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarNurserySecB.checked} disabled={classesOfSchool.MarNurserySecA.disabled} key='MarNurserySecB' name='MarNurserySecB' onChange={handleChanageClassesOfSchoolMarNursery} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarNurserySecC.checked} disabled={classesOfSchool.MarNurserySecB.disabled} key='MarNurserySecC' name='MarNurserySecC' onChange={handleChanageClassesOfSchoolMarNursery} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarNurserySecD.checked} disabled={classesOfSchool.MarNurserySecC.disabled} key='MarNurserySecD' name='MarNurserySecD' onChange={handleChanageClassesOfSchoolMarNursery} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarNurserySecE.checked} disabled={classesOfSchool.MarNurserySecD.disabled} key='MarNurserySecE' name='MarNurserySecE' onChange={handleChanageClassesOfSchoolMarNursery} /></td>

                                        {/* <td><Checkbox size='small' color='primary' disabled key='MarNurserySecA' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngNurserySecB' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngNurserySecC' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngNurserySecD' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngNurserySecE' /></td> */}
                                    </tr>

                                    < tr >
                                        <td><p className='cls'>Kindergarten-1(KG-1)</p></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngKG1SecA.checked} key='EngKG1SecA' name='EngKG1SecA' onChange={handleChanageClassesOfSchoolEngKG1} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngKG1SecB.checked} disabled={classesOfSchool.EngKG1SecA.disabled} key='EngKG1SecB' name='EngKG1SecB' onChange={handleChanageClassesOfSchoolEngKG1} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngKG1SecC.checked} disabled={classesOfSchool.EngKG1SecB.disabled} key='EngKG1SecC' name='EngKG1SecC' onChange={handleChanageClassesOfSchoolEngKG1} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngKG1SecD.checked} disabled={classesOfSchool.EngKG1SecC.disabled} key='EngKG1SecD' name='EngKG1SecD' onChange={handleChanageClassesOfSchoolEngKG1} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngKG1SecE.checked} disabled={classesOfSchool.EngKG1SecD.disabled} key='EngKG1SecE' name='EngKG1SecE' onChange={handleChanageClassesOfSchoolEngKG1} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarKG1SecA.checked} key='MarKG1SecA' name='MarKG1SecA' onChange={handleChanageClassesOfSchoolMarKG1} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarKG1SecB.checked} disabled={classesOfSchool.MarKG1SecA.disabled} key='MarKG1SecB' name='MarKG1SecB' onChange={handleChanageClassesOfSchoolMarKG1} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarKG1SecC.checked} disabled={classesOfSchool.MarKG1SecB.disabled} key='MarKG1SecC' name='MarKG1SecC' onChange={handleChanageClassesOfSchoolMarKG1} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarKG1SecD.checked} disabled={classesOfSchool.MarKG1SecC.disabled} key='MarKG1SecD' name='MarKG1SecD' onChange={handleChanageClassesOfSchoolMarKG1} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarKG1SecE.checked} disabled={classesOfSchool.MarKG1SecD.disabled} key='MarKG1SecE' name='MarKG1SecE' onChange={handleChanageClassesOfSchoolMarKG1} /></td>

                                        {/* <td><Checkbox size='small' color='primary' disabled key='MarKG1SecA' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngKG1SecB' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngKG1SecC' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngKG1SecD' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngKG1SecE' /></td> */}
                                    </tr>
                                    < tr >
                                        <td><p className='cls'>Kindergarten-2(KG-2)</p></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngKG2SecA.checked} key='EngKG2SecA' name='EngKG2SecA' onChange={handleChanageClassesOfSchoolEngKG2} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngKG2SecB.checked} disabled={classesOfSchool.EngKG2SecA.disabled} key='EngKG2SecB' name='EngKG2SecB' onChange={handleChanageClassesOfSchoolEngKG2} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngKG2SecC.checked} disabled={classesOfSchool.EngKG2SecB.disabled} key='EngKG2SecC' name='EngKG2SecC' onChange={handleChanageClassesOfSchoolEngKG2} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngKG2SecD.checked} disabled={classesOfSchool.EngKG2SecC.disabled} key='EngKG2SecD' name='EngKG2SecD' onChange={handleChanageClassesOfSchoolEngKG2} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngKG2SecE.checked} disabled={classesOfSchool.EngKG2SecD.disabled} key='EngKG2SecE' name='EngKG2SecE' onChange={handleChanageClassesOfSchoolEngKG2} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarKG2SecA.checked} key='MarKG2SecA' name='MarKG2SecA' onChange={handleChanageClassesOfSchoolMarKG2} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarKG2SecB.checked} disabled={classesOfSchool.MarKG2SecA.disabled} key='MarKG2SecB' name='MarKG2SecB' onChange={handleChanageClassesOfSchoolMarKG2} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarKG2SecC.checked} disabled={classesOfSchool.MarKG2SecB.disabled} key='MarKG2SecC' name='MarKG2SecC' onChange={handleChanageClassesOfSchoolMarKG2} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarKG2SecD.checked} disabled={classesOfSchool.MarKG2SecC.disabled} key='MarKG2SecD' name='MarKG2SecD' onChange={handleChanageClassesOfSchoolMarKG2} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarKG2SecE.checked} disabled={classesOfSchool.MarKG2SecD.disabled} key='MarKG2SecE' name='MarKG2SecE' onChange={handleChanageClassesOfSchoolMarKG2} /></td>

                                        {/* <td><Checkbox size='small' color='primary' disabled key='MarKG2SecA' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngKG2SecB' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngKG2SecC' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngKG2SecD' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngKG2SecE' /></td> */}
                                    </tr>
                                    < tr >
                                        <td className='cls'><p>Class 1</p></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass1SecA.checked} key='EngClass1SecA' name='EngClass1SecA' onChange={handleChanageClassesOfSchoolEngClass1} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass1SecB.checked} disabled={classesOfSchool.EngClass1SecA.disabled} key='EngClass1SecB' name='EngClass1SecB' onChange={handleChanageClassesOfSchoolEngClass1} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass1SecC.checked} disabled={classesOfSchool.EngClass1SecB.disabled} key='EngClass1SecC' name='EngClass1SecC' onChange={handleChanageClassesOfSchoolEngClass1} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass1SecD.checked} disabled={classesOfSchool.EngClass1SecC.disabled} key='EngClass1SecD' name='EngClass1SecD' onChange={handleChanageClassesOfSchoolEngClass1} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass1SecE.checked} disabled={classesOfSchool.EngClass1SecD.disabled} key='EngClass1SecE' name='EngClass1SecE' onChange={handleChanageClassesOfSchoolEngClass1} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass1SecA.checked} key='MarClass1SecA' name='MarClass1SecA' onChange={handleChanageClassesOfSchoolMarClass1} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass1SecB.checked} disabled={classesOfSchool.MarClass1SecA.disabled} key='MarClass1SecB' name='MarClass1SecB' onChange={handleChanageClassesOfSchoolMarClass1} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass1SecC.checked} disabled={classesOfSchool.MarClass1SecB.disabled} key='MarClass1SecC' name='MarClass1SecC' onChange={handleChanageClassesOfSchoolMarClass1} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass1SecD.checked} disabled={classesOfSchool.MarClass1SecC.disabled} key='MarClass1SecD' name='MarClass1SecD' onChange={handleChanageClassesOfSchoolMarClass1} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass1SecE.checked} disabled={classesOfSchool.MarClass1SecD.disabled} key='MarClass1SecE' name='MarClass1SecE' onChange={handleChanageClassesOfSchoolMarClass1} /></td>
                                    </tr>
                                    < tr >
                                        <td className='cls'><p>Class 2</p></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass2SecA.checked} key='EngClass2SecA' name='EngClass2SecA' onChange={handleChanageClassesOfSchoolEngClass2} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass2SecB.checked} disabled={classesOfSchool.EngClass2SecA.disabled} key='EngClass2SecB' name='EngClass2SecB' onChange={handleChanageClassesOfSchoolEngClass2} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass2SecC.checked} disabled={classesOfSchool.EngClass2SecB.disabled} key='EngClass2SecC' name='EngClass2SecC' onChange={handleChanageClassesOfSchoolEngClass2} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass2SecD.checked} disabled={classesOfSchool.EngClass2SecC.disabled} key='EngClass2SecD' name='EngClass2SecD' onChange={handleChanageClassesOfSchoolEngClass2} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass2SecE.checked} disabled={classesOfSchool.EngClass2SecD.disabled} key='EngClass2SecE' name='EngClass2SecE' onChange={handleChanageClassesOfSchoolEngClass2} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass2SecA.checked} key='MarClass2SecA' name='MarClass2SecA' onChange={handleChanageClassesOfSchoolMarClass2} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass2SecB.checked} disabled={classesOfSchool.MarClass2SecA.disabled} key='MarClass2SecB' name='MarClass2SecB' onChange={handleChanageClassesOfSchoolMarClass2} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass2SecC.checked} disabled={classesOfSchool.MarClass2SecB.disabled} key='MarClass2SecC' name='MarClass2SecC' onChange={handleChanageClassesOfSchoolMarClass2} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass2SecD.checked} disabled={classesOfSchool.MarClass2SecC.disabled} key='MarClass2SecD' name='MarClass2SecD' onChange={handleChanageClassesOfSchoolMarClass2} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass2SecE.checked} disabled={classesOfSchool.MarClass2SecD.disabled} key='MarClass2SecE' name='MarClass2SecE' onChange={handleChanageClassesOfSchoolMarClass2} /></td>
                                    </tr>
                                    < tr >
                                        <td className='cls'><p>Class 3</p></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass3SecA.checked} key='EngClass3SecA' name='EngClass3SecA' onChange={handleChanageClassesOfSchoolEngClass3} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass3SecB.checked} disabled={classesOfSchool.EngClass3SecA.disabled} key='EngClass3SecB' name='EngClass3SecB' onChange={handleChanageClassesOfSchoolEngClass3} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass3SecC.checked} disabled={classesOfSchool.EngClass3SecB.disabled} key='EngClass3SecC' name='EngClass3SecC' onChange={handleChanageClassesOfSchoolEngClass3} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass3SecD.checked} disabled={classesOfSchool.EngClass3SecC.disabled} key='EngClass3SecD' name='EngClass3SecD' onChange={handleChanageClassesOfSchoolEngClass3} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass3SecE.checked} disabled={classesOfSchool.EngClass3SecD.disabled} key='EngClass3SecE' name='EngClass3SecE' onChange={handleChanageClassesOfSchoolEngClass3} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass3SecA.checked} key='MarClass3SecA' name='MarClass3SecA' onChange={handleChanageClassesOfSchoolMarClass3} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass3SecB.checked} disabled={classesOfSchool.MarClass3SecA.disabled} key='MarClass3SecB' name='MarClass3SecB' onChange={handleChanageClassesOfSchoolMarClass3} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass3SecC.checked} disabled={classesOfSchool.MarClass3SecB.disabled} key='MarClass3SecC' name='MarClass3SecC' onChange={handleChanageClassesOfSchoolMarClass3} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass3SecD.checked} disabled={classesOfSchool.MarClass3SecC.disabled} key='MarClass3SecD' name='MarClass3SecD' onChange={handleChanageClassesOfSchoolMarClass3} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass3SecE.checked} disabled={classesOfSchool.MarClass3SecD.disabled} key='MarClass3SecE' name='MarClass3SecE' onChange={handleChanageClassesOfSchoolMarClass3} /></td>
                                    </tr>
                                    < tr >
                                        <td className='cls'><p>Class 4</p></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass4SecA.checked} key='EngClass4SecA' name='EngClass4SecA' onChange={handleChanageClassesOfSchoolEngClass4} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass4SecB.checked} disabled={classesOfSchool.EngClass4SecA.disabled} key='EngClass4SecB' name='EngClass4SecB' onChange={handleChanageClassesOfSchoolEngClass4} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass4SecC.checked} disabled={classesOfSchool.EngClass4SecB.disabled} key='EngClass4SecC' name='EngClass4SecC' onChange={handleChanageClassesOfSchoolEngClass4} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass4SecD.checked} disabled={classesOfSchool.EngClass4SecC.disabled} key='EngClass4SecD' name='EngClass4SecD' onChange={handleChanageClassesOfSchoolEngClass4} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass4SecE.checked} disabled={classesOfSchool.EngClass4SecD.disabled} key='EngClass4SecE' name='EngClass4SecE' onChange={handleChanageClassesOfSchoolEngClass4} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass4SecA.checked} key='MarClass4SecA' name='MarClass4SecA' onChange={handleChanageClassesOfSchoolMarClass4} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass4SecB.checked} disabled={classesOfSchool.MarClass4SecA.disabled} key='MarClass4SecB' name='MarClass4SecB' onChange={handleChanageClassesOfSchoolMarClass4} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass4SecC.checked} disabled={classesOfSchool.MarClass4SecB.disabled} key='MarClass4SecC' name='MarClass4SecC' onChange={handleChanageClassesOfSchoolMarClass4} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass4SecD.checked} disabled={classesOfSchool.MarClass4SecC.disabled} key='MarClass4SecD' name='MarClass4SecD' onChange={handleChanageClassesOfSchoolMarClass4} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass4SecE.checked} disabled={classesOfSchool.MarClass4SecD.disabled} key='MarClass4SecE' name='MarClass4SecE' onChange={handleChanageClassesOfSchoolMarClass4} /></td>
                                    </tr>
                                    < tr >
                                        <td className='cls'><p>Class 5</p></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass5SecA.checked} key='EngClass5SecA' name='EngClass5SecA' onChange={handleChanageClassesOfSchoolEngClass5} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass5SecB.checked} disabled={classesOfSchool.EngClass5SecA.disabled} key='EngClass5SecB' name='EngClass5SecB' onChange={handleChanageClassesOfSchoolEngClass5} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass5SecC.checked} disabled={classesOfSchool.EngClass5SecB.disabled} key='EngClass5SecC' name='EngClass5SecC' onChange={handleChanageClassesOfSchoolEngClass5} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass5SecD.checked} disabled={classesOfSchool.EngClass5SecC.disabled} key='EngClass5SecD' name='EngClass5SecD' onChange={handleChanageClassesOfSchoolEngClass5} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass5SecE.checked} disabled={classesOfSchool.EngClass5SecD.disabled} key='EngClass5SecE' name='EngClass5SecE' onChange={handleChanageClassesOfSchoolEngClass5} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass5SecA.checked} key='MarClass5SecA' name='MarClass5SecA' onChange={handleChanageClassesOfSchoolMarClass5} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass5SecB.checked} disabled={classesOfSchool.MarClass5SecA.disabled} key='MarClass5SecB' name='MarClass5SecB' onChange={handleChanageClassesOfSchoolMarClass5} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass5SecC.checked} disabled={classesOfSchool.MarClass5SecB.disabled} key='MarClass5SecC' name='MarClass5SecC' onChange={handleChanageClassesOfSchoolMarClass5} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass5SecD.checked} disabled={classesOfSchool.MarClass5SecC.disabled} key='MarClass5SecD' name='MarClass5SecD' onChange={handleChanageClassesOfSchoolMarClass5} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass5SecE.checked} disabled={classesOfSchool.MarClass5SecD.disabled} key='MarClass5SecE' name='MarClass5SecE' onChange={handleChanageClassesOfSchoolMarClass5} /></td>
                                    </tr>
                                    < tr >
                                        <td className='cls'><p>Class 6</p></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass6SecA.checked} key='EngClass6SecA' name='EngClass6SecA' onChange={handleChanageClassesOfSchoolEngClass6} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass6SecB.checked} disabled={classesOfSchool.EngClass6SecA.disabled} key='EngClass6SecB' name='EngClass6SecB' onChange={handleChanageClassesOfSchoolEngClass6} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass6SecC.checked} disabled={classesOfSchool.EngClass6SecB.disabled} key='EngClass6SecC' name='EngClass6SecC' onChange={handleChanageClassesOfSchoolEngClass6} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass6SecD.checked} disabled={classesOfSchool.EngClass6SecC.disabled} key='EngClass6SecD' name='EngClass6SecD' onChange={handleChanageClassesOfSchoolEngClass6} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass6SecE.checked} disabled={classesOfSchool.EngClass6SecD.disabled} key='EngClass6SecE' name='EngClass6SecE' onChange={handleChanageClassesOfSchoolEngClass6} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass6SecA.checked} key='MarClass6SecA' name='MarClass6SecA' onChange={handleChanageClassesOfSchoolMarClass6} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass6SecB.checked} disabled={classesOfSchool.MarClass6SecA.disabled} key='MarClass6SecB' name='MarClass6SecB' onChange={handleChanageClassesOfSchoolMarClass6} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass6SecC.checked} disabled={classesOfSchool.MarClass6SecB.disabled} key='MarClass6SecC' name='MarClass6SecC' onChange={handleChanageClassesOfSchoolMarClass6} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass6SecD.checked} disabled={classesOfSchool.MarClass6SecC.disabled} key='MarClass6SecD' name='MarClass6SecD' onChange={handleChanageClassesOfSchoolMarClass6} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass6SecE.checked} disabled={classesOfSchool.MarClass6SecD.disabled} key='MarClass6SecE' name='MarClass6SecE' onChange={handleChanageClassesOfSchoolMarClass6} /></td>
                                    </tr>
                                    < tr >
                                        <td className='cls'><p>Class 7</p></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass7SecA.checked} key='EngClass7SecA' name='EngClass7SecA' onChange={handleChanageClassesOfSchoolEngClass7} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass7SecB.checked} disabled={classesOfSchool.EngClass7SecA.disabled} key='EngClass7SecB' name='EngClass7SecB' onChange={handleChanageClassesOfSchoolEngClass7} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass7SecC.checked} disabled={classesOfSchool.EngClass7SecB.disabled} key='EngClass7SecC' name='EngClass7SecC' onChange={handleChanageClassesOfSchoolEngClass7} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass7SecD.checked} disabled={classesOfSchool.EngClass7SecC.disabled} key='EngClass7SecD' name='EngClass7SecD' onChange={handleChanageClassesOfSchoolEngClass7} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass7SecE.checked} disabled={classesOfSchool.EngClass7SecD.disabled} key='EngClass7SecE' name='EngClass7SecE' onChange={handleChanageClassesOfSchoolEngClass7} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass7SecA.checked} key='MarClass7SecA' name='MarClass7SecA' onChange={handleChanageClassesOfSchoolMarClass7} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass7SecB.checked} disabled={classesOfSchool.MarClass7SecA.disabled} key='MarClass7SecB' name='MarClass7SecB' onChange={handleChanageClassesOfSchoolMarClass7} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass7SecC.checked} disabled={classesOfSchool.MarClass7SecB.disabled} key='MarClass7SecC' name='MarClass7SecC' onChange={handleChanageClassesOfSchoolMarClass7} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass7SecD.checked} disabled={classesOfSchool.MarClass7SecC.disabled} key='MarClass7SecD' name='MarClass7SecD' onChange={handleChanageClassesOfSchoolMarClass7} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass7SecE.checked} disabled={classesOfSchool.MarClass7SecD.disabled} key='MarClass7SecE' name='MarClass7SecE' onChange={handleChanageClassesOfSchoolMarClass7} /></td>
                                    </tr>
                                    < tr >
                                        <td className='cls'><p>Class 8</p></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass8SecA.checked} key='EngClass8SecA' name='EngClass8SecA' onChange={handleChanageClassesOfSchoolEngClass8} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass8SecB.checked} disabled={classesOfSchool.EngClass8SecA.disabled} key='EngClass8SecB' name='EngClass8SecB' onChange={handleChanageClassesOfSchoolEngClass8} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass8SecC.checked} disabled={classesOfSchool.EngClass8SecB.disabled} key='EngClass8SecC' name='EngClass8SecC' onChange={handleChanageClassesOfSchoolEngClass8} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass8SecD.checked} disabled={classesOfSchool.EngClass8SecC.disabled} key='EngClass8SecD' name='EngClass8SecD' onChange={handleChanageClassesOfSchoolEngClass8} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass8SecE.checked} disabled={classesOfSchool.EngClass8SecD.disabled} key='EngClass8SecE' name='EngClass8SecE' onChange={handleChanageClassesOfSchoolEngClass8} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass8SecA.checked} key='MarClass8SecA' name='MarClass8SecA' onChange={handleChanageClassesOfSchoolMarClass8} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass8SecB.checked} disabled={classesOfSchool.MarClass8SecA.disabled} key='MarClass8SecB' name='MarClass8SecB' onChange={handleChanageClassesOfSchoolMarClass8} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass8SecC.checked} disabled={classesOfSchool.MarClass8SecB.disabled} key='MarClass8SecC' name='MarClass8SecC' onChange={handleChanageClassesOfSchoolMarClass8} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass8SecD.checked} disabled={classesOfSchool.MarClass8SecC.disabled} key='MarClass8SecD' name='MarClass8SecD' onChange={handleChanageClassesOfSchoolMarClass8} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass8SecE.checked} disabled={classesOfSchool.MarClass8SecD.disabled} key='MarClass8SecE' name='MarClass8SecE' onChange={handleChanageClassesOfSchoolMarClass8} /></td>
                                    </tr>
                                    < tr >
                                        <td className='cls'><p>Class 9</p></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass9SecA.checked} key='EngClass9SecA' name='EngClass9SecA' onChange={handleChanageClassesOfSchoolEngClass9} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass9SecB.checked} disabled={classesOfSchool.EngClass9SecA.disabled} key='EngClass9SecB' name='EngClass9SecB' onChange={handleChanageClassesOfSchoolEngClass9} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass9SecC.checked} disabled={classesOfSchool.EngClass9SecB.disabled} key='EngClass9SecC' name='EngClass9SecC' onChange={handleChanageClassesOfSchoolEngClass9} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass9SecD.checked} disabled={classesOfSchool.EngClass9SecC.disabled} key='EngClass9SecD' name='EngClass9SecD' onChange={handleChanageClassesOfSchoolEngClass9} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass9SecE.checked} disabled={classesOfSchool.EngClass9SecD.disabled} key='EngClass9SecE' name='EngClass9SecE' onChange={handleChanageClassesOfSchoolEngClass9} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass9SecA.checked} key='MarClass9SecA' name='MarClass9SecA' onChange={handleChanageClassesOfSchoolMarClass9} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass9SecB.checked} disabled={classesOfSchool.MarClass9SecA.disabled} key='MarClass9SecB' name='MarClass9SecB' onChange={handleChanageClassesOfSchoolMarClass9} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass9SecC.checked} disabled={classesOfSchool.MarClass9SecB.disabled} key='MarClass9SecC' name='MarClass9SecC' onChange={handleChanageClassesOfSchoolMarClass9} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass9SecD.checked} disabled={classesOfSchool.MarClass9SecC.disabled} key='MarClass9SecD' name='MarClass9SecD' onChange={handleChanageClassesOfSchoolMarClass9} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass9SecE.checked} disabled={classesOfSchool.MarClass9SecD.disabled} key='MarClass9SecE' name='MarClass9SecE' onChange={handleChanageClassesOfSchoolMarClass9} /></td>
                                    </tr>
                                    < tr >
                                        <td className='cls'><p>Class 10</p></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass10SecA.checked} key='EngClass10SecA' name='EngClass10SecA' onChange={handleChanageClassesOfSchoolEngClass10} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass10SecB.checked} disabled={classesOfSchool.EngClass10SecA.disabled} key='EngClass10SecB' name='EngClass10SecB' onChange={handleChanageClassesOfSchoolEngClass10} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass10SecC.checked} disabled={classesOfSchool.EngClass10SecB.disabled} key='EngClass10SecC' name='EngClass10SecC' onChange={handleChanageClassesOfSchoolEngClass10} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass10SecD.checked} disabled={classesOfSchool.EngClass10SecC.disabled} key='EngClass10SecD' name='EngClass10SecD' onChange={handleChanageClassesOfSchoolEngClass10} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass10SecE.checked} disabled={classesOfSchool.EngClass10SecD.disabled} key='EngClass10SecE' name='EngClass10SecE' onChange={handleChanageClassesOfSchoolEngClass10} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass10SecA.checked} key='MarClass10SecA' name='MarClass10SecA' onChange={handleChanageClassesOfSchoolMarClass10} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass10SecB.checked} disabled={classesOfSchool.MarClass10SecA.disabled} key='MarClass10SecB' name='MarClass10SecB' onChange={handleChanageClassesOfSchoolMarClass10} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass10SecC.checked} disabled={classesOfSchool.MarClass10SecB.disabled} key='MarClass10SecC' name='MarClass10SecC' onChange={handleChanageClassesOfSchoolMarClass10} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass10SecD.checked} disabled={classesOfSchool.MarClass10SecC.disabled} key='MarClass10SecD' name='MarClass10SecD' onChange={handleChanageClassesOfSchoolMarClass10} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass10SecE.checked} disabled={classesOfSchool.MarClass10SecD.disabled} key='MarClass10SecE' name='MarClass10SecE' onChange={handleChanageClassesOfSchoolMarClass10} /></td>
                                    </tr>
                                    < tr >
                                        <td className='cls'><p>Class 11 (Arts)</p></td>
                                        <td><Checkbox size='small' color='primary' disabled key='MarClass11ArtsSecA' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='MarClass11ArtsSecB' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='MarClass11ArtsSecC' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='MarClass11ArtsSecD' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='MarClass11ArtsSecE' /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass11ArtsSecA.checked} key='MarClass11ArtsSecA' name='MarClass11ArtsSecA' onChange={handleChanageClassesOfSchoolMarClass11Arts} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass11ArtsSecB.checked} disabled={classesOfSchool.MarClass11ArtsSecA.disabled} key='MarClass11ArtsSecB' name='MarClass11ArtsSecB' onChange={handleChanageClassesOfSchoolMarClass11Arts} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass11ArtsSecC.checked} disabled={classesOfSchool.MarClass11ArtsSecB.disabled} key='MarClass11ArtsSecC' name='MarClass11ArtsSecC' onChange={handleChanageClassesOfSchoolMarClass11Arts} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass11ArtsSecD.checked} disabled={classesOfSchool.MarClass11ArtsSecC.disabled} key='MarClass11ArtsSecD' name='MarClass11ArtsSecD' onChange={handleChanageClassesOfSchoolMarClass11Arts} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass11ArtsSecE.checked} disabled={classesOfSchool.MarClass11ArtsSecD.disabled} key='MarClass11ArtsSecE' name='MarClass11ArtsSecE' onChange={handleChanageClassesOfSchoolMarClass11Arts} /></td>
                                    </tr>
                                    < tr >
                                        <td className='cls'><p>Class 11 (Commerce)</p></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass11CommerceSecA.checked} key='EngClass11CommerceSecA' name='EngClass11CommerceSecA' onChange={handleChanageClassesOfSchoolEngClass11Commerce} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass11CommerceSecB.checked} disabled={classesOfSchool.EngClass11CommerceSecA.disabled} key='EngClass11CommerceSecB' name='EngClass11CommerceSecB' onChange={handleChanageClassesOfSchoolEngClass11Commerce} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass11CommerceSecC.checked} disabled={classesOfSchool.EngClass11CommerceSecB.disabled} key='EngClass11CommerceSecC' name='EngClass11CommerceSecC' onChange={handleChanageClassesOfSchoolEngClass11Commerce} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass11CommerceSecD.checked} disabled={classesOfSchool.EngClass11CommerceSecC.disabled} key='EngClass11CommerceSecD' name='EngClass11CommerceSecD' onChange={handleChanageClassesOfSchoolEngClass11Commerce} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass11CommerceSecE.checked} disabled={classesOfSchool.EngClass11CommerceSecD.disabled} key='EngClass11CommerceSecE' name='EngClass11CommerceSecE' onChange={handleChanageClassesOfSchoolEngClass11Commerce} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass11CommerceSecA.checked} key='MarClass11CommerceSecA' name='MarClass11CommerceSecA' onChange={handleChanageClassesOfSchoolMarClass11Commerce} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass11CommerceSecB.checked} disabled={classesOfSchool.MarClass11CommerceSecA.disabled} key='MarClass11CommerceSecB' name='MarClass11CommerceSecB' onChange={handleChanageClassesOfSchoolMarClass11Commerce} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass11CommerceSecC.checked} disabled={classesOfSchool.MarClass11CommerceSecB.disabled} key='MarClass11CommerceSecC' name='MarClass11CommerceSecC' onChange={handleChanageClassesOfSchoolMarClass11Commerce} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass11CommerceSecD.checked} disabled={classesOfSchool.MarClass11CommerceSecC.disabled} key='MarClass11CommerceSecD' name='MarClass11CommerceSecD' onChange={handleChanageClassesOfSchoolMarClass11Commerce} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass11CommerceSecE.checked} disabled={classesOfSchool.MarClass11CommerceSecD.disabled} key='MarClass11CommerceSecE' name='MarClass11CommerceSecE' onChange={handleChanageClassesOfSchoolMarClass11Commerce} /></td>
                                    </tr>
                                    < tr >
                                        <td className='cls'><p>Class 11 (Science)</p></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass11ScienceSecA.checked} key='EngClass11ScienceSecA' name='EngClass11ScienceSecA' onChange={handleChanageClassesOfSchoolEngClass11Science} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass11ScienceSecB.checked} disabled={classesOfSchool.EngClass11ScienceSecA.disabled} key='EngClass11ScienceSecB' name='EngClass11ScienceSecB' onChange={handleChanageClassesOfSchoolEngClass11Science} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass11ScienceSecC.checked} disabled={classesOfSchool.EngClass11ScienceSecB.disabled} key='EngClass11ScienceSecC' name='EngClass11ScienceSecC' onChange={handleChanageClassesOfSchoolEngClass11Science} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass11ScienceSecD.checked} disabled={classesOfSchool.EngClass11ScienceSecC.disabled} key='EngClass11ScienceSecD' name='EngClass11ScienceSecD' onChange={handleChanageClassesOfSchoolEngClass11Science} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass11ScienceSecE.checked} disabled={classesOfSchool.EngClass11ScienceSecD.disabled} key='EngClass11ScienceSecE' name='EngClass11ScienceSecE' onChange={handleChanageClassesOfSchoolEngClass11Science} /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='MarClass11ScienceSecA' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngClass11ScienceSecB' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngClass11ScienceSecC' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngClass11ScienceSecD' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngClass11ScienceSecE' /></td>
                                    </tr>
                                    < tr >
                                        <td className='cls'><p>Class 12 (Arts)</p></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngClass12ArtsSecA' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngClass12ArtsSecB' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngClass12ArtsSecC' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngClass12ArtsSecD' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngClass12ArtsSecE' /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass12ArtsSecA.checked} key='MarClass12ArtsSecA' name='MarClass12ArtsSecA' onChange={handleChanageClassesOfSchoolMarClass12Arts} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass12ArtsSecB.checked} disabled={classesOfSchool.MarClass12ArtsSecA.disabled} key='MarClass12ArtsSecB' name='MarClass12ArtsSecB' onChange={handleChanageClassesOfSchoolMarClass12Arts} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass12ArtsSecC.checked} disabled={classesOfSchool.MarClass12ArtsSecB.disabled} key='MarClass12ArtsSecC' name='MarClass12ArtsSecC' onChange={handleChanageClassesOfSchoolMarClass12Arts} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass12ArtsSecD.checked} disabled={classesOfSchool.MarClass12ArtsSecC.disabled} key='MarClass12ArtsSecD' name='MarClass12ArtsSecD' onChange={handleChanageClassesOfSchoolMarClass12Arts} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass12ArtsSecE.checked} disabled={classesOfSchool.MarClass12ArtsSecD.disabled} key='MarClass12ArtsSecE' name='MarClass12ArtsSecE' onChange={handleChanageClassesOfSchoolMarClass12Arts} /></td>
                                    </tr>
                                    < tr >
                                        <td className='cls'><p>Class 12 (Commerce)</p></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass12CommerceSecA.checked} key='EngClass12CommerceSecA' name='EngClass12CommerceSecA' onChange={handleChanageClassesOfSchoolEngClass12Commerce} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass12CommerceSecB.checked} disabled={classesOfSchool.EngClass12CommerceSecA.disabled} key='EngClass12CommerceSecB' name='EngClass12CommerceSecB' onChange={handleChanageClassesOfSchoolEngClass12Commerce} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass12CommerceSecC.checked} disabled={classesOfSchool.EngClass12CommerceSecB.disabled} key='EngClass12CommerceSecC' name='EngClass12CommerceSecC' onChange={handleChanageClassesOfSchoolEngClass12Commerce} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass12CommerceSecD.checked} disabled={classesOfSchool.EngClass12CommerceSecC.disabled} key='EngClass12CommerceSecD' name='EngClass12CommerceSecD' onChange={handleChanageClassesOfSchoolEngClass12Commerce} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass12CommerceSecE.checked} disabled={classesOfSchool.EngClass12CommerceSecD.disabled} key='EngClass12CommerceSecE' name='EngClass12CommerceSecE' onChange={handleChanageClassesOfSchoolEngClass12Commerce} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass12CommerceSecA.checked} key='MarClass12CommerceSecA' name='MarClass12CommerceSecA' onChange={handleChanageClassesOfSchoolMarClass12Commerce} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass12CommerceSecB.checked} disabled={classesOfSchool.MarClass12CommerceSecA.disabled} key='MarClass12CommerceSecB' name='MarClass12CommerceSecB' onChange={handleChanageClassesOfSchoolMarClass12Commerce} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass12CommerceSecC.checked} disabled={classesOfSchool.MarClass12CommerceSecB.disabled} key='MarClass12CommerceSecC' name='MarClass12CommerceSecC' onChange={handleChanageClassesOfSchoolMarClass12Commerce} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass12CommerceSecD.checked} disabled={classesOfSchool.MarClass12CommerceSecC.disabled} key='MarClass12CommerceSecD' name='MarClass12CommerceSecD' onChange={handleChanageClassesOfSchoolMarClass12Commerce} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.MarClass12CommerceSecE.checked} disabled={classesOfSchool.MarClass12CommerceSecD.disabled} key='MarClass12CommerceSecE' name='MarClass12CommerceSecE' onChange={handleChanageClassesOfSchoolMarClass12Commerce} /></td>
                                    </tr>
                                    < tr >
                                        <td className='cls'><p>Class 12 (Science)</p></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass12ScienceSecA.checked} key='EngClass12ScienceSecA' name='EngClass12ScienceSecA' onChange={handleChanageClassesOfSchoolEngClass12Science} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass12ScienceSecB.checked} disabled={classesOfSchool.EngClass12ScienceSecA.disabled} key='EngClass12ScienceSecB' name='EngClass12ScienceSecB' onChange={handleChanageClassesOfSchoolEngClass12Science} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass12ScienceSecC.checked} disabled={classesOfSchool.EngClass12ScienceSecB.disabled} key='EngClass12ScienceSecC' name='EngClass12ScienceSecC' onChange={handleChanageClassesOfSchoolEngClass12Science} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass12ScienceSecD.checked} disabled={classesOfSchool.EngClass12ScienceSecC.disabled} key='EngClass12ScienceSecD' name='EngClass12ScienceSecD' onChange={handleChanageClassesOfSchoolEngClass12Science} /></td>
                                        <td><Checkbox size='small' color='primary' checked={classesOfSchool.EngClass12ScienceSecE.checked} disabled={classesOfSchool.EngClass12ScienceSecD.disabled} key='EngClass12ScienceSecE' name='EngClass12ScienceSecE' onChange={handleChanageClassesOfSchoolEngClass12Science} /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='MarClass12ScienceSecA' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngClass12ScienceSecB' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngClass12ScienceSecC' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngClass12ScienceSecD' /></td>
                                        <td><Checkbox size='small' color='primary' disabled key='EngClass12ScienceSecE' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            {
                                errorDisplay === 'show' && (
                                    <div className='error_message'>
                                        <h5>* Please select the classes in the above table. Without this step the school account won't get created</h5>
                                    </div>
                                )
                            }


                            <div className='classSubjects'>
                                <h2 style={{ color: 'var(--connected-color)' }}>Add Subjects to all the Selected Classes individually</h2>
                                <table className='classnSubjects__table'>
                                    <thead>
                                        <tr>
                                            <th className='classnSubjects__tableHeadingMedium'><p>Medium</p></th>
                                            <th className='classnSubjects__tableHeadingClass'><p>Class</p></th>
                                            <th className='classnSubjects__tableHeadingSection'><p>Section</p></th >
                                            <th className='classnSubjects__tableHeadingSubjects'><p>Subjects</p></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* Pre Nursery */}
                                        <tr className='RowEngPreNurserySecA' style={schoolClassData.includes('EngPreNurserySecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Pre Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngPreNurserySecA.error} name='subNamesEngPreNurserySecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngPreNurserySecA">Select Subjects for English Medium Pre Nursery Section A</InputLabel>
                                                        <Select
                                                            labelId="subForEngPreNurserySecA"
                                                            name='subNamesEngPreNurserySecA'
                                                            id="multsubForEngPreNurserySecA"
                                                            multiple
                                                            value={subjectsOfClass.subNamesEngPreNurserySecA}
                                                            onChange={addSubjectsToClass}
                                                            input={<OutlinedInput id="subForEngPreNurserySecA" />}
                                                            MenuProps={MenuProps}
                                                            renderValue={(selected) => (
                                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                    {selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}
                                                                </Box>)}>
                                                            {subjectList.map((subject) => (
                                                                <MenuItem
                                                                    key={subject}
                                                                    value={subject}
                                                                >
                                                                    {subject}
                                                                </MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngPreNurserySecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngPreNurserySecB' style={schoolClassData.includes('EngPreNurserySecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Pre Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngPreNurserySecB.error} name='subNamesEngPreNurserySecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngPreNurserySecB">Select Subjects for English Medium Pre Nursery Section B</InputLabel>
                                                        <Select labelId="subForEngPreNurserySecB" name='subNamesEngPreNurserySecB' id="multsubForEngPreNurserySecB" multiple value={subjectsOfClass.subNamesEngPreNurserySecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngPreNurserySecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngPreNurserySecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngPreNurserySecC' style={schoolClassData.includes('EngPreNurserySecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Pre Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngPreNurserySecC.error} name='subNamesEngPreNurserySecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngPreNurserySecC">Select Subjects for English Medium Pre Nursery Section C</InputLabel>
                                                        <Select labelId="subForEngPreNurserySecC" name='subNamesEngPreNurserySecC' id="multsubForEngPreNurserySecC" multiple value={subjectsOfClass.subNamesEngPreNurserySecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngPreNurserySecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngPreNurserySecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngPreNurserySecD' style={schoolClassData.includes('EngPreNurserySecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Pre Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngPreNurserySecD.error} name='subNamesEngPreNurserySecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngPreNurserySecD">Select Subjects for English Medium Pre Nursery Section D</InputLabel>
                                                        <Select labelId="subForEngPreNurserySecD" name='subNamesEngPreNurserySecD' id="multsubForEngPreNurserySecD" multiple value={subjectsOfClass.subNamesEngPreNurserySecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngPreNurserySecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngPreNurserySecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngPreNurserySecE' style={schoolClassData.includes('EngPreNurserySecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Pre Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngPreNurserySecE.error} name='subNamesEngPreNurserySecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngPreNurserySecE">Select Subjects for English Medium Pre Nursery Section E</InputLabel>
                                                        <Select labelId="subForEngPreNurserySecE" name='subNamesEngPreNurserySecE' id="multsubForEngPreNurserySecE" multiple value={subjectsOfClass.subNamesEngPreNurserySecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngPreNurserySecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngPreNurserySecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarPreNurserySecA' style={schoolClassData.includes('MarPreNurserySecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Pre Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarPreNurserySecA.error} name='subNamesMarPreNurserySecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarPreNurserySecA">Select Subjects for English Medium Pre Nursery Section A</InputLabel>
                                                        <Select labelId="subForMarPreNurserySecA" name='subNamesMarPreNurserySecA' id="multsubForMarPreNurserySecA" multiple value={subjectsOfClass.subNamesMarPreNurserySecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarPreNurserySecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarPreNurserySecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarPreNurserySecB' style={schoolClassData.includes('MarPreNurserySecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Pre Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarPreNurserySecB.error} name='subNamesMarPreNurserySecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarPreNurserySecB">Select Subjects for English Medium Pre Nursery Section B</InputLabel>
                                                        <Select labelId="subForMarPreNurserySecB" name='subNamesMarPreNurserySecB' id="multsubForMarPreNurserySecB" multiple value={subjectsOfClass.subNamesMarPreNurserySecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarPreNurserySecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarPreNurserySecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarPreNurserySecC' style={schoolClassData.includes('MarPreNurserySecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Pre Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarPreNurserySecC.error} name='subNamesMarPreNurserySecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarPreNurserySecC">Select Subjects for English Medium Pre Nursery Section C</InputLabel>
                                                        <Select labelId="subForMarPreNurserySecC" name='subNamesMarPreNurserySecC' id="multsubForMarPreNurserySecC" multiple value={subjectsOfClass.subNamesMarPreNurserySecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarPreNurserySecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarPreNurserySecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarPreNurserySecD' style={schoolClassData.includes('MarPreNurserySecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Pre Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarPreNurserySecD.error} name='subNamesMarPreNurserySecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarPreNurserySecD">Select Subjects for English Medium Pre Nursery Section D</InputLabel>
                                                        <Select labelId="subForMarPreNurserySecD" name='subNamesMarPreNurserySecD' id="multsubForMarPreNurserySecD" multiple value={subjectsOfClass.subNamesMarPreNurserySecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarPreNurserySecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarPreNurserySecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarPreNurserySecE' style={schoolClassData.includes('MarPreNurserySecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Pre Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarPreNurserySecE.error} name='subNamesMarPreNurserySecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarPreNurserySecE">Select Subjects for English Medium Pre Nursery Section E</InputLabel>
                                                        <Select labelId="subForMarPreNurserySecE" name='subNamesMarPreNurserySecE' id="multsubForMarPreNurserySecE" multiple value={subjectsOfClass.subNamesMarPreNurserySecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarPreNurserySecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarPreNurserySecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Nursery */}
                                        <tr className='RowEngNurserySecA' style={schoolClassData.includes('EngNurserySecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngNurserySecA.error} name='subNamesEngNurserySecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngNurserySecA">Select Subjects for English Medium Nursery Section A</InputLabel>
                                                        <Select labelId="subForEngNurserySecA" name='subNamesEngNurserySecA' id="multsubForEngNurserySecA" multiple value={subjectsOfClass.subNamesEngNurserySecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngNurserySecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngNurserySecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngNurserySecB' style={schoolClassData.includes('EngNurserySecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngNurserySecB.error} name='subNamesEngNurserySecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngNurserySecB">Select Subjects for English Medium Nursery Section B</InputLabel>
                                                        <Select labelId="subForEngNurserySecB" name='subNamesEngNurserySecB' id="multsubForEngNurserySecB" multiple value={subjectsOfClass.subNamesEngNurserySecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngNurserySecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngNurserySecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngNurserySecC' style={schoolClassData.includes('EngNurserySecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngNurserySecC.error} name='subNamesEngNurserySecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngNurserySecC">Select Subjects for English Medium Nursery Section C</InputLabel>
                                                        <Select labelId="subForEngNurserySecC" name='subNamesEngNurserySecC' id="multsubForEngNurserySecC" multiple value={subjectsOfClass.subNamesEngNurserySecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngNurserySecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngNurserySecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngNurserySecD' style={schoolClassData.includes('EngNurserySecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngNurserySecD.error} name='subNamesEngNurserySecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngNurserySecD">Select Subjects for English Medium Nursery Section D</InputLabel>
                                                        <Select labelId="subForEngNurserySecD" name='subNamesEngNurserySecD' id="multsubForEngNurserySecD" multiple value={subjectsOfClass.subNamesEngNurserySecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngNurserySecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngNurserySecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngNurserySecE' style={schoolClassData.includes('EngNurserySecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngNurserySecE.error} name='subNamesEngNurserySecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngNurserySecE">Select Subjects for English Medium Nursery Section E</InputLabel>
                                                        <Select labelId="subForEngNurserySecE" name='subNamesEngNurserySecE' id="multsubForEngNurserySecE" multiple value={subjectsOfClass.subNamesEngNurserySecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngNurserySecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngNurserySecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarNurserySecA' style={schoolClassData.includes('MarNurserySecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarNurserySecA.error} name='subNamesMarNurserySecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarNurserySecA">Select Subjects for English Medium Nursery Section A</InputLabel>
                                                        <Select labelId="subForMarNurserySecA" name='subNamesMarNurserySecA' id="multsubForMarNurserySecA" multiple value={subjectsOfClass.subNamesMarNurserySecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarNurserySecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarNurserySecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarNurserySecB' style={schoolClassData.includes('MarNurserySecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarNurserySecB.error} name='subNamesMarNurserySecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarNurserySecB">Select Subjects for English Medium Nursery Section B</InputLabel>
                                                        <Select labelId="subForMarNurserySecB" name='subNamesMarNurserySecB' id="multsubForMarNurserySecB" multiple value={subjectsOfClass.subNamesMarNurserySecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarNurserySecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarNurserySecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarNurserySecC' style={schoolClassData.includes('MarNurserySecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarNurserySecC.error} name='subNamesMarNurserySecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarNurserySecC">Select Subjects for English Medium Nursery Section C</InputLabel>
                                                        <Select labelId="subForMarNurserySecC" name='subNamesMarNurserySecC' id="multsubForMarNurserySecC" multiple value={subjectsOfClass.subNamesMarNurserySecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarNurserySecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarNurserySecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarNurserySecD' style={schoolClassData.includes('MarNurserySecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarNurserySecD.error} name='subNamesMarNurserySecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarNurserySecD">Select Subjects for English Medium Nursery Section D</InputLabel>
                                                        <Select labelId="subForMarNurserySecD" name='subNamesMarNurserySecD' id="multsubForMarNurserySecD" multiple value={subjectsOfClass.subNamesMarNurserySecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarNurserySecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarNurserySecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarNurserySecE' style={schoolClassData.includes('MarNurserySecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Nursery</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarNurserySecE.error} name='subNamesMarNurserySecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarNurserySecE">Select Subjects for English Medium Nursery Section E</InputLabel>
                                                        <Select labelId="subForMarNurserySecE" name='subNamesMarNurserySecE' id="multsubForMarNurserySecE" multiple value={subjectsOfClass.subNamesMarNurserySecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarNurserySecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarNurserySecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* KG1 */}
                                        <tr className='RowEngKG1SecA' style={schoolClassData.includes('EngKG1SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-1(KG-1)</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngKG1SecA.error} name='subNamesEngKG1SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngKG1SecA">Select Subjects for English Medium KG-1 Section A</InputLabel>
                                                        <Select labelId="subForEngKG1SecA" name='subNamesEngKG1SecA' id="multsubForEngKG1SecA" multiple value={subjectsOfClass.subNamesEngKG1SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngKG1SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngKG1SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngKG1SecB' style={schoolClassData.includes('EngKG1SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-1(KG-1)</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngKG1SecB.error} name='subNamesEngKG1SecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngKG1SecB">Select Subjects for English Medium KG-1 Section B</InputLabel>
                                                        <Select labelId="subForEngKG1SecB" name='subNamesEngKG1SecB' id="multsubForEngKG1SecB" multiple value={subjectsOfClass.subNamesEngKG1SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngKG1SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngKG1SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngKG1SecC' style={schoolClassData.includes('EngKG1SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-1(KG-1)</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngKG1SecC.error} name='subNamesEngKG1SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngKG1SecC">Select Subjects for English Medium KG-1 Section C</InputLabel>
                                                        <Select labelId="subForEngKG1SecC" name='subNamesEngKG1SecC' id="multsubForEngKG1SecC" multiple value={subjectsOfClass.subNamesEngKG1SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngKG1SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngKG1SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngKG1SecD' style={schoolClassData.includes('EngKG1SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-1(KG-1)</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngKG1SecD.error} name='subNamesEngKG1SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngKG1SecD">Select Subjects for English Medium KG-1 Section D</InputLabel>
                                                        <Select labelId="subForEngKG1SecD" name='subNamesEngKG1SecD' id="multsubForEngKG1SecD" multiple value={subjectsOfClass.subNamesEngKG1SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngKG1SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngKG1SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngKG1SecE' style={schoolClassData.includes('EngKG1SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-1(KG-1)</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngKG1SecE.error} name='subNamesEngKG1SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngKG1SecE">Select Subjects for English Medium KG-1 Section E</InputLabel>
                                                        <Select labelId="subForEngKG1SecE" name='subNamesEngKG1SecE' id="multsubForEngKG1SecE" multiple value={subjectsOfClass.subNamesEngKG1SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngKG1SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngKG1SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarKG1SecA' style={schoolClassData.includes('MarKG1SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-1(KG-1)</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarKG1SecA.error} name='subNamesMarKG1SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarKG1SecA">Select Subjects for English Medium KG-1 Section A</InputLabel>
                                                        <Select labelId="subForMarKG1SecA" name='subNamesMarKG1SecA' id="multsubForMarKG1SecA" multiple value={subjectsOfClass.subNamesMarKG1SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarKG1SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarKG1SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarKG1SecB' style={schoolClassData.includes('MarKG1SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-1(KG-1)</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarKG1SecB.error} name='subNamesMarKG1SecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarKG1SecB">Select Subjects for English Medium KG-1 Section B</InputLabel>
                                                        <Select labelId="subForMarKG1SecB" name='subNamesMarKG1SecB' id="multsubForMarKG1SecB" multiple value={subjectsOfClass.subNamesMarKG1SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarKG1SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarKG1SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarKG1SecC' style={schoolClassData.includes('MarKG1SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-1(KG-1)</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarKG1SecC.error} name='subNamesMarKG1SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarKG1SecC">Select Subjects for English Medium KG-1 Section C</InputLabel>
                                                        <Select labelId="subForMarKG1SecC" name='subNamesMarKG1SecC' id="multsubForMarKG1SecC" multiple value={subjectsOfClass.subNamesMarKG1SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarKG1SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarKG1SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarKG1SecD' style={schoolClassData.includes('MarKG1SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-1(KG-1)</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarKG1SecD.error} name='subNamesMarKG1SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarKG1SecD">Select Subjects for English Medium KG-1 Section D</InputLabel>
                                                        <Select labelId="subForMarKG1SecD" name='subNamesMarKG1SecD' id="multsubForMarKG1SecD" multiple value={subjectsOfClass.subNamesMarKG1SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarKG1SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarKG1SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarKG1SecE' style={schoolClassData.includes('MarKG1SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-1(KG-1)</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarKG1SecE.error} name='subNamesMarKG1SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarKG1SecE">Select Subjects for English Medium KG-1 Section E</InputLabel>
                                                        <Select labelId="subForMarKG1SecE" name='subNamesMarKG1SecE' id="multsubForMarKG1SecE" multiple value={subjectsOfClass.subNamesMarKG1SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarKG1SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarKG1SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* KG2 */}
                                        <tr className='RowEngKG2SecA' style={schoolClassData.includes('EngKG2SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-2(KG-2)</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngKG2SecA.error} name='subNamesEngKG2SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngKG2SecA">Select Subjects for English Medium KG-2 Section A</InputLabel>
                                                        <Select labelId="subForEngKG2SecA" name='subNamesEngKG2SecA' id="multsubForEngKG2SecA" multiple value={subjectsOfClass.subNamesEngKG2SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngKG2SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngKG2SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngKG2SecB' style={schoolClassData.includes('EngKG2SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-2(KG-2)</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngKG2SecB.error} name='subNamesEngKG2SecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngKG2SecB">Select Subjects for English Medium KG-2 Section B</InputLabel>
                                                        <Select labelId="subForEngKG2SecB" name='subNamesEngKG2SecB' id="multsubForEngKG2SecB" multiple value={subjectsOfClass.subNamesEngKG2SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngKG2SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngKG2SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngKG2SecC' style={schoolClassData.includes('EngKG2SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-2(KG-2)</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngKG2SecC.error} name='subNamesEngKG2SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngKG2SecC">Select Subjects for English Medium KG-2 Section C</InputLabel>
                                                        <Select labelId="subForEngKG2SecC" name='subNamesEngKG2SecC' id="multsubForEngKG2SecC" multiple value={subjectsOfClass.subNamesEngKG2SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngKG2SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngKG2SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngKG2SecD' style={schoolClassData.includes('EngKG2SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-2(KG-2)</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngKG2SecD.error} name='subNamesEngKG2SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngKG2SecD">Select Subjects for English Medium KG-2 Section D</InputLabel>
                                                        <Select labelId="subForEngKG2SecD" name='subNamesEngKG2SecD' id="multsubForEngKG2SecD" multiple value={subjectsOfClass.subNamesEngKG2SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngKG2SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngKG2SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngKG2SecE' style={schoolClassData.includes('EngKG2SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-2(KG-2)</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngKG2SecE.error} name='subNamesEngKG2SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngKG2SecE">Select Subjects for English Medium KG-2 Section E</InputLabel>
                                                        <Select labelId="subForEngKG2SecE" name='subNamesEngKG2SecE' id="multsubForEngKG2SecE" multiple value={subjectsOfClass.subNamesEngKG2SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngKG2SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngKG2SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarKG2SecA' style={schoolClassData.includes('MarKG2SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-2(KG-2)</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarKG2SecA.error} name='subNamesMarKG2SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarKG2SecA">Select Subjects for English Medium KG-2 Section A</InputLabel>
                                                        <Select labelId="subForMarKG2SecA" name='subNamesMarKG2SecA' id="multsubForMarKG2SecA" multiple value={subjectsOfClass.subNamesMarKG2SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarKG2SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarKG2SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarKG2SecB' style={schoolClassData.includes('MarKG2SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-2(KG-2)</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarKG2SecB.error} name='subNamesMarKG2SecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarKG2SecB">Select Subjects for English Medium KG-2 Section B</InputLabel>
                                                        <Select labelId="subForMarKG2SecB" name='subNamesMarKG2SecB' id="multsubForMarKG2SecB" multiple value={subjectsOfClass.subNamesMarKG2SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarKG2SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarKG2SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarKG2SecC' style={schoolClassData.includes('MarKG2SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-2(KG-2)</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarKG2SecC.error} name='subNamesMarKG2SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarKG2SecC">Select Subjects for English Medium KG-2 Section C</InputLabel>
                                                        <Select labelId="subForMarKG2SecC" name='subNamesMarKG2SecC' id="multsubForMarKG2SecC" multiple value={subjectsOfClass.subNamesMarKG2SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarKG2SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarKG2SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarKG2SecD' style={schoolClassData.includes('MarKG2SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-2(KG-2)</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarKG2SecD.error} name='subNamesMarKG2SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarKG2SecD">Select Subjects for English Medium KG-2 Section D</InputLabel>
                                                        <Select labelId="subForMarKG2SecD" name='subNamesMarKG2SecD' id="multsubForMarKG2SecD" multiple value={subjectsOfClass.subNamesMarKG2SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarKG2SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarKG2SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarKG2SecE' style={schoolClassData.includes('MarKG2SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Kindergarten-2(KG-2)</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarKG2SecE.error} name='subNamesMarKG2SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarKG2SecE">Select Subjects for English Medium KG-2 Section E</InputLabel>
                                                        <Select labelId="subForMarKG2SecE" name='subNamesMarKG2SecE' id="multsubForMarKG2SecE" multiple value={subjectsOfClass.subNamesMarKG2SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarKG2SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarKG2SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Class 1 */}
                                        <tr className='RowEngClass1SecA' style={schoolClassData.includes('EngClass1SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 1</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass1SecA.error} name='subNamesEngClass1SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass1SecA">Select Subjects for English Medium Class 1 Section A</InputLabel>
                                                        <Select labelId="subForEngClass1SecA" name="subNamesEngClass1SecA" id="multsubForEngClass1SecA" multiple value={subjectsOfClass.subNamesEngClass1SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass1SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass1SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass1SecB' style={schoolClassData.includes('EngClass1SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 1</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass1SecB.error} name='subNamesEngClass1SecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass1SecB">Select Subjects for English Medium Class 1 Section B</InputLabel>
                                                        <Select labelId="subForEngClass1SecB" name="subNamesEngClass1SecB" id="multsubForEngClass1SecB" multiple value={subjectsOfClass.subNamesEngClass1SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass1SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass1SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass1SecC' style={schoolClassData.includes('EngClass1SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 1</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass1SecC.error} name='subNamesEngClass1SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass1SecC">Select Subjects for English Medium Class 1 Section C</InputLabel>
                                                        <Select labelId="subForEngClass1SecC" name="subNamesEngClass1SecC" id="multsubForEngClass1SecC" multiple value={subjectsOfClass.subNamesEngClass1SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass1SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass1SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass1SecD' style={schoolClassData.includes('EngClass1SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 1</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass1SecD.error} name='subNamesEngClass1SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass1SecD">Select Subjects for English Medium Class 1 Section D</InputLabel>
                                                        <Select labelId="subForEngClass1SecD" name="subNamesEngClass1SecD" id="multsubForEngClass1SecD" multiple value={subjectsOfClass.subNamesEngClass1SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass1SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass1SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass1SecE' style={schoolClassData.includes('EngClass1SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 1</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass1SecE.error} name='subNamesEngClass1SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass1SecE">Select Subjects for English Medium Class 1 Section E</InputLabel>
                                                        <Select labelId="subForEngClass1SecE" name="subNamesEngClass1SecE" id="multsubForEngClass1SecE" multiple value={subjectsOfClass.subNamesEngClass1SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass1SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass1SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass1SecA' style={schoolClassData.includes('MarClass1SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 1</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass1SecA.error} name='subNamesMarClass1SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass1SecA">Select Subjects for Marathi Medium Class 1 Section A</InputLabel>
                                                        <Select labelId="subForMarClass1SecA" name="subNamesMarClass1SecA" id="multsubForMarClass1SecA" multiple value={subjectsOfClass.subNamesMarClass1SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass1SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass1SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass1SecB' style={schoolClassData.includes('MarClass1SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 1</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <FormControl error={showEnteredSchoolSub.subNamesMarClass1SecB.error} name='subNamesMarClass1SecB' sx={{ m: 1, width: 530 }}>
                                                    <InputLabel id="subForMarClass1SecB">Select Subjects for Marathi Medium Class 1 Section B</InputLabel>
                                                    <Select labelId="subForMarClass1SecB" name="subNamesMarClass1SecB" id="multsubForMarClass1SecB" multiple value={subjectsOfClass.subNamesMarClass1SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass1SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                        {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                    </Select>
                                                    <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass1SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                </FormControl>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass1SecC' style={schoolClassData.includes('MarClass1SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 1</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass1SecC.error} name='subNamesMarClass1SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass1SecC">Select Subjects for Marathi Medium Class 1 Section C</InputLabel>
                                                        <Select labelId="subForMarClass1SecC" name="subNamesMarClass1SecC" id="multsubForMarClass1SecC" multiple value={subjectsOfClass.subNamesMarClass1SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass1SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass1SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass1SecD' style={schoolClassData.includes('MarClass1SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 1</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass1SecD.error} name='subNamesMarClass1SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass1SecD">Select Subjects for Marathi Medium Class 1 Section D</InputLabel>
                                                        <Select labelId="subForMarClass1SecD" name="subNamesMarClass1SecD" id="multsubForMarClass1SecD" multiple value={subjectsOfClass.subNamesMarClass1SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass1SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass1SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass1SecE' style={schoolClassData.includes('MarClass1SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 1</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass1SecE.error} name='subNamesMarClass1SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass1SecE">Select Subjects for Marathi Medium Class 1 Section E</InputLabel>
                                                        <Select labelId="subForMarClass1SecE" name="subNamesMarClass1SecE" id="multsubForMarClass1SecE" multiple value={subjectsOfClass.subNamesMarClass1SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass1SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass1SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Class 2 */}
                                        <tr className='RowEngClass2SecA' style={schoolClassData.includes('EngClass2SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 2</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass2SecA.error} name='subNamesEngClass2SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass2SecA">Select Subjects for English Medium Class 2 Section A</InputLabel>
                                                        <Select labelId="subForEngClass2SecA" name="subNamesEngClass2SecA" id="multsubForEngClass2SecA" multiple value={subjectsOfClass.subNamesEngClass2SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass2SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass2SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass2SecB' style={schoolClassData.includes('EngClass2SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 2</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass2SecB.error} name='subNamesEngClass2SecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass2SecB">Select Subjects for English Medium Class 2 Section B</InputLabel>
                                                        <Select labelId="subForEngClass2SecB" name="subNamesEngClass2SecB" id="multsubForEngClass2SecB" multiple value={subjectsOfClass.subNamesEngClass2SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass2SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass2SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass2SecC' style={schoolClassData.includes('EngClass2SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 2</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass2SecC.error} name='subNamesEngClass2SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass2SecC">Select Subjects for English Medium Class 2 Section C</InputLabel>
                                                        <Select labelId="subForEngClass2SecC" name="subNamesEngClass2SecC" id="multsubForEngClass2SecC" multiple value={subjectsOfClass.subNamesEngClass2SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass2SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass2SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass2SecD' style={schoolClassData.includes('EngClass2SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 2</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass2SecD.error} name='subNamesEngClass2SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass2SecD">Select Subjects for English Medium Class 2 Section D</InputLabel>
                                                        <Select labelId="subForEngClass2SecD" name="subNamesEngClass2SecD" id="multsubForEngClass2SecD" multiple value={subjectsOfClass.subNamesEngClass2SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass2SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass2SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass2SecE' style={schoolClassData.includes('EngClass2SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 2</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass2SecE.error} name='subNamesEngClass2SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass2SecE">Select Subjects for English Medium Class 2 Section E</InputLabel>
                                                        <Select labelId="subForEngClass2SecE" name="subNamesEngClass2SecE" id="multsubForEngClass2SecE" multiple value={subjectsOfClass.subNamesEngClass2SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass2SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass2SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass2SecA' style={schoolClassData.includes('MarClass2SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 2</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass2SecA.error} name='subNamesMarClass2SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass2SecA">Select Subjects for Marathi Medium Class 2 Section A</InputLabel>
                                                        <Select labelId="subForMarClass2SecA" name="subNamesMarClass2SecA" id="multsubForMarClass2SecA" multiple value={subjectsOfClass.subNamesMarClass2SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass2SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass2SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass2SecB' style={schoolClassData.includes('MarClass2SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 2</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <FormControl error={showEnteredSchoolSub.subNamesMarClass2SecB.error} name='subNamesMarClass2SecB' sx={{ m: 1, width: 530 }}>
                                                    <InputLabel id="subForMarClass2SecB">Select Subjects for Marathi Medium Class 2 Section B</InputLabel>
                                                    <Select labelId="subForMarClass2SecB" name="subNamesMarClass2SecB" id="multsubForMarClass2SecB" multiple value={subjectsOfClass.subNamesMarClass2SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass2SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                        {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                    </Select>
                                                    <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass2SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                </FormControl>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass2SecC' style={schoolClassData.includes('MarClass2SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 2</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass2SecC.error} name='subNamesMarClass2SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass2SecC">Select Subjects for Marathi Medium Class 2 Section C</InputLabel>
                                                        <Select labelId="subForMarClass2SecC" name="subNamesMarClass2SecC" id="multsubForMarClass2SecC" multiple value={subjectsOfClass.subNamesMarClass2SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass2SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass2SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass2SecD' style={schoolClassData.includes('MarClass2SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 2</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass2SecD.error} name='subNamesMarClass2SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass2SecD">Select Subjects for Marathi Medium Class 2 Section D</InputLabel>
                                                        <Select labelId="subForMarClass2SecD" name="subNamesMarClass2SecD" id="multsubForMarClass2SecD" multiple value={subjectsOfClass.subNamesMarClass2SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass2SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass2SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass2SecE' style={schoolClassData.includes('MarClass2SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 2</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass2SecE.error} name='subNamesMarClass2SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass2SecE">Select Subjects for Marathi Medium Class 2 Section E</InputLabel>
                                                        <Select labelId="subForMarClass2SecE" name="subNamesMarClass2SecE" id="multsubForMarClass2SecE" multiple value={subjectsOfClass.subNamesMarClass2SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass2SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass2SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Class 3 */}
                                        <tr className='RowEngClass3SecA' style={schoolClassData.includes('EngClass3SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 3</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass3SecA.error} name='subNamesEngClass3SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass3SecA">Select Subjects for English Medium Class 3 Section A</InputLabel>
                                                        <Select labelId="subForEngClass3SecA" name="subNamesEngClass3SecA" id="multsubForEngClass3SecA" multiple value={subjectsOfClass.subNamesEngClass3SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass3SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass3SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass3SecB' style={schoolClassData.includes('EngClass3SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 3</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass3SecB.error} name='subNamesEngClass3SecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass3SecB">Select Subjects for English Medium Class 3 Section B</InputLabel>
                                                        <Select labelId="subForEngClass3SecB" name="subNamesEngClass3SecB" id="multsubForEngClass3SecB" multiple value={subjectsOfClass.subNamesEngClass3SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass3SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass3SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass3SecC' style={schoolClassData.includes('EngClass3SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 3</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass3SecC.error} name='subNamesEngClass3SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass3SecC">Select Subjects for English Medium Class 3 Section C</InputLabel>
                                                        <Select labelId="subForEngClass3SecC" name="subNamesEngClass3SecC" id="multsubForEngClass3SecC" multiple value={subjectsOfClass.subNamesEngClass3SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass3SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass3SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass3SecD' style={schoolClassData.includes('EngClass3SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 3</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass3SecD.error} name='subNamesEngClass3SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass3SecD">Select Subjects for English Medium Class 3 Section D</InputLabel>
                                                        <Select labelId="subForEngClass3SecD" name="subNamesEngClass3SecD" id="multsubForEngClass3SecD" multiple value={subjectsOfClass.subNamesEngClass3SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass3SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass3SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass3SecE' style={schoolClassData.includes('EngClass3SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 3</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass3SecE.error} name='subNamesEngClass3SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass3SecE">Select Subjects for English Medium Class 3 Section E</InputLabel>
                                                        <Select labelId="subForEngClass3SecE" name="subNamesEngClass3SecE" id="multsubForEngClass3SecE" multiple value={subjectsOfClass.subNamesEngClass3SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass3SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass3SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass3SecA' style={schoolClassData.includes('MarClass3SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 3</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass3SecA.error} name='subNamesMarClass3SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass3SecA">Select Subjects for Marathi Medium Class 3 Section A</InputLabel>
                                                        <Select labelId="subForMarClass3SecA" name="subNamesMarClass3SecA" id="multsubForMarClass3SecA" multiple value={subjectsOfClass.subNamesMarClass3SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass3SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass3SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass3SecB' style={schoolClassData.includes('MarClass3SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 3</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <FormControl error={showEnteredSchoolSub.subNamesMarClass3SecB.error} name='subNamesMarClass3SecB' sx={{ m: 1, width: 530 }}>
                                                    <InputLabel id="subForMarClass3SecB">Select Subjects for Marathi Medium Class 3 Section B</InputLabel>
                                                    <Select labelId="subForMarClass3SecB" name="subNamesMarClass3SecB" id="multsubForMarClass3SecB" multiple value={subjectsOfClass.subNamesMarClass3SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass3SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                        {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                    </Select>
                                                    <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass3SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                </FormControl>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass3SecC' style={schoolClassData.includes('MarClass3SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 3</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass3SecC.error} name='subNamesMarClass3SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass3SecC">Select Subjects for Marathi Medium Class 3 Section C</InputLabel>
                                                        <Select labelId="subForMarClass3SecC" name="subNamesMarClass3SecC" id="multsubForMarClass3SecC" multiple value={subjectsOfClass.subNamesMarClass3SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass3SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass3SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass3SecD' style={schoolClassData.includes('MarClass3SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 3</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass3SecD.error} name='subNamesMarClass3SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass3SecD">Select Subjects for Marathi Medium Class 3 Section D</InputLabel>
                                                        <Select labelId="subForMarClass3SecD" name="subNamesMarClass3SecD" id="multsubForMarClass3SecD" multiple value={subjectsOfClass.subNamesMarClass3SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass3SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass3SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass3SecE' style={schoolClassData.includes('MarClass3SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 3</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass3SecE.error} name='subNamesMarClass3SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass3SecE">Select Subjects for Marathi Medium Class 3 Section E</InputLabel>
                                                        <Select labelId="subForMarClass3SecE" name="subNamesMarClass3SecE" id="multsubForMarClass3SecE" multiple value={subjectsOfClass.subNamesMarClass3SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass3SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass3SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Class 4 */}
                                        <tr className='RowEngClass4SecA' style={schoolClassData.includes('EngClass4SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 4</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass4SecA.error} name='subNamesEngClass4SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass4SecA">Select Subjects for English Medium Class 4 Section A</InputLabel>
                                                        <Select labelId="subForEngClass4SecA" name="subNamesEngClass4SecA" id="multsubForEngClass4SecA" multiple value={subjectsOfClass.subNamesEngClass4SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass4SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass4SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass4SecB' style={schoolClassData.includes('EngClass4SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 4</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass4SecB.error} name='subNamesEngClass4SecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass4SecB">Select Subjects for English Medium Class 4 Section B</InputLabel>
                                                        <Select labelId="subForEngClass4SecB" name="subNamesEngClass4SecB" id="multsubForEngClass4SecB" multiple value={subjectsOfClass.subNamesEngClass4SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass4SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass4SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass4SecC' style={schoolClassData.includes('EngClass4SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 4</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass4SecC.error} name='subNamesEngClass4SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass4SecC">Select Subjects for English Medium Class 4 Section C</InputLabel>
                                                        <Select labelId="subForEngClass4SecC" name="subNamesEngClass4SecC" id="multsubForEngClass4SecC" multiple value={subjectsOfClass.subNamesEngClass4SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass4SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass4SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass4SecD' style={schoolClassData.includes('EngClass4SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 4</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass4SecD.error} name='subNamesEngClass4SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass4SecD">Select Subjects for English Medium Class 4 Section D</InputLabel>
                                                        <Select labelId="subForEngClass4SecD" name="subNamesEngClass4SecD" id="multsubForEngClass4SecD" multiple value={subjectsOfClass.subNamesEngClass4SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass4SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass4SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass4SecE' style={schoolClassData.includes('EngClass4SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 4</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass4SecE.error} name='subNamesEngClass4SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass4SecE">Select Subjects for English Medium Class 4 Section E</InputLabel>
                                                        <Select labelId="subForEngClass4SecE" name="subNamesEngClass4SecE" id="multsubForEngClass4SecE" multiple value={subjectsOfClass.subNamesEngClass4SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass4SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass4SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass4SecA' style={schoolClassData.includes('MarClass4SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 4</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass4SecA.error} name='subNamesMarClass4SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass4SecA">Select Subjects for Marathi Medium Class 4 Section A</InputLabel>
                                                        <Select labelId="subForMarClass4SecA" name="subNamesMarClass4SecA" id="multsubForMarClass4SecA" multiple value={subjectsOfClass.subNamesMarClass4SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass4SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass4SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass4SecB' style={schoolClassData.includes('MarClass4SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 4</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <FormControl error={showEnteredSchoolSub.subNamesMarClass4SecB.error} name='subNamesMarClass4SecB' sx={{ m: 1, width: 530 }}>
                                                    <InputLabel id="subForMarClass4SecB">Select Subjects for Marathi Medium Class 4 Section B</InputLabel>
                                                    <Select labelId="subForMarClass4SecB" name="subNamesMarClass4SecB" id="multsubForMarClass4SecB" multiple value={subjectsOfClass.subNamesMarClass4SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass4SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                        {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                    </Select>
                                                    <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass4SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                </FormControl>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass4SecC' style={schoolClassData.includes('MarClass4SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 4</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass4SecC.error} name='subNamesMarClass4SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass4SecC">Select Subjects for Marathi Medium Class 4 Section C</InputLabel>
                                                        <Select labelId="subForMarClass4SecC" name="subNamesMarClass4SecC" id="multsubForMarClass4SecC" multiple value={subjectsOfClass.subNamesMarClass4SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass4SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass4SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass4SecD' style={schoolClassData.includes('MarClass4SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 4</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass4SecD.error} name='subNamesMarClass4SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass4SecD">Select Subjects for Marathi Medium Class 4 Section D</InputLabel>
                                                        <Select labelId="subForMarClass4SecD" name="subNamesMarClass4SecD" id="multsubForMarClass4SecD" multiple value={subjectsOfClass.subNamesMarClass4SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass4SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass4SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass4SecE' style={schoolClassData.includes('MarClass4SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 4</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass4SecE.error} name='subNamesMarClass4SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass4SecE">Select Subjects for Marathi Medium Class 4 Section E</InputLabel>
                                                        <Select labelId="subForMarClass4SecE" name="subNamesMarClass4SecE" id="multsubForMarClass4SecE" multiple value={subjectsOfClass.subNamesMarClass4SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass4SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass4SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Class 5 */}
                                        <tr className='RowEngClass5SecA' style={schoolClassData.includes('EngClass5SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 5</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass5SecA.error} name='subNamesEngClass5SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass5SecA">Select Subjects for English Medium Class 5 Section A</InputLabel>
                                                        <Select labelId="subForEngClass5SecA" name="subNamesEngClass5SecA" id="multsubForEngClass5SecA" multiple value={subjectsOfClass.subNamesEngClass5SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass5SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass5SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass5SecB' style={schoolClassData.includes('EngClass5SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 5</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass5SecB.error} name='subNamesEngClass5SecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass5SecB">Select Subjects for English Medium Class 5 Section B</InputLabel>
                                                        <Select labelId="subForEngClass5SecB" name="subNamesEngClass5SecB" id="multsubForEngClass5SecB" multiple value={subjectsOfClass.subNamesEngClass5SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass5SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass5SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass5SecC' style={schoolClassData.includes('EngClass5SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 5</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass5SecC.error} name='subNamesEngClass5SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass5SecC">Select Subjects for English Medium Class 5 Section C</InputLabel>
                                                        <Select labelId="subForEngClass5SecC" name="subNamesEngClass5SecC" id="multsubForEngClass5SecC" multiple value={subjectsOfClass.subNamesEngClass5SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass5SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass5SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass5SecD' style={schoolClassData.includes('EngClass5SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 5</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass5SecD.error} name='subNamesEngClass5SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass5SecD">Select Subjects for English Medium Class 5 Section D</InputLabel>
                                                        <Select labelId="subForEngClass5SecD" name="subNamesEngClass5SecD" id="multsubForEngClass5SecD" multiple value={subjectsOfClass.subNamesEngClass5SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass5SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass5SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass5SecE' style={schoolClassData.includes('EngClass5SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 5</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass5SecE.error} name='subNamesEngClass5SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass5SecE">Select Subjects for English Medium Class 5 Section E</InputLabel>
                                                        <Select labelId="subForEngClass5SecE" name="subNamesEngClass5SecE" id="multsubForEngClass5SecE" multiple value={subjectsOfClass.subNamesEngClass5SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass5SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass5SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass5SecA' style={schoolClassData.includes('MarClass5SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 5</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass5SecA.error} name='subNamesMarClass5SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass5SecA">Select Subjects for Marathi Medium Class 5 Section A</InputLabel>
                                                        <Select labelId="subForMarClass5SecA" name="subNamesMarClass5SecA" id="multsubForMarClass5SecA" multiple value={subjectsOfClass.subNamesMarClass5SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass5SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass5SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass5SecB' style={schoolClassData.includes('MarClass5SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 5</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <FormControl error={showEnteredSchoolSub.subNamesMarClass5SecB.error} name='subNamesMarClass5SecB' sx={{ m: 1, width: 530 }}>
                                                    <InputLabel id="subForMarClass5SecB">Select Subjects for Marathi Medium Class 5 Section B</InputLabel>
                                                    <Select labelId="subForMarClass5SecB" name="subNamesMarClass5SecB" id="multsubForMarClass5SecB" multiple value={subjectsOfClass.subNamesMarClass5SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass5SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                        {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                    </Select>
                                                    <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass5SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                </FormControl>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass5SecC' style={schoolClassData.includes('MarClass5SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 5</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass5SecC.error} name='subNamesMarClass5SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass5SecC">Select Subjects for Marathi Medium Class 5 Section C</InputLabel>
                                                        <Select labelId="subForMarClass5SecC" name="subNamesMarClass5SecC" id="multsubForMarClass5SecC" multiple value={subjectsOfClass.subNamesMarClass5SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass5SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass5SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass5SecD' style={schoolClassData.includes('MarClass5SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 5</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass5SecD.error} name='subNamesMarClass5SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass5SecD">Select Subjects for Marathi Medium Class 5 Section D</InputLabel>
                                                        <Select labelId="subForMarClass5SecD" name="subNamesMarClass5SecD" id="multsubForMarClass5SecD" multiple value={subjectsOfClass.subNamesMarClass5SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass5SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass5SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass5SecE' style={schoolClassData.includes('MarClass5SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 5</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass5SecE.error} name='subNamesMarClass5SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass5SecE">Select Subjects for Marathi Medium Class 5 Section E</InputLabel>
                                                        <Select labelId="subForMarClass5SecE" name="subNamesMarClass5SecE" id="multsubForMarClass5SecE" multiple value={subjectsOfClass.subNamesMarClass5SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass5SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass5SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Class 6 */}
                                        <tr className='RowEngClass6SecA' style={schoolClassData.includes('EngClass6SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 6</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass6SecA.error} name='subNamesEngClass6SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass6SecA">Select Subjects for English Medium Class 6 Section A</InputLabel>
                                                        <Select labelId="subForEngClass6SecA" name="subNamesEngClass6SecA" id="multsubForEngClass6SecA" multiple value={subjectsOfClass.subNamesEngClass6SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass6SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass6SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass6SecB' style={schoolClassData.includes('EngClass6SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 6</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass6SecB.error} name='subNamesEngClass6SecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass6SecB">Select Subjects for English Medium Class 6 Section B</InputLabel>
                                                        <Select labelId="subForEngClass6SecB" name="subNamesEngClass6SecB" id="multsubForEngClass6SecB" multiple value={subjectsOfClass.subNamesEngClass6SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass6SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass6SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass6SecC' style={schoolClassData.includes('EngClass6SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 6</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass6SecC.error} name='subNamesEngClass6SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass6SecC">Select Subjects for English Medium Class 6 Section C</InputLabel>
                                                        <Select labelId="subForEngClass6SecC" name="subNamesEngClass6SecC" id="multsubForEngClass6SecC" multiple value={subjectsOfClass.subNamesEngClass6SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass6SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass6SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass6SecD' style={schoolClassData.includes('EngClass6SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 6</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass6SecD.error} name='subNamesEngClass6SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass6SecD">Select Subjects for English Medium Class 6 Section D</InputLabel>
                                                        <Select labelId="subForEngClass6SecD" name="subNamesEngClass6SecD" id="multsubForEngClass6SecD" multiple value={subjectsOfClass.subNamesEngClass6SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass6SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass6SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass6SecE' style={schoolClassData.includes('EngClass6SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 6</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass6SecE.error} name='subNamesEngClass6SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass6SecE">Select Subjects for English Medium Class 6 Section E</InputLabel>
                                                        <Select labelId="subForEngClass6SecE" name="subNamesEngClass6SecE" id="multsubForEngClass6SecE" multiple value={subjectsOfClass.subNamesEngClass6SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass6SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass6SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass6SecA' style={schoolClassData.includes('MarClass6SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 6</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass6SecA.error} name='subNamesMarClass6SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass6SecA">Select Subjects for Marathi Medium Class 6 Section A</InputLabel>
                                                        <Select labelId="subForMarClass6SecA" name="subNamesMarClass6SecA" id="multsubForMarClass6SecA" multiple value={subjectsOfClass.subNamesMarClass6SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass6SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass6SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass6SecB' style={schoolClassData.includes('MarClass6SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 6</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <FormControl error={showEnteredSchoolSub.subNamesMarClass6SecB.error} name='subNamesMarClass6SecB' sx={{ m: 1, width: 530 }}>
                                                    <InputLabel id="subForMarClass6SecB">Select Subjects for Marathi Medium Class 6 Section B</InputLabel>
                                                    <Select labelId="subForMarClass6SecB" name="subNamesMarClass6SecB" id="multsubForMarClass6SecB" multiple value={subjectsOfClass.subNamesMarClass6SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass6SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                        {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                    </Select>
                                                    <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass6SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                </FormControl>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass6SecC' style={schoolClassData.includes('MarClass6SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 6</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass6SecC.error} name='subNamesMarClass6SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass6SecC">Select Subjects for Marathi Medium Class 6 Section C</InputLabel>
                                                        <Select labelId="subForMarClass6SecC" name="subNamesMarClass6SecC" id="multsubForMarClass6SecC" multiple value={subjectsOfClass.subNamesMarClass6SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass6SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass6SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass6SecD' style={schoolClassData.includes('MarClass6SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 6</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass6SecD.error} name='subNamesMarClass6SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass6SecD">Select Subjects for Marathi Medium Class 6 Section D</InputLabel>
                                                        <Select labelId="subForMarClass6SecD" name="subNamesMarClass6SecD" id="multsubForMarClass6SecD" multiple value={subjectsOfClass.subNamesMarClass6SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass6SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass6SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass6SecE' style={schoolClassData.includes('MarClass6SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 6</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass6SecE.error} name='subNamesMarClass6SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass6SecE">Select Subjects for Marathi Medium Class 6 Section E</InputLabel>
                                                        <Select labelId="subForMarClass6SecE" name="subNamesMarClass6SecE" id="multsubForMarClass6SecE" multiple value={subjectsOfClass.subNamesMarClass6SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass6SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass6SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Class 7 */}
                                        <tr className='RowEngClass7SecA' style={schoolClassData.includes('EngClass7SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 7</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass7SecA.error} name='subNamesEngClass7SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass7SecA">Select Subjects for English Medium Class 7 Section A</InputLabel>
                                                        <Select labelId="subForEngClass7SecA" name="subNamesEngClass7SecA" id="multsubForEngClass7SecA" multiple value={subjectsOfClass.subNamesEngClass7SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass7SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass7SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass7SecB' style={schoolClassData.includes('EngClass7SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 7</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass7SecB.error} name='subNamesEngClass7SecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass7SecB">Select Subjects for English Medium Class 7 Section B</InputLabel>
                                                        <Select labelId="subForEngClass7SecB" name="subNamesEngClass7SecB" id="multsubForEngClass7SecB" multiple value={subjectsOfClass.subNamesEngClass7SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass7SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass7SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass7SecC' style={schoolClassData.includes('EngClass7SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 7</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass7SecC.error} name='subNamesEngClass7SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass7SecC">Select Subjects for English Medium Class 7 Section C</InputLabel>
                                                        <Select labelId="subForEngClass7SecC" name="subNamesEngClass7SecC" id="multsubForEngClass7SecC" multiple value={subjectsOfClass.subNamesEngClass7SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass7SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass7SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass7SecD' style={schoolClassData.includes('EngClass7SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 7</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass7SecD.error} name='subNamesEngClass7SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass7SecD">Select Subjects for English Medium Class 7 Section D</InputLabel>
                                                        <Select labelId="subForEngClass7SecD" name="subNamesEngClass7SecD" id="multsubForEngClass7SecD" multiple value={subjectsOfClass.subNamesEngClass7SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass7SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass7SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass7SecE' style={schoolClassData.includes('EngClass7SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 7</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass7SecE.error} name='subNamesEngClass7SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass7SecE">Select Subjects for English Medium Class 7 Section E</InputLabel>
                                                        <Select labelId="subForEngClass7SecE" name="subNamesEngClass7SecE" id="multsubForEngClass7SecE" multiple value={subjectsOfClass.subNamesEngClass7SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass7SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass7SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass7SecA' style={schoolClassData.includes('MarClass7SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 7</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass7SecA.error} name='subNamesMarClass7SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass7SecA">Select Subjects for Marathi Medium Class 7 Section A</InputLabel>
                                                        <Select labelId="subForMarClass7SecA" name="subNamesMarClass7SecA" id="multsubForMarClass7SecA" multiple value={subjectsOfClass.subNamesMarClass7SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass7SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass7SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass7SecB' style={schoolClassData.includes('MarClass7SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 7</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <FormControl error={showEnteredSchoolSub.subNamesMarClass7SecB.error} name='subNamesMarClass7SecB' sx={{ m: 1, width: 530 }}>
                                                    <InputLabel id="subForMarClass7SecB">Select Subjects for Marathi Medium Class 7 Section B</InputLabel>
                                                    <Select labelId="subForMarClass7SecB" name="subNamesMarClass7SecB" id="multsubForMarClass7SecB" multiple value={subjectsOfClass.subNamesMarClass7SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass7SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                        {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                    </Select>
                                                    <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass7SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                </FormControl>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass7SecC' style={schoolClassData.includes('MarClass7SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 7</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass7SecC.error} name='subNamesMarClass7SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass7SecC">Select Subjects for Marathi Medium Class 7 Section C</InputLabel>
                                                        <Select labelId="subForMarClass7SecC" name="subNamesMarClass7SecC" id="multsubForMarClass7SecC" multiple value={subjectsOfClass.subNamesMarClass7SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass7SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass7SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass7SecD' style={schoolClassData.includes('MarClass7SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 7</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass7SecD.error} name='subNamesMarClass7SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass7SecD">Select Subjects for Marathi Medium Class 7 Section D</InputLabel>
                                                        <Select labelId="subForMarClass7SecD" name="subNamesMarClass7SecD" id="multsubForMarClass7SecD" multiple value={subjectsOfClass.subNamesMarClass7SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass7SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass7SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass7SecE' style={schoolClassData.includes('MarClass7SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 7</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass7SecE.error} name='subNamesMarClass7SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass7SecE">Select Subjects for Marathi Medium Class 7 Section E</InputLabel>
                                                        <Select labelId="subForMarClass7SecE" name="subNamesMarClass7SecE" id="multsubForMarClass7SecE" multiple value={subjectsOfClass.subNamesMarClass7SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass7SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass7SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Class 8 */}
                                        <tr className='RowEngClass8SecA' style={schoolClassData.includes('EngClass8SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 8</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass8SecA.error} name='subNamesEngClass8SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass8SecA">Select Subjects for English Medium Class 8 Section A</InputLabel>
                                                        <Select labelId="subForEngClass8SecA" name="subNamesEngClass8SecA" id="multsubForEngClass8SecA" multiple value={subjectsOfClass.subNamesEngClass8SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass8SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass8SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass8SecB' style={schoolClassData.includes('EngClass8SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 8</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass8SecB.error} name='subNamesEngClass8SecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass8SecB">Select Subjects for English Medium Class 8 Section B</InputLabel>
                                                        <Select labelId="subForEngClass8SecB" name="subNamesEngClass8SecB" id="multsubForEngClass8SecB" multiple value={subjectsOfClass.subNamesEngClass8SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass8SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass8SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass8SecC' style={schoolClassData.includes('EngClass8SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 8</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass8SecC.error} name='subNamesEngClass8SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass8SecC">Select Subjects for English Medium Class 8 Section C</InputLabel>
                                                        <Select labelId="subForEngClass8SecC" name="subNamesEngClass8SecC" id="multsubForEngClass8SecC" multiple value={subjectsOfClass.subNamesEngClass8SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass8SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass8SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass8SecD' style={schoolClassData.includes('EngClass8SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 8</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass8SecD.error} name='subNamesEngClass8SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass8SecD">Select Subjects for English Medium Class 8 Section D</InputLabel>
                                                        <Select labelId="subForEngClass8SecD" name="subNamesEngClass8SecD" id="multsubForEngClass8SecD" multiple value={subjectsOfClass.subNamesEngClass8SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass8SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass8SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass8SecE' style={schoolClassData.includes('EngClass8SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 8</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass8SecE.error} name='subNamesEngClass8SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass8SecE">Select Subjects for English Medium Class 8 Section E</InputLabel>
                                                        <Select labelId="subForEngClass8SecE" name="subNamesEngClass8SecE" id="multsubForEngClass8SecE" multiple value={subjectsOfClass.subNamesEngClass8SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass8SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass8SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass8SecA' style={schoolClassData.includes('MarClass8SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 8</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass8SecA.error} name='subNamesMarClass8SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass8SecA">Select Subjects for Marathi Medium Class 8 Section A</InputLabel>
                                                        <Select labelId="subForMarClass8SecA" name="subNamesMarClass8SecA" id="multsubForMarClass8SecA" multiple value={subjectsOfClass.subNamesMarClass8SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass8SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass8SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass8SecB' style={schoolClassData.includes('MarClass8SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 8</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <FormControl error={showEnteredSchoolSub.subNamesMarClass8SecB.error} name='subNamesMarClass8SecB' sx={{ m: 1, width: 530 }}>
                                                    <InputLabel id="subForMarClass8SecB">Select Subjects for Marathi Medium Class 8 Section B</InputLabel>
                                                    <Select labelId="subForMarClass8SecB" name="subNamesMarClass8SecB" id="multsubForMarClass8SecB" multiple value={subjectsOfClass.subNamesMarClass8SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass8SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                        {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                    </Select>
                                                    <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass8SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                </FormControl>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass8SecC' style={schoolClassData.includes('MarClass8SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 8</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass8SecC.error} name='subNamesMarClass8SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass8SecC">Select Subjects for Marathi Medium Class 8 Section C</InputLabel>
                                                        <Select labelId="subForMarClass8SecC" name="subNamesMarClass8SecC" id="multsubForMarClass8SecC" multiple value={subjectsOfClass.subNamesMarClass8SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass8SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass8SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass8SecD' style={schoolClassData.includes('MarClass8SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 8</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass8SecD.error} name='subNamesMarClass8SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass8SecD">Select Subjects for Marathi Medium Class 8 Section D</InputLabel>
                                                        <Select labelId="subForMarClass8SecD" name="subNamesMarClass8SecD" id="multsubForMarClass8SecD" multiple value={subjectsOfClass.subNamesMarClass8SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass8SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass8SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass8SecE' style={schoolClassData.includes('MarClass8SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 8</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass8SecE.error} name='subNamesMarClass8SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass8SecE">Select Subjects for Marathi Medium Class 8 Section E</InputLabel>
                                                        <Select labelId="subForMarClass8SecE" name="subNamesMarClass8SecE" id="multsubForMarClass8SecE" multiple value={subjectsOfClass.subNamesMarClass8SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass8SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass8SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Class 9 */}
                                        <tr className='RowEngClass9SecA' style={schoolClassData.includes('EngClass9SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 9</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass9SecA.error} name='subNamesEngClass9SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass9SecA">Select Subjects for English Medium Class 9 Section A</InputLabel>
                                                        <Select labelId="subForEngClass9SecA" name="subNamesEngClass9SecA" id="multsubForEngClass9SecA" multiple value={subjectsOfClass.subNamesEngClass9SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass9SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass9SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass9SecB' style={schoolClassData.includes('EngClass9SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 9</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass9SecB.error} name='subNamesEngClass9SecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass9SecB">Select Subjects for English Medium Class 9 Section B</InputLabel>
                                                        <Select labelId="subForEngClass9SecB" name="subNamesEngClass9SecB" id="multsubForEngClass9SecB" multiple value={subjectsOfClass.subNamesEngClass9SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass9SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass9SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass9SecC' style={schoolClassData.includes('EngClass9SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 9</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass9SecC.error} name='subNamesEngClass9SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass9SecC">Select Subjects for English Medium Class 9 Section C</InputLabel>
                                                        <Select labelId="subForEngClass9SecC" name="subNamesEngClass9SecC" id="multsubForEngClass9SecC" multiple value={subjectsOfClass.subNamesEngClass9SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass9SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass9SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass9SecD' style={schoolClassData.includes('EngClass9SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 9</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass9SecD.error} name='subNamesEngClass9SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass9SecD">Select Subjects for English Medium Class 9 Section D</InputLabel>
                                                        <Select labelId="subForEngClass9SecD" name="subNamesEngClass9SecD" id="multsubForEngClass9SecD" multiple value={subjectsOfClass.subNamesEngClass9SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass9SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass9SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass9SecE' style={schoolClassData.includes('EngClass9SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 9</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass9SecE.error} name='subNamesEngClass9SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass9SecE">Select Subjects for English Medium Class 9 Section E</InputLabel>
                                                        <Select labelId="subForEngClass9SecE" name="subNamesEngClass9SecE" id="multsubForEngClass9SecE" multiple value={subjectsOfClass.subNamesEngClass9SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass9SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass9SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass9SecA' style={schoolClassData.includes('MarClass9SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 9</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass9SecA.error} name='subNamesMarClass9SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass9SecA">Select Subjects for Marathi Medium Class 9 Section A</InputLabel>
                                                        <Select labelId="subForMarClass9SecA" name="subNamesMarClass9SecA" id="multsubForMarClass9SecA" multiple value={subjectsOfClass.subNamesMarClass9SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass9SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass9SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass9SecB' style={schoolClassData.includes('MarClass9SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 9</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <FormControl error={showEnteredSchoolSub.subNamesMarClass9SecB.error} name='subNamesMarClass9SecB' sx={{ m: 1, width: 530 }}>
                                                    <InputLabel id="subForMarClass9SecB">Select Subjects for Marathi Medium Class 9 Section B</InputLabel>
                                                    <Select labelId="subForMarClass9SecB" name="subNamesMarClass9SecB" id="multsubForMarClass9SecB" multiple value={subjectsOfClass.subNamesMarClass9SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass9SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                        {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                    </Select>
                                                    <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass9SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                </FormControl>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass9SecC' style={schoolClassData.includes('MarClass9SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 9</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass9SecC.error} name='subNamesMarClass9SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass9SecC">Select Subjects for Marathi Medium Class 9 Section C</InputLabel>
                                                        <Select labelId="subForMarClass9SecC" name="subNamesMarClass9SecC" id="multsubForMarClass9SecC" multiple value={subjectsOfClass.subNamesMarClass9SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass9SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass9SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass9SecD' style={schoolClassData.includes('MarClass9SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 9</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass9SecD.error} name='subNamesMarClass9SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass9SecD">Select Subjects for Marathi Medium Class 9 Section D</InputLabel>
                                                        <Select labelId="subForMarClass9SecD" name="subNamesMarClass9SecD" id="multsubForMarClass9SecD" multiple value={subjectsOfClass.subNamesMarClass9SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass9SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass9SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass9SecE' style={schoolClassData.includes('MarClass9SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 9</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass9SecE.error} name='subNamesMarClass9SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass9SecE">Select Subjects for Marathi Medium Class 9 Section E</InputLabel>
                                                        <Select labelId="subForMarClass9SecE" name="subNamesMarClass9SecE" id="multsubForMarClass9SecE" multiple value={subjectsOfClass.subNamesMarClass9SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass9SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass9SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Class 10 */}
                                        <tr className='RowEngClass10SecA' style={schoolClassData.includes('EngClass10SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 10</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass10SecA.error} name='subNamesEngClass10SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass10SecA">Select Subjects for English Medium Class 10 Section A</InputLabel>
                                                        <Select labelId="subForEngClass10SecA" name="subNamesEngClass10SecA" id="multsubForEngClass10SecA" multiple value={subjectsOfClass.subNamesEngClass10SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass10SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass10SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass10SecB' style={schoolClassData.includes('EngClass10SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 10</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass10SecB.error} name='subNamesEngClass10SecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass10SecB">Select Subjects for English Medium Class 10 Section B</InputLabel>
                                                        <Select labelId="subForEngClass10SecB" name="subNamesEngClass10SecB" id="multsubForEngClass10SecB" multiple value={subjectsOfClass.subNamesEngClass10SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass10SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass10SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass10SecC' style={schoolClassData.includes('EngClass10SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 10</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass10SecC.error} name='subNamesEngClass10SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass10SecC">Select Subjects for English Medium Class 10 Section C</InputLabel>
                                                        <Select labelId="subForEngClass10SecC" name="subNamesEngClass10SecC" id="multsubForEngClass10SecC" multiple value={subjectsOfClass.subNamesEngClass10SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass10SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass10SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass10SecD' style={schoolClassData.includes('EngClass10SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 10</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass10SecD.error} name='subNamesEngClass10SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass10SecD">Select Subjects for English Medium Class 10 Section D</InputLabel>
                                                        <Select labelId="subForEngClass10SecD" name="subNamesEngClass10SecD" id="multsubForEngClass10SecD" multiple value={subjectsOfClass.subNamesEngClass10SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass10SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass10SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass10SecE' style={schoolClassData.includes('EngClass10SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 10</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass10SecE.error} name='subNamesEngClass10SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass10SecE">Select Subjects for English Medium Class 10 Section E</InputLabel>
                                                        <Select labelId="subForEngClass10SecE" name="subNamesEngClass10SecE" id="multsubForEngClass10SecE" multiple value={subjectsOfClass.subNamesEngClass10SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass10SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass10SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass10SecA' style={schoolClassData.includes('MarClass10SecA') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 10</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass10SecA.error} name='subNamesMarClass10SecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass10SecA">Select Subjects for Marathi Medium Class 10 Section A</InputLabel>
                                                        <Select labelId="subForMarClass10SecA" name="subNamesMarClass10SecA" id="multsubForMarClass10SecA" multiple value={subjectsOfClass.subNamesMarClass10SecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass10SecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass10SecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass10SecB' style={schoolClassData.includes('MarClass10SecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 10</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <FormControl error={showEnteredSchoolSub.subNamesMarClass10SecB.error} name='subNamesMarClass10SecB' sx={{ m: 1, width: 530 }}>
                                                    <InputLabel id="subForMarClass10SecB">Select Subjects for Marathi Medium Class 10 Section B</InputLabel>
                                                    <Select labelId="subForMarClass10SecB" name="subNamesMarClass10SecB" id="multsubForMarClass10SecB" multiple value={subjectsOfClass.subNamesMarClass10SecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass10SecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                        {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                    </Select>
                                                    <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass10SecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                </FormControl>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass10SecC' style={schoolClassData.includes('MarClass10SecC') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 10</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass10SecC.error} name='subNamesMarClass10SecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass10SecC">Select Subjects for Marathi Medium Class 10 Section C</InputLabel>
                                                        <Select labelId="subForMarClass10SecC" name="subNamesMarClass10SecC" id="multsubForMarClass10SecC" multiple value={subjectsOfClass.subNamesMarClass10SecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass10SecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass10SecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass10SecD' style={schoolClassData.includes('MarClass10SecD') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 10</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass10SecD.error} name='subNamesMarClass10SecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass10SecD">Select Subjects for Marathi Medium Class 10 Section D</InputLabel>
                                                        <Select labelId="subForMarClass10SecD" name="subNamesMarClass10SecD" id="multsubForMarClass10SecD" multiple value={subjectsOfClass.subNamesMarClass10SecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass10SecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass10SecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass10SecE' style={schoolClassData.includes('MarClass10SecE') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 10</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass10SecE.error} name='subNamesMarClass10SecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass10SecE">Select Subjects for Marathi Medium Class 10 Section E</InputLabel>
                                                        <Select labelId="subForMarClass10SecE" name="subNamesMarClass10SecE" id="multsubForMarClass10SecE" multiple value={subjectsOfClass.subNamesMarClass10SecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass10SecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass10SecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Class 11 Arts */}
                                        <tr className='RowMarClass11ArtsSecA' style={schoolClassData.includes('MarClass11ArtsSecA') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Arts</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass11ArtsSecA.error} name='subNamesMarClass11ArtsSecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass11ArtsSecA">Select Subjects for Marathi Medium Class 11 Arts Section A</InputLabel>
                                                        <Select labelId="subForMarClass11ArtsSecA" name="subNamesMarClass11ArtsSecA" id="multsubForMarClass11ArtsSecA" multiple value={subjectsOfClass.subNamesMarClass11ArtsSecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass11ArtsSecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass11ArtsSecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass11ArtsSecB' style={schoolClassData.includes('MarClass11ArtsSecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Arts</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <FormControl error={showEnteredSchoolSub.subNamesMarClass11ArtsSecB.error} name='subNamesMarClass11ArtsSecB' sx={{ m: 1, width: 530 }}>
                                                    <InputLabel id="subForMarClass11ArtsSecB">Select Subjects for Marathi Medium Class 11 Arts Section B</InputLabel>
                                                    <Select labelId="subForMarClass11ArtsSecB" name="subNamesMarClass11ArtsSecB" id="multsubForMarClass11ArtsSecB" multiple value={subjectsOfClass.subNamesMarClass11ArtsSecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass11ArtsSecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                        {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                    </Select>
                                                    <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass11ArtsSecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                </FormControl>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass11ArtsSecC' style={schoolClassData.includes('MarClass11ArtsSecC') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Arts</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass11ArtsSecC.error} name='subNamesMarClass11ArtsSecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass11ArtsSecC">Select Subjects for Marathi Medium Class 11 Arts Section C</InputLabel>
                                                        <Select labelId="subForMarClass11ArtsSecC" name="subNamesMarClass11ArtsSecC" id="multsubForMarClass11ArtsSecC" multiple value={subjectsOfClass.subNamesMarClass11ArtsSecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass11ArtsSecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass11ArtsSecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass11ArtsSecD' style={schoolClassData.includes('MarClass11ArtsSecD') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Arts</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass11ArtsSecD.error} name='subNamesMarClass11ArtsSecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass11ArtsSecD">Select Subjects for Marathi Medium Class 11 Arts Section D</InputLabel>
                                                        <Select labelId="subForMarClass11ArtsSecD" name="subNamesMarClass11ArtsSecD" id="multsubForMarClass11ArtsSecD" multiple value={subjectsOfClass.subNamesMarClass11ArtsSecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass11ArtsSecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass11ArtsSecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass11ArtsSecE' style={schoolClassData.includes('MarClass11ArtsSecE') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Arts</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass11ArtsSecE.error} name='subNamesMarClass11ArtsSecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass11ArtsSecE">Select Subjects for Marathi Medium Class 11 Arts Section E</InputLabel>
                                                        <Select labelId="subForMarClass11ArtsSecE" name="subNamesMarClass11ArtsSecE" id="multsubForMarClass11ArtsSecE" multiple value={subjectsOfClass.subNamesMarClass11ArtsSecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass11ArtsSecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass11ArtsSecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Class 11 Commerce */}
                                        <tr className='RowEngClass11CommerceSecA' style={schoolClassData.includes('EngClass11CommerceSecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass11CommerceSecA.error} name='subNamesEngClass11CommerceSecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass11CommerceSecA">Select Subjects for English Medium Class 11 Commerce Section A</InputLabel>
                                                        <Select labelId="subForEngClass11CommerceSecA" name="subNamesEngClass11CommerceSecA" id="multsubForEngClass11CommerceSecA" multiple value={subjectsOfClass.subNamesEngClass11CommerceSecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass11CommerceSecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass11CommerceSecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass11CommerceSecB' style={schoolClassData.includes('EngClass11CommerceSecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass11CommerceSecB.error} name='subNamesEngClass11CommerceSecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass11CommerceSecB">Select Subjects for English Medium Class 11 Commerce Section B</InputLabel>
                                                        <Select labelId="subForEngClass11CommerceSecB" name="subNamesEngClass11CommerceSecB" id="multsubForEngClass11CommerceSecB" multiple value={subjectsOfClass.subNamesEngClass11CommerceSecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass11CommerceSecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass11CommerceSecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass11CommerceSecC' style={schoolClassData.includes('EngClass11CommerceSecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass11CommerceSecC.error} name='subNamesEngClass11CommerceSecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass11CommerceSecC">Select Subjects for English Medium Class 11 Commerce Section C</InputLabel>
                                                        <Select labelId="subForEngClass11CommerceSecC" name="subNamesEngClass11CommerceSecC" id="multsubForEngClass11CommerceSecC" multiple value={subjectsOfClass.subNamesEngClass11CommerceSecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass11CommerceSecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass11CommerceSecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass11CommerceSecD' style={schoolClassData.includes('EngClass11CommerceSecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass11CommerceSecD.error} name='subNamesEngClass11CommerceSecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass11CommerceSecD">Select Subjects for English Medium Class 11 Commerce Section D</InputLabel>
                                                        <Select labelId="subForEngClass11CommerceSecD" name="subNamesEngClass11CommerceSecD" id="multsubForEngClass11CommerceSecD" multiple value={subjectsOfClass.subNamesEngClass11CommerceSecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass11CommerceSecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass11CommerceSecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass11CommerceSecE' style={schoolClassData.includes('EngClass11CommerceSecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass11CommerceSecE.error} name='subNamesEngClass11CommerceSecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass11CommerceSecE">Select Subjects for English Medium Class 11 Commerce Section E</InputLabel>
                                                        <Select labelId="subForEngClass11CommerceSecE" name="subNamesEngClass11CommerceSecE" id="multsubForEngClass11CommerceSecE" multiple value={subjectsOfClass.subNamesEngClass11CommerceSecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass11CommerceSecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass11CommerceSecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass11CommerceSecA' style={schoolClassData.includes('MarClass11CommerceSecA') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass11CommerceSecA.error} name='subNamesMarClass11CommerceSecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass11CommerceSecA">Select Subjects for Marathi Medium Class 11 Commerce Section A</InputLabel>
                                                        <Select labelId="subForMarClass11CommerceSecA" name="subNamesMarClass11CommerceSecA" id="multsubForMarClass11CommerceSecA" multiple value={subjectsOfClass.subNamesMarClass11CommerceSecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass11CommerceSecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass11CommerceSecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass11CommerceSecB' style={schoolClassData.includes('MarClass11CommerceSecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <FormControl error={showEnteredSchoolSub.subNamesMarClass11CommerceSecB.error} name='subNamesMarClass11CommerceSecB' sx={{ m: 1, width: 530 }}>
                                                    <InputLabel id="subForMarClass11CommerceSecB">Select Subjects for Marathi Medium Class 11 Commerce Section B</InputLabel>
                                                    <Select labelId="subForMarClass11CommerceSecB" name="subNamesMarClass11CommerceSecB" id="multsubForMarClass11CommerceSecB" multiple value={subjectsOfClass.subNamesMarClass11CommerceSecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass11CommerceSecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                        {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                    </Select>
                                                    <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass11CommerceSecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                </FormControl>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass11CommerceSecC' style={schoolClassData.includes('MarClass11CommerceSecC') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass11CommerceSecC.error} name='subNamesMarClass11CommerceSecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass11CommerceSecC">Select Subjects for Marathi Medium Class 11 Commerce Section C</InputLabel>
                                                        <Select labelId="subForMarClass11CommerceSecC" name="subNamesMarClass11CommerceSecC" id="multsubForMarClass11CommerceSecC" multiple value={subjectsOfClass.subNamesMarClass11CommerceSecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass11CommerceSecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass11CommerceSecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass11CommerceSecD' style={schoolClassData.includes('MarClass11CommerceSecD') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass11CommerceSecD.error} name='subNamesMarClass11CommerceSecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass11CommerceSecD">Select Subjects for Marathi Medium Class 11 Commerce Section D</InputLabel>
                                                        <Select labelId="subForMarClass11CommerceSecD" name="subNamesMarClass11CommerceSecD" id="multsubForMarClass11CommerceSecD" multiple value={subjectsOfClass.subNamesMarClass11CommerceSecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass11CommerceSecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass11CommerceSecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass11CommerceSecE' style={schoolClassData.includes('MarClass11CommerceSecE') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass11CommerceSecE.error} name='subNamesMarClass11CommerceSecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass11CommerceSecE">Select Subjects for Marathi Medium Class 11 Commerce Section E</InputLabel>
                                                        <Select labelId="subForMarClass11CommerceSecE" name="subNamesMarClass11CommerceSecE" id="multsubForMarClass11CommerceSecE" multiple value={subjectsOfClass.subNamesMarClass11CommerceSecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass11CommerceSecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass11CommerceSecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Class 11 Science */}
                                        <tr className='RowEngClass11ScienceSecA' style={schoolClassData.includes('EngClass11ScienceSecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Science</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass11ScienceSecA.error} name='subNamesEngClass11ScienceSecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass11ScienceSecA">Select Subjects for English Medium Class 11 Science Section A</InputLabel>
                                                        <Select labelId="subForEngClass11ScienceSecA" name="subNamesEngClass11ScienceSecA" id="multsubForEngClass11ScienceSecA" multiple value={subjectsOfClass.subNamesEngClass11ScienceSecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass11ScienceSecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass11ScienceSecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass11ScienceSecB' style={schoolClassData.includes('EngClass11ScienceSecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Science</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass11ScienceSecB.error} name='subNamesEngClass11ScienceSecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass11ScienceSecB">Select Subjects for English Medium Class 11 Science Section B</InputLabel>
                                                        <Select labelId="subForEngClass11ScienceSecB" name="subNamesEngClass11ScienceSecB" id="multsubForEngClass11ScienceSecB" multiple value={subjectsOfClass.subNamesEngClass11ScienceSecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass11ScienceSecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass11ScienceSecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass11ScienceSecC' style={schoolClassData.includes('EngClass11ScienceSecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Science</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass11ScienceSecC.error} name='subNamesEngClass11ScienceSecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass11ScienceSecC">Select Subjects for English Medium Class 11 Science Section C</InputLabel>
                                                        <Select labelId="subForEngClass11ScienceSecC" name="subNamesEngClass11ScienceSecC" id="multsubForEngClass11ScienceSecC" multiple value={subjectsOfClass.subNamesEngClass11ScienceSecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass11ScienceSecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass11ScienceSecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass11ScienceSecD' style={schoolClassData.includes('EngClass11ScienceSecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Science</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass11ScienceSecD.error} name='subNamesEngClass11ScienceSecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass11ScienceSecD">Select Subjects for English Medium Class 11 Science Section D</InputLabel>
                                                        <Select labelId="subForEngClass11ScienceSecD" name="subNamesEngClass11ScienceSecD" id="multsubForEngClass11ScienceSecD" multiple value={subjectsOfClass.subNamesEngClass11ScienceSecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass11ScienceSecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass11ScienceSecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass11ScienceSecE' style={schoolClassData.includes('EngClass11ScienceSecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 11 Science</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass11ScienceSecE.error} name='subNamesEngClass11ScienceSecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass11ScienceSecE">Select Subjects for English Medium Class 11 Science Section E</InputLabel>
                                                        <Select labelId="subForEngClass11ScienceSecE" name="subNamesEngClass11ScienceSecE" id="multsubForEngClass11ScienceSecE" multiple value={subjectsOfClass.subNamesEngClass11ScienceSecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass11ScienceSecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass11ScienceSecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Class 12 Arts */}
                                        <tr className='RowMarClass12ArtsSecA' style={schoolClassData.includes('MarClass12ArtsSecA') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Arts</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass12ArtsSecA.error} name='subNamesMarClass12ArtsSecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass12ArtsSecA">Select Subjects for Marathi Medium Class 12 Arts Section A</InputLabel>
                                                        <Select labelId="subForMarClass12ArtsSecA" name="subNamesMarClass12ArtsSecA" id="multsubForMarClass12ArtsSecA" multiple value={subjectsOfClass.subNamesMarClass12ArtsSecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass12ArtsSecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass12ArtsSecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass12ArtsSecB' style={schoolClassData.includes('MarClass12ArtsSecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Arts</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <FormControl error={showEnteredSchoolSub.subNamesMarClass12ArtsSecB.error} name='subNamesMarClass12ArtsSecB' sx={{ m: 1, width: 530 }}>
                                                    <InputLabel id="subForMarClass12ArtsSecB">Select Subjects for Marathi Medium Class 12 Arts Section B</InputLabel>
                                                    <Select labelId="subForMarClass12ArtsSecB" name="subNamesMarClass12ArtsSecB" id="multsubForMarClass12ArtsSecB" multiple value={subjectsOfClass.subNamesMarClass12ArtsSecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass12ArtsSecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                        {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                    </Select>
                                                    <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass12ArtsSecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                </FormControl>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass12ArtsSecC' style={schoolClassData.includes('MarClass12ArtsSecC') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Arts</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass12ArtsSecC.error} name='subNamesMarClass12ArtsSecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass12ArtsSecC">Select Subjects for Marathi Medium Class 12 Arts Section C</InputLabel>
                                                        <Select labelId="subForMarClass12ArtsSecC" name="subNamesMarClass12ArtsSecC" id="multsubForMarClass12ArtsSecC" multiple value={subjectsOfClass.subNamesMarClass12ArtsSecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass12ArtsSecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass12ArtsSecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass12ArtsSecD' style={schoolClassData.includes('MarClass12ArtsSecD') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Arts</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass12ArtsSecD.error} name='subNamesMarClass12ArtsSecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass12ArtsSecD">Select Subjects for Marathi Medium Class 12 Arts Section D</InputLabel>
                                                        <Select labelId="subForMarClass12ArtsSecD" name="subNamesMarClass12ArtsSecD" id="multsubForMarClass12ArtsSecD" multiple value={subjectsOfClass.subNamesMarClass12ArtsSecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass12ArtsSecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass12ArtsSecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass12ArtsSecE' style={schoolClassData.includes('MarClass12ArtsSecE') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Arts</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass12ArtsSecE.error} name='subNamesMarClass12ArtsSecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass12ArtsSecE">Select Subjects for Marathi Medium Class 12 Arts Section E</InputLabel>
                                                        <Select labelId="subForMarClass12ArtsSecE" name="subNamesMarClass12ArtsSecE" id="multsubForMarClass12ArtsSecE" multiple value={subjectsOfClass.subNamesMarClass12ArtsSecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass12ArtsSecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass12ArtsSecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Class 12 Commerce */}
                                        <tr className='RowEngClass12CommerceSecA' style={schoolClassData.includes('EngClass12CommerceSecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass12CommerceSecA.error} name='subNamesEngClass12CommerceSecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass12CommerceSecA">Select Subjects for English Medium Class 12 Commerce Section A</InputLabel>
                                                        <Select labelId="subForEngClass12CommerceSecA" name="subNamesEngClass12CommerceSecA" id="multsubForEngClass12CommerceSecA" multiple value={subjectsOfClass.subNamesEngClass12CommerceSecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass12CommerceSecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass12CommerceSecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass12CommerceSecB' style={schoolClassData.includes('EngClass12CommerceSecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass12CommerceSecB.error} name='subNamesEngClass12CommerceSecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass12CommerceSecB">Select Subjects for English Medium Class 12 Commerce Section B</InputLabel>
                                                        <Select labelId="subForEngClass12CommerceSecB" name="subNamesEngClass12CommerceSecB" id="multsubForEngClass12CommerceSecB" multiple value={subjectsOfClass.subNamesEngClass12CommerceSecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass12CommerceSecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass12CommerceSecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass12CommerceSecC' style={schoolClassData.includes('EngClass12CommerceSecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass12CommerceSecC.error} name='subNamesEngClass12CommerceSecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass12CommerceSecC">Select Subjects for English Medium Class 12 Commerce Section C</InputLabel>
                                                        <Select labelId="subForEngClass12CommerceSecC" name="subNamesEngClass12CommerceSecC" id="multsubForEngClass12CommerceSecC" multiple value={subjectsOfClass.subNamesEngClass12CommerceSecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass12CommerceSecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass12CommerceSecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass12CommerceSecD' style={schoolClassData.includes('EngClass12CommerceSecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass12CommerceSecD.error} name='subNamesEngClass12CommerceSecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass12CommerceSecD">Select Subjects for English Medium Class 12 Commerce Section D</InputLabel>
                                                        <Select labelId="subForEngClass12CommerceSecD" name="subNamesEngClass12CommerceSecD" id="multsubForEngClass12CommerceSecD" multiple value={subjectsOfClass.subNamesEngClass12CommerceSecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass12CommerceSecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass12CommerceSecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass12CommerceSecE' style={schoolClassData.includes('EngClass12CommerceSecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass12CommerceSecE.error} name='subNamesEngClass12CommerceSecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass12CommerceSecE">Select Subjects for English Medium Class 12 Commerce Section E</InputLabel>
                                                        <Select labelId="subForEngClass12CommerceSecE" name="subNamesEngClass12CommerceSecE" id="multsubForEngClass12CommerceSecE" multiple value={subjectsOfClass.subNamesEngClass12CommerceSecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass12CommerceSecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass12CommerceSecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass12CommerceSecA' style={schoolClassData.includes('MarClass12CommerceSecA') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass12CommerceSecA.error} name='subNamesMarClass12CommerceSecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass12CommerceSecA">Select Subjects for Marathi Medium Class 12 Commerce Section A</InputLabel>
                                                        <Select labelId="subForMarClass12CommerceSecA" name="subNamesMarClass12CommerceSecA" id="multsubForMarClass12CommerceSecA" multiple value={subjectsOfClass.subNamesMarClass12CommerceSecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass12CommerceSecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass12CommerceSecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass12CommerceSecB' style={schoolClassData.includes('MarClass12CommerceSecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <FormControl error={showEnteredSchoolSub.subNamesMarClass12CommerceSecB.error} name='subNamesMarClass12CommerceSecB' sx={{ m: 1, width: 530 }}>
                                                    <InputLabel id="subForMarClass12CommerceSecB">Select Subjects for Marathi Medium Class 12 Commerce Section B</InputLabel>
                                                    <Select labelId="subForMarClass12CommerceSecB" name="subNamesMarClass12CommerceSecB" id="multsubForMarClass12CommerceSecB" multiple value={subjectsOfClass.subNamesMarClass12CommerceSecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass12CommerceSecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                        {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                    </Select>
                                                    <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass12CommerceSecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                </FormControl>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass12CommerceSecC' style={schoolClassData.includes('MarClass12CommerceSecC') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass12CommerceSecC.error} name='subNamesMarClass12CommerceSecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass12CommerceSecC">Select Subjects for Marathi Medium Class 12 Commerce Section C</InputLabel>
                                                        <Select labelId="subForMarClass12CommerceSecC" name="subNamesMarClass12CommerceSecC" id="multsubForMarClass12CommerceSecC" multiple value={subjectsOfClass.subNamesMarClass12CommerceSecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass12CommerceSecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass12CommerceSecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass12CommerceSecD' style={schoolClassData.includes('MarClass12CommerceSecD') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass12CommerceSecD.error} name='subNamesMarClass12CommerceSecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass12CommerceSecD">Select Subjects for Marathi Medium Class 12 Commerce Section D</InputLabel>
                                                        <Select labelId="subForMarClass12CommerceSecD" name="subNamesMarClass12CommerceSecD" id="multsubForMarClass12CommerceSecD" multiple value={subjectsOfClass.subNamesMarClass12CommerceSecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass12CommerceSecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass12CommerceSecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowMarClass12CommerceSecE' style={schoolClassData.includes('MarClass12CommerceSecE') ? ({ display: 'table-row' }) : ({ display: 'none' })}>
                                            <td className='classnSubjects__tableBodyMedium'>Marathi</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Commerce</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesMarClass12CommerceSecE.error} name='subNamesMarClass12CommerceSecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForMarClass12CommerceSecE">Select Subjects for Marathi Medium Class 12 Commerce Section E</InputLabel>
                                                        <Select labelId="subForMarClass12CommerceSecE" name="subNamesMarClass12CommerceSecE" id="multsubForMarClass12CommerceSecE" multiple value={subjectsOfClass.subNamesMarClass12CommerceSecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForMarClass12CommerceSecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesMarClass12CommerceSecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Class 12 Science */}
                                        <tr className='RowEngClass12ScienceSecA' style={schoolClassData.includes('EngClass12ScienceSecA') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Science</td>
                                            <td className='classnSubjects__tableBodySection'>Section A</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass12ScienceSecA.error} name='subNamesEngClass12ScienceSecA' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass12ScienceSecA">Select Subjects for English Medium Class 12 Science Section A</InputLabel>
                                                        <Select labelId="subForEngClass12ScienceSecA" name="subNamesEngClass12ScienceSecA" id="multsubForEngClass12ScienceSecA" multiple value={subjectsOfClass.subNamesEngClass12ScienceSecA} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass12ScienceSecA" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass12ScienceSecA.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass12ScienceSecB' style={schoolClassData.includes('EngClass12ScienceSecB') ? ({ display: 'table-row' }) : ({ display: 'none' })}  >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Science</td>
                                            <td className='classnSubjects__tableBodySection'>Section B</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass12ScienceSecB.error} name='subNamesEngClass12ScienceSecB' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass12ScienceSecB">Select Subjects for English Medium Class 12 Science Section B</InputLabel>
                                                        <Select labelId="subForEngClass12ScienceSecB" name="subNamesEngClass12ScienceSecB" id="multsubForEngClass12ScienceSecB" multiple value={subjectsOfClass.subNamesEngClass12ScienceSecB} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass12ScienceSecB" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass12ScienceSecB.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass12ScienceSecC' style={schoolClassData.includes('EngClass12ScienceSecC') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Science</td>
                                            <td className='classnSubjects__tableBodySection'>Section C</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass12ScienceSecC.error} name='subNamesEngClass12ScienceSecC' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass12ScienceSecC">Select Subjects for English Medium Class 12 Science Section C</InputLabel>
                                                        <Select labelId="subForEngClass12ScienceSecC" name="subNamesEngClass12ScienceSecC" id="multsubForEngClass12ScienceSecC" multiple value={subjectsOfClass.subNamesEngClass12ScienceSecC} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass12ScienceSecC" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass12ScienceSecC.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass12ScienceSecD' style={schoolClassData.includes('EngClass12ScienceSecD') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Science</td>
                                            <td className='classnSubjects__tableBodySection'>Section D</td>
                                            <td>
                                                <div>
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass12ScienceSecD.error} name='subNamesEngClass12ScienceSecD' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass12ScienceSecD">Select Subjects for English Medium Class 12 Science Section D</InputLabel>
                                                        <Select labelId="subForEngClass12ScienceSecD" name="subNamesEngClass12ScienceSecD" id="multsubForEngClass12ScienceSecD" multiple value={subjectsOfClass.subNamesEngClass12ScienceSecD} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass12ScienceSecD" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}addSubjectsToClass
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass12ScienceSecD.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='RowEngClass12ScienceSecE' style={schoolClassData.includes('EngClass12ScienceSecE') ? ({ display: 'table-row' }) : ({ display: 'none' })} >
                                            <td className='classnSubjects__tableBodyMedium'>English</td>
                                            <td className='classnSubjects__tableBodyClass'>Class 12 Science</td>
                                            <td className='classnSubjects__tableBodySection'>Section E</td>
                                            <td>
                                                <div >
                                                    <FormControl error={showEnteredSchoolSub.subNamesEngClass12ScienceSecE.error} name='subNamesEngClass12ScienceSecE' sx={{ m: 1, width: 530 }}>
                                                        <InputLabel id="subForEngClass12ScienceSecE">Select Subjects for English Medium Class 12 Science Section E</InputLabel>
                                                        <Select labelId="subForEngClass12ScienceSecE" name="subNamesEngClass12ScienceSecE" id="multsubForEngClass12ScienceSecE" multiple value={subjectsOfClass.subNamesEngClass12ScienceSecE} onChange={addSubjectsToClass} input={<OutlinedInput id="subForEngClass12ScienceSecE" />} MenuProps={MenuProps} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value) => (<Chip key={value} label={value} sx={{ margin: 0.1, backgroundColor: 'var(--connected-color)', color: 'white', fontSize: 15, fontWeight: 500 }} />))}</Box>)}>
                                                            {subjectList.map((subject) => (<MenuItem key={subject} value={subject} >{subject}</MenuItem>))}
                                                        </Select>
                                                        <FormHelperText>Total<span className='subNumber'><p>{subjectsOfClass.subNamesEngClass12ScienceSecE.length}</p></span>Subjects added to the Subjects List</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>

                            <div className='SubmitButton'>
                                <Button disabled={butDisabled} onClick={addDataToDatabase}>Continue</Button>

                            </div>
                        </div>
                    </form>
                )
            }

            {
                showBlock.showVisMisBlock && (
                    <div>
                        <CreateSchoolAccountInfo
                            adminFName={adminFName}
                            adminLName={adminLName}
                            inputSchoolName={inputSchoolName}
                            inputSchoolEmail={inputSchoolEmail}
                            inputSchoolPhoneNo={inputSchoolPhoneNo}
                            affiliation={affiliation}
                            confirmPassword={confirmPassword}

                            inputAddress={inputAddress}
                            inputVillage={inputVillage}
                            inputTehsil={inputTehsil}
                            inputCity={inputCity}
                            inputDistrict={inputDistrict}
                            inputPinCode={inputPinCode}
                            indianState={indianState}

                            schoolClassData={schoolClassData}
                            subjectsOfClass={subjectsOfClass}
                        />
                    </div>
                )
            }


        </div >
    )
}

export default CreateSchoolAccount