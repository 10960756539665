import React, { useEffect, useState } from 'react'
import '../Social/HomePagePostSender.css'
import { addDoc, collection, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

import { Avatar, Button, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import dB, { storage } from '../../firebase';

function HomePagePostSender(props) {
    const { userId, userPathCollection, userCurrentStatus, userDP } = props

    const [postData, setpostData] = useState({ postText: '', buttonDisabled: false, errorMessage: '' })
    const [errorMsg, seterrorMsg] = useState({ error: false, helperText: '' })
    const handleChangePostText = (event) => {
        setpostData(prev => { return { ...prev, postText: event.target.value } });
        seterrorMsg(prev => { return { ...prev, error: false, helperText: '' } })
        setmaxError(false)
        setpostUpdated(false)
    }

    // const [progress, setprogress] = useState(0);

    const [imgData, setImgData] = useState([]);
    const [picture, setPicture] = useState([]);
    const handleChange = async (e) => {
        setmaxError(false)
        setPicture([])
        setpostUpdated(false)
        if (e.target.files.length > 0) {
            setPicture([...e.target.files]);
        }
    }
    const [allPictures, setallPictures] = useState([])
    useEffect(() => {
        setallPictures([])
        if (picture?.length > 0) {
            const newImageUrls = [];
            picture.forEach(async (image) => {
                newImageUrls.push(URL.createObjectURL(image))
                const config = {
                    file: image,
                    maxSize: 500
                };
                const resizedImage = await resizeImage(config)
                setallPictures(prev => [...prev, resizedImage]);
                // newImageUrls.push(resizedImage)
            });
            setImgData(newImageUrls);
        }
    }, [picture]);
    var resizeImage = function (settings) {
        var file = settings.file;
        var maxSize = settings.maxSize;
        var reader = new FileReader();
        var image = new Image();
        var canvas = document.createElement('canvas');
        var dataURItoBlob = function (dataURI) {
            var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
                atob(dataURI.split(',')[1]) :
                unescape(dataURI.split(',')[1]);
            var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var max = bytes.length;
            var ia = new Uint8Array(max);
            for (var i = 0; i < max; i++)
                ia[i] = bytes.charCodeAt(i);
            return new Blob([ia], { type: mime });
        };
        var resize = function () {
            var width = image.width;
            var height = image.height;
            if (width > height) {
                if (width > maxSize) {
                    height *= maxSize / width;
                    width = maxSize;
                }
            } else {
                if (height > maxSize) {
                    width *= maxSize / height;
                    height = maxSize;
                }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            var dataUrl = canvas.toDataURL('image/jpeg');
            return dataURItoBlob(dataUrl);
        };
        return new Promise(function (ok, no) {
            if (!file.type.match(/image.*/)) {
                no(new Error("Not an image"));
                return;
            }
            reader.onload = function (readerEvent) {
                image.onload = function () { return ok(resize()); };
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        });
    };

    const [postUpdated, setpostUpdated] = useState(false)
    const [maxError, setmaxError] = useState(false)
    useEffect(() => {
        if (picture?.length > 5) {
            setmaxError(true)
        }
    }, [picture]);
    const [randomKey, setrandomKey] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var a = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
            setrandomKey(a)
        }
        return () => { aaa = false }
    }, [])

    const [btnLoading, setbtnLoading] = useState(false)
    const handleUploadPost = async (e) => {
        e.preventDefault();
        setbtnLoading(true)
        if (postData.postText || picture) {

            setpostData(prev => { return { ...prev, buttonDisabled: true } })

            if (userCurrentStatus === 'SCHOOL') {
                if (allPictures?.length > 0) {
                    // const promises = [];
                    const downloadUrls = [];
                    allPictures.map(async (onePic, i) => {
                        const imageRef = ref(storage, `SCHOOLS/${userId}/Timeline/${randomKey}${i}`);
                        const metadata = {
                            contentType: "image/jpeg",
                        };
                        await uploadBytes(imageRef, onePic, metadata)
                            .then(async (snapshot) => {
                                const downloadURL = await getDownloadURL(imageRef);
                                await downloadUrls.push(downloadURL)

                                if (allPictures?.length === downloadUrls?.length) {
                                    await setDoc(doc(dB, "SCHOOLS", userId, userPathCollection, "POSTS", "ALL_POSTS", randomKey), {
                                        POST_TEXT: postData.postText,
                                        POST_IMAGE: downloadUrls,
                                        POST_TIMESTAMP: serverTimestamp(),
                                    }).then(() => {
                                        setpostData(prev => { return { ...prev, postText: '', buttonDisabled: false } })
                                        // setprogress(0)
                                        setPicture([])
                                        setallPictures([])
                                        setImgData([])
                                        setpostUpdated(true)
                                        setbtnLoading(false)
                                        var b = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
                                        setrandomKey(b)
                                    }).catch(err => { setpostData(prev => { return { ...prev, errorMessage: err.message } }) })
                                }

                            })

                        // const uploadTask = storage.ref(`SCHOOLS/${userId}/Timeline/${randomKey}${i}`).put(onePic);
                        // promises.push(uploadTask)
                        // uploadTask.on("state_changed", (snapshot) => {
                        //     const progress = Math.round((((snapshot.bytesTransferred / snapshot.totalBytes) * 100) / (allPictures?.length - i)));
                        //     setprogress(progress);
                        // },
                        //     (error) => { setpostData(prev => { return { ...prev, errorMessage: error.message } }) },
                        //     () => {
                        //         storage.ref(`SCHOOLS/${userId}/Timeline`).child(`${randomKey}${i}`).getDownloadURL()
                        //             .then(async url => {
                        //                 await downloadUrls.push(url)


                        //             })
                        //     })
                    })

                } else {
                    await addDoc(collection(dB, "SCHOOLS", userId, userPathCollection, "POSTS", "ALL_POSTS"), {
                        POST_TEXT: postData.postText,
                        POST_IMAGE: [],
                        POST_TIMESTAMP: serverTimestamp(),
                    }).then(() => {
                        setpostData(prev => { return { ...prev, postText: '', buttonDisabled: false } })
                        // setprogress(0)
                        setPicture(null)
                        setImgData(null)
                        setpostUpdated(true)
                        setbtnLoading(false)
                    }).catch(err => { setpostData(prev => { return { ...prev, errorMessage: err.message } }) })
                }
            } else {
                if (allPictures?.length > 0) {
                    const imageRef = ref(storage, `USERS/${userId}/Timeline/${randomKey}`);
                    const metadata = {
                        contentType: "image/jpeg",
                    };
                    await uploadBytes(imageRef, allPictures[0], metadata)
                        .then(async (snapshot) => {
                            const downloadURL = await getDownloadURL(imageRef);
                            // await downloadUrls.push(downloadURL)
                            await addDoc(collection(dB, "USERS", userId, userPathCollection, "POSTS", "ALL_POSTS"), {
                                POST_TEXT: postData.postText,
                                POST_IMAGE: [downloadURL],
                                POST_TIMESTAMP: serverTimestamp(),
                            }).then(() => {
                                setpostData(prev => { return { ...prev, postText: '', buttonDisabled: false } })
                                // setprogress(0)
                                setPicture([])
                                setImgData([])
                                setallPictures([])
                                setpostUpdated(true)
                                var b = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
                                setrandomKey(b)
                                setbtnLoading(false)
                            }).catch(err => { setpostData(prev => { return { ...prev, errorMessage: err.message } }) })
                        })
                    // const uploadTask = storage.ref(`USERS/${userId}/Timeline/${randomKey}`).put(allPictures[0]);
                    // uploadTask.on("state_changed",
                    //     (snapshot) => {
                    //         const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    //         setprogress(progress);
                    //     },
                    //     (error) => { setpostData(prev => { return { ...prev, errorMessage: error.message } }) },
                    //     () => {
                    //         storage
                    //             .ref(`USERS/${userId}/Timeline`)
                    //             .child(randomKey)
                    //             .getDownloadURL()
                    //             .then(async url => {
                    //                 // post image inside database
                    //                 await addDoc(collection(dB, "USERS", userId, userPathCollection, "POSTS", "ALL_POSTS"), {
                    //                     POST_TEXT: postData.postText,
                    //                     POST_IMAGE: [url],
                    //                     POST_TIMESTAMP: serverTimestamp(),
                    //                 }).then(() => {
                    //                     
                    //                 }).catch(err => { setpostData(prev => { return { ...prev, errorMessage: err.message } }) })
                    //             })
                    //     })
                } else {
                    await addDoc(collection(dB, "USERS", userId, userPathCollection, "POSTS", "ALL_POSTS"), {
                        POST_TEXT: postData.postText,
                        POST_IMAGE: [],
                        POST_TIMESTAMP: serverTimestamp(),
                    }).then(() => {
                        setpostData(prev => { return { ...prev, postText: '', buttonDisabled: false } })
                        // setprogress(0)
                        setPicture(null)
                        setImgData(null)
                        setpostUpdated(true)
                        setbtnLoading(false)
                    }).catch(err => { setpostData(prev => { return { ...prev, errorMessage: err.message } }) })
                }


            }
        }
        else {
            setbtnLoading(false)
            // seterrorMsg(prev => { return { ...prev, error: true, helperText: 'Please type something here or select image' } })
        }
    }

    return (
        <div className='postsender'>
            <div className='postsender__top'>
                <Avatar
                    src={userDP}
                    alt={userPathCollection}
                    sx={{ width: 50, height: 50 }}
                />
                <FormControl error={errorMsg.error} name='postDetails' variant="outlined">
                    <InputLabel htmlFor="postDetails">What's on your mind?</InputLabel>
                    <OutlinedInput
                        multiline
                        type='text'
                        value={postData.postText}
                        onChange={handleChangePostText}
                        // labelWidth={155}
                        minRows={1}
                        maxRows={8}
                        label="What's on your mind?"
                    />
                    <FormHelperText id="postDetails"><p>{errorMsg.helperText}</p></FormHelperText>
                </FormControl>
            </div>
            <div className='postsender__bottom'>
                <div className='postsender__bottomUpload'>
                    <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                    >
                        <input
                            hidden
                            type="file"
                            id="postImage"
                            name="postImage"
                            accept=".jpg,.jpeg,.png,"
                            multiple={userCurrentStatus === 'SCHOOL' ? true : false}
                            onChange={handleChange}
                        />
                        <PhotoCamera sx={{ fontSize: "30px", color: 'lightblue' }} />
                        <h3 style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "grey"
                        }}>Photo</h3>
                    </IconButton>
                </div>

                <div className='postsender__bottomButton'>
                    {
                        (picture?.length > 0 || postData.postText) ? (
                            <>
                                {
                                    picture?.length === allPictures?.length && (
                                        <>
                                            {
                                                picture?.length > 5 ? (
                                                    <Button disabled={true}>Post</Button>
                                                ) : (
                                                    <LoadingButton
                                                        onClick={handleUploadPost}
                                                        loading={btnLoading}
                                                        variant="outlined"
                                                    >
                                                        Post
                                                    </LoadingButton>
                                                    // <Button disabled={postData.buttonDisabled} ></Button>
                                                )
                                            }
                                        </>

                                    )
                                }
                            </>
                        ) : (
                            <Button disabled={true} >Post</Button>
                        )
                    }
                </div>
            </div>
            <div className='uploadedImages'>
                {
                    imgData?.length > 0 && (
                        <>
                            {
                                imgData.map((image, i) => (
                                    <div className='uploadedImage' key={i}>
                                        <img
                                            src={image}
                                            alt={image}
                                        />
                                    </div>
                                ))
                            }
                        </>

                    )
                }
            </div>
            {
                maxError && (
                    <h4>Maximum 5 images allowed.</h4>
                )
            }
            {
                postUpdated && (
                    <h3>Post Updated.</h3>
                )
            }
            <h4>{postData.errorMessage}</h4>
        </div >
    )
}

export default HomePagePostSender