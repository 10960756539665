import React, { useEffect, useState } from 'react'
import '../../Pages/SchoolUpcomingEvents.css'
import '../../Pages/SidebarNoticeBoard.css'
import { addDoc, collection, doc, onSnapshot, serverTimestamp, setDoc } from 'firebase/firestore';
import { FormControl, FormControlLabel, FormHelperText, InputLabel, OutlinedInput, Radio, RadioGroup, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import subDays from "date-fns/subDays";
import LeaveApplication from './LeaveApplication';
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec';
import SentApplication from './SentApplication';

function LeaveApplicationteacher(props) {

    const { userId, userPathCollection, userCurrentStatus, userSchoolId } = props

    const [teacherSchoolData, setteacherSchoolData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userSchoolId) {
                onSnapshot(doc(dB, "SCHOOLS", userSchoolId), (snap) => {
                    if (snap.exists()) {
                        setteacherSchoolData(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userSchoolId])

    const [activeTab, setactiveTab] = useState({ sendLeaveApp: '', recievedAppl: '', acceptedAppl: '', rejectedAppl: '', sentLeaveApp: '' })
    const handleClickRecievedApplications = (e) => {
        e.preventDefault()
        setactiveTab(prev => {
            return {
                ...prev,
                sentLeaveApp: '', sendLeaveApp: '', recievedAppl: 'active', acceptedAppl: '', rejectedAppl: ''
            }
        })
    }
    const handleClickAcceptedApplications = (e) => {
        e.preventDefault()
        setactiveTab(prev => {
            return { ...prev, sentLeaveApp: '', sendLeaveApp: '', recievedAppl: '', acceptedAppl: 'active', rejectedAppl: '' }
        })
    }
    const handleClickRejectedApplications = (e) => {
        e.preventDefault()
        setactiveTab(prev => {
            return { ...prev, sentLeaveApp: '', sendLeaveApp: '', recievedAppl: '', acceptedAppl: '', rejectedAppl: 'active' }
        })
    }
    const handleClickSendApplication = (e) => {
        e.preventDefault()
        setactiveTab(prev => {
            return { ...prev, sentLeaveApp: '', sendLeaveApp: 'active', recievedAppl: '', acceptedAppl: '', rejectedAppl: '' }
        })
    }
    const handleClickSentApplication = (e) => {
        e.preventDefault()
        setactiveTab(prev => {
            return { ...prev, sentLeaveApp: 'active', sendLeaveApp: '', recievedAppl: '', acceptedAppl: '', rejectedAppl: '' }
        })
    }



    const [btnLoading, setbtnLoading] = useState(false)

    const [oneDay, setoneDay] = useState("One Day")
    const handleChangeRadio = (e) => {
        e.preventDefault();
        setoneDay(e.target.value)
    }

    const [sentText, setsentText] = useState(false)
    const [leaveAppSubject, setleaveAppSubject] = useState("")
    const [leaveAppBody, setleaveAppBody] = useState("")
    const [leaveAppDate, setleaveAppDate] = useState('')
    const handleGetSubject = (e) => {
        e.preventDefault()
        setleaveAppSubject(e.target.value)
        setshowError(prev => { return { ...prev, leaveAppSubject: { error: false, helperText: '' } } })
        setsentText(false)
    }
    const handleGetBody = (e) => {
        e.preventDefault()
        setleaveAppBody(e.target.value)
        setshowError(prev => { return { ...prev, leaveAppBody: { error: false, helperText: '' } } })
        setsentText(false)
    }
    const handleChangeSubmitDate = (event) => {
        event.preventDefault();
        setleaveAppDate(event.target.value)
        setshowError(prev => { return { ...prev, leaveAppDate: 'hide' } })
        setsentText(false)
    }
    const [showError, setshowError] = useState({
        leaveAppSubject: { error: false, helperText: '' },
        leaveAppBody: { error: false, helperText: '' },
        leaveAppDate: 'hide'
    })

    const dateToday = new Date()
    var nowDate = dateToday.getDate();
    var nowMonth = dateToday.getMonth() + 1
    var nowYear = dateToday.getFullYear()

    if (nowMonth < 10) { nowMonth = '0' + nowMonth.toString(); }
    if (nowDate < 10) { nowDate = '0' + nowDate.toString(); }

    var minDate = nowYear + '-' + nowMonth + '-' + nowDate;


    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const handleSendLeaveApplication = (e) => {
        e.preventDefault()
        setbtnLoading(true)

        if (oneDay === 'One Day') {
            if (leaveAppSubject && leaveAppBody && leaveAppDate) {

                addDoc(collection(dB, "SCHOOLS", userSchoolId, teacherSchoolData?.SCHOOL_NAME, "ALL_TEACHERS", "LEAVE_APPLICATIONS", "ALL_LEAVE_APPLICATIONS", "RECIEVED"), {
                    APPLICATION_STATUS: 'SENT',
                    // CHILD_ID: childId,
                    APPLICANT_ID: userId,
                    APPLICANT_PATH_COLLECTION: userPathCollection,
                    APPLICANT_STATUS: userCurrentStatus,
                    TIMESTAMP: serverTimestamp(),
                    ONE_DAY: true,
                    ON_DATE: leaveAppDate,
                    APPLICATION_SUBJECT: leaveAppSubject,
                    APPLICATION_BODY: leaveAppBody,
                    FROM_TO_DATE: {
                        START_DATE: "",
                        END_DATE: ""
                    },
                    VIEWED_BY: false
                }).then((docRef) => {
                    setDoc(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "IS_TEACHER_DATA", "LEAVE_APPLICATIONS", docRef.id), {
                        APPLICATION_STATUS: 'SENT',
                        TIMESTAMP: serverTimestamp(),
                        ONE_DAY: true,
                        ON_DATE: leaveAppDate,
                        APPLICATION_SUBJECT: leaveAppSubject,
                        APPLICATION_BODY: leaveAppBody,
                        FROM_TO_DATE: {
                            START_DATE: "",
                            END_DATE: ""
                        }
                    }).then(() => {
                        setsentText(true)
                        setbtnLoading(false)
                        setleaveAppSubject('')
                        setleaveAppBody('')
                        setleaveAppDate('')
                    }).catch((err) => {
                        alert(err);
                    })
                }).catch((err) => {
                    alert(err);
                })

            } else {
                setbtnLoading(false)
                if (!leaveAppSubject) { setshowError(prev => { return { ...prev, leaveAppSubject: { error: true, helperText: 'Please enter the subject of your leave application' } } }) }
                if (!leaveAppBody) { setshowError(prev => { return { ...prev, leaveAppBody: { error: true, helperText: 'Please enter the leave application information' } } }) }
                if (!leaveAppDate) { setshowError(prev => { return { ...prev, leaveAppDate: 'show' } }) }
            }
        } else {
            if (leaveAppSubject && leaveAppBody && startDate && endDate) {
                // leave status => approved/rejected/sent
                // oneDay = true/false
                // from and to (start date to end date)  (no data of the dates in between)

                addDoc(collection(dB, "SCHOOLS", userSchoolId, teacherSchoolData?.SCHOOL_NAME, "ALL_TEACHERS", "LEAVE_APPLICATIONS", "ALL_LEAVE_APPLICATIONS", "RECIEVED"), {
                    APPLICATION_STATUS: 'SENT',
                    // CHILD_ID: childId,
                    APPLICANT_ID: userId,
                    APPLICANT_PATH_COLLECTION: userPathCollection,
                    APPLICANT_STATUS: userCurrentStatus,
                    TIMESTAMP: serverTimestamp(),
                    ONE_DAY: false,
                    ON_DATE: "",
                    APPLICATION_SUBJECT: leaveAppSubject,
                    APPLICATION_BODY: leaveAppBody,
                    FROM_TO_DATE: {
                        START_DATE: startDate,
                        END_DATE: endDate
                    },
                    VIEWED_BY: false
                }).then((docRef) => {
                    setDoc(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "IS_TEACHER_DATA", "LEAVE_APPLICATIONS", docRef.id), {
                        APPLICATION_STATUS: 'SENT',
                        TIMESTAMP: serverTimestamp(),
                        ONE_DAY: false,
                        ON_DATE: "",
                        APPLICATION_SUBJECT: leaveAppSubject,
                        APPLICATION_BODY: leaveAppBody,
                        FROM_TO_DATE: {
                            START_DATE: startDate,
                            END_DATE: endDate
                        }
                    })
                    setsentText(true)
                    setbtnLoading(false)
                    setleaveAppSubject('')
                    setleaveAppBody('')
                    setleaveAppDate('')
                }).catch((err) => {
                    alert(err);
                })

            } else {
                setbtnLoading(false)
                if (!leaveAppSubject) { setshowError(prev => { return { ...prev, leaveAppSubject: { error: true, helperText: 'Please enter the subject of your leave application' } } }) }
                if (!leaveAppBody) { setshowError(prev => { return { ...prev, leaveAppBody: { error: true, helperText: 'Please enter the leave application information' } } }) }
                if (!startDate || !endDate) { setshowError(prev => { return { ...prev, leaveAppDate: 'show' } }) }
            }
        }


    }

    const [clsTeacherData, setclsTeacherData] = useState(null)
    useEffect(() => {
        if (userId && userPathCollection) {
            onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "CLASS_TEACHER_DATA"), (clsDoc) => {
                if (clsDoc.exists()) {
                    setclsTeacherData(clsDoc.data())
                }
            })
        }
    }, [userId, userPathCollection])

    const [teachMedClsSec, setteachMedClsSec] = useState('')
    useEffect(() => {
        if (clsTeacherData) {
            const abc = returnMedClsSec(`${clsTeacherData?.MEDIUM} Medium`, clsTeacherData?.CLASSNUM, clsTeacherData?.SECTION)
            setteachMedClsSec(abc)
        }
    }, [clsTeacherData])


    const [allRecieved, setallRecieved] = useState([])
    useEffect(() => {
        if (teachMedClsSec && teacherSchoolData?.SCHOOL_NAME && userSchoolId) {
            onSnapshot(collection(dB, "SCHOOLS", userSchoolId, teacherSchoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", teachMedClsSec, "LEAVE_APPLICATION", "RECIEVED", "RECIEVED_APPLICATIONS"), (snap) => {
                setallRecieved(snap.docs.map((doc) => ({
                    recievedAppId: doc.id,
                    recievedAppData: doc.data()
                })))
            })
        }
    }, [teachMedClsSec, teacherSchoolData?.SCHOOL_NAME, userSchoolId])

    const [allAccepted, setallAccepted] = useState([])
    useEffect(() => {
        if (teachMedClsSec && teacherSchoolData?.SCHOOL_NAME && userSchoolId) {
            onSnapshot(collection(dB, "SCHOOLS", userSchoolId, teacherSchoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", teachMedClsSec, "LEAVE_APPLICATION", "ACCEPTED", "ACCEPTED_APPLICATIONS"), (snap) => {
                setallAccepted(snap.docs.map((doc) => ({
                    acceptedAppId: doc.id,
                    acceptedAppData: doc.data()
                })))
            })
        }
    }, [teachMedClsSec, teacherSchoolData?.SCHOOL_NAME, userSchoolId])

    const [allRejected, setallRejected] = useState([])
    useEffect(() => {
        if (teachMedClsSec && teacherSchoolData?.SCHOOL_NAME && userSchoolId) {
            onSnapshot(collection(dB, "SCHOOLS", userSchoolId, teacherSchoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", teachMedClsSec, "LEAVE_APPLICATION", "REJECTED", "REJECTED_APPLICATIONS"), (snap) => {
                setallRejected(snap.docs.map((doc) => ({
                    rejectedAppId: doc.id,
                    rejectedAppData: doc.data()
                })))
            })
        }
    }, [teachMedClsSec, teacherSchoolData?.SCHOOL_NAME, userSchoolId])

    const [allSent, setallSent] = useState([])
    useEffect(() => {
        if (userId && userPathCollection) {
            onSnapshot(collection(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "IS_TEACHER_DATA", "LEAVE_APPLICATIONS"), (snap) => {
                setallSent(snap.docs.map((doc) => ({
                    sentAppId: doc.id,
                    sentAppData: doc.data()
                })))
            })
        }
    }, [userId, userPathCollection])



    return (
        <div className='SchoolUpcomingEvents'>
            {/* <Header /> */}
            <div className='SidebarNoticeBoard__Container'>
                <div className='SidebarNoticeBoard__ContainerTop'>
                    <h3>{teacherSchoolData?.SCHOOL_FULL_NAME}</h3>
                    <h2>Leave Applications</h2>
                    <hr />
                </div>
                <div className='SidebarNoticeBoard__ContainerBottom'>

                    <div className='SidebarNoticeBoard__ContainerBottomLeft'>
                        <nav className='SidebarNoticeBoard__containerLeft'>
                            <div key='CreateNewNotice' className={`SidebarNoticeBoard__containerLeft__title ${activeTab.recievedAppl}`} onClick={handleClickRecievedApplications}>
                                <CallReceivedIcon />
                                <h2>Recieved Applications</h2>
                            </div>
                            <div key='CreateNewNotice' className={`SidebarNoticeBoard__containerLeft__title ${activeTab.acceptedAppl}`} onClick={handleClickAcceptedApplications}>
                                <ThumbUpOffAltIcon />
                                <h2>Accepted Applications</h2>
                            </div>
                            <div key='CreateNewNotice' className={`SidebarNoticeBoard__containerLeft__title ${activeTab.rejectedAppl}`} onClick={handleClickRejectedApplications}>
                                <ThumbDownOffAltIcon />
                                <h2>Rejected Applications</h2>
                            </div>
                            <div key='recievedApplsLeft' className={`SidebarNoticeBoard__containerLeft__title ${activeTab.sendLeaveApp}`} onClick={handleClickSendApplication}>
                                <CallMadeIcon />
                                <h2>Send Leave Application</h2>
                            </div>
                            <div key='recievedApplsLeft' className={`SidebarNoticeBoard__containerLeft__title ${activeTab.sentLeaveApp}`} onClick={handleClickSentApplication}>
                                <PresentToAllIcon />
                                <h2>Sent Leave Application</h2>
                            </div>
                        </nav>
                    </div>

                    <div className='SidebarNoticeBoard__ContainerBottomRight'>
                        {
                            activeTab.recievedAppl === 'active' && (
                                <div className=' teacherLeave'>
                                    <h3>Recieved Leave Applications from Parents/Students <br /><span style={{ color: 'var(--connected-orange)' }}>{clsTeacherData?.MEDIUM} Medium | {clsTeacherData?.CLASSNUM} | {clsTeacherData?.SECTION}</span></h3>

                                    {
                                        allRecieved?.map((rec, i) => (
                                            <LeaveApplication
                                                key={i}
                                                leaveAppId={rec.recievedAppId}
                                                leaveAppData={rec.recievedAppData}

                                                userId={userId}
                                                userPathCollection={userPathCollection}
                                                userCurrentStatus={userCurrentStatus}
                                                userSchoolId={userSchoolId}
                                                teacherSchoolData={teacherSchoolData}
                                                clsTeacherData={clsTeacherData}
                                                teachMedClsSec={teachMedClsSec}

                                                appplicationStatus={"recieved"}
                                            />
                                        ))
                                    }
                                </div>
                            )
                        }

                        {
                            activeTab.acceptedAppl === 'active' && (
                                <div className=' teacherLeave'>
                                    <h3>Accepted Leave Applications from Parents/Students <br /><span style={{ color: 'var(--connected-orange)' }}>{clsTeacherData?.MEDIUM} Medium | {clsTeacherData?.CLASSNUM} | {clsTeacherData?.SECTION}</span></h3>

                                    {
                                        allAccepted?.map((rec, i) => (
                                            <LeaveApplication
                                                key={i}
                                                leaveAppId={rec.acceptedAppId}
                                                leaveAppData={rec.acceptedAppData}

                                                userId={userId}
                                                userPathCollection={userPathCollection}
                                                userCurrentStatus={userCurrentStatus}
                                                userSchoolId={userSchoolId}
                                                teacherSchoolData={teacherSchoolData}
                                                clsTeacherData={clsTeacherData}
                                                teachMedClsSec={teachMedClsSec}

                                                appplicationStatus={"accepted"}
                                            />
                                        ))
                                    }
                                </div>
                            )
                        }

                        {
                            activeTab.rejectedAppl === 'active' && (
                                <div className=' teacherLeave'>
                                    <h3>Rejected Leave Applications from Parents/Students <br /><span style={{ color: 'var(--connected-orange)' }}>{clsTeacherData?.MEDIUM} Medium | {clsTeacherData?.CLASSNUM} | {clsTeacherData?.SECTION}</span></h3>

                                    {
                                        allRejected?.map((rec, i) => (
                                            <LeaveApplication
                                                key={i}
                                                leaveAppId={rec.rejectedAppId}
                                                leaveAppData={rec.rejectedAppData}

                                                userId={userId}
                                                userPathCollection={userPathCollection}
                                                userCurrentStatus={userCurrentStatus}
                                                userSchoolId={userSchoolId}
                                                teacherSchoolData={teacherSchoolData}
                                                clsTeacherData={clsTeacherData}
                                                teachMedClsSec={teachMedClsSec}

                                                appplicationStatus={"rejected"}
                                            />
                                        ))
                                    }
                                </div>
                            )
                        }

                        {
                            activeTab.sendLeaveApp === 'active' && (
                                <div className='sentNotice teacherLeave'>
                                    <h3>Send your leave application to school</h3>
                                    <div className=''>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <div className='leaveOption'>
                                                <h3>Leave Application for</h3>
                                                <FormControl>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={handleChangeRadio}
                                                        value={oneDay}
                                                    >
                                                        <FormControlLabel value="One Day" control={<Radio />} label="One Day" />
                                                        <FormControlLabel value="More than One Day" control={<Radio />} label="More than One Day" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                            <div className='oneDayTeacher'>
                                                {/* <h3>Leave Application</h3> */}
                                                {
                                                    oneDay === 'One Day' ? (
                                                        <div className='leavedatedata'>
                                                            <div className='leaveDate' value={leaveAppDate} onChange={handleChangeSubmitDate}>
                                                                <p>Date</p>
                                                                <input type="date" min={minDate} />
                                                            </div>
                                                            {
                                                                showError.leaveAppDate === 'show' && <p>please select date</p>
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div className='leavedatedata'>
                                                            <div className='leaveDate' value={leaveAppDate} onChange={handleChangeSubmitDate}>
                                                                <p>Date</p>
                                                                <DatePicker
                                                                    placeholderText='Select date in range here.'
                                                                    selectsRange={true}
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    onChange={(update) => {
                                                                        setDateRange(update);
                                                                        setshowError(prev => { return { ...prev, leaveAppDate: 'hide' } })
                                                                    }}
                                                                    isClearable={true}
                                                                    minDate={subDays(new Date(), 5)}
                                                                />
                                                            </div>
                                                            {
                                                                showError.leaveAppDate === 'show' && <p>please select start and end date</p>
                                                            }
                                                        </div>
                                                    )
                                                }


                                                <FormControl error={showError.leaveAppSubject.error} name='leaveAppSubject' sx={{ width: '75ch' }} variant="outlined">
                                                    <InputLabel htmlFor="leaveAppSubject"> Subject </InputLabel>
                                                    <OutlinedInput
                                                        name='leaveAppSubject'
                                                        id="leaveAppSubject"
                                                        type='text'
                                                        value={leaveAppSubject}
                                                        onChange={handleGetSubject}
                                                        label='Subject '
                                                    />
                                                    <FormHelperText id="leaveAppSubject"><p>{showError.leaveAppSubject.helperText}</p></FormHelperText>
                                                </FormControl>

                                                <div className='sendNotice__noticeBody'>
                                                    <TextField
                                                        error={showError.leaveAppBody.error}
                                                        sx={{ width: '75ch' }}
                                                        name='leaveAppBody'
                                                        value={leaveAppBody}
                                                        onChange={handleGetBody}
                                                        id="leaveAppBody"
                                                        type='text'
                                                        label='Type your Leave Application here..'
                                                        multiline
                                                        rows={4}
                                                        rowsMax={6}
                                                        variant="outlined"
                                                    />
                                                    <FormHelperText id="noticefrom"><p>{showError.leaveAppBody.helperText}</p></FormHelperText>
                                                </div>

                                                <LoadingButton
                                                    loading={btnLoading}
                                                    disabled={btnLoading}
                                                    onClick={handleSendLeaveApplication}
                                                >Send Application
                                                </LoadingButton>
                                                {
                                                    sentText && (<h5>Leave Application Sent.</h5>)
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        {
                            activeTab.sentLeaveApp === 'active' && (
                                <div className=' teacherLeave'>
                                    <h3>Sent Leave Applications </h3>

                                    {
                                        allSent.map((app, i) => (
                                            <SentApplication
                                                key={i}
                                                applicationId={app?.sentAppId}
                                                applicationData={app?.sentAppData}
                                            />
                                        ))
                                    }
                                </div>
                            )
                        }


                    </div>
                </div>
                <hr />
            </div>
        </div>
    )
}

export default LeaveApplicationteacher