import React, { useEffect, useState } from 'react'
import '../Sidebars/HomePageSidebar.css'
import HomePageEducationSidebar from './HomePageEducationSidebar';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import dB from '../../firebase';
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec';

function HomePageSidebarStudents(props) {

    const { userId, userPathCollection, userCurrentStatus, studentSchoolData } = props

    const [studentClassData, setstudentClassData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_STUDENT", "IS_STUDENT_DATA"), async (studDoc) => {
                    if (studDoc.exists()) {
                        setstudentClassData(studDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const [schoolData, setschoolData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (studentSchoolData?.USER_SCHOOL_ID) {
                onSnapshot(doc(dB, "SCHOOLS", studentSchoolData?.USER_SCHOOL_ID), (schlDoc) => {
                    if (schlDoc.exists()) {
                        setschoolData(schlDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [studentSchoolData?.USER_SCHOOL_ID])

    const [studentMedClsSec, setstudentMedClsSec] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (studentClassData?.MEDIUM && studentClassData?.STUDENTS_CLASS && studentClassData?.STUDENTS_SECTION) {
                setstudentMedClsSec('')
                var abc = returnMedClsSec(studentClassData?.MEDIUM, studentClassData?.STUDENTS_CLASS, studentClassData?.STUDENTS_SECTION)
                setstudentMedClsSec(abc)
            }
        }
        return () => { aaa = false }
    }, [studentClassData?.MEDIUM, studentClassData?.STUDENTS_CLASS, studentClassData?.STUDENTS_SECTION])

    const [subList, setsubList] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (studentSchoolData?.USER_SCHOOL_ID && schoolData?.SCHOOL_NAME && studentMedClsSec) {
                onSnapshot(doc(dB, "SCHOOLS", studentSchoolData?.USER_SCHOOL_ID, schoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", studentMedClsSec), (snap) => {
                    setsubList(snap.data()?.SUBJECTS_LIST)
                })
            }
        }
        return () => { aaa = false }
    }, [schoolData?.SCHOOL_NAME, studentMedClsSec, studentSchoolData?.USER_SCHOOL_ID])


    const [assignmentData, setassignmentData] = useState(null)
    const [homeworkData, sethomeworkData] = useState(null)
    const [projectData, setprojectData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (subList?.length > 0) {
                subList.forEach(sub => {
                    onSnapshot(collection(dB, "SCHOOLS", studentSchoolData?.USER_SCHOOL_ID, schoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", studentMedClsSec, "PROJECTS_&_HOMEWORKS", sub, "ASSIGNMENT"), (snap) => {
                        setassignmentData(snap.docs.map((assDoc) => ({
                            assId: assDoc.id,
                            assData: assDoc.data(),
                        })))
                    })
                    onSnapshot(collection(dB, "SCHOOLS", studentSchoolData?.USER_SCHOOL_ID, schoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", studentMedClsSec, "PROJECTS_&_HOMEWORKS", sub, "HOMEWORK"), (snap) => {
                        sethomeworkData(snap.docs.map((homeDoc) => ({
                            homeId: homeDoc.id,
                            homeData: homeDoc.data(),
                        })))
                    })
                    onSnapshot(collection(dB, "SCHOOLS", studentSchoolData?.USER_SCHOOL_ID, schoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", studentMedClsSec, "PROJECTS_&_HOMEWORKS", sub, "PROJECT"), (snap) => {
                        setprojectData(snap.docs.map((proData) => ({
                            proId: proData.id,
                            proData: proData.data(),
                        })))
                    })
                })
            }
        }
        return () => { aaa = false }
    }, [schoolData?.SCHOOL_NAME, studentMedClsSec, studentSchoolData?.USER_SCHOOL_ID, subList])

    const [assIds, setassIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (assignmentData !== null) {
                assignmentData?.forEach(data => {
                    if (!data?.assData?.VIEWED_BY.includes(userId)) {
                        setassIds(prev => { return [...prev, data?.assId] })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [assignmentData, userId])
    const [homeIds, sethomeIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (homeworkData !== null) {
                homeworkData?.forEach(data => {
                    if (!data?.homeData?.VIEWED_BY.includes(userId)) {
                        sethomeIds(prev => { return [...prev, data?.homeId] })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [homeworkData, userId])
    const [proIds, setproIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (projectData !== null) {
                projectData?.forEach(data => {
                    if (!data?.proData?.VIEWED_BY.includes(userId)) {
                        setproIds(prev => { return [...prev, data?.proId] })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [projectData, userId])

    const totalHomeworks = assIds.length + homeIds.length + proIds.length

    return (
        <div className='HomePageSidebarStudents'>

            <div >
                {/* educational info */}
                <HomePageEducationSidebar
                    userCurrentStatus={userCurrentStatus}
                    schoolId={studentSchoolData.USER_SCHOOL_ID}
                    schoolPathCollection={schoolData?.SCHOOL_NAME}
                    userId={userId}
                    totalHomeworks={totalHomeworks}
                />
            </div>

        </div>
    )
}

export default HomePageSidebarStudents
