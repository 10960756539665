import React, { useEffect, useState } from 'react'
import '../FIndToFollow/FindToFollowTeachersNFriends.css'
// import dB from '../firebase';
import { collection, doc, onSnapshot, serverTimestamp, writeBatch } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { Avatar, Button, Card } from '@mui/material';
import dB from '../../firebase';

function FindToFollowTeachersNFriendsCard(props) {

    const {
        userId, userPathCollection, userCurrentStatus, friendImage, friendId, friendESchoolStatus, friendPathCollection
    } = props

    const [friendFullName, setfriendFullName] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (friendId && friendPathCollection) {
                onSnapshot(doc(dB, "USERS", friendId, friendPathCollection, "REGISTRATION_INFO"), (frDoc) => {
                    if (frDoc.exists) { setfriendFullName(frDoc.data()?.USER_NAME) }
                })
            }
        }
        return () => { aaa = false }
    }, [friendId, friendPathCollection])

    // const classes = useStyles();

    const [userConnectionIds, setuserConnectionIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "CONNECTIONS", "CONNECTED_USERS"), (snap) => {
                    setuserConnectionIds(snap.docs.map((conDoc) => conDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const [schoolFollowingIds, setschoolFollowingIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "SCHOOLS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS"), (snap) => {
                    setschoolFollowingIds(snap.docs.map((schlDoc) => schlDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const [userFollowingIds, setuserFollowingIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS"), (snap) => {
                    setuserFollowingIds(snap.docs.map((folDoc) => folDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const [userRequestSentIds, setuserRequestSentIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "REQUESTS_SENT", "REQUESTS_SENT_IDs"), (snap) => {
                    setuserRequestSentIds(snap.docs.map((reqSentDoc) => reqSentDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const [userRequestRecievedIds, setuserRequestRecievedIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "REQUESTS_RECIEVED", "REQUESTS_RECIEVED_IDs"), (snap) => {
                    setuserRequestRecievedIds(snap.docs.map((reqRecDoc) => reqRecDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    var batch = writeBatch(dB)

    const [btnDisabled, setbtnDisabled] = useState({
        btnAccept: false, btnReject: false, btnRemove: false, btnSend: false, btnCancel: false,
        btnSchoolFollowUser: false, btnSchoolUnFollowUser: false,
        btnUserFollowUser: false, btnUserUnFollowUser: false,
    })

    const handleAcceptRequest = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnAccept: true } })

        batch.delete(doc(dB, "USERS", userId, userPathCollection, "REQUESTS_RECIEVED", "REQUESTS_RECIEVED_IDs", friendId))

        batch.delete(doc(dB, "USERS", friendId, friendPathCollection, "REQUESTS_SENT", "REQUESTS_SENT_IDs", userId))

        batch.set(doc(dB, "USERS", userId, userPathCollection, "CONNECTIONS", "CONNECTED_USERS", friendId), {
            TIMESTAMP: serverTimestamp()
        })

        batch.set(doc(dB, "USERS", friendId, friendPathCollection, "CONNECTIONS", "CONNECTED_USERS", userId), {
            TIMESTAMP: serverTimestamp()
        })

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnAccept: false } })
        }).catch(err => alert(err.message))
    }
    const handleRejectRequest = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnReject: true } })

        batch.delete(doc(dB, "USERS", userId, userPathCollection, "REQUESTS_RECIEVED", "REQUESTS_RECIEVED_IDs", friendId))

        batch.delete(doc(dB, "USERS", friendId, friendPathCollection, "REQUESTS_SENT", "REQUESTS_SENT_IDs", userId))

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnReject: false } })
        }).catch(err => alert(err.message))
    }
    const handleRemoveFrnd = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnRemove: true } })

        batch.delete(doc(dB, "USERS", userId, userPathCollection, "CONNECTIONS", "CONNECTED_USERS", friendId))

        batch.delete(doc(dB, "USERS", friendId, friendPathCollection, "CONNECTIONS", "CONNECTED_USERS", userId))

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnRemove: false } })
        }).catch(err => alert(err.message))
    }

    const handleSendRequest = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnSend: true } })

        batch.set(doc(dB, "USERS", userId, userPathCollection, "REQUESTS_SENT", "REQUESTS_SENT_IDs", friendId), {
            TIMESTAMP: serverTimestamp()
        })

        batch.set(doc(dB, "USERS", friendId, friendPathCollection, "REQUESTS_RECIEVED", "REQUESTS_RECIEVED_IDs", userId), {
            TIMESTAMP: serverTimestamp()
        })

        batch.set(doc(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS", friendId), {
            TIMESTAMP: serverTimestamp()
        })

        batch.set(doc(dB, "USERS", friendId, friendPathCollection, "FOLLOWERS", "FOLLOWER_USERS", friendId), {
            TIMESTAMP: serverTimestamp()
        })

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnSend: false } })
        }).catch(err => alert(err.message))

    }
    const handleCancelRequest = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnCancel: true } })

        batch.delete(doc(dB, "USERS", userId, userPathCollection, "REQUESTS_SENT", "REQUESTS_SENT_IDs", friendId))

        batch.delete(doc(dB, "USERS", friendId, friendPathCollection, "REQUESTS_RECIEVED", "REQUESTS_RECIEVED_IDs", userId))

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnCancel: false } })
        }).catch(err => alert(err.message))

    }

    // done
    const handleSchoolFollowUser = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnSchoolFollowUser: true } })

        batch.set(doc(dB, "SCHOOLS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS", friendId), {
            TIMESTAMP: serverTimestamp()
        })

        batch.set(doc(dB, "USERS", friendId, friendPathCollection, "FOLLOWERS", "FOLLOWER_SCHOOLS", userId), {
            TIMESTAMP: serverTimestamp()
        })

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnSchoolFollowUser: false } })
        }).catch(err => alert(err.message))
    }
    const handleSchoolUnfollowUser = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnSchoolUnFollowUser: true } })

        batch.delete(doc(dB, "SCHOOLS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS", friendId))

        batch.delete(doc(dB, "USERS", friendId, friendPathCollection, "FOLLOWERS", "FOLLOWER_SCHOOLS", userId))

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnSchoolUnFollowUser: false } })
        }).catch(err => alert(err.message))
    }

    // done
    const handleUserFollowUser = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnUserFollowUser: true } })

        batch.set(doc(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS", friendId), {
            TIMESTAMP: serverTimestamp()
        })

        batch.set(doc(dB, "USERS", friendId, friendPathCollection, "FOLLOWERS", "FOLLOWER_USERS", userId), {
            TIMESTAMP: serverTimestamp()
        })

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnUserFollowUser: false } })
        }).catch(err => alert(err.message))
    }
    const handleUserUnfollowUser = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnUserUnFollowUser: true } })

        batch.delete(doc(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_USERS", friendId))

        batch.delete(doc(dB, "USERS", friendId, friendPathCollection, "FOLLOWERS", "FOLLOWER_USERS", userId))

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnUserUnFollowUser: false } })
        }).catch(err => alert(err.message))
    }

    return (
        <div className='FindToFollowTeachersNFriendsRow'>
            <Card sx={{ width: 140, }}>
                <div className='FindToFollowTeachersNFriendsCard'>
                    <Link to={`/friendsprofile/${friendId}`} style={{ textDecoration: 'none', color: 'black' }}>
                        <Avatar
                            alt={friendFullName}
                            src={friendImage}
                            sx={{ width: 55, height: 55 }}
                        // className={classes.root}
                        />
                    </Link>

                    <p>
                        <Link to={`/friendsprofile/${friendId}`} style={{ textDecoration: 'none', color: 'black' }}>
                            {friendFullName}
                        </Link>
                    </p>
                    {
                        friendESchoolStatus === 'PRINCIPAL' ? (
                            <h5>[{friendESchoolStatus}]</h5>
                        ) : friendESchoolStatus === 'STUDENT' ? (
                            <h5>[{friendESchoolStatus}]</h5>
                        ) : friendESchoolStatus === 'TEACHER' ? (
                            <h5>[{friendESchoolStatus}]</h5>
                        ) : friendESchoolStatus === 'PARENT' ? (
                            <h5>[USER]</h5>
                        ) : (
                            <h5>[USER]</h5>
                        )
                    }

                    {
                        userId === friendId ? (
                            <div className='youCard'>
                                <h4>You</h4>
                            </div>
                        ) : (
                            <>
                                {
                                    userCurrentStatus === 'SCHOOL' ? (
                                        <>
                                            <div className='FindToFollowTeachersNFriendsCardButtons'>
                                                <div className='FindToFollowTeachersNFriendsCardButtonsFollow'>
                                                    <Button disabled={btnDisabled.btnSchoolFollowUser} style={{ display: schoolFollowingIds.includes(friendId) ? 'none' : 'flex' }} onClick={handleSchoolFollowUser}>Follow</Button>
                                                </div>
                                                {
                                                    schoolFollowingIds.includes(friendId) && (
                                                        <div className='FindToFollowTeachersNFriendsCardButtonsH3'>
                                                            <h3 disabled={btnDisabled.btnSchoolUnFollowUser} onClick={handleSchoolUnfollowUser}>Unfollow</h3>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='FindToFollowTeachersNFriendsCardButtons'>
                                                {
                                                    userConnectionIds.includes(friendId) ? (
                                                        <div className='FindToFollowTeachersNFriendsCardButtonsH3'>
                                                            <h3 disabled={btnDisabled.btnRemove} color="primary" onClick={handleRemoveFrnd}>Remove</h3>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {
                                                                userRequestSentIds.includes(friendId) ? (
                                                                    <div className='FindToFollowTeachersNFriendsCardButtonsH3'>
                                                                        <h3 disabled={btnDisabled.btnCancel} onClick={handleCancelRequest}>Cancel Sent</h3>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        {
                                                                            userRequestRecievedIds.includes(friendId) ? (
                                                                                <>
                                                                                    <div className='FindToFollowTeachersNFriendsCardAcceptButtons'>
                                                                                        <Button disabled={btnDisabled.btnAccept} color="primary" onClick={handleAcceptRequest}>Accept</Button>
                                                                                    </div>
                                                                                    {/* reject/cancel recieved */}
                                                                                    <div className='FindToFollowTeachersNFriendsCardButtonsH3'>
                                                                                        <h3 disabled={btnDisabled.btnReject} color="primary" onClick={handleRejectRequest}>Reject</h3>
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <div className='FindToFollowTeachersNFriendsCardButtonsConnect'>
                                                                                    <Button disabled={btnDisabled.btnSend} onClick={handleSendRequest}>Connect</Button>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    !userRequestRecievedIds.includes(friendId) && (
                                                        <>
                                                            {
                                                                userFollowingIds.includes(friendId) ? (
                                                                    <div className='FindToFollowTeachersNFriendsCardButtonsH3'>
                                                                        <h3 disabled={btnDisabled.btnUserUnFollowUser} onClick={handleUserUnfollowUser}>Unfollow</h3>
                                                                    </div>
                                                                ) : (
                                                                    <div className='FindToFollowTeachersNFriendsCardButtonsFollow'>
                                                                        <Button disabled={btnDisabled.btnUserFollowUser} onClick={handleUserFollowUser}>Follow</Button>
                                                                    </div>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }

                                            </div>
                                        </>
                                    )
                                }
                            </>
                        )
                    }

                </div>
            </Card>
        </div>
    )
}

export default FindToFollowTeachersNFriendsCard
