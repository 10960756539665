import React, { useContext, useEffect, useState } from 'react'
import '../Social/HomePageFeed.css'
import HomePagePosts from './HomePagePosts';
import { FrnzPostsContext } from './HomePageFeed';
import { Button } from '@mui/material';


function FollowingUsersPosts(props) {

    const { userFullName, userId, userPathCollection, userCurrentStatus, userDP, friendId, friendPathCollection,
        friendCurrentStatus, friendDP, friendFullName, followingUsers } = props

    const postsData = useContext(FrnzPostsContext);

    const [sortedArray, setsortedArray] = useState([])
    useEffect(() => {
        if (postsData?.length > 0) {
            setsortedArray(postsData?.sort((a, b) => b.postData?.POST_TIMESTAMP.valueOf() - a.postData?.POST_TIMESTAMP.valueOf()))
        }
    }, [postsData])

    const [posts, setposts] = useState([])
    const [lastPost, setlastPost] = useState(0)
    useEffect(() => {
        if (posts?.length > 0) {
            posts?.splice(0, posts?.length)
        }
        if (sortedArray?.length > 0) {
            var abc = []
            sortedArray.map((ele, index) => {
                if (abc.length < 10) {
                    abc.push(ele)
                    setlastPost(index + 1)
                }
            })
            setposts(prev => { return [...prev, ...abc] })
        }
    }, [sortedArray])

    const loadMorePosts = () => {
        const abc = []
        for (let i = lastPost; i < lastPost + 10; i++) {
            if (abc.length < 10) {
                if (sortedArray[i]) {
                    abc.push(sortedArray[i])
                    setlastPost(i + 1)
                }
            }
        }
        setposts(prev => [...prev, ...abc])
    }



    return (
        <div>
            {
                postsData.length > 0 ? (
                    <>
                        {
                            posts.map((poData, i) => (
                                <HomePagePosts
                                    key={i}
                                    userName={userFullName}
                                    timeStamp={poData.postData?.POST_TIMESTAMP}
                                    postText={poData.postData?.POST_TEXT}
                                    postImage={poData.postData?.POST_IMAGE}
                                    postId={poData.postId}
                                    userId={userId}
                                    userPathCollection={userPathCollection}
                                    userCurrentStatus={userCurrentStatus}
                                    userDP={userDP}
                                    friendId={poData?.postUserId}
                                    friendPathCollection={poData?.postUserPathCollection}
                                    friendCurrentStatus={poData?.postUserCurrentStatus}
                                    friendFullName={poData?.postUserFullName}
                                    friendDP={poData?.postUserDP}
                                />
                            ))
                        }
                        {
                            postsData?.length > 8 ? (
                                <>
                                    {
                                        lastPost !== postsData?.length ? (
                                            <div className='showMoreBtn'>
                                                <Button color="primary" onClick={loadMorePosts}>Show More Posts..</Button>
                                                <></>
                                            </div>
                                        ) : (
                                            <div className='noMorePosts'>
                                                <h4 >No more posts to show</h4>
                                            </div>
                                        )
                                    }
                                </>
                            ) : (
                                <div className='noMorePosts'>
                                    <h4 >No more posts to show</h4>
                                </div>
                            )
                        }
                    </>
                ) : (
                    <div className='notCreatedPost'>
                        <h3>Please follow users to see their posts.</h3>
                    </div>
                )
            }
        </div>
    )
}

export default FollowingUsersPosts