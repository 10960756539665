import React, { useEffect, useState } from 'react'
import '../Sidebars/SidebarESchoolingStatusAddTeacher.css'
import '../../Pages/SidebarESchoolingStatus.css'
import '../CreateNewAccount/CreateNewAccountInfoTeachers.css'
import { collection, doc, onSnapshot, serverTimestamp, writeBatch } from 'firebase/firestore';
import { useHistory } from 'react-router-dom';
import {
    Autocomplete, FormControl, FormControlLabel, FormHelperText, InputLabel,
    MenuItem,
    Radio, RadioGroup, Select, TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec';
import { returnSection } from '../../Constants/FuncMedium';

function SidebarESchoolingStatusAddTeacher(props) {
    const { userPathCollection, userId, userStatuses,
        princiSchoolPathCollection, princiSchoolId, princiSchoolFullName
    } = props

    const history = useHistory()

    const [showErrors, setshowErrors] = useState({
        schoolFullName: { error: false, helperText: '' },
        teacherMedium: { error: false, helperText: '' },
        teacherClass: { error: false, helperText: '' },
        teacherSection: { error: false, helperText: '' },
        teacherSubject: { error: false, helperText: '' },
    })
    const [teachersInputData, setteachersInputData] = useState({
        schoolFullName: null, teacherMedium: '', teacherClass: '', teacherSection: '', teacherSubject: '',
    })
    const [teachersOptions, setteachersOptions] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (teachersOptions.length > 0) {
                teachersOptions.splice(0, teachersOptions.length)
            }
            onSnapshot(collection(dB, "SCHOOLS"), (snap) => {
                snap.forEach((doc) => {
                    const newdata = doc.data()?.SCHOOL_FULL_NAME;
                    setteachersOptions(prev => { return [...prev, { id: doc.id, schoolName: newdata }] })
                });
            })
        }
        return () => { aaa = false }
    }, [])

    const [teacherInputSchoolId, setteacherInputSchoolId] = useState('')
    const [teacherInputSchoolPathCollection, setteacherInputSchoolPathCollection] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (!princiSchoolId) {
                onSnapshot(collection(dB, "SCHOOLS"), (snap) => {
                    snap.forEach((schlDOc) => {
                        const newdata = schlDOc.data()?.SCHOOL_FULL_NAME;
                        if (newdata === teachersInputData.schoolFullName) {
                            setteacherInputSchoolPathCollection(schlDOc.data()?.SCHOOL_NAME)
                            setteacherInputSchoolId(schlDOc.id)
                        }
                    });
                })
            } else {
                setteacherInputSchoolId(princiSchoolId)
                setteacherInputSchoolPathCollection(princiSchoolPathCollection)
            }
        }
        return () => { aaa = false }
    }, [princiSchoolFullName, princiSchoolId, princiSchoolPathCollection, teachersInputData.schoolFullName])

    // fetching class list
    const [returnedTeachersClassList, setreturnedTeachersClassList] = useState([])
    useEffect(() => {
        if (returnedTeachersClassList.length > 0) {
            returnedTeachersClassList.splice(0, returnedTeachersClassList.length)
        }
        var aaa = true
        if (aaa === true) {
            if (teacherInputSchoolId && teacherInputSchoolPathCollection) {
                onSnapshot(doc(dB, "SCHOOLS", teacherInputSchoolId, teacherInputSchoolPathCollection, "CLASSES_DETAILS"), (classes) => {
                    if (classes.exists()) {
                        setreturnedTeachersClassList(classes.data()?.CLASS_LIST)
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [teacherInputSchoolId, teacherInputSchoolPathCollection])

    const [teacherMedClsSec, setteacherMedClsSec] = useState('')
    useEffect(() => {
        setteacherMedClsSec('')
        var aaa = true
        if (aaa === true) {
            var abc = returnMedClsSec(teachersInputData.teacherMedium, teachersInputData.teacherClass, teachersInputData.teacherSection)
            setteacherMedClsSec(abc)
        }
        return () => { aaa = false }
    }, [teachersInputData.teacherMedium, teachersInputData.teacherClass, teachersInputData.teacherSection])

    // Fetching Subjects
    const [teachersSubjects, setteachersSubjects] = useState([])
    useEffect(() => {
        if (teachersSubjects.length > 0) {
            teachersSubjects.splice(0, teachersSubjects.length)
        }
        var aaa = true
        if (aaa === true) {
            if (teacherMedClsSec) {
                if (teacherInputSchoolId && teacherInputSchoolPathCollection) {
                    onSnapshot(doc(dB, "SCHOOLS", teacherInputSchoolId, teacherInputSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", teacherMedClsSec), (newClassDoc) => {
                        if (newClassDoc.exists()) {
                            setteachersSubjects(newClassDoc.data()?.SUBJECTS_LIST)
                        }
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [teacherInputSchoolId, teacherInputSchoolPathCollection, teacherMedClsSec])

    // Fetching Sections
    const [teacherSections, setteacherSections] = useState(null)
    useEffect(() => {
        setteacherSections(null)
        var aaa = true
        if (aaa === true) {
            var sectionObject = returnSection(teachersInputData.teacherMedium, teachersInputData.teacherClass, returnedTeachersClassList)
            setteacherSections(sectionObject)
        }
        return () => { aaa = false }
    }, [teachersInputData.teacherMedium, teachersInputData.teacherClass, returnedTeachersClassList])

    const [parentsInputValue, setparentsInputValue] = useState('');

    var batch = writeBatch(dB)

    // generate 14 digit random number
    const [randomKey, setrandomKey] = useState({
        randomId1: '',
    })
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var a = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
            setrandomKey(prevState => { return { ...prevState, randomId1: a, } })
        }
        return () => { aaa = false }
    }, [])

    const [btnDisabled, setbtnDisabled] = useState(false)
    const [requestSent, setrequestSent] = useState(false)


    const handleSendVerificationRequest = async (e) => {
        e.preventDefault()
        setbtnDisabled(true)
        if (teachersInputData.teacherMedium && teachersInputData.teacherClass && teachersInputData.teacherSection && teachersInputData.teacherSubject) {

            await batch.set(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO"), {
                USER_SCHOOL_ID: teacherInputSchoolId
            });

            await batch.set(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "IS_TEACHER_DATA", "SUBJECTS_TEACH", randomKey.randomId1), {
                TEACH_MEDIUM: teachersInputData.teacherMedium,
                TEACH_CLASS: teachersInputData.teacherClass,
                TEACH_CLASS_SEC: teachersInputData.teacherSection,
                TEACH_CLASS_SEC_SUB: teachersInputData.teacherSubject,
                VERIFIED_TEACHER: false
            });
            await batch.set(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_TEACHER", "VERIFICATION_DETAILS"), {
                USER_STATUS: 'TEACHER',
                VERIFIED_TEACHER: false,
                USER_SCHOOL_ID: teacherInputSchoolId,
            });

            if (userStatuses.length === 0) {
                await batch.update(doc(dB, "USERS", userId), {
                    USER_CURRENT_STATUS: 'TEACHER',
                });
            }

            await batch.set(doc(dB, "SCHOOLS", teacherInputSchoolId, teacherInputSchoolPathCollection, "CLASSES_DETAILS", "CLASSES", teacherMedClsSec, "TEACHERS", "VERIFICATION_REQUESTS", teachersInputData.teacherSubject, userId), {
                TEACHERS_SUBJECT_ID: randomKey.randomId1,
                TIMESTAMP: serverTimestamp()
            });
            await batch.commit().then(() => {
                setrequestSent(true)
                setparentsInputValue('')
                setteachersInputData(prev => { return { ...prev, schoolFullName: null, teacherMedium: '', teacherClass: '', teacherSection: '', teacherSubject: '', } })
                history.push('/user')
            }).catch((error) => alert(error.message));

        } else {
            if (!teachersInputData.schoolFullName) { setshowErrors(prev => { return { ...prev, schoolFullName: { error: true, helperText: 'Please select your school' }, } }) }
            if (!teachersInputData.teacherMedium) { setshowErrors(prev => { return { ...prev, teacherMedium: { error: true, helperText: 'Please select your medium' }, } }) }
            if (!teachersInputData.teacherClass) { setshowErrors(prev => { return { ...prev, teacherClass: { error: true, helperText: 'Please select your class' }, } }) }
            if (!teachersInputData.teacherSection) { setshowErrors(prev => { return { ...prev, teacherSection: { error: true, helperText: 'Please select your section' }, } }) }
            if (!teachersInputData.teacherSubject) { setshowErrors(prev => { return { ...prev, teacherSubject: { error: true, helperText: 'Please select your subject' }, } }) }
        }
    }

    const [addStatusValue, setaddStatusValue] = useState('No');

    return (
        <div className='SidebarESchoolingStatusAddStatusYesNo'>
            <div className='yesNoStatus'>
                <h3>Are you a teacher, teaching K-12 in your school :</h3>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="gender"
                        name="controlled-radio-buttons-group"
                        value={addStatusValue}
                        onChange={(e) => {
                            e.preventDefault();
                            setaddStatusValue(e.target.value);
                            setteachersInputData(prev => { return { ...prev, schoolFullName: null, teacherMedium: '', teacherClass: '', teacherSection: '', teacherSubject: '', } })
                        }}
                    >
                        <div>
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                        </div>
                    </RadioGroup>
                </FormControl>
            </div>
            {
                addStatusValue === 'Yes' && (
                    <div className='SidebarESchoolingStatusAddTeacher'>
                        {
                            princiSchoolId ? (
                                <div className='princiSchoolName'>
                                    <h2>School Name</h2>
                                    <h3>:</h3>
                                    <h4>{princiSchoolFullName}</h4>
                                </div>
                            ) : (
                                <>
                                    <Autocomplete
                                        value={teachersInputData.schoolFullName}
                                        onChange={(e, newValue) => {
                                            setteachersInputData(prev => { return { ...prev, schoolFullName: newValue } })
                                        }}
                                        inputValue={parentsInputValue}
                                        onInputChange={(e, newInputValue) => {
                                            setparentsInputValue(newInputValue);
                                            if (returnedTeachersClassList.length > 0) {
                                                returnedTeachersClassList.splice(0, returnedTeachersClassList.length)
                                            }
                                            setshowErrors(prevState => { return { ...prevState, schoolFullName: { error: false, helperText: '' } } })
                                            setteachersInputData(prevState => {
                                                return {
                                                    ...prevState,
                                                    teacherMedium: '', teacherClass: '', teacherSection: '', teacherSubject: '',
                                                }
                                            })
                                            setrequestSent(false)
                                        }}
                                        id="schoolFullName"
                                        name='schoolFullName'
                                        options={teachersOptions.map((option) => option.schoolName)}
                                        // className={clsx(Muiclasses.extralongWidth)}
                                        sx={{ width: '750px', marginBottom: '10px' }}
                                        renderInput={(params) =>
                                        (
                                            <>
                                                <TextField
                                                    {...params}
                                                    id="schoolFullName"
                                                    name='schoolFullName'
                                                    margin="normal"
                                                    label="Search your school here by school name, village ,city or district"
                                                    variant="outlined"
                                                    error={showErrors.schoolFullName.error}
                                                />
                                                <FormHelperText id="teacherMedium"><p>{showErrors.schoolFullName.helperText}</p></FormHelperText>
                                            </>)
                                        }
                                    />
                                </>
                            )
                        }

                        <FormControl error={showErrors.teacherMedium.error} name='teacherMedium' variant="outlined">
                            <InputLabel htmlFor="teacherMedium">Medium</InputLabel>
                            <Select sx={{ width: '350px', marginBottom: '10px' }} id="teacherMedium" name='teacherMedium' label="Medium : " inputProps={{ name: 'teacherMedium', id: 'teacherMedium', }}
                                value={teachersInputData.teacherMedium}
                                onChange={(e) => {
                                    e.preventDefault();
                                    setteachersInputData(prevState => { return { ...prevState, teacherMedium: e.target.value, teacherClass: '', teacherSection: '', teacherSubject: '' } })
                                    setshowErrors(prevState => { return { ...prevState, teacherMedium: { error: false, helperText: '' } } })
                                    setrequestSent(false)
                                }}

                            >
                                <MenuItem style={{ display: teachersInputData.schoolName === '' ? 'block' : 'none' }} key='' value=''></MenuItem>

                                <MenuItem style={{ display: teachersInputData.schoolName === '' ? 'none' : 'block' }} key='EnglishMedium' value='English Medium'>English Medium</MenuItem>
                                <MenuItem style={{ display: teachersInputData.schoolName === '' ? 'none' : 'block' }} key='MarathiMedium' value='Marathi Medium'>Marathi Medium</MenuItem>
                            </Select>
                            <FormHelperText id="teacherMedium"><p>{showErrors.teacherMedium.helperText}</p></FormHelperText>
                        </FormControl>

                        <FormControl error={showErrors.teacherClass.error} name='teacherClass' variant="outlined" >
                            <InputLabel htmlFor="teacherClass">Class</InputLabel>
                            <Select sx={{ width: '350px', marginBottom: '10px' }} id="teacherClass" name='teacherClass' label="Class : " inputProps={{ name: 'teacherClass', id: 'teacherClass', }}
                                value={teachersInputData.teacherClass}
                                onChange={(e) => {
                                    e.preventDefault();
                                    setteachersInputData(prevState => { return { ...prevState, teacherClass: e.target.value, teacherSection: '', teacherSubject: '' } })
                                    setshowErrors(prevState => { return { ...prevState, teacherClass: { error: false, helperText: '' } } })
                                }}
                            >
                                <MenuItem style={{ display: teachersInputData.teacherMedium === '' ? 'block' : 'none' }} aria-label="None" value="" />

                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'English Medium' && returnedTeachersClassList.includes('EngPreNurserySecA') ? 'block' : 'none' }} id='Eng Pre Nursery' key='Eng Pre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'English Medium' && returnedTeachersClassList.includes('EngNurserySecA') ? 'block' : 'none' }} id='Eng Nursery' key='Eng Nursery' value='Nursery'>Nursery</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'English Medium' && returnedTeachersClassList.includes('EngKG1SecA') ? 'block' : 'none' }} id='Eng Kindergarten 1(KG-1)' key='Eng Kindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'English Medium' && returnedTeachersClassList.includes('EngKG2SecA') ? 'block' : 'none' }} id='Eng Kindergarten 2(KG-2)' key='Eng Kindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass1SecA') ? 'block' : 'none' }} id='Eng Class 1' key='Eng Class 1' value='Class 1'>Class 1</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass2SecA') ? 'block' : 'none' }} id='Eng Class 2' key='Eng Class 2' value='Class 2'>Class 2</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass3SecA') ? 'block' : 'none' }} id='Eng Class 3' key='Eng Class 3' value='Class 3'>Class 3</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass4SecA') ? 'block' : 'none' }} id='Eng Class 4' key='Eng Class 4' value='Class 4'>Class 4</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass5SecA') ? 'block' : 'none' }} id='Eng Class 5' key='Eng Class 5' value='Class 5'>Class 5</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass6SecA') ? 'block' : 'none' }} id='Eng Class 6' key='Eng Class 6' value='Class 6'>Class 6</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass7SecA') ? 'block' : 'none' }} id='Eng Class 7' key='Eng Class 7' value='Class 7'>Class 7</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass8SecA') ? 'block' : 'none' }} id='Eng Class 8' key='Eng Class 8' value='Class 8'>Class 8</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass9SecA') ? 'block' : 'none' }} id='Eng Class 9' key='Eng Class 9' value='Class 9'>Class 9</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass10SecA') ? 'block' : 'none' }} id='Eng Class 10' key='Eng Class 10' value='Class 10'>Class 10</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass11ScienceSecA') ? 'block' : 'none' }} id='Eng Class 11(Science)' key='Eng Class 11(Science)' value='Class 11(Science)'>Class 11(Science)</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass11CommerceSecA') ? 'block' : 'none' }} id='Eng Class 11(Commerce)' key='Eng Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass12ScienceSecA') ? 'block' : 'none' }} id='Eng Class 12(Science)' key='Eng Class 12(Science)' value='Class 12(Science)'>Class 12(Science)</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'English Medium' && returnedTeachersClassList.includes('EngClass12CommerceSecA') ? 'block' : 'none' }} id='Eng Class 12(Commerce)' key='Eng Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>

                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarPreNurserySecA') ? 'block' : 'none' }} id='Mar Pre Nursery' key='Mar Pre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarNurserySecA') ? 'block' : 'none' }} id='Mar Nursery' key='Mar Nursery' value='Nursery'>Nursery</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarKG1SecA') ? 'block' : 'none' }} id='Mar Kindergarten 1(KG-1)' key='Mar Kindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarKG2SecA') ? 'block' : 'none' }} id='Mar Kindergarten 2(KG-2)' key='Mar Kindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>

                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass1SecA') ? 'block' : 'none' }} id='Mar Class 1' key='Mar Class 1' value='Class 1'>Class 1</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass2SecA') ? 'block' : 'none' }} id='Mar Class 2' key='Mar Class 2' value='Class 2'>Class 2</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass3SecA') ? 'block' : 'none' }} id='Mar Class 3' key='Mar Class 3' value='Class 3'>Class 3</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass4SecA') ? 'block' : 'none' }} id='Mar Class 4' key='Mar Class 4' value='Class 4'>Class 4</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass5SecA') ? 'block' : 'none' }} id='Mar Class 5' key='Mar Class 5' value='Class 5'>Class 5</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass6SecA') ? 'block' : 'none' }} id='Mar Class 6' key='Mar Class 6' value='Class 6'>Class 6</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass7SecA') ? 'block' : 'none' }} id='Mar Class 7' key='Mar Class 7' value='Class 7'>Class 7</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass8SecA') ? 'block' : 'none' }} id='Mar Class 8' key='Mar Class 8' value='Class 8'>Class 8</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass9SecA') ? 'block' : 'none' }} id='Mar Class 9' key='Mar Class 9' value='Class 9'>Class 9</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass10SecA') ? 'block' : 'none' }} id='Mar Class 10' key='Mar Class 10' value='Class 10'>Class 10</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass11ArtsSecA') ? 'block' : 'none' }} id='Mar Class 11(Arts)' key='Mar Class 11(Arts)' value='Class 11(Arts)'>Class 11(Arts)</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass11CommerceSecA') ? 'block' : 'none' }} id='Mar Class 11(Commerce)' key='Mar Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass12ArtsSecA') ? 'block' : 'none' }} id='Mar Class 12(Arts)' key='Mar Class 12(Arts)' value='Class 12(Arts)'>Class 12(Arts)</MenuItem>
                                <MenuItem style={{ display: teachersInputData.teacherMedium === 'Marathi Medium' && returnedTeachersClassList.includes('MarClass12CommerceSecA') ? 'block' : 'none' }} id='Mar Class 12(Commerce)' key='Mar Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>
                            </Select>
                            <FormHelperText id="teacherClass"><p>{showErrors.teacherClass.helperText}</p></FormHelperText>
                        </FormControl>

                        <FormControl error={showErrors.teacherSection.error} name='teacherSection' variant="outlined">
                            <InputLabel htmlFor="teacherSection">Section</InputLabel>
                            <Select sx={{ width: '350px', marginBottom: '10px' }} id="teacherSection" name='teacherSection' label="Section : " inputProps={{ name: 'teacherSection', id: 'teacherSection', }}
                                value={teachersInputData.teacherSection}
                                onChange={(e) => {
                                    e.preventDefault();
                                    setteachersInputData(prevState => { return { ...prevState, teacherSection: e.target.value, teacherSubject: '' } })
                                    setshowErrors(prevState => { return { ...prevState, teacherSection: { error: false, helperText: '' } } })
                                }}
                            >
                                <MenuItem style={{ display: teachersInputData.teacherClass === '' ? 'block' : 'none' }} aria-label="None" value="" />

                                <MenuItem style={{ display: teacherSections?.SectionA === true ? 'block' : 'none' }} key='Section A' value='Section A'>Section A</MenuItem>
                                <MenuItem style={{ display: teacherSections?.SectionB === true ? 'block' : 'none' }} key='Section B' value='Section B'>Section B</MenuItem>
                                <MenuItem style={{ display: teacherSections?.SectionC === true ? 'block' : 'none' }} key='Section C' value='Section C'>Section C</MenuItem>
                                <MenuItem style={{ display: teacherSections?.SectionD === true ? 'block' : 'none' }} key='Section D' value='Section D'>Section D</MenuItem>
                                <MenuItem style={{ display: teacherSections?.SectionE === true ? 'block' : 'none' }} key='Section E' value='Section E'>Section E</MenuItem>
                            </Select>
                            <FormHelperText id="teacherSection"><p>{showErrors.teacherSection.helperText}</p></FormHelperText>
                        </FormControl>

                        <FormControl error={showErrors.teacherSubject.error} name='teacherSubject' variant="outlined">
                            <InputLabel htmlFor="teacherSubject">Subject</InputLabel>
                            <Select sx={{ width: '350px', marginBottom: '10px' }} id="teacherSubject" name='teacherSubject' label="Subject : " inputProps={{ name: 'teacherSubject', id: 'teacherSubject', }}
                                value={teachersInputData.teacherSubject}
                                onChange={(e) => {
                                    e.preventDefault();
                                    setteachersInputData(prevState => { return { ...prevState, teacherSubject: e.target.value } })
                                    setshowErrors(prevState => { return { ...prevState, teacherSubject: { error: false, helperText: '' } } })
                                }}
                            >
                                <MenuItem style={{ display: teachersSubjects.length > 0 ? 'none' : 'block' }} aria-label="None" value="" />
                                {
                                    teachersSubjects.map((sub) => {
                                        return (
                                            <MenuItem key={sub} value={sub}>{sub}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            <FormHelperText id="teacherSubject"><p>{showErrors.teacherSubject.helperText}</p></FormHelperText>
                        </FormControl>

                        <LoadingButton
                            disabled={btnDisabled}
                            onClick={handleSendVerificationRequest}
                            variant='outlined'
                        >
                            Send Verification Request to School
                        </LoadingButton>
                        {/* <Button ></Button> */}
                        {requestSent && (<h5 id='requestSentTeacher'>Request Sent to School..</h5>)}

                    </div>
                )
            }
        </div>
    )
}

export default SidebarESchoolingStatusAddTeacher
