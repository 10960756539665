import { doc, onSnapshot } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import dB from '../../firebase'

function ComplaintBody(props) {

    const { index, childId, compSuggText, timestamp, schoolId, schoolPathCollection, type } = props

    const [schoolName, setschoolName] = useState('')
    useEffect(() => {
        if (schoolId && schoolPathCollection) {
            onSnapshot(doc(dB, "SCHOOLS", schoolId), (schlDoc) => {
                if (schlDoc.exists()) {
                    setschoolName(schlDoc.data()?.SCHOOL_FULL_NAME)
                }
            })
        }
    }, [schoolId, schoolPathCollection])




    return (
        <div className='suggComp'>
            <p>{index + 1}.</p>
            <div className='compMain'>
                <div className='compData'>
                    <h4>{schoolName}</h4>
                </div>
                <div className='compData'>
                    <h4>({timestamp?.toDate().toDateString()})</h4>
                </div>
                <div className='compData'>
                    <h4 style={{ fontWeight: '600', color: 'gray' }}><span style={{ color: 'black', fontWeight: '700', }}>{type} : </span>{compSuggText}</h4>
                </div>


            </div>
        </div>
    )
}

export default ComplaintBody