import React, { useEffect, useState } from 'react'
import '../Pages/SchoolVerificationRequests.css';
import Login from './Login';
import Header from '../Components/Header/Header';
import SchoolVerificationRequestsPrincipal from '../Components/VerificationRequest/SchoolVerificationRequestsPrincipal';
import SchoolVerificationRequestsTeachingStaff from '../Components/VerificationRequest/SchoolVerificationRequestsTeachingStaff';
import SchoolVerificationRequestsParents from '../Components/VerificationRequest/SchoolVerificationRequestsParents';
import SchoolVerificationRequestsStudents from '../Components/VerificationRequest/SchoolVerificationRequestsStudents';
import dB, { auth } from '../firebase';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { returnMedClsSec } from '../Constants/FunctionClsMedSec';
import { functionClsStr } from '../Constants/FunctionClsStr';



function SchoolVerificationRequests() {

    const [schoolData, setschoolData] = useState({ schoolId: '', schoolShortName: '', schoolFullName: '', schoolAffiliation: '', })
    const [classList, setclassList] = useState([]);
    useEffect(() => {
        const unSubscribe = onAuthStateChanged(auth, async (userCredentials) => {
            if (userCredentials) {
                onSnapshot(doc(dB, "SCHOOLS", userCredentials.uid), (schlDoc) => {
                    if (schlDoc.exists()) { setschoolData(prevState => { return { ...prevState, schoolId: userCredentials.uid, schoolShortName: schlDoc.data()?.SCHOOL_NAME, schoolFullName: schlDoc.data()?.SCHOOL_FULL_NAME } }) }
                })
            }
        });
        return () => {
            unSubscribe();
        }
    }, [])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (classList?.length > 0) {
                classList.splice(0, classList?.length)
            }
            if (schoolData.schoolId && schoolData.schoolShortName) {
                onSnapshot(doc(dB, "SCHOOLS", schoolData.schoolId, schoolData.schoolShortName, "CLASSES_DETAILS",), (clsDoc) => {
                    if (clsDoc.exists()) { setclassList(clsDoc.data()?.CLASS_LIST) }
                })
                onSnapshot(doc(dB, "SCHOOLS", schoolData.schoolId, schoolData.schoolShortName, "REGISTRATION_INFO",), (regDoc) => {
                    if (regDoc.exists()) { setschoolData(prevState => { return { ...prevState, schoolAffiliation: regDoc.data()?.SCHOOL_AFFILIATION } }) }
                })
            }
        }
        return () => { aaa = false }
    }, [schoolData.schoolId, schoolData.schoolShortName])

    const [showTab, setshowTab] = useState('')
    const handleSelectPrincipal = (e) => {
        e.preventDefault()
        setshowTab('PRINCIPAL')
    }
    const [principalDatabase, setprincipalDatabase] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolData.schoolId && schoolData.schoolShortName) {
                if (aaa === true) {
                    onSnapshot(collection(dB, "SCHOOLS", schoolData.schoolId, schoolData.schoolShortName, "PRINCIPAL_DETAILS", "VERIFICATION_REQUESTS"), (snap) => {
                        setprincipalDatabase(snap.docs.map((princiDOc) => ({
                            userReqId: princiDOc.id,
                            userReqData: princiDOc.data(),
                        })))
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [schoolData])

    const [staffTeachingHeader, setstaffTeachingHeader] = useState(null)
    // Set Teachers Medium, Class & Section Path
    const [staffTeacherMedClsSec, setstaffTeacherMedClsSec] = useState('')
    useEffect(() => {

        if (staffTeachingHeader) {
            var abc = returnMedClsSec(`${staffTeachingHeader?.medium} Medium`, staffTeachingHeader?.classNum, staffTeachingHeader?.classSection)
            setstaffTeacherMedClsSec(abc)
        }

    }, [staffTeachingHeader])

    console.log('====================================');
    console.log(staffTeachingHeader);
    console.log('====================================');

    // Fetch all the subjects of selected class for Teachers
    const [staffTeacherClsSecSubs, setstaffTeacherClsSecSubs] = useState([])
    useEffect(() => {
        if (staffTeacherClsSecSubs.length > 0) {
            staffTeacherClsSecSubs.splice(0, staffTeacherClsSecSubs.length)
        }
        var aaa = true
        if (aaa === true) {
            if (schoolData.schoolId && schoolData.schoolShortName && staffTeacherMedClsSec) {
                onSnapshot(doc(dB, "SCHOOLS", schoolData.schoolId, schoolData.schoolShortName, "CLASSES_DETAILS", "CLASSES", staffTeacherMedClsSec), (teachDoc) => {
                    if (teachDoc.exists()) { setstaffTeacherClsSecSubs(teachDoc.data()?.SUBJECTS_LIST) }
                })
            }
        }
        return () => { aaa = false }
    }, [schoolData, staffTeacherMedClsSec])

    const [dropdownStaff, setdropdownStaff] = useState({
        title: false,
        subTitle: false,
        Eng: false,
        EngPreNursery: false,
        EngNursery: false,
        EngKG1: false,
        EngKG2: false,
        EngClass1: false,
        EngClass2: false,
        EngClass3: false,
        EngClass4: false,
        EngClass5: false,
        EngClass6: false,
        EngClass7: false,
        EngClass8: false,
        EngClass9: false,
        EngClass10: false,
        EngClass11Commerce: false,
        EngClass11Science: false,
        EngClass12Commerce: false,
        EngClass12Science: false,
        Mar: false,
        MarPreNursery: false,
        MarNursery: false,
        MarKG1: false,
        MarKG2: false,
        MarClass1: false,
        MarClass2: false,
        MarClass3: false,
        MarClass4: false,
        MarClass5: false,
        MarClass6: false,
        MarClass7: false,
        MarClass8: false,
        MarClass9: false,
        MarClass10: false,
        MarClass11Arts: false,
        MarClass11Commerce: false,
        MarClass12Arts: false,
        MarClass12Commerce: false,
    })
    const handleDropdownStaffTitle = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, title: !dropdownStaff.title } })
        setdropdownParents(prevState => {
            return {
                ...prevState,
                title: false,
                subTitle: false,
                Eng: false,
                EngPreNursery: false,
                EngNursery: false,
                EngKG1: false,
                EngKG2: false,
                EngClass1: false,
                EngClass2: false,
                EngClass3: false,
                EngClass4: false,
                EngClass5: false,
                EngClass6: false,
                EngClass7: false,
                EngClass8: false,
                EngClass9: false,
                EngClass10: false,
                EngClass11Commerce: false,
                EngClass11Science: false,
                EngClass12Commerce: false,
                EngClass12Science: false,
                Mar: false,
                MarPreNursery: false,
                MarNursery: false,
                MarKG1: false,
                MarKG2: false,
                MarClass1: false,
                MarClass2: false,
                MarClass3: false,
                MarClass4: false,
                MarClass5: false,
                MarClass6: false,
                MarClass7: false,
                MarClass8: false,
                MarClass9: false,
                MarClass10: false,
                MarClass11Arts: false,
                MarClass11Commerce: false,
                MarClass12Arts: false,
                MarClass12Commerce: false,
            }
        })
        setdropdownStudents(prevState => {
            return {
                ...prevState,
                title: false,
                subTitle: false,
                Eng: false,
                EngPreNursery: false,
                EngNursery: false,
                EngKG1: false,
                EngKG2: false,
                EngClass1: false,
                EngClass2: false,
                EngClass3: false,
                EngClass4: false,
                EngClass5: false,
                EngClass6: false,
                EngClass7: false,
                EngClass8: false,
                EngClass9: false,
                EngClass10: false,
                EngClass11Commerce: false,
                EngClass11Science: false,
                EngClass12Commerce: false,
                EngClass12Science: false,
                Mar: false,
                MarPreNursery: false,
                MarNursery: false,
                MarKG1: false,
                MarKG2: false,
                MarClass1: false,
                MarClass2: false,
                MarClass3: false,
                MarClass4: false,
                MarClass5: false,
                MarClass6: false,
                MarClass7: false,
                MarClass8: false,
                MarClass9: false,
                MarClass10: false,
                MarClass11Arts: false,
                MarClass11Commerce: false,
                MarClass12Arts: false,
                MarClass12Commerce: false,
            }
        })
    }
    const handleDropdownStaffSubTitle = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, subTitle: !dropdownStaff.subTitle } })
    }
    const handleDropdownStaffEng = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, Eng: !dropdownStaff.Eng } })
    }
    const handleDropdownStaffEngPreNursery = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, EngPreNursery: !dropdownStaff.EngPreNursery } })
    }
    const handleDropdownStaffEngNursery = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, EngNursery: !dropdownStaff.EngNursery } })
    }
    const handleDropdownStaffEngKG1 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, EngKG1: !dropdownStaff.EngKG1 } })
    }
    const handleDropdownStaffEngKG2 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, EngKG2: !dropdownStaff.EngKG2 } })
    }
    const handleDropdownStaffEngClass1 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, EngClass1: !dropdownStaff.EngClass1 } })
    }
    const handleDropdownStaffEngClass2 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, EngClass2: !dropdownStaff.EngClass2 } })
    }
    const handleDropdownStaffEngClass3 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, EngClass3: !dropdownStaff.EngClass3 } })
    }
    const handleDropdownStaffEngClass4 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, EngClass4: !dropdownStaff.EngClass4 } })
    }
    const handleDropdownStaffEngClass5 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, EngClass5: !dropdownStaff.EngClass5 } })
    }
    const handleDropdownStaffEngClass6 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, EngClass6: !dropdownStaff.EngClass6 } })
    }
    const handleDropdownStaffEngClass7 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, EngClass7: !dropdownStaff.EngClass7 } })
    }
    const handleDropdownStaffEngClass8 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, EngClass8: !dropdownStaff.EngClass8 } })
    }
    const handleDropdownStaffEngClass9 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, EngClass9: !dropdownStaff.EngClass9 } })
    }
    const handleDropdownStaffEngClass10 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, EngClass10: !dropdownStaff.EngClass10 } })
    }
    const handleDropdownStaffEngClass11Commerce = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, EngClass11Commerce: !dropdownStaff.EngClass11Commerce } })
    }
    const handleDropdownStaffEngClass11Science = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, EngClass11Science: !dropdownStaff.EngClass11Science } })
    }
    const handleDropdownStaffEngClass12Commerce = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, EngClass12Commerce: !dropdownStaff.EngClass12Commerce } })
    }
    const handleDropdownStaffEngClass12Science = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, EngClass12Science: !dropdownStaff.EngClass12Science } })
    }
    const handleDropdownStaffMar = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, Mar: !dropdownStaff.Mar } })
    }
    const handleDropdownStaffMarPreNursery = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, MarPreNursery: !dropdownStaff.MarPreNursery } })
    }
    const handleDropdownStaffMarNursery = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, MarNursery: !dropdownStaff.MarNursery } })
    }
    const handleDropdownStaffMarKG1 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, MarKG1: !dropdownStaff.MarKG1 } })
    }
    const handleDropdownStaffMarKG2 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, MarKG2: !dropdownStaff.MarKG2 } })
    }
    const handleDropdownStaffMarClass1 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, MarClass1: !dropdownStaff.MarClass1 } })
    }
    const handleDropdownStaffMarClass2 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, MarClass2: !dropdownStaff.MarClass2 } })
    }
    const handleDropdownStaffMarClass3 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, MarClass3: !dropdownStaff.MarClass3 } })
    }
    const handleDropdownStaffMarClass4 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, MarClass4: !dropdownStaff.MarClass4 } })
    }
    const handleDropdownStaffMarClass5 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, MarClass5: !dropdownStaff.MarClass5 } })
    }
    const handleDropdownStaffMarClass6 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, MarClass6: !dropdownStaff.MarClass6 } })
    }
    const handleDropdownStaffMarClass7 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, MarClass7: !dropdownStaff.MarClass7 } })
    }
    const handleDropdownStaffMarClass8 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, MarClass8: !dropdownStaff.MarClass8 } })
    }
    const handleDropdownStaffMarClass9 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, MarClass9: !dropdownStaff.MarClass9 } })
    }
    const handleDropdownStaffMarClass10 = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, MarClass10: !dropdownStaff.MarClass10 } })
    }
    const handleDropdownStaffMarClass11Arts = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, MarClass11Arts: !dropdownStaff.MarClass11Arts } })
    }
    const handleDropdownStaffMarClass11Commerce = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, MarClass11Commerce: !dropdownStaff.MarClass11Commerce } })
    }
    const handleDropdownStaffMarClass12Arts = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, MarClass12Arts: !dropdownStaff.MarClass12Arts } })
    }
    const handleDropdownStaffMarClass12Commerce = (e) => {
        e.preventDefault();
        setdropdownStaff(prevState => { return { ...prevState, MarClass12Commerce: !dropdownStaff.MarClass12Commerce } })
    }

    const handleSelectTeachingStaff = (e) => {
        e.preventDefault();
        setshowTab('TEACHING_STAFF')

        var xyz = functionClsStr(e.target.id)
        setstaffTeachingHeader(xyz)
    }


    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [parentsDataSet, setparentsDataSet] = useState(null)

    // Set Parents Medium, Class & Section Path
    const [parentsMedClsSec, setparentsMedClsSec] = useState('')
    useEffect(() => {

        if (parentsDataSet) {
            var abc = returnMedClsSec(`${parentsDataSet?.medium} Medium`, parentsDataSet?.classNum, parentsDataSet?.section)
            setparentsMedClsSec(abc)
        }

    }, [parentsDataSet])

    const [parentsData, setparentsData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolData.schoolId && schoolData.schoolShortName && parentsMedClsSec) {
                onSnapshot(collection(dB, "SCHOOLS", schoolData.schoolId, schoolData.schoolShortName, "CLASSES_DETAILS", "CLASSES", parentsMedClsSec, "PARENTS", "VERIFICATION_REQUESTS", "VERIFICATION_REQUESTS_DATA"), (snap) => {
                    setparentsData(snap.docs.map((parDoc) => ({
                        parentsId: parDoc.id,
                        parentsData: parDoc.data(),
                    })))
                })
            }
        }
        return () => { aaa = false }
    }, [schoolData, parentsMedClsSec])

    const [dropdownParents, setdropdownParents] = useState({
        title: false,
        subTitle: false,
        Eng: false,
        EngPreNursery: false,
        EngNursery: false,
        EngKG1: false,
        EngKG2: false,
        EngClass1: false,
        EngClass2: false,
        EngClass3: false,
        EngClass4: false,
        EngClass5: false,
        EngClass6: false,
        EngClass7: false,
        EngClass8: false,
        EngClass9: false,
        EngClass10: false,
        EngClass11Commerce: false,
        EngClass11Science: false,
        EngClass12Commerce: false,
        EngClass12Science: false,
        Mar: false,
        MarPreNursery: false,
        MarNursery: false,
        MarKG1: false,
        MarKG2: false,
        MarClass1: false,
        MarClass2: false,
        MarClass3: false,
        MarClass4: false,
        MarClass5: false,
        MarClass6: false,
        MarClass7: false,
        MarClass8: false,
        MarClass9: false,
        MarClass10: false,
        MarClass11Arts: false,
        MarClass11Commerce: false,
        MarClass12Arts: false,
        MarClass12Commerce: false,
    })
    const handleDropdownParentsSubTitle = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, subTitle: !dropdownParents.subTitle } })
        setdropdownStaff(prevState => {
            return {
                ...prevState,
                title: false,
                subTitle: false,
                Eng: false,
                EngPreNursery: false,
                EngNursery: false,
                EngKG1: false,
                EngKG2: false,
                EngClass1: false,
                EngClass2: false,
                EngClass3: false,
                EngClass4: false,
                EngClass5: false,
                EngClass6: false,
                EngClass7: false,
                EngClass8: false,
                EngClass9: false,
                EngClass10: false,
                EngClass11Commerce: false,
                EngClass11Science: false,
                EngClass12Commerce: false,
                EngClass12Science: false,
                Mar: false,
                MarPreNursery: false,
                MarNursery: false,
                MarKG1: false,
                MarKG2: false,
                MarClass1: false,
                MarClass2: false,
                MarClass3: false,
                MarClass4: false,
                MarClass5: false,
                MarClass6: false,
                MarClass7: false,
                MarClass8: false,
                MarClass9: false,
                MarClass10: false,
                MarClass11Arts: false,
                MarClass11Commerce: false,
                MarClass12Arts: false,
                MarClass12Commerce: false,
            }
        })
        setdropdownStudents(prevState => {
            return {
                ...prevState,
                title: false,
                subTitle: false,
                Eng: false,
                EngPreNursery: false,
                EngNursery: false,
                EngKG1: false,
                EngKG2: false,
                EngClass1: false,
                EngClass2: false,
                EngClass3: false,
                EngClass4: false,
                EngClass5: false,
                EngClass6: false,
                EngClass7: false,
                EngClass8: false,
                EngClass9: false,
                EngClass10: false,
                EngClass11Commerce: false,
                EngClass11Science: false,
                EngClass12Commerce: false,
                EngClass12Science: false,
                Mar: false,
                MarPreNursery: false,
                MarNursery: false,
                MarKG1: false,
                MarKG2: false,
                MarClass1: false,
                MarClass2: false,
                MarClass3: false,
                MarClass4: false,
                MarClass5: false,
                MarClass6: false,
                MarClass7: false,
                MarClass8: false,
                MarClass9: false,
                MarClass10: false,
                MarClass11Arts: false,
                MarClass11Commerce: false,
                MarClass12Arts: false,
                MarClass12Commerce: false,
            }
        })
    }
    const handleDropdownParentsEng = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, Eng: !dropdownParents.Eng } })
    }
    const handleDropdownParentsEngPreNursery = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, EngPreNursery: !dropdownParents.EngPreNursery } })
    }
    const handleDropdownParentsEngNursery = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, EngNursery: !dropdownParents.EngNursery } })
    }
    const handleDropdownParentsEngKG1 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, EngKG1: !dropdownParents.EngKG1 } })
    }
    const handleDropdownParentsEngKG2 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, EngKG2: !dropdownParents.EngKG2 } })
    }
    const handleDropdownParentsEngClass1 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, EngClass1: !dropdownParents.EngClass1 } })
    }
    const handleDropdownParentsEngClass2 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, EngClass2: !dropdownParents.EngClass2 } })
    }
    const handleDropdownParentsEngClass3 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, EngClass3: !dropdownParents.EngClass3 } })
    }
    const handleDropdownParentsEngClass4 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, EngClass4: !dropdownParents.EngClass4 } })
    }
    const handleDropdownParentsEngClass5 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, EngClass5: !dropdownParents.EngClass5 } })
    }
    const handleDropdownParentsEngClass6 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, EngClass6: !dropdownParents.EngClass6 } })
    }
    const handleDropdownParentsEngClass7 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, EngClass7: !dropdownParents.EngClass7 } })
    }
    const handleDropdownParentsEngClass8 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, EngClass8: !dropdownParents.EngClass8 } })
    }
    const handleDropdownParentsEngClass9 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, EngClass9: !dropdownParents.EngClass9 } })
    }
    const handleDropdownParentsEngClass10 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, EngClass10: !dropdownParents.EngClass10 } })
    }
    const handleDropdownParentsEngClass11Commerce = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, EngClass11Commerce: !dropdownParents.EngClass11Commerce } })
    }
    const handleDropdownParentsEngClass11Science = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, EngClass11Science: !dropdownParents.EngClass11Science } })
    }
    const handleDropdownParentsEngClass12Commerce = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, EngClass12Commerce: !dropdownParents.EngClass12Commerce } })
    }
    const handleDropdownParentsEngClass12Science = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, EngClass12Science: !dropdownParents.EngClass12Science } })
    }
    const handleDropdownParentsMar = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, Mar: !dropdownParents.Mar } })
    }
    const handleDropdownParentsMarPreNursery = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, MarPreNursery: !dropdownParents.MarPreNursery } })
    }
    const handleDropdownParentsMarNursery = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, MarNursery: !dropdownParents.MarNursery } })
    }
    const handleDropdownParentsMarKG1 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, MarKG1: !dropdownParents.MarKG1 } })
    }
    const handleDropdownParentsMarKG2 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, MarKG2: !dropdownParents.MarKG2 } })
    }
    const handleDropdownParentsMarClass1 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, MarClass1: !dropdownParents.MarClass1 } })
    }
    const handleDropdownParentsMarClass2 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, MarClass2: !dropdownParents.MarClass2 } })
    }
    const handleDropdownParentsMarClass3 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, MarClass3: !dropdownParents.MarClass3 } })
    }
    const handleDropdownParentsMarClass4 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, MarClass4: !dropdownParents.MarClass4 } })
    }
    const handleDropdownParentsMarClass5 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, MarClass5: !dropdownParents.MarClass5 } })
    }
    const handleDropdownParentsMarClass6 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, MarClass6: !dropdownParents.MarClass6 } })
    }
    const handleDropdownParentsMarClass7 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, MarClass7: !dropdownParents.MarClass7 } })
    }
    const handleDropdownParentsMarClass8 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, MarClass8: !dropdownParents.MarClass8 } })
    }
    const handleDropdownParentsMarClass9 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, MarClass9: !dropdownParents.MarClass9 } })
    }
    const handleDropdownParentsMarClass10 = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, MarClass10: !dropdownParents.MarClass10 } })
    }
    const handleDropdownParentsMarClass11Arts = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, MarClass11Arts: !dropdownParents.MarClass11Arts } })
    }
    const handleDropdownParentsMarClass11Commerce = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, MarClass11Commerce: !dropdownParents.MarClass11Commerce } })
    }
    const handleDropdownParentsMarClass12Arts = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, MarClass12Arts: !dropdownParents.MarClass12Arts } })
    }
    const handleDropdownParentsMarClass12Commerce = (e) => {
        e.preventDefault();
        setdropdownParents(prevState => { return { ...prevState, MarClass12Commerce: !dropdownParents.MarClass12Commerce } })
    }

    const handleSelectParents = (e) => {
        e.preventDefault()
        setshowTab('PARENTS')

        var xyz = functionClsStr(e.target.id)
        setparentsDataSet(xyz)
    }


    // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    const [studentsDataSet, setstudentsDataSet] = useState(null)
    // Set Students Medium, Class & Section Path
    const [studentsMedClsSec, setstudentsMedClsSec] = useState('')
    useEffect(() => {

        if (studentsDataSet) {
            var abc = returnMedClsSec(`${studentsDataSet?.medium} Medium`, studentsDataSet?.classNum, studentsDataSet?.section)
            setstudentsMedClsSec(abc)
        }

    }, [studentsDataSet])

    // Fetch all the Students of selected class
    // const [isStudentMounted, setisStudentMounted] = useState(false)
    const [studentsInfo, setstudentsInfo] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolData.schoolId && schoolData.schoolShortName && studentsMedClsSec) {
                onSnapshot(collection(dB, "SCHOOLS", schoolData.schoolId, schoolData.schoolShortName, "CLASSES_DETAILS", "CLASSES", studentsMedClsSec, "STUDENTS", "VERIFICATION_REQUESTS", "VERIFICATION_REQUESTS_DATA"), (snap) => {
                    setstudentsInfo(snap.docs.map((studDoc) => ({
                        studentId: studDoc.id,
                        studentData: studDoc.data(),
                    })))
                })
            }
        }
        return () => { aaa = false }
    }, [schoolData, studentsMedClsSec])

    const [dropdownStudents, setdropdownStudents] = useState({
        title: false,
        subTitle: false,
        Eng: false,
        EngClass8: false,
        EngClass9: false,
        EngClass10: false,
        EngClass11Commerce: false,
        EngClass11Science: false,
        EngClass12Commerce: false,
        EngClass12Science: false,
        Mar: false,
        MarClass8: false,
        MarClass9: false,
        MarClass10: false,
        MarClass11Arts: false,
        MarClass11Commerce: false,
        MarClass12Arts: false,
        MarClass12Commerce: false,
    })
    const handleDropdownStudentsSubTitle = (e) => {
        e.preventDefault();
        setdropdownStudents(prevState => { return { ...prevState, subTitle: !dropdownStudents.subTitle } })
        setdropdownStaff(prevState => {
            return {
                ...prevState,
                title: false,
                subTitle: false,
                Eng: false,
                EngPreNursery: false,
                EngNursery: false,
                EngKG1: false,
                EngKG2: false,
                EngClass1: false,
                EngClass2: false,
                EngClass3: false,
                EngClass4: false,
                EngClass5: false,
                EngClass6: false,
                EngClass7: false,
                EngClass8: false,
                EngClass9: false,
                EngClass10: false,
                EngClass11Commerce: false,
                EngClass11Science: false,
                EngClass12Commerce: false,
                EngClass12Science: false,
                Mar: false,
                MarPreNursery: false,
                MarNursery: false,
                MarKG1: false,
                MarKG2: false,
                MarClass1: false,
                MarClass2: false,
                MarClass3: false,
                MarClass4: false,
                MarClass5: false,
                MarClass6: false,
                MarClass7: false,
                MarClass8: false,
                MarClass9: false,
                MarClass10: false,
                MarClass11Arts: false,
                MarClass11Commerce: false,
                MarClass12Arts: false,
                MarClass12Commerce: false,
            }
        })
        setdropdownParents(prevState => {
            return {
                ...prevState,
                title: false,
                subTitle: false,
                Eng: false,
                EngPreNursery: false,
                EngNursery: false,
                EngKG1: false,
                EngKG2: false,
                EngClass1: false,
                EngClass2: false,
                EngClass3: false,
                EngClass4: false,
                EngClass5: false,
                EngClass6: false,
                EngClass7: false,
                EngClass8: false,
                EngClass9: false,
                EngClass10: false,
                EngClass11Commerce: false,
                EngClass11Science: false,
                EngClass12Commerce: false,
                EngClass12Science: false,
                Mar: false,
                MarPreNursery: false,
                MarNursery: false,
                MarKG1: false,
                MarKG2: false,
                MarClass1: false,
                MarClass2: false,
                MarClass3: false,
                MarClass4: false,
                MarClass5: false,
                MarClass6: false,
                MarClass7: false,
                MarClass8: false,
                MarClass9: false,
                MarClass10: false,
                MarClass11Arts: false,
                MarClass11Commerce: false,
                MarClass12Arts: false,
                MarClass12Commerce: false,
            }
        })
    }
    const handleDropdownStudentsEng = (e) => {
        e.preventDefault();
        setdropdownStudents(prevState => { return { ...prevState, Eng: !dropdownStudents.Eng } })
    }
    const handleDropdownStudentsEngClass8 = (e) => {
        e.preventDefault();
        setdropdownStudents(prevState => { return { ...prevState, EngClass8: !dropdownStudents.EngClass8 } })
    }
    const handleDropdownStudentsEngClass9 = (e) => {
        e.preventDefault();
        setdropdownStudents(prevState => { return { ...prevState, EngClass9: !dropdownStudents.EngClass9 } })
    }
    const handleDropdownStudentsEngClass10 = (e) => {
        e.preventDefault();
        setdropdownStudents(prevState => { return { ...prevState, EngClass10: !dropdownStudents.EngClass10 } })
    }
    const handleDropdownStudentsEngClass11Commerce = (e) => {
        e.preventDefault();
        setdropdownStudents(prevState => { return { ...prevState, EngClass11Commerce: !dropdownStudents.EngClass11Commerce } })
    }
    const handleDropdownStudentsEngClass11Science = (e) => {
        e.preventDefault();
        setdropdownStudents(prevState => { return { ...prevState, EngClass11Science: !dropdownStudents.EngClass11Science } })
    }
    const handleDropdownStudentsEngClass12Commerce = (e) => {
        e.preventDefault();
        setdropdownStudents(prevState => { return { ...prevState, EngClass12Commerce: !dropdownStudents.EngClass12Commerce } })
    }
    const handleDropdownStudentsEngClass12Science = (e) => {
        e.preventDefault();
        setdropdownStudents(prevState => { return { ...prevState, EngClass12Science: !dropdownStudents.EngClass12Science } })
    }
    const handleDropdownStudentsMar = (e) => {
        e.preventDefault();
        setdropdownStudents(prevState => { return { ...prevState, Mar: !dropdownStudents.Mar } })
    }
    const handleDropdownStudentsMarClass8 = (e) => {
        e.preventDefault();
        setdropdownStudents(prevState => { return { ...prevState, MarClass8: !dropdownStudents.MarClass8 } })
    }
    const handleDropdownStudentsMarClass9 = (e) => {
        e.preventDefault();
        setdropdownStudents(prevState => { return { ...prevState, MarClass9: !dropdownStudents.MarClass9 } })
    }
    const handleDropdownStudentsMarClass10 = (e) => {
        e.preventDefault();
        setdropdownStudents(prevState => { return { ...prevState, MarClass10: !dropdownStudents.MarClass10 } })
    }
    const handleDropdownStudentsMarClass11Arts = (e) => {
        e.preventDefault();
        setdropdownStudents(prevState => { return { ...prevState, MarClass11Arts: !dropdownStudents.MarClass11Arts } })
    }
    const handleDropdownStudentsMarClass11Commerce = (e) => {
        e.preventDefault();
        setdropdownStudents(prevState => { return { ...prevState, MarClass11Commerce: !dropdownStudents.MarClass11Commerce } })
    }
    const handleDropdownStudentsMarClass12Arts = (e) => {
        e.preventDefault();
        setdropdownStudents(prevState => { return { ...prevState, MarClass12Arts: !dropdownStudents.MarClass12Arts } })
    }
    const handleDropdownStudentsMarClass12Commerce = (e) => {
        e.preventDefault();
        setdropdownStudents(prevState => { return { ...prevState, MarClass12Commerce: !dropdownStudents.MarClass12Commerce } })
    }


    const handleSelectStudents = (e) => {
        e.preventDefault();
        setshowTab('STUDENTS')

        var xyz = functionClsStr(e.target.id)
        setstudentsDataSet(xyz)
    }

    // students
    const [studentsClassess, setstudentsClassess] = useState({
        EngClass8: '', EngClass9: '', MarClass12Commerce: '', MarClass12Arts: '',
        MarClass11Commerce: '',
        MarClass11Arts: '', MarClass10: '', MarClass9: '', MarClass8: '', EngClass12Science: '', EngClass12Commerce: '',
        EngClass11Science: '', EngClass11Commerce: '', EngClass10: '',
    })
    const [studentsRequests, setstudentsRequests] = useState({
        EngClass8SecA: [], EngClass8SecB: [], EngClass8SecC: [], EngClass8SecD: [], EngClass8SecE: [],
        EngClass9SecA: [], EngClass9SecB: [], EngClass9SecC: [], EngClass9SecD: [], EngClass9SecE: [],
        EngClass10SecA: [], EngClass10SecB: [], EngClass10SecC: [], EngClass10SecD: [], EngClass10SecE: [],
        EngClass11CommerceSecA: [], EngClass11CommerceSecB: [], EngClass11CommerceSecC: [], EngClass11CommerceSecD: [], EngClass11CommerceSecE: [],
        EngClass11ScienceSecA: [], EngClass11ScienceSecB: [], EngClass11ScienceSecC: [], EngClass11ScienceSecD: [], EngClass11ScienceSecE: [],
        EngClass12CommerceSecA: [], EngClass12CommerceSecB: [], EngClass12CommerceSecC: [], EngClass12CommerceSecD: [], EngClass12CommerceSecE: [],
        EngClass12ScienceSecA: [], EngClass12ScienceSecB: [], EngClass12ScienceSecC: [], EngClass12ScienceSecD: [], EngClass12ScienceSecE: [],

        MarClass8SecA: [], MarClass8SecB: [], MarClass8SecC: [], MarClass8SecD: [], MarClass8SecE: [],
        MarClass9SecA: [], MarClass9SecB: [], MarClass9SecC: [], MarClass9SecD: [], MarClass9SecE: [],
        MarClass10SecA: [], MarClass10SecB: [], MarClass10SecC: [], MarClass10SecD: [], MarClass10SecE: [],
        MarClass11ArtsSecA: [], MarClass11ArtsSecB: [], MarClass11ArtsSecC: [], MarClass11ArtsSecD: [], MarClass11ArtsSecE: [],
        MarClass11CommerceSecA: [], MarClass11CommerceSecB: [], MarClass11CommerceSecC: [], MarClass11CommerceSecD: [], MarClass11CommerceSecE: [],
        MarClass12ArtsSecA: [], MarClass12ArtsSecB: [], MarClass12ArtsSecC: [], MarClass12ArtsSecD: [], MarClass12ArtsSecE: [],
        MarClass12CommerceSecA: [], MarClass12CommerceSecB: [], MarClass12CommerceSecC: [], MarClass12CommerceSecD: [], MarClass12CommerceSecE: [],
    })
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            let studentsClasses = [
                'EngClass8SecA', 'EngClass8SecB', 'EngClass8SecC', 'EngClass8SecD', 'EngClass8SecE',
                'EngClass9SecA', 'EngClass9SecB', 'EngClass9SecC', 'EngClass9SecD', 'EngClass9SecE',
                'EngClass10SecA', 'EngClass10SecB', 'EngClass10SecC', 'EngClass10SecD', 'EngClass10SecE',
                'EngClass11CommerceSecA', 'EngClass11CommerceSecB', 'EngClass11CommerceSecC', 'EngClass11CommerceSecD', 'EngClass11CommerceSecE',
                'EngClass11ScienceSecA', 'EngClass11ScienceSecB', 'EngClass11ScienceSecC', 'EngClass11ScienceSecD', 'EngClass11ScienceSecE',
                'EngClass12CommerceSecA', 'EngClass12CommerceSecB', 'EngClass12CommerceSecC', 'EngClass12CommerceSecD', 'EngClass12CommerceSecE',
                'EngClass12ScienceSecA', 'EngClass12ScienceSecB', 'EngClass12ScienceSecC', 'EngClass12ScienceSecD', 'EngClass12ScienceSecE',

                'MarClass8SecA', 'MarClass8SecB', 'MarClass8SecC', 'MarClass8SecD', 'MarClass8SecE',
                'MarClass9SecA', 'MarClass9SecB', 'MarClass9SecC', 'MarClass9SecD', 'MarClass9SecE',
                'MarClass10SecA', 'MarClass10SecB', 'MarClass10SecC', 'MarClass10SecD', 'MarClass10SecE',
                'MarClass11CommerceSecA', 'MarClass11CommerceSecB', 'MarClass11CommerceSecC', 'MarClass11CommerceSecD', 'MarClass11CommerceSecE',
                'MarClass11ArtsSecA', 'MarClass11ArtsSecB', 'MarClass11ArtsSecC', 'MarClass11ArtsSecD', 'MarClass11ArtsSecE',
                'MarClass12CommerceSecA', 'MarClass12CommerceSecB', 'MarClass12CommerceSecC', 'MarClass12CommerceSecD', 'MarClass12CommerceSecE',
                'MarClass12ArtsSecA', 'MarClass12ArtsSecB', 'MarClass12ArtsSecC', 'MarClass12ArtsSecD', 'MarClass12ArtsSecE',
            ]
            if (schoolData.schoolId && schoolData.schoolShortName) {
                studentsClasses.forEach(stuCls => {
                    onSnapshot(collection(dB, "SCHOOLS", schoolData.schoolId, schoolData.schoolShortName, "CLASSES_DETAILS", "CLASSES", stuCls, "STUDENTS", "VERIFICATION_REQUESTS", "VERIFICATION_REQUESTS_DATA"), (snap) => {
                        const aaa = snap.docs.map((stuDoc) => stuDoc.id)
                        setstudentsRequests(prev => { return { ...prev, [stuCls]: [...aaa] } })
                    })
                })
            }
        }
        return () => { aaa = false }
    }, [schoolData.schoolId, schoolData.schoolShortName])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            setstudentsClassess(prev => {
                return {
                    ...prev,
                    EngClass8: studentsRequests.EngClass8SecA?.length + studentsRequests.EngClass8SecB?.length + studentsRequests.EngClass8SecC?.length + studentsRequests.EngClass8SecD?.length + studentsRequests.EngClass8SecE?.length,
                    EngClass9: studentsRequests.EngClass9SecA?.length + studentsRequests.EngClass9SecB?.length + studentsRequests.EngClass9SecC?.length + studentsRequests.EngClass9SecD?.length + studentsRequests.EngClass9SecE?.length,
                    EngClass10: studentsRequests.EngClass10SecA?.length + studentsRequests.EngClass10SecB?.length + studentsRequests.EngClass10SecC?.length + studentsRequests.EngClass10SecD?.length + studentsRequests.EngClass10SecE?.length,
                    EngClass11Commerce: studentsRequests.EngClass11CommerceSecA?.length + studentsRequests.EngClass11CommerceSecB?.length + studentsRequests.EngClass11CommerceSecC?.length + studentsRequests.EngClass11CommerceSecD?.length + studentsRequests.EngClass11CommerceSecE?.length,
                    EngClass12Commerce: studentsRequests.EngClass12CommerceSecA?.length + studentsRequests.EngClass12CommerceSecB?.length + studentsRequests.EngClass12CommerceSecC?.length + studentsRequests.EngClass12CommerceSecD?.length + studentsRequests.EngClass12CommerceSecE?.length,
                    EngClass11Science: studentsRequests.EngClass11ScienceSecA?.length + studentsRequests.EngClass11ScienceSecB?.length + studentsRequests.EngClass11ScienceSecC?.length + studentsRequests.EngClass11ScienceSecD?.length + studentsRequests.EngClass11ScienceSecE?.length,
                    EngClass12Science: studentsRequests.EngClass12ScienceSecA?.length + studentsRequests.EngClass12ScienceSecB?.length + studentsRequests.EngClass12ScienceSecC?.length + studentsRequests.EngClass12ScienceSecD?.length + studentsRequests.EngClass12ScienceSecE?.length,

                    MarClass8: studentsRequests.MarClass8SecA?.length + studentsRequests.MarClass8SecB?.length + studentsRequests.MarClass8SecC?.length + studentsRequests.MarClass8SecD?.length + studentsRequests.MarClass8SecE?.length,
                    MarClass9: studentsRequests.MarClass9SecA?.length + studentsRequests.MarClass9SecB?.length + studentsRequests.MarClass9SecC?.length + studentsRequests.MarClass9SecD?.length + studentsRequests.MarClass9SecE?.length,
                    MarClass10: studentsRequests.MarClass10SecA?.length + studentsRequests.MarClass10SecB?.length + studentsRequests.MarClass10SecC?.length + studentsRequests.MarClass10SecD?.length + studentsRequests.MarClass10SecE?.length,
                    MarClass11Commerce: studentsRequests.Class11CommerceSecA?.length + studentsRequests.Class11CommerceSecB?.length + studentsRequests.Class11CommerceSecC?.length + studentsRequests.Class11CommerceSecD?.length + studentsRequests.Class11CommerceSecE?.length,
                    MarClass12Commerce: studentsRequests.Class12CommerceSecA?.length + studentsRequests.Class12CommerceSecB?.length + studentsRequests.Class12CommerceSecC?.length + studentsRequests.Class12CommerceSecD?.length + studentsRequests.Class12CommerceSecE?.length,
                    MarClass11Arts: studentsRequests.Class11ArtsSecA?.length + studentsRequests.Class11ArtsSecB?.length + studentsRequests.Class11ArtsSecC?.length + studentsRequests.Class11ArtsSecD?.length + studentsRequests.Class11ArtsSecE?.length,
                    MarClass12Arts: studentsRequests.Class12ArtsSecA?.length + studentsRequests.Class12ArtsSecB?.length + studentsRequests.Class12ArtsSecC?.length + studentsRequests.Class12ArtsSecD?.length + studentsRequests.Class12ArtsSecE?.length,
                }
            })
        }
        return () => { aaa = false }
    }, [studentsRequests])


    // parents
    const [parentsClassess, setparentsClassess] = useState({
        EngPreNursery: '', EngNursery: '', EngKG1: '', EngKG2: '', EngClass1: '', EngClass2: '', EngClass3: '', EngClass4: '', EngClass5: '', EngClass6: '', EngClass7: '', EngClass8: '',
        EngClass9: '', EngClass10: '', EngClass11Science: '', EngClass11Commerce: '', EngClass12Science: '', EngClass12Commerce: '',
        MarPreNursery: '', MarNursery: '', MarKG1: '', MarKG2: '', MarClass1: '', MarClass2: '', MarClass3: '', MarClass4: '', MarClass5: '', MarClass6: '', MarClass7: '', MarClass8: '',
        MarClass9: '', MarClass10: '', MarClass11Arts: '', MarClass11Commerce: '', MarClass12Commerce: '', MarClass12Arts: '',
    })
    const [parentsRequests, setparentsRequests] = useState({
        EngPreNurserySecA: [], EngPreNurserySecB: [], EngPreNurserySecC: [], EngPreNurserySecD: [], EngPreNurserySecE: [],
        EngNurserySecA: [], EngNurserySecB: [], EngNurserySecC: [], EngNurserySecD: [], EngNurserySecE: [],
        EngKG1SecA: [], EngKG1SecB: [], EngKG1SecC: [], EngKG1SecD: [], EngKG1SecE: [],
        EngKG2SecA: [], EngKG2SecB: [], EngKG2SecC: [], EngKG2SecD: [], EngKG2SecE: [],
        EngClass1SecA: [], EngClass1SecB: [], EngClass1SecC: [], EngClass1SecD: [], EngClass1SecE: [],
        EngClass2SecA: [], EngClass2SecB: [], EngClass2SecC: [], EngClass2SecD: [], EngClass2SecE: [],
        EngClass3SecA: [], EngClass3SecB: [], EngClass3SecC: [], EngClass3SecD: [], EngClass3SecE: [],
        EngClass4SecA: [], EngClass4SecB: [], EngClass4SecC: [], EngClass4SecD: [], EngClass4SecE: [],
        EngClass5SecA: [], EngClass5SecB: [], EngClass5SecC: [], EngClass5SecD: [], EngClass5SecE: [],
        EngClass6SecA: [], EngClass6SecB: [], EngClass6SecC: [], EngClass6SecD: [], EngClass6SecE: [],
        EngClass7SecA: [], EngClass7SecB: [], EngClass7SecC: [], EngClass7SecD: [], EngClass7SecE: [],
        EngClass8SecA: [], EngClass8SecB: [], EngClass8SecC: [], EngClass8SecD: [], EngClass8SecE: [],
        EngClass9SecA: [], EngClass9SecB: [], EngClass9SecC: [], EngClass9SecD: [], EngClass9SecE: [],
        EngClass10SecA: [], EngClass10SecB: [], EngClass10SecC: [], EngClass10SecD: [], EngClass10SecE: [],
        EngClass11CommerceSecA: [], EngClass11CommerceSecB: [], EngClass11CommerceSecC: [], EngClass11CommerceSecD: [], EngClass11CommerceSecE: [],
        EngClass11ScienceSecA: [], EngClass11ScienceSecB: [], EngClass11ScienceSecC: [], EngClass11ScienceSecD: [], EngClass11ScienceSecE: [],
        EngClass12CommerceSecA: [], EngClass12CommerceSecB: [], EngClass12CommerceSecC: [], EngClass12CommerceSecD: [], EngClass12CommerceSecE: [],
        EngClass12ScienceSecA: [], EngClass12ScienceSecB: [], EngClass12ScienceSecC: [], EngClass12ScienceSecD: [], EngClass12ScienceSecE: [],

        MarPreNurserySecA: [], MarPreNurserySecB: [], MarPreNurserySecC: [], MarPreNurserySecD: [], MarPreNurserySecE: [],
        MarNurserySecA: [], MarNurserySecB: [], MarNurserySecC: [], MarNurserySecD: [], MarNurserySecE: [],
        MarKG1SecA: [], MarKG1SecB: [], MarKG1SecC: [], MarKG1SecD: [], MarKG1SecE: [],
        MarKG2SecA: [], MarKG2SecB: [], MarKG2SecC: [], MarKG2SecD: [], MarKG2SecE: [],
        MarClass1SecA: [], MarClass1SecB: [], MarClass1SecC: [], MarClass1SecD: [], MarClass1SecE: [],
        MarClass2SecA: [], MarClass2SecB: [], MarClass2SecC: [], MarClass2SecD: [], MarClass2SecE: [],
        MarClass3SecA: [], MarClass3SecB: [], MarClass3SecC: [], MarClass3SecD: [], MarClass3SecE: [],
        MarClass4SecA: [], MarClass4SecB: [], MarClass4SecC: [], MarClass4SecD: [], MarClass4SecE: [],
        MarClass5SecA: [], MarClass5SecB: [], MarClass5SecC: [], MarClass5SecD: [], MarClass5SecE: [],
        MarClass6SecA: [], MarClass6SecB: [], MarClass6SecC: [], MarClass6SecD: [], MarClass6SecE: [],
        MarClass7SecA: [], MarClass7SecB: [], MarClass7SecC: [], MarClass7SecD: [], MarClass7SecE: [],
        MarClass8SecA: [], MarClass8SecB: [], MarClass8SecC: [], MarClass8SecD: [], MarClass8SecE: [],
        MarClass9SecA: [], MarClass9SecB: [], MarClass9SecC: [], MarClass9SecD: [], MarClass9SecE: [],
        MarClass10SecA: [], MarClass10SecB: [], MarClass10SecC: [], MarClass10SecD: [], MarClass10SecE: [],
        MarClass11ArtsSecA: [], MarClass11ArtsSecB: [], MarClass11ArtsSecC: [], MarClass11ArtsSecD: [], MarClass11ArtsSecE: [],
        MarClass11CommerceSecA: [], MarClass11CommerceSecB: [], MarClass11CommerceSecC: [], MarClass11CommerceSecD: [], MarClass11CommerceSecE: [],
        MarClass12ArtsSecA: [], MarClass12ArtsSecB: [], MarClass12ArtsSecC: [], MarClass12ArtsSecD: [], MarClass12ArtsSecE: [],
        MarClass12CommerceSecA: [], MarClass12CommerceSecB: [], MarClass12CommerceSecC: [], MarClass12CommerceSecD: [], MarClass12CommerceSecE: [],
    })
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            let parentsClasses = [
                'EngPreNurserySecA', 'EngPreNurserySecB', 'EngPreNurserySecC', 'EngPreNurserySecD', 'EngPreNurserySecE',
                'EngNurserySecA', 'EngNurserySecB', 'EngNurserySecC', 'EngNurserySecD', 'EngNurserySecE',
                'EngKG1SecA', 'EngKG1SecB', 'EngKG1SecC', 'EngKG1SecD', 'EngKG1SecE',
                'EngKG2SecA', 'EngKG2SecB', 'EngKG2SecC', 'EngKG2SecD', 'EngKG2SecE',
                'EngClass1SecA', 'EngClass1SecB', 'EngClass1SecC', 'EngClass1SecD', 'EngClass1SecE',
                'EngClass2SecA', 'EngClass2SecB', 'EngClass2SecC', 'EngClass2SecD', 'EngClass2SecE',
                'EngClass3SecA', 'EngClass3SecB', 'EngClass3SecC', 'EngClass3SecD', 'EngClass3SecE',
                'EngClass4SecA', 'EngClass4SecB', 'EngClass4SecC', 'EngClass4SecD', 'EngClass4SecE',
                'EngClass5SecA', 'EngClass5SecB', 'EngClass5SecC', 'EngClass5SecD', 'EngClass5SecE',
                'EngClass6SecA', 'EngClass6SecB', 'EngClass6SecC', 'EngClass6SecD', 'EngClass6SecE',
                'EngClass7SecA', 'EngClass7SecB', 'EngClass7SecC', 'EngClass7SecD', 'EngClass7SecE',
                'EngClass8SecA', 'EngClass8SecB', 'EngClass8SecC', 'EngClass8SecD', 'EngClass8SecE',
                'EngClass9SecA', 'EngClass9SecB', 'EngClass9SecC', 'EngClass9SecD', 'EngClass9SecE',
                'EngClass10SecA', 'EngClass10SecB', 'EngClass10SecC', 'EngClass10SecD', 'EngClass10SecE',
                'EngClass11CommerceSecA', 'EngClass11CommerceSecB', 'EngClass11CommerceSecC', 'EngClass11CommerceSecD', 'EngClass11CommerceSecE',
                'EngClass11ScienceSecA', 'EngClass11ScienceSecB', 'EngClass11ScienceSecC', 'EngClass11ScienceSecD', 'EngClass11ScienceSecE',
                'EngClass12CommerceSecA', 'EngClass12CommerceSecB', 'EngClass12CommerceSecC', 'EngClass12CommerceSecD', 'EngClass12CommerceSecE',
                'EngClass12ScienceSecA', 'EngClass12ScienceSecB', 'EngClass12ScienceSecC', 'EngClass12ScienceSecD', 'EngClass12ScienceSecE',

                'MarPreNurserySecA', 'MarPreNurserySecB', 'MarPreNurserySecC', 'MarPreNurserySecD', 'MarPreNurserySecE',
                'MarNurserySecA', 'MarNurserySecB', 'MarNurserySecC', 'MarNurserySecD', 'MarNurserySecE',
                'MarKG1SecA', 'MarKG1SecB', 'MarKG1SecC', 'MarKG1SecD', 'MarKG1SecE',
                'MarKG2SecA', 'MarKG2SecB', 'MarKG2SecC', 'MarKG2SecD', 'MarKG2SecE',
                'MarClass1SecA', 'MarClass1SecB', 'MarClass1SecC', 'MarClass1SecD', 'MarClass1SecE',
                'MarClass2SecA', 'MarClass2SecB', 'MarClass2SecC', 'MarClass2SecD', 'MarClass2SecE',
                'MarClass3SecA', 'MarClass3SecB', 'MarClass3SecC', 'MarClass3SecD', 'MarClass3SecE',
                'MarClass4SecA', 'MarClass4SecB', 'MarClass4SecC', 'MarClass4SecD', 'MarClass4SecE',
                'MarClass5SecA', 'MarClass5SecB', 'MarClass5SecC', 'MarClass5SecD', 'MarClass5SecE',
                'MarClass6SecA', 'MarClass6SecB', 'MarClass6SecC', 'MarClass6SecD', 'MarClass6SecE',
                'MarClass7SecA', 'MarClass7SecB', 'MarClass7SecC', 'MarClass7SecD', 'MarClass7SecE',
                'MarClass8SecA', 'MarClass8SecB', 'MarClass8SecC', 'MarClass8SecD', 'MarClass8SecE',
                'MarClass9SecA', 'MarClass9SecB', 'MarClass9SecC', 'MarClass9SecD', 'MarClass9SecE',
                'MarClass10SecA', 'MarClass10SecB', 'MarClass10SecC', 'MarClass10SecD', 'MarClass10SecE',
                'MarClass11CommerceSecA', 'MarClass11CommerceSecB', 'MarClass11CommerceSecC', 'MarClass11CommerceSecD', 'MarClass11CommerceSecE',
                'MarClass11ArtsSecA', 'MarClass11ArtsSecB', 'MarClass11ArtsSecC', 'MarClass11ArtsSecD', 'MarClass11ArtsSecE',
                'MarClass12CommerceSecA', 'MarClass12CommerceSecB', 'MarClass12CommerceSecC', 'MarClass12CommerceSecD', 'MarClass12CommerceSecE',
                'MarClass12ArtsSecA', 'MarClass12ArtsSecB', 'MarClass12ArtsSecC', 'MarClass12ArtsSecD', 'MarClass12ArtsSecE',
            ]
            if (schoolData.schoolId && schoolData.schoolShortName) {
                parentsClasses.forEach(parentCls => {

                    onSnapshot(collection(dB, "SCHOOLS", schoolData.schoolId, schoolData.schoolShortName, "CLASSES_DETAILS", "CLASSES", parentCls, "PARENTS", "VERIFICATION_REQUESTS", "VERIFICATION_REQUESTS_DATA"), (snap) => {
                        snap.docs.forEach((childDoc) => {
                            onSnapshot(collection(dB, "SCHOOLS", schoolData.schoolId, schoolData.schoolShortName, "CLASSES_DETAILS", "CLASSES", parentCls, "PARENTS", "VERIFICATION_REQUESTS", "VERIFICATION_REQUESTS_DATA", childDoc.id, "CHILDS_LIST"), (snapShot) => {
                                const bbb = snapShot.docs.map((document) => document.id)
                                setparentsRequests(prev => { return { ...prev, [parentCls]: [...bbb] } })
                            })
                        })
                    })
                    // dB.collection('SCHOOLS').doc(schoolData.schoolId)
                    //     .collection(schoolData.schoolShortName).doc('CLASSES_DETAILS')
                    //     .collection('CLASSES').doc(parentCls)
                    //     .collection('PARENTS').doc('VERIFICATION_REQUESTS')
                    //     .collection('VERIFICATION_REQUESTS_DATA')
                    //     .onSnapshot(snap => {

                    //         // setparentsRequests(prev => { return { ...prev, [parentCls]: [...aaa] } })
                    //     })
                })
            }
        }
        return () => { aaa = false }
    }, [schoolData.schoolId, schoolData.schoolShortName])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            setparentsClassess(prev => {
                return {
                    ...prev,
                    EngPreNursery: parentsRequests.EngPreNurserySecA?.length + parentsRequests.EngPreNurserySecB?.length + parentsRequests.EngPreNurserySecC?.length + parentsRequests.EngPreNurserySecD?.length + parentsRequests.EngPreNurserySecE?.length,
                    EngNursery: parentsRequests.EngNurserySecA?.length + parentsRequests.EngNurserySecB?.length + parentsRequests.EngNurserySecC?.length + parentsRequests.EngNurserySecD?.length + parentsRequests.EngNurserySecE?.length,
                    EngKG1: parentsRequests.EngKG1SecA?.length + parentsRequests.EngKG1SecB?.length + parentsRequests.EngKG1SecC?.length + parentsRequests.EngKG1SecD?.length + parentsRequests.EngKG1SecE?.length,
                    EngKG2: parentsRequests.EngKG2SecA?.length + parentsRequests.EngKG2SecB?.length + parentsRequests.EngKG2SecC?.length + parentsRequests.EngKG2SecD?.length + parentsRequests.EngKG2SecE?.length,
                    EngClass1: parentsRequests.EngClass1SecA?.length + parentsRequests.EngClass1SecB?.length + parentsRequests.EngClass1SecC?.length + parentsRequests.EngClass1SecD?.length + parentsRequests.EngClass1SecE?.length,
                    EngClass2: parentsRequests.EngClass2SecA?.length + parentsRequests.EngClass2SecB?.length + parentsRequests.EngClass2SecC?.length + parentsRequests.EngClass2SecD?.length + parentsRequests.EngClass2SecE?.length,
                    EngClass3: parentsRequests.EngClass3SecA?.length + parentsRequests.EngClass3SecB?.length + parentsRequests.EngClass3SecC?.length + parentsRequests.EngClass3SecD?.length + parentsRequests.EngClass3SecE?.length,
                    EngClass4: parentsRequests.EngClass4SecA?.length + parentsRequests.EngClass4SecB?.length + parentsRequests.EngClass4SecC?.length + parentsRequests.EngClass4SecD?.length + parentsRequests.EngClass4SecE?.length,
                    EngClass5: parentsRequests.EngClass5SecA?.length + parentsRequests.EngClass5SecB?.length + parentsRequests.EngClass5SecC?.length + parentsRequests.EngClass5SecD?.length + parentsRequests.EngClass5SecE?.length,
                    EngClass6: parentsRequests.EngClass6SecA?.length + parentsRequests.EngClass6SecB?.length + parentsRequests.EngClass6SecC?.length + parentsRequests.EngClass6SecD?.length + parentsRequests.EngClass6SecE?.length,
                    EngClass7: parentsRequests.EngClass7SecA?.length + parentsRequests.EngClass7SecB?.length + parentsRequests.EngClass7SecC?.length + parentsRequests.EngClass7SecD?.length + parentsRequests.EngClass7SecE?.length,
                    EngClass8: parentsRequests.EngClass8SecA?.length + parentsRequests.EngClass8SecB?.length + parentsRequests.EngClass8SecC?.length + parentsRequests.EngClass8SecD?.length + parentsRequests.EngClass8SecE?.length,
                    EngClass9: parentsRequests.EngClass9SecA?.length + parentsRequests.EngClass9SecB?.length + parentsRequests.EngClass9SecC?.length + parentsRequests.EngClass9SecD?.length + parentsRequests.EngClass9SecE?.length,
                    EngClass10: parentsRequests.EngClass10SecA?.length + parentsRequests.EngClass10SecB?.length + parentsRequests.EngClass10SecC?.length + parentsRequests.EngClass10SecD?.length + parentsRequests.EngClass10SecE?.length,
                    EngClass11Commerce: parentsRequests.EngClass11CommerceSecA?.length + parentsRequests.EngClass11CommerceSecB?.length + parentsRequests.EngClass11CommerceSecC?.length + parentsRequests.EngClass11CommerceSecD?.length + parentsRequests.EngClass11CommerceSecE?.length,
                    EngClass12Commerce: parentsRequests.EngClass12CommerceSecA?.length + parentsRequests.EngClass12CommerceSecB?.length + parentsRequests.EngClass12CommerceSecC?.length + parentsRequests.EngClass12CommerceSecD?.length + parentsRequests.EngClass12CommerceSecE?.length,
                    EngClass11Science: parentsRequests.EngClass11ScienceSecA?.length + parentsRequests.EngClass11ScienceSecB?.length + parentsRequests.EngClass11ScienceSecC?.length + parentsRequests.EngClass11ScienceSecD?.length + parentsRequests.EngClass11ScienceSecE?.length,
                    EngClass12Science: parentsRequests.EngClass12ScienceSecA?.length + parentsRequests.EngClass12ScienceSecB?.length + parentsRequests.EngClass12ScienceSecC?.length + parentsRequests.EngClass12ScienceSecD?.length + parentsRequests.EngClass12ScienceSecE?.length,

                    MarPreNursery: parentsRequests.MarPreNurserySecA?.length + parentsRequests.MarPreNurserySecB?.length + parentsRequests.MarPreNurserySecC?.length + parentsRequests.MarPreNurserySecD?.length + parentsRequests.MarPreNurserySecE?.length,
                    MarNursery: parentsRequests.MarNurserySecA?.length + parentsRequests.MarNurserySecB?.length + parentsRequests.MarNurserySecC?.length + parentsRequests.MarNurserySecD?.length + parentsRequests.MarNurserySecE?.length,
                    MarKG1: parentsRequests.MarKG1SecA?.length + parentsRequests.MarKG1SecB?.length + parentsRequests.MarKG1SecC?.length + parentsRequests.MarKG1SecD?.length + parentsRequests.MarKG1SecE?.length,
                    MarKG2: parentsRequests.MarKG2SecA?.length + parentsRequests.MarKG2SecB?.length + parentsRequests.MarKG2SecC?.length + parentsRequests.MarKG2SecD?.length + parentsRequests.MarKG2SecE?.length,
                    MarClass1: parentsRequests.MarClass1SecA?.length + parentsRequests.MarClass1SecB?.length + parentsRequests.MarClass1SecC?.length + parentsRequests.MarClass1SecD?.length + parentsRequests.MarClass1SecE?.length,
                    MarClass2: parentsRequests.MarClass2SecA?.length + parentsRequests.MarClass2SecB?.length + parentsRequests.MarClass2SecC?.length + parentsRequests.MarClass2SecD?.length + parentsRequests.MarClass2SecE?.length,
                    MarClass3: parentsRequests.MarClass3SecA?.length + parentsRequests.MarClass3SecB?.length + parentsRequests.MarClass3SecC?.length + parentsRequests.MarClass3SecD?.length + parentsRequests.MarClass3SecE?.length,
                    MarClass4: parentsRequests.MarClass4SecA?.length + parentsRequests.MarClass4SecB?.length + parentsRequests.MarClass4SecC?.length + parentsRequests.MarClass4SecD?.length + parentsRequests.MarClass4SecE?.length,
                    MarClass5: parentsRequests.MarClass5SecA?.length + parentsRequests.MarClass5SecB?.length + parentsRequests.MarClass5SecC?.length + parentsRequests.MarClass5SecD?.length + parentsRequests.MarClass5SecE?.length,
                    MarClass6: parentsRequests.MarClass6SecA?.length + parentsRequests.MarClass6SecB?.length + parentsRequests.MarClass6SecC?.length + parentsRequests.MarClass6SecD?.length + parentsRequests.MarClass6SecE?.length,
                    MarClass7: parentsRequests.MarClass7SecA?.length + parentsRequests.MarClass7SecB?.length + parentsRequests.MarClass7SecC?.length + parentsRequests.MarClass7SecD?.length + parentsRequests.MarClass7SecE?.length,
                    MarClass8: parentsRequests.MarClass8SecA?.length + parentsRequests.MarClass8SecB?.length + parentsRequests.MarClass8SecC?.length + parentsRequests.MarClass8SecD?.length + parentsRequests.MarClass8SecE?.length,
                    MarClass9: parentsRequests.MarClass9SecA?.length + parentsRequests.MarClass9SecB?.length + parentsRequests.MarClass9SecC?.length + parentsRequests.MarClass9SecD?.length + parentsRequests.MarClass9SecE?.length,
                    MarClass10: parentsRequests.MarClass10SecA?.length + parentsRequests.MarClass10SecB?.length + parentsRequests.MarClass10SecC?.length + parentsRequests.MarClass10SecD?.length + parentsRequests.MarClass10SecE?.length,
                    MarClass11Commerce: parentsRequests.Class11CommerceSecA?.length + parentsRequests.Class11CommerceSecB?.length + parentsRequests.Class11CommerceSecC?.length + parentsRequests.Class11CommerceSecD?.length + parentsRequests.Class11CommerceSecE?.length,
                    MarClass12Commerce: parentsRequests.Class12CommerceSecA?.length + parentsRequests.Class12CommerceSecB?.length + parentsRequests.Class12CommerceSecC?.length + parentsRequests.Class12CommerceSecD?.length + parentsRequests.Class12CommerceSecE?.length,
                    MarClass11Arts: parentsRequests.Class11ArtsSecA?.length + parentsRequests.Class11ArtsSecB?.length + parentsRequests.Class11ArtsSecC?.length + parentsRequests.Class11ArtsSecD?.length + parentsRequests.Class11ArtsSecE?.length,
                    MarClass12Arts: parentsRequests.Class12ArtsSecA?.length + parentsRequests.Class12ArtsSecB?.length + parentsRequests.Class12ArtsSecC?.length + parentsRequests.Class12ArtsSecD?.length + parentsRequests.Class12ArtsSecE?.length,
                }
            })
        }
        return () => { aaa = false }
    }, [parentsRequests])



    return (
        <>
            {
                schoolData.schoolId === '' ? (
                    <Login />
                ) : (
                    <div className='schoolverificationrequests'>
                        <Header />

                        <div className='schoolverificationrequests__container'>

                            <div className='schoolverificationrequests__containerTop'>
                                <h3>{schoolData.schoolFullName}</h3>
                                <h5>(K to 12th)</h5>
                                <h4>{schoolData.schoolAffiliation}</h4>
                                <hr />
                            </div>
                            <div className='schoolverificationrequests__containerBottom'>
                                <nav className='schoolverificationrequests__containerLeft'>
                                    <div id='schoolStaff' className='schoolStaff'>
                                        <div name='title mainDropdown' className='title' onClick={handleDropdownStaffTitle}>
                                            <h2>School Staff</h2>
                                            {dropdownStaff.title === true ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                                        </div>
                                        <div style={{ display: dropdownStaff.title === true ? 'flex' : 'none' }} className='schoolStaffTitles'>
                                            <div className='title_subtitles' onClick={handleSelectPrincipal}>
                                                <h3>Principal / HM <span style={{ color: 'var(--connected-orange)', display: principalDatabase?.length ? 'contents' : 'none' }}>({principalDatabase?.length})</span></h3>
                                            </div>
                                            <div className='schoolStaffTitlesTeaching'>
                                                <div name='subTitle' className='title_subtitles' onClick={handleDropdownStaffSubTitle}>
                                                    <h3>Teaching Staff</h3>
                                                    {dropdownStaff.subTitle === true ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                                                </div>
                                                <div style={{ display: dropdownStaff.subTitle === true ? 'flex' : 'none' }} className='schoolStaffTitlesTeachingMedium'>
                                                    <div>
                                                        <div name='medium' className='title_subtitlesMediums' onClick={handleDropdownStaffEng}>
                                                            <h4>English Medium</h4>
                                                            {dropdownStaff.Eng === true ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownStaff.Eng === true ? 'flex' : 'none' }} className='schoolStaffTitlesTeachingMediumClass'>

                                                            <div style={{ display: classList.includes('EngPreNurserySecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='EngPreNursery' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffEngPreNursery}>
                                                                    <h5>Pre Nursery</h5>
                                                                    {dropdownStaff.EngPreNursery === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.EngPreNursery === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngPreNurserySecA' key='staffEngPreNurserySecA' style={{ display: classList.includes('EngPreNurserySecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngPreNurserySecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngPreNurserySecB' style={{ display: classList.includes('EngPreNurserySecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngPreNurserySecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngPreNurserySecC' style={{ display: classList.includes('EngPreNurserySecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngPreNurserySecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngPreNurserySecD' style={{ display: classList.includes('EngPreNurserySecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngPreNurserySecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngPreNurserySecE' style={{ display: classList.includes('EngPreNurserySecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngPreNurserySecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('EngNurserySecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='EngNursery' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffEngNursery}>
                                                                    <h5>Nursery</h5>
                                                                    {dropdownStaff.EngNursery === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.EngNursery === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngNurserySecA' key='staffEngNurserySecA' style={{ display: classList.includes('EngNurserySecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngNurserySecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngNurserySecB' style={{ display: classList.includes('EngNurserySecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngNurserySecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngNurserySecC' style={{ display: classList.includes('EngNurserySecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngNurserySecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngNurserySecD' style={{ display: classList.includes('EngNurserySecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngNurserySecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngNurserySecE' style={{ display: classList.includes('EngNurserySecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngNurserySecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('EngKG1SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='EngKG1' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffEngKG1}>
                                                                    <h5>Kindergarten 1 (KG-1)</h5>
                                                                    {dropdownStaff.EngKG1 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.EngKG1 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngKG1SecA' key='staffEngKG1SecA' style={{ display: classList.includes('EngKG1SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngKG1SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngKG1SecB' style={{ display: classList.includes('EngKG1SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngKG1SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngKG1SecC' style={{ display: classList.includes('EngKG1SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngKG1SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngKG1SecD' style={{ display: classList.includes('EngKG1SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngKG1SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngKG1SecE' style={{ display: classList.includes('EngKG1SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngKG1SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('EngKG2SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='engMediumKG2' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffEngKG2}>
                                                                    <h5>Kindergarten 2 (KG-2)</h5>
                                                                    {dropdownStaff.EngKG2 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.EngKG2 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngKG2SecA' style={{ display: classList.includes('EngKG2SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngKG2SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngKG2SecB' style={{ display: classList.includes('EngKG2SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngKG2SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngKG2SecC' style={{ display: classList.includes('EngKG2SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngKG2SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngKG2SecD' style={{ display: classList.includes('EngKG2SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngKG2SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngKG2SecE' style={{ display: classList.includes('EngKG2SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngKG2SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('EngClass1SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='engMediumClass1' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffEngClass1}>
                                                                    <h5>Class 1</h5>
                                                                    {dropdownStaff.EngClass1 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.EngClass1 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass1SecA' style={{ display: classList.includes('EngClass1SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass1SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass1SecB' style={{ display: classList.includes('EngClass1SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass1SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass1SecC' style={{ display: classList.includes('EngClass1SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass1SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass1SecD' style={{ display: classList.includes('EngClass1SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass1SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass1SecE' style={{ display: classList.includes('EngClass1SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass1SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('EngClass2SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='engMediumClass2' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffEngClass2}>
                                                                    <h5>Class 2</h5>
                                                                    {dropdownStaff.EngClass2 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.EngClass2 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass2SecA' style={{ display: classList.includes('EngClass2SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass2SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass2SecB' style={{ display: classList.includes('EngClass2SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass2SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass2SecC' style={{ display: classList.includes('EngClass2SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass2SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass2SecD' style={{ display: classList.includes('EngClass2SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass2SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass2SecE' style={{ display: classList.includes('EngClass2SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass2SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('EngClass3SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='engMediumClass3' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffEngClass3}>
                                                                    <h5>Class 3</h5>
                                                                    {dropdownStaff.EngClass3 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.EngClass3 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass3SecA' style={{ display: classList.includes('EngClass3SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass3SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass3SecB' style={{ display: classList.includes('EngClass3SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass3SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass3SecC' style={{ display: classList.includes('EngClass3SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass3SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass3SecD' style={{ display: classList.includes('EngClass3SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass3SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass3SecE' style={{ display: classList.includes('EngClass3SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass3SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('EngClass4SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='engMediumClass4' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffEngClass4}>
                                                                    <h5>Class 4</h5>
                                                                    {dropdownStaff.EngClass4 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.EngClass4 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass4SecA' style={{ display: classList.includes('EngClass4SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass4SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass4SecB' style={{ display: classList.includes('EngClass4SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass4SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass4SecC' style={{ display: classList.includes('EngClass4SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass4SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass4SecD' style={{ display: classList.includes('EngClass4SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass4SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass4SecE' style={{ display: classList.includes('EngClass4SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass4SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('EngClass5SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='engMediumClass5' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffEngClass5}>
                                                                    <h5>Class 5</h5>
                                                                    {dropdownStaff.EngClass5 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.EngClass5 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass5SecA' style={{ display: classList.includes('EngClass5SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass5SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass5SecB' style={{ display: classList.includes('EngClass5SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass5SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass5SecC' style={{ display: classList.includes('EngClass5SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass5SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass5SecD' style={{ display: classList.includes('EngClass5SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass5SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass5SecE' style={{ display: classList.includes('EngClass5SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass5SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('EngClass6SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='engMediumClass6' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffEngClass6}>
                                                                    <h5>Class 6</h5>
                                                                    {dropdownStaff.EngClass6 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.EngClass6 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass6SecA' style={{ display: classList.includes('EngClass6SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass6SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass6SecB' style={{ display: classList.includes('EngClass6SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass6SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass6SecC' style={{ display: classList.includes('EngClass6SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass6SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass6SecD' style={{ display: classList.includes('EngClass6SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass6SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass6SecE' style={{ display: classList.includes('EngClass6SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass6SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('EngClass7SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='engMediumClass7' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffEngClass7}>
                                                                    <h5>Class 7</h5>
                                                                    {dropdownStaff.EngClass7 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.EngClass7 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass7SecA' style={{ display: classList.includes('EngClass7SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass7SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass7SecB' style={{ display: classList.includes('EngClass7SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass7SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass7SecC' style={{ display: classList.includes('EngClass7SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass7SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass7SecD' style={{ display: classList.includes('EngClass7SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass7SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass7SecE' style={{ display: classList.includes('EngClass7SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass7SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('EngClass8SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='engMediumClass8' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffEngClass8}>
                                                                    <h5>Class 8</h5>
                                                                    {dropdownStaff.EngClass8 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.EngClass8 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass8SecA' style={{ display: classList.includes('EngClass8SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass8SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass8SecB' style={{ display: classList.includes('EngClass8SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass8SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass8SecC' style={{ display: classList.includes('EngClass8SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass8SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass8SecD' style={{ display: classList.includes('EngClass8SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass8SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass8SecE' style={{ display: classList.includes('EngClass8SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass8SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('EngClass9SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='engMediumClass9' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffEngClass9}>
                                                                    <h5>Class 9</h5>
                                                                    {dropdownStaff.EngClass9 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.EngClass9 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass9SecA' style={{ display: classList.includes('EngClass9SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass9SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass9SecB' style={{ display: classList.includes('EngClass9SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass9SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass9SecC' style={{ display: classList.includes('EngClass9SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass9SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass9SecD' style={{ display: classList.includes('EngClass9SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass9SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass9SecE' style={{ display: classList.includes('EngClass9SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass9SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('EngClass10SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='engMediumClass10' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffEngClass10}>
                                                                    <h5>Class 10</h5>
                                                                    {dropdownStaff.EngClass10 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.EngClass10 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass10SecA' style={{ display: classList.includes('EngClass10SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass10SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass10SecB' style={{ display: classList.includes('EngClass10SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass10SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass10SecC' style={{ display: classList.includes('EngClass10SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass10SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass10SecD' style={{ display: classList.includes('EngClass10SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass10SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass10SecE' style={{ display: classList.includes('EngClass10SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass10SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('EngClass11CommerceSecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='engMediumclass11Commerce' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffEngClass11Commerce}>
                                                                    <h5>Class 11 Commerce</h5>
                                                                    {dropdownStaff.EngClass11Commerce === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.EngClass11Commerce === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass11CommerceSecA' style={{ display: classList.includes('EngClass11CommerceSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass11CommerceSecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass11CommerceSecB' style={{ display: classList.includes('EngClass11CommerceSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass11CommerceSecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass11CommerceSecC' style={{ display: classList.includes('EngClass11CommerceSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass11CommerceSecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass11CommerceSecD' style={{ display: classList.includes('EngClass11CommerceSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass11CommerceSecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass11CommerceSecE' style={{ display: classList.includes('EngClass11CommerceSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass11CommerceSecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('EngClass11ScienceSecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='engMediumclass11Science' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffEngClass11Science}>
                                                                    <h5>Class 11 Science</h5>
                                                                    {dropdownStaff.EngClass11Science === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.EngClass11Science === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass11ScienceSecA' style={{ display: classList.includes('EngClass11ScienceSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass11ScienceSecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass11ScienceSecB' style={{ display: classList.includes('EngClass11ScienceSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass11ScienceSecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass11ScienceSecC' style={{ display: classList.includes('EngClass11ScienceSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass11ScienceSecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass11ScienceSecD' style={{ display: classList.includes('EngClass11ScienceSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass11ScienceSecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass11ScienceSecE' style={{ display: classList.includes('EngClass11ScienceSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass11ScienceSecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('EngClass12CommerceSecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='engMediumclass12Commerce' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffEngClass12Commerce}>
                                                                    <h5>Class 12 Commerce</h5>
                                                                    {dropdownStaff.EngClass12Commerce === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.EngClass12Commerce === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass12CommerceSecA' style={{ display: classList.includes('EngClass12CommerceSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass12CommerceSecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass12CommerceSecB' style={{ display: classList.includes('EngClass12CommerceSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass12CommerceSecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass12CommerceSecC' style={{ display: classList.includes('EngClass12CommerceSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass12CommerceSecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass12CommerceSecD' style={{ display: classList.includes('EngClass12CommerceSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass12CommerceSecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass12CommerceSecE' style={{ display: classList.includes('EngClass12CommerceSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass12CommerceSecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('EngClass12ScienceSecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='engMediumclass12Science' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffEngClass12Science}>
                                                                    <h5>Class 12 Science</h5>
                                                                    {dropdownStaff.EngClass12Science === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.EngClass12Science === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass12ScienceSecA' style={{ display: classList.includes('EngClass12ScienceSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass12ScienceSecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass12ScienceSecB' style={{ display: classList.includes('EngClass12ScienceSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass12ScienceSecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass12ScienceSecC' style={{ display: classList.includes('EngClass12ScienceSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass12ScienceSecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass12ScienceSecD' style={{ display: classList.includes('EngClass12ScienceSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass12ScienceSecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='EngClass12ScienceSecE' style={{ display: classList.includes('EngClass12ScienceSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='EngClass12ScienceSecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div name='medium' className='title_subtitlesMediums' onClick={handleDropdownStaffMar}>
                                                            <h4>Marathi Medium</h4>
                                                            {dropdownStaff.Mar === true ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownStaff.Mar === true ? 'flex' : 'none' }} className='schoolStaffTitlesTeachingMediumClass'>

                                                            <div style={{ display: classList.includes('MarPreNurserySecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='MarPreNursery' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffMarPreNursery}>
                                                                    <h5>Pre Nursery</h5>
                                                                    {dropdownStaff.MarPreNursery === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.MarPreNursery === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarPreNurserySecA' key='staffMarPreNurserySecA' style={{ display: classList.includes('MarPreNurserySecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarPreNurserySecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarPreNurserySecB' style={{ display: classList.includes('MarPreNurserySecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarPreNurserySecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarPreNurserySecC' style={{ display: classList.includes('MarPreNurserySecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarPreNurserySecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarPreNurserySecD' style={{ display: classList.includes('MarPreNurserySecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarPreNurserySecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarPreNurserySecE' style={{ display: classList.includes('MarPreNurserySecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarPreNurserySecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('MarNurserySecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='MarNursery' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffMarNursery}>
                                                                    <h5>Nursery</h5>
                                                                    {dropdownStaff.MarNursery === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.MarNursery === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarNurserySecA' key='staffMarNurserySecA' style={{ display: classList.includes('MarNurserySecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarNurserySecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarNurserySecB' style={{ display: classList.includes('MarNurserySecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarNurserySecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarNurserySecC' style={{ display: classList.includes('MarNurserySecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarNurserySecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarNurserySecD' style={{ display: classList.includes('MarNurserySecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarNurserySecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarNurserySecE' style={{ display: classList.includes('MarNurserySecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarNurserySecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('MarKG1SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='MarKG1' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffMarKG1}>
                                                                    <h5>Kindergarten 1 (KG-1)</h5>
                                                                    {dropdownStaff.MarKG1 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.MarKG1 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarKG1SecA' key='staffMarKG1SecA' style={{ display: classList.includes('MarKG1SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarKG1SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarKG1SecB' style={{ display: classList.includes('MarKG1SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarKG1SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarKG1SecC' style={{ display: classList.includes('MarKG1SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarKG1SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarKG1SecD' style={{ display: classList.includes('MarKG1SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarKG1SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarKG1SecE' style={{ display: classList.includes('MarKG1SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarKG1SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('MarKG2SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='engMediumKG2' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffMarKG2}>
                                                                    <h5>Kindergarten 2 (KG-2)</h5>
                                                                    {dropdownStaff.MarKG2 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.MarKG2 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarKG2SecA' style={{ display: classList.includes('MarKG2SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarKG2SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarKG2SecB' style={{ display: classList.includes('MarKG2SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarKG2SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarKG2SecC' style={{ display: classList.includes('MarKG2SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarKG2SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarKG2SecD' style={{ display: classList.includes('MarKG2SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarKG2SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarKG2SecE' style={{ display: classList.includes('MarKG2SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarKG2SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('MarClass1SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                                <div name='engMediumClass1' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffMarClass1}>
                                                                    <h5>Class 1</h5>
                                                                    {dropdownStaff.MarClass1 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.MarClass1 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass1SecA' style={{ display: classList.includes('MarClass1SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass1SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass1SecB' style={{ display: classList.includes('MarClass1SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass1SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass1SecC' style={{ display: classList.includes('MarClass1SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass1SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass1SecD' style={{ display: classList.includes('MarClass1SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass1SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass1SecE' style={{ display: classList.includes('MarClass1SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass1SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('MarClass1SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                                <div name='marMediumClass1' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffMarClass1}>
                                                                    <h5>Class 1</h5>
                                                                    {dropdownStaff.MarClass1 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.MarClass1 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass1SecA' style={{ display: classList.includes('MarClass1SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass1SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass1SecB' style={{ display: classList.includes('MarClass1SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass1SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass1SecC' style={{ display: classList.includes('MarClass1SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass1SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass1SecD' style={{ display: classList.includes('MarClass1SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass1SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass1SecE' style={{ display: classList.includes('MarClass1SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass1SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('MarClass2SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                                <div name='marMediumClass2' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffMarClass2}>
                                                                    <h5>Class 2</h5>
                                                                    {dropdownStaff.MarClass2 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.MarClass2 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass2SecA' style={{ display: classList.includes('MarClass2SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass2SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass2SecB' style={{ display: classList.includes('MarClass2SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass2SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass2SecC' style={{ display: classList.includes('MarClass2SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass2SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass2SecD' style={{ display: classList.includes('MarClass2SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass2SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass2SecE' style={{ display: classList.includes('MarClass2SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass2SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('MarClass3SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                                <div name='marMediumClass3' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffMarClass3}>
                                                                    <h5>Class 3</h5>
                                                                    {dropdownStaff.MarClass3 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.MarClass3 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass3SecA' style={{ display: classList.includes('MarClass3SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass3SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass3SecB' style={{ display: classList.includes('MarClass3SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass3SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass3SecC' style={{ display: classList.includes('MarClass3SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass3SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass3SecD' style={{ display: classList.includes('MarClass3SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass3SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass3SecE' style={{ display: classList.includes('MarClass3SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass3SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('MarClass4SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                                <div name='marMediumClass4' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffMarClass4}>
                                                                    <h5>Class 4</h5>
                                                                    {dropdownStaff.MarClass4 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.MarClass4 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass4SecA' style={{ display: classList.includes('MarClass4SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass4SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass4SecB' style={{ display: classList.includes('MarClass4SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass4SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass4SecC' style={{ display: classList.includes('MarClass4SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass4SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass4SecD' style={{ display: classList.includes('MarClass4SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass4SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass4SecE' style={{ display: classList.includes('MarClass4SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass4SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('MarClass5SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                                <div name='marMediumClass5' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffMarClass5}>
                                                                    <h5>Class 5</h5>
                                                                    {dropdownStaff.MarClass5 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.MarClass5 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass5SecA' style={{ display: classList.includes('MarClass5SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass5SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass5SecB' style={{ display: classList.includes('MarClass5SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass5SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass5SecC' style={{ display: classList.includes('MarClass5SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass5SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass5SecD' style={{ display: classList.includes('MarClass5SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass5SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass5SecE' style={{ display: classList.includes('MarClass5SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass5SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('MarClass6SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                                <div name='marMediumClass6' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffMarClass6}>
                                                                    <h5>Class 6</h5>
                                                                    {dropdownStaff.MarClass6 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.MarClass6 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass6SecA' style={{ display: classList.includes('MarClass6SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass6SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass6SecB' style={{ display: classList.includes('MarClass6SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass6SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass6SecC' style={{ display: classList.includes('MarClass6SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass6SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass6SecD' style={{ display: classList.includes('MarClass6SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass6SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass6SecE' style={{ display: classList.includes('MarClass6SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass6SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('MarClass7SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                                <div name='marMediumClass7' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffMarClass7}>
                                                                    <h5>Class 7</h5>
                                                                    {dropdownStaff.MarClass7 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.MarClass7 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass7SecA' style={{ display: classList.includes('MarClass7SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass7SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass7SecB' style={{ display: classList.includes('MarClass7SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass7SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass7SecC' style={{ display: classList.includes('MarClass7SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass7SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass7SecD' style={{ display: classList.includes('MarClass7SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass7SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass7SecE' style={{ display: classList.includes('MarClass7SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass7SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('MarClass8SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                                <div name='marMediumClass8' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffMarClass8}>
                                                                    <h5>Class 8</h5>
                                                                    {dropdownStaff.MarClass8 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.MarClass8 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass8SecA' style={{ display: classList.includes('MarClass8SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass8SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass8SecB' style={{ display: classList.includes('MarClass8SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass8SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass8SecC' style={{ display: classList.includes('MarClass8SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass8SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass8SecD' style={{ display: classList.includes('MarClass8SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass8SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass8SecE' style={{ display: classList.includes('MarClass8SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass8SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('MarClass9SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                                <div name='marMediumClass9' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffMarClass9}>
                                                                    <h5>Class 9</h5>
                                                                    {dropdownStaff.MarClass9 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.MarClass9 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass9SecA' style={{ display: classList.includes('MarClass9SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass9SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass9SecB' style={{ display: classList.includes('MarClass9SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass9SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass9SecC' style={{ display: classList.includes('MarClass9SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass9SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass9SecD' style={{ display: classList.includes('MarClass9SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass9SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass9SecE' style={{ display: classList.includes('MarClass9SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass9SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('MarClass10SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                                <div name='marMediumClass10' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffMarClass10}>
                                                                    <h5>Class 10</h5>
                                                                    {dropdownStaff.MarClass10 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.MarClass10 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass10SecA' style={{ display: classList.includes('MarClass10SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass10SecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass10SecB' style={{ display: classList.includes('MarClass10SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass10SecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass10SecC' style={{ display: classList.includes('MarClass10SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass10SecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass10SecD' style={{ display: classList.includes('MarClass10SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass10SecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass10SecE' style={{ display: classList.includes('MarClass10SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass10SecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('MarClass11ArtsSecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                                <div name='marMediumclass11Arts' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffMarClass11Arts}>
                                                                    <h5>Class 11 Arts</h5>
                                                                    {dropdownStaff.MarClass11Arts === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.MarClass11Arts === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass11ArtsSecA' style={{ display: classList.includes('MarClass11ArtsSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass11ArtsSecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass11ArtsSecB' style={{ display: classList.includes('MarClass11ArtsSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass11ArtsSecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass11ArtsSecC' style={{ display: classList.includes('MarClass11ArtsSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass11ArtsSecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass11ArtsSecD' style={{ display: classList.includes('MarClass11ArtsSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass11ArtsSecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass11ArtsSecE' style={{ display: classList.includes('MarClass11ArtsSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass11ArtsSecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('MarClass11CommerceSecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                                <div name='marMediumclass11Commerce' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffMarClass11Commerce}>
                                                                    <h5>Class 11 Commerce</h5>
                                                                    {dropdownStaff.MarClass11Commerce === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.MarClass11Commerce === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass11CommerceSecA' style={{ display: classList.includes('MarClass11CommerceSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass11CommerceSecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass11CommerceSecB' style={{ display: classList.includes('MarClass11CommerceSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass11CommerceSecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass11CommerceSecC' style={{ display: classList.includes('MarClass11CommerceSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass11CommerceSecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass11CommerceSecD' style={{ display: classList.includes('MarClass11CommerceSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass11CommerceSecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass11CommerceSecE' style={{ display: classList.includes('MarClass11CommerceSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass11CommerceSecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('MarClass12ArtsSecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                                <div name='marMediumclass12Arts' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffMarClass12Arts}>
                                                                    <h5>Class 12 Arts</h5>
                                                                    {dropdownStaff.MarClass12Arts === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.MarClass12Arts === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass12ArtsSecA' style={{ display: classList.includes('MarClass12ArtsSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass12ArtsSecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass12ArtsSecB' style={{ display: classList.includes('MarClass12ArtsSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass12ArtsSecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass12ArtsSecC' style={{ display: classList.includes('MarClass12ArtsSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass12ArtsSecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass12ArtsSecD' style={{ display: classList.includes('MarClass12ArtsSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass12ArtsSecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass12ArtsSecE' style={{ display: classList.includes('MarClass12ArtsSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass12ArtsSecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: classList.includes('MarClass12CommerceSecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                                <div name='marMediumclass12Commerce' className='title_subtitlesMediumsClass' onClick={handleDropdownStaffMarClass12Commerce}>
                                                                    <h5>Class 12 Commerce</h5>
                                                                    {dropdownStaff.MarClass12Commerce === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                                </div>
                                                                <div style={{ display: dropdownStaff.MarClass12Commerce === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass12CommerceSecA' style={{ display: classList.includes('MarClass12CommerceSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass12CommerceSecA'>Section A</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass12CommerceSecB' style={{ display: classList.includes('MarClass12CommerceSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass12CommerceSecB'>Section B</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass12CommerceSecC' style={{ display: classList.includes('MarClass12CommerceSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass12CommerceSecC'>Section C</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass12CommerceSecD' style={{ display: classList.includes('MarClass12CommerceSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass12CommerceSecD'>Section D</h5>
                                                                    </div>
                                                                    <div onClick={handleSelectTeachingStaff} id='MarClass12CommerceSecE' style={{ display: classList.includes('MarClass12CommerceSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                        <ArrowRightIcon />
                                                                        <h5 id='MarClass12CommerceSecE'>Section E</h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id='parents' className='parents '>
                                        <div name='subTitle mainDropdown' className='title' onClick={handleDropdownParentsSubTitle}>
                                            <h2>Parents</h2>
                                            {dropdownParents.subTitle === true ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                                        </div>
                                        <div style={{ display: dropdownParents.subTitle === true ? 'flex' : 'none' }} className='schoolParentsTitlesMedium'>
                                            <div>
                                                <div name='medium' className='title_subtitlesMediums' onClick={handleDropdownParentsEng}>
                                                    <h4>English Medium</h4>
                                                    {dropdownParents.Eng === true ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                                                </div>
                                                <div style={{ display: dropdownParents.Eng === true ? 'flex' : 'none' }} className='schoolParentsTitlesMediumClass'>

                                                    <div style={{ display: classList.includes('EngPreNurserySecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumPreNursery' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsEngPreNursery}>
                                                            <h5>Pre Nursery <span style={{ color: 'var(--connected-orange)', display: parentsClassess.EngPreNursery ? 'contents' : 'none' }}>({parentsClassess.EngPreNursery})</span></h5>
                                                            {dropdownParents.EngPreNursery === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.EngPreNursery === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='EngPreNurserySecA' style={{ display: classList.includes('EngPreNurserySecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngPreNurserySecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngPreNurserySecA?.length ? 'contents' : 'none' }}>({parentsRequests.EngPreNurserySecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngPreNurserySecB' style={{ display: classList.includes('EngPreNurserySecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngPreNurserySecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngPreNurserySecB?.length ? 'contents' : 'none' }}>({parentsRequests.EngPreNurserySecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngPreNurserySecC' style={{ display: classList.includes('EngPreNurserySecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngPreNurserySecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngPreNurserySecC?.length ? 'contents' : 'none' }}>({parentsRequests.EngPreNurserySecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngPreNurserySecD' style={{ display: classList.includes('EngPreNurserySecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngPreNurserySecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngPreNurserySecD?.length ? 'contents' : 'none' }}>({parentsRequests.EngPreNurserySecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngPreNurserySecE' style={{ display: classList.includes('EngPreNurserySecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngPreNurserySecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngPreNurserySecE?.length ? 'contents' : 'none' }}>({parentsRequests.EngPreNurserySecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngNurserySecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumNursery' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsEngNursery}>
                                                            <h5>Nursery  <span style={{ color: 'var(--connected-orange)', display: parentsClassess.EngNursery ? 'contents' : 'none' }}>({parentsClassess.EngNursery})</span></h5>
                                                            {dropdownParents.EngNursery === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.EngNursery === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='EngNurserySecA' style={{ display: classList.includes('EngNurserySecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngNurserySecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngNurserySecA?.length ? 'contents' : 'none' }}>({parentsRequests.EngNurserySecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngNurserySecB' style={{ display: classList.includes('EngNurserySecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngNurserySecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngNurserySecB?.length ? 'contents' : 'none' }}>({parentsRequests.EngNurserySecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngNurserySecC' style={{ display: classList.includes('EngNurserySecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngNurserySecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngNurserySecC?.length ? 'contents' : 'none' }}>({parentsRequests.EngNurserySecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngNurserySecD' style={{ display: classList.includes('EngNurserySecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngNurserySecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngNurserySecD?.length ? 'contents' : 'none' }}>({parentsRequests.EngNurserySecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngNurserySecE' style={{ display: classList.includes('EngNurserySecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngNurserySecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngNurserySecE?.length ? 'contents' : 'none' }}>({parentsRequests.EngNurserySecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngKG1SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumKG1' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsEngKG1}>
                                                            <h5>Kindergarten 1 (KG-1) <span style={{ color: 'var(--connected-orange)', display: parentsClassess.EngKG1 ? 'contents' : 'none' }}>({parentsClassess.EngKG1})</span></h5>
                                                            {dropdownParents.EngKG1 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.EngKG1 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='EngKG1SecA' style={{ display: classList.includes('EngKG1SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngKG1SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngKG1SecA?.length ? 'contents' : 'none' }}>({parentsRequests.EngKG1SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngKG1SecB' style={{ display: classList.includes('EngKG1SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngKG1SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngKG1SecB?.length ? 'contents' : 'none' }}>({parentsRequests.EngKG1SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngKG1SecC' style={{ display: classList.includes('EngKG1SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngKG1SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngKG1SecC?.length ? 'contents' : 'none' }}>({parentsRequests.EngKG1SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngKG1SecD' style={{ display: classList.includes('EngKG1SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngKG1SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngKG1SecD?.length ? 'contents' : 'none' }}>({parentsRequests.EngKG1SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngKG1SecE' style={{ display: classList.includes('EngKG1SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngKG1SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngKG1SecE?.length ? 'contents' : 'none' }}>({parentsRequests.EngKG1SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngKG2SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumKG2' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsEngKG2}>
                                                            <h5>Kindergarten 2 (KG-2) <span style={{ color: 'var(--connected-orange)', display: parentsClassess.EngKG2 ? 'contents' : 'none' }}>({parentsClassess.EngKG2})</span></h5>
                                                            {dropdownParents.EngKG2 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.EngKG2 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='EngKG2SecA' style={{ display: classList.includes('EngKG2SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngKG2SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngKG2SecA?.length ? 'contents' : 'none' }}>({parentsRequests.EngKG2SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngKG2SecB' style={{ display: classList.includes('EngKG2SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngKG2SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngKG2SecB?.length ? 'contents' : 'none' }}>({parentsRequests.EngKG2SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngKG2SecC' style={{ display: classList.includes('EngKG2SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngKG2SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngKG2SecC?.length ? 'contents' : 'none' }}>({parentsRequests.EngKG2SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngKG2SecD' style={{ display: classList.includes('EngKG2SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngKG2SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngKG2SecD?.length ? 'contents' : 'none' }}>({parentsRequests.EngKG2SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngKG2SecE' style={{ display: classList.includes('EngKG2SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngKG2SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngKG2SecE?.length ? 'contents' : 'none' }}>({parentsRequests.EngKG2SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass1SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumClass1' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsEngClass1}>
                                                            <h5>Class 1 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.EngClass1 ? 'contents' : 'none' }}>({parentsClassess.EngClass1})</span></h5>
                                                            {dropdownParents.EngClass1 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.EngClass1 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='EngClass1SecA' style={{ display: classList.includes('EngClass1SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass1SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass1SecA?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass1SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass1SecB' style={{ display: classList.includes('EngClass1SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass1SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass1SecB?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass1SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass1SecC' style={{ display: classList.includes('EngClass1SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass1SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass1SecC?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass1SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass1SecD' style={{ display: classList.includes('EngClass1SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass1SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass1SecD?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass1SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass1SecE' style={{ display: classList.includes('EngClass1SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass1SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass1SecE?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass1SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass2SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumClass2' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsEngClass2}>
                                                            <h5>Class 2 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.EngClass2 ? 'contents' : 'none' }}>({parentsClassess.EngClass2})</span></h5>
                                                            {dropdownParents.EngClass2 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.EngClass2 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='EngClass2SecA' style={{ display: classList.includes('EngClass2SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass2SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass2SecA?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass2SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass2SecB' style={{ display: classList.includes('EngClass2SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass2SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass2SecB?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass2SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass2SecC' style={{ display: classList.includes('EngClass2SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass2SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass2SecC?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass2SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass2SecD' style={{ display: classList.includes('EngClass2SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass2SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass2SecD?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass2SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass2SecE' style={{ display: classList.includes('EngClass2SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass2SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass12CommerceSecE?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass12CommerceSecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass3SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumClass3' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsEngClass3}>
                                                            <h5>Class 3 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.EngClass3 ? 'contents' : 'none' }}>({parentsClassess.EngClass3})</span></h5>
                                                            {dropdownParents.EngClass3 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.EngClass3 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='EngClass3SecA' style={{ display: classList.includes('EngClass3SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass3SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass3SecA?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass3SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass3SecB' style={{ display: classList.includes('EngClass3SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass3SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass3SecB?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass3SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass3SecC' style={{ display: classList.includes('EngClass3SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass3SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass3SecC?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass3SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass3SecD' style={{ display: classList.includes('EngClass3SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass3SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass3SecD?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass3SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass3SecE' style={{ display: classList.includes('EngClass3SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass3SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass3SecE?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass3SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass4SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumClass4' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsEngClass4}>
                                                            <h5>Class 4 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.EngClass4 ? 'contents' : 'none' }}>({parentsClassess.EngClass4})</span></h5>
                                                            {dropdownParents.EngClass4 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.EngClass4 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='EngClass4SecA' style={{ display: classList.includes('EngClass4SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass4SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass4SecA?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass4SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass4SecB' style={{ display: classList.includes('EngClass4SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass4SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass4SecB?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass4SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass4SecC' style={{ display: classList.includes('EngClass4SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass4SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass4SecC?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass4SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass4SecD' style={{ display: classList.includes('EngClass4SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass4SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass4SecD?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass4SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass4SecE' style={{ display: classList.includes('EngClass4SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass4SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass4SecE?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass4SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass5SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumClass5' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsEngClass5}>
                                                            <h5>Class 5 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.EngClass5 ? 'contents' : 'none' }}>({parentsClassess.EngClass5})</span></h5>
                                                            {dropdownParents.EngClass5 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.EngClass5 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='EngClass5SecA' style={{ display: classList.includes('EngClass5SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass5SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass5SecA?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass5SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass5SecB' style={{ display: classList.includes('EngClass5SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass5SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass5SecB?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass5SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass5SecC' style={{ display: classList.includes('EngClass5SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass5SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass5SecC?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass5SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass5SecD' style={{ display: classList.includes('EngClass5SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass5SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass5SecD?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass5SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass5SecE' style={{ display: classList.includes('EngClass5SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass5SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass5SecE?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass5SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass6SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumClass6' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsEngClass6}>
                                                            <h5>Class 6 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.EngClass6 ? 'contents' : 'none' }}>({parentsClassess.EngClass6})</span></h5>
                                                            {dropdownParents.EngClass6 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.EngClass6 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='EngClass6SecA' style={{ display: classList.includes('EngClass6SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass6SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass6SecA?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass6SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass6SecB' style={{ display: classList.includes('EngClass6SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass6SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass6SecB?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass6SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass6SecC' style={{ display: classList.includes('EngClass6SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass6SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass6SecC?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass6SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass6SecD' style={{ display: classList.includes('EngClass6SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass6SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass6SecD?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass6SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass6SecE' style={{ display: classList.includes('EngClass6SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass6SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass6SecE?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass6SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass7SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumClass7' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsEngClass7}>
                                                            <h5>Class 7 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.EngClass7 ? 'contents' : 'none' }}>({parentsClassess.EngClass7})</span></h5>
                                                            {dropdownParents.EngClass7 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.EngClass7 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='EngClass7SecA' style={{ display: classList.includes('EngClass7SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass7SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass7SecA?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass7SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass7SecB' style={{ display: classList.includes('EngClass7SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass7SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass7SecB?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass7SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass7SecC' style={{ display: classList.includes('EngClass7SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass7SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass7SecC?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass7SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass7SecD' style={{ display: classList.includes('EngClass7SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass7SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass7SecD?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass7SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass7SecE' style={{ display: classList.includes('EngClass7SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass7SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass7SecE?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass7SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass8SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumClass8' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsEngClass8}>
                                                            <h5>Class 8 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.EngClass8 ? 'contents' : 'none' }}>({parentsClassess.EngClass8})</span></h5>
                                                            {dropdownParents.EngClass8 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.EngClass8 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='EngClass8SecA' style={{ display: classList.includes('EngClass8SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass8SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass8SecA?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass8SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass8SecB' style={{ display: classList.includes('EngClass8SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass8SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass8SecB?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass8SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass8SecC' style={{ display: classList.includes('EngClass8SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass8SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass8SecC?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass8SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass8SecD' style={{ display: classList.includes('EngClass8SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass8SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass8SecD?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass8SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass8SecE' style={{ display: classList.includes('EngClass8SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass8SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass8SecE?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass8SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass9SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumClass9' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsEngClass9}>
                                                            <h5>Class 9 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.EngClass9 ? 'contents' : 'none' }}>({parentsClassess.EngClass9})</span></h5>
                                                            {dropdownParents.EngClass9 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.EngClass9 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='EngClass9SecA' style={{ display: classList.includes('EngClass9SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass9SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass9SecA?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass9SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass9SecB' style={{ display: classList.includes('EngClass9SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass9SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass9SecB?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass9SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass9SecC' style={{ display: classList.includes('EngClass9SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass9SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass9SecC?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass9SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass9SecD' style={{ display: classList.includes('EngClass9SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass9SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass9SecD?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass9SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass9SecE' style={{ display: classList.includes('EngClass9SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass9SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass9SecE?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass9SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass10SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumClass10' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsEngClass10}>
                                                            <h5>Class 10 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.EngClass10 ? 'contents' : 'none' }}>({parentsClassess.EngClass10})</span></h5>
                                                            {dropdownParents.EngClass10 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.EngClass10 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='EngClass10SecA' style={{ display: classList.includes('EngClass10SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass10SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass10SecA?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass10SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass10SecB' style={{ display: classList.includes('EngClass10SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass10SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass10SecB?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass10SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass10SecC' style={{ display: classList.includes('EngClass10SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass10SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass10SecC?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass10SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass10SecD' style={{ display: classList.includes('EngClass10SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass10SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass10SecD?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass10SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass10SecE' style={{ display: classList.includes('EngClass10SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass10SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass10SecE?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass10SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass11CommerceSecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumclass11Commerce' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsEngClass11Commerce}>
                                                            <h5>Class 11 Commerce <span style={{ color: 'var(--connected-orange)', display: parentsClassess.EngClass11Commerce ? 'contents' : 'none' }}>({parentsClassess.EngClass11Commerce})</span></h5>
                                                            {dropdownParents.EngClass11Commerce === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.EngClass11Commerce === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='EngClass11CommerceSecA' style={{ display: classList.includes('EngClass11CommerceSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11CommerceSecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass11CommerceSecA?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass11CommerceSecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass11CommerceSecB' style={{ display: classList.includes('EngClass11CommerceSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11CommerceSecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass11CommerceSecB?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass11CommerceSecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass11CommerceSecC' style={{ display: classList.includes('EngClass11CommerceSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11CommerceSecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass11CommerceSecC?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass11CommerceSecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass11CommerceSecD' style={{ display: classList.includes('EngClass11CommerceSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11CommerceSecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass11CommerceSecD?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass11CommerceSecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass11CommerceSecE' style={{ display: classList.includes('EngClass11CommerceSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11CommerceSecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass11CommerceSecE?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass11CommerceSecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass11ScienceSecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumclass11Science' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsEngClass11Science}>
                                                            <h5>Class 11 Science <span style={{ color: 'var(--connected-orange)', display: parentsClassess.EngClass11Science ? 'contents' : 'none' }}>({parentsClassess.EngClass11Science})</span></h5>
                                                            {dropdownParents.EngClass11Science === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.EngClass11Science === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='EngClass11ScienceSecA' style={{ display: classList.includes('EngClass11ScienceSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11ScienceSecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass11ScienceSecA?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass11ScienceSecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass11ScienceSecB' style={{ display: classList.includes('EngClass11ScienceSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11ScienceSecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass11ScienceSecB?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass11ScienceSecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass11ScienceSecC' style={{ display: classList.includes('EngClass11ScienceSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11ScienceSecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass11ScienceSecC?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass11ScienceSecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass11ScienceSecD' style={{ display: classList.includes('EngClass11ScienceSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11ScienceSecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass11ScienceSecD?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass11ScienceSecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass11ScienceSecE' style={{ display: classList.includes('EngClass11ScienceSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11ScienceSecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass11ScienceSecE?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass11ScienceSecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass12CommerceSecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumclass12Commerce' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsEngClass12Commerce}>
                                                            <h5>Class 12 Commerce <span style={{ color: 'var(--connected-orange)', display: parentsClassess.EngClass12Commerce ? 'contents' : 'none' }}>({parentsClassess.EngClass12Commerce})</span></h5>
                                                            {dropdownParents.EngClass12Commerce === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.EngClass12Commerce === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='EngClass12CommerceSecA' style={{ display: classList.includes('EngClass12CommerceSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12CommerceSecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass12CommerceSecA?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass12CommerceSecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass12CommerceSecB' style={{ display: classList.includes('EngClass12CommerceSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12CommerceSecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass12CommerceSecB?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass12CommerceSecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass12CommerceSecC' style={{ display: classList.includes('EngClass12CommerceSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12CommerceSecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass12CommerceSecC?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass12CommerceSecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass12CommerceSecD' style={{ display: classList.includes('EngClass12CommerceSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12CommerceSecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass12CommerceSecD?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass12CommerceSecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass12CommerceSecE' style={{ display: classList.includes('EngClass12CommerceSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12CommerceSecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass12CommerceSecE?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass12CommerceSecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass12ScienceSecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumclass12Science' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsEngClass12Science}>
                                                            <h5>Class 12 Science <span style={{ color: 'var(--connected-orange)', display: parentsClassess.EngClass12Science ? 'contents' : 'none' }}>({parentsClassess.EngClass12Science})</span></h5>
                                                            {dropdownParents.EngClass12Science === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.EngClass12Science === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='EngClass12ScienceSecA' style={{ display: classList.includes('EngClass12ScienceSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12ScienceSecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass12ScienceSecA?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass12ScienceSecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass12ScienceSecB' style={{ display: classList.includes('EngClass12ScienceSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12ScienceSecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass12ScienceSecB?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass12ScienceSecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass12ScienceSecC' style={{ display: classList.includes('EngClass12ScienceSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12ScienceSecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass12ScienceSecC?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass12ScienceSecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass12ScienceSecD' style={{ display: classList.includes('EngClass12ScienceSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12ScienceSecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass12ScienceSecD?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass12ScienceSecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='EngClass12ScienceSecE' style={{ display: classList.includes('EngClass12ScienceSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12ScienceSecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.EngClass12ScienceSecE?.length ? 'contents' : 'none' }}>({parentsRequests.EngClass12ScienceSecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div>
                                                <div name='medium' className='title_subtitlesMediums' onClick={handleDropdownParentsMar}>
                                                    <h4>Marathi Medium</h4>
                                                    {dropdownParents.Mar === true ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                                                </div>
                                                <div style={{ display: dropdownParents.Mar === true ? 'flex' : 'none' }} className='schoolParentsTitlesMediumClass'>

                                                    <div style={{ display: classList.includes('MarPreNurserySecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='marMediumPreNursery' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsMarPreNursery}>
                                                            <h5>Pre Nursery <span style={{ color: 'var(--connected-orange)', display: parentsClassess.MarPreNursery ? 'contents' : 'none' }}>({parentsClassess.MarPreNursery})</span></h5>
                                                            {dropdownParents.MarPreNursery === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.MarPreNursery === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='MarPreNurserySecA' style={{ display: classList.includes('MarPreNurserySecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarPreNurserySecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarPreNurserySecA?.length ? 'contents' : 'none' }}>({parentsRequests.MarPreNurserySecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarPreNurserySecB' style={{ display: classList.includes('MarPreNurserySecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarPreNurserySecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarPreNurserySecB?.length ? 'contents' : 'none' }}>({parentsRequests.MarPreNurserySecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarPreNurserySecC' style={{ display: classList.includes('MarPreNurserySecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarPreNurserySecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarPreNurserySecC?.length ? 'contents' : 'none' }}>({parentsRequests.MarPreNurserySecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarPreNurserySecD' style={{ display: classList.includes('MarPreNurserySecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarPreNurserySecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarPreNurserySecD?.length ? 'contents' : 'none' }}>({parentsRequests.MarPreNurserySecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarPreNurserySecE' style={{ display: classList.includes('MarPreNurserySecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarPreNurserySecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarPreNurserySecE?.length ? 'contents' : 'none' }}>({parentsRequests.MarPreNurserySecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarNurserySecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumNursery' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsMarNursery}>
                                                            <h5>Nursery  <span style={{ color: 'var(--connected-orange)', display: parentsClassess.MarNursery ? 'contents' : 'none' }}>({parentsClassess.MarNursery})</span></h5>
                                                            {dropdownParents.MarNursery === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.MarNursery === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='MarNurserySecA' style={{ display: classList.includes('MarNurserySecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarNurserySecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarNurserySecA?.length ? 'contents' : 'none' }}>({parentsRequests.MarNurserySecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarNurserySecB' style={{ display: classList.includes('MarNurserySecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarNurserySecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarNurserySecB?.length ? 'contents' : 'none' }}>({parentsRequests.MarNurserySecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarNurserySecC' style={{ display: classList.includes('MarNurserySecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarNurserySecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarNurserySecC?.length ? 'contents' : 'none' }}>({parentsRequests.MarNurserySecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarNurserySecD' style={{ display: classList.includes('MarNurserySecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarNurserySecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarNurserySecD?.length ? 'contents' : 'none' }}>({parentsRequests.MarNurserySecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarNurserySecE' style={{ display: classList.includes('MarNurserySecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarNurserySecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarNurserySecE?.length ? 'contents' : 'none' }}>({parentsRequests.MarNurserySecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarKG1SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumKG1' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsMarKG1}>
                                                            <h5>Kindergarten 1 (KG-1) <span style={{ color: 'var(--connected-orange)', display: parentsClassess.MarKG1 ? 'contents' : 'none' }}>({parentsClassess.MarKG1})</span></h5>
                                                            {dropdownParents.MarKG1 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.MarKG1 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='MarKG1SecA' style={{ display: classList.includes('MarKG1SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarKG1SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarKG1SecA?.length ? 'contents' : 'none' }}>({parentsRequests.MarKG1SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarKG1SecB' style={{ display: classList.includes('MarKG1SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarKG1SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarKG1SecB?.length ? 'contents' : 'none' }}>({parentsRequests.MarKG1SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarKG1SecC' style={{ display: classList.includes('MarKG1SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarKG1SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarKG1SecC?.length ? 'contents' : 'none' }}>({parentsRequests.MarKG1SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarKG1SecD' style={{ display: classList.includes('MarKG1SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarKG1SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarKG1SecD?.length ? 'contents' : 'none' }}>({parentsRequests.MarKG1SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarKG1SecE' style={{ display: classList.includes('MarKG1SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarKG1SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarKG1SecE?.length ? 'contents' : 'none' }}>({parentsRequests.MarKG1SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarKG2SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumKG2' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsMarKG2}>
                                                            <h5>Kindergarten 2 (KG-2) <span style={{ color: 'var(--connected-orange)', display: parentsClassess.MarKG2 ? 'contents' : 'none' }}>({parentsClassess.MarKG2})</span></h5>
                                                            {dropdownParents.MarKG2 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.MarKG2 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='MarKG2SecA' style={{ display: classList.includes('MarKG2SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarKG2SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarKG2SecA?.length ? 'contents' : 'none' }}>({parentsRequests.MarKG2SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarKG2SecB' style={{ display: classList.includes('MarKG2SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarKG2SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarKG2SecB?.length ? 'contents' : 'none' }}>({parentsRequests.MarKG2SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarKG2SecC' style={{ display: classList.includes('MarKG2SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarKG2SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarKG2SecC?.length ? 'contents' : 'none' }}>({parentsRequests.MarKG2SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarKG2SecD' style={{ display: classList.includes('MarKG2SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarKG2SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarKG2SecD?.length ? 'contents' : 'none' }}>({parentsRequests.MarKG2SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarKG2SecE' style={{ display: classList.includes('MarKG2SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarKG2SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarKG2SecE?.length ? 'contents' : 'none' }}>({parentsRequests.MarKG2SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: classList.includes('MarClass1SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumClass1' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsMarClass1}>
                                                            <h5>Class 1 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.MarClass1 ? 'contents' : 'none' }}>({parentsClassess.MarClass1})</span></h5>
                                                            {dropdownParents.MarClass1 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.MarClass1 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='MarClass1SecA' style={{ display: classList.includes('MarClass1SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass1SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass1SecA?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass1SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass1SecB' style={{ display: classList.includes('MarClass1SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass1SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass1SecB?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass1SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass1SecC' style={{ display: classList.includes('MarClass1SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass1SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass1SecC?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass1SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass1SecD' style={{ display: classList.includes('MarClass1SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass1SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass1SecD?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass1SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass1SecE' style={{ display: classList.includes('MarClass1SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass1SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass1SecE?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass1SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarClass2SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumClass2' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsMarClass2}>
                                                            <h5>Class 2 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.MarClass2 ? 'contents' : 'none' }}>({parentsClassess.MarClass2})</span></h5>
                                                            {dropdownParents.MarClass2 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.MarClass2 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='MarClass2SecA' style={{ display: classList.includes('MarClass2SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass2SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass2SecA?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass2SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass2SecB' style={{ display: classList.includes('MarClass2SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass2SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass2SecB?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass2SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass2SecC' style={{ display: classList.includes('MarClass2SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass2SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass2SecC?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass2SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass2SecD' style={{ display: classList.includes('MarClass2SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass2SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass2SecD?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass2SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass2SecE' style={{ display: classList.includes('MarClass2SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass2SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass2SecE?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass2SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarClass3SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumClass3' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsMarClass3}>
                                                            <h5>Class 3 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.MarClass3 ? 'contents' : 'none' }}>({parentsClassess.MarClass3})</span></h5>
                                                            {dropdownParents.MarClass3 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.MarClass3 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='MarClass3SecA' style={{ display: classList.includes('MarClass3SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass3SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass3SecA?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass3SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass3SecB' style={{ display: classList.includes('MarClass3SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass3SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass3SecB?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass3SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass3SecC' style={{ display: classList.includes('MarClass3SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass3SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass3SecC?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass3SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass3SecD' style={{ display: classList.includes('MarClass3SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass3SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass3SecD?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass3SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass3SecE' style={{ display: classList.includes('MarClass3SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass3SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass3SecE?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass3SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarClass4SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumClass4' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsMarClass4}>
                                                            <h5>Class 4 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.MarClass4 ? 'contents' : 'none' }}>({parentsClassess.MarClass4})</span></h5>
                                                            {dropdownParents.MarClass4 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.MarClass4 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='MarClass4SecA' style={{ display: classList.includes('MarClass4SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass4SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass4SecA?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass4SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass4SecB' style={{ display: classList.includes('MarClass4SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass4SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass4SecB?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass4SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass4SecC' style={{ display: classList.includes('MarClass4SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass4SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass4SecC?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass4SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass4SecD' style={{ display: classList.includes('MarClass4SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass4SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass4SecD?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass4SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass4SecE' style={{ display: classList.includes('MarClass4SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass4SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass4SecE?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass4SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarClass5SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumClass5' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsMarClass5}>
                                                            <h5>Class 5 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.MarClass5 ? 'contents' : 'none' }}>({parentsClassess.MarClass5})</span></h5>
                                                            {dropdownParents.MarClass5 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.MarClass5 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='MarClass5SecA' style={{ display: classList.includes('MarClass5SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass5SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass5SecA?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass5SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass5SecB' style={{ display: classList.includes('MarClass5SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass5SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass5SecB?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass5SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass5SecC' style={{ display: classList.includes('MarClass5SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass5SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass5SecC?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass5SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass5SecD' style={{ display: classList.includes('MarClass5SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass5SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass5SecD?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass5SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass5SecE' style={{ display: classList.includes('MarClass5SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass5SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass5SecE?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass5SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarClass6SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumClass6' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsMarClass6}>
                                                            <h5>Class 6 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.MarClass6 ? 'contents' : 'none' }}>({parentsClassess.MarClass6})</span></h5>
                                                            {dropdownParents.MarClass6 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.MarClass6 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='MarClass6SecA' style={{ display: classList.includes('MarClass6SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass6SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass6SecA?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass6SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass6SecB' style={{ display: classList.includes('MarClass6SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass6SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass6SecB?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass6SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass6SecC' style={{ display: classList.includes('MarClass6SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass6SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass6SecC?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass6SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass6SecD' style={{ display: classList.includes('MarClass6SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass6SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass6SecD?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass6SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass6SecE' style={{ display: classList.includes('MarClass6SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass6SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass6SecE?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass6SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarClass7SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumClass7' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsMarClass7}>
                                                            <h5>Class 7 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.MarClass7 ? 'contents' : 'none' }}>({parentsClassess.MarClass7})</span></h5>
                                                            {dropdownParents.MarClass7 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.MarClass7 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='MarClass7SecA' style={{ display: classList.includes('MarClass7SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass7SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass7SecA?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass7SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass7SecB' style={{ display: classList.includes('MarClass7SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass7SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass7SecB?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass7SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass7SecC' style={{ display: classList.includes('MarClass7SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass7SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass7SecC?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass7SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass7SecD' style={{ display: classList.includes('MarClass7SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass7SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass7SecD?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass7SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass7SecE' style={{ display: classList.includes('MarClass7SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass7SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass7SecE?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass7SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarClass8SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumClass8' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsMarClass8}>
                                                            <h5>Class 8 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.MarClass8 ? 'contents' : 'none' }}>({parentsClassess.MarClass8})</span></h5>
                                                            {dropdownParents.MarClass8 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.MarClass8 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='MarClass8SecA' style={{ display: classList.includes('MarClass8SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass8SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass8SecA?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass8SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass8SecB' style={{ display: classList.includes('MarClass8SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass8SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass8SecB?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass8SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass8SecC' style={{ display: classList.includes('MarClass8SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass8SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass8SecC?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass8SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass8SecD' style={{ display: classList.includes('MarClass8SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass8SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass8SecD?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass8SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass8SecE' style={{ display: classList.includes('MarClass8SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass8SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass8SecE?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass8SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarClass9SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumClass9' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsMarClass9}>
                                                            <h5>Class 9 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.MarClass9 ? 'contents' : 'none' }}>({parentsClassess.MarClass9})</span></h5>
                                                            {dropdownParents.MarClass9 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.MarClass9 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='MarClass9SecA' style={{ display: classList.includes('MarClass9SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass9SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass9SecA?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass9SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass9SecB' style={{ display: classList.includes('MarClass9SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass9SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass9SecB?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass9SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass9SecC' style={{ display: classList.includes('MarClass9SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass9SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass9SecC?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass9SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass9SecD' style={{ display: classList.includes('MarClass9SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass9SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass9SecD?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass9SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass9SecE' style={{ display: classList.includes('MarClass9SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass9SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass9SecE?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass9SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarClass10SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumClass10' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsMarClass10}>
                                                            <h5>Class 10 <span style={{ color: 'var(--connected-orange)', display: parentsClassess.MarClass10 ? 'contents' : 'none' }}>({parentsClassess.MarClass10})</span></h5>
                                                            {dropdownParents.MarClass10 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.MarClass10 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='MarClass10SecA' style={{ display: classList.includes('MarClass10SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass10SecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass10SecA?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass10SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass10SecB' style={{ display: classList.includes('MarClass10SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass10SecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass10SecB?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass10SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass10SecC' style={{ display: classList.includes('MarClass10SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass10SecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass10SecC?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass10SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass10SecD' style={{ display: classList.includes('MarClass10SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass10SecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass10SecD?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass10SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass10SecE' style={{ display: classList.includes('MarClass10SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass10SecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass10SecE?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass10SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarClass11ArtsSecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumclass11Arts' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsMarClass11Arts}>
                                                            <h5>Class 11 Arts <span style={{ color: 'var(--connected-orange)', display: parentsClassess.MarClass11Arts ? 'contents' : 'none' }}>({parentsClassess.MarClass11Arts})</span></h5>
                                                            {dropdownParents.MarClass11Arts === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.MarClass11Arts === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='MarClass11ArtsSecA' style={{ display: classList.includes('MarClass11ArtsSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11ArtsSecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass11ArtsSecA?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass11ArtsSecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass11ArtsSecB' style={{ display: classList.includes('MarClass11ArtsSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11ArtsSecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass11ArtsSecB?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass11ArtsSecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass11ArtsSecC' style={{ display: classList.includes('MarClass11ArtsSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11ArtsSecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass11ArtsSecC?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass11ArtsSecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass11ArtsSecD' style={{ display: classList.includes('MarClass11ArtsSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11ArtsSecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass11ArtsSecD?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass11ArtsSecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass11ArtsSecE' style={{ display: classList.includes('MarClass11ArtsSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11ArtsSecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass11ArtsSecE?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass11ArtsSecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarClass11CommerceSecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumclass11Commerce' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsMarClass11Commerce}>
                                                            <h5>Class 11 Commerce <span style={{ color: 'var(--connected-orange)', display: parentsClassess.MarClass11Commerce ? 'contents' : 'none' }}>({parentsClassess.MarClass11Commerce})</span></h5>
                                                            {dropdownParents.MarClass11Commerce === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.MarClass11Commerce === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='MarClass11CommerceSecA' style={{ display: classList.includes('MarClass11CommerceSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11CommerceSecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass11CommerceSecA?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass11CommerceSecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass11CommerceSecB' style={{ display: classList.includes('MarClass11CommerceSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11CommerceSecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass11CommerceSecB?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass11CommerceSecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass11CommerceSecC' style={{ display: classList.includes('MarClass11CommerceSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11CommerceSecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass11CommerceSecC?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass11CommerceSecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass11CommerceSecD' style={{ display: classList.includes('MarClass11CommerceSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11CommerceSecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass11CommerceSecD?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass11CommerceSecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass11CommerceSecE' style={{ display: classList.includes('MarClass11CommerceSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11CommerceSecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass11CommerceSecE?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass11CommerceSecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarClass12ArtsSecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumclass12Arts' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsMarClass12Arts}>
                                                            <h5>Class 12 Arts <span style={{ color: 'var(--connected-orange)', display: parentsClassess.MarClass12Arts ? 'contents' : 'none' }}>({parentsClassess.MarClass12Arts})</span></h5>
                                                            {dropdownParents.MarClass12Arts === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.MarClass12Arts === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='MarClass12ArtsSecA' style={{ display: classList.includes('MarClass12ArtsSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12ArtsSecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass12ArtsSecA?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass12ArtsSecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass12ArtsSecB' style={{ display: classList.includes('MarClass12ArtsSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12ArtsSecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass12ArtsSecB?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass12ArtsSecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass12ArtsSecC' style={{ display: classList.includes('MarClass12ArtsSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12ArtsSecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass12ArtsSecC?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass12ArtsSecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass12ArtsSecD' style={{ display: classList.includes('MarClass12ArtsSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12ArtsSecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass12ArtsSecD?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass12ArtsSecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass12ArtsSecE' style={{ display: classList.includes('MarClass12ArtsSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12ArtsSecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass12ArtsSecE?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass12ArtsSecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarClass12CommerceSecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumclass12Commerce' className='title_subtitlesMediumsClass' onClick={handleDropdownParentsMarClass12Commerce}>
                                                            <h5>Class 12 Commerce <span style={{ color: 'var(--connected-orange)', display: parentsClassess.MarClass12Commerce ? 'contents' : 'none' }}>({parentsClassess.MarClass12Commerce})</span></h5>
                                                            {dropdownParents.MarClass12Commerce === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownParents.MarClass12Commerce === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectParents} id='MarClass12CommerceSecA' style={{ display: classList.includes('MarClass12CommerceSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12CommerceSecA'>Section A <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass12CommerceSecA?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass12CommerceSecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass12CommerceSecB' style={{ display: classList.includes('MarClass12CommerceSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12CommerceSecB'>Section B <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass12CommerceSecB?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass12CommerceSecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass12CommerceSecC' style={{ display: classList.includes('MarClass12CommerceSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12CommerceSecC'>Section C <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass12CommerceSecC?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass12CommerceSecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass12CommerceSecD' style={{ display: classList.includes('MarClass12CommerceSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12CommerceSecD'>Section D <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass12CommerceSecD?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass12CommerceSecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectParents} id='MarClass12CommerceSecE' style={{ display: classList.includes('MarClass12CommerceSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12CommerceSecE'>Section E <span style={{ color: 'var(--connected-color)', display: parentsRequests.MarClass12CommerceSecE?.length ? 'contents' : 'none' }}>({parentsRequests.MarClass12CommerceSecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id='students' className='students ' >
                                        <div name='subTitle mainDropdown' className='title' onClick={handleDropdownStudentsSubTitle}>
                                            <h2>Students</h2>
                                            {dropdownStudents.subTitle === true ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                                        </div>
                                        <div style={{ display: dropdownStudents.subTitle === true ? 'flex' : 'none' }} className='schoolStudentsTitlesMedium'>
                                            <div>
                                                <div name='medium' className='title_subtitlesMediums' onClick={handleDropdownStudentsEng}>
                                                    <h4>English Medium</h4>
                                                    {dropdownStudents.Eng === true ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                                                </div>
                                                <div style={{ display: dropdownStudents.Eng === true ? 'flex' : 'none' }} className='schoolStudentsTitlesMediumClass'>

                                                    <div style={{ display: classList.includes('EngClass8SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumClass8' className='title_subtitlesMediumsClass' onClick={handleDropdownStudentsEngClass8}>
                                                            <h5>Class 8 <span style={{ color: 'var(--connected-orange)', display: studentsClassess.EngClass8 ? 'contents' : 'none' }}>({studentsClassess.EngClass8})</span></h5>
                                                            {dropdownStudents.EngClass8 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownStudents.EngClass8 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectStudents} id='EngClass8SecA' style={{ display: classList.includes('EngClass8SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass8SecA'>Section A <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass8SecA?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass8SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass8SecB' style={{ display: classList.includes('EngClass8SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass8SecB'>Section B <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass8SecB?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass8SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass8SecC' style={{ display: classList.includes('EngClass8SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass8SecC'>Section C <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass8SecC?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass8SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass8SecD' style={{ display: classList.includes('EngClass8SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass8SecD'>Section D <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass8SecD?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass8SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass8SecE' style={{ display: classList.includes('EngClass8SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass8SecE'>Section E <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass8SecE?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass8SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass9SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumClass9' className='title_subtitlesMediumsClass' onClick={handleDropdownStudentsEngClass9}>
                                                            <h5>Class 9 <span style={{ color: 'var(--connected-orange)', display: studentsClassess.EngClass9 ? 'contents' : 'none' }}>({studentsClassess.EngClass9})</span></h5>
                                                            {dropdownStudents.EngClass9 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownStudents.EngClass9 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectStudents} id='EngClass9SecA' style={{ display: classList.includes('EngClass9SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass9SecA'>Section A <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass9SecA?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass9SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass9SecB' style={{ display: classList.includes('EngClass9SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass9SecB'>Section B <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass9SecB?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass9SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass9SecC' style={{ display: classList.includes('EngClass9SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass9SecC'>Section C <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass9SecC?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass9SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass9SecD' style={{ display: classList.includes('EngClass9SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass9SecD'>Section D <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass9SecD?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass9SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass9SecE' style={{ display: classList.includes('EngClass9SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass9SecE'>Section E <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass9SecE?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass9SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass10SecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumClass10' className='title_subtitlesMediumsClass' onClick={handleDropdownStudentsEngClass10}>
                                                            <h5>Class 10 <span style={{ color: 'var(--connected-orange)', display: studentsClassess.EngClass10 ? 'contents' : 'none' }}>({studentsClassess.EngClass10})</span></h5>
                                                            {dropdownStudents.EngClass10 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownStudents.EngClass10 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectStudents} id='EngClass10SecA' style={{ display: classList.includes('EngClass10SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass10SecA'>Section A <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass10SecA?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass10SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass10SecB' style={{ display: classList.includes('EngClass10SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass10SecB'>Section B <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass10SecB?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass10SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass10SecC' style={{ display: classList.includes('EngClass10SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass10SecC'>Section C <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass10SecC?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass10SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass10SecD' style={{ display: classList.includes('EngClass10SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass10SecD'>Section D <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass10SecD?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass10SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass10SecE' style={{ display: classList.includes('EngClass10SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass10SecE'>Section E <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass10SecE?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass10SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass11CommerceSecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumclass11Commerce' className='title_subtitlesMediumsClass' onClick={handleDropdownStudentsEngClass11Commerce}>
                                                            <h5>Class 11 Commerce <span style={{ color: 'var(--connected-orange)', display: studentsClassess.EngClass11Commerce ? 'contents' : 'none' }}>({studentsClassess.EngClass11Commerce})</span></h5>
                                                            {dropdownStudents.EngClass11Commerce === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownStudents.EngClass11Commerce === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectStudents} id='EngClass11CommerceSecA' style={{ display: classList.includes('EngClass11CommerceSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11CommerceSecA'>Section A <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass11CommerceSecA?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass11CommerceSecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass11CommerceSecB' style={{ display: classList.includes('EngClass11CommerceSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11CommerceSecB'>Section B <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass11CommerceSecB?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass11CommerceSecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass11CommerceSecC' style={{ display: classList.includes('EngClass11CommerceSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11CommerceSecC'>Section C <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass11CommerceSecC?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass11CommerceSecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass11CommerceSecD' style={{ display: classList.includes('EngClass11CommerceSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11CommerceSecD'>Section D <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass11CommerceSecD?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass11CommerceSecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass11CommerceSecE' style={{ display: classList.includes('EngClass11CommerceSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11CommerceSecE'>Section E <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass11CommerceSecE?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass11CommerceSecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass11ScienceSecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumclass11Science' className='title_subtitlesMediumsClass' onClick={handleDropdownStudentsEngClass11Science}>
                                                            <h5>Class 11 Science <span style={{ color: 'var(--connected-orange)', display: studentsClassess.EngClass11Science ? 'contents' : 'none' }}>({studentsClassess.EngClass11Science})</span></h5>
                                                            {dropdownStudents.EngClass11Science === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownStudents.EngClass11Science === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectStudents} id='EngClass11ScienceSecA' style={{ display: classList.includes('EngClass11ScienceSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11ScienceSecA'>Section A <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass11ScienceSecA?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass11ScienceSecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass11ScienceSecB' style={{ display: classList.includes('EngClass11ScienceSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11ScienceSecB'>Section B <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass11ScienceSecB?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass11ScienceSecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass11ScienceSecC' style={{ display: classList.includes('EngClass11ScienceSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11ScienceSecC'>Section C <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass11ScienceSecC?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass11ScienceSecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass11ScienceSecD' style={{ display: classList.includes('EngClass11ScienceSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11ScienceSecD'>Section D <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass11ScienceSecD?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass11ScienceSecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass11ScienceSecE' style={{ display: classList.includes('EngClass11ScienceSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass11ScienceSecE'>Section E <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass11ScienceSecE?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass11ScienceSecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass12CommerceSecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumclass12Commerce' className='title_subtitlesMediumsClass' onClick={handleDropdownStudentsEngClass12Commerce}>
                                                            <h5>Class 12 Commerce <span style={{ color: 'var(--connected-orange)', display: studentsClassess.EngClass12Commerce ? 'contents' : 'none' }}>({studentsClassess.EngClass12Commerce})</span></h5>
                                                            {dropdownStudents.EngClass12Commerce === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownStudents.EngClass12Commerce === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectStudents} id='EngClass12CommerceSecA' style={{ display: classList.includes('EngClass12CommerceSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12ScienceSecA'>Section A <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass12CommerceSecA?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass12CommerceSecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass12CommerceSecB' style={{ display: classList.includes('EngClass12CommerceSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12ScienceSecB'>Section B <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass12CommerceSecB?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass12CommerceSecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass12CommerceSecC' style={{ display: classList.includes('EngClass12CommerceSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12ScienceSecC'>Section C <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass12CommerceSecC?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass12CommerceSecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass12CommerceSecD' style={{ display: classList.includes('EngClass12CommerceSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12ScienceSecD'>Section D <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass12CommerceSecD?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass12CommerceSecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass12CommerceSecE' style={{ display: classList.includes('EngClass12CommerceSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12ScienceSecE'>Section E <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass12CommerceSecE?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass12CommerceSecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('EngClass12ScienceSecA') ? 'flex' : 'none' }} className='EngMediumClassNSection'>
                                                        <div name='engMediumclass12Science' className='title_subtitlesMediumsClass' onClick={handleDropdownStudentsEngClass12Science}>
                                                            <h5>Class 12 Science <span style={{ color: 'var(--connected-orange)', display: studentsClassess.EngClass12Science ? 'contents' : 'none' }}>({studentsClassess.EngClass12Science})</span></h5>
                                                            {dropdownStudents.EngClass12Science === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownStudents.EngClass12Science === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectStudents} id='EngClass12ScienceSecA' style={{ display: classList.includes('EngClass12ScienceSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12ScienceSecA'>Section A <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass12ScienceSecA?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass12ScienceSecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass12ScienceSecB' style={{ display: classList.includes('EngClass12ScienceSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12ScienceSecB'>Section B <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass12ScienceSecB?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass12ScienceSecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass12ScienceSecC' style={{ display: classList.includes('EngClass12ScienceSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12ScienceSecC'>Section C <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass12ScienceSecC?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass12ScienceSecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass12ScienceSecD' style={{ display: classList.includes('EngClass12ScienceSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12ScienceSecD'>Section D <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass12ScienceSecD?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass12ScienceSecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='EngClass12ScienceSecE' style={{ display: classList.includes('EngClass12ScienceSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='EngClass12ScienceSecE'>Section E <span style={{ color: 'var(--connected-color)', display: studentsRequests.EngClass12ScienceSecE?.length ? 'contents' : 'none' }}>({studentsRequests.EngClass12ScienceSecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div>
                                                <div name='medium' className='title_subtitlesMediums' onClick={handleDropdownStudentsMar}>
                                                    <h4>Marathi Medium</h4>
                                                    {dropdownStudents.Mar === true ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                                                </div>
                                                <div style={{ display: dropdownStudents.Mar === true ? 'flex' : 'none' }} className='schoolStudentsTitlesMediumClass'>

                                                    <div style={{ display: classList.includes('MarClass8SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumClass8' className='title_subtitlesMediumsClass' onClick={handleDropdownStudentsMarClass8}>
                                                            <h5>Class 8 <span style={{ color: 'var(--connected-orange)', display: studentsClassess.MarClass8 ? 'contents' : 'none' }}>({studentsClassess.MarClass8})</span></h5>
                                                            {dropdownStudents.MarClass8 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownStudents.MarClass8 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectStudents} id='MarClass8SecA' style={{ display: classList.includes('MarClass8SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass8SecA'>Section A <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass8SecA?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass8SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass8SecB' style={{ display: classList.includes('MarClass8SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass8SecB'>Section B <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass8SecB?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass8SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass8SecC' style={{ display: classList.includes('MarClass8SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass8SecC'>Section C <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass8SecC?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass8SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass8SecD' style={{ display: classList.includes('MarClass8SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass8SecD'>Section D <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass8SecD?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass8SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass8SecE' style={{ display: classList.includes('MarClass8SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass8SecE'>Section E <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass8SecE?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass8SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarClass9SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumClass9' className='title_subtitlesMediumsClass' onClick={handleDropdownStudentsMarClass9}>
                                                            <h5>Class 9 <span style={{ color: 'var(--connected-orange)', display: studentsClassess.MarClass9 ? 'contents' : 'none' }}>({studentsClassess.MarClass9})</span></h5>
                                                            {dropdownStudents.MarClass9 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownStudents.MarClass9 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectStudents} id='MarClass9SecA' style={{ display: classList.includes('MarClass9SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass9SecA'>Section A <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass9SecA?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass9SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass9SecB' style={{ display: classList.includes('MarClass9SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass9SecB'>Section B <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass9SecB?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass9SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass9SecC' style={{ display: classList.includes('MarClass9SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass9SecC'>Section C <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass9SecC?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass9SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass9SecD' style={{ display: classList.includes('MarClass9SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass9SecD'>Section D <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass9SecD?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass9SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass9SecE' style={{ display: classList.includes('MarClass9SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass9SecE'>Section E <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass9SecE?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass9SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarClass10SecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumClass10' className='title_subtitlesMediumsClass' onClick={handleDropdownStudentsMarClass10}>
                                                            <h5>Class 10 <span style={{ color: 'var(--connected-orange)', display: studentsClassess.MarClass10 ? 'contents' : 'none' }}>({studentsClassess.MarClass10})</span></h5>
                                                            {dropdownStudents.MarClass10 === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownStudents.MarClass10 === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectStudents} id='MarClass10SecA' style={{ display: classList.includes('MarClass10SecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass10SecA'>Section A <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass10SecA?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass10SecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass10SecB' style={{ display: classList.includes('MarClass10SecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass10SecB'>Section B <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass10SecB?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass10SecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass10SecC' style={{ display: classList.includes('MarClass10SecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass10SecC'>Section C <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass10SecC?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass10SecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass10SecD' style={{ display: classList.includes('MarClass10SecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass10SecD'>Section D <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass10SecD?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass10SecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass10SecE' style={{ display: classList.includes('MarClass10SecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass10SecE'>Section E <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass10SecE?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass10SecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarClass11ArtsSecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumclass11Arts' className='title_subtitlesMediumsClass' onClick={handleDropdownStudentsMarClass11Arts}>
                                                            <h5>Class 11 Arts <span style={{ color: 'var(--connected-orange)', display: studentsClassess.MarClass11Arts ? 'contents' : 'none' }}>({studentsClassess.MarClass11Arts})</span></h5>
                                                            {dropdownStudents.MarClass11Arts === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownStudents.MarClass11Arts === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectStudents} id='MarClass11ArtsSecA' style={{ display: classList.includes('MarClass11ArtsSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11ArtsSecA'>Section A <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass11ArtsSecA?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass11ArtsSecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass11ArtsSecB' style={{ display: classList.includes('MarClass11ArtsSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11ArtsSecB'>Section B <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass11ArtsSecB?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass11ArtsSecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass11ArtsSecC' style={{ display: classList.includes('MarClass11ArtsSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11ArtsSecC'>Section C <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass11ArtsSecC?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass11ArtsSecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass11ArtsSecD' style={{ display: classList.includes('MarClass11ArtsSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11ArtsSecD'>Section D <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass11ArtsSecD?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass11ArtsSecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass11ArtsSecE' style={{ display: classList.includes('MarClass11ArtsSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11ArtsSecE'>Section E <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass11ArtsSecE?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass11ArtsSecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarClass11CommerceSecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumclass11Commerce' className='title_subtitlesMediumsClass' onClick={handleDropdownStudentsMarClass11Commerce}>
                                                            <h5>Class 11 Commerce <span style={{ color: 'var(--connected-orange)', display: studentsClassess.MarClass11Commerce ? 'contents' : 'none' }}>({studentsClassess.MarClass11Commerce})</span></h5>
                                                            {dropdownStudents.MarClass11Commerce === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownStudents.MarClass11Commerce === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectStudents} id='MarClass11CommerceSecA' style={{ display: classList.includes('MarClass11CommerceSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11CommerceSecA'>Section A <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass11CommerceSecA?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass11CommerceSecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass11CommerceSecB' style={{ display: classList.includes('MarClass11CommerceSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11CommerceSecB'>Section B <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass11CommerceSecB?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass11CommerceSecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass11CommerceSecC' style={{ display: classList.includes('MarClass11CommerceSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11CommerceSecC'>Section C <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass11CommerceSecC?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass11CommerceSecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass11CommerceSecD' style={{ display: classList.includes('MarClass11CommerceSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11CommerceSecD'>Section D <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass11CommerceSecD?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass11CommerceSecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass11CommerceSecE' style={{ display: classList.includes('MarClass11CommerceSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass11CommerceSecE'>Section E <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass11CommerceSecE?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass11CommerceSecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarClass12ArtsSecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumclass12Arts' className='title_subtitlesMediumsClass' onClick={handleDropdownStudentsMarClass12Arts}>
                                                            <h5>Class 12 Arts <span style={{ color: 'var(--connected-orange)', display: studentsClassess.MarClass12Arts ? 'contents' : 'none' }}>({studentsClassess.MarClass12Arts})</span></h5>
                                                            {dropdownStudents.MarClass12Arts === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownStudents.MarClass12Arts === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectStudents} id='MarClass12ArtsSecA' style={{ display: classList.includes('MarClass12ArtsSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12ArtsSecA'>Section A <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass12ArtsSecA?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass12ArtsSecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass12ArtsSecB' style={{ display: classList.includes('MarClass12ArtsSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12ArtsSecB'>Section B <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass12ArtsSecB?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass12ArtsSecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass12ArtsSecC' style={{ display: classList.includes('MarClass12ArtsSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12ArtsSecC'>Section C <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass12ArtsSecC?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass12ArtsSecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass12ArtsSecD' style={{ display: classList.includes('MarClass12ArtsSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12ArtsSecD'>Section D <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass12ArtsSecD?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass12ArtsSecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass12ArtsSecE' style={{ display: classList.includes('MarClass12ArtsSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12ArtsSecE'>Section E <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass12ArtsSecE?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass12ArtsSecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: classList.includes('MarClass12CommerceSecA') ? 'flex' : 'none' }} className='MarMediumClassNSection'>
                                                        <div name='marMediumclass12Commerce' className='title_subtitlesMediumsClass' onClick={handleDropdownStudentsMarClass12Commerce}>
                                                            <h5>Class 12 Commerce <span style={{ color: 'var(--connected-orange)', display: studentsClassess.MarClass12CommerceSecE ? 'contents' : 'none' }}>({studentsClassess.MarClass12CommerceSecE})</span></h5>
                                                            {dropdownStudents.MarClass12Commerce === true ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                                                        </div>
                                                        <div style={{ display: dropdownStudents.MarClass12Commerce === true ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSec'>
                                                            <div onClick={handleSelectStudents} id='MarClass12CommerceSecA' style={{ display: classList.includes('MarClass12CommerceSecA') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12CommerceSecA'>Section A <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass12CommerceSecA?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass12CommerceSecA?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass12CommerceSecB' style={{ display: classList.includes('MarClass12CommerceSecB') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12CommerceSecB'>Section B <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass12CommerceSecB?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass12CommerceSecB?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass12CommerceSecC' style={{ display: classList.includes('MarClass12CommerceSecC') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12CommerceSecC'>Section C <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass12CommerceSecC?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass12CommerceSecC?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass12CommerceSecD' style={{ display: classList.includes('MarClass12CommerceSecD') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12CommerceSecD'>Section D <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass12CommerceSecD?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass12CommerceSecD?.length})</span></h5>
                                                            </div>
                                                            <div onClick={handleSelectStudents} id='MarClass12CommerceSecE' style={{ display: classList.includes('MarClass12CommerceSecE') ? 'flex' : 'none' }} className='title_subtitlesMediumsClassSection'>
                                                                <ArrowRightIcon />
                                                                <h5 id='MarClass12CommerceSecE'>Section E <span style={{ color: 'var(--connected-color)', display: studentsRequests.MarClass12CommerceSecE?.length ? 'contents' : 'none' }}>({studentsRequests.MarClass12CommerceSecE?.length})</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </nav>

                                <div className='schoolverificationrequests__containerRight'>

                                    {/* done */}
                                    <div style={{ display: showTab === 'PRINCIPAL' ? 'flex' : 'none' }} className='schoolverificationrequests__containerRightPrincipal'>
                                        <div className='verificationrequests__topData'>
                                            <h1>Verification Requests ({principalDatabase?.length})</h1>
                                            <p>From</p>
                                            <h3>Principal</h3>
                                        </div>
                                        {
                                            principalDatabase?.length > 0 ? (
                                                <>
                                                    {
                                                        principalDatabase?.map((data, i) => {
                                                            return (
                                                                <SchoolVerificationRequestsPrincipal
                                                                    key={i}
                                                                    requestId={data?.userReqId}
                                                                    schoolId={schoolData.schoolId}
                                                                    schoolShortName={schoolData.schoolShortName}
                                                                    schoolFullName={schoolData.schoolFullName}
                                                                    schoolAffiliation={schoolData.schoolAffiliation}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    <div className='noStudentRequest'>
                                                        <h3>No new request at the moment</h3>
                                                    </div>
                                                </>
                                            )

                                        }
                                    </div>

                                    {/* done */}
                                    <div style={{ display: showTab === 'TEACHING_STAFF' ? 'flex' : 'none' }} className='schoolverificationrequests__containerRightTeachingStaff'>
                                        <div className='verificationrequests__topData'>
                                            <h1>Verification Requests</h1>
                                            <p>From</p>
                                            <h3>Teaching Staff of ({staffTeachingHeader?.medium} Medium) {staffTeachingHeader?.classNum} ({staffTeachingHeader?.classSection})</h3>
                                        </div>
                                        {
                                            staffTeacherClsSecSubs.map((sub, i) => {
                                                return (
                                                    <SchoolVerificationRequestsTeachingStaff
                                                        key={i}
                                                        subjectName={sub}
                                                        staffTeachMedium={staffTeachingHeader?.medium}
                                                        staffTeachClassNum={staffTeachingHeader?.classNum}
                                                        staffTeachSection={staffTeachingHeader?.section}
                                                        staffTeacherMedClsSec={staffTeacherMedClsSec}
                                                        schoolId={schoolData.schoolId}
                                                        schoolShortName={schoolData.schoolShortName}
                                                        schoolFullName={schoolData.schoolFullName}
                                                        schoolAffiliation={schoolData.schoolAffiliation}
                                                    />
                                                )
                                            })
                                        }

                                    </div>

                                    {/* done */}
                                    <div style={{ display: showTab === 'PARENTS' ? 'flex' : 'none' }} className='schoolverificationrequests__containerRightParentsGuardians'>
                                        <div className='verificationrequests__topData'>
                                            <h1>Verification Requests</h1>
                                            <p>From</p>
                                            <h3>Parents of Students of ({parentsDataSet?.medium} Medium) {parentsDataSet?.classNum} ({parentsDataSet?.section})</h3>
                                        </div>
                                        {
                                            parentsData?.length > 0 ? (
                                                <>
                                                    {
                                                        parentsData?.map((data, i) => {
                                                            return (
                                                                <SchoolVerificationRequestsParents
                                                                    key={i}
                                                                    childsMedium={parentsDataSet?.medium}
                                                                    childsClass={parentsDataSet?.classNum}
                                                                    childsSection={parentsDataSet?.section}
                                                                    parentsId={data?.parentsId}
                                                                    parentsMedClsSec={parentsMedClsSec}
                                                                    schoolId={schoolData.schoolId}
                                                                    schoolShortName={schoolData.schoolShortName}
                                                                    schoolFullName={schoolData.schoolFullName}
                                                                    schoolAffiliation={schoolData.schoolAffiliation}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    <div className='noStudentRequest'>
                                                        <h3>No new request at the moment</h3>
                                                    </div>
                                                </>
                                            )
                                        }

                                    </div>

                                    {/* done */}
                                    <div style={{ display: showTab === 'STUDENTS' ? 'flex' : 'none' }} className='schoolverificationrequests__containerRightStudents'>
                                        <div className='verificationrequests__topData'>
                                            <h1>Verification Requests ({studentsInfo?.length})</h1>
                                            <p>From</p>
                                            <h3>Students of ({studentsDataSet?.medium} Medium) {studentsDataSet?.classNum} ({studentsDataSet?.section})</h3>
                                        </div>
                                        {
                                            studentsInfo?.length > 0 ? (
                                                <>{
                                                    studentsInfo.map((data, i) => {
                                                        return (
                                                            <SchoolVerificationRequestsStudents
                                                                key={i}
                                                                studentMedium={studentsDataSet?.medium}
                                                                studentClass={studentsDataSet?.classNum}
                                                                studentSection={studentsDataSet?.section}
                                                                studentCollectionId={data?.studentId}
                                                                studentsMedClsSec={studentsMedClsSec}
                                                                schoolId={schoolData.schoolId}
                                                                schoolShortName={schoolData.schoolShortName}
                                                                schoolFullName={schoolData.schoolFullName}
                                                                schoolAffiliation={schoolData.schoolAffiliation}
                                                            />
                                                        )
                                                    })
                                                }
                                                </>
                                            ) : (
                                                <>
                                                    <div className='noStudentRequest'>
                                                        <h3>No new request at the moment</h3>
                                                    </div>
                                                </>
                                            )

                                        }

                                    </div>
                                </div>

                            </div>
                            <hr />
                        </div>

                    </div>
                )
            }
        </>

    )
}

export default SchoolVerificationRequests
