import React, { useEffect, useState } from 'react'
import '../ProjectsHomeworks/HomeWorksTeachers.css'
import { doc, onSnapshot } from 'firebase/firestore'
import dB from '../../firebase'
import SyllabusCoveredTeachersClsNSub from './SyllabusCoveredTeachersClsNSub'

function SyllabusCoveredTeachers(props) {
    const { userId, userPathCollection, userCurrentStatus, userSchoolId } = props

    const [teacherSchoolData, setteacherSchoolData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userSchoolId) {
                onSnapshot(doc(dB, "SCHOOLS", userSchoolId), (snap) => {
                    if (snap.exists()) {
                        setteacherSchoolData(snap.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userSchoolId])

    return (
        <div className='homeworkteachers'>
            <div className='homeworkteachers__top'>
                <h3>{teacherSchoolData?.SCHOOL_FULL_NAME}</h3>
                <h4>Syllabus Covered</h4>
                <hr />
            </div>
            <SyllabusCoveredTeachersClsNSub
                userId={userId}
                userPathCollection={userPathCollection}
                userCurrentStatus={userCurrentStatus}
                userSchoolId={userSchoolId}
                userSchoolPathCollection={teacherSchoolData?.SCHOOL_NAME}
            />
        </div>
    )
}

export default SyllabusCoveredTeachers