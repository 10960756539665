import React, { useEffect, useState } from 'react'
import '../Sidebars/HomePageEducationSidebar.css'
// import dB from '../firebase';
import dB from '../../firebase';
import { collection, onSnapshot } from 'firebase/firestore';
import { Link } from 'react-router-dom'

import { Badge } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PeopleIcon from '@mui/icons-material/People';
import HelpIcon from '@mui/icons-material/Help';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import StoreIcon from '@mui/icons-material/Store';

function HomePageEducationSidebar(props) {
    const { userCurrentStatus, schoolId, schoolPathCollection, userId, totalHomeworks } = props

    const [noticesData, setnoticesData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolId && schoolPathCollection) {
                onSnapshot(collection(dB, "SCHOOLS", schoolId, schoolPathCollection, "NOTICE_BOARD", "NOTICES_SENT"), (snap) => {
                    setnoticesData(snap.docs.map((notDoc) => ({
                        noticeId: notDoc.id,
                        noticeData: notDoc.data(),
                    })))
                })
            }
        }
        return () => { aaa = false }
    }, [schoolId, schoolPathCollection])

    const [noticeList, setnoticeList] = useState([])
    useEffect(() => {
        if (noticeList.length > 0) {
            noticeList.splice(0, noticeList.length)
        }
        var aaa = true
        if (aaa === true) {
            if (noticesData?.length > 0) {
                noticesData.forEach(notice => {
                    if (!notice?.noticeData?.VIEWED_BY.includes(userId)) {
                        setnoticeList(prev => { return [...prev, notice?.noticeId] })
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [noticesData, userId])


    return (
        <div className='educationSidebar'>
            <div className='educationSidebar__top'>
                <Link to='/findtofollow' style={{ textDecoration: 'none', color: 'darkgray' }}>
                    <div className='educationSidebar__option'>
                        <PeopleIcon />
                        <p>Follow/Connect</p>
                    </div>
                </Link>
                <Link to='/eschoolingstatus' style={{ textDecoration: 'none', color: 'darkgray' }}>
                    <div className='educationSidebar__option'>
                        <SchoolIcon />
                        <p>E-Schooling Status</p>
                    </div>
                </Link>
                <Link to='/projects&homeworks' style={{ textDecoration: 'none', color: 'darkgray' }}>
                    <div style={{ display: userCurrentStatus === '' ? 'none' : 'flex' }} className='educationSidebar__option'>

                        <Badge color="secondary" badgeContent={totalHomeworks}>
                            <div className='educationSidebar__optionNew'>
                                <HomeWorkIcon />
                            </div>
                        </Badge>
                        <p>Projects & Homeworks</p>
                    </div>
                </Link>
                <Link to='/usernoticeboard' style={{ textDecoration: 'none', color: 'darkgray' }}>
                    <div style={{ display: userCurrentStatus === '' ? 'none' : 'flex' }} className='educationSidebar__option'>
                        {
                            userCurrentStatus === 'SCHOOL' ? (
                                <ListAltIcon />
                            ) : (
                                <Badge color="secondary" badgeContent={noticeList?.length}>
                                    <div className='educationSidebar__optionNew'>
                                        <ListAltIcon />
                                    </div>
                                </Badge>
                            )
                        }
                        <p>Notice Board</p>
                    </div>
                </Link>
                <Link to='/syllabuscovered' style={{ textDecoration: 'none', color: 'darkgray' }}>
                    <div style={{ display: userCurrentStatus === '' ? 'none' : 'flex' }} className='educationSidebar__option'>

                        {/* <Badge color="secondary" badgeContent={totalHomeworks}> */}
                        <div className='educationSidebar__optionNew'>
                            <NoteAltIcon />
                        </div>
                        {/* </Badge> */}
                        <p>Syllabus Covered</p>
                    </div>
                </Link>
                <Link to='/schooltimetable' style={{ textDecoration: 'none', color: 'darkgray' }}>
                    <div style={{ display: userCurrentStatus === '' ? 'none' : 'flex' }} className='educationSidebar__option'>

                        {/* <Badge color="secondary" badgeContent={totalHomeworks}> */}
                        <div className='educationSidebar__optionNew'>
                            <ViewTimelineIcon />
                        </div>
                        {/* </Badge> */}
                        <p>Timetable</p>
                    </div>
                </Link>
                <Link to='/leaveapplications' style={{ textDecoration: 'none', color: 'darkgray' }}>
                    <div style={{ display: userCurrentStatus === '' || userCurrentStatus === 'STUDENT' ? 'none' : 'flex' }} className='educationSidebar__option'>

                        {/* <Badge color="secondary" badgeContent={totalHomeworks}> */}
                        <div className='educationSidebar__optionNew'>
                            <TransferWithinAStationIcon />
                        </div>
                        {/* </Badge> */}
                        <p>Leave Application</p>
                    </div>
                </Link>
                <Link to='/schoolcomplaints' style={{ textDecoration: 'none', color: 'darkgray' }}>
                    <div style={{ display: userCurrentStatus === '' || userCurrentStatus === 'STUDENT' ? 'none' : 'flex' }} className='educationSidebar__option'>

                        {/* <Badge color="secondary" badgeContent={totalHomeworks}> */}
                        <div className='educationSidebar__optionNew'>
                            <ErrorOutlineIcon />
                        </div>
                        {/* </Badge> */}
                        <p>Complaints & Feedback</p>
                    </div>
                </Link>
                {/* <Link to='/schoolresults' style={{ textDecoration: 'none', color: 'darkgray' }}>
                    <div style={{ display: userCurrentStatus === '' ? 'none' : 'flex' }} className='educationSidebar__option'>

                        <Badge color="secondary" badgeContent={totalHomeworks}>
                            <div className='educationSidebar__optionNew'>
                                <NotificationAddIcon />
                            </div>
                        </Badge>
                        <p>Results</p>
                    </div>
                </Link> */}
            </div>
        </div >
    )
}

export default HomePageEducationSidebar
