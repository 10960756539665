import React from 'react'
import '../../Pages/HowToUse.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function HowToUseVerReq() {
    return (
        <>
            <div className='HowToUse'>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className='HowToUseSummary'>
                            <h3>How to see Verification Requests from Teachers?</h3>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='HowToUseDetails'>
                            <h2>Verification requests from teachers will appear here. You can either confirm their request or cancel it. Once you accept the verification requests, that request is then moved to verified members list.  </h2>

                            <div className='HowToData'>
                                <h3>Teachers</h3>
                                <p><span>Step 1 : </span> Click on “Verification Requests” link on the left side of the homepage.</p>
                                <p><span>Step 2 : </span> Click on “School Staff”.</p>
                                <p><span>Step 3 : </span> Click on “Teaching Staff”.</p>
                                <p><span>Step 4 : </span> Click on the Medium you want. For example “English Medium”.</p>
                                <p><span>Step 5 : </span> Click on the Class you want. For example “Kindergarten-1 (KG-1)”.</p>
                                <p><span>Step 6 : </span> Click on the Section you want. For example “Section A”.</p>
                                <p><span>Step 7 : </span> A vertical list of subjects will appear. </p>
                                <p><span>Step 8 : </span> For each subject, if request is received from a teacher for that particular subject, you will see teachers name with buttons of “confirm” & “cancel” appear in front of that subject. </p>
                                <div className='HowToDataData'>
                                    <p><span style={{ fontSize: '20px', fontWeight: '700' }}>•</span> You can <b>confirm</b> the request if you have cross verified that person is a genuine teacher of your school, teaching that particular subject, at that particular class & section.</p>
                                    <p><span style={{ fontSize: '20px', fontWeight: '700' }}>•</span> You can <b>cancel</b> the request if you found out that person does not teach at your school or if person teaches at your school but other subjects or at other class and section and the request was sent by mistake.</p>
                                </div>
                                <p><span>Step 9 : </span> If no request is received for that subject then you will see message as “No request at the moment for the subject of English”.</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className='HowToUse'>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className='HowToUseSummary'>
                            <h3>How to see Verification Requests from Principal?</h3>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='HowToUseDetails'>
                            <h2>Verification requests from principal will appear here. You can either confirm their request or cancel it. Once you accept the verification requests, that request is then moved to verified members list.  </h2>

                            <div className='HowToData'>
                                <h3>Principal</h3>
                                <p><span>Step 1 : </span> Click on “Verification Requests” link on the left side of the homepage.</p>
                                <p><span>Step 2 : </span> Click on “School Staff”.</p>
                                <p><span>Step 3 : </span> Click on “Principal / HM”.</p>
                                <p><span>Step 4 : </span> One or more principal requests will appear.</p>
                                <p><span>Step 5 : </span> For each request, you will see the principal’s name and image with buttons of “confirm” & “cancel” appearing beside name.</p>
                                <div className='HowToDataData'>
                                    <p><span style={{ fontSize: '20px', fontWeight: '700' }}>•</span> You can <b>confirm</b> the request if you have cross verified that person is a genuine principal of your school.</p>
                                    <p><span style={{ fontSize: '20px', fontWeight: '700' }}>•</span> You can <b>cancel</b> the request if you found out that person is not genuine principal of your school and the request was sent by mistake.</p>
                                </div>
                                <p><span>Step 6 : </span> If no request is received then you will see message as “No new request at the moment”.</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className='HowToUse'>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className='HowToUseSummary'>
                            <h3>How to see Verification Requests from Parents?</h3>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='HowToUseDetails'>
                            <h2>Verification requests from parents will appear here. You can either confirm their request or cancel it. Once you accept the verification requests, that request is then moved to verified members list.  </h2>
                            <div className='HowToData'>
                                <h3>Parents</h3>
                                <p><span>Step 1 : </span> Click on “Verification Requests” link on the left side of the homepage.</p>
                                <p><span>Step 2 : </span> Click on “Parents”.</p>
                                <p><span>Step 3 : </span> Click on the Medium you want. For example “English Medium”.</p>
                                <p><span>Step 4 : </span> Click on the Class you want. For example “Kindergarten-1 (KG-1)”.</p>
                                <p><span>Step 5 : </span> Click on the Section you want. For example “Section A”.</p>
                                <p><span>Step 6 : </span> A list of parents request will appear.</p>
                                <p><span>Step 7 : </span> For each request, you will see the parents name and image with parental status, i.e. “Father of” or “Mother of” and child’s name and image. For each request, buttons of “confirm” & “cancel” appear in besides child’s name. </p>
                                <div className='HowToDataData'>
                                    <p><span style={{ fontSize: '20px', fontWeight: '700' }}>•</span> You can <b>confirm</b> the request if you have cross verified that person is a genuine parent of genuine child of your school</p>
                                    <p><span style={{ fontSize: '20px', fontWeight: '700' }}>•</span> You can <b>cancel</b> the request if you found out that child does not study at your school or if child studies at your school but in some other class or section or medium and the request was sent by mistake or child studies in your school with request at the right class and section but parents name appearing is not his/her genuine father/mother.</p>
                                </div>
                                <p><span>Step 8 : </span> If no parents request is received then you will see message as “No new request at the moment”.</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className='HowToUse'>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className='HowToUseSummary'>
                            <h3>How to see Verification Requests from Students?</h3>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='HowToUseDetails'>
                            <h2>Verification requests from students will appear here. You can either confirm their request or cancel it. Once you accept the verification requests, that request is then moved to verified members list.  </h2>
                            <div className='HowToData'>
                                <h3>Students</h3>
                                <p><span>Step 1 : </span> Click on “Verification Requests” link on the left side of the homepage.</p>
                                <p><span>Step 2 : </span> Click on “Students”.</p>
                                <p><span>Step 3 : </span> Click on the Medium you want. For example “English Medium”.</p>
                                <p><span>Step 4 : </span> Click on the Class you want. For example “Class 8”.</p>
                                <p><span>Step 5 : </span> Click on the Section you want. For example “Section A”.</p>
                                <p><span>Step 6 : </span> A list of students request will appear.</p>
                                <p><span>Step 7 : </span> For each request, you will see the student’s name and image with buttons of “confirm” & “cancel” appearing beside name.</p>
                                <div className='HowToDataData'>
                                    <p><span style={{ fontSize: '20px', fontWeight: '700' }}>•</span> You can <b>confirm</b> the request if you have cross verified that person is a genuine student of your school.</p>
                                    <p><span style={{ fontSize: '20px', fontWeight: '700' }}>•</span> You can <b>cancel</b> the request if you found out that student does not study at your school or if student studies at your school but in some other class or section or medium and the request was sent by mistake.</p>
                                </div>
                                <p><span>Step 8 : </span> If no student’s request is received then you will see message as “No new request at the moment”.</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
}

export default HowToUseVerReq