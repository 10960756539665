import React, { useEffect, useState } from 'react'
import '../Pages/SidebarNoticeBoard.css'
import Login from './Login';
import Header from '../Components/Header/Header';
import SidebarNoticeBoardAccordian from '../Components/NoticeBoards/SidebarNoticeBoardAccordian';
import dB, { auth } from '../firebase';
import { addDoc, collection, doc, onSnapshot, orderBy, query, serverTimestamp } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

import CreateIcon from '@mui/icons-material/Create';
import SendIcon from '@mui/icons-material/Send';
import { FormControl, FormHelperText, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';


function SidebarNoticeBoard() {
    const [userData, setuserData] = useState({
        userId: '', schoolId: '', schoolFullName: '', userCurrentStatus: '', userPathCollection: '',
    })
    const [schoolData, setschoolData] = useState({
        schoolId: '', schoolShortName: '', schoolFullName: '', schoolAffiliation: '',
    })
    useEffect(() => {
        const unSubscribe = onAuthStateChanged(auth, async (userCredentials) => {
            if (userCredentials) {
                var uid = userCredentials.uid;
                onSnapshot(doc(dB, "SCHOOLS", uid), (snap) => {
                    if (snap.exists()) {
                        setschoolData(prev => {
                            return {
                                ...prev,
                                schoolId: uid,
                                schoolShortName: snap.data()?.SCHOOL_NAME,
                                schoolFullName: snap.data()?.SCHOOL_FULL_NAME
                            }
                        })
                    }
                })
                onSnapshot(doc(dB, "SCHOOLS", uid), (snap) => {
                    if (snap.exists()) {
                        setuserData(prev => {
                            return {
                                ...prev,
                                schoolId: uid,
                                userId: uid,
                                schoolFullName: snap.data()?.SCHOOL_FULL_NAME,
                                userCurrentStatus: snap.data()?.USER_STATUS,
                                userPathCollection: snap.data()?.SCHOOL_NAME,
                            }
                        })
                    }
                })
            }
        });
        return () => { unSubscribe(); }
    }, [userData.schoolId, userData.userCurrentStatus, userData.userId, userData.userPathCollection])

    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolData.schoolId && schoolData.schoolShortName) {
                onSnapshot(doc(dB, "SCHOOLS", schoolData.schoolId, schoolData.schoolShortName, "REGISTRATION_INFO"), (snap) => {
                    if (snap.exists()) { setschoolData(prev => { return { ...prev, schoolAffiliation: snap.data()?.SCHOOL_AFFILIATION } }) }
                })
            }
        }
        return () => { aaa = false }
    }, [schoolData.schoolId, schoolData.schoolShortName])



    const [noticesData, setnoticesData] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (schoolData.schoolId && schoolData.schoolShortName) {
                onSnapshot(query(collection(dB, "SCHOOLS", schoolData.schoolId, schoolData.schoolShortName, "NOTICE_BOARD", "NOTICES_SENT"), orderBy('NOTICE_TIMESTAMP', 'desc')), (snap) => {
                    setnoticesData(snap.docs.map((notDoc) => ({
                        noticeId: notDoc.id,
                        noticeData: notDoc.data(),
                    })))
                })
            }
        }
        return () => { aaa = false }
    }, [schoolData.schoolId, schoolData.schoolShortName])

    const [showError, setshowError] = useState({
        noticeSubject: { error: false, helperText: '' },
        noticefrom: { error: false, helperText: '' },
        noticeBody: { error: false, helperText: '' },
    })

    const [noticeData, setnoticeData] = useState({
        noticeSubject: '', noticeBody: '', noticefrom: '', isSent: false,
    })
    const handleChangenoticeSubject = (event) => {
        event.preventDefault();
        setnoticeData(prev => { return { ...prev, noticeSubject: event.target.value } });
        setshowError(prev => { return { ...prev, noticeSubject: { error: false, helperText: '' } } })
        setnoticeData(prev => { return { ...prev, isSent: false } });
    }
    const handleChangeNoticeBody = (event) => {
        event.preventDefault();
        setnoticeData(prev => { return { ...prev, noticeBody: event.target.value } });
        setshowError(prev => { return { ...prev, noticeBody: { error: false, helperText: '' } } })
        setnoticeData(prev => { return { ...prev, isSent: false } });
    }
    const handleChangeNoticeFrom = (event) => {
        event.preventDefault();
        setnoticeData(prev => { return { ...prev, noticefrom: event.target.value } });
        setshowError(prev => { return { ...prev, noticefrom: { error: false, helperText: '' } } })
        setnoticeData(prev => { return { ...prev, isSent: false } });
    };

    const [dateToday, setdateToday] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var d = new Date().toLocaleDateString();
            setdateToday(d)
        }
        return () => { aaa = false }
    }, [])

    const [activeTab, setactiveTab] = useState({ createNotice: '', sentNotice: '' })
    const handleClickCreateNewNotices = (e) => {
        e.preventDefault()
        setactiveTab(prev => { return { ...prev, createNotice: 'active', sentNotice: '' } })
    }
    const handleClickAlreadyPublicNotices = (e) => {
        e.preventDefault()
        setactiveTab(prev => { return { ...prev, createNotice: '', sentNotice: 'active' } })
    }

    const [btnLoading, setbtnLoading] = useState(false)

    const handleClickSendNotice = (e) => {
        e.preventDefault()
        setbtnLoading(true)
        if (noticeData.noticeSubject && noticeData.noticeBody && noticeData.noticefrom) {
            addDoc(collection(dB, "SCHOOLS", schoolData.schoolId, schoolData.schoolShortName, "NOTICE_BOARD", "NOTICES_SENT"), {
                NOTICE_SUBJECT: noticeData.noticeSubject,
                NOTICE_BODY: noticeData.noticeBody,
                NOTICE_FROM: noticeData.noticefrom,
                VIEWED_BY: [],
                NOTICE_TIMESTAMP: serverTimestamp()
            }).then(function (docRef) {
                setnoticeData(prev => { return { ...prev, noticeTo: '', noticeSubject: '', noticeBody: '', noticefrom: '' } });
                setnoticeData(prev => { return { ...prev, isSent: true } });
                setbtnLoading(false)
            }).catch(err => alert(err.message))
        } else {
            if (!noticeData.noticeSubject) { setshowError(prev => { return { ...prev, noticeSubject: { error: true, helperText: 'Please enter the subject of your notice' } } }) }
            if (!noticeData.noticeBody) { setshowError(prev => { return { ...prev, noticeBody: { error: true, helperText: 'Please enter the notice information' } } }) }
            if (!noticeData.noticefrom) { setshowError(prev => { return { ...prev, noticefrom: { error: true, helperText: 'Please enter the sender info' } } }) }
        }
    }

    return (
        <>
            {
                userData.userId === '' ? (
                    <Login />
                ) : (
                    <div className='SidebarNoticeBoard'>
                        <Header />
                        <div className='SidebarNoticeBoard__Container'>
                            <div className='SidebarNoticeBoard__ContainerTop'>
                                <h3>{schoolData.schoolFullName}</h3>
                                <h2>Notice Board</h2>
                                <hr />
                            </div>
                            <div className='SidebarNoticeBoard__ContainerBottom'>

                                <div className='SidebarNoticeBoard__ContainerBottomLeft'>
                                    <nav className='SidebarNoticeBoard__containerLeft'>
                                        <div key='CreateNewNotice' className={`SidebarNoticeBoard__containerLeft__title ${activeTab.createNotice}`} onClick={handleClickCreateNewNotices}>
                                            <CreateIcon />
                                            <h2>Create New Notice</h2>
                                        </div>
                                        <div key='sentNoticesLeft' className={`SidebarNoticeBoard__containerLeft__title ${activeTab.sentNotice}`} onClick={handleClickAlreadyPublicNotices}>
                                            <SendIcon />
                                            <h2>Sent Notices</h2>
                                        </div>
                                    </nav>
                                </div>

                                <div className='SidebarNoticeBoard__ContainerBottomRight'>
                                    {
                                        activeTab.createNotice === 'active' && (
                                            <div className='sendNoticeBlock'>
                                                {
                                                    noticesData.length === 12 ? (
                                                        <div className='limitNotice'>
                                                            <h4>You have exhausted your limit of notices. You can store Max 12 notices at a time. Delete few notices to add new one.</h4>
                                                        </div>
                                                    ) : (
                                                        <div className='sendNotice'>
                                                            {/* school name heading */}
                                                            <h4>{schoolData.schoolFullName}</h4>

                                                            {/* Notice title */}
                                                            <h3>Notice</h3>

                                                            {/* subject */}
                                                            <div className='sendNotice__subject'>

                                                                <FormControl error={showError.noticeSubject.error} name='noticeSubject' sx={{ width: '50ch' }} variant="outlined">
                                                                    <InputLabel htmlFor="noticeSubject">Subject </InputLabel>
                                                                    <OutlinedInput

                                                                        name='noticeSubject'
                                                                        id="noticeSubject"
                                                                        type='text'
                                                                        value={noticeData.noticeSubject}
                                                                        onChange={handleChangenoticeSubject}
                                                                        label='Subject '
                                                                    />
                                                                    <FormHelperText id="noticeSubject"><p>{showError.noticeSubject.helperText}</p></FormHelperText>
                                                                </FormControl>
                                                            </div>

                                                            {/* date */}
                                                            <div className='h5__sendNotice__date'>
                                                                <div className='sendNotice__date'>
                                                                    <h4>Date : </h4>
                                                                    <h2>{dateToday}</h2>
                                                                </div>
                                                            </div>

                                                            {/* notice details */}
                                                            <div className='sendNotice__noticeBody'>
                                                                <TextField
                                                                    error={showError.noticeBody.error}
                                                                    // className={MuiClasses.longWidth}
                                                                    sx={{ width: '75ch' }}
                                                                    name='noticeBody'
                                                                    value={noticeData.noticeBody}
                                                                    onChange={handleChangeNoticeBody}
                                                                    id="noticeBody"
                                                                    type='text'
                                                                    label='Type your Notice here..'
                                                                    multiline
                                                                    rows={10}
                                                                    rowsMax={12}
                                                                    variant="outlined"

                                                                />
                                                                <FormHelperText id="noticefrom"><p>{showError.noticeBody.helperText}</p></FormHelperText>
                                                            </div>

                                                            {/* from section */}
                                                            <div className='sendNotice__from'>

                                                                <FormControl error={showError.noticefrom.error} name='noticefrom' sx={{ width: '35ch' }} variant="outlined">
                                                                    <InputLabel htmlFor="noticefrom">From</InputLabel>
                                                                    <OutlinedInput
                                                                        name='noticefrom'
                                                                        id="noticefrom"
                                                                        type='text'
                                                                        value={noticeData.noticefrom}
                                                                        onChange={handleChangeNoticeFrom}
                                                                        label='From'
                                                                        placeholder='From (e.g. Principal/Administration)'
                                                                    />
                                                                    <FormHelperText id="noticefrom"><p>{showError.noticefrom.helperText}</p></FormHelperText>
                                                                </FormControl>
                                                            </div>

                                                            {/* send button */}
                                                            <div className='sendNotice__buttonSend'>
                                                                <LoadingButton
                                                                    onClick={handleClickSendNotice}
                                                                    loading={btnLoading}
                                                                    variant='outlined'
                                                                >
                                                                    Send
                                                                </LoadingButton>
                                                                {/* <Button disabled={btnLoading} ></Button> */}
                                                                {noticeData.isSent && (<h3>Notice sent to all</h3>)}

                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        )
                                    }

                                    {
                                        activeTab.sentNotice === 'active' && (
                                            <div className='sentNotice' >
                                                {
                                                    noticesData.length === 12 && (
                                                        <div className='limitNoticeSent'>
                                                            <h4>You have exhausted your limit of notices. You can store Max 12 notices at a time. Delete few notices to add new one.</h4>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    noticesData.length > 0 ? (
                                                        <>
                                                            {
                                                                noticesData.map((data, i) => {
                                                                    return (
                                                                        <SidebarNoticeBoardAccordian
                                                                            key={i}
                                                                            userCurrentStatus={userData.userCurrentStatus}
                                                                            userId={userData.userId}
                                                                            schoolId={userData.schoolId}
                                                                            schoolFullName={userData.schoolFullName}
                                                                            userPathCollection={userData.userPathCollection}
                                                                            userSchoolPath={userData.userSchoolPath}
                                                                            noticeSubject={data?.noticeData?.NOTICE_SUBJECT}
                                                                            noticeBody={data?.noticeData?.NOTICE_BODY}
                                                                            noticefrom={data?.noticeData?.NOTICE_FROM}
                                                                            noticeDate={data?.noticeData?.NOTICE_TIMESTAMP}
                                                                            noticeTimeStamp={data?.noticeData?.NOTICE_TIMESTAMP}
                                                                            noticeId={data?.noticeId}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    ) : (
                                                        <div className='notCreatedYet'>
                                                            <h3>You have not created/sent any notices yet. Please create a notice first</h3>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                            <hr />
                        </div>

                    </div >
                )
            }
        </>
    )
}

export default SidebarNoticeBoard
