import React, { useEffect, useState } from 'react'
import SchoolTimeTableSchoolClsNSub from './SchoolTimeTableSchoolClsNSub'
import '../ProjectsHomeworks/HomeWorksSchool.css'
import dB from '../../firebase'
import { doc, onSnapshot } from 'firebase/firestore'

function SchoolTimeTableSchool(props) {

    const { userId, userPathCollection, userCurrentStatus } = props

    const [userSchoolName, setuserSchoolName] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId) {
                onSnapshot(doc(dB, "SCHOOLS", userId), (schlDoc) => {
                    if (schlDoc.exists()) {
                        setuserSchoolName(schlDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userId])
    return (
        <div>
            <div className='HomeWorksSchool'>
                <div className='HomeWorksSchool__top'>
                    <h3 style={{ textAlign: 'center' }}>{userSchoolName?.SCHOOL_FULL_NAME}</h3>
                    <h4>Timetables</h4>
                    <hr />
                </div>

                <SchoolTimeTableSchoolClsNSub
                    userId={userId}
                    userPathCollection={userPathCollection}
                    userCurrentStatus={userCurrentStatus}
                    userSchoolId={userId}
                    userSchoolPathCollection={userPathCollection}
                    isPrinciVerified={null}
                />

            </div>
        </div>
    )
}

export default SchoolTimeTableSchool