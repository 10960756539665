import React, { useEffect, useState } from 'react'
import '../Social/SchoolAccountTop.css'
import '../Social/SchoolFriendTop.css'
import { collection, doc, onSnapshot, serverTimestamp, writeBatch } from 'firebase/firestore';
import { Avatar, Box, Button, Dialog, Modal, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';

const imageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
};

function SchoolFriendTop(props) {
    const { schoolId, schoolPathCollection, schoolFullName, schoolAffiliation,
        userId, userFullName, userPathCollection, userCurrentStatus, friendDP
    } = props

    const [followingSchlsIds, setfollowingSchlsIds] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                if (userCurrentStatus === 'SCHOOL') {
                    onSnapshot(collection(dB, "SCHOOLS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_SCHOOLS"), (snap) => {
                        setfollowingSchlsIds(snap.docs.map((schlDoc) => schlDoc.id))
                    })
                } else {
                    onSnapshot(collection(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_SCHOOLS"), (snap) => {
                        setfollowingSchlsIds(snap.docs.map((userDOc) => userDOc.id))
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [userCurrentStatus, userId, userPathCollection])

    // const classes = useStyles();

    const [btnDisabled, setbtnDisabled] = useState({
        btnFollowSchool: false, btnUnfollowSchool: false, btnSendMsg: false
    })

    var batch = writeBatch(dB)
    const handleFollowSchool = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnFollowSchool: true } })
        if (userCurrentStatus === 'SCHOOL') {
            batch.set(doc(dB, "SCHOOLS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_SCHOOLS", schoolId), {
                TIMESTAMP: serverTimestamp()
            })
            batch.set(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWERS", "FOLLOWER_SCHOOLS", userId), {
                TIMESTAMP: serverTimestamp()
            })
        }
        else {
            batch.set(doc(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_SCHOOLS", schoolId), {
                TIMESTAMP: serverTimestamp()
            })
            batch.set(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWERS", "FOLLOWER_USERS", userId), {
                TIMESTAMP: serverTimestamp()
            })
        }

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnFollowSchool: false } })
        }).catch(err => alert(err.message))
    }
    const handleUnFollowSchool = async (e) => {
        e.preventDefault();
        setbtnDisabled(prev => { return { ...prev, btnUnfollowSchool: true } })
        if (userCurrentStatus === 'SCHOOL') {

            batch.delete(doc(dB, "SCHOOLS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_SCHOOLS", schoolId))

            batch.delete(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWERS", "FOLLOWER_SCHOOLS", userId))

        } else {
            batch.delete(doc(dB, "USERS", userId, userPathCollection, "FOLLOWING", "FOLLOWING_SCHOOLS", schoolId))

            batch.delete(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "FOLLOWERS", "FOLLOWER_USERS", userId))
        }

        await batch.commit().then(() => {
            setbtnDisabled(prev => { return { ...prev, btnUnfollowSchool: false } })
        }).catch(err => alert(err.message))
    }


    const [open, setOpen] = useState(false);
    const handleClickOpen = (e) => { e.preventDefault(); setOpen(true); };
    const handleClose = (e) => { e.preventDefault(); setOpen(false); setmsgToSend('') };

    const [sentMessages, setsentMessages] = useState([])
    useEffect(() => {
        if (userId && userPathCollection) {
            if (userCurrentStatus === 'SCHOOL') {
                onSnapshot(collection(dB, "SCHOOLS", userId, userPathCollection, "MESSAGES_SENT", "MESSAGES"), (snap) => {
                    setsentMessages(snap.docs.map((sentDoc) => sentDoc.id))
                })
            } else {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "MESSAGES_SENT", "MESSAGES"), (snap) => {
                    setsentMessages(snap.docs.map((sentDoc) => sentDoc.id))
                })
            }
        }
    }, [userCurrentStatus, userId, userPathCollection])
    const [receivedMessages, setreceivedMessages] = useState([])
    useEffect(() => {
        if (userId && userPathCollection) {
            if (userCurrentStatus === 'SCHOOL') {
                onSnapshot(collection(dB, "SCHOOLS", userId, userPathCollection, "MESSAGES_RECIEVED", "MESSAGES"), (snap) => {
                    setreceivedMessages(snap.docs.map((recDOc) => recDOc.id))
                })
            } else {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "MESSAGES_RECIEVED", "MESSAGES"), (snap) => {
                    setreceivedMessages(snap.docs.map((recDOc) => recDOc.id))
                })
            }
        }
    }, [userCurrentStatus, userId, userPathCollection])

    // generate 14 digit random number
    const [randomKey, setrandomKey] = useState({ senderNum: '', reciverNum: '' })
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var a = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
            var b = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
            setrandomKey(prev => { return { ...prev, senderNum: a, reciverNum: b } })
        }
        return () => { aaa = false }

    }, [])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (sentMessages?.length > 0 && randomKey.senderNum) {
                if (sentMessages.includes(randomKey.senderNum)) {
                    setrandomKey(prev => { return { ...prev, senderNum: randomKey.senderNum + 1 } })
                }
            }
        }
        return () => { aaa = false }
    }, [randomKey.senderNum, sentMessages])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (receivedMessages?.length > 0 && randomKey.reciverNum) {
                if (receivedMessages.includes(randomKey.reciverNum)) {
                    setrandomKey(prev => { return { ...prev, reciverNum: randomKey.reciverNum + 1 } })
                }
            }
        }
        return () => { aaa = false }
    }, [randomKey.reciverNum, receivedMessages])
    const [showSent, setshowSent] = useState(false)
    const [msgToSend, setmsgToSend] = useState('')
    const [btnLoading, setbtnLoading] = useState(false)
    const handleClickSendMessage = async (e) => {
        e.preventDefault()
        setbtnLoading(true)
        setbtnDisabled(prev => { return { ...prev, btnSendMsg: true } })
        if (msgToSend !== '') {
            // add details to friendId in recieved message
            batch.set(doc(dB, "SCHOOLS", schoolId, schoolPathCollection, "MESSAGES_RECIEVED", "MESSAGES", randomKey.reciverNum), {
                MSG_TEXT: msgToSend,
                MSG_TIMESTAMP: serverTimestamp(),
                MSG_FROM: userId,
                MSG_SENDER_STATUS: userCurrentStatus === 'SCHOOL' ? 'SCHOOL' : 'USER',
                IS_VIEWED: false
            })
            // add details to userId in sent message
            if (userCurrentStatus === 'SCHOOL') {
                batch.set(doc(dB, "SCHOOLS", userId, userPathCollection, "MESSAGES_SENT", "MESSAGES", randomKey.senderNum), {
                    MSG_TEXT: msgToSend,
                    MSG_TIMESTAMP: serverTimestamp(),
                    MSG_TO: schoolId,
                    MSG_RECEIVER_STATUS: 'SCHOOL'
                })
            } else {
                batch.set(doc(dB, "USERS", userId, userPathCollection, "MESSAGES_SENT", "MESSAGES", randomKey.senderNum), {
                    MSG_TEXT: msgToSend,
                    MSG_TIMESTAMP: serverTimestamp(),
                    MSG_TO: schoolId,
                    MSG_RECEIVER_STATUS: 'SCHOOL'
                })
            }
            await batch.commit().then(() => {
                setbtnDisabled(prev => { return { ...prev, btnSendMsg: false } })
                setshowSent(true)
                setmsgToSend('')
                var c = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
                var d = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
                setrandomKey(prev => { return { ...prev, senderNum: c, reciverNum: d } })
                setbtnLoading(false)
            }).catch(err => alert(err.message))
        }
    }

    const [openImage, setOpenImage] = React.useState(false);
    const handleOpenImage = () => setOpenImage(true);
    const handleCloseImage = () => setOpenImage(false);

    return (
        <div className='schoolaccounttop'>
            <div className='schoolaccounttop__image'>
                <Box sx={{ display: 'flex', '& > *': { margin: 1, } }} id='schoolaccounttop__image'>
                    <Avatar
                        alt={schoolFullName}
                        src={friendDP}
                        // className={classes.Avatar}
                        onClick={handleOpenImage}
                        sx={{ width: 150, height: 150 }}
                    />
                    <Modal
                        open={openImage}
                        onClose={handleCloseImage}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={imageStyle}>
                            <div className='profileImageBig'>
                                <img src={friendDP} alt={schoolFullName} />
                            </div>
                        </Box>
                    </Modal>
                </Box>
            </div>
            <div className='schoolaccounttop__info'>
                <h5 >{schoolFullName}</h5>
                <p ><strong>(K to 12th)</strong></p>
                <p >{schoolAffiliation}</p>
                <div className='verifyButton'>
                    <div className='verifyButtonBut sendMsg'>
                        <Button onClick={handleClickOpen}>Message</Button>
                    </div>
                    {
                        followingSchlsIds.includes(schoolId) ? (
                            <div className='verifyButtonBut unFollowSchool'>
                                <Button disabled={btnDisabled.btnUnfollowSchool} onClick={handleUnFollowSchool}>Unfollow</Button>
                            </div>
                        ) : (
                            <div className='verifyButtonBut followSchool'>
                                <Button disabled={btnDisabled.btnFollowSchool} onClick={handleFollowSchool}>Follow</Button>
                            </div>
                        )
                    }
                </div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <div className='sendMsgBox'>
                        <TextField
                            id="outlined-multiline-static"
                            multiline
                            minRows={4}
                            placeholder='Type your message here..'
                            value={msgToSend}
                            onChange={(e) => { e.preventDefault(); setmsgToSend(e.target.value); setshowSent(false) }}
                        />
                        <LoadingButton
                            onClick={handleClickSendMessage}
                            variant='outlined'
                            loading={btnLoading}
                        >
                            Send Message
                        </LoadingButton>
                        {/* <Button disabled={btnDisabled.btnSendMsg} ></Button> */}
                        {showSent && (<h3>Message Sent..</h3>)}

                    </div>
                </Dialog>
            </div>
        </div>
    )
}

export default SchoolFriendTop
