import React, { useState, useEffect } from 'react'
import dB from '../../firebase'
import { doc, onSnapshot } from 'firebase/firestore'
import SchoolTimeTableUsersParentsChild from './SchoolTimeTableUsersParentsChild'

function SchoolTimeTableUsersStudents(props) {

    const { userId, userPathCollection, userCurrentStatus, userSchoolId, } = props

    const [stuSchoolData, setstuSchoolData] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userSchoolId) {
                onSnapshot(doc(dB, "SCHOOLS", userSchoolId), (stuDOc) => {
                    if (stuDOc.exists()) {
                        setstuSchoolData(stuDOc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userSchoolId])

    const [studData, setstudData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_STUDENT", "IS_STUDENT_DATA"), (studDoc) => {
                    if (studDoc.exists()) {
                        setstudData(studDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const [studVerification, setstudVerification] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_STUDENT", "VERIFICATION_DETAILS"), (verDoc) => {
                    if (verDoc.exists()) {
                        setstudVerification(verDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    return (
        <div>
            <div className='HomeWorksParents'>
                <div className='HomeWorksParents__top'>
                    <h3>{stuSchoolData?.SCHOOL_FULL_NAME}</h3>
                    <h4>Timetable</h4>
                    <hr />
                </div>

                <div className='HomeWorksParents__bottom'>
                    {
                        studVerification?.VERIFIED_STUDENT ? (
                            <SchoolTimeTableUsersParentsChild
                                userId={userId}
                                userPathCollection={userPathCollection}
                                userCurrentStatus={userCurrentStatus}
                                childSchoolId={userSchoolId}
                                childName={null}
                                childMedium={studData?.MEDIUM}
                                childClass={studData?.STUDENTS_CLASS}
                                childSection={studData?.STUDENTS_SECTION}
                                childVerified={studVerification?.VERIFIED_STUDENT}
                                childId={null}
                                childSchoolPathCollection={stuSchoolData?.SCHOOL_NAME}
                            />
                        ) : (
                            <div className='HomeWorksParentsChildNotVerified'>
                                <h3>You are not verified by your school at the moment.<br /> Please wait till school verifies you.</h3>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default SchoolTimeTableUsersStudents