import React, { useEffect, useState } from 'react'
import '../VerifiedMembers/SchoolVerifiedMembersPrincipal.css'
import '../VerificationRequest/SchoolVerificationRequestsPrincipal.css'
import '../../Pages/SchoolVerifiedMembers.css'
import { Link } from 'react-router-dom';
import { doc, onSnapshot, writeBatch } from 'firebase/firestore';
import { Avatar, Box, Button, Modal } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function SchoolVerifiedMembersPrincipal(props) {
    const { princiId, schoolId, schoolShortName, schoolFullName, schoolAffiliation, } = props

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [userData, setuserData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (princiId) {
                onSnapshot(doc(dB, "USERS", princiId), (snap) => {
                    if (snap.exists()) { setuserData(snap.data()) }
                })
            }
        }
        return () => { aaa = false }
    }, [princiId])

    const [princiRegName, setprinciRegName] = useState('')
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (princiId && userData) {
                onSnapshot(doc(dB, "USERS", princiId, userData?.USER_NAME, "REGISTRATION_INFO"), (snap) => {
                    if (snap.exists()) { setprinciRegName(snap.data()?.USER_NAME) }
                })
            }
        }
        return () => { aaa = false }
    }, [princiId, userData])

    var batch = writeBatch(dB)

    const [btnLoading, setbtnLoading] = useState(false)

    const handlePrinciRemove = async (e) => {
        e.preventDefault();
        setbtnLoading(true)

        await batch.delete(doc(dB, "SCHOOLS", schoolId, schoolShortName, "PRINCIPAL_DETAILS", "VERIFIED_MEMBERS", "PRINCIPAL_DATA"));

        batch.update(doc(dB, "USERS", princiId, userData?.USER_NAME, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), {
            VERIFIED_PRINCIPAL: false,
        })

        await batch.commit().then(() => {
            setOpen(false)
            setbtnLoading(false)
        }).catch(err => alert(err.message));
    }

    return (
        <div className='SchoolVerifiedMembersPrincipal' >

            <div className='SchoolVerifiedMembersPrincipal__containerRightPrincipalData' >
                <div className='SchoolVerifiedMembersPrincipal__containerRightPrincipalDataName dataNameParent'>
                    <Link to={`/friendsprofile/${princiId}`} style={{ textDecoration: 'none', color: 'gray' }}>
                        <Avatar
                            alt={princiRegName}
                            src={userData?.USER_DISPLAY_PIC}
                            sx={{ width: 50, height: 50, }}
                        />
                    </Link>
                    <h3>
                        <Link to={`/friendsprofile/${princiId}`} style={{ textDecoration: 'none', color: 'black' }}>
                            {princiRegName}
                        </Link>
                    </h3>
                </div>
                <div className='SchoolVerifiedMembersPrincipal__containerRightPrincipalDataButtons'>
                    <Button onClick={handleOpen}>Remove</Button>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div className='removeVerifiedUser'>
                                <div className='removeVerifiedUserText'>
                                    <h4>Do you really want to remove <br /> <strong>{princiRegName}</strong> from <strong>Principal's</strong> post?</h4>
                                </div>
                                <div className='removeVerifiedUserButtons'>
                                    <LoadingButton
                                        variant='outlined'
                                        loading={btnLoading}
                                        onClick={handlePrinciRemove}
                                    >
                                        Yes
                                    </LoadingButton>
                                    <Button onClick={handleClose}>No</Button>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                </div>
            </div>

        </div>
    )
}

export default SchoolVerifiedMembersPrincipal
