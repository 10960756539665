import React, { useEffect, useState } from 'react'
import '../Pages/FriendsProfile.css'
import Login from './Login'
import UserProfile from './UserProfile'
import Header from '../Components/Header/Header'
import FriendsProfileTop from '../Components/Social/FriendsProfileTop'
import UserProfileSidebar from '../Components/Social/UserProfileSidebar'
import HomePageFeed from '../Components/Social/HomePageFeed'
import UserAbout from '../Components/Social/UserAbout'
import UserPhotos from '../Components/Social/UserPhotos'
import UserFriends from '../Components/Social/UserFriends'

// import dB, { auth } from '../firebase'
import { collection, doc, onSnapshot } from 'firebase/firestore'
import { onAuthStateChanged } from 'firebase/auth'
import { useParams } from 'react-router-dom'

import PropTypes from 'prop-types';
import { AppBar, Box, Tab, Tabs, Typography } from '@mui/material'
import dB, { auth } from '../firebase'



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function FriendsProfile() {

    const [userData, setuserData] = useState({
        userId: '', userCurrentStatus: '', userPathCollection: '', userFullName: ''
    })
    useEffect(() => {
        const unSubscribe = onAuthStateChanged(auth, (userCredentials) => {
            if (userCredentials) {
                var uid = userCredentials.uid;
                onSnapshot(doc(dB, "USERS", uid), (userDoc) => {
                    if (userDoc.exists()) {
                        setuserData(prev => {
                            return {
                                ...prev,
                                userId: uid,
                                userCurrentStatus: userDoc.data()?.USER_CURRENT_STATUS,
                                userPathCollection: userDoc.data()?.USER_NAME,
                            }
                        })
                    } else {
                        onSnapshot(doc(dB, "SCHOOLS", uid), (schlDoc) => {
                            if (schlDoc.exists()) {
                                setuserData(prev => {
                                    return {
                                        ...prev,
                                        userId: uid,
                                        userCurrentStatus: schlDoc.data()?.USER_STATUS,
                                        userPathCollection: schlDoc.data()?.SCHOOL_NAME,
                                        userFullName: schlDoc.data()?.SCHOOL_FULL_NAME
                                    }
                                })
                            }
                        })
                    }
                })
            }
        });
        return () => { unSubscribe(); }
    }, [])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userData.userCurrentStatus !== 'SCHOOL') {
                if (userData.userId && userData.userPathCollection) {
                    onSnapshot(doc(dB, "USERS", userData.userId, userData.userPathCollection, "REGISTRATION_INFO"), (regDOc) => {
                        if (regDOc.exists()) {
                            setuserData(prev => { return { ...prev, userFullName: regDOc.data()?.USER_NAME, } })
                        }
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [userData.userCurrentStatus, userData.userId, userData.userPathCollection])

    const { friendsHandleId } = useParams();

    const [friendsData, setfriendsData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (friendsHandleId) {
                onSnapshot(doc(dB, "USERS", friendsHandleId), (frndDoc) => {
                    if (frndDoc.exists()) {
                        setfriendsData(frndDoc.data())
                    }
                })
            }
        }
        return () => { aaa = false }
    }, [friendsHandleId])

    const [friendRegInfoData, setfriendRegInfoData] = useState(null)
    const [friendSocialInfoData, setfriendSocialInfoData] = useState(null)
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (friendsData?.USER_NAME && friendsHandleId) {
                onSnapshot(doc(dB, "USERS", friendsHandleId, friendsData?.USER_NAME, "REGISTRATION_INFO"), (snap) => {
                    if (snap.exists()) { setfriendRegInfoData(snap.data()) }
                })
                onSnapshot(doc(dB, "USERS", friendsHandleId, friendsData?.USER_NAME, "SOCIAL_INFO"), (snap) => {
                    if (snap.exists()) { setfriendSocialInfoData(snap.data()) }
                })
            }
        }
        return () => { aaa = false }
    }, [friendsHandleId, friendsData?.USER_NAME])

    const [friendStatuses, setfriendStatuses] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (friendsHandleId && friendsData?.USER_NAME) {
                if (friendStatuses.length > 0) {
                    friendStatuses.splice(0, friendStatuses.length)
                }
                if (friendsHandleId && friendsData?.USER_NAME) {
                    onSnapshot(doc(dB, "USERS", friendsHandleId, friendsData?.USER_NAME, "E-SCHOOLING_INFO", "IS_TEACHER", "VERIFICATION_DETAILS"), (teachDoc) => {
                        if (teachDoc.exists()) { setfriendStatuses(prev => { return [...prev, teachDoc.data()?.USER_STATUS] }) }
                    })
                    onSnapshot(doc(dB, "USERS", friendsHandleId, friendsData?.USER_NAME, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), (princiDoc) => {
                        if (princiDoc.exists()) { setfriendStatuses(prev => { return [...prev, princiDoc.data()?.USER_STATUS] }) }
                    })
                    onSnapshot(doc(dB, "USERS", friendsHandleId, friendsData?.USER_NAME, "E-SCHOOLING_INFO", "IS_STUDENT", "VERIFICATION_DETAILS"), (studDOc) => {
                        if (studDOc.exists()) { setfriendStatuses(prev => { return [...prev, studDOc.data()?.USER_STATUS] }) }
                    })
                    onSnapshot(doc(dB, "USERS", friendsHandleId, friendsData?.USER_NAME, "E-SCHOOLING_INFO", "IS_PARENT", "VERIFICATION_DETAILS"), (parDoc) => {
                        if (parDoc.exists()) { setfriendStatuses(prev => { return [...prev, parDoc.data()?.USER_STATUS] }) }
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [friendsData?.USER_NAME, friendsHandleId, userData.userId, userData.userPathCollection])

    const [isStudentData, setisStudentData] = useState(null)
    const [studentVerificationDeatils, setstudentVerificationDeatils] = useState(null)
    const [princiVerificationDeatils, setprinciVerificationDeatils] = useState(null)
    const [isTeacherData, setisTeacherData] = useState(null)
    const [teacherVerificationDeatils, setteacherVerificationDeatils] = useState(null)


    // get data of students, teachers and principals
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (friendStatuses.length) {
                if (friendStatuses.includes('TEACHER')) {
                    onSnapshot(doc(dB, "USERS", friendsHandleId, friendsData?.USER_NAME, "E-SCHOOLING_INFO", "IS_TEACHER", "VERIFICATION_DETAILS"), (teachDoc) => {
                        if (teachDoc.exists()) { setteacherVerificationDeatils(teachDoc.data()) }
                    })
                    onSnapshot(collection(dB, "USERS", friendsHandleId, friendsData?.USER_NAME, "E-SCHOOLING_INFO", "IS_TEACHER", "IS_TEACHER_DATA", "SUBJECTS_TEACH"), (snap) => {
                        setisTeacherData(snap.docs.map((subDoc) => ({
                            subId: subDoc.id,
                            subData: subDoc.data(),
                        })))
                    })
                }
                if (friendStatuses.includes('STUDENT')) {
                    onSnapshot(doc(dB, "USERS", friendsHandleId, friendsData?.USER_NAME, "E-SCHOOLING_INFO", "IS_STUDENT", "VERIFICATION_DETAILS"), (studDoc) => {
                        if (studDoc.exists()) { setstudentVerificationDeatils(studDoc.data()) }
                    })
                    onSnapshot(doc(dB, "USERS", friendsHandleId, friendsData?.USER_NAME, "E-SCHOOLING_INFO", "IS_STUDENT", "IS_STUDENT_DATA"), (studDoc) => {
                        if (studDoc.exists()) { setisStudentData(studDoc.data()) }
                    })
                }
                if (friendStatuses.includes('PRINCIPAL')) {
                    onSnapshot(doc(dB, "USERS", friendsHandleId, friendsData?.USER_NAME, "E-SCHOOLING_INFO", "IS_PRINCIPAL", "VERIFICATION_DETAILS"), (princiDoc) => {
                        if (princiDoc.exists()) { setprinciVerificationDeatils(princiDoc.data()) }
                    })
                }
            }
        }
        return () => { aaa = false }
    }, [friendStatuses, friendsData?.USER_NAME, friendsHandleId])

    // const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => { event.preventDefault(); setValue(newValue); };

    return (
        <>
            {
                userData.userId === '' ? (
                    <Login />
                ) : (
                    <>
                        {
                            userData.userId === friendsHandleId ? (
                                <UserProfile />
                            ) : (
                                <div className='friendsprofile'>
                                    {/* Header */}
                                    <Header />

                                    <div className='friendsprofile__body'>
                                        <div className='friendsprofile__bodyTop'>
                                            {/* top profile pic and information */}
                                            <FriendsProfileTop
                                                friendId={friendsHandleId}
                                                friendCurrentStatus={friendsData?.USER_CURRENT_STATUS}
                                                friendPathCollection={friendsData?.USER_NAME}
                                                friendFullName={friendRegInfoData?.USER_NAME}
                                                friendDP={friendsData?.USER_DISPLAY_PIC}
                                                userId={userData.userId}
                                                userFullName={userData.userFullName}
                                                userPathCollection={userData.userPathCollection}
                                                userCurrentStatus={userData.userCurrentStatus}
                                            />
                                        </div>
                                        <div className='friendsprofile__bodyBottom'>
                                            <Box id='friendsprofile__bodyTopNavbarAppBar' sx={{ flexGrow: 1, backgroundColor: 'var(--connected-background)', }}>
                                                <AppBar color='inherit' position="static">
                                                    <Tabs indicatorColor='primary' textColor='primary' className='tab' value={value} onChange={handleChange} centered>
                                                        <Tab sx={{ fontSize: '16px', fontWeight: '600', width: "150px", color: 'black' }} label="Timeline" {...a11yProps(0)} />
                                                        <Tab sx={{ fontSize: '16px', fontWeight: '600', width: "150px", color: 'black' }} label="About" {...a11yProps(1)} />
                                                        <Tab sx={{ fontSize: '16px', fontWeight: '600', width: "150px", color: 'black' }} label="Photos" {...a11yProps(2)} />
                                                        <Tab sx={{ fontSize: '16px', fontWeight: '600', width: "150px", color: 'black' }} label="Connections" {...a11yProps(3)} />
                                                    </Tabs>
                                                </AppBar>
                                                <TabPanel value={value} index={0}>
                                                    <div id="feed" className='friendsprofile__bodyBottom'>
                                                        <div className='friendsprofile__bodyBottomLeft'>
                                                            {/* Sidebar -> Intro / Photos / Friends */}
                                                            <UserProfileSidebar
                                                                userBio={friendSocialInfoData?.USER_BIO}
                                                                userDOB={friendRegInfoData?.USER_DOB}
                                                                userGender={friendRegInfoData?.USER_GENDER}
                                                                userMobNo={friendRegInfoData?.USER_MOB_NO}
                                                            />
                                                        </div>
                                                        <div className='friendsprofile__bodyBottomRight'>
                                                            {/* Feed -> Posts */}
                                                            <HomePageFeed
                                                                userId={userData.userId}
                                                                userFullName={userData.userName}
                                                                userPathCollection={userData.userPathCollection}
                                                                userCurrentStatus={userData.userCurrentStatus}
                                                                userDP={friendsData?.USER_DISPLAY_PIC}

                                                                friendId={friendsHandleId}
                                                                friendFullName={friendRegInfoData?.USER_NAME}
                                                                friendPathCollection={friendsData?.USER_NAME}
                                                                friendCurrentStatus={friendsData?.USER_CURRENT_STATUS}
                                                                friendDP={friendsData?.USER_DISPLAY_PIC}

                                                                MainHomePage={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel value={value} index={1}>
                                                    <div id="about" className='friendsprofile__bodyBottom'>
                                                        <UserAbout
                                                            userId={userData.userId}
                                                            userCurrentStatus={userData.userCurrentStatus}
                                                            userPathCollection={userData.userPathCollection}
                                                            friendId={friendsHandleId}
                                                            friendFullName={friendRegInfoData?.USER_NAME}
                                                            friendPathCollection={friendsData?.USER_NAME}
                                                            friendCurrentStatus={friendsData?.USER_CURRENT_STATUS}

                                                            userBio={friendSocialInfoData?.USER_BIO}
                                                            userDOB={friendRegInfoData?.USER_DOB}
                                                            userGender={friendRegInfoData?.USER_GENDER}
                                                            userMobNo={friendRegInfoData?.USER_MOB_NO}
                                                            userEmail={friendSocialInfoData?.USER_EMAIL_ID}

                                                            userDegree1={friendSocialInfoData?.USER_DEGREE_1}
                                                            userDegree2={friendSocialInfoData?.USER_DEGREE_2}
                                                            userDegree3={friendSocialInfoData?.USER_DEGREE_3}

                                                            userAchievements={friendSocialInfoData?.USER_ACHIEVEMENTS}

                                                            isStudentData={isStudentData}
                                                            studentVerificationDeatils={studentVerificationDeatils}
                                                            princiVerificationDeatils={princiVerificationDeatils}
                                                            isTeacherData={isTeacherData}
                                                            teacherVerificationDeatils={teacherVerificationDeatils}
                                                            userStatuses={null}
                                                            friendStatuses={friendStatuses}
                                                        />
                                                    </div>
                                                </TabPanel>
                                                <TabPanel value={value} index={2}>
                                                    <div id="friends" className='friendsprofile__bodyBottom'>
                                                        <UserPhotos
                                                            userId={userData.userId}
                                                            userCurrentStatus={userData.userCurrentStatus}
                                                            userPathCollection={userData.userPathCollection}

                                                            friendId={friendsHandleId}
                                                            friendCurrentStatus={friendsData?.USER_CURRENT_STATUS}
                                                            friendPathCollection={friendsData?.USER_NAME}
                                                        />
                                                    </div>
                                                </TabPanel>
                                                <TabPanel value={value} index={3}>
                                                    <div id="photos" className='friendsprofile__bodyBottom'>
                                                        <UserFriends
                                                            userId={userData.userId}
                                                            userCurrentStatus={userData.userCurrentStatus}
                                                            userPathCollection={userData.userPathCollection}
                                                            friendId={friendsHandleId}
                                                            friendFullName={friendRegInfoData?.USER_NAME}
                                                            friendPathCollection={friendsData?.USER_NAME}
                                                            friendCurrentStatus={friendsData?.USER_CURRENT_STATUS}
                                                        />
                                                    </div>
                                                </TabPanel>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </>
                )

            }
        </>

    )
}

export default FriendsProfile;