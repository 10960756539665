import { collection, onSnapshot } from 'firebase/firestore'
import React, { useState, useEffect } from 'react'
import dB from '../../firebase'
import PrinciLeave from './PrinciLeave'

function ParentLeave(props) {

    const { userId, userPathCollection, userCurrentStatus, userSchoolId, princiSchoolData, princiVerified,
        classPath, medium, classNum, section, applStatus } = props

    const [allParRec, setallParRec] = useState([])
    useEffect(() => {
        if (princiSchoolData && userSchoolId && classPath && applStatus === "recieved") {
            onSnapshot(collection(dB, "SCHOOLS", userSchoolId, princiSchoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", classPath, "LEAVE_APPLICATION", "RECIEVED", "RECIEVED_APPLICATIONS"), (snap) => {
                setallParRec(snap.docs.map((parDoc) => ({
                    parRecAppId: parDoc.id,
                    parRecAppData: parDoc.data()
                })))
            })
        }
    }, [princiSchoolData, userSchoolId, classPath, applStatus])

    const [allParAcc, setallParAcc] = useState([])
    useEffect(() => {
        if (princiSchoolData && userSchoolId && classPath && applStatus === "accepted") {
            onSnapshot(collection(dB, "SCHOOLS", userSchoolId, princiSchoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", classPath, "LEAVE_APPLICATION", "ACCEPTED", "ACCEPTED_APPLICATIONS"), (snap) => {
                setallParAcc(snap.docs.map((parDoc) => ({
                    parAccAppId: parDoc.id,
                    parAccAppData: parDoc.data()
                })))
            })
        }
    }, [princiSchoolData, userSchoolId, classPath, applStatus])

    const [allParRej, setallParRej] = useState([])
    useEffect(() => {
        if (princiSchoolData && userSchoolId && classPath && applStatus === "rejected") {
            onSnapshot(collection(dB, "SCHOOLS", userSchoolId, princiSchoolData?.SCHOOL_NAME, "CLASSES_DETAILS", "CLASSES", classPath, "LEAVE_APPLICATION", "REJECTED", "REJECTED_APPLICATIONS"), (snap) => {
                setallParRej(snap.docs.map((parDoc) => ({
                    parRejAppId: parDoc.id,
                    parRejAppData: parDoc.data()
                })))
            })
        }
    }, [princiSchoolData, userSchoolId, classPath, applStatus])


    return (
        <div>
            {
                applStatus === "recieved" && (
                    <>
                        {
                            allParRec.map((rec, i) => (
                                <PrinciLeave
                                    key={i}
                                    leaveAppId={rec.parRecAppId}
                                    leaveAppData={rec.parRecAppData}

                                    userId={userId}
                                    userPathCollection={userPathCollection}
                                    userCurrentStatus={userCurrentStatus}
                                    userSchoolId={userSchoolId}
                                    princiSchoolData={princiSchoolData}

                                    appplicationStatus={"recieved"}
                                    showButtons={false}
                                />
                            ))
                        }
                    </>
                )
            }
            {
                applStatus === "accepted" && (
                    <>
                        {
                            allParAcc.map((rec, i) => (
                                <PrinciLeave
                                    key={i}
                                    leaveAppId={rec.parAccAppId}
                                    leaveAppData={rec.parAccAppData}

                                    userId={userId}
                                    userPathCollection={userPathCollection}
                                    userCurrentStatus={userCurrentStatus}
                                    userSchoolId={userSchoolId}
                                    princiSchoolData={princiSchoolData}

                                    appplicationStatus={"accepted"}
                                    showButtons={false}
                                />
                            ))
                        }
                    </>
                )
            }
            {
                applStatus === "rejected" && (
                    <>
                        {
                            allParRej.map((rec, i) => (
                                <PrinciLeave
                                    key={i}
                                    leaveAppId={rec.parRejAppId}
                                    leaveAppData={rec.parRejAppData}

                                    userId={userId}
                                    userPathCollection={userPathCollection}
                                    userCurrentStatus={userCurrentStatus}
                                    userSchoolId={userSchoolId}
                                    princiSchoolData={princiSchoolData}

                                    appplicationStatus={"rejected"}
                                    showButtons={false}
                                />
                            ))
                        }
                    </>
                )
            }
        </div>
    )
}

export default ParentLeave