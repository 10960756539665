import React, { useEffect, useState } from 'react'
// import '../Components/UserPhotos.css';
import '../Social/UserPhotos.css'
// import dB, { storage } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, listAll, ref } from 'firebase/storage';
import { Box, Button, Modal } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dB, { storage } from '../../firebase';

const imageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
};
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function UserPhotos(props) {
    const { userId, userCurrentStatus, userPathCollection, friendId, friendCurrentStatus, friendPathCollection } = props

    const [profilePics, setprofilePics] = useState([])
    const [timelinePics, settimelinePics] = useState([])

    useEffect(() => {
        if (friendId === null) {
            if (userId && userCurrentStatus) {
                if (userCurrentStatus === 'SCHOOL') {
                    const timelineRef = ref(storage, `SCHOOLS/${userId}/Timeline`);
                    listAll(timelineRef)
                        .then((res) => {
                            res.items.map((itemRef) => {
                                getDownloadURL(itemRef).then((url) => {
                                    settimelinePics(prev => [...prev, url])
                                }).catch((error) => alert(error.message));
                            });
                        }).catch((error) => alert(error.message));

                    const profileRef = ref(storage, `SCHOOLS/${userId}/Profile`);
                    listAll(profileRef)
                        .then((res) => {
                            res.items.map((itemRef) => {
                                getDownloadURL(itemRef).then((url) => {
                                    setprofilePics(prev => [...prev, url])
                                }).catch((error) => alert(error.message));
                            });
                        }).catch((error) => alert(error.message));
                }
                else {
                    const timelineRef = ref(storage, `USERS/${userId}/Timeline`);
                    listAll(timelineRef)
                        .then((res) => {
                            res.items.map((itemRef) => {
                                getDownloadURL(itemRef).then((url) => {
                                    settimelinePics(prev => [...prev, url])
                                }).catch((error) => alert(error.message));
                            });
                        }).catch((error) => alert(error.message));

                    const profileRef = ref(storage, `USERS/${userId}/Profile`);
                    listAll(profileRef)
                        .then((res) => {
                            res.items.map((itemRef) => {
                                getDownloadURL(itemRef).then((url) => {
                                    setprofilePics(prev => [...prev, url])
                                }).catch((error) => alert(error.message));
                            });
                        }).catch((error) => alert(error.message));
                }
            }
        } else {
            if (friendId && friendCurrentStatus) {
                if (friendCurrentStatus === 'SCHOOL') {
                    const timelineRef = ref(storage, `SCHOOLS/${friendId}/Timeline`);
                    listAll(timelineRef)
                        .then((res) => {
                            res.items.map((itemRef) => {
                                getDownloadURL(itemRef).then((url) => {
                                    settimelinePics(prev => [...prev, url])
                                }).catch((error) => alert(error.message));
                            });
                        }).catch((error) => alert(error.message));

                    const profileRef = ref(storage, `SCHOOLS/${friendId}/Profile`);
                    listAll(profileRef)
                        .then((res) => {
                            res.items.map((itemRef) => {
                                getDownloadURL(itemRef).then((url) => {
                                    setprofilePics(prev => [...prev, url])
                                }).catch((error) => alert(error.message));
                            });
                        }).catch((error) => alert(error.message));
                }
                else {
                    const timelineRef = ref(storage, `USERS/${friendId}/Timeline`);
                    listAll(timelineRef)
                        .then((res) => {
                            res.items.map((itemRef) => {
                                getDownloadURL(itemRef).then((url) => {
                                    settimelinePics(prev => [...prev, url])
                                }).catch((error) => alert(error.message));
                            });
                        }).catch((error) => alert(error.message));

                    const profileRef = ref(storage, `USERS/${friendId}/Profile`);
                    listAll(profileRef)
                        .then((res) => {
                            res.items.map((itemRef) => {
                                getDownloadURL(itemRef).then((url) => {
                                    setprofilePics(prev => [...prev, url])
                                }).catch((error) => alert(error.message));
                            });
                        }).catch((error) => alert(error.message));
                }
            }
        }
    }, [friendCurrentStatus, friendId, userCurrentStatus, userId])

    const [selectedPic, setselectedPic] = useState('')
    const [openImage, setOpenImage] = React.useState(false);
    const handleOpenImage = (pic) => {
        setOpenImage(true);
        setselectedPic(pic)
    }
    const handleCloseImage = () => setOpenImage(false);

    const [disabledButtons, setdisabledButtons] = useState({ yesDisabled: false, noDisabled: false })

    // open yes/no modal for deleting post
    const [openModalRemove, setOpenModalRemove] = useState(false);
    const handleOpenModalRemove = (pic) => { setselectedPicRemove(pic); setOpenModalRemove(true); };
    const handleCloseModalRemove = (e) => { setOpenModalRemove(false); };

    // open yes/no modal for deleting post
    const [openModalProfilePic, setOpenModalProfilePic] = useState(false);
    const handleOpenModalProfilePic = (pic) => { setselectedPicProfilePic(pic); setOpenModalProfilePic(true); };
    const handleCloseModalProfilePic = (e) => { setOpenModalProfilePic(false); };

    const [selectedPicRemove, setselectedPicRemove] = useState('');
    const [selectedPicProfilePic, setselectedPicProfilePic] = useState('');

    const [btnLoading, setbtnLoading] = useState(false)
    const handleMakeProfilePic = (selectedPicProfilePic) => {
        setbtnLoading(true)
        setdisabledButtons(prev => { return { ...prev, yesDisabled: true } })

        if (userCurrentStatus === 'SCHOOL') {
            updateDoc(doc(dB, "SCHOOLS", userId), {
                USER_DISPLAY_PIC: selectedPicProfilePic,
            }).then(() => {
                setOpenModalProfilePic(false)
                setbtnLoading(false)
                setdisabledButtons(prev => { return { ...prev, yesDisabled: false, noDisabled: false } })
            }).catch(err => alert(err.message))
        } else {
            updateDoc(doc(dB, "USERS", userId), {
                USER_DISPLAY_PIC: selectedPicProfilePic,
            }).then(() => {
                setOpenModalProfilePic(false)
                setbtnLoading(false)
                setdisabledButtons(prev => { return { ...prev, yesDisabled: false, noDisabled: false } })
            }).catch(err => alert(err.message))
        }
    }

    const handleRemovePic = async (selectedPicRemove) => {
        setbtnLoading(true)
        setdisabledButtons(prev => { return { ...prev, yesDisabled: true } })
        const photosRef = ref(storage, selectedPicRemove);
        deleteObject(photosRef).then(() => { setbtnLoading(false); setOpenModalRemove(false) }).catch((err) => err.message);
    }

    return (
        <div className='photosContainer'>
            <div className='userphotos'>
                <h2>Profile Photos</h2>
                <div className='userphotos__profilepics'>
                    <hr />
                    <div id='gridContainerPhotos'>
                        {
                            profilePics.length > 0 ? (
                                <>
                                    {
                                        profilePics.map((pic, i) => (
                                            <div key={i}>
                                                <div
                                                    style={{
                                                        backgroundImage: `url(${pic})`,
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundSize: 'cover',
                                                        height: '150px',
                                                        width: '150px',
                                                        borderRadius: '10px',
                                                        cursor: 'pointer'
                                                    }}
                                                    className='imageDiv'
                                                    onClick={() => handleOpenImage(pic)}
                                                >
                                                </div>
                                                {
                                                    !friendId && (
                                                        <div className='imageOptions'>
                                                            <h3 onClick={(e) => { e.preventDefault(); handleOpenModalRemove(pic) }}>Remove</h3>
                                                            <h4 onClick={(e) => { e.preventDefault(); handleOpenModalProfilePic(pic) }}>Make Profile Pic</h4>
                                                        </div>
                                                    )
                                                }


                                                <Modal
                                                    open={openModalRemove}
                                                    onClose={handleCloseModalRemove}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                >
                                                    <Box sx={style}>
                                                        <div className='removePic'>
                                                            <div className='removePicText'>
                                                                <h4>Do you really want to delete this photo?</h4>
                                                                <div
                                                                    style={{
                                                                        backgroundImage: `url(${selectedPicRemove})`,
                                                                        backgroundRepeat: 'no-repeat',
                                                                        backgroundSize: 'cover',
                                                                        height: '150px',
                                                                        width: '150px',
                                                                        borderRadius: '10px',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    className='imageDiv'
                                                                >
                                                                </div>
                                                            </div>
                                                            <div className='removePicButtons'>
                                                                <LoadingButton
                                                                    onClick={(e) => { e.preventDefault(); handleRemovePic(selectedPicRemove) }}
                                                                    loading={btnLoading}
                                                                    variant='outlined'
                                                                >
                                                                    Yes
                                                                </LoadingButton>
                                                                {/* <Button disabled={disabledButtons.yesDisabled} ></Button> */}
                                                                <Button disabled={disabledButtons.noDisabled} onClick={handleCloseModalRemove}>No</Button>
                                                            </div>
                                                        </div>
                                                    </Box>
                                                </Modal>

                                                <Modal
                                                    open={openModalProfilePic}
                                                    onClose={handleCloseModalProfilePic}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                >
                                                    <Box sx={style}>
                                                        <div className='makeProfilePic'>
                                                            <div className='makeProfilePicText'>
                                                                <h4>Do you really want to set this photo as your profile picture?</h4>
                                                                <div
                                                                    style={{
                                                                        backgroundImage: `url(${selectedPicProfilePic})`,
                                                                        backgroundRepeat: 'no-repeat',
                                                                        backgroundSize: 'cover',
                                                                        height: '150px',
                                                                        width: '150px',
                                                                        borderRadius: '10px',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    className='imageDiv'
                                                                >
                                                                </div>
                                                            </div>
                                                            <div className='makeProfilePicButtons'>
                                                                <LoadingButton
                                                                    onClick={(e) => { e.preventDefault(); handleMakeProfilePic(selectedPicProfilePic) }}
                                                                    loading={btnLoading}
                                                                    variant='outlined'
                                                                >
                                                                    Yes
                                                                </LoadingButton>
                                                                {/* <Button disabled={disabledButtons.yesDisabled} >Yes</Button> */}
                                                                <Button disabled={disabledButtons.noDisabled} onClick={handleCloseModalProfilePic}>No</Button>
                                                            </div>
                                                        </div>
                                                    </Box>
                                                </Modal>
                                            </div>
                                        ))
                                    }
                                </>
                            ) : (
                                <div className='noImagesToShow'>
                                    <h3>No photos to show</h3>
                                </div>
                            )
                        }
                        <Modal
                            open={openImage}
                            onClose={handleCloseImage}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={imageStyle}>
                                <div className='profileImageBig'>
                                    <img src={selectedPic} alt={selectedPic} />
                                </div>
                            </Box>
                        </Modal>
                    </div>
                </div>
            </div>
            <div className='userphotos'>
                <h2>Timeline Photos</h2>
                <div className='userphotos__profilepics'>
                    <hr />
                    <div id='gridContainerPhotos'>
                        {
                            timelinePics.length > 0 ? (
                                <>
                                    {
                                        timelinePics.map((pic, i) => (
                                            <div key={i} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <div
                                                    style={{
                                                        backgroundImage: `url(${pic})`,
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundSize: 'cover',
                                                        height: '150px',
                                                        width: '150px',
                                                        borderRadius: '10px',
                                                        cursor: 'pointer'
                                                    }}
                                                    className='imageDiv'
                                                    onClick={() => handleOpenImage(pic)}
                                                >
                                                </div>
                                                {
                                                    !friendId && (
                                                        <div className='imageOptions'>
                                                            <h4 onClick={(e) => { e.preventDefault(); handleOpenModalProfilePic(pic) }}>Make Profile Pic</h4>
                                                        </div>
                                                    )
                                                }

                                                <Modal
                                                    open={openModalProfilePic}
                                                    onClose={handleCloseModalProfilePic}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                >
                                                    <Box sx={style}>
                                                        <div className='makeProfilePic'>
                                                            <div className='makeProfilePicText'>
                                                                <h4>Do you really want to set this photo as your profile picture?</h4>
                                                                <div
                                                                    style={{
                                                                        backgroundImage: `url(${selectedPicProfilePic})`,
                                                                        backgroundRepeat: 'no-repeat',
                                                                        backgroundSize: 'cover',
                                                                        height: '150px',
                                                                        width: '150px',
                                                                        borderRadius: '10px',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    className='imageDiv'
                                                                >
                                                                </div>
                                                            </div>
                                                            <div className='makeProfilePicButtons'>
                                                                <LoadingButton
                                                                    onClick={(e) => { e.preventDefault(); handleMakeProfilePic(selectedPicProfilePic) }}
                                                                    loading={btnLoading}
                                                                    variant='outlined'
                                                                >
                                                                    Yes
                                                                </LoadingButton>
                                                                {/* <Button disabled={disabledButtons.yesDisabled} >Yes</Button> */}
                                                                <Button disabled={disabledButtons.noDisabled} onClick={handleCloseModalProfilePic}>No</Button>
                                                            </div>
                                                        </div>
                                                    </Box>
                                                </Modal>
                                            </div>
                                        ))
                                    }
                                </>
                            ) : (
                                <div className='noImagesToShow'>
                                    <h3>No photos to show</h3>
                                </div>
                            )
                        }
                        <Modal
                            open={openImage}
                            onClose={handleCloseImage}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={imageStyle}>
                                <div className='profileImageBig'>
                                    <img src={selectedPic} alt={selectedPic} />
                                </div>
                            </Box>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserPhotos