import React, { useEffect, useState } from 'react'
import '../Sidebars/SidebarESchoolingStatusShowParent.css'
import SidebarESchoolingStatusShowParentChild from './SidebarESchoolingStatusShowParentChild';
import { collection, doc, onSnapshot, serverTimestamp, writeBatch } from 'firebase/firestore';
import {
    Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, FormControl,
    FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import dB from '../../firebase';
import { returnMedClsSec } from '../../Constants/FunctionClsMedSec';
import { returnSection } from '../../Constants/FuncMedium';

function SidebarESchoolingStatusShowParent(props) {
    const { userId, userPathCollection, userStatuses, userCurrentStatus } = props

    const [parentChilds, setparentChilds] = useState([])
    const [parentsChildIdList, setparentsChildIdList] = useState([])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (userId && userPathCollection) {
                onSnapshot(collection(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO"), (snap) => {
                    setparentChilds(snap.docs.map((childDOc) => ({
                        childId: childDOc.id,
                        childData: childDOc.data(),
                    })))
                    setparentsChildIdList(snap.docs.map((childDoc) => childDoc.id))
                })
            }
        }
        return () => { aaa = false }
    }, [userId, userPathCollection])

    const [showEnteredParents, setshowEnteredParents] = useState({
        childSchoolName: { error: false },
        childFName: { error: false, helperText: '' },
        childLName: { error: false, helperText: '' },
        childClass: { error: false, helperText: '' },
        childSection: { error: false, helperText: '' },
        childsMedium: { error: false, helperText: '' },
    })
    const [parentsData, setparentsData] = useState({
        childFName: '', childLName: '', childSchoolName: null, childSchoolNameInputValue: '',
        childsMedium: '', childClass: '', childSection: '',
    })
    const [parentsOptions, setparentsOptions] = useState([])
    useEffect(() => {
        if (parentsOptions.length > 0) {
            parentsOptions.splice(0, parentsOptions.length)
        }
        var aaa = true
        if (aaa === true) {
            onSnapshot(collection(dB, "SCHOOLS"), (snap) => {
                snap.forEach((schlDOc) => {
                    const newdata = schlDOc.data()?.SCHOOL_FULL_NAME;
                    setparentsOptions(prev => { return [...prev, { id: schlDOc.id, schoolName: newdata }] })
                });
            })
        }
        return () => { aaa = false }
    }, [])
    const [parentsSchoolPath, setparentsSchoolPath] = useState({ schoolSmallName: '', schoolId: '', })
    const [returnedChildsClassList, setreturnedChildsClassList] = useState([])
    useEffect(() => {
        if (returnedChildsClassList.length > 0) {
            returnedChildsClassList.splice(0, returnedChildsClassList.length)
        }
        var aaa = true
        if (aaa === true) {
            onSnapshot(collection(dB, "SCHOOLS"), (snap) => {
                snap.forEach((schlDoc) => {
                    const newdata = schlDoc.data()?.SCHOOL_FULL_NAME;
                    if (newdata === parentsData.childSchoolName) {
                        const schoolCollection = schlDoc.data()?.SCHOOL_NAME;
                        setparentsSchoolPath(prevState => { return { ...prevState, schoolSmallName: schoolCollection, schoolId: schlDoc.id } })
                        onSnapshot(doc(dB, "SCHOOLS", schlDoc.id, schoolCollection, "CLASSES_DETAILS"), (classes) => {
                            if (classes.exists()) {
                                setreturnedChildsClassList(classes.data()?.CLASS_LIST)
                            }
                        })
                    }
                });
            })
        }
        return () => { aaa = false }
    }, [parentsData.childSchoolName])

    const [returnedChildsSectionList, setreturnedChildsSectionList] = useState(null);
    useEffect(() => {
        setreturnedChildsSectionList(null)
        var aaa = true
        if (aaa === true) {
            var sectionObject = returnSection(parentsData.childsMedium, parentsData.childClass, returnedChildsClassList)
            setreturnedChildsSectionList(sectionObject)
        }
        return () => { aaa = false }
    }, [parentsData.childsMedium, parentsData.childClass, returnedChildsClassList])

    const [paarentChildClassSection, setpaarentChildClassSection] = useState('')
    useEffect(() => {
        setpaarentChildClassSection('')
        var aaa = true
        if (aaa === true) {
            var abc = returnMedClsSec(parentsData.childsMedium, parentsData.childClass, parentsData.childSection)
            setpaarentChildClassSection(abc)

        }
        return () => { aaa = false }
    }, [parentsData.childClass, parentsData.childSection, parentsData.childsMedium])

    // generate 20 digit random number
    const [randomKey, setrandomKey] = useState({ randomId1: '', })
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            var a = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
            setrandomKey(prevState => { return { ...prevState, randomId1: a } })
        }
        return () => { aaa = false }
    }, [])
    useEffect(() => {
        var aaa = true
        if (aaa === true) {
            if (parentsChildIdList?.length > 0) {
                if (parentsChildIdList?.includes(randomKey.randomId1)) {
                    var b = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
                    setrandomKey(prevState => { return { ...prevState, randomId1: b } })
                }
            }
        }
        return () => { aaa = false }
    }, [parentsChildIdList, randomKey.randomId1])

    const [actionsPerformed, setactionsPerformed] = useState({
        msgSent: false, buttonDisabled: false, buttonRemoveDisabled: false
    })

    var batch = writeBatch(dB)

    const [btnLoading, setbtnLoading] = useState(false)
    const sendRequestChild = async (e) => {
        e.preventDefault()
        setbtnLoading(true)
        setactionsPerformed(prev => { return { ...prev, msgSent: false, buttonDisabled: true } })
        if (parentsData.childFName && parentsData.childLName && parentsData.childSchoolName && parentsData.childClass && parentsData.childSection && parentsData.childsMedium) {

            const childFullName = parentsData.childFName + ' ' + parentsData.childLName

            await batch.set(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA", "CHILDS_INFO", randomKey.randomId1), {
                CHILDS_FULL_NAME: childFullName,
                CHILDS_CLASS: parentsData.childClass,
                CHILDS_SECTION: parentsData.childSection,
                CHILDS_MEDIUM: parentsData.childsMedium,
                VERIFIED_CHILD: false,
                CHILDS_SCHOOL_ID: parentsSchoolPath.schoolId
            });
            await batch.update(doc(dB, "USERS", userId, userPathCollection, "E-SCHOOLING_INFO", "IS_PARENT", "IS_PARENT_DATA"), {
                CURRENT_SELECTED_CHILD: randomKey.randomId1
            })

            await batch.set(doc(dB, "SCHOOLS", parentsSchoolPath.schoolId, parentsSchoolPath.schoolSmallName, "CLASSES_DETAILS", "CLASSES", paarentChildClassSection, "PARENTS", "VERIFICATION_REQUESTS", "VERIFICATION_REQUESTS_DATA", userId, "CHILDS_LIST", randomKey.randomId1), {
                TIMESTAMP: serverTimestamp()
            });

            await batch.commit().then(() => {
                setbtnLoading(false)
                setactionsPerformed(prev => { return { ...prev, msgSent: true, buttonDisabled: false } })
                setparentsData(prev => {
                    return {
                        ...prev, childFName: '', childLName: '', childSchoolName: '', childSchoolNameInputValue: '',
                        childsMedium: '', childClass: '', childSection: ''
                    }
                })
            }).catch(err => alert(err.message))
        } else {
            setbtnLoading(false)
            if (!parentsData.childFName) { setshowEnteredParents(prevState => { return { ...prevState, childFName: { error: true, helperText: 'Please enter your childs First Name.' } } }) }
            if (!parentsData.childLName) { setshowEnteredParents(prevState => { return { ...prevState, childLName: { error: true, helperText: 'Please enter your childs Last Name.' } } }) }
            if (!parentsData.childSchoolName) { setshowEnteredParents(prevState => { return { ...prevState, childSchoolName: { error: true, helperText: 'Please select your childs school name.' } } }) }
            if (!parentsData.childClass) { setshowEnteredParents(prevState => { return { ...prevState, childClass: { error: true, helperText: 'Please select the class your child is studying in.' } } }) }
            if (!parentsData.childSection) { setshowEnteredParents(prevState => { return { ...prevState, childSection: { error: true, helperText: 'Please select your childs section here.' } } }) }
            if (!parentsData.childsMedium) { setshowEnteredParents(prevState => { return { ...prevState, childsMedium: { error: true, helperText: 'Please select your childs medium here.' } } }) }
        }
    }

    return (
        <div className='SidebarESchoolingStatusShowParent'>
            <div className='rowData'>
                <h2>User E-Schooling Status</h2>
                <h4>:</h4>
                <h3>"PARENT"</h3>
            </div>
            <div className='previouslyAddedChild'>
                <h3>Existing child/childs</h3>
                {
                    parentChilds.map((data, i) => (
                        <SidebarESchoolingStatusShowParentChild
                            key={i}
                            childsId={data?.childId}
                            childName={data?.childData?.CHILDS_FULL_NAME}
                            isChildVerified={data?.childData?.VERIFIED_CHILD}
                            childMedium={data?.childData?.CHILDS_MEDIUM}
                            childSchoolId={data?.childData?.CHILDS_SCHOOL_ID}
                            childClass={data?.childData?.CHILDS_CLASS}
                            childSection={data?.childData?.CHILDS_SECTION}
                            childPic={data?.childData?.CHILD_DISPLAY_PIC}
                            userId={userId}
                            userPathCollection={userPathCollection}
                            userStatuses={userStatuses}
                            parentsChildIdList={parentsChildIdList}
                            userCurrentStatus={userCurrentStatus}
                        />
                    ))
                }
            </div>
            {
                parentsChildIdList?.length === 6 ? (
                    <div className='exceededLimit'>
                        <h3>You have exceeded your limit. You can not add more childs.</h3>
                    </div>
                ) : (
                    <div className='addedNewChild'>
                        <h3>Add New Child</h3>
                        <Box id='addedNewChild' sx={{ width: '800px', marginLeft: '50px', marginTop: '20px' }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className='addNewChildDataHereTop'>
                                        <h3>Add Childs information here</h3>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className='addNewChildDataHere'>
                                        <div className='addNewChildDataHereName'>
                                            <FormControl error={showEnteredParents.childFName.error} name='childFName' sx={{ width: '35ch', marginRight: '14px' }} variant="outlined">
                                                <InputLabel htmlFor="childFName">Childs First Name</InputLabel>
                                                <OutlinedInput
                                                    id="childFName"
                                                    name='childFName'
                                                    type='text'
                                                    value={parentsData.childFName}
                                                    onChange={(e) => {
                                                        e.preventDefault()
                                                        setparentsData(prevState => { return { ...prevState, childFName: e.target.value } })
                                                        setshowEnteredParents(prevState => { return { ...prevState, childFName: { error: false, helperText: '' } } })
                                                        setactionsPerformed(prev => { return { ...prev, msgSent: false } })
                                                    }}
                                                    label='Childs First Name'

                                                />
                                                <FormHelperText id="childFName"><p>{showEnteredParents.childFName.helperText}</p></FormHelperText>
                                            </FormControl>

                                            <FormControl error={showEnteredParents.childLName.error} name='childLName' sx={{ width: '35ch', marginRight: '14px' }} variant="outlined">
                                                <InputLabel htmlFor="childLName">Childs Last Name</InputLabel>
                                                <OutlinedInput
                                                    id="childLName"
                                                    name='childLName'
                                                    type='text'
                                                    value={parentsData.childLName}
                                                    onChange={(e) => {
                                                        e.preventDefault()
                                                        setparentsData(prevState => { return { ...prevState, childLName: e.target.value } })
                                                        setshowEnteredParents(prevState => { return { ...prevState, childLName: { error: false, helperText: '' } } })
                                                        setactionsPerformed(prev => { return { ...prev, msgSent: false } })
                                                    }}
                                                    label='Childs Last Name'

                                                />
                                                <FormHelperText id="childLName"><p>{showEnteredParents.childLName.helperText}</p></FormHelperText>
                                            </FormControl>
                                        </div>

                                        <div className='addNewChildDataHereSchoolName'>
                                            <Autocomplete
                                                value={parentsData.childSchoolName}
                                                onChange={(e, newValue) => {
                                                    e.preventDefault()
                                                    setparentsData(prev => { return { ...prev, childSchoolName: newValue } })
                                                    setparentsData(prevState => { return { ...prevState, childClass: '', childSection: '', childsMedium: '' } })
                                                    setactionsPerformed(prev => { return { ...prev, msgSent: false } })
                                                }}
                                                inputValue={parentsData.childSchoolNameInputValue}
                                                onInputChange={(event, newInputValue) => {
                                                    event.preventDefault()
                                                    setparentsData(prev => { return { ...prev, childSchoolNameInputValue: newInputValue } })
                                                    if (returnedChildsClassList.length > 0) {
                                                        returnedChildsClassList.splice(0, returnedChildsClassList.length)
                                                    }
                                                    setshowEnteredParents(prevState => { return { ...prevState, childSchoolName: { error: false } } })
                                                }}
                                                id="childSchoolName"
                                                name='childSchoolName'
                                                options={parentsOptions.map((option) => option.schoolName)}
                                                // className={clsx(classes.longWidth)}
                                                sx={{ width: '620px', }}
                                                renderInput={(params) =>
                                                    <TextField
                                                        id="childSchoolName"
                                                        name='childSchoolName'
                                                        {...params}
                                                        margin="normal"
                                                        label="Search your child's school here by school name, village ,city or district"
                                                        variant="outlined"
                                                        error={showEnteredParents.childSchoolName.error}
                                                    />
                                                }
                                            />
                                            <div className='noAvailable'>
                                                <h5><b>Note : </b>If School you are looking for does not appear in the list, then your school is yet to create an account. Ask your school to create account first. Till then you can use this platform to follow other schools and make new friends.</h5>
                                            </div>
                                        </div>

                                        <FormControl error={showEnteredParents.childsMedium.error} name='childsMedium' variant="outlined" >
                                            <InputLabel htmlFor="childsMedium">Medium</InputLabel>
                                            <Select sx={{ width: '30ch' }} id="childsMedium" name='childsMedium' label="Section" inputProps={{ name: 'childsMedium', id: 'childsMedium', }}
                                                value={parentsData.childsMedium}
                                                onChange={(e) => {
                                                    e.preventDefault()
                                                    setparentsData(prevState => { return { ...prevState, childsMedium: e.target.value } })
                                                    setparentsData(prevState => { return { ...prevState, childClass: '', childSection: '' } })
                                                    setshowEnteredParents(prevState => { return { ...prevState, childsMedium: { error: false, helperText: '' } } })
                                                }}
                                            >
                                                <MenuItem style={{ display: parentsData.childSchoolName === '' ? 'block' : 'none' }} aria-label="None" value="" />

                                                <MenuItem style={{ display: parentsData.childSchoolName === '' ? 'none' : 'block' }} key='English Medium' value='English Medium'>English Medium</MenuItem>
                                                <MenuItem style={{ display: parentsData.childSchoolName === '' ? 'none' : 'block' }} key='Marathi Medium' value='Marathi Medium'>Marathi Medium</MenuItem>
                                            </Select>
                                            <FormHelperText id="childsMedium"><p>{showEnteredParents.childsMedium.helperText}</p></FormHelperText>
                                        </FormControl>

                                        <div className='addNewChildDataHereClassNSection'>
                                            <FormControl error={showEnteredParents.childClass.error} name='childClass' variant="outlined" >
                                                <InputLabel htmlFor="childClass">Class :</InputLabel>
                                                <Select sx={{ width: '37ch', marginRight: '14px' }} id="childClass" name='childClass' label="Class : " inputProps={{ name: 'childClass', id: 'childClass', }}
                                                    value={parentsData.childClass}
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setparentsData(prevState => { return { ...prevState, childClass: e.target.value } })
                                                        setparentsData(prevState => { return { ...prevState, childSection: '' } })
                                                        setshowEnteredParents(prevState => { return { ...prevState, childClass: { error: false, helperText: '' } } })
                                                    }}
                                                >
                                                    <MenuItem style={{ display: parentsData.childsMedium != null ? 'none' : 'block' }} aria-label="None" value="" />

                                                    <MenuItem style={{ display: parentsData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngPreNurserySecA') ? 'block' : 'none' }} id='Eng Pre Nursery' key='Eng Pre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngNurserySecA') ? 'block' : 'none' }} id='Eng Nursery' key='Eng Nursery' value='Nursery'>Nursery</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngKG1SecA') ? 'block' : 'none' }} id='Eng Kindergarten 1(KG-1)' key='Eng Kindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngKG2SecA') ? 'block' : 'none' }} id='Eng Kindergarten 2(KG-2)' key='Eng Kindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass1SecA') ? 'block' : 'none' }} id='Eng Class 1' key='Eng Class 1' value='Class 1'>Class 1</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass2SecA') ? 'block' : 'none' }} id='Eng Class 2' key='Eng Class 2' value='Class 2'>Class 2</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass3SecA') ? 'block' : 'none' }} id='Eng Class 3' key='Eng Class 3' value='Class 3'>Class 3</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass4SecA') ? 'block' : 'none' }} id='Eng Class 4' key='Eng Class 4' value='Class 4'>Class 4</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass5SecA') ? 'block' : 'none' }} id='Eng Class 5' key='Eng Class 5' value='Class 5'>Class 5</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass6SecA') ? 'block' : 'none' }} id='Eng Class 6' key='Eng Class 6' value='Class 6'>Class 6</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass7SecA') ? 'block' : 'none' }} id='Eng Class 7' key='Eng Class 7' value='Class 7'>Class 7</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass8SecA') ? 'block' : 'none' }} id='Eng Class 8' key='Eng Class 8' value='Class 8'>Class 8</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass9SecA') ? 'block' : 'none' }} id='Eng Class 9' key='Eng Class 9' value='Class 9'>Class 9</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass10SecA') ? 'block' : 'none' }} id='Eng Class 10' key='Eng Class 10' value='Class 10'>Class 10</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass11ScienceSecA') ? 'block' : 'none' }} id='Eng Class 11(Science)' key='Eng Class 11(Science)' value='Class 11(Science)'>Class 11(Science)</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass11CommerceSecA') ? 'block' : 'none' }} id='Eng Class 11(Commerce)' key='Eng Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass12ScienceSecA') ? 'block' : 'none' }} id='Eng Class 12(Science)' key='Eng Class 12(Science)' value='Class 12(Science)'>Class 12(Science)</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'English Medium' && returnedChildsClassList.includes('EngClass12CommerceSecA') ? 'block' : 'none' }} id='Eng Class 12(Commerce)' key='Eng Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>

                                                    <MenuItem style={{ display: parentsData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarPreNurserySecA') ? 'block' : 'none' }} id='Mar Pre Nursery' key='Mar Pre Nursery' value='Pre Nursery'>Pre Nursery</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarNurserySecA') ? 'block' : 'none' }} id='Mar Nursery' key='Mar Nursery' value='Nursery'>Nursery</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarKG1SecA') ? 'block' : 'none' }} id='Mar Kindergarten 1(KG-1)' key='Mar Kindergarten 1(KG-1)' value='Kindergarten 1(KG-1)'>Kindergarten 1(KG-1)</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarKG2SecA') ? 'block' : 'none' }} id='Mar Kindergarten 2(KG-2)' key='Mar Kindergarten 2(KG-2)' value='Kindergarten 2(KG-2)'>Kindergarten 2(KG-2)</MenuItem>

                                                    <MenuItem style={{ display: parentsData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass1SecA') ? 'block' : 'none' }} id='Mar Class 1' key='Mar Class 1' value='Class 1'>Class 1</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass2SecA') ? 'block' : 'none' }} id='Mar Class 2' key='Mar Class 2' value='Class 2'>Class 2</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass3SecA') ? 'block' : 'none' }} id='Mar Class 3' key='Mar Class 3' value='Class 3'>Class 3</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass4SecA') ? 'block' : 'none' }} id='Mar Class 4' key='Mar Class 4' value='Class 4'>Class 4</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass5SecA') ? 'block' : 'none' }} id='Mar Class 5' key='Mar Class 5' value='Class 5'>Class 5</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass6SecA') ? 'block' : 'none' }} id='Mar Class 6' key='Mar Class 6' value='Class 6'>Class 6</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass7SecA') ? 'block' : 'none' }} id='Mar Class 7' key='Mar Class 7' value='Class 7'>Class 7</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass8SecA') ? 'block' : 'none' }} id='Mar Class 8' key='Mar Class 8' value='Class 8'>Class 8</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass9SecA') ? 'block' : 'none' }} id='Mar Class 9' key='Mar Class 9' value='Class 9'>Class 9</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass10SecA') ? 'block' : 'none' }} id='Mar Class 10' key='Mar Class 10' value='Class 10'>Class 10</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass11ArtsSecA') ? 'block' : 'none' }} id='Mar Class 11(Arts)' key='Mar Class 11(Arts)' value='Class 11(Arts)'>Class 11(Arts)</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass11CommerceSecA') ? 'block' : 'none' }} id='Mar Class 11(Commerce)' key='Mar Class 11(Commerce)' value='Class 11(Commerce)'>Class 11(Commerce)</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass12ArtsSecA') ? 'block' : 'none' }} id='Mar Class 12(Arts)' key='Mar Class 12(Arts)' value='Class 12(Arts)'>Class 12(Arts)</MenuItem>
                                                    <MenuItem style={{ display: parentsData.childsMedium === 'Marathi Medium' && returnedChildsClassList.includes('MarClass12CommerceSecA') ? 'block' : 'none' }} id='Mar Class 12(Commerce)' key='Mar Class 12(Commerce)' value='Class 12(Commerce)'>Class 12(Commerce)</MenuItem>
                                                </Select>
                                                <FormHelperText id="childClass"><p>{showEnteredParents.childClass.helperText}</p></FormHelperText>
                                            </FormControl>

                                            <FormControl error={showEnteredParents.childSection.error} name='childSection' variant="outlined" >
                                                <InputLabel htmlFor="childSection">Section :</InputLabel>
                                                <Select sx={{ width: '30ch' }} id="childSection" name='childSection' label="Section" inputProps={{ name: 'childSection', id: 'childSection', }}
                                                    value={parentsData.childSection}
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setparentsData(prevState => { return { ...prevState, childSection: e.target.value } })
                                                        setshowEnteredParents(prevState => { return { ...prevState, childSection: { error: false, helperText: '' } } })
                                                    }}
                                                >
                                                    <MenuItem style={{ display: parentsData.childClass === '' ? 'block' : 'none' }} aria-label="None" value="" />

                                                    <MenuItem style={{ display: returnedChildsSectionList?.SectionA === true ? 'block' : 'none' }} key='Section A' value='Section A'>Section A</MenuItem>
                                                    <MenuItem style={{ display: returnedChildsSectionList?.SectionB === true ? 'block' : 'none' }} key='Section B' value='Section B'>Section B</MenuItem>
                                                    <MenuItem style={{ display: returnedChildsSectionList?.SectionC === true ? 'block' : 'none' }} key='Section C' value='Section C'>Section C</MenuItem>
                                                    <MenuItem style={{ display: returnedChildsSectionList?.SectionD === true ? 'block' : 'none' }} key='Section D' value='Section D'>Section D</MenuItem>
                                                    <MenuItem style={{ display: returnedChildsSectionList?.SectionE === true ? 'block' : 'none' }} key='Section E' value='Section E'>Section E</MenuItem>
                                                </Select>
                                                <FormHelperText id="childSection"><p>{showEnteredParents.childSection.helperText}</p></FormHelperText>
                                            </FormControl>
                                        </div>

                                        <LoadingButton
                                            onClick={sendRequestChild}
                                            variant='outlined'
                                            loading={btnLoading}
                                        >
                                            Send Verification Request to School
                                        </LoadingButton>
                                        {/* <Button disabled={actionsPerformed.buttonDisabled} ></Button> */}
                                        {
                                            actionsPerformed.msgSent && (
                                                <div className='addNewChildDataHereRequestSent'>
                                                    <h3>Request Sent to School...</h3>
                                                </div>
                                            )
                                        }
                                        <hr />
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </div>

                )
            }


        </div>
    )
}

export default SidebarESchoolingStatusShowParent